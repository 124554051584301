import { inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { ActivatedFeatureInterfaceResponse } from "@shared/interfaces/activated-feature.interface";
import { IFeature } from "@shared/components/interfaces/feature.interface";
import { String } from "typescript-string-operations";
import { UserWaitlistDto } from "@core/services/users/user-waitlist-dto";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { IFeaturePrice } from "@shared/interfaces/feature-price-list.interface";
import { IFeatureSport } from "@shared/interfaces/feature-sport.interface";
import { IFeatureConfiguration } from "@shared/interfaces/feature-configuration.interface";
import { ApiBaseService } from "@core/services/api-base.service";

@Injectable()
export class FeatureStoreService {
  private apiService: ApiService = inject(ApiService);
  private apiBaseService: ApiBaseService = inject(ApiBaseService);

  public getFeatureList(): Observable<IFeature[]> {
    return this.apiService.get(AppConstants.API.FEATURE_ITEM.GET);
  }

  public getFeatureStoreCatalog(): Observable<IFeature[]> {
    return this.apiService.get(AppConstants.API.FEATURE_CATALOG.GET);
  }

  public getFeatureById(id: number): Observable<IFeature> {
    return this.apiService.get(`${AppConstants.API.FEATURE_ITEM.GET}${id}/`,);
  }

  public getExpandFeatureById(id: number): Observable<IFeature> {
    return this.apiService.get(`${AppConstants.API.FEATURE_ITEM.GET}${id}?expand=defaults`,);
  }

  public getFeatureBySlug(slug: string): Observable<IFeature> {
    return this.apiService.get(`${AppConstants.API.FEATURE_ITEM.GET}?feature_slug=${slug}`,);
  }

  public setToWaitList(id: number): Observable<Object> {
    return this.apiService.post(String.Format(AppConstants.API.FEATURE_WAIT_LIST.POST, id));
  }

  public getActivatedFeatureList(): Observable<ActivatedFeatureInterfaceResponse> {
    return this.apiService.get(AppConstants.API.FEATURE_STORE.PURCHASED_FEATURE_LIST);
  }

  public getFeatureInWaitList(featureId: number, waitList: UserWaitlistDto[]): UserWaitlistDto | undefined {
    return waitList.find((feature: UserWaitlistDto): boolean => feature.id === featureId);
  }

  public getCurrencyNameByFeature(featureList: ITileConfig[] | IFeaturePrice[]): string {
    if (featureList.length) {
      return featureList[0].currencyName;
    }

    return 'N/A';
  }

  public getFeatureListByCategory(categoryName: string, showAllFeature: boolean = true): Observable<IFeatureSport[]> {
    return this.apiService.get(`${AppConstants.API.FEATURE_ITEM.GET}?category_name=${categoryName}`)
      .pipe(
        map((response: any): IFeatureSport[] => showAllFeature ? response.results : response.results.filter((feature: IFeatureSport) => feature.is_enabled))
      );
  }

  public getFeatureConfigurationById(id: number): Observable<IFeatureConfiguration> {
    return this.apiService.get(String.Format(AppConstants.API.FEATURE_SPORT_CONFIGURATION.GET, id));
  }

  public getExtendedSportFeatureConfigurationList(showAllFeature: boolean = true): Observable<IFeatureConfiguration> {
    return this.apiService.get('features/configurations/?category_name=Sports&expand=feature')
      .pipe(
        map((response: any): IFeatureConfiguration => showAllFeature ? response.results : response.results.filter((feature: IFeatureSport) => feature.is_enabled))
      );
  }

  public createFeatureConfiguration(configurationValue: any): Observable<any> {
    return this.apiBaseService.postJson('features/configurations/',  configurationValue);
  }

  public updateFeatureConfiguration(configurationValue: any, configId: number): Observable<any> {
    return this.apiBaseService.putJson(`features/configurations/${configId}/`,  configurationValue);
  }

  public unsubscribeFeature(purchaseId: number): Observable<any> {
    const apiUrl: string = String.Format(AppConstants.API.FEATURE_PURCHASE.UNSUBSCRIBE, purchaseId);
    return this.apiBaseService.deleteJson(apiUrl);
  }

  public invalidateCancellation(purchaseId: number): Observable<any> {
    const apiUrl: string = String.Format(AppConstants.API.FEATURE_PURCHASE.INVALIDATE_CANCELLATION, purchaseId);
    return this.apiBaseService.postJson(apiUrl, {});
  }
}
