import { inject, Injectable } from '@angular/core';
import { IProfileItem } from "@shared/interfaces/profile-item.interface";
import { SessionService } from "@core/services/session.service";

@Injectable()
export class MyProfileService {
  private sessionService: SessionService = inject(SessionService);
  private BACK_QUERY: string = 'general_back_button=show';

  public getProfileItemList(): IProfileItem[] {
    return [
      {
        title: 'Account Info',
        description: 'Address (Billing & street), Logo, usage statistics',
        urlNavigateTo: `/home/account-info?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/account-info.svg',
        iconLabel: 'account-info',
        isVisible: this.sessionService.rights.admin_org_update,
      },
      {
        title: 'Personal Info',
        description: 'Personal info of user: adress, email, PW',
        urlNavigateTo: `/home/personal-info?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/personal-info.svg',
        iconLabel: 'personal-info',
        isVisible: true,
      },
      {
        title: 'Devices',
        description: 'Current manage device',
        urlNavigateTo: `/home/administration/device-management?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/devices.svg',
        iconLabel: 'devices',
        isVisible: this.sessionService.rights.admin_devices_list,
      },
      {
        title: 'Tags',
        description: 'Current manage tags',
        urlNavigateTo: `/home/administration/tags?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/tags.svg',
        iconLabel: 'tags',
        isVisible: this.sessionService.rights.admin_tags_list,
      },
      {
        title: 'Integrations',
        description: 'Automatically create API keys, API tracking',
        urlNavigateTo: `/home/integrations?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/integrations.svg',
        iconLabel: 'integrations',
        isVisible: true,
      },
      {
        title: 'Security',
        description: 'Current Security settings',
        urlNavigateTo: `/home/security?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/security.svg',
        iconLabel: 'security',
        isVisible: true,
      },
      {
        title: 'Subscriptions',
        description: 'Current My subscriptions page, plus feature store purchases',
        urlNavigateTo: `/home/plan-subscription/my-subscription?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/subscriptions.svg',
        iconLabel: 'subscriptions',
        isVisible: this.sessionService.rights.subscription_view_subscription_page,
      },
      {
        title: 'Billing',
        description: 'Current Invoices, plus receipts, plus change payment methods',
        urlNavigateTo: `/home/plan-subscription?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/billing.svg',
        iconLabel: 'billing',
        isVisible: this.sessionService.rights.subscription_view_invoice_page,
      },
      {
        title: 'User Rights Management',
        description: 'Allows to configure access to the specific feature',
        urlNavigateTo: `/home/administration/rights-management?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/rights-management.svg',
        iconLabel: 'rights-management',
        isVisible: true,
      },
    ];
  }
}
