<app-loader-indicator></app-loader-indicator>
<div class="full-page-container">
    <div class="form">
      <div class="form-group text-center">
        <h1>Reset Password</h1>
      </div>
    <div [formGroup]="resetForm">
      <div class="field">
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input
            class="loginInput"
            type="password"
            matInput
            placeholder="New password"
            formControlName="password"
          />
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field appearance="outline" [style.width.px]="350">
            <input
              class="loginInput"
              type="password"
              matInput
              placeholder="Confirm password"
              formControlName="password_confirm"
            />
        </mat-form-field>
      </div>
    </div>
      <button class="c-btn-blue" (click)="handleResetPassword()">Update</button>
      <a class="back-login-link" [routerLink]="['']">Back to Login</a>
    </div>
  </div>

