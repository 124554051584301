<div class="page-container">
  <div class="top-toolbar">
    <div class="sub-title">SETTINGS</div>
    <div class="title">Integrations</div>
  </div>
  <ng-container *ngIf="integrationObject?.key; else noApiKey">
    <app-integration-item [config]="integrationObject"></app-integration-item>
  </ng-container>
  <ng-template #noApiKey>
    <div class="u-text-align-center">
      There is no API Key
    </div>
  </ng-template>

</div>
