<div>
  <h2 mat-dialog-title>{{ data?.isEdit ? "Edit Product" : "Add Product" }}</h2>
  <mat-dialog-content>
    <div [formGroup]="infoForm">
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label> Name (*) </mat-label>
          <input
            matInput
            type="text"
            name="name"
            formControlName="name"
          />
          <mat-error
            *ngIf="
              infoForm.controls?.name?.errors &&
              infoForm.controls?.name?.touched
            "
          >
            <div
              [innerHTML]="
                infoForm.controls.name.errors
                  | errorFormFormat : { fieldName: 'Name' }
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field field-toggle">
        <mat-slide-toggle
          style="margin-bottom: 2%"
          class="full-width"
          formControlName="is_publish"
          >Published</mat-slide-toggle>
      </div>
    </div>
    <div [formGroup]="detailsForm">
      <div class="setting__section">
        <div class="sub-title border-color">
          <mat-icon
            *ngIf="!isShowProductDetails"
            (click)="isShowProductDetails = true"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            *ngIf="isShowProductDetails"
            (click)="isShowProductDetails = false"
            >keyboard_arrow_down</mat-icon
          >
          Product details
        </div>
        <div class="content" *ngIf="isShowProductDetails">
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label> Description (*) </mat-label>
              <textarea
                formControlName="description"
                rows="5"
                matInput
              ></textarea>
              <mat-error
                *ngIf="
                  detailsForm.controls?.description?.errors &&
                  detailsForm.controls?.description?.touched
                "
              >
                <div
                  [innerHTML]="
                    detailsForm.controls.description.errors
                      | errorFormFormat : { fieldName: 'Description' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
          </div>

          <ng-container
            formArrayName="features"
            *ngFor="let feature of features.controls; index as i"
          >
            <div [formGroupName]="i" class="feature-field">
              <button
                type="button"
                mat-raised-button
                color="warn"
                (click)="removeFeature(i)"
                class="remove-btn"
              >
                x
              </button>
              <mat-form-field
                style="width: 92%"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label> Feature {{ i + 1 }} (*) </mat-label>
                <input
                  matInput
                  type="text"
                  id="feature"
                  name="feature"
                  formControlName="feature"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <div class="btn-add">
            <button mat-raised-button type="button" (click)="addFeatureField()">
              Add Feature
            </button>
          </div>
          <div class="sub-title border-color"></div>
          <div class="field">
            <app-chips
              [label]="'Included plans'"
              [form]="detailsForm.get('plans')"
              [options]="planOptions"
              [displayField]="'name'"
              (optionChange)="onSelectPlan($event)"
            ></app-chips>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Plan billed at</mat-label>

              <mat-select formControlName="plan_bill_at">
                <mat-option
                  *ngFor="let billPlan of billPlans"
                  [value]="billPlan.id"
                >
                  {{ billPlan.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error
              *ngIf="
                detailsForm.controls?.plan_bill_at?.errors &&
                detailsForm.controls?.plan_bill_at?.touched
              "
            >
              <div
                [innerHTML]="
                  detailsForm.controls.plan_bill_at.errors
                    | errorFormFormat : { fieldName: 'Plan billed at' }
                "
              ></div>
            </mat-error>
          </div>

          <div class="field field-toggle">
            <mat-slide-toggle
              style="margin-bottom: 2%"
              class="full-width"
              (change)="openCloseRights($event.checked)"
              [checked]="detailsForm.get('is_show_setting')?.value"
              formControlName="is_show_setting"
              >Settings in Right Management</mat-slide-toggle>
          </div>
        </div>
      </div>
      <div
        class="setting__section"
        *ngIf="detailsForm.get('is_show_setting')?.value"
      >
        <div class="sub-title border-color">
          <mat-icon
            *ngIf="!showRightManagement"
            (click)="showRightManagement = true"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            *ngIf="showRightManagement"
            (click)="showRightManagement = false"
            >keyboard_arrow_down</mat-icon
          >
          Right Management
        </div>
        <div *ngIf="showRightManagement">
          <div class="note">
            <span>YES</span>
            <span>NO</span>
          </div>
          <ng-container *ngIf="rightList.length">
            <fieldset class="dynamic-fieldset" *ngFor="let item of rightList">
              <legend>{{ item.name }}</legend>
              <div class="option" *ngFor="let optionItem of item.options">
                <div class="label">{{ optionItem?.name }}</div>
                <mat-radio-group
                  (change)="changedValueOfRadioButton(optionItem.path, $event)"
                  class="value"
                >
                  <mat-radio-button
                    style="margin-right: 12px"
                    color="primary"
                    [value]="true"
                    [checked]="isRadioChecked(optionItem.path, true)"
                  ></mat-radio-button>
                  <mat-radio-button
                    color="primary"
                    [value]="false"
                    [checked]="isRadioChecked(optionItem.path, false)"
                  ></mat-radio-button>
                </mat-radio-group>
              </div>
              <ng-container *ngFor="let subGroup of item.subGroup">
                <div class="sub-title">{{ subGroup.name }}</div>
                <div
                  class="option"
                  *ngFor="let subOptionItem of subGroup.options"
                >
                  <div class="label">{{ subOptionItem?.name }}</div>
                  <mat-radio-group
                    (change)="
                      changedValueOfRadioButton(subOptionItem.path, $event)
                    "
                    class="value"
                  >
                    <mat-radio-button
                      style="margin-right: 12px"
                      color="primary"
                      [value]="true"
                      [checked]="isRadioChecked(subOptionItem.path, true)"
                    ></mat-radio-button>
                    <mat-radio-button
                      color="primary"
                      [value]="false"
                      [checked]="isRadioChecked(subOptionItem.path, false)"
                    ></mat-radio-button>
                  </mat-radio-group>
                </div>
              </ng-container>
            </fieldset>
          </ng-container>
        </div>
      </div>
    </div>
    <div [formGroup]="availabilityForm">
      <div class="setting__section">
        <div class="sub-title border-color">
          <mat-icon
            *ngIf="!isShowAvailability"
            (click)="isShowAvailability = true"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            *ngIf="isShowAvailability"
            (click)="isShowAvailability = false"
            >keyboard_arrow_down</mat-icon
          >
          Availability
        </div>
        <div class="content" *ngIf="isShowAvailability">
          <div class="field">
            <app-chips
              [label]="'Account type it applies to'"
              [form]="availabilityForm.get('account_types')"
              [options]="accountTypeOptions"
              [displayField]="'name'"
              (optionChange)="onSelectAccountType($event)"
            ></app-chips>
          </div>
          <div class="field">
            <app-chips
              [label]="'Region'"
              [form]="availabilityForm.get('regions')"
              [options]="regionPricingOptions"
              [displayField]="'name'"
              (optionChange)="onSelectRegion($event)"
            ></app-chips>
          </div>
          <div class="field">
            <app-chips
              [label]="'Organization it applies to'"
              [form]="availabilityForm.get('organizations')"
              [options]="organizationOptions"
              [displayField]="'name'"
              (optionChange)="onSelectOrganization($event)"
            ></app-chips>
          </div>
        </div>
      </div>
    </div>
    <div [formGroup]="pricesForm">
      <div class="setting__section">
        <div class="sub-title border-color">
          <mat-icon *ngIf="!isShowPrice" (click)="isShowPrice = true"
            >keyboard_arrow_right</mat-icon
          >
          <mat-icon *ngIf="isShowPrice" (click)="isShowPrice = false"
            >keyboard_arrow_down</mat-icon
          >
          Price - initial setup fee
        </div>
        <div *ngIf="isShowPrice">
          <ng-container
            formArrayName="prices"
            *ngFor="let price of prices.controls; index as i"
          >
            <div class="field" [formGroupName]="i">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>{{ price.value.region_name }}</mat-label>
                <input
                  matInput
                  id="price"
                  name="price"
                  formControlName="price"
                />
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="setting__section" [formGroup]="discountsForm">
      <div class="sub-title border-color">
        <mat-icon *ngIf="!isShowDiscount" (click)="isShowDiscount = true"
          >keyboard_arrow_right</mat-icon
        >
        <mat-icon *ngIf="isShowDiscount" (click)="isShowDiscount = false"
          >keyboard_arrow_down</mat-icon
        >
        Discount
      </div>
      <div *ngIf="isShowDiscount">
        <ng-container
          formArrayName="discounts"
          *ngFor="let discount of discounts.controls; index as i"
        >
          <div class="discount-container">
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="removeDiscountField(i)"
              class="remove-btn"
            >
              x
            </button>
            <div [formGroupName]="i" class="discount-field">
              <div class="feature-field">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label> Discount {{ i + 1 }}</mat-label>
                  <input
                    matInput
                    id="discount"
                    name="discount"
                    formControlName="discount_value"
                  />
                </mat-form-field>
                <mat-form-field
                  style="width: 20%"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label> Discount type </mat-label>
                  <mat-select formControlName="discount_type">
                    <mat-option
                      *ngFor="let discType of discountTypes"
                      [value]="discType.id"
                    >
                      {{ discType.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <p>Applies to:</p>

              <div class="field">
                <app-chips
                  [label]="'Account type it applies to'"
                  [form]="getFormControlFromArray('account_types', i)"
                  [options]="accountTypeOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscAccountType($event, i)"
                ></app-chips>
              </div>
              <div class="field">
                <app-chips
                  [label]="'Region'"
                  [form]="getFormControlFromArray('regions', i)"
                  [options]="regionPricingOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscRegion($event, i)"
                ></app-chips>
              </div>
              <div class="field">
                <app-chips
                  [label]="'Organization it applies to'"
                  [form]="getFormControlFromArray('organizations', i)"
                  [options]="organizationOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscOrganization($event, i)"
                ></app-chips>
              </div>
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Valid to</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="valid_until"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <div class="btn-add">
          <button mat-raised-button type="button" (click)="addDiscountForm()">
            Add Discount
          </button>
        </div>
      </div>
    </div>
    <div class="setting__section" [formGroup]="freePeriodForm">
      <div class="sub-title border-color">
        <mat-icon *ngIf="!isShowFreePeriod" (click)="isShowFreePeriod = true"
          >keyboard_arrow_right</mat-icon
        >
        <mat-icon *ngIf="isShowFreePeriod" (click)="isShowFreePeriod = false"
          >keyboard_arrow_down</mat-icon
        >
        Free period
      </div>
      <div *ngIf="isShowFreePeriod">
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label> Duration (days) (*) </mat-label>
            <input
              matInput
              type="text"
              name="name"
              formControlName="duration"
            />
            <mat-error
              *ngIf="
                freePeriodForm.controls?.duration?.errors &&
                freePeriodForm.controls?.duration?.touched
              "
            >
              <div
                [innerHTML]="
                  freePeriodForm.controls.duration.errors
                    | errorFormFormat : { fieldName: 'Duration (days)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <app-chips
            [label]="'Start trigger (*)'"
            [form]="freePeriodForm.get('triggers')"
            [options]="items"
            [displayField]="'name'"
            (optionChange)="triggersChange($event)"
          ></app-chips>
        </div>
        <div class="max-field">
          <div class="field" *ngFor="let item of items">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label> {{ item.name }} </mat-label>
              <input
                matInput
                type="number"
                name="name"
                min="0"
                formControlName="{{ item.name }}"
              />
            </mat-form-field>
          </div>
        </div>
        <ng-container
          formArrayName="ratios"
          *ngFor="let ratio of ratios.controls; index as i"
        >
          <div class="ratio-container">
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="removeRatio(i)"
              class="remove-btn"
            >
              x
            </button>
            <div [formGroupName]="i" class="ratio-field">
              <p>Ratio {{ i + 1 }}</p>
              <div class="ratio-row-container">
                <div class="field" style="width: 33%">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label> Item 1 </mat-label>
                    <mat-select formControlName="item1">
                      <mat-option *ngFor="let item of items" [value]="item.id">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label> Quantity </mat-label>
                    <input
                      matInput
                      type="number"
                      id="quantity"
                      name="quantity"
                      formControlName="item1_quantity"
                    />
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label> Period value </mat-label>
                    <input
                      matInput
                      type="number"
                      id="period_value"
                      name="period_value"
                      formControlName="period_value"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="ratio-row-container">
                <div class="field" style="width: 33%">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label> Item 2 </mat-label>

                    <mat-select formControlName="item2">
                      <mat-option *ngFor="let item of items" [value]="item.id">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label> Quantity </mat-label>
                    <input
                      matInput
                      type="number"
                      id="quantity"
                      name="item2_quantity"
                      value="1"
                      disabled
                    />
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label> Period type </mat-label>
                    <mat-select formControlName="period_type">
                      <mat-option
                        *ngFor="let periodType of periodTypes"
                        [value]="periodType.id"
                        >{{ periodType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="btn-add">
          <button mat-raised-button type="button" (click)="addRatioForm()">
            Add Ratio
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button id="cancelButton" mat-button (click)="close()" type="button">
        Cancel
      </button>
      <button
        id="saveButton"
        mat-raised-button
        color="primary"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
