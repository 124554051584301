import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConstants } from '@core/constants';
import { PpdService } from '../../services/ppd.service';
import { DialogsData } from '../../tabs/tabs.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-max-effort-dialog',
  templateUrl: './max-effort-dialog.component.html',
  styleUrls: ['./max-effort-dialog.component.scss'],
})
export class MaxEffortDialogComponent implements OnInit {
  public form!: UntypedFormGroup;
  public lactate_rest_values: number[] = [];
  public post_lactate_values: number[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MaxEffortDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogsData,
    private snackBar: MatSnackBar,
    private ppdService: PpdService,
    private destroyRef: DestroyRef,
  ) {}

  public initForm(): void {
    this.form = this.fb.group({
      duration_efforts: new UntypedFormControl(this.data.duration_efforts),
      average_speed: new UntypedFormControl(this.data.average_speed),
      average_power: new UntypedFormControl(this.data.average_power),
      lactate_rest_1: new UntypedFormControl(),
      lactate_rest_2: new UntypedFormControl(),
      lactate_rest_3: new UntypedFormControl(),
      post_lactate_1: new UntypedFormControl(),
      post_lactate_2: new UntypedFormControl(),
      post_lactate_3: new UntypedFormControl(),
      post_lactate_4: new UntypedFormControl(),
      post_lactate_5: new UntypedFormControl(),
      post_lactate_6: new UntypedFormControl(),
      post_lactate_7: new UntypedFormControl(),
      post_lactate_8: new UntypedFormControl(),
      post_lactate_9: new UntypedFormControl(),
      post_lactate_10: new UntypedFormControl(),
    });
  }

  public ngOnInit(): void {
    this.initForm();
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formChangedValues): void => {
        let lactate_values =
          this.ppdService.updateSprintDialogMaxEffortDialogValues(
            formChangedValues
          );
        this.lactate_rest_values = lactate_values.lactate_rest_values;
        this.post_lactate_values = lactate_values.post_lactate_values;
      });
  }

  public save(): void {
    let maxEffortData = {
      ...this.form.value,
      lactate_rest_values: this.lactate_rest_values,
      post_lactate_values: this.post_lactate_values,
    };

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    if (!this.lactate_rest_values.length || !this.post_lactate_values.length) {
      this.snackBar.open('There must be at least one Lactate Rest value and one Post Lactate value', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
    this.dialogRef.close(maxEffortData);
  }
  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}
