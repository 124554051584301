import { Injectable } from "@angular/core";
import { ApiBaseService } from "@core/services/api-base.service";
import { BehaviorSubject, delay, distinctUntilChanged, forkJoin } from "rxjs";
import { AppConstants } from "@core/constants";
import { IIntegrationLink, IIntegrationLinkResponse } from "@shared/interfaces/integration-link.interface";
import { IIntegrationTemplate, IIntegrationTemplateResponse } from "@shared/interfaces/integration-template.interface";
import { ApiInfoDto } from "@core/services/manage-organizations/manage-organization-dto";

@Injectable()
export class IntegrationComponentState {
  private readonly _loading = new BehaviorSubject<boolean>(false);
  private readonly _integrationTemplates = new BehaviorSubject<IIntegrationTemplate[]>([]);
  private readonly _integrations = new BehaviorSubject<IIntegrationLink[]>([]);
  private readonly _apiInfo = new BehaviorSubject<ApiInfoDto[]>([]);

  public loading$ = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());
  public integrationTemplates$ = this._integrationTemplates.asObservable();
  public integrations$ = this._integrations.asObservable();
  public apiInfo$ = this._apiInfo.asObservable();

  constructor(
    private _apiService: ApiBaseService
  ) { }

  public loadState(): void {
    this._loading.next(true);

    const requests = {
      templates: this._apiService.getJson<IIntegrationTemplateResponse>(AppConstants.API.INTEGRATIONS.TEMPLATES),
      links: this._apiService.getJson<IIntegrationLinkResponse>(AppConstants.API.INTEGRATIONS.LINKS.LIST),
      apiInfo: this._apiService.getJson<any>(AppConstants.API.ORGANIZATIONS.API_INFO),
    };
    forkJoin(requests).subscribe({
      next: ({templates, links, apiInfo}) => {
        this._loading.next(false);
        this._apiInfo.next(apiInfo.results as ApiInfoDto[] ?? []);
        this._integrationTemplates.next(templates.results ?? []);
        this._integrations.next(links.results ?? []);
      },
      error: (err) => {
        this._loading.next(false);
        console.error(err);
      },
    })
  }
}