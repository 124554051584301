import { Component, Input, OnChanges, ViewChild } from "@angular/core";
import { TextEditorComponent } from "@modules/administration/report-template/text-editor/text-editor.component";
import { AppConstants } from "@core/constants";
import { ReportBuildingBlocks } from "@modules/apc/apc/data/chart-modules";

@Component({
  selector: 'app-edit-text-preview',
  templateUrl: 'edit-text-preview.component.html',
  styleUrls: ['edit-text-preview.component.scss'],
})
export class EditTextPreviewComponent implements OnChanges {
  @Input() public id: string;
  @Input() public width: number;
  @Input() public height: number;
  @Input() public isDefaultText: boolean;
  @Input() public textType: string;
  @Input() public organisationText: string;
  @Input() public set text(data: string) {
    this.textFromEditor = data;
    this.copyTextFromEditor = data;
  }

  @ViewChild(TextEditorComponent) textEditorComponent: TextEditorComponent;

  public PREVIEW_PDF: string = AppConstants.TEXT_EDITOR_VIEW_TYPE.PREVIEW_PDF;
  public isShowTextEditor: boolean = false;
  public isShowTextPreview: boolean = true;
  public textFromEditor: string;
  public copyTextFromEditor: string;

  public ngOnChanges(): void {
    if (this.isDefaultText) {
      this.textFromEditor = this.copyTextFromEditor;
    } else {
      if (this.textType !== ReportBuildingBlocks.GENERAL_TEXT) {
        this.textFromEditor = this.organisationText;
      }
    }
  }

  public enableTextEditor(): void {
    this.isShowTextEditor = true;
    this.isShowTextPreview = false;
  }

  public disableAndSaveTextEditor(): void {
    this.textFromEditor = this.textEditorComponent.getModel();
    this.isShowTextEditor = false;
    this.isShowTextPreview = true;
  }
}
