import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRequest } from '@shared/models/api-request';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { TrainingZonePagedResultDto } from './training-zone-paged-result-dto';
import { TrainingZoneTemplateDto } from './training-zone-template-dto';
import { ServiceBase } from '../service-base';
import { String } from 'typescript-string-operations';

@Injectable()
export class TrainingZoneService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto): Observable<TrainingZonePagedResultDto> {
    let apiUrl: string = this._appConstants.API.TRAINING_ZONE_TEMPLATE.LIST;
    apiUrl += `?page=${request.pageIndex}`;
    if (request.keyword) {
      apiUrl += `&search=${encodeURI(request.keyword)}`;
    }
    if (request.sorting) {
      const ordering: string = !request.sortByDesc ? request.sorting : encodeURI(`-${request.sorting}`);
      apiUrl += `&ordering=${ordering}`;
    } else {
      apiUrl += `&ordering=` + encodeURI(`-id`);
    }
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl});

    return this._apiService.get(apiRequest.apiUrl).pipe(_observableMergeMap((response_: any): Observable<TrainingZonePagedResultDto> => {
      if (response_.length === 0) {
        return _observableOf(new TrainingZonePagedResultDto());
      }
      const elementPagedResultDto: TrainingZonePagedResultDto = new TrainingZonePagedResultDto().deserialize({
        totalCount: response_.count,
        items: response_.results.map((item: TrainingZoneTemplateDto) => new TrainingZoneTemplateDto().deserialize(item))
      });
      return _observableOf(elementPagedResultDto);
    }));
  }

  public create(template: TrainingZoneTemplateDto): Observable<TrainingZoneTemplateDto> {
    const apiUrl: string = this._appConstants.API.TRAINING_ZONE_TEMPLATE.ADD;
    const body = {};
    Object.assign(body, template);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl});

    return this._apiService.post(apiRequest.apiUrl, apiRequest.body).pipe(_observableMergeMap((response_: any) => {
      const elementPagedResultDto: TrainingZoneTemplateDto = new TrainingZoneTemplateDto().deserialize(response_);

      return _observableOf(elementPagedResultDto);
    }));
  }

  public get(id: number): Observable<TrainingZoneTemplateDto> {
    const apiUrl: string = String.Format(this._appConstants.API.TRAINING_ZONE_TEMPLATE.UPDATE, id);

    return this._apiService.get(apiUrl).pipe(_observableMergeMap((response_: any) => {
      const elementPagedResultDto:TrainingZoneTemplateDto = new TrainingZoneTemplateDto().deserialize(response_);

      return _observableOf(elementPagedResultDto);
    }));
  }

  public update (template: TrainingZoneTemplateDto): Observable<TrainingZoneTemplateDto> {
    const apiUrl: string = String.Format(this._appConstants.API.TRAINING_ZONE_TEMPLATE.UPDATE, template.id);
    const body = {};
    Object.assign(body, template);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl});

    return this._apiService.put(apiRequest.apiUrl, apiRequest.body).pipe(_observableMergeMap((response_: any) => {
      const elementPagedResultDto: TrainingZoneTemplateDto = new TrainingZoneTemplateDto().deserialize(response_);

      return _observableOf(elementPagedResultDto);
    }));
  }

  public delete(id: number): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TRAINING_ZONE_TEMPLATE.DELETE, id);

    return this._apiService.delete(apiUrl).pipe(_observableMergeMap((response_: any) => {
      return _observableOf(response_);
    })).pipe(_observableCatch((response_: any) => {
        return <Observable<void>><any>_observableThrow(response_);
    }));
  }
}
