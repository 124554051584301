import {Component, Inject} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {AppBaseComponent} from '@shared/components/app-component-base';
import {combineLatest} from 'rxjs';
import {ApiService} from '@core/services/api.service';
import {ProductDto} from '@core/services/products/product-dto';
import {AppConstants} from '@core/constants';
import * as _ from 'lodash-es';
import {ProductService} from '@core/services/products/product.service';
import {IRightList} from '../../rights-management/rights-management-dialog/rights-management-dialog.component';
import {RightConstants} from '../../rights-management/constants/right-constants';

@Component({
  selector: 'app-add-product-dialog',
  templateUrl: './add-product-dialog.component.html',
  styleUrls: ['./add-product-dialog.component.scss'],
})
export class AddProductDialogComponent extends AppBaseComponent {
  public rightList: Array<IRightList> = [];
  public rightOptions: any;
  public rights: any;
  public isShowProductDetails: boolean = true;
  public isShowAvailability: boolean = true;
  public isShowDiscount: boolean = true;
  public isShowPrice: boolean = true;
  public isShowFreePeriod: boolean = true;
  public rightManagemntToggleButton: boolean = false;
  public planOptions: any[] = [];
  public accountTypeOptions: any;
  public regionPricingOptions: any;
  public organizationOptions: any;
  public periodTypes: any;
  public infoForm: UntypedFormGroup = {} as UntypedFormGroup;
  public detailsForm: UntypedFormGroup = {} as UntypedFormGroup;
  public featureForm: UntypedFormGroup = {} as UntypedFormGroup;
  public availabilityForm: UntypedFormGroup = {} as UntypedFormGroup;
  public pricesForm: UntypedFormGroup = {} as UntypedFormGroup;
  public discountsForm: UntypedFormGroup = {} as UntypedFormGroup;
  public discountForm: UntypedFormGroup = {} as UntypedFormGroup;
  public freePeriodForm: UntypedFormGroup = {} as UntypedFormGroup;
  public ratioForm: UntypedFormGroup = {} as UntypedFormGroup;
  public showRightManagement: boolean = true;
  public billPlans = [
    {
      id: 1,
      name: 'At the end of billing period',
    },
    {
      id: 2,
      name: 'At the begining of billing period',
    },
  ];
  public discountTypes = [
    {
      id: 1,
      name: '%',
    },
    {
      id: 2,
      name: 'Total',
    },
  ];
  public items = [
    {
      id: 1,
      name: 'User',
    },
    {
      id: 2,
      name: 'Athlete',
    },
    {
      id: 3,
      name: 'Lactate',
    },
    {
      id: 4,
      name: 'PPD',
    },
    {
      id: 5,
      name: 'Manual',
    },
    {
      id: 6,
      name: 'Virtual',
    },
    {
      id: 7,
      name: 'Event',
    },
    {
      id: 8,
      name: 'Result',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<AddProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: UntypedFormBuilder,
    private readonly snackBar: MatSnackBar,
    private apiService: ApiService,
    private productService: ProductService
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForms();
    this.initOptionAndValue();
  }

  public initForms(): void {
    this.rightOptions = _.cloneDeepWith(RightConstants.DEFAULT_RIGHTS);
    this.rightList = _.cloneDeepWith(
      RightConstants.RIGHTS_LIST_DISPLAY_PRODUCT_DIALOGS
    );
    this.rights = this.rightOptions;
    this.resetRights();
    this.rightManagemntToggleButton = this.data.isEdit;

    this.infoForm = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      is_publish: new UntypedFormControl(false),
    });

    this.featureForm = this.fb.group({
      feature: new UntypedFormControl('', Validators.required),
    });
    this.detailsForm = this.fb.group({
      description: new UntypedFormControl('', Validators.required),
      features: this.fb.array([this.featureForm]),
      plans: new UntypedFormControl(''),
      plan_bill_at: new UntypedFormControl(null),
      rights_toggle: new UntypedFormControl(false),
      settingsRightManagement: new UntypedFormControl(false),
      rightManagement: new UntypedFormControl(this.rights),
      is_show_setting: new UntypedFormControl(false),
    });

    this.availabilityForm = this.fb.group({
      account_types: new UntypedFormControl(''),
      regions: new UntypedFormControl(''),
      organizations: new UntypedFormControl(''),
    });
    this.pricesForm = this.fb.group({
      prices: this.fb.array([]),
    });

    this.discountForm = this.fb.group({
      discount_value: new UntypedFormControl('', [Validators.required]),
      discount_type: new UntypedFormControl(1, [Validators.required]),
      account_types: new UntypedFormControl(''),
      regions: new UntypedFormControl(''),
      organizations: new UntypedFormControl(''),
      valid_until: new UntypedFormControl(''),
    });
    this.discountsForm = this.fb.group({
      discounts: this.fb.array([]),
    });

    this.freePeriodForm = this.fb.group({
      duration: new UntypedFormControl(null, Validators.required),
      triggers: new UntypedFormControl([], [Validators.required]),
      User: new UntypedFormControl(0),
      Athlete: new UntypedFormControl(0),
      Lactate: new UntypedFormControl(0),
      PPD: new UntypedFormControl(0),
      Manual: new UntypedFormControl(0),
      Virtual: new UntypedFormControl(0),
      Event: new UntypedFormControl(0),
      Result: new UntypedFormControl(0),
      ratios: this.fb.array([]),
    });

    this.ratioForm = this.fb.group({
      item1: new UntypedFormControl(''),
      item2: new UntypedFormControl(''),
      item1_quantity: new UntypedFormControl(0),
      period_value: new UntypedFormControl(''),
      period_type: new UntypedFormControl(''),
      item2_quantity: new UntypedFormControl(1),
    });

    if (this.data.isEdit) {
      try {
        this.rights = JSON.parse(this.data.product.right_management);
      } catch (error) {
        this.rights = this.rightOptions;
        this.resetRights();
      }

      this.infoForm = this.fb.group({
        name: new UntypedFormControl(this.data.product.name, Validators.required),
        is_publish: new UntypedFormControl(this.data.product.is_publish),
      });

      this.featureForm = this.fb.group({
        feature: new UntypedFormControl('', Validators.required),
      });

      this.detailsForm = this.fb.group({
        description: new UntypedFormControl(
          this.data.product.description,
          Validators.required
        ),
        features: this.prepareFeatures(),
        plan_bill_at: new UntypedFormControl(this.data.product.plan_bill_at),
        is_show_setting: new UntypedFormControl(this.data.product.is_show_setting),
        settingsRightManagement: new UntypedFormControl(false),
        rightManagement: new UntypedFormControl(this.rights),
        plans: new UntypedFormControl(''),
      });

      this.pricesForm = this.fb.group({
        prices: this.fb.array([]),
      });

      this.freePeriodForm = this.fb.group({
        duration: new UntypedFormControl(
          this.data.product.duration,
          Validators.required
        ),
        triggers: new UntypedFormControl(this.prepareTriggers(), Validators.required),
        User: new UntypedFormControl(this.data.product.max_user),
        Athlete: new UntypedFormControl(this.data.product.max_athlete),
        Lactate: new UntypedFormControl(this.data.product.max_test_la),
        PPD: new UntypedFormControl(this.data.product.max_test_map),
        Manual: new UntypedFormControl(this.data.product.max_test_manual),
        Virtual: new UntypedFormControl(this.data.product.max_test_virtual),
        Event: new UntypedFormControl(this.data.product.max_test_event),
        Result: new UntypedFormControl(this.data.product.max_test_result),
        ratios: this.prepareRatios(),
      });
    }
  }

  public resetRights(): void {
    const keys: string[] = Object.keys(this.rights);
    keys.map((key: string): void => {
      this.rights[key] = false;
    });
  }

  public prepareDiscounts(): UntypedFormArray {
    const discounts = this.data.product.discounts;
    const discountFormsArray: UntypedFormArray = this.fb.array([]);

    discounts.map((discount: any): void => {
      const formGroup: UntypedFormGroup = this.fb.group({
        discount_value: new UntypedFormControl(discount.discount_value),
        discount_type: new UntypedFormControl(discount.discount_type),
        account_types: new UntypedFormControl(
          this.prepareData(discount.account_types, this.accountTypeOptions)
        ),
        regions: new UntypedFormControl(
          this.prepareData(discount.regions, this.regionPricingOptions)
        ),
        organizations: new UntypedFormControl(
          this.prepareData(discount.organizations, this.organizationOptions)
        ),
        valid_until: new UntypedFormControl(discount.valid_until),
      });

      discountFormsArray.push(formGroup);
    });

    return discountFormsArray;
  }

  public prepareData(data: any, dataOptions: any) {
    return dataOptions.filter((item: any) => data.includes(item.value));
  }

  public prepareTriggers() {
    const triggerIds = this.data.product.triggers.split('|');

    return this.items.filter((item) =>
      triggerIds.includes(item.id.toString())
    );
  }
  public prepareFeatures(): UntypedFormArray {
    const features = this.data.product.features.split('|');
    const featureForms: UntypedFormArray = this.fb.array([]);
    features.map((feature: string): void => {
      featureForms.push(
        this.fb.group({
          feature: new UntypedFormControl(feature, Validators.required),
        })
      );
    });

    return featureForms;
  }

  public preparePrices(): void {
    this.regionPricingOptions.map((region: any) => {
      this.data.product.prices.map((price: any) => {
        if (price.region === region.value) {
          this.addPriceField(region, price.price);
        }
      });
    });
  }

  public prepareRatios(): UntypedFormArray {
    const ratioFormArray: UntypedFormArray = this.fb.array([]);

    this.data.product.ratios.map((ratio: any) => {
      const formGroup: UntypedFormGroup = this.fb.group({
        item1: new UntypedFormControl(ratio.item1),
        item2: new UntypedFormControl(ratio.item2),
        item1_quantity: new UntypedFormControl(ratio.item1_quantity),
        period_value: new UntypedFormControl(ratio.period_value),
        period_type: new UntypedFormControl(ratio.period_type),
      });

      ratioFormArray.push(formGroup);
    });

    return ratioFormArray;
  }

  private initOptionAndValue(): void {
    this.periodTypes = AppConstants.PERIOD_TYPES;
    combineLatest([
      this.apiService.get('plans/'),
      this.apiService.get('region-pricing/'),
      this.apiService.get('account-type/'),
      this.apiService.get('organizations/'),
    ]).subscribe(
      ([resPlans, resRegions, resAccountTypes, resOrganizations]: any): void => {
        this.planOptions = resPlans?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        this.accountTypeOptions = resAccountTypes?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        this.regionPricingOptions = resRegions?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });
        this.organizationOptions = resOrganizations?.results?.map(
          (item: any) => {
            return this.mappingOptionValue(item, 'name');
          }
        );

        if (this.data.isEdit) {
          this.detailsForm.patchValue({
            plans: this.prepareData(this.data.product.plans, this.planOptions),
          });
          this.availabilityForm.setValue({
            account_types: this.prepareData(
              this.data.product.account_types,
              this.accountTypeOptions
            ),
            regions: this.prepareData(
              this.data.product.regions,
              this.regionPricingOptions
            ),
            organizations: this.prepareData(
              this.data.product.organizations,
              this.organizationOptions
            ),
          });
          this.preparePrices();
          this.discountsForm = this.fb.group({
            discounts: this.prepareDiscounts(),
          });
        }
      }
    );
  }

  public getFormControlFromArray(controlName: string, index: number): AbstractControl<any, any> | null {
    return (this.discountsForm.get('discounts') as UntypedFormArray).controls[index].get(controlName);
  }

  private mappingOptionValue(item: any, param: string) {
    return {
      value: item?.id,
      name: item[param],
    };
  }

  public onSelectPlan(value: any): void {
    this.detailsForm.get('plans')?.setValue(value);
  }

  public onSelectAccountType(value: any): void {
    this.availabilityForm.get('account_types')?.setValue(value);
  }

  public onSelectRegion(valueList: any): void {
    this.availabilityForm.get('regions')?.setValue(valueList);

    if (valueList.length > this.prices.controls.length) {
      this.addPriceField(
        this.getDifference(valueList, this.prices.controls)[0]
      );
    } else if (valueList.length < this.prices.controls.length) {
      this.removePriceField(
        this.getDifferenceWhenRemoving(this.prices.controls, valueList)[0].value
          .region_id
      );
    }
  }
  public getDifference(a: any[], b: any[]): any {
    return a.filter((object1) => {
      return !b.some((object2): boolean => {
        return object1.value === object2.value.region_id;
      });
    });
  }
  public getDifferenceWhenRemoving(a: any[], b: any[]): any {
    return a.filter((object1) => {
      return !b.some((object2): boolean => {
        return object2.value === object1.value.region_id;
      });
    });
  }
  public onSelectOrganization(value: any): void {
    this.availabilityForm.get('organizations')?.setValue(value);
  }
  public triggersChange(value: any): void {
    this.freePeriodForm.get('triggers')?.setValue(value);
  }

  public onSelectDiscAccountType(value: any, index: number): void {
    this.getFormControlFromArray('account_types', index)?.setValue(value);
  }

  public onSelectDiscRegion(value: any, index: number): void {
    this.getFormControlFromArray('regions', index)?.setValue(value);
  }

  public onSelectDiscOrganization(value: any, index: number): void {
    this.getFormControlFromArray('organizations', index)?.setValue(value);
  }
  public get prices(): UntypedFormArray {
    return this.pricesForm.get('prices') as UntypedFormArray;
  }
  public addPriceField(region: any, priceValue?: number): void {
    const price: UntypedFormGroup = this.fb.group({
      price: new UntypedFormControl(priceValue ? priceValue : '', Validators.required),
      region_name: new UntypedFormControl(region.name),
      region_id: new UntypedFormControl(region.value),
    });
    this.prices.push(price);
  }

  public removePriceField(region: any): void {
    this.prices.removeAt(
      this.prices.value.findIndex((price: any): boolean => price.region_id === region)
    );
  }

  public get features(): UntypedFormArray {
    return this.detailsForm.get('features') as UntypedFormArray;
  }

  public addFeatureField(): void {
    const feature: UntypedFormGroup = this.fb.group({
      feature: new UntypedFormControl('', [Validators.required]),
    });
    this.features.push(feature);
  }

  public removeFeature(index: number): void {
    if (this.features.length > 1) this.features.removeAt(index);
    else this.features.patchValue([{ phoneNo: null, emailAddr: null }]);
  }

  public get discounts(): UntypedFormArray {
    return this.discountsForm.get('discounts') as UntypedFormArray;
  }

  public removeDiscountField(index: number): void {
    this.discounts.removeAt(index);
  }
  addDiscountForm() {
    const discount: UntypedFormGroup = this.fb.group({
      discount_value: new UntypedFormControl('', [Validators.required]),
      discount_type: new UntypedFormControl(1, [Validators.required]),
      account_types: new UntypedFormControl([]),
      regions: new UntypedFormControl([]),
      organizations: new UntypedFormControl([], [Validators.required]),
      valid_until: new UntypedFormControl('', [Validators.required]),
    });
    this.discounts.push(discount);
  }

  public get ratios(): UntypedFormArray {
    return this.freePeriodForm.get('ratios') as UntypedFormArray;
  }

  public removeRatio(index: number): void {
    this.ratios.removeAt(index);
  }
  addRatioForm() {
    const ratio: UntypedFormGroup = this.fb.group({
      item1: new UntypedFormControl('', Validators.required),
      item2: new UntypedFormControl('', Validators.required),
      item1_quantity: new UntypedFormControl('', Validators.required),
      period_value: new UntypedFormControl('', Validators.required),
      period_type: new UntypedFormControl('', Validators.required),
      item2_quantity: new UntypedFormControl(1),
    });
    this.ratios.push(ratio);
  }

  public save(): void {
    const isRatioValid: boolean = this.isRatioFormValid();
    if (this.infoForm.valid && this.detailsForm.valid && this.discountsForm.valid && this.freePeriodForm.valid && isRatioValid) {
      const product: ProductDto = new ProductDto();
      product.name = this.infoForm.get('name')?.value;
      product.is_publish = this.infoForm.get('is_publish')?.value;
      product.description = this.detailsForm.get('description')?.value;
      product.features = this.detailsForm.get('features')?.value.map((feature: any) => feature.feature).join('|');
      product.plan_bill_at = this.detailsForm.get('plan_bill_at')?.value;
      product.is_show_setting = this.detailsForm.get('is_show_setting')?.value;
      product.plans = this.prepareArraysForSave(this.detailsForm, 'plans');
      product.account_types = this.prepareArraysForSave(this.availabilityForm, 'account_types');
      product.regions = this.prepareArraysForSave(this.availabilityForm, 'regions');
      product.organizations = this.prepareArraysForSave(this.availabilityForm, 'organizations');
      product.right_management = JSON.stringify(this.detailsForm.get('rightManagement')?.value);
      product.duration = this.freePeriodForm.get('duration')?.value;
      product.triggers = this.freePeriodForm.get('triggers')?.value ? this.freePeriodForm.get('triggers')?.value.map((trigger: any) => trigger.id).join('|') : '';
      product.ratios = this.freePeriodForm.get('ratios')?.value;
      product.discounts = this.discountsForm
        .get('discounts')
        ?.value?.map((discount: any) => {
          discount.account_types = discount?.account_types?.map(
            (account_type: any) => account_type.value
          );
          discount.regions = discount.regions?.map(
            (region: any) => region.value
          );
          discount.organizations = discount.organizations?.map(
            (organization: any) => organization.value
          );

          return discount;
        });
      product.prices = this.pricesForm
        .get('prices')
        ?.value.map((price: any) => {
          return {
            region: price.region_id,
            price: price.price,
          };
        });
      product.max_athlete = this.freePeriodForm.get('Athlete')?.value;
      product.max_user = this.freePeriodForm.get('User')?.value;
      product.max_test_virtual = this.freePeriodForm.get('Virtual')?.value;
      product.max_test_result = this.freePeriodForm.get('Result')?.value;
      product.max_test_map = this.freePeriodForm.get('PPD')?.value;
      product.max_test_la = this.freePeriodForm.get('Lactate')?.value;
      product.max_test_manual = this.freePeriodForm.get('Manual')?.value;
      product.max_test_event = this.freePeriodForm.get('Event')?.value;

      if (this.data.isEdit) {
        this.productService
          .update(this.data.product.id, product)
          .subscribe((): void => {
            this.dialogRef.close(true);
          });
      } else {
        this.apiService
          .post('products/', product)
          .subscribe(() => this.dialogRef.close(true));
      }
    } else {
      this.infoForm.markAllAsTouched();
      this.detailsForm.markAllAsTouched();
      this.availabilityForm.markAllAsTouched();
      this.discountsForm.markAllAsTouched();
      this.pricesForm.markAllAsTouched();
      this.freePeriodForm.markAllAsTouched();

      const message: string = !isRatioValid
        ? 'Ratio section is invalid '
        : 'Please fill all required fileds (name, description, features, duration, triggers, discounts, prices, ratios)';

      this.snackBar.open(message, 'OK', this.constant.TOAST_CONFIG.ERROR);
    }
  }
  public isRatioFormValid(): boolean {
    let ratioId: number = 0;
    let ratioQuantity: number = 0;
    let isValid: boolean[] = [];
    this.freePeriodForm.get('ratios')?.value.map((ratio: any): void => {
      ratioId = ratio.item1;
      ratioQuantity = ratio.item1_quantity;
      this.items.map((item: any) => {
        if (item.id === ratioId && this.freePeriodForm.get(item.name)?.value < ratioQuantity) {
          isValid.push(false);
        }
        isValid.push(true);
      });
    });

    return isValid.every(Boolean);
  }

  public openCloseRights(event: any): void {
    this.detailsForm.patchValue({ is_show_setting: event });

    this.showRightManagement = !!this.detailsForm.get('is_show_setting')?.value;
  }

  public changedValueOfRadioButton(path: string, event: any): void {
    this.rights[path] = event.value;
    this.detailsForm.get('rightManagement')?.setValue(this.rights);
  }

  public isRadioChecked(path: string, value: any): boolean {
    return this.rights[path] === value;
  }

  public prepareArraysForSave(form: UntypedFormGroup, fieldName: string) {
    if (form.get(fieldName)?.value) {
      return form.get(fieldName)?.value.map((item: any) => item.value);
    } else {
      return [];
    }
  }

public  close(): void {
    this.dialogRef.close(false);
  }
}
