<form autocomplete="off" [formGroup]="accountTypeForm" class="createNew">
  <div>
    <div class="">
      <h1 mat-dialog-title id="">Add Account Type</h1>
    </div>
    <div class="form-group row">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Name of account (*)</mat-label>
          <input matInput formControlName="name" />
          <mat-error> </mat-error>
          <mat-error
            *ngIf="
              accountTypeForm.get('name')?.hasError('required') &&
              accountTypeForm.controls.name.touched
            "
          >
            Please complete field
          </mat-error>
        </mat-form-field>
      </div>
      <div class="multiple-select">
        <div class="theme-switcher-container">
          Active
          <mat-slide-toggle formControlName="is_active"
            >&nbsp;</mat-slide-toggle
          >
        </div>
        <mat-error
          *ngIf="accountTypeForm.get('is_active')?.hasError('required')"
        >
          Please complete field
        </mat-error>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <div class="mt-3">
        <button mat-raised-button (click)="closeDialog()">Cancel</button>
        <button mat-raised-button color="primary" (click)="saveAction()">
          Save
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
