<h4 mat-dialog-title>Manual Entry VO<sub>2 max</sub></h4>
<h2 style="text-align: center">VO<sub>2 max</sub> Manual Entry</h2>
<mat-dialog-content>
  <div [formGroup]="form" class="form-box">
    <div class="form-row">
      <p style="width: 80px">VO<sub>2 max</sub></p>
      <mat-form-field
        style="width: 50%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>VO<sub>2 max</sub></mat-label>
        <input
          matInput
          type="number"
          min="1"
          max="250"
          formControlName="vo2_max"
        />
        <mat-error
          *ngIf="form.get('vo2_max')?.errors && form.get('vo2_max')?.touched"
          >VO<sub>2 max</sub> must be between 1 and 250
        </mat-error>
      </mat-form-field>
      <p>(ml/min)kg<sup>-1</sup></p>
    </div>
    <div class="form-row">
      <p style="width: 80px">Tolerance</p>
      <mat-form-field
        style="width: 50%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Tolerance</mat-label>
        <input
          matInput
          type="number"
          min="0"
          max="100"
          formControlName="tolerance"
        />
        <mat-error
          *ngIf="
            form.get('tolerance')?.errors && form.get('tolerance')?.touched
          "
          >Tolerance must be between 0 and 100
        </mat-error>
      </mat-form-field>
      <p>%</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">OK</button>
  </div>
</mat-dialog-actions>
