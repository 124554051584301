import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { OrganizationDefaultTextDto, OrganizationDto, OrganizationResultDto } from './organization-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from '@shared/models/api-request';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSettingService extends ServiceBase {
  public getStatistics(): Observable<OrganizationResultDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATION_SETTING.STATISTICS;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new OrganizationResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: OrganizationDto) =>
            new OrganizationDto().deserialize(item)
          ),
        });
      })
    );
  }

  public getDefaultText(): Observable<OrganizationDefaultTextDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATION_SETTING.DEFAULT_TEXT;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return res.results[0] as OrganizationDefaultTextDto;
      })
    );
  }

  public updateDefaultText(input: OrganizationDefaultTextDto): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.ORGANIZATION_SETTING.UPDATE_DEFAULT_TEXT, input.id);
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    Object.assign(body, input);

    return this._apiService.put(apiRequest.apiUrl, apiRequest.body);
  }
}
