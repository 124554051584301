<div class="payment-link-dialog">
  <h3>Purchase link</h3>
  <div class="payment-link-generate">
    <button *ngIf="!paymentLink?.url" [disabled]="!canGenerateLink()" id="generatePaymentLink" mat-raised-button color="primary" (click)="generatePaymentLink()">
      Generate Link
    </button>
    <code *ngIf="paymentLink?.url">{{paymentLink.url}}</code>
  </div>
  <div class="payment-link-form">
    <form [formGroup]="paymentLinkForm">
      <div>
        <mat-form-field floatLabel="always" appearance="outline" class="full-width">
          <mat-label>Email address</mat-label>
          <input formControlName="email" matInput />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field *ngIf="showAccountType()" floatLabel="always" appearance="outline" class="full-width">
          <mat-label>Account Type</mat-label>
          <mat-select placeholder="Select an account type to enable link generation" formControlName="accountType">
            <mat-option *ngFor="let item of accountTypeOptions" [value]="item.id">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <mat-dialog-actions align="end">
    <div>
      <button id="cancelButton" mat-button (click)="cancel()" type="button">
        Cancel
      </button>
      <button [disabled]="!paymentLinkForm.valid" id="cloneButton" mat-raised-button color="primary" (click)="sendEmail()">
        Send email
      </button>
    </div>
  </mat-dialog-actions>
</div>
