<div [formGroup]="fingerprintForm" class="metabolic-fingerprint o-flex u-border-radius border-color tile-color-background u-margin-top-20">
  <div class="metabolic-fingerprint__wrapper">
    <mat-icon class="metabolic-fingerprint__half-icon" svgIcon="configurator-metabolic-fingerprint"></mat-icon>
    <div class="metabolic-fingerprint__dropdown-menu-left">
      <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent metabolic-fingerprint__dropdown-menu-width metabolic-fingerprint__dashed-line">
        <mat-label>Select metric for angle 1:</mat-label>
        <mat-select formControlName="left">
          <mat-option (click)="onChangeLeft(item)" [value]="item.id" *ngFor="let item of metricOptionsConfig">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="metabolic-fingerprint__left-dashed-line metabolic-fingerprint--arrow"></div>
    </div>
    <div class="metabolic-fingerprint__dropdown-menu-center">
      <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent metabolic-fingerprint__dropdown-menu-width">
        <mat-label>Select metric for angle 2:</mat-label>
        <mat-select formControlName="center">
          <mat-option (click)="onChangeCenter(item)" [value]="item.id" *ngFor="let item of metricOptionsConfig">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="metabolic-fingerprint__center-dashed-line metabolic-fingerprint--arrow"></div>
    </div>
    <div class="metabolic-fingerprint__dropdown-menu-right">
      <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent metabolic-fingerprint__dropdown-menu-width">
        <mat-label>Select metric for angle 3:</mat-label>
        <mat-select formControlName="right">
          <mat-option (click)="onChangeRight(item)" [value]="item.id" *ngFor="let item of metricOptionsConfig">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="metabolic-fingerprint__right-dashed-line metabolic-fingerprint--arrow"></div>
      <div class="metabolic-fingerprint__right-dashed-line metabolic-fingerprint--arrow"></div>
    </div>
  </div>
</div>
