import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { IGeneralInfoList } from "@shared/interfaces/general-info-list.interface";
import { IExtendPaymentModalDialog } from "@shared/interfaces/extend-payment-modal-dialog.interface";
import { ITotalPrice } from "@shared/interfaces/total-price-dialog.interface";
import { IFeaturePrice } from "@shared/interfaces/feature-price-list.interface";
import { HelperService } from "@shared/services/helper.service";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";

@Component({
  selector: 'app-payment-summary-dialog',
  templateUrl: './payment-summary-dialog.component.html',
  styleUrls: ['./payment-summary-dialog.component.scss'],
})
export class PaymentSummaryDialogComponent implements OnInit {
  public expiresOn: number;
  public dependencyLength: number;
  public totalPrice: ITotalPrice;
  public featureListWithPrice: IFeaturePrice[];
  public copyFeatureListWithPrice: IFeaturePrice[];
  public featureDependencyList: IFeaturePrice[];
  public additionalDependencies: IFeaturePrice[] = [];

  constructor(
    public dialogRef: MatDialogRef<PaymentSummaryDialogComponent>,
    private helperService: HelperService,
    private featureStoreService: FeatureStoreService,
    @Inject(MAT_DIALOG_DATA) public data: IGeneralInfoList & IExtendPaymentModalDialog,
  ) {
    this.dependencyLength = data.dependencyLength;
    this.totalPrice = data.totalPrice;
    this.featureListWithPrice = data.featureListWithPrice;
    this.copyFeatureListWithPrice = this.helperService.makeCopy(data.featureListWithPrice);
    this.featureDependencyList = data.featureDependencyList;
  }

  public ngOnInit(): void {
    const currentDate: Date = new Date();
    const THIRTY_DAYS: number = 30;
    this.expiresOn = currentDate.setDate(currentDate.getDate() + THIRTY_DAYS);
  }

  public onAddFeatureToPrice(feature: IFeaturePrice[]): void {
    this.featureListWithPrice = [];
    this.additionalDependencies = feature;
    this.featureListWithPrice = [
      ...this.copyFeatureListWithPrice,
      ...feature,
    ];
    this.totalPrice = {
      subTotal: this.featureListWithPrice.reduce((acc: number, feature: IFeaturePrice): number => feature.price + acc, 0),
      tax: 0,
      discount: 0,
      totalAmount: this.featureListWithPrice.reduce((acc: number, feature: IFeaturePrice): number => feature.price + acc, 0),
      currentCurrencyName: this.featureStoreService.getCurrencyNameByFeature(this.featureListWithPrice),
    };
  }

  public accept(): void {
    this.dialogRef.close({featureId: this.data.featureId, additionalDependencies: this.additionalDependencies});
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
