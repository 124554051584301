import { Component, DestroyRef, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { AppConstants } from '@core/constants';
import { OrganizationDefaultTextDto } from '@core/services/organization/organization-dto';
import { OrganizationSettingService } from '@core/services/organization/organization-setting.service';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UrlBuilderService } from "@shared/services/url-builder.service";

@Component({
  selector: 'app-org-tab-description-text',
  templateUrl: './org-tab-description-text.component.html',
  styleUrls: ['./org-tab-description-text.component.scss'],
})
export class OrgTabDescriptionTextComponent extends AppBaseComponent {
  public data: OrganizationDefaultTextDto = {} as OrganizationDefaultTextDto;
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public constants = AppConstants;
  public tabIndexToReturn: number | undefined = undefined;
  public customUrl: string;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly snackBar: MatSnackBar,
    private readonly fb: UntypedFormBuilder,
    private urlBuilder: UrlBuilderService,
    private router: Router,
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForm();

    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params: Params): void => {
        this.setTabIndexToReturn(params.tab_index_to_return);
        this.customUrl = this.urlBuilder.getCreatedCustomUrl('/home/my-inscyd', 'tab_index_to_return', this.tabIndexToReturn);
      });

    this.organizationSettingService.getDefaultText().subscribe(
      (res: OrganizationDefaultTextDto): void => {
        this.data = res;
        this.initValue();
      },
      (error): void => {
        this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    );
  }

  public cancel(): void {
    this.router.navigateByUrl(this.customUrl);
  }

  public setTabIndexToReturn(returnIndex: number): void {
    this.tabIndexToReturn = returnIndex;
  }

  private initForm(): void {
    this.form = this.fb.group({
      body_composition: new UntypedFormControl(''),
      introduction: new UntypedFormControl(''),
      metabolic_capacities: new UntypedFormControl(''),
      metabolic_power: new UntypedFormControl(''),
      metabolic_fingerprint: new UntypedFormControl(''),
      metabolic_profile: new UntypedFormControl(''),
      performance_development: new UntypedFormControl(''),
      test_data: new UntypedFormControl(''),
      training_zones: new UntypedFormControl(''),
      heart_rate: new UntypedFormControl(''),
      economy: new UntypedFormControl(''),
      test_data_tables: new UntypedFormControl(''),
    });
  }

  private initValue(): void {
    if (this.data) {
      const {
        body_composition,
        introduction,
        metabolic_capacities,
        metabolic_fingerprint,
        metabolic_profile,
        performance_development,
        test_data,
        training_zones,
        heart_rate,
        economy,
        metabolic_power,
        test_data_tables,
      } = this.data;

      this.form.patchValue({
        body_composition,
        introduction,
        metabolic_capacities,
        metabolic_fingerprint,
        metabolic_profile,
        performance_development,
        test_data,
        training_zones,
        heart_rate,
        economy,
        metabolic_power,
        test_data_tables,
      });
    }
  }

  public save(): void {
    const {
      body_composition,
      introduction,
      metabolic_capacities,
      metabolic_fingerprint,
      metabolic_profile,
      performance_development,
      test_data,
      training_zones,
      heart_rate,
      economy,
      metabolic_power,
      test_data_tables,
    } = this.form.value;

    this.data = {
      ...this.data,
      body_composition,
      introduction,
      metabolic_capacities,
      metabolic_fingerprint,
      metabolic_profile,
      performance_development,
      test_data,
      training_zones,
      heart_rate,
      economy,
      metabolic_power,
      test_data_tables,
    } as OrganizationDefaultTextDto;
    this.organizationSettingService
      .updateDefaultText(this.data)
      .subscribe({
        next: (): void => {
          this.isDataLoading = false;
          this.snackBar.open('Item updated successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        },
        error: (error: any): void => {
          this.isDataLoading = false;
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      });
  }
}
