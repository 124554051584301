import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder,UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { ITagsDetail } from '@core/services/tags-management/tags-management-dto';
import { TagsManagementService } from '@core/services/tags-management/tags-management.service';

@Component({
  selector: 'app-tag-dialog',
  templateUrl: './tag-dialog.component.html',
})
export class TagDialogComponent extends AppBaseComponent {
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public currentTag: ITagsDetail = {};

  constructor(
    private readonly tagsManagementService: TagsManagementService,
    public dialogRef: MatDialogRef<TagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly fb: UntypedFormBuilder
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForm();
    this.initValue();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    });
  }

  private initValue(): void {
    if (this.data.id) {
      this.tagsManagementService.getById(this.data.id).subscribe((res: ITagsDetail): void => {
        if (res) {
          this.currentTag = res;
          this.form.patchValue({
            name: res.name,
          });
        }
      });
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    if (this.form.valid) {
      this.currentTag.name = this.form.value.name;
      if (this.data.id) {
        this.tagsManagementService.update(this.currentTag).subscribe((res) => {
          this.dialogRef.close(res);
        });
      } else {
        const input = {
          name: this.form.value.name,
          organization: this.auth?.organization?.id,
        };
        this.tagsManagementService.create(input).subscribe((res: Object): void => {
          this.dialogRef.close(res);
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }
}
