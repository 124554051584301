<div class="reference-link">
  <h1>{{config.title}}</h1>
  <div class="reference-link__link-container">
    <ng-container *ngFor="let reference of config.referenceArray">
      <div class="reference-link__link-item u-padding-top-bottom-8">
        <a class="u-color-blue" target="_blank" [href]="reference.url">{{reference.name}}</a>
      </div>
    </ng-container>
  </div>
</div>
