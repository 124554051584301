import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { finalize } from 'rxjs/operators';
import { AppConstants } from '@core/constants';
import { CourseDto } from '@core/services/courses/course-dto';
import { CoursesService } from "@core/services/courses/courses.service";
import { EditCoursesDialogComponent } from './edit-courses-dialog/edit-courses-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
})
export class CoursesComponent extends PagedListingBaseComponent<CourseDto> {
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: CourseDto[] = [];
  public displayedColumns: string[] = ['id', 'name', 'published', 'action'];

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }
  protected list( request: PagedAndSortedRequestDto, pageNumber: number, finishedCallback: Function): void {
    request.pageIndex = pageNumber;
    this.coursesService
      .list(request)
      .pipe(finalize(() => finishedCallback()))
      .subscribe((res: any): void => {
        this.dataSource = res.items;
        this.totalItems = res.totalCount;
      });
  }

  constructor(
    private coursesService: CoursesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  public editCourse(course: any): void {
    this.openDialog(true, course);
  }

  private openDialog(isEdit: boolean, course?: CourseDto): void {
    const dialogRef: MatDialogRef<EditCoursesDialogComponent> = this.dialog.open(EditCoursesDialogComponent, {
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { course: course, courseList: this.dataSource, isEdit },
    });

    dialogRef.afterClosed().subscribe((res): void => {
      if (res) {
        let message: string = 'Course has been updated successfully';
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.refresh();
      }
    });
  }
}
