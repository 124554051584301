<app-loader-indicator></app-loader-indicator>
<h1 mat-dialog-title>Payment Summary</h1>
<div mat-dialog-content>
  <app-general-info-list
    *ngIf="purchaseInfo$ | async as purchaseInfo"
    [isPaymentView]="true"
    [config]="getGeneralInfoConfig(purchaseInfo)"
  ></app-general-info-list>
  <div class="line-height-28">
    You are activating the <span class="u-color-red-faded">{{ (purchaseInfo$ | async)?.name }}</span> feature
    <span *ngIf="purchaseInfo$ | async as purchaseInfo">{{ getDependenciesText(purchaseInfo) }}</span>
  </div>
  <div class="line-height-28">
    <span>You may cancel the subscription anytime in the my subscription page.</span>
  </div>
  <div class="line-height-28">
    <span>The free trial expires on
      <span class="u-color-red-faded">{{ (purchaseInfo$ | async)?.free_trial_expiry_date | date: 'dd MMM, YYYY' }}</span>
    </span>
  </div>
  <div *ngIf="purchaseInfo$ | async as purchaseInfo">
    <ng-container *ngIf="showFeatureMultiSelect(purchaseInfo)">
      <app-feature-multi-select (addFeatureToPrice)="onAddFeatureToPrice($event, purchaseInfo)" [config]="getFeatureDependencyList(purchaseInfo)"></app-feature-multi-select>
    </ng-container>
  </div>
  <div *ngIf="purchaseInfo$ | async as purchaseInfo">
    <app-feature-price-list [title]="'After that you will be charged:'" [config]="getFeatureListWithPrice(purchaseInfo)"></app-feature-price-list>
  </div>
  <div *ngIf="purchaseInfo$ | async as purchaseInfo">
    <app-total-price [config]="getTotalPrice(purchaseInfo)"></app-total-price>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="accept()">Accept</button>
</mat-dialog-actions>
