import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class FilesResultDto extends PagedResultDto {
  public items: FileDto[] = [];
}

export class FileDto extends Deserializable {
  public ascent: number | undefined;
  public athlete:
    | {
        coaches: Array<any> | undefined;
        display_id: number | undefined;
        first_name: string | undefined;
        gender: string | undefined;
        id: number | undefined;
        last_name: string | undefined;
        photo: string | undefined;
      }
    | undefined;
  public cad: number | undefined;
  public comment: string | undefined;
  public competition: string | undefined;
  public created_at: string | undefined;
  public created_by: string | undefined;
  public date: string | undefined;
  public display_id: number | undefined;
  public dist: number | undefined;
  public energy: number | undefined;
  public file_name: string | undefined;
  public hr: number | undefined;
  public id: number | undefined;
  public initials: string | undefined;
  public is_delete: boolean | undefined;
  public organization: number | undefined;
  public power: number | undefined;
  public speed: number | undefined;
  public team: string | undefined;
  public time: any;
  public type: number | undefined;
  public updated_at: string | undefined;
  public weight: number | undefined;
}
