import { Injectable } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Subject } from "rxjs";

@Injectable()
export class RightSideNavService {
  public $rightSideNav: Subject<any> = new Subject<any>();
  public rightSideNavRef: MatSidenav | undefined;

  public setRightSideNav(navSide: MatSidenav): void {
    this.$rightSideNav.next(navSide);
    this.rightSideNavRef = navSide;
  }

  public getRightSideNav(): Subject<MatSidenav> {
    return this.$rightSideNav;
  }
}
