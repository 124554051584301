import { Component, DestroyRef, inject } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { catchError, finalize } from 'rxjs/operators';
import { AthleteTrainingLevelDto, AthleteTrainingLevelResultDto } from '@core/services/athletes-management/athletes-training-level-dto';
import { AthletesService } from '@core/services/athletes-management/athletes.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UrlBuilderService } from "@shared/services/url-builder.service";

@Component({
  selector: 'app-custom-athlete-category',
  templateUrl: './custom-athlete-category.component.html',
})
export class CustomAthleteCategoryComponent extends AppBaseComponent {
  private fb: FormBuilder = inject(FormBuilder);
  private athletesService: AthletesService = inject(AthletesService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private urlBuilder: UrlBuilderService = inject(UrlBuilderService);
  private router: Router = inject(Router);

  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public listTrainingLevel: Array<AthleteTrainingLevelDto> = [];
  public tabIndexToReturn: number = 0;
  public customUrl: string;
  public tags: string[] = ['General'];

  constructor() {
    super();
  }

  public onInitPage(): void {
    this.isDataLoading = true;

    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params: Params): void => {
        this.setTabIndexToReturn(params.tab_index_to_return);
        this.customUrl = this.urlBuilder.getCreatedCustomUrl('/home/my-inscyd', 'tab_index_to_return', this.tabIndexToReturn);
      });

    this.athletesService.getAthleteTrainingLevel()
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        }),
        finalize((): boolean => (this.isDataLoading = false))
      )
      .subscribe((res: AthleteTrainingLevelResultDto): void => {
        this.listTrainingLevel = res.items;
        this.initValue();
      });
    this.initForm();
  }

  public setTabIndexToReturn(returnIndex: number): void {
    this.tabIndexToReturn = returnIndex;
  }

  private initForm(): void {
    this.form = this.fb.group({
      custom1: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom2: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom3: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom4: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom5: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom6: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
    });
  }

  private initValue(): void {
    const levelCustom: AthleteTrainingLevelDto[] = this.listTrainingLevel.filter(
      (item: AthleteTrainingLevelDto) => !item.is_default_level
    );
    this.form.patchValue({
      custom1: levelCustom[0]?.value,
      custom2: levelCustom[1]?.value,
      custom3: levelCustom[2]?.value,
      custom4: levelCustom[3]?.value,
      custom5: levelCustom[4]?.value,
      custom6: levelCustom[5]?.value,
    });
  }

  public save(): void {
    if (this.form.valid) {
      const input = this.listTrainingLevel
        .filter((item: AthleteTrainingLevelDto) => !item.is_default_level)
        .map((category: AthleteTrainingLevelDto, index: number) => {
          return {
            id: category.id,
            value: this.form.value[`custom${index + 1}`],
          } as { id: number; value: string };
        });
      this.isDataLoading = true;
      this.athletesService.updateAthleteTrainingLevel(input)
        .pipe(
          catchError((error) => {
            this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);

            throw error;
          }),
          finalize((): boolean => (this.isDataLoading = false))
        )
        .subscribe((): void => {
          this.snackBar.open('Items updated successfully', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
        });
    } else {
      this.form.markAllAsTouched();
    }
  }

  public back(): void {
    this.router.navigateByUrl(this.customUrl);
  }
}

