<div class="o-flex o-flex--column u-padding-30">
  <div class="o-flex o-flex--justify-space-between u-margin-bottom-20">
    <span class="u-color-grey-light u-font-size-16 line-height-16 u-font-weight-700">Subtotal:</span>
    <span class="u-font-size-16 line-height-16 u-font-weight-700">{{config.currentCurrencyName}}{{config.subTotal}}</span>
  </div>
  <div class="o-flex o-flex--justify-space-between u-margin-bottom-20">
    <span class="u-color-grey-light u-font-size-16 line-height-16 u-font-weight-700">Tax:</span>
    <span class="u-font-size-16 line-height-16 u-font-weight-700">{{config.currentCurrencyName}}{{config.tax}}</span>
  </div>
  <div class="o-flex o-flex--justify-space-between u-margin-bottom-20">
    <span class="u-color-grey-light u-font-size-16 line-height-16 u-font-weight-700">Discount:</span>
    <span class="u-font-size-16 line-height-16 u-font-weight-700">{{config.currentCurrencyName}}{{config.discount}}</span>
  </div>
  <div class="o-flex o-flex--justify-space-between u-margin-top-10">
    <span class="u-color-grey-light u-font-size-24 line-height-28 u-font-weight-400">Total Amount:</span>
    <span class="u-font-size-24 line-height-28 u-font-weight-400">{{config.currentCurrencyName}} {{config.totalAmount}}</span>
  </div>
</div>
