<mat-toolbar class="border-color">
  <div class="app-logo-container border-color">
    <img *ngIf="!isDarkMode"
         src="../assets/images/app-logo-header.png"
         [style.height.px]="20"
         alt="logo">
    <img *ngIf="isDarkMode"
         src="../assets/images/app-logo-header-dark-theme.png"
         [style.height.px]="20"
         alt="logo">
  </div>
  <span>&nbsp;</span><span>&nbsp;</span>
  <div class="header-menu">
    <div class="header-left">
      <button mat-icon-button
              (click)="toggleMenuBar()">
        <mat-icon>menu</mat-icon>
      </button>

      <div class="theme-switcher-container">
        <mat-icon mat-list-icon>light_mode</mat-icon>&nbsp;
        <mat-slide-toggle [formControl]="themeSwitcher"
                          (change)="changeTheme()">&nbsp;</mat-slide-toggle>
        <mat-icon mat-list-icon>dark_mode</mat-icon>
      </div>

    </div>
    <div class="header-right">
      <!-- Create New -->
      <button *ngIf="isShowCreateButton()"
              mat-raised-button
              [matMenuTriggerFor]="menu"
              class="create-new-button">
        <span>CREATE NEW <mat-icon>add_circle_outline</mat-icon></span>
      </button>

      <mat-menu #menu="matMenu">
        <a *ngIf="(!auth.role.is_owner) && rights.athletes_create"
           mat-menu-item
           (click)="openAthleteModalWindow()">
          <span>Athlete</span>
        </a>
        <a *ngIf="rights.teams_list && (!auth.role.is_owner) && (!auth.role.is_agent_role)"
           mat-menu-item
          (click)="openTeamModalWindow()">
          <span>Team</span>
        </a>
        <a *ngIf="rights.dynamic_simulation_event_list && (!auth.role.is_owner) && (!auth.role.is_agent_role)"
          mat-menu-item
           (click)="openEventModalWindow()">
          <span>Event</span>
        </a>
        <a mat-menu-item *ngIf="(!auth.role.is_owner && (rights.test_create_critical_power_test || rights.test_create_virtual
        || rights.test_manual_test || rights.test_create_lactate))"
           [matMenuTriggerFor]="test">Test</a>
      </mat-menu>
      <mat-menu #test="matMenu">
        <a *ngIf="rights.test_create_critical_power_test"
           mat-menu-item
           (click)="openPPDTest()">PPD</a>
        <a [disabled]="!currentTestId"
           *ngIf="rights.test_create_virtual"
           mat-menu-item
          (click)="openVirtualTest()">Virtual</a>
        <a *ngIf="rights.test_manual_test"
           mat-menu-item
           (click)="openManualTest()">Manual</a>
        <a *ngIf="rights.test_create_lactate"
           mat-menu-item
           (click)="openLactateTest()">Lactate</a>
      </mat-menu>

      <!-- Settings -->
      <button mat-icon-button
              (click)="openHelpCenterLink()"
              matTooltip="Help Center" >
        <mat-icon>help_outline</mat-icon>
      </button>

      <!-- Logout -->
      <button mat-icon-button
              (click)="logout()"
              matTooltip="Logout">
        <mat-icon>power_settings_new</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
