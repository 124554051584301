import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-device-management-overview',
  templateUrl: './device-management-overview.component.html',
  styleUrls: ['./device-management-overview.component.scss']
})
export class DeviceManagementOverviewComponent {
  @Input() public maximumDeviceNumber: number = 0;
  @Input() public currentUsedDeviceNumber: number = 0;
}
