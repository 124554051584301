import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { AccountTypeDialogComponent } from '@shared/dialogs/account-type-dialog/account-type-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { finalize } from 'rxjs/operators';
import { AppConstants } from '@core/constants';
import { AccountTypeDto, AccountTypeResultDto } from '@core/services/account-types/account-type-dto';
import { AccountTypesService } from '@core/services/account-types/account-types.service';
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-account-types',
  templateUrl: './account-types.component.html',
})
export class AccountTypesComponent extends PagedListingBaseComponent<AccountTypeDto> {
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public searchText: string = '';
  public displayedColumns: string[] = ['id', 'name', 'status', 'action'];
  public dataSource: AccountTypeDto[] = [];

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }
  protected list(request: PagedRequestDto, pageNumber: number, finishedCallback: Function): void {
    request.pageIndex = pageNumber;
    this.accountTypesService
      .list(request)
      .pipe(
        finalize(() => finishedCallback()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res: AccountTypeResultDto): void => {
        this.dataSource = res.items;
        this.totalItems = res.totalCount;
      });
  }

  constructor(
    private accountTypesService: AccountTypesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    super();
  }

  public deleteAccount(id: number): void {
    const message: string = 'Are you sure you want to delete this account type?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result): void => {
      if (result) {
        if (id) {
          this.accountTypesService.delete(id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            });
        }
      }
    });
  }

  public addAccount(): void {
    this.openDialog(false);
  }
  public editAccount(accountType: AccountTypeDto): void {
    this.openDialog(true, accountType);
  }

  public openDialog(isEdit: boolean, accountType?: AccountTypeDto): void {
    const dialogRef: MatDialogRef<AccountTypeDialogComponent> = this.dialog.open(AccountTypeDialogComponent, {
      width: '600px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { isEdit, accountType },
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
      if (res) {
        let message: string = 'Item created successfully';
        if (isEdit) {
          message = 'Item updated successfully';
        }
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.refresh();
      }
    });
  }
}
