import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { Clipboard } from '@angular/cdk/clipboard';
import countries from '@shared/json/countries.json';
import { AppConstants } from '@core/constants';
import { ManageOrganizationsService } from '@core/services/manage-organizations/manage-organizations.service';
import { ApiService } from '@core/services/api.service';
import { HelperService } from '@shared/services/helper.service';
import { String } from 'typescript-string-operations';
import { ApiInfoResultDto, ManageOrganizationDto } from '@core/services/manage-organizations/manage-organization-dto';
import { AccountTypeResultDto } from "@core/services/account-types/account-type-dto";
import { HeadResultDto } from "@core/services/manage-organizations/head-dto";

export interface IKey {
  id: string;
  key: string;
  name: string;
  revoked: boolean;
}
@Component({
  selector: 'app-manage-organizations-dialog',
  templateUrl: './manage-organizations-dialog.component.html',
  styleUrls: ['./manage-organizations-dialog.component.scss'],
})
export class ManageOrganizationsDialogComponent extends AppBaseComponent {
  public apiOptions: Array<any> = [];
  public accountTypes: Array<any> = [];
  public heads: Array<any> = [];
  public countries: Array<any> = [];
  public invoice_countries: Array<any> = [];
  public isChecked: boolean = false;
  public key: string = '';
  public orgForm: UntypedFormGroup = this.fb.group({});
  public formArray: UntypedFormArray = this.fb.array([]);
  public enabled_keys: any[] = [];
  public imgFile: any;

  constructor(
    public dialogRef: MatDialogRef<ManageOrganizationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly snackBar: MatSnackBar,
    private organizationService: ManageOrganizationsService,
    private fb: UntypedFormBuilder,
    private clipboard: Clipboard,
    private apiService: ApiService,
    private readonly cdr: ChangeDetectorRef,
    private helperService: HelperService
  ) {
    super();
  }

  public onInitPage(): void {
    this.countries = countries;
    this.invoice_countries = countries;
    this.loadApisInfo();
    this.loadAccountTypes();

    if (this.data.isEdit) {
      this.loadHeads();
    }

    this.initForm();

    if (this.data.organizationDto?.is_use_invoice_address == true) {
      this.isChecked = true;
      this.setSameValues();
    } else {
      this.isChecked = false;
    }
  }

  public initForm(): void {
    if (!this.data.isEdit) {
      this.orgForm = this.fb.group({
        photo: new UntypedFormControl(''),
        name: new UntypedFormControl('', Validators.required),
        max_devices: new UntypedFormControl(0),
        address_street_name: new UntypedFormControl(''),
        address_street_number: new UntypedFormControl(''),
        address_zip: new UntypedFormControl(''),
        address_city: new UntypedFormControl(''),
        address_state: new UntypedFormControl(''),
        vat_number: new UntypedFormControl(''),
        country: new UntypedFormControl('', Validators.required),
        is_use_invoice_address: new UntypedFormControl(false),
        invoice_org_name: new UntypedFormControl('', Validators.required),
        invoice_address_street_name: new UntypedFormControl(''),
        invoice_address_street_number: new UntypedFormControl(''),
        invoice_address_zip: new UntypedFormControl(''),
        invoice_address_city: new UntypedFormControl(''),
        invoice_address_state: new UntypedFormControl(''),
        invoice_vat_number: new UntypedFormControl(''),
        invoice_country: new UntypedFormControl('', Validators.required),
        phone: new UntypedFormControl(''),
        phone2: new UntypedFormControl(''),
        fax: new UntypedFormControl(''),
        email: new UntypedFormControl('', [Validators.email, Validators.required]),
        email2: new UntypedFormControl(''),
        website: new UntypedFormControl(''),
        facebook: new UntypedFormControl(''),
        instagram: new UntypedFormControl(''),
        is_active: new UntypedFormControl(false),
        account_type: new UntypedFormControl(''),
        is_solvent: new UntypedFormControl(false),
        admin_username: new UntypedFormControl(''),
        admin_email: new UntypedFormControl(''),
        api: new UntypedFormControl([]),
        api_keys: this.fb.array([]),
        address_country_id: new UntypedFormControl(''),
      });
    } else {
      const photo: string = this.data.organizationDto?.photo ? this.helperService.replaceCorrectPhotoURL(this.data.organizationDto?.photo) : '';
      this.setKeys();
      this.orgForm = this.fb.group({
        photo: new UntypedFormControl(),
        address_country_id: new UntypedFormControl(''),
        country: new UntypedFormControl(this.data.organizationDto.country.id, Validators.required),
        invoice_country: new UntypedFormControl(this.data.organizationDto?.invoice_country ? this.data.organizationDto?.invoice_country.id : '', Validators.required),
        name: new UntypedFormControl(this.data.organizationDto.name, Validators.required),
        max_devices: new UntypedFormControl(this.data.organizationDto.max_devices),
        address_street_name: new UntypedFormControl(this.data.organizationDto.address_street_name),
        address_street_number: new UntypedFormControl(this.data.organizationDto.address_street_number),
        address_zip: new UntypedFormControl(this.data.organizationDto.address_zip),
        address_city: new UntypedFormControl(this.data.organizationDto.address_city),
        address_state: new UntypedFormControl(this.data.organizationDto.address_state),
        vat_number: new UntypedFormControl(this.data.organizationDto.vat_number),
        is_use_invoice_address: new UntypedFormControl(this.data.organizationDto.is_use_invoice_address),
        invoice_org_name: new UntypedFormControl(this.data.organizationDto.invoice_org_name, Validators.required),
        invoice_address_street_name: new UntypedFormControl(this.data.organizationDto.invoice_address_street_name),
        invoice_address_street_number: new UntypedFormControl(this.data.organizationDto.invoice_address_street_number),
        invoice_address_zip: new UntypedFormControl(this.data.organizationDto.invoice_address_zip),
        invoice_address_city: new UntypedFormControl(this.data.organizationDto.invoice_address_city),
        invoice_address_state: new UntypedFormControl(this.data.organizationDto.invoice_address_state),
        invoice_vat_number: new UntypedFormControl(this.data.organizationDto.invoice_vat_number),
        phone: new UntypedFormControl(this.data.organizationDto.phone),
        phone2: new UntypedFormControl(this.data.organizationDto.phone2),
        fax: new UntypedFormControl(this.data.organizationDto.fax),
        email: new UntypedFormControl(this.data.organizationDto.email),
        email2: new UntypedFormControl(this.data.organizationDto.email2),
        website: new UntypedFormControl(this.data.organizationDto.website),
        facebook: new UntypedFormControl(this.data.organizationDto.facebook),
        instagram: new UntypedFormControl(this.data.organizationDto.instagram),
        is_active: new UntypedFormControl(this.data.organizationDto.is_active),
        account_type: new UntypedFormControl(this.data.organizationDto.account_type),
        is_solvent: new UntypedFormControl(this.data.organizationDto.is_solvent),
        head: new UntypedFormControl(this.data.organizationDto.head),
        api: new UntypedFormControl(
          this.data.organizationDto.api?.map((item: any) => {
            return {
              value: item.id,
              name: item.name,
            };
          })
        ),
        api_keys: this.formArray,
      });
      this.orgForm.patchValue({
        photo: photo,
      });
    }
  }

  public generateKey(): void {
    if (this.orgForm.get('api_keys')?.value.length >= 10) {
      this.snackBar.open('Maximum of api key is 10.', 'OK', AppConstants.TOAST_CONFIG.WARNING);

      return;
    } else {
      this.organizationService.setApiKey().subscribe((res: any): void => {
        this.key = res;
        const keyForm: UntypedFormGroup = this.fb.group({
          id: res.id,
          key: res.key,
          name: res.name,
          revoked: false,
        });
        this.keys.push(keyForm);
        this.enabled_keys.push(res);
        this.snackBar.open('Item updated successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.hideLoading();
      });
    }
  }

  public setKeys(): UntypedFormArray {
    this.data?.organizationDto.api_keys.map((res: any): void => {
      const keyForm: UntypedFormGroup = this.fb.group({
        id: res?.id,
        key: res?.key,
        name: res?.name,
        revoked: res?.revoked,
      });
      this.formArray.push(keyForm);
    });

    return this.formArray;
  }

  public updateKey(id: number): void {
    const apiUrl: string = String.Format(AppConstants.API.ORGANIZATIONS.UPDATE_KEY, id);
    this.keys.value.map((item: IKey): void => {
      let findItem = this.enabled_keys.find((itemEnable: IKey): boolean => itemEnable.id === item.id);
      if (findItem) {
        item.revoked = false;
      }
      item.revoked = true;
    });
    this.apiService.put(apiUrl, this.keys.value).subscribe();
  }

  public save(): void {
    if (this.orgForm.invalid) {
      this.orgForm.markAllAsTouched();
      this.snackBar.open('Please check the fields and try again.', 'OK', this.constant.TOAST_CONFIG.ERROR);

      return;
    }

    this.orgForm.value.photo = this.imgFile ? this.imgFile : undefined;
    this.orgForm.value.api = this.orgForm?.value?.api ? this.orgForm?.value?.api.map((a: any) => a.value) : [];
    this.orgForm.value.address_country_id = this.orgForm?.value.country;

    if (!this.data.isEdit) {
      this.organizationService.create(this.orgForm.value).subscribe(
        (res: ManageOrganizationDto): void => {
          this.updateKey(res.id);
          this.dialogRef.close(res);
        },
        (error): void => {
          if (error.status == 201) {
            this.snackBar.open('Item created successfully', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          } else {
            if (error && error.res && error.res.indexOf('Duplicate entry') > -1) {
              this.snackBar.open("Organization Admin's username already exist.", 'OK', this.constant.TOAST_CONFIG.ERROR);
            } else {
              this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
            }
          }
        }
      );
    } else {
      this.organizationService
        .update({ ...this.orgForm.value, id: this.data.organizationDto.id })
        .subscribe((res) => {
          this.updateKey(this.data.organizationDto.id);
          this.dialogRef.close(res);
        });
    }
  }

  public onChangeDefault(event: any): void {
    if (event.checked) {
      this.isChecked = true;
      this.setSameValues();
    } else {
      this.isChecked = false;
      this.setEmptyFields();
    }
  }

  public setEmptyFields(): void {
    this.orgForm.controls['invoice_org_name'].setValue('');
    this.orgForm.controls['invoice_address_street_name'].setValue('');
    this.orgForm.controls['invoice_address_street_number'].setValue('');
    this.orgForm.controls['invoice_address_zip'].setValue('');
    this.orgForm.controls['invoice_address_city'].setValue('');
    this.orgForm.controls['invoice_address_state'].setValue('');
    this.orgForm.controls['invoice_vat_number'].setValue('');
    this.orgForm.controls['invoice_country'].setValue('');
  }

  public setSameValues(): void {
    this.orgForm.controls['invoice_org_name'].setValue(
      this.orgForm.controls['name'].value
    );
    this.orgForm.controls['invoice_address_street_name'].setValue(
      this.orgForm.controls['address_street_name'].value
    );
    this.orgForm.controls['invoice_address_street_number'].setValue(
      this.orgForm.controls['address_street_number'].value
    );
    this.orgForm.controls['invoice_address_zip'].setValue(
      this.orgForm.controls['address_zip'].value
    );
    this.orgForm.controls['invoice_address_city'].setValue(
      this.orgForm.controls['address_city'].value
    );
    this.orgForm.controls['invoice_address_state'].setValue(
      this.orgForm.controls['address_state'].value
    );
    this.orgForm.controls['invoice_vat_number'].setValue(
      this.orgForm.controls['vat_number'].value
    );
    this.orgForm.controls['invoice_country'].setValue(
      this.orgForm.controls['country'].value
    );
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public get keys(): UntypedFormArray {
    return this.orgForm.get('api_keys') as UntypedFormArray;
  }

  public removeKey(index: number): void {
    this.keys.removeAt(index);
  }

  public copyKey(key: any): void {
    this.clipboard.copy(key.value.key);
  }

  public onSelectOrganization(value: any): void {
    this.orgForm.get('api')?.setValue(value);
  }

  public loadAccountTypes(): void {
    this.organizationService.getAccountTypes().subscribe((res: AccountTypeResultDto): void => {
      this.accountTypes = res.items;
    });
  }

  public loadHeads(): void {
    this.organizationService
      .getHead(this.data.organizationDto.id)
      .subscribe((res: HeadResultDto): void => {
        this.heads = res.items;
      });
  }

  public loadApisInfo(): void {
    this.organizationService.getApiInfo().subscribe((res: ApiInfoResultDto): void => {
      this.apiOptions = res.items.map((item: any) => {
        return {
          value: item.id,
          name: item.name,
        };
      });
    });
  }

  public changeImage(event: any): void {
    const files: File[] = event.target.files;
    if (files.length > 1 || !files.length) {
      event.target.value = '';
    }
    const readFile = async (file: File): Promise<void> => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.orgForm.controls.photo.setValue(e.target?.result);
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    };
    if (files.length) {
      if (files[0]?.size <= AppConstants.ORGANIZATION_PHOTO_MAX_SIZE) {
        this.imgFile = files[0];
        readFile(files[0]);
      } else {
        event.target.value = '';
        this.orgForm.controls.photo.setValue('');
        this.cdr.detectChanges();
        this.snackBar.open('Photo size must be less than 100KB', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    }
  }

  public onChangeName(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_org_name'].setValue(event.target.value);
    }
  }

  public onChangeStreet(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_street_name'].setValue(
        event.target.value
      );
    }
  }

  public onChangeNumber(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_street_number'].setValue(
        event.target.value
      );
    }
  }

  public onChangeZip(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_zip'].setValue(event.target.value);
    }
  }

  public onChangeCity(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_city'].setValue(
        event.target.value
      );
    }
  }

  public onChangeState(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_state'].setValue(
        event.target.value
      );
    }
  }

  public onChangeVat(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_vat_number'].setValue(event.target.value);
    }
  }

  public onChangeCountry(): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_country'].setValue(
        this.orgForm.controls['country'].value
      );
    }
  }
}
