import { Injectable } from "@angular/core";
import { FitParseSpeedUnit, FitParserLengthUnit, FitParserTemperatureUnit, FitParserMode } from "./fit-file-parser.enum";

declare const require: any;

@Injectable()
export class FitFileParserService {
  public parseFileBuffer(fileBuffer: any, file_name: string, athlete_id: number, weight: number, callback: any): any {
    const FitParser = require('src/assets/js/fit-parser.bundle.js').default;
    const fitObject = new FitParser({
      force: true,
      speedUnit: FitParseSpeedUnit.KilometersPerHour,
      lengthUnit: FitParserLengthUnit.Kilometer,
      temperatureUnit: FitParserTemperatureUnit.Kelvin,
      elapsedRecordField: true,
      mode: FitParserMode.Both,
    });
    fitObject.parse(fileBuffer, (error: any, data: any): void => {
      if (error) {
        return;
      }
      let items: any = [];
      let record_date = null;
      let time_zone: number = 0;
      let readFromRecords: boolean = false;
      if (data.hasOwnProperty('activity')) {
        const activity = data.activity;
        if (activity.hasOwnProperty('timestamp')) {
          const value = this.handleTimeStamp(activity);
          record_date = value.record_date;
          time_zone = value.time_zone;
        }

        if (activity.hasOwnProperty('sessions')) {
          const value = this.handelSection(
            activity,
            record_date,
            items,
            time_zone
          );
          record_date = value.record_date1;
          readFromRecords = value.readFromRecords;
        } else if (activity.hasOwnProperty('laps')) {
          // XML 4.fit and 5.fit
          ({ items, record_date } = this.parseActivityLaps(
            activity,
            time_zone
          ));
        }
      }

      if (readFromRecords && data.hasOwnProperty('records')) {
        this.mapRecordData(data.records, items, time_zone);
      }

      const model =  {
        file_name: file_name,
        initials: 'FEParser',
        athlete: athlete_id,
        weight: weight,
        date: record_date,
        time: this.last(items, 4),
        dist: this.last(items, 3),
        energy: this.sum(items, 8) / 1000,
        power: this.average(items, 8),
        hr: this.average(items, 5),
        cad: this.average(items, 2),
        speed: this.average(items, 9),
        ascent: 0,
        json_data: JSON.stringify(items),
        time_zone: time_zone,
      };
      if (model) {
        callback(model);
      }
    });
  }

  private handelSection(activity: any, record_date: any, items: any, time_zone: any) {
    let record_date1;
    let readFromRecords: boolean = false;
    const sessions = activity.sessions;
    if (sessions.length > 0) {
      const session = sessions[0];
      if (record_date == null) {
        record_date1 = session.start_time;
      } else {
        record_date1 = record_date;
      }
      if (session.hasOwnProperty('laps')) {
        const laps = session.laps;
        readFromRecords = laps.length == 0;
        for (let lap of laps) {
          if (lap.hasOwnProperty('records')) {
            const records = lap.records;
            this.mapRecordData(records, items, time_zone);
          }
        }
      }
    }
    return { record_date1, readFromRecords };
  }

  private handleTimeStamp(activity: any) {
    const record_date = activity.timestamp;
    let time_zone: number = 0;

    if (activity.hasOwnProperty('local_timestamp')) {
      if (activity.local_timestamp.getFullYear() == activity.timestamp.getFullYear()) {
        time_zone = activity.local_timestamp - activity.timestamp;
      }
    }
    return { record_date, time_zone };
  }

  private mapRecordData(origRecs: any, targetRecs: any, timeZone: number): void {
    let prevRec: any = null;
    origRecs.forEach((origRec: any): void => {
      const item = [];
      if (typeof origRec.timestamp == 'object') {
        item[0] = Math.round((origRec.timestamp.getTime() + timeZone) / 1000);
      } else {
        item[0] = Math.round(
          (new Date(origRec?.timestamp?.getTime() + timeZone) as any) / 1000
        );
      }
      item[1] = Math.round(origRec.altitude || 0); // Altitude
      item[2] = Math.round(origRec.cadence || 0); // Cadence
      item[3] = origRec.distance || 0; // Distance
      item[4] = Math.round(origRec.elapsed_time || 0); // Elapsed time
      item[5] = Math.round(origRec.heart_rate || 0); // Heart  Rate
      item[6] = origRec.position_lat || 0; // Position Lat
      item[7] = origRec.position_long || 0; // Position Long
      item[8] = Math.round(origRec.power || 0); // Power
      item[9] = origRec.speed || 0; // Speed
      item[10] = Math.round(origRec.temperature || 0); // Temperature

      // If (!records["speed"] and records["distance"]) then calculate speed
      if (
        prevRec != null &&
        prevRec[0] &&
        prevRec[3] &&
        item[0] &&
        item[3] &&
        item[9] == 0
      ) {
        const delta_time: number = item[0] - prevRec[0];
        const delta_distance: number = item[3] - prevRec[3];
        // Calculate speed in m/s
        let speed: number = (delta_distance * 1000) / delta_time;
        // Convert m/s to km/h
        speed = speed * 3.6;
        item[9] = speed;
      }
      prevRec = item;
      targetRecs.push(item);
    });
  }

  private parseActivityLaps(activity: any, timezZone: any) {
    const items: any = [];
    const laps = activity.laps;
    let record_date;
    for (let lap of laps) {
      if (lap.hasOwnProperty('records')) {
        const records = lap.records;
        this.mapRecordData(records, items, timezZone);
        if (records.length > 0) {
          if (typeof records[0].timestamp == 'object') {
            record_date = records[0].timestamp + timezZone;
          } else {
            record_date = new Date(records[0].timestamp + timezZone);
          }
        }
      }
    }
    return { items, record_date };
  }

  private average(arrayOfArrays: any, columnIndex: any): number {
    let sum: number = 0;
    arrayOfArrays.forEach((array: any) => {
      sum += array[columnIndex];
    });
    return sum / arrayOfArrays.length;
  }

  private sum(arrayOfArrays: any, columnIndex: any): number {
    let sum: number = 0;
    arrayOfArrays.forEach((array: any): void => {
      sum += array[columnIndex];
    });
    return sum;
  }

  private last(arrayOfArrays: any, columnIndex: any) {
    const array = arrayOfArrays[arrayOfArrays.length - 1];
    return array[columnIndex];
  }
}
