<div class="page-container">
  <p class="sub-title" style="margin-bottom: 0%;">HOME</p>
    <div class="title-with-search">
      <p>My Devices</p>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input
          matInput
          placeholder="Search"
          [(ngModel)]="searchText"
          (keyup.enter)="applyFilter($event)"
        />
        <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
      </mat-form-field>
    </div>
    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      style="width: 100%"
      (matSortChange)="sortData($event)"
      matSortActive="id"
      matSortDirection="desc"
      matSortDisableClear
      aria-describedby="my devices list">
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="mat-column-id"
          scope="row"
        >
          ID
        </th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <ng-container matColumnDef="organization__name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
          Organization
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.organization?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
          Device Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
      </ng-container>
      <ng-container matColumnDef="ip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
          IP Address
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.ip }}</td>
      </ng-container>
      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
          Last Activity
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.updated_at | date: constant.DATE_FORMAT.DATE_TIME_SECOND }}
        </td>
      </ng-container>
      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
          Active
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.is_active | translateBooleanText }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action" *ngIf="isHadAction">
        <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element" class="fixed-width-action">
          <button
            *ngIf="rights.admin_devices_delete"
            mat-raised-button
            class="btn-icon-text"
            (click)="deleteDevice(element.id)"
          >
            <mat-icon>delete</mat-icon> Delete
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row no-data-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="isHadAction ? 7 : 6">
          No data available in table
        </td>
      </tr>
    </table>
  </div>

