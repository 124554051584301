<mat-table [dataSource]="dataSource"
           matSort
           matSortActive="id"
           matSortDirection="desc">
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let row"
              title="{{row.name}}">
      <p  class="u-truncated u-margin-bottom-none">{{row.name}}</p>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
    <mat-cell *matCellDef="let row"
              title="{{row.description}}">
      <p  class="u-truncated u-margin-bottom-none">{{row.description}}</p>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="active">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.is_active}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button mat-raised-button
              color="primary"
              (click)="onCopyTemplate(row.id)"
              class="btn-icon-text">
        <mat-icon>file_copy</mat-icon> Copy
      </button>
      <button mat-raised-button
              color="primary"
              (click)="onEditTemplate(row.id)"
              class="btn-icon-text">
        <mat-icon>mode_edit</mat-icon> Edit
      </button>
      <button mat-raised-button
              (click)="onDeleteTemplate(row.id)"
              class="btn-icon-text">
        <mat-icon>delete</mat-icon> Delete
      </button>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: dataColumns;">
  </mat-row>
  <ng-container *matNoDataRow>
    <p class="u-padding">
      No data available in table
    </p>
  </ng-container>
</mat-table>
<mat-paginator [pageSizeOptions]="[10]"></mat-paginator>

