import { Component, Inject } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { TrainingZoneTemplateDto, ZoneDto } from '@core/services/training-zone/training-zone-template-dto';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from '@core/constants';
import { SportService } from '@core/services/sports/sport.service';
import { SportPagedResultDto } from '@core/services/sports/sport-paged-result-dto';
import { SportDto } from '@core/services/sports/sport-dto';
import { TrainingZoneService } from '@core/services/training-zone/training-zone.service';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ValidatorHelper } from '@shared/helpers/ValidatorHelper';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog';
import * as _ from 'lodash-es';
import { ErrorFormFormatPipe } from '@shared/pipes/error-form-format.pipe';
import { SnackbarComponent } from '@shared/snackbar/snackbar.component';
import { FeatureManagementService } from '@shared/services/feature-management/feature-management.service';

class PagedTrainingZoneRequestDto extends PagedAndSortedRequestDto {
  public title: string | undefined;
}

@Component({
  selector: 'app-template-builder',
  templateUrl: './template-builder-dialog.component.html',
  styleUrls: ['./template-builder-dialog.component.scss'],
})
export class TemplateBuilderDialogComponent extends AppBaseComponent {
  public request: PagedTrainingZoneRequestDto = new PagedTrainingZoneRequestDto();
  public events: TrainingZoneTemplateDto[] = [];
  public sports: SportDto[] = [];
  public addForm: UntypedFormGroup = this.fb.group({});
  public referenceSystems: any[] = [];
  public masterMetrics: any[] = [];
  public filterMasterMetrics: any[] = [];
  public filterAdditionalMetrics: any[] = [];
  public targetValues: any[] = [];
  public addValues: any[] = [];
  public addValuesForSpeed: any[] = [];
  public addValuesForPower: any[] = [];
  public trainingZoneTemplate: TrainingZoneTemplateDto | undefined;
  public isLoaded: Promise<boolean> | undefined;
  public currentMetric: any = null;
  public currentAddValue: any = null;
  public maxValueOfValueField: number = 99999;
  public maxPercentage: number = 300;

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TemplateBuilderDialogComponent>,
    private sportService: SportService,
    private trainingZoneService: TrainingZoneService,
    private snackBar: MatSnackBar,
    private errorFormFormat: ErrorFormFormatPipe,
    private featureManagementService: FeatureManagementService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
  }

  public onInitPage(): void {
    this.initReferenceSystemData();
    this.initMasterMetric();
    this.initFeatureMasterMetrics();
    this.sortMetrics();
    this.initTargetValue();
    this.initAddValue();
    this.loadSport();
    this.initForm();
  }

  public closeDialog(result: boolean): void {
    this.dialogRef.close(result);
  }

  public initReferenceSystemData(): void {
    this.referenceSystems = [
      {
        value: AppConstants.REFERENCE_SYSTEM.POWER_WATT,
        viewValue: 'Power (watt)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.POWER_WATT_KG,
        viewValue: 'Power (watt/kg)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.SPEED_MS,
        viewValue: 'Speed (m/s)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.SPEED_KM_H,
        viewValue: 'Speed (km/h)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.SPEED_MP_H,
        viewValue: 'Speed (mp/h)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_KM,
        viewValue: 'Pace (min:sec/km)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_MILE,
        viewValue: 'Pace (min:sec/mile)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_100M,
        viewValue: 'Pace (min:sec/100m)',
        active: false,
      },
      {
        value: AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_500M,
        viewValue: 'Pace (min:sec/500m)',
        active: false,
      },
    ];
  }

  private initMasterMetric(): void {
    this.masterMetrics = [
      {
        value: AppConstants.MASTER_METRIC.AEROBIC_POWER_KCAL_H,
        viewValue: 'Aerobic Power (kcal/h)',
        unit: 'kcal/h',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.AEROBIC_POWER_WATT,
        viewValue: 'Aerobic Power (Watt)',
        unit: 'Watt',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.AEROBIC_POWER_WATT_KG,
        viewValue: 'Aerobic Power (Watt/kg)',
        unit: 'Watt/kg',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.AEROBIC_POWER_JOULE_H,
        viewValue: 'Aerobic Power (kJ/h)',
        unit: 'kJ/h',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.ANAEROBIC_POWER_K_CAL,
        viewValue: 'Anaerobic Power (kcal/h)',
        unit: 'kcal/h',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.ANAEROBIC_POWER_WATT,
        viewValue: 'Anaerobic Power (Watt)',
        unit: 'Watt',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.ANAEROBIC_POWER_WATT_KG,
        viewValue: 'Anaerobic Power (Watt/kg)',
        unit: 'Watt/kg',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.ANAEROBIC_POWER_WATT_JOULE,
        viewValue: 'Anaerobic Power (kJ/h)',
        unit: 'kJ/h',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.ANAEROBIC_THRESHOLD,
        viewValue:
          'Anaerobic Threshold (% of the speed /power at Anaerobic threshold)',
        unit: '%',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.CARBOHYDRATES_PERCENT,
        viewValue: 'Carbohydrates (%)',
        unit: '%',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.CARBOHYDRATES_KCAL_H,
        viewValue: 'Carbohydrates (kcal/h)',
        unit: 'kcal/h',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.CARBOHYDRATES_G_H,
        viewValue: 'Carbohydrates (g/h)',
        unit: 'g/h',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.ENERGY_CONTRIBUTION_AEROBIC,
        viewValue: 'Energy contribution aerobic (%)',
        unit: '%',
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.ENERGY_CONTRIBUTION_ANAEROBIC,
        viewValue: 'Energy contribution anaerobic (%)',
        unit: '%',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.FAT_PERCENT,
        viewValue: 'Fat (%)',
        unit: '%',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.FAT_KCAL,
        viewValue: 'Fat (kcal/h)',
        unit: 'kcal/h',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.FAT_GH,
        viewValue: 'Fat (g/h)',
        unit: 'g/h',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.FAT_MAX,
        viewValue: 'FatMax (% of the kcal/h at FatMax)',
        unit: '%',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.FAT_MAX_POWER,
        viewValue: 'FatMax power (%)',
        unit: '%',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.HEART_RATE,
        viewValue: 'Heart rate (bpm)',
        unit: 'bpm',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.LACTATE_ACCUMULATION_RATE,
        viewValue: 'Lactate accumulation rate (mmol/l/min)',
        unit: 'mmol/l/min',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.LACTATE_CLEARANCE_RATE,
        viewValue: 'Lactate clearance rate (mmol/l/min)',
        unit: 'mmol/l/min',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.LACTATE_CONCENTRATION,
        viewValue: 'Lactate concentration (mmol/l)',
        unit: 'mmol/l',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.MAX_LACTATE_PRODUCTION_RATE,
        viewValue: 'Lactate production rate (mmol/l/min)',
        unit: 'mmol/l/min',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.LACTATE_SHUTTLING,
        viewValue: 'Lactate shuttling',
        unit: '',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.MAX_HEART_RATE_PERCENT,
        viewValue: 'Max heart rate (%)',
        unit: '%',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.MAX_LACTATE_CLEARANCE,
        viewValue:
          'Max. Lactate clearance (% of the mmol/l/min value of the lack of pyruvate)',
        unit: '%',
        additional: false,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.TOTAL_ENERGY,
        viewValue: 'Total Energy (kcal/h)',
        unit: 'kcal/h',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.VO2_MAX,
        viewValue: 'VO2max (%)',
        unit: '%',
        additional: true,
        output: true,
        master: false,
      },
      {
        value: AppConstants.MASTER_METRIC.VLA_MAX,
        viewValue: 'VLamax (%)',
        unit: '%',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.MASTER_METRIC.VO2MAX_POWER,
        viewValue: 'VO2max-power (%)',
        unit: '%',
        additional: true,
        output: false,
        master: true,
      },
    ];
    this.filterAdditionalMetrics = this.masterMetrics.filter((item) => {
      if (item.output) return item;
    });
    this.filterMasterMetrics = this.masterMetrics.filter((item) => {
      if (item.master) return item;
    });
  }

  private initFeatureMasterMetrics(): void {
    const featureMetrics: Record<string, Record<string, any>> = {
      lt1: {
        value: AppConstants.MASTER_METRIC.LT1,
        viewValue: 'LT1 (%)',
        unit: '%',
        additional: true,
        output: true,
        master: true,
      }
    }
    for (const slug in featureMetrics) {
      if (this.featureManagementService.isFeatureEnabled(slug)) {
        this.masterMetrics.push(featureMetrics[slug])
      }
    }
    this.filterAdditionalMetrics = this.masterMetrics.filter((item) => {
      if (item.output) return item;
    });
    this.filterMasterMetrics = this.masterMetrics.filter((item) => {
      if (item.master) return item;
    });
  }

  private sortMetrics(): void {
    this.filterMasterMetrics.sort((metricA, metricB) => this.stringComparator(metricA.viewValue, metricB.viewValue));
    this.filterAdditionalMetrics.sort((metricA, metricB) => this.stringComparator(metricA.viewValue, metricB.viewValue));
  }

  private stringComparator(string1: string, string2: string): number {
    return string1 < string2 ? -1 : string1 > string2 ? 1 : 0;
  }

  public initTargetValue(): void {
    this.targetValues = [
      {
        value: 1,
        viewValue: '% - percentage',
      },
      {
        value: 2,
        viewValue: '- fixed value',
      },
    ];
  }

  private initAddValue(): void {
    this.addValuesForSpeed = [
      {
        value: 0,
        viewValue: 'None',
        unit: '',
      },
      {
        value: 1,
        viewValue: 'distance (m)',
        unit: 'm',
      },
      {
        value: 2,
        viewValue: 'time (s)',
        unit: 's',
      },
    ];
    this.addValuesForPower = [
      {
        value: 0,
        viewValue: 'None',
        unit: '',
      },
      {
        value: 2,
        viewValue: 'time (s)',
        unit: 's',
      },
    ];
  }

  public onSubmit(): void {
    if (this.addForm.value.zones.length === 0) {
      this.snackBar.open('Zone is required', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      return;
    }
    if (!this.checkOutputValue()) {
      const maxOutput: number = AppConstants.TRAINING_ZONE_VALIDATION.MAX_OUTPUT;
      this.snackBar.open('Maximum output value is ' + maxOutput, 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
    if (!this.checkSystemRefValue()) {
      const maxOutput: number = AppConstants.TRAINING_ZONE_VALIDATION.MAX_SYSTEM_REF;
      this.snackBar.open('Maximum system reference is ' + maxOutput, 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
    if (!this.checkAdditionalValue()) {
      this.snackBar.open('Additional value is required', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
    if (!this.checkMaxHeartRatePercent()) {
      this.snackBar.open('Target, upper, lower of max heart rate percent must be from 20% to 100%', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
    if (this.isInvalidAddValueValue()) {
      this.snackBar.open('The value must be less than or equal ' + this.maxValueOfValueField, 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }

    if (this.addForm.status === 'INVALID') {
      this.showErrorWhenInvalidForm();

      return;
    }
    if (this.auth?.role?.is_owner && this.addForm.value['isDefault']) {
      let dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        disableClose: false,
      });

      dialogRef.componentInstance.confirmMessage = 'Are you sure you want to set this as default the template?';
      dialogRef.afterClosed().subscribe((result): void => {
        if (result) {
          this.handleSubmitData();
        } else {
          this.addForm?.patchValue({ isDefault: false });
          this.addForm.get('isPublic')?.enable();
        }
      });
    } else {
      this.handleSubmitData();
    }
  }

  private showErrorWhenInvalidForm(): void {
    let error_messages: Array<string> = [];
    let error_object: Array<any> = [];
    const zones: AbstractControl<any, any>[] = (this.addForm.get('zones') as UntypedFormArray).controls;
    _.forEach(zones, (zone: AbstractControl<any, any>): void => {
      const values: AbstractControl<any, any> = (zone.get('values') as UntypedFormArray).controls[0];
      if (error_messages.indexOf('Target') === -1 && values.get('targetValue')?.errors) {
        error_object.push(
          this.errorFormFormat.transform(values.get('targetValue')?.errors, {
            fieldName: 'Target',
          })
        );
        error_messages.push('Target');
      }
      if (error_messages.indexOf('Lower') === -1 && values.get('lowerValue')?.errors) {
        error_object.push(
          this.errorFormFormat.transform(values.get('lowerValue')?.errors, {
            fieldName: 'Lower',
          })
        );
        error_messages.push('Lower');
      }
      if (error_messages.indexOf('Upper') === -1 && values.get('upperValue')?.errors) {
        error_object.push(
          this.errorFormFormat.transform(values.get('upperValue')?.errors, {
            fieldName: 'Upper',
          })
        );
        error_messages.push('Upper');
      }
      if (error_messages.indexOf('Value') === -1 && values.get('addValueValue')?.errors) {
        error_object.push(
          this.errorFormFormat.transform(values.get('addValueValue')?.errors, {
            fieldName: 'Value',
          })
        );
        error_messages.push('Value');
      }
    });

    if (error_object.length > 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: 'error',
        verticalPosition: 'bottom',
        data: { html: error_object.join('<br/>') },
      });
    } else {
      this.snackBar.open('Please full fill the form', 'OK', AppConstants.TOAST_CONFIG.ERROR);
    }
  }

  private isInvalidAddValueValue(): boolean {
    const zoneControl: AbstractControl<any, any>[] = (this.addForm.controls.zones as UntypedFormArray).controls;
    let isValid: boolean = true;
    if (zoneControl.length) {
      zoneControl.forEach((item: any): void => {
        const itemControl = item?.controls?.values?.controls[0].controls.addValueValue;
        if (itemControl.errors?.max) {
          isValid = false;
        }
      });
    }

    return !isValid;
  }

  public handleSubmitData(): void {
    this.addForm.value['is_active'] = !!this.addForm.value['isActive'];
    this.addForm.value['is_default'] = !!this.addForm.value['isDefault'];
    this.addForm.value['is_public'] = !!this.addForm.value['isPublic'];
    this.addForm.value['sport_id'] = this.addForm.value['sport'].id;
    if (this.addForm.value['is_default']) {
      this.addForm.value['is_public'] = true;
    }
    this.addForm.value.zones.map((item: any, index: number) => {
      item['index'] = index + 1;
      item['reference_system'] = item.referenceSystem.join(',');
      item['master_metric'] = item.masterMetric.value;
      item['output_value'] = item.outputValue ? item.outputValue.join(',') : '';
      item['zone_input_respected_values'] = [];
      if (item.values) {
        item['zone_input_respected_values'] = item.values.map((value: any) => {
          value['target'] = value.targetValue;
          value['target_unit'] = 0;
          value['lower'] = value.lowerValue;
          value['lower_unit'] = 0;
          value['upper'] = value.upperValue;
          value['upper_unit'] = 0;
          value['add_value'] = value.addValueValue || 0;
          value['add_value_unit'] = value.addValueSelect?.value || 0;
          return value;
        });
      }

      return item;
    });
    if (!this.data.id || this.data.isCopy) {
      this.trainingZoneService.create(this.addForm.value).subscribe(
        (): void => {
          this.closeDialog(true);
        },
        (error): void => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      );
    } else {
      const updateObject = this.addForm.value;
      updateObject['id'] = this.data.id;
      this.trainingZoneService.update(updateObject).subscribe(
        (): void => {
          this.closeDialog(true);
        },
        (error): void => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      );
    }
  }

  public checkMaxHeartRatePercent(): boolean {
    let isValid: boolean = true;
    this.addForm.value.zones.map((item: any): void => {
      if (item.masterMetric && item.masterMetric === AppConstants.MASTER_METRIC.MAX_HEART_RATE_PERCENT) {
        if (
          item.values &&
          ((item.values[0].lowerValue && item.values[0].lowerValue > 100) ||
            item.values[0].lowerValue < 20 ||
            (item.values[0].upperValue && item.values[0].upperValue > 100) ||
            item.values[0].upperValue < 20 ||
            (item.values[0].targetValue && item.values[0].targetValue > 100) ||
            item.values[0].targetValue < 20)
        ) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  public checkOutputValue(): boolean {
    let isValid: boolean = true;
    this.addForm.value.zones.map((item: any): void => {
      if (
        item.outputValue &&
        item.outputValue.length >
        AppConstants.TRAINING_ZONE_VALIDATION.MAX_OUTPUT
      ) {
        isValid = false;
      }
    });

    return isValid;
  }

  public checkSystemRefValue(): boolean {
    let isValid: boolean = true;
    this.addForm.value.zones.map((item: any): void => {
      if (item.referenceSystem && item.referenceSystem.length > AppConstants.TRAINING_ZONE_VALIDATION.MAX_SYSTEM_REF) {
        isValid = false;
      }
    });

    return isValid;
  }

  public checkAdditionalValue(): boolean {
    let isValid: boolean = true;
    this.addForm.value.zones.map((item: any): void => {
      if (item.values && item?.masterMetric?.value != AppConstants.MASTER_METRIC.LACTATE_SHUTTLING) {
        item.values.map((value: any): void => {
          if (!value.addValueSelect && !parseInt(value.addValueValue)) {
            isValid = false;
          }
        });
      }
    });

    return isValid;
  }

  public addNewZone(): void {
    if (this.addForm.value.zones.length >= AppConstants.TRAINING_ZONE_VALIDATION.MAX_ZONE) {
      this.snackBar.open('Cannot add new zone, maximum 20 zones', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
    this.zones.push(
      this.fb.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        referenceSystem: ['', Validators.required],
        masterMetric: ['', Validators.required],
        values: this.fb.array([
          this.fb.group({
            targetValue: ['', Validators.required],
            lowerValue: ['', Validators.required],
            upperValue: ['', Validators.required],
            addValueSelect: [''],
            addValueValue: [
              '',
              [
                Validators.max(this.maxValueOfValueField),
                Validators.pattern(AppConstants.REGEX.INTEGER),
              ],
            ],
          }),
        ]),
        outputValue: ['', Validators.required],
      })
    );
  }

  public get zones(): UntypedFormArray {
    return this.addForm.get('zones') as UntypedFormArray;
  }

  public getFormValues(i: number): UntypedFormArray {
    return (this.addForm.get('zones') as UntypedFormArray).controls[i].get('values') as UntypedFormArray;
  }

  public setFormValue(i: number): void {
    (this.addForm.get('zones') as UntypedFormArray).controls[i].patchValue({ referenceSystem: '', });
  }

  public setAdditionalValue(i: number): void {
    this.getFormValues(i).controls[0].patchValue({ addValueValue: 0 });
  }

  public setAdditionalSelect(i: number): void {
    this.getFormValues(i).controls[0].patchValue({ addValueSelect: this.addValues[0], });
  }

  public onSelectChangeMaterMetric(id: any, i: number): void {
    this.currentMetric = this.masterMetrics.find((item): boolean => item.value === id);
    const zone: AbstractControl<any, any> = (this.addForm.get('zones') as UntypedFormArray).controls[i];
    const values: AbstractControl<any, any> = (zone.get('values') as UntypedFormArray).controls[0];
    const output: UntypedFormArray = zone.get('outputValue') as UntypedFormArray;
    if (id === this.constant.MASTER_METRIC.LACTATE_SHUTTLING) {
      if (values) {
        values.get('lowerValue')?.clearValidators();
        values.get('lowerValue')?.disable();
        values.get('lowerValue')?.updateValueAndValidity();
        values.get('upperValue')?.clearValidators();
        values.get('upperValue')?.disable();
        values.get('upperValue')?.updateValueAndValidity();
        values.get('targetValue')?.clearValidators();
        values.get('targetValue')?.disable();
        values.get('targetValue')?.updateValueAndValidity();
        values.get('addValueSelect')?.disable();
        values.get('addValueSelect')?.updateValueAndValidity();
        values.get('addValueValue')?.disable();
        values.get('addValueValue')?.updateValueAndValidity();
      }
      if (output) {
        output.clearValidators();
        output.disable();
        output.updateValueAndValidity();
      }
    } else {
      if (values) {
        const validator_array: any[] = this.updateValidatorBasedOnMasterMetric();
        values.get('lowerValue')?.setValidators(validator_array);
        values.get('lowerValue')?.enable();
        values.get('lowerValue')?.updateValueAndValidity();
        values.get('upperValue')?.setValidators(validator_array);
        values.get('upperValue')?.enable();
        values.get('upperValue')?.updateValueAndValidity();
        values.get('targetValue')?.setValidators(validator_array);
        values.get('targetValue')?.enable();
        values.get('targetValue')?.updateValueAndValidity();
        values.get('addValueSelect')?.enable();
        values.get('addValueSelect')?.updateValueAndValidity();
        values.get('addValueValue')?.enable();
        values.get('addValueValue')?.updateValueAndValidity();
      }
      if (output) {
        output.setValidators([
          Validators.required,
          ValidatorHelper.validateOutputValues,
        ]);
        output.enable();
        output.updateValueAndValidity();
      }
    }
  }

  public updateValidatorBasedOnMasterMetric(): Array<any> {
    const default_validator = [Validators.required];
    if (
      [
        AppConstants.MASTER_METRIC.AEROBIC_POWER_KCAL_H,
        AppConstants.MASTER_METRIC.ANAEROBIC_POWER_K_CAL,
        AppConstants.MASTER_METRIC.CARBOHYDRATES_KCAL_H,
        AppConstants.MASTER_METRIC.FAT_KCAL,
        AppConstants.MASTER_METRIC.TOTAL_ENERGY,
        AppConstants.MASTER_METRIC.CARBOHYDRATES_G_H,
        AppConstants.MASTER_METRIC.FAT_GH,
        AppConstants.MASTER_METRIC.AEROBIC_POWER_WATT,
        AppConstants.MASTER_METRIC.ANAEROBIC_POWER_WATT,
        AppConstants.MASTER_METRIC.AEROBIC_POWER_JOULE_H,
        AppConstants.MASTER_METRIC.ANAEROBIC_POWER_WATT_JOULE,
        AppConstants.MASTER_METRIC.HEART_RATE,
      ].indexOf(this.currentMetric.value) !== -1
    ) {
      default_validator.push(Validators.pattern(AppConstants.REGEX.INTEGER));
    } else if (
      [
        AppConstants.MASTER_METRIC.ANAEROBIC_THRESHOLD,
        AppConstants.MASTER_METRIC.CARBOHYDRATES_PERCENT,
        AppConstants.MASTER_METRIC.ENERGY_CONTRIBUTION_AEROBIC,
        AppConstants.MASTER_METRIC.ENERGY_CONTRIBUTION_ANAEROBIC,
        AppConstants.MASTER_METRIC.FAT_PERCENT,
        AppConstants.MASTER_METRIC.FAT_MAX,
        AppConstants.MASTER_METRIC.FAT_MAX_POWER,
        AppConstants.MASTER_METRIC.MAX_HEART_RATE_PERCENT,
        AppConstants.MASTER_METRIC.MAX_LACTATE_CLEARANCE,
        AppConstants.MASTER_METRIC.VO2_MAX,
        AppConstants.MASTER_METRIC.VLA_MAX,
        AppConstants.MASTER_METRIC.VO2MAX_POWER,
      ].indexOf(this.currentMetric.value) !== -1
    ) {
      default_validator.push(
        Validators.pattern(AppConstants.REGEX.DECIMAL_2),
        Validators.max(this.maxPercentage)
      );
    } else {
      default_validator.push(Validators.pattern(AppConstants.REGEX.DECIMAL_2));
    }

    return default_validator;
  }

  public getCurrentMasterMetric(i: number) {
    const zone: AbstractControl<any, any> = (this.addForm.get('zones') as UntypedFormArray).controls[i];
    return this.masterMetrics.find(
      (item): boolean => item.value === parseInt(zone.get('masterMetric')?.value?.value)
    );
  }

  public getCurrentAddValue(i: number) {
    const zone: AbstractControl<any, any> = (this.addForm.get('zones') as UntypedFormArray).controls[i];
    const values: AbstractControl<any, any> = (zone.get('values') as UntypedFormArray).controls[0];
    const result = this.addValues.find(
      (item): boolean => item.value === parseInt(values?.value?.addValueSelect?.value)
    );
    if (result?.unit == '') {
      return 'Unit';
    }

    return result?.unit;
  }

  public isDisableValue(): boolean {
    return this.currentMetric === this.constant.MASTER_METRIC.LACTATE_SHUTTLING;
  }

  public onSelectChangeAddValue(id: any, i: number): void {
    this.currentAddValue = this.addValues.find((item): boolean => item.value === id);
    if (!this.currentAddValue.value) {
      this.setAdditionalValue(i);
    }
  }

  public onSelectSystemReferenceChange(event: any, i: number): void {
    const maxSystemRef: number = AppConstants.TRAINING_ZONE_VALIDATION.MAX_SYSTEM_REF;
    if (event.value.length > maxSystemRef) {
      this.snackBar.open('Maximum system reference value is ' + maxSystemRef, 'OK', AppConstants.TOAST_CONFIG.ERROR);
    }

    this.updateAdditionalType(event.value);

    if (
      this.zones?.controls[i]?.value?.values[0] &&
      !this.zones?.controls[i]?.value?.values[0]?.addValueSelect
    ) {
      this.setAdditionalSelect(i);
    }
  }

  public updateAdditionalType(value: any): void {
    if (value.includes(AppConstants.REFERENCE_SYSTEM.POWER_WATT_KG) || value.includes(AppConstants.REFERENCE_SYSTEM.POWER_WATT)) {
      this.addValues = this.addValuesForPower;
    } else {
      this.addValues = this.addValuesForSpeed;
    }
  }

  public onSelectSport(): void {
    this.zones.controls.forEach((item, i: number): void => {
      this.setFormValue(i);
    });
  }

  public onSelectOutputValue(event: any): void {
    const maxOutput: number = AppConstants.TRAINING_ZONE_VALIDATION.MAX_OUTPUT;
    if (event.value.length > maxOutput) {
      this.snackBar.open('Maximum output value is ' + maxOutput, 'OK', AppConstants.TOAST_CONFIG.ERROR);
    }
  }

  public deleteZone(i: number): void {
    this.zones.removeAt(i);
  }

  public isEditTemplate() {
    return this.data.isAllowEdit;
  }

  private loadSport(): void {
    this.sportService.listAll().subscribe((result: SportPagedResultDto): void => {
      this.sports = result.items;
      if (this.data.id) {
        this.loadDetailData();
      } else {
        this.isLoaded = Promise.resolve(true);
      }
    });
  }

  private loadDetailData(): void {
    this.trainingZoneService.get(this.data.id)
      .subscribe((result: TrainingZoneTemplateDto): void => {
        this.trainingZoneTemplate = result;
        this.initForm(result);
        this.isLoaded = Promise.resolve(true);
      });
  }

  private getReferenceSystem(value: number) {
    const item = this.referenceSystems.find((i): boolean => i.value === value);
    if (item) {
      return {
        id: item.value,
        name: item.viewValue,
      };
    }

    return null;
  }

  private initForm(data?: TrainingZoneTemplateDto): void {
    if (data) {
      this.onSelectSport();
      const zones = this.initZoneForm(data);
      this.addForm = this.fb.group({
        name: [
          !this.data.isCopy ? data.name : data.name + ' Copy',
          Validators.required,
        ],
        description: [data.description, Validators.required],
        sport: [data.sport.id, Validators.required],
        isActive: [data.is_active],
        isDefault: [data.is_default],
        isPublic: [{ value: data.is_public, disabled: !!data.is_default }],
        zones: zones,
      });
      this.initValue(data);
    } else {
      this.addForm = this.fb.group({
        name: ['', Validators.required],
        description: ['', Validators.required],
        sport: ['', Validators.required],
        isActive: [''],
        isDefault: [''],
        isPublic: [''],
        zones: this.fb.array([
          this.fb.group({
            name: ['', Validators.required],
            code: ['', Validators.required],
            referenceSystem: ['', Validators.required],
            masterMetric: ['', Validators.required],
            values: this.fb.array([
              this.fb.group({
                targetValue: [
                  '',
                  [
                    Validators.required,
                    Validators.pattern(AppConstants.REGEX.DECIMAL_2),
                  ],
                ],
                lowerValue: [
                  '',
                  [
                    Validators.required,
                    Validators.pattern(AppConstants.REGEX.DECIMAL_2),
                  ],
                ],
                upperValue: [
                  '',
                  [
                    Validators.required,
                    Validators.pattern(AppConstants.REGEX.DECIMAL_2),
                  ],
                ],
                addValueSelect: [''],
                addValueValue: [
                  '',
                  [
                    Validators.max(this.maxValueOfValueField),
                    Validators.pattern(AppConstants.REGEX.INTEGER),
                  ],
                ],
              }),
            ]),
            outputValue: [
              '',
              [Validators.required, ValidatorHelper.validateOutputValues],
            ],
          }),
        ]),
      });
    }
  }

  private initValue(data: any): void {
    this.addForm.patchValue({
      sport: data.sport,
    });
  }

  private initZoneForm(data: TrainingZoneTemplateDto): UntypedFormArray {
    const zones: UntypedFormArray = this.fb.array([]);
    data.zones.forEach((item: ZoneDto): void => {
      const metricItem: number | "" = item.master_metric ? parseInt(item.master_metric) : '';
      this.currentMetric = this.masterMetrics.find(
        (itemFind): boolean => itemFind.value === metricItem
      );
      const values: UntypedFormArray = this.fb.array([]);
      const currentReferenceSystems: number[] = item.reference_system
        ? item.reference_system.split(',').map(Number)
        : [];
      const isDisableOutput: boolean = this.currentMetric.value === this.constant.MASTER_METRIC.LACTATE_SHUTTLING;
      this.updateAdditionalType(currentReferenceSystems);
      const validator_array: any[] = this.updateValidatorBasedOnMasterMetric();

      item.zone_input_respected_values.forEach((input: any, index: number): void => {
        const addValueSelectOption = this.addValues.find(
          (mm): boolean => mm.value == input.add_value_unit
        );
        const system = this.getReferenceSystem(currentReferenceSystems[index]);
        values.push(
          this.fb.group({
            key: system ? system.id : 0,
            keyName: system ? system.name : 0,
            targetValue: [
              { value: input.target, disabled: isDisableOutput },
              validator_array,
            ],
            lowerValue: [
              { value: input.lower, disabled: isDisableOutput },
              validator_array,
            ],
            upperValue: [
              { value: input.upper, disabled: isDisableOutput },
              validator_array,
            ],
            addValueSelect: [
              { value: addValueSelectOption, disabled: isDisableOutput },
            ],
            addValueValue: [
              { value: input.add_value, disabled: isDisableOutput },
              [
                Validators.max(this.maxValueOfValueField),
                Validators.pattern(AppConstants.REGEX.INTEGER),
              ],
            ],
          })
        );
      });
      if (
        !item.zone_input_respected_values ||
        (item.zone_input_respected_values &&
          item.zone_input_respected_values.length === 0)
      ) {
        values.push(
          this.fb.group({
            key: 0,
            keyName: 0,
            targetValue: [{ value: '', disabled: true }],
            lowerValue: [{ value: '', disabled: true }],
            upperValue: [{ value: '', disabled: true }],
            addValueSelect: [{ value: '', disabled: true }],
            addValueValue: [
              { value: '', disabled: true },
              [
                Validators.max(this.maxValueOfValueField),
                Validators.pattern(AppConstants.REGEX.INTEGER),
              ],
            ],
          })
        );
      }
      const masterMetricOption = this.masterMetrics.find(
        (mm): boolean => mm.value == item.master_metric
      );
      zones.push(
        this.fb.group({
          name: [item.name, Validators.required],
          code: [item.code, Validators.required],
          referenceSystem: [
            item.reference_system
              ? item.reference_system.split(',').map(Number)
              : '',
            [Validators.required, ValidatorHelper.validateSystemRef],
          ],
          masterMetric: [masterMetricOption, Validators.required],
          values: values,
          outputValue: [
            {
              value: item.output_value ? item.output_value.split(',').map(Number) : '',
              disabled: isDisableOutput,
            },
            [Validators.required, ValidatorHelper.validateOutputValues],
          ],
        })
      );
    });

    return zones;
  }

  public onChangeDefault(event: any): void {
    if (event.checked) {
      this.addForm.get('isPublic')?.disable();
      this.addForm.get('isPublic')?.setValue(true);
    } else {
      this.addForm.get('isPublic')?.enable();
    }
  }
}
