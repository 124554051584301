import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppConstants } from "@core/constants";
import { ConfirmDialogComponent, ConfirmDialogModel } from "@shared/dialogs/confirm-dialog/confirm-dialog.component";
import { IFeature } from '../interfaces/feature.interface';
import * as moment from 'moment';
import { FeatureStoreService } from '@shared/services/feature-store/feature-store.service';
import { CustomRendererService } from '@shared/services/custom-renderer.service';

@Component({
  selector: 'app-activated-button',
  templateUrl: './activated-button.component.html',
})
export class ActivatedButtonComponent implements OnInit {
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private readonly dialog: MatDialog = inject(MatDialog);

  @Input() public feature: IFeature;
  public buttonText: string = "Activated";
  public isDisabled: boolean = true;

  ngOnInit(): void {
    if (this.isFeatureResumable()) {
      this.isDisabled = false;
      this.buttonText = "Resume";
    }
  }

  public resumeSubscription(): void {
    const message = "You have requested to resume your subscription to the " +
      `feature called '${this.feature.name}'. The feature will be resumed immediately ` +
      "and will maintain its original renewal date";

    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Resume feature subscription', message, 'Resume Subscription', 'Ignore', false);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '540px',
      data: dialogData,
      disableClose: false,
    });

    dialogRef.afterClosed()
      .subscribe((result: any): void => {
        if (!result) {
          // Keep cancellation -> do nothing
          return
        }
        this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
        this.featureStoreService.invalidateCancellation(this.feature.purchase!).subscribe({
          next: (response: any) => {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
            const message = "Subscription renewed. This feature will auto-renew after the current period ends"
            this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          },
          error: (error: any) => {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
            let errorMessage = "Unable to resume the subscription. Please contact our support team for further assistance"
            if (error?.error?.detail) {
              errorMessage = error?.error?.detail as string
            }
            this.snackBar.open(errorMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          }
        });
      });
  }

  private isFeatureResumable(): boolean {
    if (!this.feature.cancellation_date) {
      return false
    }

    if (!this.feature.subscription_end_date) {
      return false
    }

    const now = moment();
    const cancellationDate = moment(this.feature.cancellation_date);
    const subscriptionEndDate = moment(this.feature.subscription_end_date);
    return now > cancellationDate && now < subscriptionEndDate;
  }
}
