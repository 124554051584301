import { SearchService } from "@shared/services/search/search.service";
import { Component, DestroyRef, Inject, inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "@shared/services/helper.service";
import { TileService } from "@shared/services/tile/tile.service";
import { ISearch } from "@shared/interfaces/search.interface";
import { BehaviorSubject } from "rxjs";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { ITab } from "@shared/interfaces/tab.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IAdditionalTabConfig } from "@shared/interfaces/additional-tab-config.interface";
import { AdditionalTabNameEnum } from "@shared/enums/additional-tab-name.enum";

@Component({
  template: '',
})
export abstract class SearchTabsAbstract implements OnInit {
  protected searchService: SearchService = inject(SearchService);
  protected destroyRef: DestroyRef = inject(DestroyRef);
  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  protected helperService: HelperService = inject(HelperService);
  protected tileService: TileService = inject(TileService);

  public searchConfig: ISearch;
  public searchObservable$: BehaviorSubject<string>;
  public tileList: ITileConfig[];
  public categoryNameList: string[];
  public tabConfig: ITab[] = [];

  constructor(@Inject('config') private config: ISearch & IAdditionalTabConfig) {
    this.searchConfig = this.searchService.getCreatedConfig(this.config.searchName, this.config.matIcon, this.config.placeholder);
    this.searchObservable$ = this.searchService.getPageSearchObs();
    this.tileList = this.helperService.makeCopy<ITileConfig[]>(this.activatedRoute.snapshot.data.data);
    this.categoryNameList = this.helperService.getUniqueValueByPropertyName(this.tileList, this.config.propertyName).sort();
    if (this.config.enabledFeatureList) {
      this.categoryNameList.push(this.config.enabledFeatureList);
    }
    this.categoryNameList.push(this.config.additionalTabName);
  }

  public ngOnInit(): void {
    this.searchObservable$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (searchValue: string): void => {
          this.categoryNameList.forEach((category: string | AdditionalTabNameEnum, index: number) => {
            this.tabConfig[index] = {
              tabIndexToReturn: index,
              name: category,
              itemList: this.tileService.filterTabsData(this.tileList, category, searchValue),
            };
          });
        },
      });
  }
}
