<div class="profile" (click)="navigateTo()">
  <div class="profile__title-icon profile--padding-left-right">
    <div class="u-font-size-20 u-font-weight-500 u-ellipsis-general u-ellipsis-1-line">{{config.title}}</div>
    <mat-icon *ngIf="config.iconUrl; else noIconUrl" class="profile--icon" [svgIcon]="config.iconLabel"></mat-icon>
  </div>
  <div class="profile__description profile--padding-left-right u-ellipsis-general u-ellipsis-5-line">{{config.description}}</div>
</div>
<ng-template #noIconUrl>
  <mat-icon color="primary" class="profile--icon">{{config.iconLabel}}</mat-icon>
</ng-template>
