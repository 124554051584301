import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, forkJoin } from "rxjs";
import { delay, distinctUntilChanged, finalize, map } from "rxjs/operators";
import { AppConstants } from "src/app/core/constants";
import { ApiService } from "src/app/core/services/api.service";
import { PlanPaymentSessionInterface, PlanPaymentSessionStatusInterface } from "./interfaces/plan-payment-session.interface";
import { CountryInterface } from "./interfaces/country.interface";

@Injectable()
export class PlanComponentState {
  private readonly _loading = new BehaviorSubject<boolean>(false);
  private readonly _paymentSessionStatus = new Subject<PlanPaymentSessionStatusInterface>();
  private readonly _paymentSession = new Subject<PlanPaymentSessionInterface>();
  private readonly _countries = new BehaviorSubject<CountryInterface[]>([]);
  private readonly _privacyPolicy = new Subject<string>;
  private readonly _terms = new Subject<string>;

  public loading$ = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());
  public paymentSession$ = this._paymentSession.asObservable();
  public paymentSessionStatus$ = this._paymentSessionStatus.asObservable();
  public countries$ = this._countries.asObservable();
  public privacyPolicy$ = this._privacyPolicy.asObservable();
  public terms$ = this._terms.asObservable();

  constructor(
    private _apiService: ApiService
  ) { }

  /**
   * We use the status value to determine whether or not to show the plan
   * purchase page.
   */
  public getPaymentSessionStatus(guid: string): void {
    this._loading.next(true);
    const params = new URLSearchParams({ guid: guid });
    this._apiService
      .get(`${AppConstants.API.PRICING_PLANS.PAYMENT_LINKS.STATUS_BY_GUID}?${params.toString()}`)
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe({
        next: (data: any) => this._paymentSessionStatus.next(data as PlanPaymentSessionStatusInterface),
        error: (error: any) => console.error(`Unable to fetch the payment link's status: ${error}`)
      })
  }

  public loadState(guid: string) {
    forkJoin({
      paymentSessionData: this.fetchState(guid),
      countryData: this.fetchCountries(),
      privacyPolicy: this.fetchPrivacyPolicy(),
      terms: this.fetchTerms()
    }).subscribe({
      next: (data: any) => {
        this._paymentSession.next(data.paymentSessionData);
        this._countries.next(data.countryData);
        this._privacyPolicy.next(data.privacyPolicy);
        this._terms.next(data.terms);
      },
      error: (error: any) => {
        console.error("Unable to fetch payment session data", error)
      }
    })
  }

  public fetchState(guid: string): Observable<PlanPaymentSessionInterface> {
    this._loading.next(true);

    const params = { guid: guid, expand: "plan" }
    const url = `${AppConstants.API.PRICING_PLANS.PAYMENT_LINKS.BY_GUID}?${new URLSearchParams(params).toString()}`;

    return this._apiService.get(url).pipe(
      map((paymentSession: any) => paymentSession as PlanPaymentSessionInterface),
      finalize(() => this._loading.next(false))
    )
  }

  public fetchCountries(): Observable<CountryInterface[]> {
    const url = AppConstants.ACCOUNTS_URL.ACCOUNTS + AppConstants.ACCOUNTS_URL.GET.COUNTRY;
    return this._apiService.get(url).pipe(map((countries: any) => countries as CountryInterface[]))
  }

  public fetchPrivacyPolicy(): Observable<string> {
    return this._apiService
      .get(AppConstants.PRIVACY_POLICY_API)
      .pipe(map((res: Record<string, any>) => res.html_content as string));
  }

  public fetchTerms(): Observable<string> {
    return this._apiService
      .get(AppConstants.TERM_API)
      .pipe(map((res: Record<string, any>) => res.html_content as string));
  }
}
