import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
  public title: string;
  public message: string;
  public primaryButton: string;
  public secondaryButton: string;
  public isHideBtnSecondary: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
  ) {
    this.title = data.title;
    this.message = data.message;
    this.primaryButton = data.primaryButton || 'Yes';
    this.secondaryButton = data.secondaryButton || 'No';
    this.isHideBtnSecondary = data.isHideBtnSecondary || false;
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public primaryButton?: string,
    public secondaryButton?: string,
    public isHideBtnSecondary?: boolean
  ) {}
}
