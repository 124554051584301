import { Pipe, PipeTransform } from '@angular/core';
import { AppBaseComponent } from '@shared/components/app-component-base';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormatExt'
})
export class DateFormatPipe extends AppBaseComponent implements PipeTransform {
    public transform(date: any, format: string, keepUtc = false): string {
        if (!date) {
            return '';
        }
        if (keepUtc) {
            return moment(date).utc().format(format);
        }
        return moment(date).format(format);
    }

}
