import { Pipe, PipeTransform } from '@angular/core';
import { IListMonthPrices } from '@core/services/plan-purchase/plan-dto';

@Pipe({
  name: 'pricePlanPeriod',
})
export class PricePlanPeriodPipe implements PipeTransform {
  public transform(
    value: number | undefined,
    listMonthPrice: Array<IListMonthPrices> | undefined,
    param: keyof IListMonthPrices
  ): number | undefined | string {
    if (value && listMonthPrice) {
      const period: IListMonthPrices | undefined = listMonthPrice.find((item: any): boolean => item.period === value);
      return period ? period[param] : '';
    }
    return '';
  }
}
