import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class TeamResultDto extends PagedResultDto {
  public items: TeamDto[] = [];
}

export class TeamDto extends Deserializable {
  public organization: any;
  public id: number | undefined;
  public athletes: [] = [];
  public coaches: [] = [];
  public year: number | undefined;
  public is_active: boolean = false;
  public name: string | undefined;
  public updated_at: Date | undefined;
  public created_at: Date | undefined;

  public init(_data?: any): void {
    if (_data) {
      this.organization = _data['organization'];
    }
  }
  static fromJS(data: any): TeamDto {
    data = typeof data === 'object' ? data : {};
    let result: TeamDto = new TeamDto();
    result.init(data);

    return result;
  }
}
