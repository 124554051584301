import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { AppConstants } from '@core/constants';
import { InformationPopupService } from '@core/services/information-popup/information-popup.service';
import { OrganizationService } from '@core/services/organization/organization.service';
import { OrganizationResultDto } from "@core/services/organization/organization-dto";
import { InformationPopupDto } from "@core/services/information-popup/information-popup-dto";

@Component({
  selector: 'app-add-information-dialog',
  templateUrl: './add-information-dialog.component.html',
})
export class AddInformationDialogComponent extends AppBaseComponent {
  public informationForm: UntypedFormGroup = this.fb.group({});
  public types: any[] = [];
  public organizationOptions: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddInformationDialogComponent>,
    private informationService: InformationPopupService,
    private organizationService: OrganizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    super();
  }

  public onInitPage(): void {
    this.initForm();
    this.initTypes();
    this.getAllOrganizations();
  }

  public getAllOrganizations(): void {
    this.organizationService.listAll().subscribe((res: OrganizationResultDto): void => {
      this.organizationOptions = res?.items?.map((item: any) => {
        return {
          value: item.id,
          name: item.name
        };
      });
    });
  }

  public addPopup(): void {
    this.informationService
        .create(this.informationForm.value)
        .subscribe((res: InformationPopupDto) => this.dialogRef.close(res));
  }

  public editPopup(): void {
    this.informationService
        .update({ ...this.informationForm.value, id: this.data.informationPopup.id })
        .subscribe((res: InformationPopupDto) => this.dialogRef.close(res));
  }

  public onSelectOrganization(value: any): void {
    this.informationForm.get('organizations')?.setValue(value);
  }

  public save(): void {
    if(!this.data.isEdit){
      if(this.informationForm?.value?.is_all_organizations){
        this.informationForm.value.organizations = this.organizationOptions.map((org: any) => org.value);
      } else {
        this.informationForm.value.organizations = this.informationForm.value.organizations.map((org: any) => org.value);
      }
      this.addPopup();
    } else {
        if(this.informationForm?.value?.is_all_organizations){
          this.informationForm.value.organizations = this.organizationOptions.map((org: any) => org.value);
        } else {
          this.informationForm.value.organizations = this.informationForm.value.organizations.map((org: any) => org.value);
        }
        this.editPopup();
      }
  }

  public initForm(): void {
    if(!this.data.isEdit) {
      this.informationForm = this.fb.group({
        name: new UntypedFormControl('', Validators.required),
        title: new UntypedFormControl('', Validators.required),
        text_content: new UntypedFormControl('', Validators.required),
        is_all_organizations: new UntypedFormControl(false),
        type: new UntypedFormControl('', Validators.required),
        expiration: new UntypedFormControl('', Validators.required),
        is_active: new UntypedFormControl(true),
        organizations: new UntypedFormControl([])
      });
    } else {
        this.informationForm = this.fb.group({
          name: new UntypedFormControl(this.data.informationPopup.name, Validators.required),
          title: new UntypedFormControl(this.data.informationPopup.title, Validators.required),
          text_content: new UntypedFormControl(this.data.informationPopup.text_content, Validators.required),
          is_all_organizations: new UntypedFormControl(this.data.informationPopup.is_all_organizations),
          type: new UntypedFormControl(this.data.informationPopup.type, Validators.required),
          expiration: new UntypedFormControl(this.data.informationPopup.expiration, Validators.required),
          is_active: new UntypedFormControl(this.data.informationPopup.is_active),
          organizations: new UntypedFormControl(this.data.informationPopup.organizations?.map((item: any) => {
            return {
              value: item.id,
              name: item.name
            };
          }))
        });
    }
  }

  public initTypes(): void {
    this.types = [
      {
        value: AppConstants.INFORMATION_POPUP_TYPE.FIRST,
        viewValue: 'Read',
        additional: true,
        output: true,
        master: true,
      },
      {
        value: AppConstants.INFORMATION_POPUP_TYPE.SECOND,
        viewValue: 'Read & Accept',
        additional: true,
        output: true,
        master: true,
      },
    ];
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
