import { Component, DestroyRef, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { ApiService } from '@core/services/api.service';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { AppConstants} from '@core/constants';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TestService } from '@core/services/test/test.service';
import { catchError, debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { FileHandle } from '@shared/directives/drag-file.directive';
import { FilesService } from '@core/services/files/files.service';
import { OptimizationOptionDialogComponent } from '../optimization-option-dialog/optimization-option-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { String } from 'typescript-string-operations';
import { AutocompleteTagsEnum } from "@shared/components/chips/autocomplete-tags.interface";
import { FitFileParserService } from '@shared/services/fit-file-parser.service';

@Component({
  selector: 'app-test-dialog',
  templateUrl: './test-dialog.component.html',
  styleUrls: ['./test-dialog.component.scss'],
})
export class TestDialogComponent extends AppBaseComponent implements OnDestroy {
  public selection_graph = {...AppConstants.SELECTION_GRAPH_CONSTANTS};
  public additional_constants = {...AppConstants.ADDITIONAL_CONSTANTS};
  public listFileUpload: Array<any> = [];
  public testId: any;
  public testData: {[x: string]: any} = {};
  public _appConstants = AppConstants;
  public defaultSportConstant = this.constant.SPORT_DEFAULT_CONSTANTS;
  public fileToUpload: File | undefined;
  public tabIndex: number = 0;
  public isShowAdvancedSetting: boolean = false;
  public isShowExpertSetting: boolean = false;
  public isShowEnergySetting: boolean = false;
  public isShowTestSetting: boolean = false;
  public isShowConstantsSetting: boolean = false;
  public isChecked: boolean = false;
  public allTags: Array<any> = [];
  public coaches: any;
  public allSports: any;
  public filteredAthletes: any;
  public athletes: any;
  public filteredTests: any;
  public testForm: UntypedFormGroup = this.fb.group({});
  public formConditions: UntypedFormGroup = {} as UntypedFormGroup;
  public egbA: any;
  public egbB: any;
  public egbD: any;
  public eqA: any;
  public eqB: any;
  public eqD: any;
  public back: any;
  public backTestDataLoaded: boolean = false;
  public initDataLoaded: boolean = false;
  private testDataLoaded$: Subject<boolean> = new Subject<boolean>();
  public testDataLoadedSubscription: Subscription = new Subscription();
  public searchAthleteCtrl: UntypedFormControl = new UntypedFormControl('');
  public autocompleteTagsEnum = AutocompleteTagsEnum;
  public newTagList: Array<string> = [];
  public selectedValueCopy: any = {};

  @ViewChild('fileUpload') fileUpload!: ElementRef;


  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private testService: TestService,
    private readonly filesService: FilesService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly fitFileParserService: FitFileParserService,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForm();
    this.initOptionAndValue();
    this.testDataLoadedSubscription = this.testDataLoaded$.subscribe((): void=> {
      this.testForm.patchValue(this.testData, {emitEvent: false});
    });

    this.testDataLoaded$
      .subscribe((): void=> {
      this.testForm.patchValue(this.testData, {emitEvent: false});
    });

    this.testId = this.data.testId;
    this.back = this.data.isBack;

    if ( (this.data?.isLactate || this.data?.isPPD) && this.data?.isBack) {
        this.testService.getBackTestById(this.testId)
          .subscribe((res: any): void => {
          this.testData = res.results[0];
          this.testForm.patchValue(this.testData, {emitEvent: false});
          this.backTestDataLoaded = true;
        });
    } else {
      this.backTestDataLoaded = true;
    }

    if (this.data.isVirtual) {
      this.testService.getTestById(this.testId)
        .subscribe((res: any): void => {
        this.testData = res;
        this.testData.vlamax = +res.vlamax.toFixed(2);
        this.testData.vo2max_min = +res.vo2max_min.toFixed(2);
      });
    }

    this.searchAthleteCtrl.valueChanges
      .pipe(
        debounceTime(450),
        distinctUntilChanged(),
      )
      .subscribe((): void => {
      this._applyFilter();
    });
  }

  public ngOnDestroy(): void {
    if (this.testDataLoadedSubscription) {
      this.testDataLoadedSubscription.unsubscribe();
    }
  }

  public prepareEditConditions(conditions: any): UntypedFormArray {
    const dataCondition = JSON.parse(conditions);
    if (dataCondition && dataCondition.length) {
      dataCondition.forEach((item: any): void => {
        this.conditions.push(this.newConditions(item));
      });
    }
    return this.conditions;
  }

  private initForm(): void {
    this.formConditions = this.fb.group({
      conditions: this.fb.array([]),
    });

    this.testForm = this.fb.group({
      athlete: new UntypedFormControl('', Validators.required),
      sport: new UntypedFormControl('', Validators.required),
      test_date: new UntypedFormControl('', Validators.required),
      coach: new UntypedFormControl(this.auth.id, Validators.required),
      location: new UntypedFormControl(''),
      tags: new UntypedFormControl('', this.validateTags),
      comment: new UntypedFormControl(''),

      mass: new UntypedFormControl('', [
        Validators.required,
        Validators.min(10),
        Validators.max(200),
      ]),
      height: new UntypedFormControl('', [
        Validators.required,
        Validators.max(300),
        Validators.min(100),
      ]),
      proz_body_fat_100: new UntypedFormControl(15, [
        Validators.required,
        Validators.max(40),
        Validators.min(3),
      ]),

      auto_calc_body_com: new UntypedFormControl(true, []),
      proz_body_water_100_override: new UntypedFormControl('', [
        Validators.required,
        Validators.max(99),
        Validators.min(1),
      ]),
      proz_body_muscle_100_override: new UntypedFormControl('', [
        Validators.required,
        Validators.max(99),
        Validators.min(1),
      ]),
      proz_muscle_mass_used_100_override: new UntypedFormControl('',[
        Validators.required,
        Validators.max(99),
        Validators.min(1),
      ]),
      proz_lactat_dist_space_100_override: new UntypedFormControl(50, [
        Validators.required,
        Validators.max(99),
        Validators.min(1),
      ]),
      glycogen_content: new UntypedFormControl('', [
        Validators.max(40),
        Validators.min(13),
      ]),

      //MANUAL TESTS
      vo2max_min: new UntypedFormControl('', [Validators.max(150), Validators.min(1), Validators.pattern(AppConstants.REGEX.DECIMAL_2)]),
      vlamax: new UntypedFormControl('', [Validators.max(3), Validators.min(0), Validators.pattern(AppConstants.REGEX.DECIMAL_2)]),

      //PPD TESTS
      files: new UntypedFormControl([]),

      au_override: new UntypedFormControl('', [
        Validators.max(20),
        Validators.min(-1),
      ]),
      bu_override: new UntypedFormControl('', Validators.min(0)),
      du_override: new UntypedFormControl('', Validators.min(0)),
      o2_per_watt_eq_a: new UntypedFormControl('', Validators.min(0)),
      o2_per_watt_eq_b: new UntypedFormControl('', Validators.min(0)),
      o2_per_watt_eq_d: new UntypedFormControl('', Validators.min(0)),
      watto2eq: new UntypedFormControl(12.5, [Validators.max(30), Validators.min(1)]),
      kel: new UntypedFormControl(2.5, [Validators.max(13), Validators.min(1)]),
      tau: new UntypedFormControl(32, Validators.required),
      power_meter: new UntypedFormControl(''),
      lactate_analyzer: new UntypedFormControl(''),
      spirograph: new UntypedFormControl(''),
      heart_rate_device: new UntypedFormControl(''),
      ergometer: new UntypedFormControl(''),
      temperature: new UntypedFormControl(''),
      air_pressure: new UntypedFormControl(''),
      humidity: new UntypedFormControl(''),
      altitude: new UntypedFormControl(''),
      klavo2: new UntypedFormControl(0.01575, Validators.required),
      ks_lapH: new UntypedFormControl(4.5e-21, Validators.required),
      ks_vo2: new UntypedFormControl(0.00135, Validators.required),
      ks2_laADP: new UntypedFormControl(0.0045, Validators.required),
      lao2eq: new UntypedFormControl(2.745, Validators.required),
      pcro2eq: new UntypedFormControl(3.95, Validators.required),
      vla_rest: new UntypedFormControl(0.06, Validators.required),
      f_vs_musc_male_grad: new UntypedFormControl(0.58, Validators.required),
      f_vs_musc_male_b: new UntypedFormControl(-5, Validators.required),
      f_vs_musc_female_grad: new UntypedFormControl(0.58, Validators.required),
      f_vs_musc_female_b: new UntypedFormControl(-5, Validators.required),
      f_vs_h2o_male_grad: new UntypedFormControl(-0.25, Validators.required),
      f_vs_h2o_male_b: new UntypedFormControl(72, Validators.required),
      f_vs_h2o_female_grad: new UntypedFormControl(-0.25, Validators.required),
      f_vs_h2o_female_b: new UntypedFormControl(72, Validators.required),
      tau_vo2: new UntypedFormControl(1.5, Validators.required),
      konv_lapcr: new UntypedFormControl(1.35),
      pcr_exhaustion: new UntypedFormControl(5.5),
      pcr_initial: new UntypedFormControl(22),
      pcr_mlo2: new UntypedFormControl(4.051),
      unit_tau: new UntypedFormControl(12.5),
      vo2_initial: new UntypedFormControl(1),
      test_type: new UntypedFormControl(''),
      test_type_display: new UntypedFormControl(''),
      is_ppd: new UntypedFormControl(''),
      is_lactate: new UntypedFormControl(''),
    });

    this.isChecked = this.testForm.controls['auto_calc_body_com']?.value == true;
    this.testForm.valueChanges.pipe(
      debounceTime(600),
    ).subscribe(newVal => {
      Object.keys(newVal).forEach((key: string): void => {
        this.testData[key] = newVal[key];
      });
    });
  }

  private initOptionAndValue(): void {
    forkJoin([
      this.apiService.get('tags/all'),
      this.apiService.get('sports/'),
      this.apiService.get('accounts/coaches/'),
      this.apiService.get('athletes/'),
    ])
      .subscribe(([resTags, resSports, resCoaches, resAthletes]: any): void => {
        this.allTags = resTags?.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });
        this.allSports = resSports?.results;

        if (this.data.isPPD) {
          this.allSports = resSports.results.filter(
            (sport: any) => sport.use_in_ppd
          );
        }

        this.coaches = resCoaches?.results;
        this.filteredAthletes = resAthletes?.results;

      if (this.data.isVirtual ||
        ((this.data.isLactate || this.data.isPPD) && this.back)) {
        const sport = this.allSports.find(
          (x: any) => x.id == this.testData.sport
        );

        this.egbA = sport.ebg_factor_a;
        this.egbB = sport.ebg_factor_b;
        this.egbD = sport.ebg_factor_d;
        this.eqA = sport.o2_per_watt_eq_a;
        this.eqB = sport.o2_per_watt_eq_b;
        this.eqD = sport.o2_per_watt_eq_d;
        this.formConditions = this.fb.group({
          conditions: this.prepareEditConditions(this.testData.ebg_conditions),
        });

        this.testForm.markAllAsTouched();
        this.isChecked = this.testForm.controls['auto_calc_body_com']?.value == true;
        this.testDataLoaded$.next(true);
      }
      this.initDataLoaded = true;
      this._setupAthleteFilter();
    });
  }

  private _setupAthleteFilter(): void {
    this.athletes = this.filteredAthletes.slice();
    this._applyFilter();
  }

  private _applyFilter(): void {
    let search = this.searchAthleteCtrl.value;

    if (!search) {
      this.athletes = this.filteredAthletes.slice();
    } else {
      search = search.toLowerCase();

      this.athletes = this.filteredAthletes.filter((field: any) => (field.first_name + ' ' + field.last_name)
        .toLowerCase().includes(search));
    }

  }

  public getTestsForAthlete(id: any): Observable<any[]> {
    this.apiService.get('files/select2/?ordering=-id&athlete_id=' + id)
      .subscribe((res: any): void => {
        this.filteredTests = res.map((item: any) => {
          return {
            value: item.id,
            name: item.file_name,
          };
        });
      });
    return of(this.filteredTests);
  }

  private mappingOptionValue(item: any, param: string) {
    return {
      value: item?.id,
      name: item[param],
    };
  }

  public prepareAutoCalcBodyCom() {
    if (this.isChecked) {
      return 1;
    } else return 0;
  }

  public prepareData() {
    let temp: string = this.formatDateDOB(this.testForm.get('test_date')?.value);
    this.checkTestType();
    this.prepareConditions();
    this.testForm
      .get('auto_calc_body_com')
      ?.setValue(this.prepareAutoCalcBodyCom());
    if (this.data.isPPD) {
      this.testForm
        .get('files')
        ?.setValue(
          this.testForm?.value?.files
            ? this.testForm?.value?.files.map((a: any) => a.value)
            : []
        );
    }
    if (this.isChecked) {
      this.testForm.get('glycogen_content')?.setValue(20);
    }

    return {
      ...this.testForm.value,
      test_id: this.testId,
      lao2eq_precise: AppConstants.TEST_STATIC_CONSTANTS.lao2eq_precise,
      vo2max_tol_proz: AppConstants.TEST_DEFAULT_CONSTANTS.vo2max_tol_proz,
      vlamax_tol_proz: AppConstants.TEST_DEFAULT_CONSTANTS.vlamax_tol_proz,
      tolerance: AppConstants.TEST_DEFAULT_CONSTANTS.tolerance,
      max_heart_rate: AppConstants.TEST_DEFAULT_CONSTANTS.max_heart_rate,
      created_by: this.auth.id,
      organization: this.auth.organization.id,
      tags: this.testForm?.value?.tags
        ? this.testForm?.value?.tags.filter((a: any) => a.value).map((element: any) => element.value)
        : [],
      new_tags: this.newTagList || [],
      test_date: temp,
      max_o2debt: this.calculateMaxO2Debt(),
      excel_file: this.fileToUpload,
      ebg_conditions: this.prepareConditions(),
      json_data: JSON.stringify({
        selection_graph: this.selection_graph,
        additional_constants: this.additional_constants,
      }),
    };
  }

  public create(isViewAction: boolean, input: any): void {
    this.testService
      .createTest(input)
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        }),
        finalize(():boolean => (this.isDataLoading = false)),
      )
      .subscribe((res: any): void => {
        const obj = JSON.parse(res.response);
        if (isViewAction) {
          this.openOptimizationOptionsModal(true, obj.id);
          this.dialogRef.close(res);
        } else {
          if (this.data.isLactate) {
            this.router.navigateByUrl(
              'home/metabolic-profile/' + obj.id + '/shows?create=lactate'
            );
          }
          if (this.data.isPPD) {
            let createType: string | null = this.route.snapshot.queryParamMap.get('create');
            if (createType) {
              this.router.navigateByUrl(
                'home/ppd/' + obj.id + '/optimization?create=' + createType
              );
            } else {
              this.router.navigateByUrl('home/ppd/' + obj.id + '/optimization');
            }
          }
          if (this.data.isManual || this.data.isVirtual) {
            this.openOptimizationOptionsModal(false, obj.id);
          }

          this.dialogRef.close(res);
        }
      });
  }

  public openOptimizationOptionsModal(isAcceptAndView: boolean, testId: number): void {
    const type = this.data.isVirtual
      ? AppConstants.TEST_TYPE_CHARS.virtual
      : AppConstants.TEST_TYPE_CHARS.manual;
    let modalUpdate: MatDialogRef<OptimizationOptionDialogComponent> = this.dialog.open(OptimizationOptionDialogComponent, {
      panelClass: 'general-dialog',
      disableClose: true,
      data: {
        type,
      },
    });

    modalUpdate.afterClosed()
      .subscribe((data: any): void => {
        console.log(data);
      if (!data || data.action !== 'confirm') {
        return;
      }
      // TODO: try to combine these two functions
      if (type == AppConstants.TEST_TYPE_CHARS.manual) {
        console.log("manual");
        this.doManualTestOptimization(isAcceptAndView, data, testId);
      } else {
        this.doTestOptimization(isAcceptAndView, data, testId);
      }
    });
  }

  public doManualTestOptimization(isAcceptAndView: boolean, data: any, testId: number): void {
    let param_array: any[] = [];
    let params: string = '';

    if (data && data.action == "confirm") {
      if (data.productId) {
            param_array.push('product=' + data.productId);
      }
      if (data.planId) {
            param_array.push('plan=' + data.planId);
      }
      params = param_array.join('&');
    }
    console.log(params);
    const api = `tests/${testId}/accept/${params ? '?' + params : ''}`;
    this.apiService.put(api, {}).pipe(
      catchError((error) => {
        // Our API service seems to sanitize these errors so error is just a string message
        // See: api.service.ts::handleError
        const paramErrors = error.split(' ').slice(1); // Ignore the initial 'Error: ' prefix
        if (error === 'Error: TEST_ALREADY_ACTIVE') {
          this.router.navigateByUrl(
            'home/metabolic-profile/' + testId + '/'
          );
        }
        if (
          paramErrors.length > 0 &&
          paramErrors.indexOf('500') > -1
        ) {
          // const paramErrors = error.error.detail.split('_');
          let testName = '';
          if (paramErrors[1] == 'newtype') testName = 'PPD test';
          else if (paramErrors[1] == AppConstants.TEST_TYPES.LACTATE)
            testName = 'Lactate test';
          else if (paramErrors[1] == AppConstants.TEST_TYPES.VIRTUAL)
            testName = 'Virtual test';
          else if (paramErrors[1] == AppConstants.TEST_TYPES.CRITICAL_POWER)
            testName = 'critical power test';
          else if (paramErrors[1] == AppConstants.TEST_TYPES.MANUAL)
            testName = 'Manual test';
          this.popupPurchase(testName, error);
        } else {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
        }
        throw error;
      }),
    ).subscribe((): void => {
      this.snackBar.open('Item updated successfully.', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
      this.handleRedirect(testId, isAcceptAndView);
    });
  }

  doTestOptimization(isAcceptView: boolean, data: any, testId: number) {
    let param_array = [];
    let params = '';

    if (data && data.action == 'confirm') {
      if (data.productId) {
        param_array.push('product=' + data.productId);
      }
      if (data.planId) {
        param_array.push('plan=' + data.planId);
      }
      params = param_array.join('&');
    }

    const api: string = `tests/${testId}/accept_multiple/${params ? '?' + params : ''}`;
    this.apiService.get(api, false)      
      .subscribe((res: any): void => {
      if (!res?.data?.is_multiple) {
        this.doAcceptAndViewOPtimization(true, data, testId);
      }
    },
      (error): void => {
        if (error === 'Error: Test already active') {
          this.router.navigateByUrl('/home/apc/setcard/' + testId);
        }
      });
  }

  public doAcceptAndViewOPtimization(isAcceptView: boolean, data: any, testId: number): void {
    let param_array: any[] = [];
    let params: string = '';

    if (data && data.action == 'confirm') {
      if (data.productId) {
        param_array.push('product=' + data.productId);
      }
      if (data.planId) {
        param_array.push('plan=' + data.planId);
      }
      params = param_array.join('&');
    }
    const api: string = `tests/${testId}/accept/${params ? '?' + params : ''}`;
    this.apiService
      .put(api, {})
      .pipe(
        catchError((error) => {
          const paramErrors = error.split(' ').slice(1); // Ignore the initial 'Error: ' prefix
          if (error === 'Error: TEST_ALREADY_ACTIVE') {
            this.router.navigateByUrl('home/metabolic-profile/' + testId + '/');
          }
          if (
            paramErrors.length > 0 &&
            paramErrors.indexOf('500') > -1
          ) {
            let testName: string = '';
            if (paramErrors[1] == 'newtype') testName = 'PPD test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.LACTATE)
              testName = 'Lactate test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.VIRTUAL)
              testName = 'Virtual test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.MANUAL)
              testName = 'Manual test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.CRITICAL_POWER)
              testName = 'critical power test';
            this.popupPurchase(testName, error);
          } else {
            this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          }
          throw error;
        }),
      )
      .subscribe((): void => {
        this.snackBar.open('Item updated successfully.', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
        this.handleRedirect(testId, isAcceptView);
      });
  }

  private popupPurchase(testName: string, error: string): void {
    const paramErrors: string[] = error.split(' ');
    const message: string = `Maximum number of ${testName}s has been exceeded. Do you want to purchase more?`;
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Confirmation', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed()
      .subscribe((result: any): void => {
      if (result) {
        const params1: string =
          '?plan=' +
          paramErrors[3] +
          '&type=' +
          paramErrors[2] +
          '&new_id=' +
          paramErrors[4];
        const apiUrl: string = String.Format(
          this.constant.API.INVOICES.PURCHASE_EXCEED,
          params1
        );
        this.apiService.post(apiUrl, {})
          .subscribe(
          (): void => {
            this.handleRedirect(this.testId, true);
          },
          (errorPurchase): void => {
            this.snackBar.open(
              errorPurchase,
              'OK',
              this.constant.TOAST_CONFIG.ERROR
            );
          }
        );
      }
    });
  }

  public handleRedirect(testId: number, isAcceptView: boolean): void {
    if (!isAcceptView) {
      const createType: string | null = this.route.snapshot.queryParamMap.get('create');
      if (createType) {
        this.router.navigate(['home/manage/users']);
        setTimeout(() => {
          this.router.navigate(['home/manage/tests']);
        });

        this.dialogRef.close();
      }
    } else {
      this.router.navigateByUrl('home/apc/setcard/' + testId);
    }
  }

  public doPurchase(data: any): void {
    let api: string = AppConstants.STAPS.PURCHASE_LINK;
    data.force_return_true = !!data.paid_method;
    const createType: string | null = this.route.snapshot.queryParamMap.get('create');
    if (createType) {
      data.redirect_url = '/apc/setcard?test_id=' + this.testId;
    } else {
      data.redirect_url = '/home/metabolic-profile/' + this.testId + '/';
    }
    this.apiService.post(api, data)
      .subscribe((response: any): void => {
      if (response.data === true) {
        this.handleRedirect(this.testId, true);
      }
      let link = response.data.data.link;
      let currentHref: string = encodeURIComponent(location.href);
      this.router.navigateByUrl(AppConstants.STAPS.URL + link + '&callback=' + currentHref + '&currentTab=true');
      });
  }

  public update(): void {
    this.testService
      .updateTest(this.testId, this.testData)
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        }),
        finalize((): boolean => (this.isDataLoading = false)),
      )
      .subscribe((res: any): void => {
        const obj = JSON.parse(res.response);
        if (this.data.isLactate) {
          this.router.navigateByUrl(
            'home/metabolic-profile/' + obj.id + '/shows?create=lactate'
          );
        }
        if (this.data.isPPD) {
          this.router.navigateByUrl(
            'home/ppd/' + obj.id + '/optimization?create=newtype'
          );
        }
        this.dialogRef.close(res);
      });
  }

  public save(isViewAction: boolean): void {
    if (this.testForm.invalid) {
      this.testForm.markAllAsTouched();
      this.snackBar.open(
        'Please check the fields and try again.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return;
    }
    if (this.data.isLactate && !this.fileToUpload) {
      this.snackBar.open(
        'Lactate Test file is required.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return;
    }
    if (this.data.isPPD && !this.testForm.get('files')?.value.length) {
      this.snackBar.open(
        'Fit file is required.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return;
    }
    const input = this.prepareData();
    if ((this.data.isPPD || this.data.isLactate) && this.back) {
      this.update();
    } else {
      this.create(isViewAction, input);
    }
  }

  get conditions(): UntypedFormArray {
    return this.formConditions.get('conditions') as UntypedFormArray;
  }

  public newConditions(data?: any): UntypedFormGroup {
    return this.fb.group({
      name: new UntypedFormControl(data?.name, [
        Validators.required,
        Validators.pattern(this.constant.REGEX.NUMBER_LETTER_DASH_UNDERSCORE),
      ]),
      ebg_factor_a: new UntypedFormControl(data?.ebg_factor_a, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      ebg_factor_b: new UntypedFormControl(data?.ebg_factor_b, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      ebg_factor_d: new UntypedFormControl(data?.ebg_factor_d, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      o2_per_watt_eq_a: new UntypedFormControl(data?.o2_per_watt_eq_a, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      o2_per_watt_eq_b: new UntypedFormControl(data?.o2_per_watt_eq_b, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      o2_per_watt_eq_d: new UntypedFormControl(data?.o2_per_watt_eq_d, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      created_by: new UntypedFormControl(data?.created_by ?? ''),
    });
  }

  public addCondition(): void {
    if (this.testForm.get('sport')?.value) {
      const data = this.defaultSportConstant;
      data.created_by = this.auth.id;
      this.conditions.push(this.newConditions(data));
    } else {
      this.snackBar.open(
        'Please select Sport',
        'OK',
        AppConstants.TOAST_CONFIG.ERROR
      );
    }
  }

  public removeCondition(i: number): void {
    this.conditions.removeAt(i);
  }

  public prepareConditions(): string {
    const conditions = this.formConditions.getRawValue().conditions;

    return JSON.stringify(conditions);
  }

  public onChangeSport(newValue: any): void {
    this.formConditions = this.fb.group({ conditions: this.fb.array([]) });
    const sport = this.allSports.find((x: any): boolean => x.id == newValue);

    this.testForm.controls['proz_muscle_mass_used_100_override'].setValue(
      sport.muscle_usage_percentage
    );
    this.testForm.controls['au_override'].setValue(sport.ebg_factor_a);
    this.testForm.controls['bu_override'].setValue(sport.ebg_factor_b);
    this.testForm.controls['du_override'].setValue(sport.ebg_factor_d);
    this.testForm.controls['o2_per_watt_eq_a'].setValue(sport.o2_per_watt_eq_a);
    this.testForm.controls['o2_per_watt_eq_b'].setValue(sport.o2_per_watt_eq_b);
    this.testForm.controls['o2_per_watt_eq_d'].setValue(sport.o2_per_watt_eq_d);

    this.egbA = sport.ebg_factor_a;
    this.egbB = sport.ebg_factor_b;
    this.egbD = sport.ebg_factor_d;
    this.eqA = sport.o2_per_watt_eq_a;
    this.eqB = sport.o2_per_watt_eq_b;
    this.eqD = sport.o2_per_watt_eq_d;

    const dataCondition = JSON.parse(sport.ebg_conditions);
    if (dataCondition && dataCondition.length) {
      dataCondition.forEach((item: any) => {
        this.conditions.push(this.newConditions(item));
      });
    }
  }

  public onChangeAthlete(newValue: any): void {
    const athlete = this.filteredAthletes.find((x: any): boolean => x.id == newValue);

    if (this.data.isPPD) {
      this.getTestsForAthlete(athlete.id);
      this.testForm.get('files')?.setValue([]);
    }

    if (athlete.gender == 'male') {
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        50
      );
      this.updateLao2eq(
        this.testForm.get('proz_lactat_dist_space_100_override')?.value
      );
      let bodyWaterGrade: number = -0.68;
      let bodyWaterIntercept: number = 75;
      let bodyMuscleGrade: number = -0.42;
      let bodyMuscleIntercept: number = 46.2;
      this.testForm.controls['proz_body_water_100_override'].setValue(
        (
          this.testForm.get('proz_body_fat_100')?.value * bodyWaterGrade +
          bodyWaterIntercept
        ).toFixed(2)
      );
      this.testForm.controls['proz_body_muscle_100_override'].setValue(
        (
          this.testForm.get('proz_body_fat_100')?.value * bodyMuscleGrade +
          bodyMuscleIntercept
        ).toFixed(2)
      );
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        (
          this.testForm.get('proz_body_water_100_override')?.value * 0.735
        ).toFixed(2)
      );
    } else {
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        42
      );
      this.updateLao2eq(
        this.testForm.get('proz_lactat_dist_space_100_override')?.value
      );
      let bodyWaterGrade: number = -0.73;
      let bodyWaterIntercept: number = 73.5;
      let bodyMuscleGrade: number = -0.47;
      let bodyMuscleIntercept: number = 45;
      this.testForm.controls['proz_body_water_100_override'].setValue(
        (
          this.testForm.get('proz_body_fat_100')?.value * bodyWaterGrade +
          bodyWaterIntercept
        ).toFixed(2)
      );
      this.testForm.controls['proz_body_muscle_100_override'].setValue(
        (
          this.testForm.get('proz_body_fat_100')?.value * bodyMuscleGrade +
          bodyMuscleIntercept
        ).toFixed(2)
      );
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        (
          this.testForm.get('proz_body_water_100_override')?.value * 0.735
        ).toFixed(2)
      );
    }
    this.updateLao2eq(
      this.testForm.get('proz_lactat_dist_space_100_override')?.value
    );
  }

  public updateLao2eq(lactate_distribution_space: any): void {
    lactate_distribution_space = parseFloat(lactate_distribution_space);
    if (isNaN(lactate_distribution_space)) {
      this.testForm.controls['lao2eq'].setValue('');
    } else {
      let lao2eq: number =
        (lactate_distribution_space / 100.0) *
        AppConstants.TEST_STATIC_CONSTANTS.lao2eq_precise;
      this.testForm.controls['lao2eq'].setValue(Number(lao2eq.toFixed(4)));
    }
  }

  public bodyWaterOnChanged(): void {
    if (!isNaN(this.testForm.get('proz_body_water_100_override')?.value)) {
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        (
          this.testForm.get('proz_body_water_100_override')?.value * 0.735
        ).toFixed(2)
      );
    }
  }

  public checkTestType(): void {
    if (this.data.isManual == true) {
      this.testForm.get('is_ppd')?.setValue(false);
      this.testForm.get('is_lactate')?.setValue(false);
      this.testForm.get('test_type')?.setValue('manual');
      this.testForm.get('test_type_display')?.setValue('Manual');
    } else if (this.data.isPPD == true) {
      this.testForm.get('is_ppd')?.setValue(true);
      this.testForm.get('is_lactate')?.setValue(false);
      this.testForm.get('test_type')?.setValue('newtype');
      this.testForm.get('test_type_display')?.setValue('PPD');
    } else if (this.data.isVirtual == true) {
      this.testForm.get('is_ppd')?.setValue(false);
      this.testForm.get('is_lactate')?.setValue(false);
      this.testForm.get('test_type')?.setValue('virtual');
      this.testForm.get('test_type_display')?.setValue('Virtual');
    } else if (this.data.isLactate == true) {
      this.testForm.get('is_ppd')?.setValue(false);
      this.testForm.get('is_lactate')?.setValue(true);
      this.testForm.get('test_type')?.setValue('lactate');
      this.testForm.get('test_type_display')?.setValue('Lactate');
    }
  }

  public onSelectTag(value: any): void {
    this.testForm.get('tags')?.setValue(value);
  }

  public onSelectNewTag(value: any): void {
    this.newTagList.push(value);
  }

  public onRemoveNewTag(tag: any): void {
    const indexForRemove: number = this.newTagList.indexOf(tag.option.name);
    this.testForm.get('tags')?.setValue(tag.inputValue);
    if (indexForRemove > -1) {
      this.newTagList.splice(indexForRemove, 1);
    }
  }

  public onSelectFile(value: any): void {
    this.testForm.get('files')?.setValue(value);
  }

  public saveCopyBodyComposition(): void {
    this.selectedValueCopy = {
      proz_body_water_100_override: this.testForm.get('proz_body_water_100_override')?.value,
      proz_body_muscle_100_override: this.testForm.get('proz_body_muscle_100_override')?.value,
      proz_muscle_mass_used_100_override: this.testForm.get('proz_muscle_mass_used_100_override')?.value,
      proz_lactat_dist_space_100_override: this.testForm.get('proz_lactat_dist_space_100_override')?.value,
    };
  }

  public restoreBodyComposition(): void {
    this.testForm.get('proz_body_water_100_override')?.setValue(this.selectedValueCopy.proz_body_water_100_override);
    this.testForm.get('proz_body_muscle_100_override')?.setValue(this.selectedValueCopy.proz_body_muscle_100_override);
    this.testForm.get('proz_muscle_mass_used_100_override')?.setValue(this.selectedValueCopy.proz_muscle_mass_used_100_override);
    this.testForm.get('proz_lactat_dist_space_100_override')?.setValue(this.selectedValueCopy.proz_lactat_dist_space_100_override);
  }

  public showOptions(event: MatCheckboxChange): void {
    this.isChecked = event.checked;
    if (!event.checked) {
      this.saveCopyBodyComposition();
      this.testForm.controls['glycogen_content'].setValue(20);
    } else if (event.checked) {
      this.restoreBodyComposition();
    }
  }

  public calculateGrossEfficiency(o2_per_watt_eq_b: any): string {
    if (!o2_per_watt_eq_b) {
      return 'NaN';
    } else {
      try {
        var ge = 1 / (((o2_per_watt_eq_b / 100) * 20.9) / 60);
        return ge.toFixed(2);
      } catch (e) {
        return 'NaN';
      }
    }
  }

  public calculateMaxO2Debt(): number {
    const additional_constants = this._appConstants.ADDITIONAL_CONSTANTS;
    let athlete_weight = this.testForm.get('mass')?.value;
    let body_muscle_pct: number = this.testForm.get('proz_body_muscle_100_override')?.value / 100;
    let muscle_mass_pct = this.testForm.get('proz_muscle_mass_used_100_override')?.value / 100;
    let activeMuscleMass: number = athlete_weight * body_muscle_pct * muscle_mass_pct;
    let pcrInitialValue: number = additional_constants.pcr_initial;
    let pcrAtExhaustion: number = additional_constants.pcr_exhaustion;
    let deltaPcrAvailable: number = pcrInitialValue - pcrAtExhaustion;
    let totalPCrAvailable: number = deltaPcrAvailable * activeMuscleMass;

    return (totalPCrAvailable * additional_constants.pcr_mlo2) / athlete_weight;
  }

  public formatDateDOB(date: any): string {
    let d: Date = new Date(date),
      month: string = '' + (d.getMonth() + 1),
      day: string = '' + d.getDate(),
      year: number = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  public handleFileInput(data: any): void {
    this.fileToUpload = data.target.files[0];
    if ('excel_file' in this.testData) {
      this.testData.excel_file = this.fileToUpload;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabIndex = tabChangeEvent.index;
  }

  public goPrev(): void {
    this.tabIndex = this.tabIndex - 1;
  }

  public validateTags(control: AbstractControl): any {
    if (control.value) {
      const isString: boolean = Object.prototype.toString.call(control.value) === '[object String]';

      return !isString ? null : {errorTags: 'Press enter button to complete adding tag'};
    }

    return null;
  }

  public validateFirstTabFields(): boolean {
    return !(!this.testForm.get('mass')?.valid ||
      !this.testForm.get('height')?.valid ||
      !this.testForm.get('sport')?.valid ||
      !this.testForm.get('athlete')?.valid ||
      !this.testForm.get('test_date')?.valid ||
      !this.testForm.get('tags')?.valid ||
      !this.testForm.get('proz_body_fat_100')?.valid);

  }

  public goNext(): void {
    if (this.validateFirstTabFields()) {
      this.tabIndex = this.tabIndex + 1;
      return;
    }
    this.testForm.markAllAsTouched();
    this.snackBar.open(
      'Please fill all required fields',
      'OK',
      AppConstants.TOAST_CONFIG.ERROR
    );
  }

  public changeFile(event: any): void {
    if (this.validateBeforeUploadFile() && event?.target?.files) {
      this.listFileUpload = event?.target?.files;
      this.uploadFile();
    } else {
      this.fileUpload.nativeElement.value = '';
    }
  }

  private validateBeforeUploadFile(): boolean {
    if (!this.testForm.get('athlete')?.value) {
      this.snackBar.open(
        'Have to select an athlete before upload files.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return false;
    } else if (!this.testForm.get('mass')?.value) {
      this.snackBar.open(
        'Have to input weight before upload files.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return false;
    }
    return true;
  }

  public filesDropped(event: FileHandle[]): void {
    if (this.validateBeforeUploadFile()) {
      this.listFileUpload = event.map((item: FileHandle) => item.file);
      this.uploadFile();
    }
  }

  public uploadFile(): void {
    // preventing upload if more than 5 files
    if (!this.listFileUpload || this.listFileUpload.length === 0) {
      this.snackBar.open(
        'Select a file to upload.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return;
    }
    if (this.listFileUpload && this.listFileUpload.length > 20) {
      this.snackBar.open(
        'At the moment the service is not able to process more than 20 files',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return;
    }

    // Check file extension
    const extensionRegex: RegExp = /(?:\.([^.]+))?$/;
    let isValidExtension: boolean = true;
    let invalidFileName: string = '';
    for (let file of this.listFileUpload) {
      const ext: string| undefined = extensionRegex.exec(file.name)?.[1];
      if (!ext || ext.toUpperCase() !== 'FIT') {
        invalidFileName = file.name;
        isValidExtension = false;
        break;
      }
    }
    if (!isValidExtension) {
      this.snackBar.open(
        invalidFileName + ' is not a valid .fit file.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      return;
    }

    for (let file of this.listFileUpload) {
      const reader: FileReader = new FileReader();
      const file_name = file.name;
      const athlete_id = this.testForm.get('athlete')?.value;
      const weight = this.testForm.get('mass')?.value || 0;
      reader.onload = (e: any): void => {
        this.fitFileParserService.parseFileBuffer(e.target.result, file_name, athlete_id, weight, this.callBE.bind(this));
      };
      reader.readAsArrayBuffer(file);
    }
  }

  private callBE(model: any): void {
    this.filesService.create(model)
      .subscribe(
      (res: any): void => {
        this.getTestsForAthlete(res.athlete)
          .subscribe((): void => {
          const obj = {
            name: res.file_name,
            value: res.id,
          };
          const array = this.testForm.get('files')?.value;
          array.push(obj);
          this.testForm.get('files')?.setValue(array);
        });
        this.snackBar.open(
          'Item created successfully',
          'OK',
          this.constant.TOAST_CONFIG.SUCCESS
        );
        this.fileUpload.nativeElement.value = '';
        this.listFileUpload = [];
      },
      (error): void => {
        this.fileUpload.nativeElement.value = '';
        this.listFileUpload = [];
        this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
      }
    );
  }

  public onChangeFat(newValue: any): void {
    const athlete = this.testForm.get('athlete')?.value;
    const genderAth = this.filteredAthletes.find((x: any): boolean => x.id == athlete);

    if (genderAth.gender == 'male') {
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        50
      );
      this.updateLao2eq(
        this.testForm.get('proz_lactat_dist_space_100_override')?.value
      );
      let bodyWaterGrade: number = -0.68;
      let bodyWaterIntercept: number = 75;
      let bodyMuscleGrade: number = -0.42;
      let bodyMuscleIntercept: number = 46.2;
      this.testForm.controls['proz_body_water_100_override'].setValue(
        (newValue.target.value * bodyWaterGrade + bodyWaterIntercept).toFixed(2)
      );
      this.testForm.controls['proz_body_muscle_100_override'].setValue(
        (newValue.target.value * bodyMuscleGrade + bodyMuscleIntercept).toFixed(
          2
        )
      );
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        (
          this.testForm.get('proz_body_water_100_override')?.value * 0.735
        ).toFixed(2)
      );
    } else {
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        42
      );
      this.updateLao2eq(
        this.testForm.get('proz_lactat_dist_space_100_override')?.value
      );
      let bodyWaterGrade: number = -0.73;
      let bodyWaterIntercept: number = 73.5;
      let bodyMuscleGrade: number = -0.47;
      let bodyMuscleIntercept: number = 45;
      this.testForm.controls['proz_body_water_100_override'].setValue(
        (newValue.target.value * bodyWaterGrade + bodyWaterIntercept).toFixed(2)
      );
      this.testForm.controls['proz_body_muscle_100_override'].setValue(
        (newValue.target.value * bodyMuscleGrade + bodyMuscleIntercept).toFixed(
          2
        )
      );
      this.testForm.controls['proz_lactat_dist_space_100_override'].setValue(
        (
          this.testForm.get('proz_body_water_100_override')?.value * 0.735
        ).toFixed(2)
      );
    }
    this.updateLao2eq(
      this.testForm.get('proz_lactat_dist_space_100_override')?.value
    );
    this.bodyWaterOnChanged();
  }
}
