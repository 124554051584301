import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { PricingPlansDto } from '@core/services/pricing-plans/pricing-plans-dto';
import { Subject } from 'rxjs';
import { ClonePlanState } from './clone-plan-dialog.state';

@Component({
  selector: 'app-clone-plan-dialog',
  templateUrl: './clone-plan-dialog.component.html',
  styleUrls: ['./clone-plan-dialog.component.scss'],
  providers: [ClonePlanState]
})
export class ClonePlanDialogComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  public clonePlanForm!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public plan: PricingPlansDto,
    public dialogRef: MatDialogRef<ClonePlanDialogComponent>,
    private fb: FormBuilder,
    private state: ClonePlanState
  ) { }


  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public cancel() { this.dialogRef.close([false, undefined]); }

  public clone() {
    if (!this.plan.id) { return; }

    this.state.clonePlan(this.plan.id, this.clonePlanForm.value).subscribe({
      next: () => this.dialogRef.close([true, undefined]),
      error: (message: any) => { 
        return this.dialogRef.close([false, message]);
      }
    })
  }

  public generateName() {
    return `[Cloned] ${this.plan.name}`;
  }

  private initForm() {
    this.clonePlanForm = this.fb.group({
      clone_discounts: [true],
      clone_organizations: [false],
      is_publish: [false],
      name: new FormControl<string>(""),
    })
  }
}
