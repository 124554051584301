<div>
  <h2 mat-dialog-title>Edit course</h2>
  <mat-dialog-content>
    <div [formGroup]="infoForm">
      <div class="field non-editable-item">
        <label class="course-label">Name of Course </label>
        <span class="course-value">{{ courseName }}</span>
      </div>
      <div class="field non-editable-item">
        <label class="course-label">LMS System ID </label>
        <span class="course-value">{{ courseId }}</span>
      </div>
      <div class="field">
        <mat-checkbox
            formControlName="waitlist"
            style="margin-left: 12px; margin-bottom: 16px;"
            color="primary"
        >Waitlist only?</mat-checkbox>
      </div>
      <div *ngIf="isChildCourse()" class="field non-editable-item">
        <label class="course-label">Child course of</label>
        <span class="course-value">{{ parentId }}</span>
      </div>
      <div class="discount-field full-width">
        <div class="feature-field full-width">
          <div class="full-width">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label> Entitled for (days) </mat-label>
              <input
                matInput
                type="number"
                min="0"
                name="name"
                formControlName="entitled_for_days"
              />
            </mat-form-field>
          </div>
          <div class="full-width">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label> Offer ongoing access for (days) </mat-label>
              <input
                matInput
                type="number"
                min="0"
                name="name"
                formControlName="offer_ongoing_access_for_days"/>
            </mat-form-field>
          </div>
        </div>
        <div class="feature-field full-width">
          <angular-editor formControlName="description_html" [config]="textEditorConfig"></angular-editor>
        </div>
      </div>
    </div>
    <div>
    </div>
    <div [formGroup]="pricesForm">
      <div class="setting__section">
        <div class="sub-title border-color">
          <mat-icon *ngIf="!isShowPrice" (click)="isShowPrice = true">keyboard_arrow_right</mat-icon>
          <mat-icon *ngIf="isShowPrice" (click)="isShowPrice = false">keyboard_arrow_down</mat-icon>
          Prices
        </div>
        <div *ngIf="isShowPrice">
          <table class="course-table-price">
            <thead>
            <tr>
              <th>Region</th>
              <th>One Time access</th>
              <th>Permanent-1st course</th>
            </tr>
            </thead>
                    <tbody formArrayName="prices" *ngFor="let price of prices.controls; index as i">
                    <tr [formGroupName]="i">
                      <td><p style="white-space: nowrap;">{{getRegionName(price.value.region)}}</p></td>
                      <td>
                        <div class="field">
                          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                            <input matInput type="number" min="0"  name="price" formControlName="initial_purchase"/>
                          </mat-form-field>
                        </div>
                      </td>
                      <td>
                        <div class="field">
                          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                            <input matInput type="number" min="0"  name="price" formControlName="ongoing_access"/>
                          </mat-form-field>
                        </div>
                      </td>
                    </tr>
                    </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="setting__section" [formGroup]="discountsForm">
      <div class="sub-title border-color">
        <mat-icon *ngIf="!isShowDiscount" (click)="isShowDiscount = true">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="isShowDiscount" (click)="isShowDiscount = false"
        >keyboard_arrow_down</mat-icon>
        Discount
      </div>
      <div *ngIf="isShowDiscount">
        <ng-container formArrayName="discounts" *ngFor="let discount of discounts.controls; index as i">
          <div class="discount-container">
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="removeDiscountField(i)"
              class="remove-btn">
              x
            </button>
            <div [formGroupName]="i" class="discount-field">
              <div class="feature-field">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label> Discount {{ i + 1 }}</mat-label>
                  <input
                    matInput
                    id="discount"
                    name="discount"
                    formControlName="discount_value"/>
                </mat-form-field>
                <mat-form-field
                  style="width: 20%"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label> Discount type </mat-label>
                  <mat-select formControlName="discount_type">
                    <mat-option
                      *ngFor="let discType of discountTypes"
                      [value]="discType.id">
                      {{ discType.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <p>Applies to:</p>
              <div class="field plans-with-toggle">
                <app-chips
                  class="plans-chips"
                  [isDisabled]="discounts.controls[i].get('apply_to_any_plan')?.value"
                  [label]="'For user with plans'"
                  [form]="getFormControlPlansFromArray('plans', i)"
                  [options]="planOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscPlanType($event, i)"></app-chips>
                <div class="field field-toggle plan-toggle">
                  <mat-slide-toggle style="margin-bottom: 2%" class="full-width" formControlName="apply_to_any_plan">Apply for all</mat-slide-toggle>
                </div>
              </div>
              <div class="field">
                <app-chips
                  [label]="'Users'"
                  [form]="getFormControlPlansFromArray('users', i)"
                  [options]="userOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscUser($event, i)"></app-chips>
              </div>
              <div class="field">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label> Plans with annual revenue larger than </mat-label>
                  <input
                    matInput
                    type="number"
                    min="0"
                    formControlName="annual_revenue"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="btn-add">
          <button mat-raised-button type="button" (click)="addDiscountForm()">
            Add Discount
          </button>
        </div>
      </div>
    </div>
    <div class="setting__section" [formGroup]="chaptersForm">
      <div class="sub-title border-color">
        <mat-icon *ngIf="!isShowChapter" (click)="isShowChapter = true">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="isShowChapter" (click)="isShowChapter = false"
        >keyboard_arrow_down</mat-icon>
        Chapter
      </div>
      <div *ngIf="isShowChapter">
        <ng-container formArrayName="chapters" *ngFor="let chapter of chapters.controls; index as i">
          <div class="chapter-container">
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="removeChapterField(i)"
              class="remove-btn">
              x
            </button>
            <div [formGroupName]="i" class="chapter-field">
              <div class="feature-field">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label> Chapter {{ i + 1 }}</mat-label>
                  <input
                    matInput
                    type="number"
                    name="chapter"
                    formControlName="number"/>
                </mat-form-field>
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label>Title</mat-label>
                  <input
                    matInput
                    name="title"
                    formControlName="title"/>
                </mat-form-field>
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label>Description</mat-label>
                  <textarea matInput name="description" formControlName="description"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="btn-add">
          <button mat-raised-button type="button" (click)="addChapterForm()">Add Chapter</button>
        </div>
      </div>
    </div>
    <div class="setting__section" [formGroup]="suggestedCourses">
      <div class="sub-title border-color">
        <mat-icon *ngIf="!isShowSuggestedCourses" (click)="isShowSuggestedCourses = true">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="isShowSuggestedCourses" (click)="isShowSuggestedCourses = false">keyboard_arrow_down</mat-icon>
        Suggested courses:
      </div>
      <div *ngIf="isShowSuggestedCourses">
        <ng-container>
          <div class="field">
            <app-chips
              [label]="'Suggested courses'"
              [form]="suggestedCourses.get('courses')"
              [options]="coursesOptions"
              [displayField]="'name'"
              (optionChange)="onSelectCourses($event)"></app-chips>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="setting__section" [formGroup]="courseUnits">
      <div class="sub-title border-color">
        <mat-icon *ngIf="!isShowCourseUnits" (click)="isShowCourseUnits = true">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="isShowCourseUnits" (click)="isShowCourseUnits = false">keyboard_arrow_down</mat-icon>
        This course is a parent course of:
      </div>
      <div *ngIf="isShowCourseUnits">
        <ng-container>
          <div class="field">
            <app-chips
              [label]="'This course is a parent course of'"
              [form]="courseUnits.get('courses')"
              [options]="courseUnitsOptions"
              [displayField]="'name'"
              [isDisabled]="isChildCourse()"
              (optionChange)="onSelectCourseUnits($event)"></app-chips>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <mat-error *ngIf="
                this.infoForm.invalid ||
         this.pricesForm.invalid ||
          this.discountsForm.invalid ||
            this.suggestedCourses.invalid">
        <div>Please fill all fields</div>
      </mat-error>
      <button id="cancelButton" mat-button (click)="close()" type="button">
        Cancel
      </button>
      <button
        id="saveButton"
        [disabled]="this.infoForm.invalid ||
         this.pricesForm.invalid ||
          this.discountsForm.invalid ||
            this.suggestedCourses.invalid ||
            chaptersForm.invalid"
        mat-raised-button
        color="primary"
        (click)="save()">
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
