import { Component, Input } from '@angular/core';
import { IFeaturePrice } from "@shared/interfaces/feature-price-list.interface";

@Component({
  selector: 'app-feature-price-list',
  templateUrl: './feature-price-list.component.html',
})
export class FeaturePriceListComponent {
  @Input() public config: IFeaturePrice[];
  @Input() public title: string = 'N/A';
}
