import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class CountriesResultDto extends PagedResultDto {
  public items: CountriesDto[] = [];
}

export class CountriesDto extends Deserializable {
  public id: number | undefined;
  public name: string | undefined;
  public region:
    | {
        id: number | undefined;
        name: string | undefined;
        price: number | undefined;
        country:
        | Array<{
            id: number | undefined;
            name: string | undefined;
            country_code: string | undefined;
          }>
        | undefined;
      }
    | undefined;
}
