import { inject, Injectable } from '@angular/core';
import { IProfileItem } from "@shared/interfaces/profile-item.interface";
import { SessionService } from "@core/services/session.service";

@Injectable()
export class MyProfileService {
  private sessionService: SessionService = inject(SessionService);
  private BACK_QUERY: string = 'general_back_button=show';

  public getProfileItemList(): IProfileItem[] {
    return [
      {
        title: 'Account Info',
        description: 'Organization info and address. Update of logo',
        urlNavigateTo: `/home/account-info?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/account-info.svg',
        iconLabel: 'account-info',
        isVisible: this.sessionService.rights.admin_list,
      },
      {
        title: 'Personal Info',
        description: 'Your personal info and change your password',
        urlNavigateTo: `/home/personal-info?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/personal-info.svg',
        iconLabel: 'personal-info',
        isVisible: true,
      },
      {
        title: 'Devices',
        description: 'View, edit and delete devices of users',
        urlNavigateTo: `/home/administration/device-management?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/devices.svg',
        iconLabel: 'devices',
        isVisible: this.sessionService.rights.admin_devices_list,
      },
      {
        title: 'Tags',
        description: 'Create, edit and delete tags',
        urlNavigateTo: `/home/administration/tags?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/tags.svg',
        iconLabel: 'tags',
        isVisible: this.sessionService.rights.admin_tags_list,
      },
      {
        title: 'Integrations',
        description: 'Manage connections to other applications you can use with INSCYD',
        urlNavigateTo: `/home/integrations?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/integrations.svg',
        iconLabel: 'integrations',
        isVisible: this.sessionService.rights.tracking_api_access,
      },
      {
        title: 'Security',
        description: 'View and end sessions of users',
        urlNavigateTo: `/home/security?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/security.svg',
        iconLabel: 'security',
        isVisible: this.sessionService.rights.admin_devices_update,
      },
      {
        title: 'Subscriptions',
        description: 'Utilization of your subscription to testing plans and features',
        urlNavigateTo: `/home/plan-subscription/my-subscription?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/subscriptions.svg',
        iconLabel: 'subscriptions',
        isVisible: this.sessionService.rights.subscription_view_subscription_page,
      },
      {
        title: 'Billing',
        description: 'Download invoices and change your payment method',
        urlNavigateTo: `/home/plan-subscription?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/billing.svg',
        iconLabel: 'billing',
        isVisible: this.sessionService.rights.subscription_view_invoice_page,
      },
      {
        title: 'User Rights Management',
        description: 'Create and manage roles and access of users to specific functions',
        urlNavigateTo: `/home/administration/rights-management?${this.BACK_QUERY}`,
        iconUrl: 'assets/custom-icons/rights-management.svg',
        iconLabel: 'rights-management',
        isVisible: this.sessionService.rights.admin_rights_list,
      },
      {
        title: 'Plans & Pricing',
        description: 'Change your subscription',
        urlNavigateTo: `/home/plan-subscription/plan-purchase?${this.BACK_QUERY}`,
        iconUrl: undefined,
        iconLabel: 'card_membership',
        isVisible: this.sessionService.rights.subscription_view_plan,
      },
    ];
  }
}
