import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { CurrentAccountService } from "@shared/services/current-account/current-account.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppConstants } from "@core/constants";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from "@shared/dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private currentAccountService: CurrentAccountService = inject(CurrentAccountService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private snackBar: MatSnackBar = inject(MatSnackBar);

  public changePasswordForm: UntypedFormGroup;
  public inputTextType: string = 'text';
  public inputPasswordType: string = 'password';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,) {
  }

  public ngOnInit(): void {
    this.initChangePasswordForm();
  }

  private initChangePasswordForm(): void {
    this.changePasswordForm = this.fb.group({
      current_password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      isShowPassword: [false],
    }, {
      validators: this.passwordMatchValidator(),
    });
  }

  public changePassword(): void {
    this.currentAccountService.changePasswordAccount(this.changePasswordFormRef.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.snackBar.open('Password has been changed successfully', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        },
        error: (err: any) => {
          this.snackBar.open(err, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      })
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const newPassword = control.get('new_password')?.value;
      const confirmPassword = control.get('confirm_password')?.value;

      if (newPassword && confirmPassword && newPassword !== confirmPassword) {
        return { matchPasswordError: true, }
      }

      return null;
    }
  }

  public get showPassword(): boolean {
    return this.changePasswordForm.get('isShowPassword')?.value;
  }

  public get changePasswordFormRef(): UntypedFormGroup {
    return this.changePasswordForm;
  }
}
