import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';
import * as moment from 'moment';

export class TagsManagementResultDto extends PagedResultDto {
  public items: TagsManagementDto[] = [];
}

export class TagsManagementDto extends Deserializable {
  public id: number | undefined;
  public display_id: number | undefined;
  public is_system: boolean | undefined;
  public is_delete: boolean | undefined;
  public name: string | undefined;
  public updated_at: string | moment.Moment | undefined;
  public organization:
    | {
        address_country_id: number | undefined;
        id: number | undefined;
        is_check_invoice: boolean | undefined;
        is_solvent: boolean | undefined;
        name: string | undefined;
        photo: string | undefined;
      }
    | undefined;
}

export interface ITagsDetail {
  created_at?: string;
  display_id?: number;
  id?: number;
  is_delete?: boolean;
  is_fixed?: boolean;
  is_system?: boolean;
  name?: string;
  organization?: number;
  updated_at?: string;
}
