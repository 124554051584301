import { Injectable } from "@angular/core";
import { InitialValuesInterface } from "@shared/interfaces/initial-values.interface";
import { Subject } from "rxjs";

type CarbField = "gh" | "value";

@Injectable()
export class MaximizeConstraintService {
  public maximizeComponentState$: Subject<any> = new Subject<any>();
  public constraintComponentState$: Subject<any> = new Subject<any>();

  public getCarbohydrateCurrentValue(initialValues: InitialValuesInterface, ghValue: number): string {
    const ghRange = [
      initialValues?.carbohydrates?.table[0]?.gh,
      initialValues?.carbohydrates?.table[initialValues?.carbohydrates?.table.length - 1].gh
    ];

    if (!ghValue) {
      return ""
    }

    if (ghValue < ghRange[0] || ghValue > ghRange[1]) {
      // Outside of the x-values range
      return ""
    }

    const currentValue = this.getTableValue(initialValues, ghValue, "gh", "value");
    if (!currentValue) {
      return ""
    }

    return currentValue.toFixed(2) + " " + initialValues?.carbohydrates?.units;
  }

  public getRawCarbohydrateInverseValue(initialValues: InitialValuesInterface, yValue: number): number | undefined {
    const yRange = [
      initialValues?.carbohydrates?.table[0]?.value,
      initialValues?.carbohydrates?.table[initialValues?.carbohydrates?.table.length - 1].value
    ];

    if (!yValue) {
      return
    }

    if (yValue < yRange[0] || yValue > yRange[1]) {
      // Outside of the y-values range
      return
    }

    const ghValue = this.getTableValue(initialValues, yValue, "value", "gh");
    if (!ghValue) {
      return
    }

    return ghValue
  }

  public getCarbohydrateInverseValue(initialValues: InitialValuesInterface, yValue: number): string {
    const ghValue = this.getRawCarbohydrateInverseValue(initialValues, yValue)
    if (!ghValue) {
      return ""
    }

    return ghValue.toFixed(2) + " g/h";
  }

  public getDynamicPowerCurrentValue(
    initialValues: InitialValuesInterface,
    initialValue: string,
    isPowerSport: boolean,
    isRelative: boolean
  ): string {
    const units = isPowerSport ? (isRelative ? "Watt/kg" : "Watt") : "";
    const coefficients = initialValues.power_speed_dynamic?.coefficients;
    if (!coefficients) {
      return ""
    }

    if (isPowerSport) {
      const valueInSeconds = this.timeToSeconds(initialValue);
      if (!valueInSeconds) {
        return ""
      }

      const powerInWatts = this.powerInWatts(valueInSeconds, coefficients?.c ?? 0, coefficients?.b, coefficients.a);
      const powerInRelative = isRelative ? powerInWatts / initialValues.mass : powerInWatts;
      return powerInRelative.toFixed(2) + " " + units;
    } else {
      if (!(+initialValue)) {
        return ""
      }
      const timeInSeconds = this.timeInSeconds(+initialValue, coefficients.b, coefficients.a);
      if (timeInSeconds < 0) {
        return ""
      }
      return this.secondsToTime(timeInSeconds) + " " + units;
    }
  }

  private getTableValue(
    initialValues: InitialValuesInterface, parameter: number, parameterField: CarbField, valueField: CarbField
  ): number | undefined {
    const table = initialValues.carbohydrates?.table;
    let valueIndex = -1;

    for (let i = 0; i < table.length; i++) {
      const row = table[i];
      if (parameter < row[parameterField]) {
        valueIndex = i - 1;
        break;
      }
    }

    if (valueIndex === -1) {
      return undefined;
    }

    // Floor
    return table[valueIndex][valueField];
  }

  private timeToSeconds(timeString: string): number | undefined {
    const timeParts = timeString.split(":");
    if (timeParts.length !== 2) {
      return undefined
    }
    const timePartValues = timeParts.map(v => parseInt(v));
    return timePartValues[0] * 60 + timePartValues[1];
  }

  private secondsToTime(valueInSeconds: number): string {
    // Extract decimal part
    const secondsPart = Math.floor(valueInSeconds);
    const decimalPart = valueInSeconds - secondsPart;
    // Convert to minutes and seconds
    const minutes = Math.floor(secondsPart / 60);
    const seconds = secondsPart % 60;
    // Add back decimals to seconds part
    const secondsWithDecimal = seconds + decimalPart;  
    const prefixedSecondsWithDecimal = secondsWithDecimal < 10 ? "0" + secondsWithDecimal.toFixed(1) : secondsWithDecimal.toFixed(1);
    return minutes + ":" + prefixedSecondsWithDecimal;
  }

  private powerInWatts(valueInSeconds: number, c: number, b: number, a: number): number {
    return (Math.pow(valueInSeconds, 2) * c) + (b * valueInSeconds) + a;
  }

  private timeInSeconds(valueInMeters: number, b: number, a: number): number {
    return (b * valueInMeters) + a;
  }
}
