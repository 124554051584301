import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview-meter',
  templateUrl: './preview-meter.component.html',
  styleUrls: ['./preview-meter.component.scss']
})
export class PreviewMeterComponent {
  @Input() config: any;
}

