import { SessionService } from "@core/services/session.service";
import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { SPECIFIC_LIVE_ORGANIZATION_TOKEN_LIST } from "@tokens/specific-ogranization-token-list.token";
import { AppConstants } from "@core/constants";
import { HelperService } from "@shared/services/helper.service";

export class SpecificOrganizationGuardService {
  private sessionService: SessionService = inject(SessionService);
  private helperService: HelperService = inject(HelperService);
  private router: Router = inject(Router);
  private SPECIFIC_LIVE_ORGANIZATION_TOKEN_LIST: number[] = inject(SPECIFIC_LIVE_ORGANIZATION_TOKEN_LIST);

  public canActivate(): boolean {
    const organizatoinId: number = JSON.parse(this.sessionService.getCookie('auth')).organization.id;
    const isProductionEnvironment: boolean = this.helperService.isProductionEnvironment(location, AppConstants.ENVIRONMENT_URL.PROD);

    if (this.SPECIFIC_LIVE_ORGANIZATION_TOKEN_LIST.includes(organizatoinId) || !isProductionEnvironment) {
      return true;
    } else {
      this.router.navigateByUrl('/home/dashboard');

      return false;
    }
  }
}

export const SpecificOrganizationGuard: CanActivateFn = () => {
  return inject(SpecificOrganizationGuardService).canActivate();
};
