import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@core/guards/auth.guard";
import { PaymentMethodGuard } from "@core/guards/payment-method.guard";
import { AccountTypesComponent } from "./account-types/account-types.component";
import { AdministrationComponent } from "./administration.component";
import { ApiTrackingComponent } from "./api-tracking/api-tracking.component";
import { DeviceManagementComponent } from "./device-management/device-management.component";
import { HistoryLogsComponent } from "./history-logs/history-logs.component";
import { InformationPopupComponent } from "./information-popup/information-read/information-popup/information-popup.component";
import { ManageOrganizationsComponent } from "./manage-organizations/manage-organizations.component";
import { OrganizationCommonSettingComponent } from "./organization-common-setting/organization-common-setting.component";
import { PricingPlansComponent } from "./pricing-plans/pricing-plans.component";
import { ProductsComponent } from "./products/products.component";
import { RegionSettingsComponent } from "./region-settings/region-settings.component";
import { ReportTemplateComponent } from "./report-template/report-template.component";
import { RightsManagementComponent } from "./rights-management/rights-management.component";
import { SportsComponent } from "./sports/sports.component";
import { SystemSettingsComponent } from "./system-settings/system-settings.component";
import { TagsManagementComponent } from "./tags-management/tags-management.component";
import { TrainingZoneTemplateComponent } from "./training-zone-template/training-zone-template.component";
import { CoursesComponent } from "./courses/courses.component";

const routes: Routes = [
	{
		path: "",
		component: AdministrationComponent,
		canActivate: [AuthGuard, PaymentMethodGuard],
		data: {
			condition: "rights.admin_list"
		},
		children: [
			{
				path: "",
				redirectTo: "training-zone-template",
				pathMatch: "full"
			},
			{
				path: "training-zone-template",
				component: TrainingZoneTemplateComponent,
				canActivate: [AuthGuard, PaymentMethodGuard]
			},
			{
				path: "rights-management",
				component: RightsManagementComponent,
				canActivate: [AuthGuard, PaymentMethodGuard]
			},
			{
				path: "device-management",
				component: DeviceManagementComponent,
				canActivate: [AuthGuard, PaymentMethodGuard]
			},
			{
				path: "tags",
				component: TagsManagementComponent,
				canActivate: [AuthGuard, PaymentMethodGuard]
			},
			{
				path: "organization-settings",
				component: OrganizationCommonSettingComponent,
				canActivate: [AuthGuard, PaymentMethodGuard]
			},
			{
				path: "sports",
				component: SportsComponent,
				canActivate: [AuthGuard, PaymentMethodGuard]
			},
			{
				path: "account-types",
				component: AccountTypesComponent,
				canActivate: [AuthGuard]
			},
      {
        path: "products",
        component: ProductsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "courses",
        component: CoursesComponent,
        canActivate: [AuthGuard]
      },
			{
				path: "api-tracking",
				component: ApiTrackingComponent,
				canActivate: [AuthGuard]
			},
			{
				path: "region-pricing",
				component: RegionSettingsComponent,
				canActivate: [AuthGuard]
			},
			{
				path: "pricing-plans",
				component: PricingPlansComponent,
				canActivate: [AuthGuard]
			},
			{
				path: "information-popup",
				component: InformationPopupComponent,
				canActivate: [AuthGuard]
			},
			{
				path: "manage-organizations",
				component: ManageOrganizationsComponent,
				canActivate: [AuthGuard]
			},
			{
				path: "history-logs",
				component: HistoryLogsComponent,
				canActivate: [AuthGuard]
			},
			{
				path: "system-settings",
				component: SystemSettingsComponent,
				canActivate: [AuthGuard]
			},
			{
				path: "report-template",
				component: ReportTemplateComponent,
				canActivate: [AuthGuard]
			},
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AdministrationRoutingModule {}
