import { Component, inject, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import IMask from "imask";
import { MaximizeConstraintService } from "@shared/services/maximize-constraint.service";
import { HelperService } from "@shared/services/helper.service";
import { InitialValuesInterface } from "@shared/interfaces/initial-values.interface";

@Component({
  selector: 'app-constraints',
  templateUrl: 'constraints.component.html',
  styleUrls: ['./constraints.component.scss']
})
export class ConstraintsComponent implements OnInit {
  @Input() public sportType: number;
  @Input() public systemReference: number;
  @Input() public initialValues: any;

  private maximizeConstraintService: MaximizeConstraintService = inject(MaximizeConstraintService);
  private helperService: HelperService = inject(HelperService);
  public constraintsForm: FormGroup;
  public first: boolean = false;
  public selectedValue: any = null;
  public selectedValueItem: any = null;
  public isShowToggle: boolean = false;
  public isPowerSport: boolean = false;
  public dataArray: any;
  public powerBasedConfig: any = [
    {
      id: 'anaerobic_threshold',
      name: 'Anaerobic Threshold',
      isDisable: false,
      isShow: true,
      isShowAnaerobicThreshold: true,
      placeholder: 'Watt',
    },
    {
      id: 'fat_max',
      name: 'FatMax',
      isDisable: false,
      isShow: true,
      isShowFatMax: true,
      placeholder: 'Watt',
    },
    {
      id: 'power_speed_static',
      name: 'Power @ ',
      isShowCarbohydrateInput: true,
      isShowCarbohydrateInverseInput: true,
      placeholder: ' g/h',
      postfix: ' carbohydrate combustion rate',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'power_speed_dynamic',
      name: 'Power @ duration of ',
      placeholder: 'mm:ss.s',
      placeholderOverride: 'Watt',
      isShowDistanceTimeInput: true,
      isShowDistanceTimeOverrideInput: true,
      isDisable: false,
      isShow: true,
    },
    {
      id: 'none',
      name: 'None',
      isDisable: false,
      isShow: true,
    },
  ];

  public speedBasedPaceConfig: any = [
    {
      id: 'anaerobic_threshold',
      name: 'Anaerobic Threshold',
      isDisable: false,
      isShow: true,
      isShowAnaerobicThreshold: true,
      placeholder: 'value',
    },
    {
      id: 'fat_max',
      name: 'FatMax',
      isDisable: false,
      isShow: true,
      isShowFatMax: true,
      placeholder: 'value',
    },
    {
      id: 'power_speed_static',
      name: 'Pace @',
      isShowCarbohydrateInput: true,
      isShowCarbohydrateInverseInput: true,
      placeholder: 'g/h',
      postfix: 'carbohydrate combustion rate',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'power_speed_dynamic',
      name: 'Time @ distance of',
      placeholder: 'meters',
      placeholderOverride: 'mm:ss.s',
      isShowDistanceTimePaceInput: true,
      isShowDistanceTimePaceOverrideInput: true,
      isDisable: false,
      isShow: true,
    },
    {
      id: 'none',
      name: 'None',
      isDisable: false,
      isShow: true,
    },
  ];

  public ngOnInit(): void {
    this.isPowerSport = this.helperService.isPowerBasedSport(this.sportType);

    if (this.helperService.isPowerBasedSport(this.sportType)) {
      this.isShowToggle = true;
      this.dataArray = this.powerBasedConfig;
      this.constraintsForm = new FormGroup({
        constraints: new FormControl(''),
        anaerobicThreshold: new FormControl(''),
        fatmax: new FormControl(''),
        carbohydrate: new FormControl(''),
        carbohydrateInverse: new FormControl(''),
        constraintsToggle: new FormControl(false),
        initialValue: new FormControl(''),
        overrideValue: new FormControl(''),
      });
    } else if (this.helperService.isSpeedBasedSport(this.sportType)) {
      this.isShowToggle = false;
      this.dataArray = this.speedBasedPaceConfig;
      this.constraintsForm = new FormGroup({
        constraints: new FormControl(''),
        carbohydrate: new FormControl(''),
        carbohydrateInverse: new FormControl(''),
        anaerobicThreshold: new FormControl(''),
        fatmax: new FormControl(''),
        initialValue: new FormControl(''),
        overrideValue: new FormControl(''),
      });
    }

    this.constraintsForm.valueChanges.subscribe({
      next: (formValue: FormGroup): void => {
        this.maximizeConstraintService.constraintComponentState$.next(formValue);
      }
    });

    this.maximizeConstraintService.maximizeComponentState$.subscribe({
      next: (maximize: any): void => {
        this.resetStateDataArray();
        if (this.helperService.isSpeedBasedSport(this.sportType)) {
          this.dataArray.forEach((item: any) => {
            if (maximize.maximize === item.id) {
              item.isDisable = true;
              item.isShow = false;
            }
          });
        } else if (this.helperService.isPowerBasedSport(this.sportType)) {
          if (maximize.maximize === this.constraints?.value && maximize.maximizeToggle === this.formToggle?.value) {
            // TODO: Where is this logic described? It seems a bit arbitrary so disabling for now
            // this.formToggle?.setValue(!this.formToggle?.value);
          }
        }
      },
    });
  }

  public get formToggle(): AbstractControl | null {
    return this.constraintsForm.get('constraintsToggle');
  }

  public get constraints(): AbstractControl | null {
    return this.constraintsForm.get('constraints');
  }

  public resetStateDataArray(): void {
    this.dataArray.forEach((item: any) => {
      item.isDisable = false;
      item.isShow = true;
    });
  }

  public addMaskToInputFatMaxPaceMinSecKm(): void {
    const el: any = document.getElementById('fatmax-pace-max-min-sec-km');

    IMask(el, {mask: '00:00',});
  }

  public addMaskToInputATPaceMinSecKm(): void {
    const el: any = document.getElementById('at-pace-max-min-sec-km');

    IMask(el, {mask: '00:00',});
  }

  public setValue(value: any): void {
    this.selectedValue = value.name;
    this.selectedValueItem = value;
  }

  public getCurrentConstraintsForm(): FormGroup {
    return this.constraintsForm;
  }

  public getCarbohydrateCurrentValue(): string {
    const ghValue = this.constraintsForm.get("carbohydrate")?.value;
    return this.maximizeConstraintService.getCarbohydrateCurrentValue(this.initialValues, ghValue);
  }

  public getCarbohydrateInverseValue(): string {
    const yValue = this.constraintsForm.get("carbohydrateInverse")?.value;
    return this.maximizeConstraintService.getCarbohydrateInverseValue(this.initialValues, yValue);
  }

  public getGHCarbohydrateInverseValue(): number | undefined {
    const yValue = this.constraintsForm.get("carbohydrateInverse")?.value;
    return this.maximizeConstraintService.getRawCarbohydrateInverseValue(this.initialValues, yValue);
  }

  public getCarbohydrateInverseUnits(): string {
    return (this.initialValues as InitialValuesInterface).carbohydrates?.units || "";
  }

  public getDynamicPowerInitialValue(): string {
    const dynPowerValue = this.constraintsForm.get("initialValue")?.value;
    const isRelative = this.constraintsForm.get('constraintsToggle')?.value ?? false;
    return this.maximizeConstraintService.getDynamicPowerCurrentValue(this.initialValues, dynPowerValue, this.isPowerSport, isRelative);
  }

  public get isSpeedRef(): boolean {
    return this.helperService.isSpeedMS(this.systemReference) ||
      this.helperService.isSpeedKmH(this.systemReference) ||
      this.helperService.isSpeedMph(this.systemReference) ||
      this.helperService.isSpeedMph(this.systemReference);
  }

  public get isPaceRef(): boolean {
    return this.helperService.isPaceMinSecKm(this.systemReference) ||
      this.helperService.isPaceMinSecMile(this.systemReference) ||
      this.helperService.isPaceMinSec100Meter(this.systemReference) ||
      this.helperService.isPaceMinSec500Meter(this.systemReference);
  }
}
