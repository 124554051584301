import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppBaseComponent } from "@shared/components/app-component-base";
import { ChartService } from "@shared/services/chart.service";
import { TestService } from "@core/services/test/test.service";
import * as _ from "lodash-es";
import * as moment from 'moment';
import { forkJoin, Observable } from "rxjs";
import {  AppConstants } from "@core/constants";
import { TrainingZonesComponentService } from "@core/services/apc/training-zones-component.service";
import { ApiService } from "@core/services/api.service";
import { ReportPreviewPage, ReportPreviewService } from "@core/services/report-preview/reportPreview.service";
import { ReportField, ReportTemplate, ReportTemplateService } from "@core/services/report-template/report-template.service";
import { TestDto } from "@core/services/test/test-dto";
import { ChartMappingData } from "../../apc/apc/data/chart-mapping-data";
import { ChartConstants, ReportBuildingBlocks } from "../../apc/apc/data/chart-modules";

@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss'],
  providers: [
    {
      provide: 'Window', useValue: window
    }
  ]
})
export class ReportPreviewComponent extends AppBaseComponent {
  public testId: number | null = null;
  public athleteId: number | null = null;
  public reportTemplateId: number;
  public reportTemplate: ReportTemplate;
  private _decreaseSize: number = 0;
  public pageWidth: number = 1080 - this._decreaseSize;
  public defaultPageHeight: number = 800;
  public pageHeight: number = this.defaultPageHeight;
  public widthUnit: number = this.pageWidth / 30;
  public heightUnit: number = 10;
  public scaleFactor: number = 2;
  public appConstant = AppConstants;
  public pages: ReportPreviewPage[] = [];
  public selectedTest: any;
  public selectedAthlete: any;
  public organisation: any;
  public testSettings: any;
  public testMetabolic: any;
  public trainingZoneJsonData: any = null;
  public trainingZoneTableData: any[] = [];
  public trainingZoneTableHtml: string;
  public MAX_LOW_UP_TARGET: number = 6;
  public measuredValues: any[] = [];
  public weightedValues: any = {};
  public isSpeed: boolean = false;
  public isPPD: boolean = false;
  public rawTestDataTable: any = null;
  public calculatedDataTable: any = null;
  public linearRelationship: any = null;
  public primary_unit: string = '';
  public bodyCompositionChart: any = null;
  public metabolicFingerprintChart: any = null;
  public performanceDevelopmentChart: any = null;
  public gaugeChartData: any = null;
  public metabolicProfileData: any = null;
  public bodyComposition: any;
  public resultsReady: boolean = false;
  public isGeneratingReport: boolean = false;
  public reportBuildingBlocks = ReportBuildingBlocks;
  public showFeatureFlag: boolean = false;
  public isDefaultText: boolean = true;
  public defaultTextList: any;
  protected readonly AppConstants = AppConstants;

  constructor(
    private readonly rout: ActivatedRoute,
    private readonly router: Router,
    private readonly reportPreviewService: ReportPreviewService,
    private readonly apiService: ApiService,
    private readonly chartService: ChartService,
    public readonly testService: TestService,
    private readonly reportTemplateService: ReportTemplateService,
    private readonly trainingZonesService: TrainingZonesComponentService,
  ) {
    super();
  }

  public onInitPage(): void {
    this.rout.params.subscribe((params: Params): void => {
      this.testId = Number(params.testId);
    });

    this.rout.queryParams.subscribe((params: Params) => {
      this.athleteId = Number(params.athleteId);
      this.reportTemplateId = Number(params.templateId);
      this.showFeatureFlag = params.feature === 'true';
    });

    const listTests: Observable<Object>[] = [
      this.apiService.get(`tests/?athlete_id=${this.athleteId}&page=1&ordering=-id`, false),
      this.apiService.get(`organizations/${this.auth.organization.id}`, false),
      this.apiService.get(`test-settings/?test_id=${this.testId}&training_zone_template_id=${this.selectedTest?.training_zone_template}`),
      this.apiService.get(`tests/metabolic/${this.testId}/${this._getAdditionQueryParamMetabolic()}`, false),
      this.reportTemplateService.getReportTemplate(this.reportTemplateId),
      this.apiService.get(`default-text`, false),
    ];

    this.apiService.get(`tests/?id=${this.testId}`, false)
      .subscribe((res: any): void => {
        this.selectedTest = res?.results[0] as TestDto;
        forkJoin(listTests)
          .subscribe(([
                        athleteResponse,
                        organisationsResponse,
                        testSettingsResponse,
                        testMetabolicResponse,
                        reportTemplateResponse,
                        defaultText,
                      ]): void => {
            this.selectedAthlete = athleteResponse;
            this.organisation = organisationsResponse;
            this.testSettings = (testSettingsResponse as any).results[0];
            this.testMetabolic = testMetabolicResponse;
            this.bodyComposition = (testMetabolicResponse as [any])[0]?.table_data;
            this.reportTemplate = reportTemplateResponse as ReportTemplate;
            this.defaultTextList = (defaultText as any).results[0];
            this.pages = this.reportPreviewService.getPagesFromTemplate(this.reportTemplate);
            this.pageHeight = this.reportPreviewService.calculatePageHeight(this.reportTemplate, this.heightUnit, this.defaultPageHeight);
            this.resultsReady = true;

            const chartData  = {
              id: this.selectedTest.id,
              test_type: this.selectedTest.test_type,
              data: this.testMetabolic,
              test: this.selectedTest,
              athlete: this.selectedTest.athlete,
              test_settings: this.testSettings,
              is_chart_changed: false,
            };

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.METABOLIC_FINGERPRINT)) {
              setTimeout((): void => {
                this._renderMetabolicFingerprintChart(chartData);
              }, 300);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.BODY_COMPOSITION)) {
              setTimeout((): void => {
                this._renderBodyCompositionChart(chartData);
              }, 300);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.PERFORMANCE_DEVELOPMENT)) {
              setTimeout((): void => {
                this._renderPerformanceDevelopmentChart(chartData);
              }, 300);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.PHYSIOLOGICAL_PERFORMANCE)) {
              setTimeout((): void => {
                this._renderMetabolicCapacitiesChart(chartData);
              }, 300);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.METABOLIC_POWER)) {
              setTimeout((): void => {
                this._renderMetabolicPowerChart(chartData);
              }, 300);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.METABOLIC_PROFILE)) {
              setTimeout((): void => {
                this._renderMetabolicProfile(chartData);
              }, 300);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.TEST_DATA_GRAPHS)) {
              setTimeout((): void => {
                this._renderTestDataChart(chartData);
              }, 500);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.ECONOMY)) {
              setTimeout((): void => {
                this._renderEconomy(chartData);
              }, 300);
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.TEST_DATA_TABLES)) {
              setTimeout((): void => {
                this._renderTables(chartData);
              });
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.TRAINING_ZONE)) {
              setTimeout((): void => {
                this._renderTrainingZone();
              });
            }

            if (this.reportTemplate?.fields?.some((item: ReportField): boolean => item.id === ReportBuildingBlocks.HEART_RATE)) {
              setTimeout((): void => {
                this._renderHeartRate();
              }, 300);
            }
          });
      });

  }

  public onPrint(): void {
    this.generateBackendPdf();
  }

  public generateBackendPdf(): void {
    const testId = this.rout.snapshot.params.testId;
    const { templateId, athleteId } = this.rout.snapshot.queryParams;

    const chartData: Record<string, any> = {
      id: this.selectedTest.id,
      test_type: this.selectedTest.test_type,
      data: this.testMetabolic,
      test: this.selectedTest,
      athlete: this.selectedTest.athlete,
      test_settings: this.testSettings,
      is_chart_changed: false,
    };

    let coachFullName: string = "";
    if (this.selectedTest.coach.first_name) {
      coachFullName += this.selectedTest.coach.first_name;
    }
    if (this.selectedTest.coach.last_name) {
      coachFullName = coachFullName ? coachFullName + ` ${this.selectedTest.coach.last_name}` : this.selectedTest.coach.last_name;
    }

    const testInfo: Record<string, any> = {
      testDisplayId: this.selectedTest.display_id,
      testDate: moment(this.selectedTest.test_date).format(this.constant.DATE_FORMAT.APP_FULL_DATE_MOMENT),
      sportName: this.selectedTest.sport.name,
      testType: this.testService.formatTestType(this.selectedTest.test_type),
      athleteFullName: `${this.selectedTest.athlete.first_name} ${this.selectedTest.athlete.last_name}`,
      coachFullName,
    };


    const postData: Record<string, any> = {
      chartData,
      isSpeed: this.isSpeed,
      testInfo,
      text: "This was sent from the frontend call",
      template_id: parseInt(templateId),
      athlete_id: parseInt(athleteId),
      metabolic_fingerprint: this.getCanvasData('#metabolic_fingerprint_radar'),
      metabolic_power: this.getCanvasData('#metabolic_power'),
      performance_development: this.getCanvasData("#performance_development canvas.bk-canvas"),
      economy: this.getCanvasData('#economy-chart'),
      heart_rate: this.getHeartRate(),
      body_composition: {
        humanBodyCtx: this.getCanvasData('#humanBodyCtx'),
        body_composition: this.getCanvasData('#body_composition canvas.bk-canvas'),
        body_composition_data: this.bodyComposition
      },
      metabolic_capacities: this.getMetabolicCapacitiesCanvasData(),
      metabolic_profile: this.getMetabolicProfile('#metabolic_profile canvas.bk-canvas'),
      textData: this.getTextData(),
      training_zone: {
        jsonData: this.trainingZoneJsonData,
        tableData: this.trainingZoneTableData,
        tableHtml: this.trainingZoneTableHtml
      }
    };

    const testData: Record<string, any>[] = this.getTestData(chartData);
    if (testData != null && testData.length > 0) {
      postData.test_data = testData;
    }

    this.isGeneratingReport = true;
    this.apiService.post(`tests/${testId}/report/`, postData, false, {
      responseType: 'arraybuffer' as 'json'
    }).subscribe((response: any): void => {
      this.isGeneratingReport = false;
      let file: Blob = new Blob([response], { type: 'application/pdf' });
      var fileURL: string = URL.createObjectURL(file);
      window.open(fileURL);
    });
  }

  private getCanvasData(queryString: string): string | null {
    const canvas: HTMLCanvasElement = document.querySelector(queryString) as HTMLCanvasElement;
    return canvas ? canvas.toDataURL() : null;
  }

  private getMetabolicProfile(queryString: string): Array<Record<string, any>> {
    const plots = this.metabolicProfileData?.data?.plots;
    if (plots == null || plots.length === 0) {
      return [];
    }

    return Array.from(document.querySelectorAll(queryString))
      .filter((canvas: Element): boolean => canvas != null)
      .filter((canvas: any, index: number) => {
        const excludeIndices: any[] = [];
        if (!this.rights.metabolic_profiling_fat_carbohydrate) {
          excludeIndices.push(5);
        }
        if (!this.rights.metabolic_profiling_aerobic_anaerobic) {
          excludeIndices.push(4);
        }
        return !excludeIndices.includes(index);
      })
      .map((canvas: any, index: number) => {
        //Manually fix title if the last graph is excluded
        let title = plots[index]?.title_text;
        if (!this.rights.metabolic_profiling_aerobic_anaerobic && index === 4) {
          title = plots[index+1]?.title_text;
        }

        return {
          title,
          canvas: (canvas as HTMLCanvasElement).toDataURL()
        };
      });
  }

  private getMetabolicCapacitiesCanvasData(): Array<Record<string, any>> {
    return (this.gaugeChartData ?? [])
      .map((data: any, index: number) => {
        const canvas: HTMLCanvasElement = document.querySelector(`#gauge-${index}`) as HTMLCanvasElement;
        return {
          ...data,
          canvas: canvas ? canvas.toDataURL() : null
        };
      })
      .filter((canvasData: string): boolean => canvasData != null);
  }

  private getTextData(): Array<{id: string | undefined, content: string}> {
    const otherFields: NodeListOf<Element> = document.querySelectorAll('app-edit-text-preview .editable-area div.text');
    const textData: Array<{id: string | undefined, content: string}> = [];
    otherFields.forEach((element: Element): void => {
      const htmlElement: HTMLElement = element as HTMLElement;
      textData.push({
        id: htmlElement.parentElement?.id,
        content: htmlElement.innerHTML
      });
    });
    return textData;
  }

  private getHeartRate(): Record<string, any> {
    return {
      no_data: this.linearRelationship == null || this.linearRelationship?.intercept == null,
      is_ppd: this.isPPD,
      slope: this.linearRelationship?.slope,
      primary_unit: this.primary_unit,
      intercept: this.linearRelationship?.intercept,
      r2_value: this.linearRelationship?.r2_value,
      max_heart_rate: this.linearRelationship?.max_heart_rate?.toFixed(0),
      best_delay_time: this.linearRelationship?.best_delay_time,
      canvas: this.getCanvasData('#td-heart-rate-chart canvas.bk-canvas')
    };
  }

  private getTestData(chartData: Record<string, any>): Array<Record<string, any>> {
    const canvases: NodeListOf<Element> = document.querySelectorAll("#test_data canvas.bk-canvas");
    const testData = chartData.data[6];
    let plots: Array<Record<string, any>> = [];
    try {
      plots = testData["plots"];
    } catch (e) {
      plots = testData[0]["plots"];
    }

    const apiTestData: Array<Record<string, any>> = [];
    if (plots == null) {
      if (canvases.length === 0) {
        return [];
      }

      plots = [];
      const canvas: HTMLCanvasElement = canvases[0] as HTMLCanvasElement;
      apiTestData.push({
        canvas: canvas ? canvas.toDataURL() : null
      });
    }

    plots.forEach((plot: Record<string, any>, index: number): void => {
      const group_header: string = index === 0 ? "Determination of metabolic demand" : "Determination of lactate accumulation";
      const canvas: HTMLCanvasElement = canvases[index] as HTMLCanvasElement;
      apiTestData.push({
        display_chart: plot.display_chart,
        header_text: plot.header_text,
        title_text: plot?.title_text,
        group_header,
        canvas: canvas ? canvas.toDataURL() : null
      });
    });

    return apiTestData;
  }

  private _getAdditionQueryParamMetabolic(): string {
    const urlSplit: string[] = this.router?.url?.split('/');
    if (urlSplit?.length >= 3) {
      const url: string = `/${urlSplit[1]}/${urlSplit[2]}`;
      let queryParam: string = '?data=';
      switch (url) {
        case '/apc/setcard':
        case '/apc/load-characteristics':
        case '/apc/test-data':
        case '/apc/speed-relationships':
        case '/apc/economy':
        case '/apc/time-to-depletion':
        case '/apc/recovery-matrix':
        case '/apc/training-zones':
          queryParam = queryParam + urlSplit[2];
          break;
        default:
          queryParam = '';
          break;
      }
      return queryParam;
    }
    return '';
  }

  private _renderMetabolicFingerprintChart(event: any): void {
    try {
      const request = {
        data: event.data[3],
        testId: event.id,
        elementId: ChartConstants.METABOLIC_FINGERPRINT.ELEMENT_ID,
        type: ChartConstants.METABOLIC_FINGERPRINT.ID,
        test_type: event.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
        isPdfPreview: true
      };

      this.metabolicFingerprintChart = this.chartService.ChartBokeh(request, true);
    } catch (error) {
      console.log('renderMetabolicFinderprintChart failed.', error);
    }
  }

  private _renderBodyCompositionChart(event: any): void {
    try {
      const request = {
        data: event.data[0],
        testId: event.id,
        elementId: ChartConstants.BODY_COMPOSITION.ELEMENT_ID,
        type: ChartConstants.BODY_COMPOSITION.ID,
        test_type: event.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
        isPdfPreview: true
      };

      this.bodyCompositionChart = this.chartService.ChartBokeh(request, true);
      this._renderHumanBody(event);

    } catch (error) {
      console.log('renderBodyCompositionChart failed.', error);
    }
  }

  private _renderHumanBody(event: any): void {
    try {
      const tableData = event.data[0].table_data;
      const humanAttributes = [
        {
          name: 'non H2O',
          value: tableData['body_attributes']['non_h2o_space'],
        },
        {
          name: 'H2O',
          value: tableData['body_attributes']['h2o_space']},
        {
          name: 'passive lactate',
          value: tableData['body_attributes']['passive_lactate_space'],
        },
        {
          name: 'active muscle',
          value: tableData['body_attributes']['active_muscle_space'],
        },
      ];

      const canvasEle: HTMLElement | null = document.getElementById('humanBodyCtx');
      this.chartService.drawHumanBody(canvasEle, humanAttributes, 'LIGHT', 500, 600);

    } catch (e) {
      console.log('Cannot render human body shape chart.', e);
    }
  }

  private _renderPerformanceDevelopmentChart(event: any): void {
    try {
      const request = {
        data: event.data[4],
        testId: event.id,
        elementId: ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID,
        type: ChartConstants.PERFORMANCE_DEVELOPMENT.ID,
        test_type: event.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
        isPdfPreview: true
      };

      this.performanceDevelopmentChart = this.chartService.ChartBokeh(request, true);
    } catch (error) {
      console.log('renderPerformanceDevelopmentChart failed.', error);
    }
  }

  private _renderMetabolicCapacitiesChart(event: any): void {
    try {
      const plots = event.data[1].plots;
      const gaugeChartItems: any = [];
      plots.forEach((currentPlot: any): void => {
        try {
          const gaugeChartItem = {
            min: 0,
            max: 100,
            current: currentPlot.circles[0].source.percentage[0],
            title: currentPlot.title_text,
            value: null,
            unit: null,
            prefix: null,
            value_alternative_1: null,
            unit_alternative_1: null,
            prefix_alternative_1: null,
            value_alternative_2: null,
            unit_alternative_2: null,
            prefix_alternative_2: null,
            is_heart_rate:
              currentPlot.title_text.toLowerCase().indexOf('heart rate') > -1,
          };

          // Parse prefix / value / unit
          const values = (currentPlot.circles[0].source.names[0] || '').split(
            ' ',
          );
          let isValueContainsPrefix =
            currentPlot.circles[0].source.names[0].indexOf('elative') > -1 ||
            currentPlot.circles[0].source.names[0].indexOf('otal') > -1;
          const param = {
            prefixField: 'prefix',
            valueField: 'value',
            unitField: 'unit',
          };
          this._parse_Prefix_Value_Unit(gaugeChartItem, values, isValueContainsPrefix, param,);

          if (currentPlot.circles.length > 1) {
            // Parse prefix / value / unit
            const values_alt = (
              currentPlot.circles[1].source.names[0] || ''
            ).split(' ');
            isValueContainsPrefix =
              currentPlot.circles[1].source.names[0].indexOf('elative') > -1 ||
              currentPlot.circles[0].source.names[0].indexOf('otal') > -1;
            const param_atl = {
              prefixField: 'prefix_alternative_1',
              valueField: 'value_alternative_1',
              unitField: 'unit_alternative_1',
            };
            this._parse_Prefix_Value_Unit(gaugeChartItem, values_alt, isValueContainsPrefix, param_atl,);
          }
          if (currentPlot.circles.length > 2) {
            // Parse prefix / value / unit
            const values_alt = (
              currentPlot.circles[2].source.names[0] || ''
            ).split(' ');
            isValueContainsPrefix =
              currentPlot.circles[2].source.names[0].indexOf('elative') > -1 ||
              currentPlot.circles[0].source.names[0].indexOf('otal') > -1;
            const param_atl = {
              prefixField: 'prefix_alternative_2',
              valueField: 'value_alternative_2',
              unitField: 'unit_alternative_2',
            };
            this._parse_Prefix_Value_Unit(gaugeChartItem, values_alt, isValueContainsPrefix, param_atl,);
          }
          gaugeChartItems.push(gaugeChartItem);
        } catch (e1) {
          console.log(e1);
        }
      });
      this.gaugeChartData = gaugeChartItems;

      const request = {
        data: this.gaugeChartData,
      };
      setTimeout((): void => {
        this.chartService.generateMetabolicCapacitiesGaugeChart(request);
      }, 200);
    } catch (error) {
      this.gaugeChartData = [];
      console.log('renderMetabolicCapacitiesChart failed.', error);
    }
  }

  private _parse_Prefix_Value_Unit(
    gaugeChartItem: any,
    values: any,
    isValueContainsPrefix: boolean,
    param: { prefixField: string; valueField: string; unitField: string },
  ): void {
    if (values.length >= 3 && isValueContainsPrefix) {
      gaugeChartItem[param.prefixField] = values[0];
      gaugeChartItem[param.valueField] = values[1];
      gaugeChartItem[param.unitField] = values.slice(2).join(' ');
    } else if (values.length >= 3 && !isValueContainsPrefix) {
      gaugeChartItem[param.valueField] = values[0];
      gaugeChartItem[param.unitField] = values.slice(1).join(' ');
    } else if (values.length == 2) {
      gaugeChartItem[param.valueField] = values[0];
      gaugeChartItem[param.unitField] = values[1];
    } else {
      gaugeChartItem[param.valueField] = values[0];
    }
  }

  private _renderMetabolicPowerChart(event: any): void {
    try {
      const tableData = event.data[0].table_data;
      const data = {
        data: [tableData['metabolic_power']],
        simulation_type: event.test.sport.simulation_type,
        test: event.test,
      };
      this.chartService.generateMetabolicPower(data);
    } catch (error) {
      console.log('renderMetabolicPowerChart failed.', error);
    }
  }

  private _renderMetabolicProfile(event: any): void {
    this.metabolicProfileData = {
      data: event.data[2],
      testId: event.id,
      elementId: ChartConstants.LOAD_CHARACTERISTICS.ELEMENT_ID,
      type: ChartConstants.LOAD_CHARACTERISTICS.ID,
      test_type: event.test_type,
      legend: 'legends',
      mappingData: ChartMappingData,
      test: event.test,
      isPdfPreview: true
    };
  }

  private _renderTestDataChart(event: any): void {
    const testData = event.data[6];
    let test_type = event.test_type;
    if (testData && (testData['cp19'] || (testData[0] && testData[0].cp19))) {
      test_type = AppConstants.TEST_TYPES.PPD;
    }

    const request = {
      data: testData,
      testId: event.id,
      elementId: ChartConstants.TEST_DATA.ELEMENT_ID,
      type: ChartConstants.TEST_DATA.ID,
      test_type: test_type,
      legend: 'legends',
      mappingData: ChartMappingData,
      test: event.test,
      isPdfPreview: true
    };

    this.chartService.ChartBokeh(request);
  }

  private _renderEconomy(testData: any): void {
    const chartTypes = [{value: 1, name: '%VO2tot',}, {value: 2, name: 'Power',}];
    if (testData.test.sport.simulation_type === AppConstants.SIMULATE_TYPE.SPEED) {
      chartTypes[1].name = 'Speed';
    } else {
      chartTypes[1].name = 'Power';
    }

    setTimeout((): void => {
      const data = {
        economy_values: testData.data[6].economy_values,
        simulation_type: testData.test.sport.simulation_type,
        isConvert: false,
      };
      this.chartService.generateEconomyChart(data);
    }, 500);
  }

  private _renderTables(event: any): void {
    this.primary_unit = this.chartService.findXAxisLabel(event.test.sport.primary_type);
    if (event?.test?.sport) {
      this.isSpeed = event.test.sport.simulation_type == AppConstants.SIMULATE_TYPE.SPEED;
    }

    if (event?.test?.is_ppd) {
      this._renderTablePPD(event);
    } else if (event.data[6] && event.data[6].cp19 && event.data[6].cp19.measured_values) {
      this._renderTablePPD(event);
    } else if (event.data[6].table_data) {
      if (event?.test?.use_old_raw_test_data_table) {
        this.rawTestDataTable = JSON.parse(event.data[6].table_data);
        this.rawTestDataTable.data = _.map(
          this.rawTestDataTable.data,
          this._processTableData
        );
      } else {
        this.rawTestDataTable = JSON.parse(
          event.data[6].table_data.measured_values_table
        );
        this.rawTestDataTable.data = _.map(
          this.rawTestDataTable.data,
          this._processTableData
        );

        this.calculatedDataTable = JSON.parse(
          event.data[6].table_data.calculated_values_table
        );
        this.calculatedDataTable.data = _.map(
          this.calculatedDataTable.data,
          this._processTableData
        );
      }
    }
  }

  private _processTableData(item: any, key: number) {
    if (key === 0) {
      return item;
    }

    return item;
  }

  private _renderTablePPD(event: any): void {
    const cp19 = event.data[6]['cp19'] || event.data[6][0].cp19;
    if (cp19) {
      this.measuredValues = cp19.measured_values;
      this.weightedValues = cp19.weighted_values;
    } else {
      console.log('Cannot render table ppd, please check detail.');
    }
  }

  private _renderTrainingZone(): void {
    if (!this.trainingZoneJsonData) {
      this.trainingZoneJsonData = this.trainingZonesService.convertZeroValueToString(
        this.testMetabolic[5].json_data
      );
      if (this.trainingZoneJsonData) {
        this.trainingZoneJsonData.map((item: any) => {
          item.upper_length = this.trainingZonesService.getColspan(item.upper);
          item.lower_length = this.trainingZonesService.getColspan(item.lower);
          item.target_length = this.trainingZonesService.getColspan(item.target);
          return item;
        });
      }
    }

    if (this.trainingZoneJsonData) {
      this.trainingZoneTableData = this.trainingZonesService.convertJsonDataToTable(
        this.trainingZoneJsonData
      );
    }

    if (!this.trainingZoneTableHtml) {
      this.trainingZoneTableHtml = this.testMetabolic[5].table_data;
    }
  }

  private _renderHeartRate(): void {
    const heartRateField: ReportField = this.reportTemplate.fields.find((field: ReportField): boolean => field.id === ReportBuildingBlocks.HEART_RATE)!;
    const tabs = this.testMetabolic[6];
    const chartWidth: number = heartRateField?.width * this.widthUnit * this.scaleFactor - 20;
    const chartHeight: number = heartRateField?.height * this.heightUnit - 160;

    if (this.selectedTest.is_ppd) {

      const data = {
        test_id: this.selectedTest.id,
        window_size: Math.round(heartRateField?.width * this.widthUnit * this.scaleFactor)
      };

      this.testService.genChartTZBHeartRate(data).subscribe((response) => {
        if (response && response.have_hr_data === false) {
          this.linearRelationship = null;
          const chartElem: HTMLElement = document.getElementById('td-heart-rate-chart')!;
          chartElem.innerHTML = '';
          return;
        }

        if (response) {
          this.linearRelationship = response;
          if (this.linearRelationship && this.linearRelationship.intercept) {
            this.linearRelationship.intercept = parseFloat(
              this.linearRelationship.intercept.toFixed(2)
            );
          }
          this.chartService.generateHeartRatePlot(response, this.selectedTest, chartWidth, chartHeight);
        }
      });

    } else {

      if (tabs && Array.isArray(tabs) && tabs.length && tabs[0].linear_relationship) {
        this.chartService.generateHeartRatePlot(tabs[0].linear_relationship, this.selectedTest, chartWidth, chartHeight);
      } else if (tabs && !Array.isArray(tabs) && tabs.linear_relationship) {
        this.chartService.generateHeartRatePlot(tabs.linear_relationship, this.selectedTest, chartWidth, chartHeight);
      }
      this.linearRelationship = tabs.linear_relationship;
    }
  }

  public getWidthOutputValue() {
    return this.trainingZonesService.getWidthOutputValue(this.trainingZoneJsonData);
  }

  public getMaxOutputValues(): number {
    return this.trainingZonesService.getMaxOutputValues(this.trainingZoneJsonData);
  }

  public getOrganizationText(field: any) {
    return (this.defaultTextList ?? {})[field.textSettingType] ?? "";
  }

  public get maxLowUpTarget() {
    return this.MAX_LOW_UP_TARGET;
  }

  public getPdfGenerateButtonText(): string {
    return this.isGeneratingReport ? "Generating PDF..." : "Generate PDF";
  }
}
