export interface IDeserializable {
    deserialize(input: any): this;
    clone(): any;
}

export class Deserializable implements IDeserializable {
    clone(): any {
        throw new Error('');
    }
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
