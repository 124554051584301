<form class="training-builder" [formGroup]="addForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div id="training-zone-template-builder" *ngIf="isLoaded | async">
        <div mat-dialog-content>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always" class="top-input">
                    <mat-label>Name</mat-label>
                    <input type="text" matInput placeholder="" formControlName="name">
                    <mat-error *ngIf="addForm.controls?.name?.errors?.required">
                      Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col">
                  <app-autocomplate [label]="'Sports'" [form]="addForm.get('sport')" [options]="sports" (optionChange)="onSelectSport()" [showErrorRequired]="addForm.controls.sport.errors?.required"></app-autocomplate>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" floatLabel="always" class="top-input">
                    <mat-label>Description</mat-label>
                    <textarea rows="5" matInput formControlName="description"></textarea>
                    <mat-error *ngIf="addForm.controls?.description?.errors?.required">
                      Description is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="d-flex flex-wrap slide-toggle-container mt-2 mb-4">
                    <div *ngIf="auth && auth.role.is_owner">
                      <mat-slide-toggle (change)="onChangeDefault($event)" formControlName="isDefault">Use default</mat-slide-toggle>
                    </div>
                    <div>
                      <mat-slide-toggle formControlName="isActive">Active</mat-slide-toggle>
                    </div>
                    <div *ngIf="auth && auth.role.is_owner">
                      <mat-slide-toggle formControlName="isPublic">Public for all users</mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
          <div class="builder-rea">
            <a class="default" mat-raised-button (click)="addNewZone()">
              {{ 'Add more' }}
            </a>
            <div formArrayName="zones">
              <div *ngFor="let zone of zones.controls; let i = index">
                  <fieldset [formGroupName]="i" class="zone-fieldset">
                  <legend>ZONE {{i + 1}}</legend>
                  <button type="button" mat-icon-button mat-raised-button (click)="deleteZone(i)" class="close-button">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon>
                  </button>
                  <div class="builder-column" style="flex: 0.75">
                    <mat-form-field class="example-full-width" appearance="outline" floatLabel="always">
                      <mat-label>Name</mat-label>
                      <input type="text" matInput placeholder="" formControlName="name">
                    </mat-form-field>
                  </div>
                  <div class="builder-column" style="flex: 0.25">
                    <mat-form-field class="example-full-width" appearance="outline" floatLabel="always">
                      <mat-label>Code</mat-label>
                      <input type="text" matInput formControlName="code" maxlength="3" placeholder="">
                    </mat-form-field>
                  </div>
                  <div class="builder-column ref-sys" style="flex: 1.5">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Ref-Sys</mat-label>
                      <mat-select formControlName="referenceSystem" multiple="true"
                        (selectionChange)="onSelectSystemReferenceChange($event, i)">
                        <mat-option *ngFor="let system of referenceSystems" [value]="system.value">{{system.viewValue}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="builder-column" style="flex: 1.25">
                    <app-autocomplate [label]="'Master metric'" [form]="zone.get('masterMetric')" [options]="filterMasterMetrics" (optionChange)="onSelectChangeMaterMetric($event, i)" [displayField]="'viewValue'"></app-autocomplate>
                  </div>
                  <div class="value-column" formArrayName="values" style="flex: 2">
                    <div *ngFor="let valueRow of getFormValues(i).controls; let j = index">
                      <div [formGroupName]="j" style="flex-direction: row; display: flex;">
                        <div class="builder-column" style="flex: 0.65">
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label *ngIf="currentMetric">Target ({{getCurrentMasterMetric(i)?.unit}})</mat-label>
                            <mat-label *ngIf="!currentMetric">Target</mat-label>
                            <input type="text" matInput placeholder="" formControlName="targetValue">
                          </mat-form-field>
                        </div>
                        <div class="builder-column" style="flex: 0.65">
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label *ngIf="currentMetric">Lower ({{getCurrentMasterMetric(i)?.unit}})</mat-label>
                            <mat-label *ngIf="!currentMetric">Lower</mat-label>
                            <input [attr.disabled]="isDisableValue() ? 'disabled' : null" type="text" matInput placeholder="" formControlName="lowerValue">
                          </mat-form-field>
                        </div>
                        <div class="builder-column" style="flex: 0.65">
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label *ngIf="currentMetric">Upper ({{getCurrentMasterMetric(i)?.unit}})</mat-label>
                            <mat-label *ngIf="!currentMetric">Upper</mat-label>
                            <input type="text" matInput placeholder="" formControlName="upperValue">
                          </mat-form-field>
                        </div>
                        <div style="flex: 1">
                          <div style="flex-direction: row; display: flex">
                            <div style="flex: 1">
                              <app-autocomplate [label]="'Additional Type'" [form]="valueRow.get('addValueSelect')" [options]="addValues" (optionChange)="onSelectChangeAddValue($event, i)" [displayField]="'viewValue'"></app-autocomplate>
                            </div>
                            <div style="flex: 1">
                              <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>Value ({{getCurrentAddValue(i)}})</mat-label>
                                <input type="text " matInput placeholder="" formControlName="addValueValue">
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="builder-column additional-metrics-output" style="flex: 1.5">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Additional output metrics</mat-label>
                      <mat-select multiple formControlName="outputValue" (selectionChange)="onSelectOutputValue($event)">
                        <mat-option *ngFor="let metric of filterAdditionalMetrics" [value]="metric.value">{{metric.viewValue}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button id="cancelButton" mat-raised-button (click)="closeDialog(false)" type="button">  {{ "Cancel" }}</button>
      <button id="saveButton" mat-raised-button color="primary" *ngIf="isEditTemplate()" type="submit">  {{ "Save" }}</button>
    </div>
  </mat-dialog-actions>
</form>
