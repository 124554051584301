import { Pipe, PipeTransform } from '@angular/core';
import { IListPlanItemCounts } from '@core/services/plan-purchase/plan-dto';

@Pipe({
  name: 'itemCountPlanPeriod',
})
export class ItemCountPlanPeriodPipe implements PipeTransform {
  public transform(
    value: number | undefined,
    listPlanItemCounts: Array<IListPlanItemCounts> | undefined,
    param: keyof IListPlanItemCounts
  ): number | undefined | string {
    if (value && listPlanItemCounts) {
      const period: IListPlanItemCounts | undefined = listPlanItemCounts.find((item: any): boolean => item.period === value);
      return period ? period[param] : '';
    }
    return '';
  }
}
