import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '@core/constants';
import { ApiBaseService } from '@core/services/api-base.service';
import { IIntegrationLink } from '@shared/interfaces/integration-link.interface';
import { IIntegrationTemplate } from '@shared/interfaces/integration-template.interface';
import { String } from 'typescript-string-operations';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-integration-cardioworld',
  templateUrl: './integration-cardioworld.component.html',
  styleUrls: ['./integration-cardioworld.component.scss']
})
export class IntegrationCardioworldComponent implements OnInit, OnChanges {
  @Input() public template!: IIntegrationTemplate | undefined;
  @Input() public integration: IIntegrationLink | undefined;

  public integrationForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiBaseService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateForm();
  }

  private initForm() {
    this.integrationForm = this.fb.group({
      email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
      is_active: new FormControl<boolean>(true),
    });
  }

  private populateForm() {
    if (!this.integration) {
      return
    }

    this.integrationForm.setValue({
      is_active: this.integration.is_active,
      email: this.integration.configuration?.email,
    })
  }

  public canSave(): boolean {
    return this.integrationForm.valid;
  }

  public save() {
    const saveData: Record<string, any> = {
      configuration: {
        email: this.integrationForm.get("email")?.value,
      },
      is_active: this.integrationForm.get("is_active")?.value,
    };

    if (this.integration != null) {
      return this.update(this.integration.id, saveData);
    }

    saveData.template = this.template?.id;
    return this.create(saveData);
  }

  private update(id: number, data: Record<string, any>) {
    const apiUrl: string = String.Format(AppConstants.API.INTEGRATIONS.LINKS.UPDATE, id);
    this.apiService.patchJson(apiUrl, data).subscribe({
      next: () => {
        this.snackBar.open("Cardioworld integration updated successfully", 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      },
      error: (err) => {
        this.snackBar.open(err, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    });
  }

  private create(data: Record<string, any>) {
    this.apiService.postJson(AppConstants.API.INTEGRATIONS.LINKS.CREATE, data).subscribe({
      next: () => {
        this.snackBar.open("Cardioworld integration created successfully", 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      },
      error: (err) => {
        this.snackBar.open(err, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    });
  }
}
