<div>
  <h2 mat-dialog-title>Athlete Category</h2>
  <mat-dialog-content>
    <div [formGroup]="form">
      <ng-container *ngFor="let item of listTrainingLevel; index as i">
        <div class="athlete-category-list-item" *ngIf="item?.is_default_level">{{ i + 1 }}. {{ item.value }}</div>
      </ng-container>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Athlete Category 1</mat-label>
          <input matInput formControlName="custom1" />
          <mat-error
            *ngIf="
              form.controls?.custom1?.errors && form.controls?.custom1?.touched
            ">
            <div
              [innerHTML]="
                form.controls.custom1.errors
                  | errorFormFormat: { fieldName: 'Athlete Category 1' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Athlete Category 2</mat-label>
          <input matInput formControlName="custom2" />
          <mat-error
            *ngIf="
              form.controls?.custom2?.errors && form.controls?.custom2?.touched
            ">
            <div
              [innerHTML]="
                form.controls.custom2.errors
                  | errorFormFormat: { fieldName: 'Athlete Category 2' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Athlete Category 3</mat-label>
          <input matInput formControlName="custom3" />
          <mat-error
            *ngIf="
              form.controls?.custom3?.errors && form.controls?.custom3?.touched
            ">
            <div
              [innerHTML]="
                form.controls.custom3.errors
                  | errorFormFormat: { fieldName: 'Athlete Category 3' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Athlete Category 4</mat-label>
          <input matInput formControlName="custom4" />
          <mat-error
            *ngIf="
              form.controls?.custom4?.errors && form.controls?.custom4?.touched
            ">
            <div
              [innerHTML]="
                form.controls.custom4.errors
                  | errorFormFormat: { fieldName: 'Athlete Category 4' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Athlete Category 5</mat-label>
          <input matInput formControlName="custom5" />
          <mat-error
            *ngIf="
              form.controls?.custom5?.errors && form.controls?.custom5?.touched
            ">
            <div
              [innerHTML]="
                form.controls.custom5.errors
                  | errorFormFormat: { fieldName: 'Athlete Category 5' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Athlete Category 6</mat-label>
          <input matInput formControlName="custom6" />
          <mat-error
            *ngIf="
              form.controls?.custom6?.errors && form.controls?.custom6?.touched
            ">
            <div
              [innerHTML]="
                form.controls.custom6.errors
                  | errorFormFormat: { fieldName: 'Athlete Category 6' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button
        id="saveButton"
        mat-raised-button
        color="primary"
        (click)="save()">Save
      </button>
      <button id="cancelButton" mat-raised-button (click)="close()" type="button">
        Cancel
      </button>
    </div>
  </mat-dialog-actions>
</div>
