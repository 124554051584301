import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ManageOrganizationsService } from "@core/services/manage-organizations/manage-organizations.service";
import { ManageOrganizationDto } from "@core/services/manage-organizations/manage-organization-dto";
import { SessionService } from "@core/services/session.service";

@Injectable()
export class IntegrationListResolver {
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private organizationService: ManageOrganizationsService = inject(ManageOrganizationsService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private sessionService: SessionService = inject(SessionService);

  public resolve(): Observable<ManageOrganizationDto | null> {
    const organisationApiKey: number = JSON.parse(this.sessionService.getCookie('auth')).organization.id;

    return this.organizationService.getOrganizationById(organisationApiKey)
      .pipe(
        catchError(error => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          return of(null);
        }
        )
      )
  }
}
