import { AfterViewInit, Component, inject, Input, OnInit } from '@angular/core';
import { EventConstants } from "@core/event-constants";
import { ChartService } from "@shared/services/chart.service";
import { EventService } from "@shared/services/event.service";
import * as _ from "lodash-es";
import { ChartConstants } from "@modules/apc/apc/data/chart-modules";
import { ChartMappingData } from "@modules/apc/apc/data/chart-mapping-data";
import { HelperService } from "@shared/services/helper.service";

@Component({
  selector: 'app-performance-development-chart',
  templateUrl: './performance-development-chart.component.html',
  styleUrls: ['./performance-development-chart.component.scss']
})
export class PerformanceDevelopmentChartComponent implements OnInit, AfterViewInit {
  private chartService: ChartService = inject(ChartService);
  private eventService: EventService = inject(EventService);
  private helperService: HelperService = inject(HelperService);


  public oldChartSettings: any = null;
  public uniqueChartPostfixId: string;
  public chartIdWithoutHash: string;
  public performanceDevelopmentComment: string = '';

  @Input() public config: any;
  @Input() public testId: number;
  @Input() public index: number;

  public ngOnInit(): void {
    this.generateChartPrefixId();
  }

  public ngAfterViewInit(): void {
    this.prepareChartSettings();
    this.renderPerformanceDevelopmentChart(this.config, this.testId, this.uniqueChartPostfixId);
    this.renderComments();
  }

  public generateChartPrefixId(): void {
    this.uniqueChartPostfixId = ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID+'_'+ this.helperService.generateRandomString();
    this.chartIdWithoutHash = this.uniqueChartPostfixId.substring(1);
  }

  private renderComments(): void {
    const pdSettings: string = this.chartService.event.test_settings.performance_development;
    if (pdSettings) {
      this.performanceDevelopmentComment = pdSettings;
    } else if (this.chartService.event.hasOwnProperty('default_text') && this.chartService.event.default_text && this.chartService.event.default_text.hasOwnProperty('performance_development')) {
      this.performanceDevelopmentComment = this.chartService.event.default_text.performance_development;
    }
    this.oldChartSettings = this.helperService.makeCopy(this.chartService.event.test_settings);
  }

  private renderPerformanceDevelopmentChart(plot: any, testId: number, elementId: string): void {
    try {
      const payload = {
        plot,
        elementId,
        testId,
        type: ChartConstants.PERFORMANCE_DEVELOPMENT.ID,
        legend: 'legends',
        mappingData: ChartMappingData,
      };
      this.chartService.generateSeparatePerformanceDevelopment(payload.plot, payload.elementId, payload.testId, false, this.index);

    } catch (error) {
      console.log('renderPerformanceDevelopmentChart failed with ID: ' + 55, error);
    }
  }

  public resetGraph(): void {
    this.chartService.event.test_settings.chart_settings = {};
    this.eventService.BroadcastEvent(EventConstants.APC.SAVE_SETTINGS, { isReset: true });
  }

  public processComment(): void {
    this.chartService.event.is_chart_changed = !_.isEqual(this.oldChartSettings, this.chartService.event.test_settings);
  }

  public prepareChartSettings(): void {
    if (this.chartService?.event?.id) {
      const performanceDevelopmentXSettings: string | null = localStorage.getItem('PDsettings_' + this.chartService?.event?.id,);
      if (performanceDevelopmentXSettings != null) {
        this.chartService.event.test_settings.chart_settings.PDsettings = JSON.parse(performanceDevelopmentXSettings,);
      }

      const performanceDevelopmentYSettings = localStorage.getItem('PDYsettings_' + this.chartService?.event?.id,);
      if (performanceDevelopmentYSettings != null) {
        this.chartService.event.test_settings.chart_settings.PDYsettings = JSON.parse(performanceDevelopmentYSettings);
      }
    }
  }
}
