import { Component, OnInit } from '@angular/core';
import { SearchTabsAbstract } from "@shared/abstracts/search-tabs.abstract";
import { ISearch } from "@shared/interfaces/search.interface";
import { IAdditionalTabConfig } from "@shared/interfaces/additional-tab-config.interface";

@Component({
  selector: 'app-my-inscyd',
  templateUrl: './my-inscyd.component.html',
  styleUrls: ['./my-inscyd.component.scss']
})
export class MyInscydComponent extends SearchTabsAbstract implements OnInit {
  constructor() {
    const searchConfig: ISearch & IAdditionalTabConfig  = {
      searchName: 'Search',
      matIcon: 'search',
      placeholder: 'Type here',
      propertyName: 'category_name',
      additionalTabName: 'All',
    };

    super(searchConfig);
  }
}
