import { Component, inject, Input } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-custom-back-button',
  templateUrl: './custom-back-button.component.html',
})
export class CustomBackButtonComponent {
  private router: Router = inject(Router);

  @Input() public customUrl: string;

  public back(): void {
    this.router.navigateByUrl(this.customUrl);
  }
}
