<!--<div class="suggested-features__content o-flex o-flex&#45;&#45;column" *ngIf="suggestedFeatures">-->
<!--  <p class="suggested-features__heading">{{title}}</p>-->
<!--  <swiper [config]="configSwiper">-->
<!--    <ng-template swiperSlide *ngFor="let feature of suggestedFeatures">-->
<!--      <app-tile [config]="feature"></app-tile>-->
<!--    </ng-template>-->
<!--  </swiper>-->
<!--</div>-->

<div class="feature-detail__dependencies o-flex o-flex--column">
  <div>
    <h1>Dependencies</h1>
  </div>
  <div class="o-flex feature-detail__dependency-container">
    <app-tile class="feature-detail__dependency-item" *ngFor="let feature of dependencyFeatureList" [config]="feature"></app-tile>
  </div>
</div>
