import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params } from "@angular/router";
import { UrlBuilderService } from "@shared/services/url-builder.service";

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
})
export class AdministrationComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private urlBuilder: UrlBuilderService = inject(UrlBuilderService);

  public customUrl: string;
  public tabIndexToReturn: number | undefined = undefined;

  public ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params: Params): void => {
        this.setTabIndexToReturn(params.tab_index_to_return);
        this.customUrl = this.urlBuilder.getCreatedCustomUrl('/home/my-inscyd', 'tab_index_to_return', this.tabIndexToReturn);
      });
  }

  public setTabIndexToReturn(returnIndex: number): void {
    this.tabIndexToReturn = returnIndex;
  }
}
