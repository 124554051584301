<div class="sport-dialog__section">
  <h2 mat-dialog-title>{{ data?.id ? "Edit Sports" : "Add Sports" }}</h2>
  <mat-dialog-content>
    <div class="infor__section" [formGroup]="formInfor">
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Name (*)</mat-label>
          <input matInput formControlName="name" />
          <mat-error
            *ngIf="
              formInfor.controls?.name?.errors &&
              formInfor.controls?.name?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.name.errors
                  | errorFormFormat: { fieldName: 'Name' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <app-chips
          [label]="'Additional Athlete Category'"
          [form]="formAthleteCategory.get('categories')"
          [options]="athleteCategoryOptions"
          [displayField]="'value'"
          (optionChange)="categoriesChange($event)"
        ></app-chips>
      </div>
      <div class="title-table primary-color">
        Chosen athlete categories will be added to every Metric Parameter in the
        table below with the default value of UL, OL and std dev is 10. You can
        update these numbers by expanding the parameter accordion.
      </div>
      <div class="field" *ngIf="!isReadOnly">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Sport type (*)</mat-label>
          <mat-select formControlName="simulation_type">
            <mat-option *ngFor="let item of sportTypeOptions" [value]="item.id"
              >{{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              formInfor.controls?.simulation_type?.errors &&
              formInfor.controls?.simulation_type?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.simulation_type.errors
                  | errorFormFormat: { fieldName: 'Sport type' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <span>Reference System (*) : </span>
      </div>
      <div class="field-col">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Primary Type</mat-label>
          <mat-select formControlName="primary_type">
            <mat-option [value]="item.id" *ngFor="let item of referenceOptions"
              >{{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              formInfor.controls?.primary_type?.errors &&
              formInfor.controls?.primary_type?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.primary_type.errors
                  | errorFormFormat: { fieldName: 'Primary type' }
              "></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Secondary type</mat-label>
          <mat-select formControlName="secondary_type">
            <mat-option
              [value]="item.id"
              *ngFor="let item of secondaryReferenceOptions"
              >{{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              formInfor.controls?.secondary_type?.errors &&
              formInfor.controls?.secondary_type?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.secondary_type.errors
                  | errorFormFormat: { fieldName: 'Secondary type' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>% of total muscle mass used(*)</mat-label>
          <input type="number" matInput formControlName="muscle_usage_percentage" />
          <span matSuffix>%</span>
          <mat-error
            *ngIf="
              formInfor.controls?.muscle_usage_percentage?.errors &&
              formInfor.controls?.muscle_usage_percentage?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.muscle_usage_percentage.errors
                  | errorFormFormat
                    : { fieldName: '% of total muscle mass used' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-col" *ngIf="auth.role.is_owner">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Min Value (*)</mat-label>
          <input matInput formControlName="min_value" type="number" />
          <span matSuffix> Watt or m/s</span>
          <mat-error
            *ngIf="
              formInfor.controls?.min_value?.errors &&
              formInfor.controls?.min_value?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.min_value.errors
                  | errorFormFormat: { fieldName: 'Min Value' }
              "></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Max Value (*)</mat-label>
          <input matInput formControlName="max_value" type="number" />
          <span matSuffix> Watt or m/s</span>
          <mat-error *ngIf="formInfor.controls?.max_value?.errors && formInfor.controls?.max_value?.touched">
            <div [innerHTML]="formInfor.controls.max_value.errors | errorFormFormat: { fieldName: 'Max value' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-col" *ngIf="!isReadOnly">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>VO Weight</mat-label>
          <input matInput formControlName="vo_weight" type="number" />
          <mat-error
            *ngIf="
              formInfor.controls?.vo_weight?.errors &&
              formInfor.controls?.vo_weight?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.vo_weight.errors
                  | errorFormFormat: { fieldName: 'VO Weight' }
              "></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>VL Weight</mat-label>
          <input matInput formControlName="vl_weight" type="number" />
          <mat-error
            *ngIf="
              formInfor.controls?.vl_weight?.errors &&
              formInfor.controls?.vl_weight?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.vl_weight.errors
                  | errorFormFormat: { fieldName: 'VL Weight' }
              "></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>AT Weight</mat-label>
          <input matInput formControlName="at_weight" type="number" />
          <mat-error
            *ngIf="
              formInfor.controls?.at_weight?.errors &&
              formInfor.controls?.at_weight?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.at_weight.errors
                  | errorFormFormat: { fieldName: 'AT Weight' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field" style="margin-bottom: 16px" *ngIf="!isReadOnly">
        <mat-slide-toggle
          labelPosition="before"
          class="full-width"
          formControlName="use_in_ppd"
          >Use in PPD</mat-slide-toggle>
      </div>
      <div class="field" *ngIf="!isReadOnly">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Duration for steady state (*)</mat-label>
          <input matInput formControlName="duration_steady_state" />
          <span matSuffix>Minutes</span>
          <mat-error
            *ngIf="
              formInfor.controls?.duration_steady_state?.errors &&
              formInfor.controls?.duration_steady_state?.touched
            ">
            <div
              [innerHTML]="
                formInfor.controls.duration_steady_state.errors
                  | errorFormFormat: { fieldName: 'Duration for steady state' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="setting__section" [formGroup]="formSetting">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowDetailSetting"
          (click)="isShowDetailSetting = true"
          >keyboard_arrow_right</mat-icon>
        <mat-icon
          *ngIf="isShowDetailSetting"
          (click)="isShowDetailSetting = false">keyboard_arrow_down</mat-icon>
        EDE & O2 per Watt settings
      </div>
      <div class="content" *ngIf="isShowDetailSetting">
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always">
            <mat-label>EDE-A (*)</mat-label>
            <input
              matInput
              formControlName="ebg_factor_a"
              placeholder="Factor value"
            />
            <mat-error
              *ngIf="
                formSetting.controls?.ebg_factor_a?.errors &&
                formSetting.controls?.ebg_factor_a?.touched
              ">
              <div
                [innerHTML]="
                  formSetting.controls.ebg_factor_a.errors
                    | errorFormFormat: { fieldName: 'EDE-A' }
                "></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always">
            <mat-label>EDE-B (*)</mat-label>
            <input
              matInput
              formControlName="ebg_factor_b"
              placeholder="Factor value"
            />
            <mat-error
              *ngIf="
                formSetting.controls?.ebg_factor_b?.errors &&
                formSetting.controls?.ebg_factor_b?.touched
              "
            >
              <div
                [innerHTML]="
                  formSetting.controls.ebg_factor_b.errors
                    | errorFormFormat: { fieldName: 'EDE-B' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>EDE-D (*)</mat-label>
            <input
              matInput
              formControlName="ebg_factor_d"
              placeholder="Factor value"
            />
            <mat-error
              *ngIf="
                formSetting.controls?.ebg_factor_d?.errors &&
                formSetting.controls?.ebg_factor_d?.touched
              "
            >
              <div
                [innerHTML]="
                  formSetting.controls.ebg_factor_d.errors
                    | errorFormFormat: { fieldName: 'EDE-D' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>O2 per watt EQ A(*)</mat-label>
            <input
              matInput
              formControlName="o2_per_watt_eq_a"
              placeholder="Factor value"
            />
            <mat-error
              *ngIf="
                formSetting.controls?.o2_per_watt_eq_a?.errors &&
                formSetting.controls?.o2_per_watt_eq_a?.touched
              "
            >
              <div
                [innerHTML]="
                  formSetting.controls.o2_per_watt_eq_a.errors
                    | errorFormFormat: { fieldName: 'O2 per watt EQ A' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>O2 per watt EQ B(*)</mat-label>
            <input
              matInput
              formControlName="o2_per_watt_eq_b"
              placeholder="Factor value"
            />
            <mat-error
              *ngIf="
                formSetting.controls?.o2_per_watt_eq_b?.errors &&
                formSetting.controls?.o2_per_watt_eq_b?.touched
              "
            >
              <div
                [innerHTML]="
                  formSetting.controls.o2_per_watt_eq_b.errors
                    | errorFormFormat: { fieldName: 'O2 per watt EQ B' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>O2 per watt EQ D(*)</mat-label>
            <input
              matInput
              formControlName="o2_per_watt_eq_d"
              placeholder="Factor value"
            />
            <mat-error
              *ngIf="
                formSetting.controls?.o2_per_watt_eq_d?.errors &&
                formSetting.controls?.o2_per_watt_eq_d?.touched
              "
            >
              <div
                [innerHTML]="
                  formSetting.controls.o2_per_watt_eq_d.errors
                    | errorFormFormat: { fieldName: 'O2 per watt EQ D' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Gross Efficiency</mat-label>
            <input
              matInput
              disabled="true"
              [value]="
                formSetting.controls.o2_per_watt_eq_b.value | grossEfficiency
              "
            />
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>O<sub>2</sub> constant term</mat-label>
            <input matInput disabled="true" value="16" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="condition__section" [formGroup]="formConditions">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowDetailCondition"
          (click)="isShowDetailCondition = true"
          >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowDetailCondition"
          (click)="isShowDetailCondition = false"
          >keyboard_arrow_down</mat-icon
        >
        EDE & O2 per Watt conditions
        <button
          *ngIf="isShowDetailCondition"
          style="margin-left: auto"
          mat-raised-button
          color="primary"
          class="mb-2"
          (click)="addCondition()"
        >
          Add Condition
        </button>
      </div>
      <div
        class="content"
        *ngIf="isShowDetailCondition"
        formArrayName="conditions"
      >
        <ng-container
          *ngFor="let condition of conditions.controls; let i = index"
        >
          <fieldset [formGroupName]="i" class="condition-group">
            <legend>Condition {{ i + 1 }}</legend>
            <button
              *ngIf="condition?.get('isDisabled')?.value === false"
              type="button"
              mat-icon-button
              class="close-button shadow-bg"
              (click)="removeCondition(i)"
            >
              <mat-icon aria-hidden="false" aria-label="Close">close</mat-icon>
            </button>
            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Condition (*)</mat-label>
                <input
                  matInput
                  formControlName="name"
                  placeholder="Condition Name"
                />
                <mat-error
                  *ngIf="
                    condition.get('name')?.errors &&
                    condition.get('name')?.touched
                  "
                >
                  <div
                    [innerHTML]="
                      condition?.get('name')?.errors
                        | errorFormFormat: { fieldName: 'Condition' }
                    "
                  ></div>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>EDE factor A (constant) (*)</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="ebg_factor_a"
                  placeholder="Factor value"
                />
                <mat-error
                  *ngIf="
                    condition.get('ebg_factor_a')?.errors &&
                    condition.get('ebg_factor_a')?.touched
                  "
                >
                  <div
                    [innerHTML]="
                      condition?.get('ebg_factor_a')?.errors
                        | errorFormFormat: { fieldName: 'EDE-A' }
                    "
                  ></div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>EDE factor B (linear) (*)</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="ebg_factor_b"
                  placeholder="Factor value"
                />
                <mat-error
                  *ngIf="
                    condition.get('ebg_factor_b')?.errors &&
                    condition.get('ebg_factor_b')?.touched
                  "
                >
                  <div
                    [innerHTML]="
                      condition.get('ebg_factor_b')?.errors
                        | errorFormFormat: { fieldName: 'EDE-B' }
                    "
                  ></div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>EDE factor D (cubic) (*)</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="ebg_factor_d"
                  placeholder="Factor value"
                />
                <mat-error
                  *ngIf="
                    condition.get('ebg_factor_d')?.errors &&
                    condition.get('ebg_factor_d')?.touched
                  "
                >
                  <div
                    [innerHTML]="
                      condition.get('ebg_factor_d')?.errors
                        | errorFormFormat: { fieldName: 'EDE-D' }
                    "
                  ></div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>O2 per watt EQ A(*)</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="o2_per_watt_eq_a"
                  placeholder="Factor value"
                />
                <mat-error
                  *ngIf="
                    condition.get('o2_per_watt_eq_a')?.errors &&
                    condition.get('o2_per_watt_eq_a')?.touched
                  "
                >
                  <div
                    [innerHTML]="
                      condition.get('o2_per_watt_eq_a')?.errors
                        | errorFormFormat: { fieldName: 'O2 per watt EQ A' }
                    "
                  ></div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>O2 per watt EQ B(*)</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="o2_per_watt_eq_b"
                  placeholder="Factor value"
                />
                <mat-error
                  *ngIf="
                    condition.get('o2_per_watt_eq_b')?.errors &&
                    condition.get('o2_per_watt_eq_b')?.touched
                  "
                >
                  <div
                    [innerHTML]="
                      condition.get('o2_per_watt_eq_b')?.errors
                        | errorFormFormat: { fieldName: 'O2 per watt EQ B' }
                    "
                  ></div>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>O2 per watt EQ D(*)</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="o2_per_watt_eq_d"
                  placeholder="Factor value"
                />
                <mat-error
                  *ngIf="
                    condition.get('o2_per_watt_eq_d')?.errors &&
                    condition.get('o2_per_watt_eq_d')?.touched
                  "
                >
                  <div
                    [innerHTML]="
                      condition.get('o2_per_watt_eq_d')?.errors
                        | errorFormFormat: { fieldName: 'O2 per watt EQ D' }
                    "
                  ></div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Gross Efficiency</mat-label>
                <input
                  type="number"
                  matInput
                  disabled="true"
                  [value]="
                    condition.get('o2_per_watt_eq_b')?.value | grossEfficiency
                  "
                />
              </mat-form-field>
            </div>
          </fieldset>
        </ng-container>
      </div>
    </div>

    <div class="title-table primary-color">
      * For each parameter, enable either Physiological Performance Benchmarks,
      Metabolic Fingerprint or Performance Development will make UL, OL and std
      dev values required.
    </div>
    <table
      mat-table
      [dataSource]="tableData"
      style="width: 100%"
      aria-describedby="list"
      class="table-data-section"
      multiTemplateDataRows
    >
      <!-- ID Column -->
      <ng-container matColumnDef="parameter">
        <th mat-header-cell *matHeaderCellDef scope="row">Parameter</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex">
            <mat-icon
              *ngIf="!element.showLevel"
              (click)="element.showLevel = true"
              >keyboard_arrow_right</mat-icon
            >
            <mat-icon
              *ngIf="element.showLevel"
              (click)="element.showLevel = false"
              >keyboard_arrow_down</mat-icon
            >
            <span [innerHTML]="element?.field"></span>
          </div>
        </td>
      </ng-container>

      <!-- unit Column -->
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef scope="row">Unit</th>
        <td mat-cell *matCellDef="let element">
          <span [innerHTML]="element?.unit"></span>
        </td>
      </ng-container>

      <!-- performance_capacities Column -->
      <ng-container matColumnDef="performance_capacities">
        <th mat-header-cell *matHeaderCellDef scope="row">
          Physiological Performance Benchmarks
        </th>
        <td mat-cell *matCellDef="let element">
          <input
            *ngIf="element.performance_capacities"
            type="checkbox"
            [(ngModel)]="element.performance_capacities.value"
            [disabled]="isReadOnly"
          />
        </td>
      </ng-container>

      <!--  metabolic_fingerprint Column -->
      <ng-container matColumnDef="metabolic_fingerprint">
        <th mat-header-cell *matHeaderCellDef scope="row">
          Metabolic Fingerprint
        </th>
        <td mat-cell *matCellDef="let element">
          <input
            *ngIf="element.metabolic_fingerprint"
            type="checkbox"
            [(ngModel)]="element.metabolic_fingerprint.value"
            [disabled]="isReadOnly"
          />
        </td>
      </ng-container>

      <!--  performance_development Column -->
      <ng-container matColumnDef="performance_development">
        <th mat-header-cell *matHeaderCellDef scope="row">
          Performance Development
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field
            appearance="outline"
            class="full-width sport-select"
            *ngIf="element?.performance_development"
          >
            <mat-select
              [(ngModel)]="element.performance_development"
              [compareWith]="objectComparisonFunction"
              [disabled]="isReadOnly"
            >
              <mat-option
                [value]="item"
                *ngFor="let item of performanceDevelopmentOptions"
                >{{ item.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- level -->
      <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef scope="row">Level</th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <!-- gender Column -->
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef scope="row">Gender</th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <!-- upper_boundary Column -->
      <ng-container matColumnDef="upper_boundary">
        <th mat-header-cell *matHeaderCellDef scope="row" class="text-center">
          Upper Boundary <br />
          {{
            formInfor.controls.primary_type.value | convertPrimaryTypeToUnit
          }}
        </th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <!-- lower_boundary Column -->
      <ng-container matColumnDef="lower_boundary">
        <th mat-header-cell *matHeaderCellDef scope="row" class="text-center">
          Lower Boundary <br />
          {{
            formInfor.controls.primary_type.value | convertPrimaryTypeToUnit
          }}
        </th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <!-- std_dev Column -->
      <ng-container matColumnDef="std_dev">
        <th mat-header-cell *matHeaderCellDef scope="row" class="text-center">
          std dev <br />
          {{
            formInfor.controls.primary_type.value | convertPrimaryTypeToUnit
          }}
        </th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <ng-container matColumnDef="levelDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <table
            *ngIf="element?.showLevel"
            mat-table
            [dataSource]="element.level"
            class="full-width table-level"
            aria-describedby="list"
            multiTemplateDataRows
          >
            <!-- ID Column -->
            <ng-container matColumnDef="parameter">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!-- unit Column -->
            <ng-container matColumnDef="unit">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!-- performance_capacities Column -->
            <ng-container matColumnDef="performance_capacities">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!--  metabolic_fingerprint Column -->
            <ng-container matColumnDef="metabolic_fingerprint">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!--  performance_development Column -->
            <ng-container matColumnDef="performance_development">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!-- level -->
            <ng-container matColumnDef="level">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level">
                <div class="d-flex">
                  <mat-icon
                    *ngIf="!level.showGender"
                    (click)="level.showGender = true"
                    >keyboard_arrow_right</mat-icon
                  >
                  <mat-icon
                    *ngIf="level.showGender"
                    (click)="level.showGender = false"
                    >keyboard_arrow_down</mat-icon
                  >
                  <span> {{ level?.value }}</span>
                </div>
              </td>
            </ng-container>

            <!-- gender Column -->
            <ng-container matColumnDef="gender">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!-- upper_boundary Column -->
            <ng-container matColumnDef="upper_boundary">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!-- lower_boundary Column -->
            <ng-container matColumnDef="lower_boundary">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!-- std_dev Column -->
            <ng-container matColumnDef="std_dev">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td mat-cell *matCellDef="let level"></td>
            </ng-container>

            <!-- Gender DEtail Column -->
            <ng-container matColumnDef="genderDetail">
              <th mat-header-cell *matHeaderCellDef scope="row"></th>
              <td
                mat-cell
                *matCellDef="let level"
                [attr.colspan]="displayedColumns.length"
              >
                <table
                  mat-table
                  [dataSource]="level.gender"
                  class="full-width table-level"
                  aria-describedby="list"
                >
                  <!-- ID Column -->
                  <ng-container matColumnDef="parameter">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender"></td>
                  </ng-container>

                  <!-- unit Column -->
                  <ng-container matColumnDef="unit">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender"></td>
                  </ng-container>

                  <!-- performance_capacities Column -->
                  <ng-container matColumnDef="performance_capacities">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender"></td>
                  </ng-container>

                  <!--  metabolic_fingerprint Column -->
                  <ng-container matColumnDef="metabolic_fingerprint">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender"></td>
                  </ng-container>

                  <!--  performance_development Column -->
                  <ng-container matColumnDef="performance_development">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender"></td>
                  </ng-container>

                  <!-- level -->
                  <ng-container matColumnDef="level">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender"></td>
                  </ng-container>

                  <!-- gender Column -->
                  <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender">
                      {{ gender?.sex }}
                    </td>
                  </ng-container>

                  <!-- upper_boundary Column -->
                  <ng-container matColumnDef="upper_boundary">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender">
                      <mat-form-field
                        appearance="outline"
                        class="sport-select"
                        [class.sport-invalid]="gender?.inValid_ol"
                      >
                        <input
                          matInput
                          type="number"
                          [(ngModel)]="gender.ol"
                          [disabled]="isCustomAthleteCategoryReadOnly(level)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <!-- lower_boundary Column -->
                  <ng-container matColumnDef="lower_boundary">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender">
                      <mat-form-field
                        appearance="outline"
                        class="sport-select"
                        [class.sport-invalid]="gender?.inValid_ul"
                      >
                        <input
                          matInput
                          type="number"
                          [(ngModel)]="gender.ul"
                          [disabled]="isCustomAthleteCategoryReadOnly(level)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <!-- std_dev Column -->
                  <ng-container matColumnDef="std_dev">
                    <th mat-header-cell *matHeaderCellDef scope="row"></th>
                    <td mat-cell *matCellDef="let gender">
                      <mat-form-field
                        appearance="outline"
                        class="sport-select"
                      >
                        <input
                          matInput
                          type="number"
                          [(ngModel)]="gender.std_dev"
                          [disabled]="isCustomAthleteCategoryReadOnly(level)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: ['genderDetail']"
              [class.display-none]="!row?.showGender"
            ></tr>
          </table>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['levelDetail']"
        [class.display-none]="!row?.showLevel"
      ></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div>
      <button id="cancelButton" mat-raised-button (click)="close()" type="button">
        Cancel
      </button>
      <button
        id="saveButton"
        mat-raised-button
        color="primary"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
