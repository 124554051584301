export const EventConstants = {
  APC: {
    SELECT_ATHLETE: 'SELECT_ATHLETE',
    SELECT_TEST: 'SELECT_TEST',
    ATHLETE_SIDEBAR_COLLAPSE: 'ATHLETE_SIDEBAR_COLLAPSE',
    ATHLETE_SIDEBAR_EXPAND: 'ATHLETE_SIDEBAR_EXPAND',
    MENU_SIDEBAR_COLLAPSE: 'MENU_SIDEBAR_COLLAPSE',
    MENU_SIDEBAR_EXPAND: 'MENU_SIDEBAR_EXPAND',
    SAVE_SETTINGS: 'SAVE_SETTINGS',
    GENERATE_REPORT: 'GENERATE_REPORT',
    SELECTED_TEST_ID: 'SELECTED_TEST_ID',
  },
  ACTIONS: {
    OPEN_ATHLETE_MODAL: 'OPEN_ATHLETE_MODAL',
  },
};
