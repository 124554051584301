import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { ActivatedFeatureInterface } from "@shared/interfaces/activated-feature.interface";
import { IFeature } from "@shared/components/interfaces/feature.interface";
import { String } from "typescript-string-operations";
import { UserWaitlistDto } from "@core/services/users/user-waitlist-dto";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { IFeaturePrice } from "@shared/interfaces/feature-price-list.interface";

@Injectable()
export class FeatureStoreService {
  private apiService: ApiService = inject(ApiService);

  public getFeatureList(): Observable<IFeature[]> {
    return this.apiService.get(AppConstants.API.FEATURE_ITEM.GET);
  }

  public getFeatureById(id: number): Observable<IFeature> {
    return this.apiService.get(`${AppConstants.API.FEATURE_ITEM.GET}${id}/`,);
  }

  public getFeatureBySlug(slug: string): Observable<IFeature> {
    return this.apiService.get(`${AppConstants.API.FEATURE_ITEM.GET}?feature_slug=${slug}`,);
  }

  public setToWaitList(id: number): Observable<Object> {
    return this.apiService.post(String.Format(AppConstants.API.FEATURE_WAIT_LIST.POST, id));
  }

  public getActivatedFeatureList(): Observable<ActivatedFeatureInterface[]> {
    return this.apiService.get(AppConstants.API.FEATURE_STORE.PURCHASED_FEATURE_LIST);
  }

  public getFeatureInWaitList(featureId: number, waitList: UserWaitlistDto[]): UserWaitlistDto | undefined {
    return waitList.find((feature: UserWaitlistDto): boolean => feature.id === featureId);
  }

  public getCurrencyNameByFeature(featureList: ITileConfig[] | IFeaturePrice[]): string {
    if (featureList.length) {
      return featureList[0].currencyName;
    }

    return 'N/A';
  }
}
