import { Component, DestroyRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { EventService } from '@shared/services/event.service';
import { SnackbarComponent } from '@shared/snackbar/snackbar.component';
import * as _ from 'lodash-es';
import {forkJoin, Observable} from 'rxjs';
import { AppConstants } from '@core/constants';
import { EventConstants } from '@core/event-constants';
import { ApiService } from '@core/services/api.service';
import { SessionService } from '@core/services/session.service';
import { String } from 'typescript-string-operations';
import { InformationReadComponent } from '../administration/information-popup/information-read/information-read.component';
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: 'app-new-app',
  templateUrl: './new-app.component.html',
})
export class NewAppComponent extends AppBaseComponent {
  public title: string = 'INSCYD';
  public currentTestId = '';
  public invoiceNotifications: any[] = [];
  public athleteNotifications: any[] = [];
  public generalBackButton: boolean;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  constructor(
    public sessionService: SessionService,
    public dialog: MatDialog,
    private _apiService: ApiService,
    private eventService: EventService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private destroyRef: DestroyRef,
    private router: Router,
    private _location: Location,
  ) {
    super();
  }

  public onInitPage(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params: Params): void => {
        this.generalBackButton = params.general_back_button === 'show';
      });

    if (!this.auth.role.is_owner && !this.auth.role.is_agent_role) {
      setTimeout(() => this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS))
      this.checkNotification();
      this.openModelInformationPopupUpdate();
    }

    this.eventService.GetEvent(EventConstants.APC.SELECT_TEST)
      .subscribe((event): void => {
        this.currentTestId = event.id;
      });
  }

  public toggleMenuBar(): void {
    if (this.sidenav.opened) {
      this.eventService.BroadcastEvent(
        EventConstants.APC.MENU_SIDEBAR_COLLAPSE,
        {}
      );
    } else {
      this.eventService.BroadcastEvent(
        EventConstants.APC.MENU_SIDEBAR_EXPAND,
        {}
      );
    }
    this.sidenav.toggle().then((): void => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  public openModelInformationPopupUpdate(): void {
    this._apiService.get(AppConstants.API.INFORMATION_POPUP.GET_BY_USER)
      .subscribe((response: any): void => {
        if (response?.text_content) {
          response.text_content = response.text_content.replaceAll(
            '\n',
            '<br/>'
          );
          let dialogRef: MatDialogRef<InformationReadComponent> = this.dialog.open(InformationReadComponent, {
            width: '40%',
            panelClass: 'general-dialog',
            disableClose: true,
            data: response,
          });

          dialogRef.afterClosed().subscribe((result): void => {
            if (result && result == 'refresh') {
              this.openModelInformationPopupUpdate();
            }
          });
        }
      });
  }

  public checkNotification(): void {
    const listApiRequest: Observable<Object>[] = [
      this._apiService.get(AppConstants.API.ATHLETE.GET_NOTIFICATIONS),
      this._apiService.get(AppConstants.API.INVOICES.PLANS),
    ];
    forkJoin(listApiRequest).subscribe((responses: any[]): void => {
      if (responses[0] && responses[0].length > 0) {
        this.athleteNotifications = responses[0];
      }

      if (responses[1] && responses[1].length > 0) {
        const period_type = responses[1].period_type;
        let period_type_text: string = 'month';
        if (period_type == 1) {
          period_type_text = 'year';
        } else if (period_type == 2) {
          period_type_text = 'quarter';
        }

        const message: string = 'Your account has exceeded {0} % of its allowed active {1} limit per ' + period_type_text;

        this.handlePushShowNofitication(responses[1], message);

        if (this.invoiceNotifications.length > 0) {
          this.invoiceNotifications.push(
            'Perhaps you wish to <a href="plan-subscription/plan-purchase">upgrade</a>'
          );
        }
      }

      this.showNotifications();
    });
  }

  private handlePushShowNofitication(data: any, message: string): void {
    _.forEach(data, (plan): void => {
      if (
        this.isValidCondition(plan.max_user, plan.notification_user, plan.total_user, plan.notification_user)) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_user, 'user'));
      }

      if (plan.max_athlete > 0 && plan.notification_athlete && (plan.total_athlete / plan.max_athlete) * 100 >= plan.notification_athlete) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_athlete, 'athlete'));
      }

      if (plan.max_test_la > 0 && plan.notification_test_la && (plan.total_test_la / plan.max_test_la) * 100 >= plan.notification_test_la) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_test_la, 'lactate test'));
      }

      if (plan.max_test_map && plan.notification_test_map && (plan.total_test_map / plan.max_test_map) * 100 >= plan.notification_test_map) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_test_map, 'PPD test'));
      }

      if (this.isValidCondition(plan.max_test_manual, plan.notification_test_manual, plan.total_test_manual, plan.notification_test_manual)) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_test_manual, 'manual test'));
      }

      if (
        this.isValidCondition(plan.max_test_virtual, plan.notification_test_virtual, plan.total_test_virtual, plan.notification_test_virtual)) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_test_virtual, 'virtual test'));
      }

      if (this.isValidCondition(plan.max_test_event, plan.notification_test_event, plan.total_test_event, plan.notification_test_event)
      ) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_test_event, 'event test'));
      }

      if (this.isValidCondition(plan.max_test_result, plan.notification_test_result, plan.total_test_result, plan.notification_test_result)) {
        this.invoiceNotifications.push(String.Format(message, plan.notification_test_result, 'result test'));
      }
    });
  }

  private isValidCondition(param1: any, param2: any, param3: any, param4: any): boolean {
    return param1 > 0 && param2 > 0 && (param3 / param1) * 100 >= param4;
  }

  public showNotifications(): void {
    if (this.athleteNotifications.length > 0) {
      const athleteNotification = this.athleteNotifications.shift();
      this.readNotification(athleteNotification.id);
      const snackBarAthleteRef: MatSnackBarRef<SnackbarComponent> = this.snackBar.openFromComponent(
        SnackbarComponent,
        {
          panelClass: 'success',
          verticalPosition: 'top',
          data: { html: athleteNotification.message },
        }
      );

      snackBarAthleteRef.afterDismissed().subscribe((): void => {
        if (this.athleteNotifications.length == 0) {
          this.showInvoiceNotifications();
        } else {
          this.showNotifications();
        }
      });
    } else {
      this.showInvoiceNotifications();
    }
  }

  public showInvoiceNotifications(): void {
    if (this.invoiceNotifications.length > 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: 'success',
        verticalPosition: 'top',
        data: { html: this.invoiceNotifications.join('<br/>') },
      });
    }
  }

  public readNotification(id: any): void {
    const apiURL: string = String.Format(
      AppConstants.API.ATHLETE.UPDATE_NOTIFICATION,
      id
    );
    this._apiService.put(apiURL, {}).subscribe();
  }
}
