<div class="background-color integration-item u-padding-16">
  <span class="integration-item__title line-height-24 u-font-size-16">
    Integration with {{config.name}}
  </span>
  <div class="integration-item__general-info border-color u-margin-top-20 u-margin-bottom-8">
    <div class="integration-item__title position-absolute u-font-weight-400 u-font-size-small line-height-16">API Key</div>
    <div class="integration-item__key position-absolute u-font-weight-400 u-font-size-16 line-height-24">{{config.key}}</div>
    <div class="integration-item__info position-absolute"><mat-icon class="u-font-size-20">info</mat-icon></div>
  </div>
  <button mat-raised-button color="primary" class="integration-item__float-right" (click)="copyApiKey()">Copy API key</button>
</div>
