import { Component, Input } from '@angular/core';
import { IGeneralInfoList } from "@shared/interfaces/general-info-list.interface";

@Component({
  selector: 'app-general-info-list',
  templateUrl: './general-info-list.component.html',
  styleUrls: ['./general-info-list.component.scss']
})
export class GeneralInfoListComponent {
  @Input() config: IGeneralInfoList;
  @Input() isPaymentView: boolean = false;
}
