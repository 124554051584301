<div>
  <h2 mat-dialog-title>Statistics</h2>
  <mat-dialog-content>
    <div [formGroup]="form">
      <div class="field" *ngFor="let item of items">
        <p class="label">{{ item }}</p>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Count</mat-label>
          <input
            type="number"
            matInput
            min="0"
            max="9999"
            formControlName="{{ item }}"/>
          <mat-error
            *ngIf="
              form.get(item)?.hasError('required') ||
              form.get(item)?.hasError('lessThanZero')
            ">
            <div *ngIf="form.get(item)?.hasError('required')">
              {{ item }} count is required
            </div>
            <div *ngIf="form.get(item)?.hasError('lessThanZero')">
              {{ item }} count is greater or equal to 0
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Limit</mat-label>
          <input
            type="number"
            matInput
            min="0"
            max="9999"
            formControlName="{{ item }}-limit"/>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="actionContainer">
      <div>
        <button
          id="cancelButton"
          mat-raised-button
          (click)="downloadCSV()"
          type="button"
        >
          Export Statistics
        </button>
      </div>
      <div>
        <button mat-button (click)="close()" type="button">
          Cancel
        </button>
        <button
          id="saveButton"
          mat-raised-button
          color="primary"
          (click)="save()">Save</button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
