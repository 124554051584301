<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onDismiss()" *ngIf="!isHideBtnSecondary">{{secondaryButton}}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{primaryButton}}</button>
</mat-dialog-actions>
