import { Component, inject, OnInit } from '@angular/core';
import { IIntegration } from "@shared/components/interfaces/integration.interface";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
})
export class IntegrationsComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  public integrationObject: IIntegration;
  public azumApiKey: string = '';

  public ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.data.api_keys.length) {
      this.azumApiKey = this.activatedRoute.snapshot.data.data.api_keys[0].key || '';
      this.integrationObject = {
        key: this.azumApiKey,
        name: 'AZUM'
      };
    }
  }
}
