<h4 mat-dialog-title>Manual Entry VLa<sub>max</sub></h4>
<h2 style="text-align: center">VLa<sub>max</sub> Manual Entry</h2>
<mat-dialog-content>
  <div [formGroup]="form" class="form-box">
    <div class="form-row">
      <p style="width: 80px">VLa<sub>max</sub></p>
      <mat-form-field
        style="width: 50%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>VLa<sub>max</sub></mat-label>
        <input
          matInput
          type="number"
          min="0.1"
          max="2.5"
          formControlName="vla_max"
        />
        <mat-error
          *ngIf="form.get('vla_max')?.errors && form.get('vla_max')?.touched"
          >VLa<sub>max</sub> must be between 0.1 and 2.5
        </mat-error>
      </mat-form-field>
      <p>(mmol/l)s<sup>-1</sup></p>
    </div>
    <div class="form-row">
      <p style="width: 80px">Tolerance</p>
      <mat-form-field
        style="width: 50%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Tolerance</mat-label>
        <input
          matInput
          type="number"
          min="0"
          max="100"
          formControlName="tolerance"
        /><mat-error
          *ngIf="
            form.get('tolerance')?.errors && form.get('tolerance')?.touched
          "
          >Tolerance must be between 0 and 100
        </mat-error>
      </mat-form-field>
      <p>%</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">OK</button>
  </div>
</mat-dialog-actions>
