import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { BehaviorSubject, Observable, throwError as _observableThrow } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IColumnDefinition, ITableHeader } from '@shared/components/interfaces/table.interface';

@Injectable()
export class TableService {
  public tableData$: BehaviorSubject<any> = new BehaviorSubject([]);
  public tableDataObs$: Observable<any> = this.tableData$.asObservable();
  public columnListData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public dataColumnList$: Observable<any> = this.columnListData$.asObservable();
  public displayedColumnList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public displayedColumnsObs$: Observable<any> = this.displayedColumnList$.asObservable();

  constructor(private readonly apiService: ApiService) {}

  public getTableData(endpoint: string, headers: ITableHeader[]): Observable<object | void> {
    return this.apiService.get(endpoint).pipe(
      tap((response: any): void => {
        const tableData: any = response?.results ? response.results : response;

        const flattenArray = this.flattenArrayOfObjects(tableData);
        const columns: IColumnDefinition[] = this.getColumnList(flattenArray, headers);
        this.columnListData$.next(columns);
        this.tableData$.next(tableData);
        this.displayedColumnList$.next(this.getDisplayedColumns(columns));
      }),
      catchError((error: any) => {
        return <Observable<void>>(<any>_observableThrow(error));
      })
    );
  }

  public getTableHeadersFromString(tableHeadersString: string[]) : ITableHeader[] {
    return tableHeadersString.map((item: string) : ITableHeader => {
      const splittedItem: string[] = item.split(".");
      return {
        fieldName: splittedItem[0],
        columnName: splittedItem[1],
      };
    });
  }

  private getColumnList(tableData: any, headers: ITableHeader[]): IColumnDefinition[] {
    const headerFieldNames : Set<string> = this.getHeaderFieldNames(headers);
    const fieldNameToColumnName: Record<string,string> = this.getFieldNameToColumnName(headers);
    const uniqueColumns: string[] = this.getUniqueColumns(tableData, headerFieldNames);

    return uniqueColumns.map((column: string) => {
      return {
        columnDef: column,
        header: fieldNameToColumnName[column],
        cell: (element: any) => this.flattenObject(element)[column] ?? '',
      };
    });
  }

  private getDisplayedColumns(columns: any): object[] {
    return columns.map((c: any) => c.columnDef);
  }

  private flattenObject(obj: any, parentKey: string = '', result: any = {}): any {
    for (let [key, value] of Object.entries(obj)) {
      const newKey: string = parentKey ? `${parentKey}_${key}` : key;
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        this.flattenObject(value, newKey, result);
      } else if (Array.isArray(value)) {
        value?.forEach((val, index: number): void => {
          if (typeof val === 'object' && val !== null) {
            this.flattenObject(val, `${newKey}_${index}`, result);
          } else {
            result[`${newKey}_${index}`] = val;
          }
        });
      } else {
        result[newKey] = value;
      }
    }
    return result;
  }

  private flattenArrayOfObjects(data: any): any {
    return data.map((item: any) => this.flattenObject(item));
  }

  private getHeaderFieldNames(headers: ITableHeader[]): Set<string> {
    return new Set(headers.map((header: ITableHeader) => header.fieldName));
  }

  private getFieldNameToColumnName(headers: ITableHeader[]): Record<string,string> {
    return headers.reduce((acc: any, header: ITableHeader) => {
      acc[header.fieldName] = header.columnName;
      return acc;
    }, {});
  }

  private getUniqueColumns(tableData: any, headerFieldNames: Set<string>): string[] {
    const uniqueColumns: string[] = [];

    tableData.forEach((row: any) => {
      Object.keys(this.flattenObject(row)).forEach((column: string): void => {
        if (headerFieldNames.has(column) && !uniqueColumns.includes(column)) {
          uniqueColumns.push(column);
        }
      });
    });

    return uniqueColumns;
  }
}
