<div class="description-text__section" >
  <div class="form" [formGroup]="form">
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.INTRODUCTION_TEXT}}</mat-label>
        <textarea matInput formControlName="introduction" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.BODY_COMPOSITION}}</mat-label>
        <textarea matInput formControlName="body_composition" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.PHYSIOLOGICAL_PERFORMANCE}}</mat-label>
        <textarea matInput formControlName="metabolic_capacities" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.LOAD_CHARACTERISTICS}}</mat-label>
        <textarea matInput formControlName="metabolic_profile" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.METABOLIC_FINGERPRINT}}</mat-label>
        <textarea matInput formControlName="metabolic_fingerprint" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.PERFORMANCE_DEVELOPMENT}}</mat-label>
        <textarea matInput formControlName="performance_development" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.TRAINING_ZONES}}</mat-label>
        <textarea matInput formControlName="training_zones" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.TEST_DATA_GRAPHS}}</mat-label>
        <textarea matInput formControlName="test_data" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.ECONOMY}}</mat-label>
        <textarea matInput formControlName="economy" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.TEST_DATA_TABLES}}</mat-label>
        <textarea matInput formControlName="test_data_tables" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.HEART_RATE}}</mat-label>
        <textarea matInput formControlName="heart_rate" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.METABOLIC_POWER}}</mat-label>
        <textarea matInput formControlName="metabolic_power" rows="5"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="footer">
    <button id="saveButton" mat-raised-button color="primary" (click)="save()">Save</button>
  </div>
</div>
