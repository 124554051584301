import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { AppConstants } from '@core/constants';
import { ApiDetailDto, ApiDetailResultDto } from '@core/services/api-tracking/api-tracking-detail-dto';
import { ApiTrackingService } from '@core/services/api-tracking/api-tracking.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-api-tracking-dialog',
  templateUrl: './api-tracking-dialog.component.html',
  styleUrls: ['./api-tracking-dialog.component.scss']
})
export class ApiTrackingDialogComponent extends PagedListingBaseComponent<ApiDetailDto> {
  public _appConstants = AppConstants;
  public searchText: string = '';
  public selected: string = '';
  public dataSource: ApiDetailDto[] = [];
  public results: ApiDetailDto[] = [];
  public displayedColumns: string[] = [ 'api_name', 'api_key', 'timestamp', 'status'];
  public isHadAction: boolean = false;
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public trackingStatuses = [
    {
        value: 0,
        name: "All"
    },
    {
    value: this._appConstants.API_TRACKING_STATUS.SUCCESS,
    name: "Success"
    },
    {
        value: this._appConstants.API_TRACKING_STATUS.FAILED,
        name: "Failed"
    }];

  constructor(
    private readonly apiTrackingService: ApiTrackingService,
    public dialogRef: MatDialogRef<ApiTrackingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
   }

  public onInitPage(): void {
    this.isHadAction = true;
    if (!this.isHadAction) {
      this.displayedColumns = this.displayedColumns.filter(
        (item: string): boolean => item !== 'actions'
      );
    }
    super.onInitPage();
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number): void {
    request.pageIndex = pageNumber;
    this.apiTrackingService.getDetails(request, this.data.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((results: ApiDetailResultDto): void => {
      this.dataSource = results.items;
      this.results = this.dataSource;
      this.totalItems = results.totalCount;
    });
  }

  public selectFilter(): void {
    if(this.selected == "1"){
      this.results = this.dataSource.filter((s: any) => s.status.toString().includes('1'));
    } else if(this.selected == "2"){
      this.results = this.dataSource.filter((s: any) => s.status.toString().includes('2'));
    } else {
      this.results = this.dataSource;
    }
  }

  public dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement): void {
    const dateStart: string = dateRangeStart.value;
    const [day1, month1, year1] = dateStart.split('.');
    const dateFirst: Date = new Date(parseInt(year1),parseInt(month1)-1,parseInt(day1));

    const dateEnd: string = dateRangeEnd.value;
    const [day2, month2, year2] = dateEnd.split('.');
    const dateLast: Date = new Date(parseInt(year2), parseInt(month2)-1, parseInt(day2));

    this.results = this.dataSource.filter((item:any)=>{
      let date: string | Date = item.created_at ? new Date(item.created_at) : "";
      return (date > dateFirst && date < dateLast);
    });
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }
}
