<div class="container-metric-control">
  <div>
    <div class="control-container__metric-name control-container__metric-name--grey-background-color">Metric</div>
    <div class="control-container__metric-name control-container--metric-slider-name">Value & Range</div>
  </div>
  <div class="container__controls-panel">
    <div class="container__controls-panel-header">
      <mat-button-toggle-group #group="matButtonToggleGroup" class="container__controls-panel--toggle-group container__controls-panel--text-align" multiple [value]="1" (change)="onVisibilityChange(group.value)">
        <mat-button-toggle class="size" *ngFor="let item of config" [value]="item.id">
          <div class="container__controls-panel--line-height-35">
            {{item.metricName}}
            <sub class="u-font-size-xsmall">{{item.metricNamePostfix}}</sub>
          </div>
          <div class="container__controls-panel--font-size-10">{{item.unit}}</div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="container__controls-panel-slider">
    <span class="container__controls-panel--text-align" *ngFor="let item of config; let i = index;">
      <app-range-slider [config]="item.slider" [index]="i" [step]="item.step" [metricId]="item.metricPropertyName" (updateTableData)="updateSliderControls($event)"></app-range-slider>
      <span class="container-metric-control__value-with-unit" *ngIf="item.currentMetricValue">{{item.currentMetricValue}}</span>
    </span>
    </div>
  </div>
</div>
