import { EntityDto } from '@shared/models/entity-dto';
import { AthleteDto } from '../athletes-management/athletes-dto';

export class TestDto extends EntityDto {
  public id: number | undefined;
  public athlete: AthleteDto | undefined;
  public coach?: any;
  public sport?: any;
  public files?: any;
  public excel_file: string | undefined;
  public json_data: string | undefined;
  public test_type: string | undefined;
  public comment: string | undefined;
  public vo2max_min: number | undefined;
  public vlamax: number | undefined;
  public test_date: string | undefined;
  public mass: number | undefined;
  public height: number | undefined;
  public proz_body_fat_100: number | undefined;
  public proz_body_water_100_override: number | undefined;
  public proz_body_muscle_100_override: number | undefined;
  public proz_muscle_mass_used_100_override: number | undefined;
  public proz_lactat_dist_space_100_override: number | undefined;
  public watto2eq: number | undefined;
  public au_override: number | undefined;
  public bu_override: number | undefined;
  public kel: number | undefined;
  public tau: number | undefined;
  public tau_vo2: number | undefined;
  public klavo2: number | undefined;
  public ks_lapH: number | undefined;
  public ks_vo2: number | undefined;
  public ks2_laADP: number | undefined;
  public lao2eq: number | undefined;
  public lao2eq_precise: number | undefined;
  public max_o2debt: number | undefined;
  public pcro2eq: number | undefined;
  public vla_rest: number | undefined;
  public f_vs_musc_male_grad: number | undefined;
  public f_vs_musc_male_b: number | undefined;
  public f_vs_musc_female_grad: number | undefined;
  public f_vs_musc_female_b: number | undefined;
  public f_vs_h2o_male_grad: number | undefined;
  public f_vs_h2o_male_b: number | undefined;
  public f_vs_h2o_female_grad: number | undefined;
  public f_vs_h2o_female_b: number | undefined;
  public tolerance: number | undefined;
  public vo2max_min_display: number | undefined;
  public vlamax_display: number | undefined;
  public created_at: string | undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
  public temperature: string | undefined;
  public altitude: string | undefined;
  public location: string | undefined;
  public test_id: number | undefined;
  public error: number | undefined;
  public display_id: number | undefined;
  public max_heart_rate: number | undefined;
  public organization: number | undefined;
  public created_by: number | undefined;
  public product?: any;
  public plan: number | undefined;
  public training_zone_template: number | undefined;

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  public clone(): TestDto {
    return new TestDto().deserialize(this);
  }
}
