import { Injectable } from "@angular/core";
import { IAuth } from "@modules/login/login.component";
import { AppConstants } from "@core/constants";

@Injectable()
export class GoogleTagManagerHelperService {
  public setDataLayer(auth: IAuth): void {
    if (window.dataLayer) {
      const NA: string = 'N/A';

      window.dataLayer.push({
        event: AppConstants.GOOGLE_TAG_MANAGER.EVENTS.UPDATE_USER_PROFILE,
        user_id: auth.id ? auth.id : NA,
        user_hash : auth.intercom_user_hash ? auth.intercom_user_hash : NA,
        email: auth.email ? auth.email : NA,
        name: auth.first_name ? auth.first_name : NA,
        phone: auth.phone ? auth.phone : NA,
        avatar: auth.photo ? auth.photo : NA,
        company: auth.organization.name ? auth.organization.name : NA,
        company_id: auth.organization.id ? auth.organization.id : NA,
        company_name: auth.organization.name ? auth.organization.name : NA,
        website: auth.organization.website ? auth.organization.website : NA,
      });
    }
  }
}
