import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class CompetitionResultDto extends PagedResultDto {
  public items: CompetitionDto[] = [];
}
export class CompetitionDto extends Deserializable {
  public id: number | undefined;
  public date_from: string | undefined;
  public date_to: string | undefined;
  public name: string | undefined;
  public organization: number | undefined;
  public short_name: string | undefined;
  public year: number | undefined;
}
