import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { finalize } from 'rxjs/operators';
import { AppConstants } from '@core/constants';
import { HistoryLogDto, HistoryLogResultDto, IHistoryLogDetail } from '@core/services/history-logs/history-log-dto';
import { HistoryLogService } from '@core/services/history-logs/history-log.service';
import { HistoryLogsDetailComponent } from './history-logs-detail/history-logs-detail.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-history-logs',
  templateUrl: './history-logs.component.html',
})
export class HistoryLogsComponent extends PagedListingBaseComponent<HistoryLogDto> {
  public currentLog: IHistoryLogDetail = {};
  public form: UntypedFormGroup = this.fb.group({});
  public isOwnerOrAgent: boolean = false;
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: HistoryLogDto[] = [];
  public displayedColumns: string[] = ['item_type', 'type', 'item_id', 'test_type', 'sport', 'organization_id', 'user_id', 'ip_address', 'actions'];
  public showingActionOptions: any[] = [];
  public actionOptions: any[] = [
    {
      name:  'Item type',
      columnDef: 'item_type',
      attributeName: 'object_table',
      isShow: true,
      isDefault: true,
    },
    {
      name:  'Type',
      columnDef: 'type',
      attributeName: 'action',
      isShow: true,
      isDefault: true,
    },
    {
      name:  'Item ID',
      columnDef: 'item_id',
      attributeName: 'object_id',
      isShow: true,
      isDefault: true,
    },
    {
      name:  'Test type',
      columnDef: 'test_type',
      attributeName: 'object_name',
      isShow: true,
      isDefault: true,
    },
    {
      name:  'Sport',
      columnDef: 'sport',
      attributeName: 'test_sport_name',
      isShow: true,
      isDefault: true,
    },
    {
      name:  'Organization',
      columnDef: 'organization_id',
      attributeName: 'organization',
      isShow: true,
      isDefault: true,
    },
    {
      name:  'User ID',
      columnDef: 'user_id',
      attributeName: 'user',
      isShow: true,
      isDefault: true,
    },
    {
      name:  'Creation IP Address User',
      columnDef: 'ip_address',
      attributeName: 'user_ip',
      isShow: true,
      isDefault: true,
    },
    {
      name: 'Time zone User',
      columnDef: 'user_tz',
      attributeName: 'user_tz',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Timestamp of upload (Zone)',
      columnDef: 'created_at',
      attributeName: 'created_at',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Payment',
      columnDef: 'payment',
      attributeName: 'payment',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Payment date',
      columnDef: 'payment_date',
      attributeName: 'payment_date',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Deletion timestamp',
      columnDef: 'created_a',
      attributeName: '',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Deletion user ID',
      columnDef: 'user',
      attributeName: '',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Deletion User IP',
      columnDef: 'user_ip',
      attributeName: '',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'VO2max',
      columnDef: 'test_vo2max',
      attributeName: 'test_vo2max',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Vlamax',
      columnDef: 'test_vlamax',
      attributeName: 'test_vlamax',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Body weight',
      columnDef: 'test_mass',
      attributeName: 'test_mass',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'LaEqO2',
      columnDef: 'test_lao2eq',
      attributeName: 'test_lao2eq',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'EDE',
      columnDef: 'test_lao2eqa',
      attributeName: 'test_lao2eqa',
      isShow: false,
      isDefault: false,
  },
  {
      name: 'Date of item',
      columnDef: 'test_date',
      attributeName: 'test_date',
      isShow: false,
      isDefault: false,
  },
  {
    name: 'Action',
    columnDef: 'actions',
    isShow: true,
    isDefault: true,
  }
  ];

  constructor(
    private readonly logService: HistoryLogService,
    private readonly dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private readonly snackBar: MatSnackBar,
  ) {
    super();
  }

  public onInitPage(): void {
    this.isOwnerOrAgent = this.auth.role.is_owner || this.auth.admin_view_all_history_log;
    this.list(this.request, this.pageNumber, (): void => {});
    this.showingActionOptions = this.actionOptions.filter(item => item.isShow && item.columnDef !='actions' && item.columnDef !='organization_id' && item.columnDef !='created_at');
    this.initValues();
  }

  public initValues(): void {
    this.form = this.fb.group({
      actions: new UntypedFormControl(this.actionOptions.filter(item => item.isDefault == true))
    });
  }

  public viewDialog(id: number): void {
    this.logService.getById(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IHistoryLogDetail): void => {
      this.currentLog = res;
      if(!this.currentLog.json_data && !this.currentLog.json_processing_data){
        this.snackBar.open('No data to show for this Historical Log', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      } else {
        this.dialog.open(
          HistoryLogsDetailComponent, {
            panelClass: 'general-dialog',
            width: '1000px',
            autoFocus: false,
            disableClose: true,
            data: { historyLog: this.currentLog },
          });
      }
    });
  }

  public onSelectAction(value: any): void {
    this.form.get('actions')?.setValue(value);
    const currentlyOpeneColums = value.map((item:any) => item.name);
    this.showingActionOptions = this.actionOptions.filter(item => currentlyOpeneColums.includes(item.name) && item.columnDef != 'actions' && item.columnDef !='organization_id' && item.columnDef !='created_at');
    this.displayedColumns = this.actionOptions.filter(item => currentlyOpeneColums.includes(item.name)).map(item => item.columnDef);
  }

  public onSelectCheckBox(field: any): void {
    if(field.checked){
      this.form = this.fb.group({
        actions: new UntypedFormControl(this.actionOptions.map((item: any) => {
          return {
            value: item.value,
            name: item.name
          };
        }))
      });
      this.showingActionOptions = this.actionOptions.filter(item => item.columnDef != 'actions' && item.columnDef !='organization_id' && item.columnDef !='created_at');
      this.displayedColumns = this.actionOptions.map(item => item.columnDef);
    } else {
      this.initValues();
      this.showingActionOptions = this.actionOptions.filter(item => item.isDefault && item.columnDef !='actions' && item.columnDef !='organization_id' && item.columnDef !='created_at');
      this.displayedColumns =  this.actionOptions.filter(item => item.isDefault).map(item => item.columnDef);
    }
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number, finishedCallback: Function): void {
    request.pageIndex = pageNumber;
    this.logService.list(request)
      .pipe(
        finalize(() => finishedCallback()),
        takeUntilDestroyed(this.destroyRef)
        )
      .subscribe((res: HistoryLogResultDto): void => {
        this.dataSource = res.items;
        this.totalItems = res.totalCount;
      });
  }
}
