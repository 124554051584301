import { Component, inject } from '@angular/core';
import { HelperService } from "@shared/services/helper.service";
import { ActivatedRoute } from "@angular/router";
import { IProfileItem } from "@shared/interfaces/profile-item.interface";

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent {
  private helperService: HelperService = inject(HelperService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  public profileItemList: IProfileItem[] = [];

  constructor() {
    this.profileItemList = this.helperService.makeCopy<IProfileItem[]>(this.activatedRoute.snapshot.data.data);
  }
}
