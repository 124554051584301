import { Component, DestroyRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public resetForm!: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private apiService: ApiService,
    private readonly snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    const ControlsConfig = {
      username: new UntypedFormControl('', Validators.required),
    };
    this.resetForm = this.fb.group(ControlsConfig);
  }

  public handleResetPassword(): void {
    const api: string = 'accounts/forgot-password/';
    const invalidMessage: string = 'Username is required.';
    const confirmationMessage: string = 'We have sent you an email to reset your password. It will arrive shortly.';
    const tryAgainMessage: string = 'Cannot send forgot password email at the moment. Please try again later.';
    const invalidCredentialsMessage: string = 'Invalid user credentials.';

    if(!this.resetForm.valid){
      this.snackBar.open(invalidMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      return;
    }
    this.apiService.post(api, this.resetForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {
      this.snackBar.open(confirmationMessage, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      this.resetForm.get('username')?.setValue('');
    },
    error => {
      if (error && error.error_msg == "SMTP_ERROR") {
        this.snackBar.open(tryAgainMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
      this.snackBar.open(invalidCredentialsMessage ,'OK', AppConstants.TOAST_CONFIG.ERROR);
    });
  }
}
