import { Injectable } from "@angular/core";
import { ApiService } from "@core/services/api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { delay, distinctUntilChanged, finalize, tap } from "rxjs/operators";
import { AppConstants } from "@core/constants";

export type Tab = Record<string, any> | Array<Record<string, any>>;

@Injectable()
export class MetabolicProfileFacade {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _tabs: BehaviorSubject<Tab[]> = new BehaviorSubject<Tab[]>([]);

  public loading$: Observable<boolean> = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());
  public tabs$: Observable<Tab> = this._tabs.asObservable();


  constructor(private _apiService: ApiService,) {}

  public fetchMetabolicProfile(apiCallId: string) {
    const params: URLSearchParams = new URLSearchParams({ api_call_id: apiCallId });

    this._loading.next(true);
    return this._apiService.get(`${AppConstants.API.EXTERNAL.HUMANGO.METABOLIC_PROFILE}?${params.toString()}`)
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe((tabs: any) => this._tabs.next(tabs));
  }
}
