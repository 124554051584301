import { Injectable } from "@angular/core";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { TileTypeNavigationEnum } from "@shared/enums/tile-type-navigation.enum";

@Injectable()
export class TileService {
  public getGeneratedTileConfigBasedOnResponse<T>(featureList: T[], buttonName: string | null = null, urlNavigateTo: string = '', tileTypeNavigation: TileTypeNavigationEnum = TileTypeNavigationEnum.APP): ITileConfig[] {
    return featureList.map((item: any): ITileConfig => (
      {
        id: item.id,
        image: item.image || 'https://inscyd-prod-public.s3.eu-central-2.amazonaws.com/courses/183/image_preview.png',
        title: item.name || 'N/A',
        description: item.description || 'N/A',
        description_html: item.description_html,
        isRecommended: item.is_recommended,
        buttonName: buttonName ? buttonName : item.buttonName,
        urlNavigateTo: urlNavigateTo.length ? urlNavigateTo : item.urlNavigateTo,
        tags: item.tags || ['N/A'],
        category_name: item.category_name,
        tagHeight: 24,
        marketingFeatures: item.marketing_features || [],
        suggestedCourses: item.suggested_courses || [],
        currencyName: item.currency_name,
        price: item.price,
        navigationType: tileTypeNavigation,
        configuration: item.configuration ? item.configuration : 'null',
      }
    ));
  }

  public filterTabsData(featureList: ITileConfig[], category: string, searchValue: string): ITileConfig[] {
    if (category === 'All') {
      if (searchValue) {
        return featureList.filter((feature: ITileConfig): boolean => feature.title.includes(searchValue) || feature.tags.some((tag: string) => tag.includes(searchValue)));
      }

      return featureList;
    } else {
      if (searchValue) {
        return featureList.filter((feature: ITileConfig): boolean => {
          return feature.category_name === category && (feature.title.includes(searchValue) || feature.tags.some((tag: string) => tag.includes(searchValue)));
        });
      }

      return featureList.filter((feature: ITileConfig): boolean => {
        return feature.category_name === category;
      });
    }
  }
}
