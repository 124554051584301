import { AfterViewInit, Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core';
import { SessionService } from "@core/services/session.service";
import { AppConstants } from "@core/constants";
import { HelperService } from "@shared/services/helper.service";

@Directive({
  selector: '[appVisibilityForOrganization]'
})
export class ElementVisibilityDirective implements AfterViewInit {
  private sessionService: SessionService = inject(SessionService);
  private elementRef: ElementRef = inject(ElementRef);
  private helperService: HelperService = inject(HelperService);
  private renderer2: Renderer2 = inject(Renderer2);

  @Input() public appVisibilityForOrganization: number[];

  public ngAfterViewInit(): void {
    const organizationId = JSON.parse(this.sessionService.getCookie('auth')).organization.id;
    const currentElement: ElementRef = this.elementRef.nativeElement;
    const isProductionEnvironment: boolean = this.helperService.isProductionEnvironment(location, AppConstants.ENVIRONMENT_URL.PROD);

    if (this.appVisibilityForOrganization.includes(organizationId) || !isProductionEnvironment) {
      this.renderer2.setStyle(currentElement, 'display', 'block');
    } else {
      this.renderer2.setStyle(currentElement, 'display', 'none');
    }
  }
}
