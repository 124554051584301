import { inject, Injectable } from '@angular/core';
import { ApiService } from "@core/services/api.service";
import { Observable } from "rxjs";
import { ICurrentAccount } from "@shared/interfaces/current-account.interface";
import { AppConstants } from "@core/constants";
import { ICurrentUserSession } from "@shared/interfaces/current-user-session.interface";

@Injectable()
export class CurrentAccountService {
  private apiService: ApiService = inject(ApiService);

  public getCurrentAccount(): Observable<ICurrentAccount> {
    return this.apiService.get(AppConstants.ACCOUNTS_URL.ACCOUNTS_CURRENT.GET);
  }

  public changePasswordAccount(body: any): Observable<any> {
    return this.apiService.post(AppConstants.ACCOUNTS_URL.CHANGE_PASSWORD.POST, body);
  }

  public getAllUserSessionList(): Observable<ICurrentUserSession[]> {
    return this.apiService.get(AppConstants.ACCOUNTS_URL.ALL_USER_SESSION.GET);
  }
}
