<h1 mat-dialog-title>Payment Summary</h1>
<div mat-dialog-content>
  <app-general-info-list [isPaymentView]="true" [config]="data"></app-general-info-list>
  <div class="line-height-28">You are activating <span class="u-color-red-faded">Bar Chart</span> feature
    <span *ngIf="dependencyLength">with the
      <span class="u-color-red-faded">{{dependencyLength}} other dependencies</span>
    </span>
  </div>
  <div class="line-height-28">
    <span>The free trial expires on
      <span class="u-color-red-faded">{{expiresOn | date: 'dd/MM/YYYY'}}</span>
    </span>
  </div>
  <div class="line-height-28">
    <span>You may cancel the subscription anytime in the my subscription page.</span>
  </div>
  <div *ngIf="featureDependencyList.length">
    <app-feature-multi-select (addFeatureToPrice)="onAddFeatureToPrice($event)" [config]="featureDependencyList"></app-feature-multi-select>
  </div>
  <div *ngIf="featureListWithPrice.length">
    <app-feature-price-list [title]="'After that you will be charged:'" [config]="featureListWithPrice"></app-feature-price-list>
  </div>
  <div *ngIf="totalPrice.totalAmount">
    <app-total-price [config]="totalPrice"></app-total-price>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="accept()">Accept</button>
</mat-dialog-actions>
