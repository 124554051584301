import { Injectable } from "@angular/core";
import mixpanel from "mixpanel-browser";
import { environment } from 'src/environments/environment';

const MIXPANEL_INIT_KEY = "mixpanel_init";

@Injectable({
  providedIn: "root"
})
export class MixpanelService {

  public init(): void {
    if (this.shouldInit()) {
      const debug = environment.name === "Local";
      mixpanel.init(environment.mixpanelToken, {
        debug: debug,
        track_pageview: false,
        persistence: "localStorage",
        ignore_dnt: true
      });
    }
  }

  public identify(userId: string) {
    if (this.isInitialized()) {
      mixpanel.identify(userId);
    }
  }

  public reset(): void {
    if (this.isInitialized()) {
      mixpanel.reset();
    }
  }

  public track(eventName: string, properties: Record<string, any> = {}) {
    if (this.isInitialized()) {
      mixpanel.track(eventName, properties);
    }
  }

  public start_session_recording(): void {
    if (this.isInitialized()) {
      mixpanel.start_session_recording();
    }
  }

  public stop_session_recording(): void {
    if (this.isInitialized()) {
      mixpanel.stop_session_recording();
    }
  }

  private shouldInit(): boolean {
    let shouldInit = true;
    if (!environment.mixpanelToken) {
      shouldInit = false;
    }
    if (environment.name === "Prod") {
      shouldInit = false;
    }
    if (environment.mixpanelToken === "TOKEN_NOT_PROVIDED") {
      shouldInit = false;
    }

    localStorage.setItem(MIXPANEL_INIT_KEY, shouldInit.toString());
    return shouldInit;
  }

  private isInitialized(): boolean {
    return localStorage.getItem(MIXPANEL_INIT_KEY) === "true";
  }
}
