<div class="clone-plan-dialog">
  <h3>Clone plan - {{plan.name}}</h3>
  <br />
  <div class="clone-plan-form">
    <form [formGroup]="clonePlanForm">
      <div>
        <mat-form-field floatLabel="always" appearance="outline" class="full-width">
          <mat-label>Cloned plan name</mat-label>
          <input formControlName="name" matInput placeholder="{{generateName()}}" />
        </mat-form-field>
      </div>
      <p><mat-checkbox formControlName="is_publish">Publish?</mat-checkbox></p>
      <p><mat-checkbox formControlName="clone_organizations">Clone organization availability?</mat-checkbox></p>
      <p><mat-checkbox formControlName="clone_discounts">Clone discounts?</mat-checkbox></p>
    </form>
  </div>
  <mat-dialog-actions align="end">
    <div>
      <button id="cancelButton" mat-button (click)="cancel()" type="button">
        Cancel
      </button>
      <button id="cloneButton" mat-raised-button color="primary" (click)="clone()">
        Clone
      </button>
    </div>
  </mat-dialog-actions>
</div>