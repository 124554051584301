<form [formGroup]="personalInfoForm" class="page-container">
  <div class="top-toolbar">
    <div class="sub-title">SETTINGS</div>
    <div class="title">Personal Info</div>
  </div>
  <div class="sport-dialog__section personal-info">
    <mat-dialog-content>
      <div>
        <hr>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>User name</mat-label>
            <input matInput placeholder="Enter name" formControlName="username"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Enter email" formControlName="email"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Enter first name" formControlName="first_name"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Enter last name" formControlName="last_name"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Phone</mat-label>
            <input matInput placeholder="Enter phone" formControlName="phone"/>
          </mat-form-field>
        </div>
      </div>
      <div>
        <span class="u-font-size-14 u-font-weight-700 line-height-normal">Password</span>
        <hr>
        <br>
        <button mat-raised-button color="primary" (click)="openChangePasswordDialog()">Change password</button>
      </div>
      <br>
      <br>
      <div>
        <span class="u-font-size-14 u-font-weight-700 line-height-normal">Role</span>
        <hr>
        <br>
        <div class="personal-info__role disable-background">
          <span class="personal-info__role--title position-absolute disabled-text u-font-weight-400 u-font-size-small line-height-12">Role</span>
          <span class="personal-info__role--content position-absolute disabled-text u-font-weight-400 u-font-size-16 line-height-24">{{role}}</span>
        </div>
      </div>
    </mat-dialog-content>
    <br>
    <mat-dialog-actions align="center">
      <div class="full-width o-flex o-flex--justify-space-between">
        <button class="width-49-percent personal-info--button-border" mat-button (click)="back()" type="button">Cancel</button>
        <button class="u-position-relative width-49-percent" mat-raised-button color="primary" (click)="save()">
          Save
          <span class="personal-info__text-position">changes will apply upon next login</span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
