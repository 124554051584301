import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class HeadResultDto extends PagedResultDto {
  public items: HeadDto[] = [];
}

export class HeadDto extends Deserializable {
  public id: number | undefined;
  public username: string | undefined;
  public email: string | undefined;
  public role:
    |
        {
            id: number | undefined;
            name: string | undefined;
            is_agent_role: boolean | undefined;
            is_owner: boolean | undefined;
        }
    | undefined;
  public role_id: number | undefined;
  public organization:
    |
        {
            id: number | undefined;
            name: string | undefined;
            photo: string | undefined;
            is_solvent: boolean | undefined;
            address_country_id: number | undefined;
            is_check_invoice: boolean | undefined;
        }
    | undefined;
  public photo: any | undefined;
  public is_active: boolean | undefined;
  public first_name: string | undefined;
  public last_name: string | undefined;
  public phone: string | undefined;
  public enable_login: boolean | undefined;
  public is_sse_validation_active: boolean | undefined;
}
