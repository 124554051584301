import { inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { IMatIconRegister } from "@shared/interfaces/mat-icon-register.interface";

@Injectable()
export class MatIconRegistryService {
  private matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
  private domSanitizer: DomSanitizer = inject(DomSanitizer);

  public customSvgIconList: IMatIconRegister[] = [
    {
      iconUrl: 'assets/custom-icons/settings.svg',
      iconLabel: 'settings',
    }
  ];

  public registerCustomMatIcon(label: string, url: string): void {
    this.matIconRegistry.addSvgIcon(label, this.sanitizeSvgIconUrl(url));
  }

  private sanitizeSvgIconUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public getCustomSvgIconList(): IMatIconRegister[] {
    return this.customSvgIconList;
  }
}
