import { IEconomyEfficiencySettings } from "@shared/interfaces/economy-efficiency-settings.interface";
import { EconomyEfficientSettingsEnum } from "@shared/enums/economy-efficient-settings.enum";

export const ECONOMY_EFFICIENCY_SETTINGS: IEconomyEfficiencySettings[] = [
  { name: EconomyEfficientSettingsEnum.FAT_MAX, id: 1, },
  { name: EconomyEfficientSettingsEnum.CARD_MAX, id: 2, },
  { name: EconomyEfficientSettingsEnum.MLSS, id: 3, },
  { name: EconomyEfficientSettingsEnum.VO2MAX_POWER, id: 4, },
  { name: EconomyEfficientSettingsEnum.CREATE_CUSTOM, id: 5,  },
];
