import { Component, DestroyRef, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { CountriesDto } from '@core/services/region-settings/countries-dto';
import { CurrencyDto } from '@core/services/region-settings/currency-dto';
import { RegionSettingsDto } from '@core/services/region-settings/region-settings-dto';
import { ICountryList, RegionSettingsService } from '@core/services/region-settings/region-settings.service';
import { ChangeRegionDialogComponent } from '../change-region-dialog/change-region-dialog.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export interface ICountry { name: string, value: number }

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
})
export class AddDialogComponent extends AppBaseComponent {
  public dataSource: CurrencyDto[] = [];
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public countries: CountriesDto[] = [];
  public countryOptions: any;
  public region: RegionSettingsDto = new RegionSettingsDto();

  constructor(
    public dialogRef: MatDialogRef<ChangeRegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly regionSettingsService: RegionSettingsService,
    private readonly fb: UntypedFormBuilder,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.loadCountries();
    this.loadCurrency();
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      currency: new UntypedFormControl('', Validators.required),
      countries: new UntypedFormControl('')
    });
    if(this.data.isEdit){
      this.getDetail();
    }
  }

  public loadCurrency(): void {
    this.regionSettingsService.currencyList()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
      this.dataSource = res.items;
    });
  }

  public getDetail(): void {
    this.regionSettingsService.getRegionById(this.data.regionId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: RegionSettingsDto) => {
      this.countryOptions = res.country?.map((item) => {
        return {
          name: item.name,
          value: item.id
        };
      });
      this.form = this.fb.group({
        name: new UntypedFormControl(res.name, Validators.required),
        currency: new UntypedFormControl(res.currency?.id, Validators.required),
        countries:  new UntypedFormControl(res.country?.map((item) => {
          return {
            value: item.id,
            name: item.name
          };
        }))
      });
    });
  }

  public loadCountries(): void {
    this.regionSettingsService.getCountries()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ICountryList): void => {
      this.countryOptions = res.results;
    });
  }

  private isValidForm(): boolean {
    let isValid;
    if (this.form.valid) {
      isValid = true;
    } else {
      isValid = false;
      this.form.markAllAsTouched();
    }
    return isValid;
  }

  public save(): void {
    if(this.isValidForm()) {
        const input: RegionSettingsDto = {
        name: this.form.get('name')?.value,
        price: 0,
        currency: this.form.get('currency')?.value,
        country: this.form.get('countries')?.value ? this.form.get('countries')?.value.map((country: ICountry) => country.value) : [],
      } as RegionSettingsDto;

      if(this.data.isEdit){
        this.regionSettingsService.update(this.data.regionId, input)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((res: RegionSettingsDto): void => {
          this.dialogRef.close(res);
        });
      } else {
        this.regionSettingsService.addRegion(input)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((res: RegionSettingsDto): void => {
          this.dialogRef.close(res);
        });
      }
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public onSelectCountry(value: number): void {
    this.form.get('countries')?.setValue(value);
  }
}
