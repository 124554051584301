<div class="device-management-list">
  <div class="title">System Settings</div>
  <mat-tab-group>
    <mat-tab label="General Settings">
      <ng-template matTabContent>
        <app-general-settings></app-general-settings>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
