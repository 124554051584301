import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class ManageOrganizationResultDto extends PagedResultDto {
  public items: ManageOrganizationDto[] = [];
}

export interface ICountry {
    id: number;
    name: string;
    country_code: string;
    created_at: Date;
    updated_at: Date;
    is_delete: boolean;
}

export interface AccumulatedTestDTO {
  test_count: number,
  expiry_date: string,
}

export class ManageOrganizationDto extends Deserializable {
  public id!: number;
  public country!: ICountry;
  public invoice_country: any | undefined;
  public api: |  Array<any>  | undefined;
  public name: string | undefined;
  public photo: string | undefined;
  public is_active: boolean | undefined;
  public max_devices: number | undefined;
  public total_user: number | undefined;
  public max_user: number | undefined;
  public total_athlete: number | undefined;
  public max_athlete: number | undefined;
  public total_test_la: number | undefined;
  public max_test_la: number | undefined;
  public total_test_map: number | undefined;
  public max_test_map: number | undefined;
  public total_test_manual: number | undefined;
  public max_test_manual: number | undefined;
  public total_test_virtual: number | undefined;
  public max_test_virtual: number | undefined;
  public total_test_event: number | undefined;
  public max_test_event: number | undefined;
  public total_test_result: number | undefined;
  public max_test_result: number | undefined;
  public address_street_name: string | undefined;
  public address_street_number: string | undefined;
  public address_zip: string | undefined;
  public address_city: string | undefined;
  public address_state: string | undefined;
  public address_country_id: number | undefined;
  public phone: string | undefined;
  public phone2: string | undefined;
  public fax: string | undefined;
  public email: string | undefined;
  public email2: string | undefined;
  public website: string | undefined;
  public facebook: string | undefined;
  public instagram: string | undefined;
  public vat_number: any | undefined;
  public invoice_org_name: string | undefined;
  public invoice_address_street_name: string | undefined;
  public invoice_address_street_number: string | undefined;
  public invoice_address_zip: string | undefined;
  public invoice_address_city: string | undefined;
  public invoice_address_state: string | undefined;
  public invoice_vat_number: any | undefined;
  public is_use_invoice_address: boolean | undefined;
  public is_solvent: boolean | undefined;
  public paid_method: string | undefined;
  public is_check_invoice: boolean | undefined;
  public is_multiple_exceed_invoice: boolean | undefined;
  public created_at: string | undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
  public head: number | undefined;
  public account_type: any | undefined;
  public created_by: any | undefined;
  public api_keys: any | undefined;
  public accumulated_tests: Record<string, Array<AccumulatedTestDTO>> | undefined;
}

export class ApiInfoDto extends Deserializable {
  public id: number | undefined;
  public name: string | undefined;
  public method: string | undefined;
  public url: string | undefined;
  public is_active: boolean | undefined;
  public created_at: string | undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
  public alias: string | undefined;
}

export class ApiInfoResultDto extends PagedResultDto {
  public items: ApiInfoDto[] = [];
}
