<h4 mat-dialog-title>Add new fit file</h4>
<mat-dialog-content>
  <div [formGroup]="form" class="form-box">
    <div class="setting__section">
      <h3 class="sub-title border-color">Fit file</h3>
      <div class="content">
        <div class="field">
          <app-chips [label]="'Files'" [form]="form.get('files')" [options]="filteredTests" [displayField]="'name'"
            (optionChange)="onSelectFile($event)"></app-chips>
        </div>
        <div class="file-upload-section border-color" appDragFile (files)="filesDropped($event)">
          <svg class="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
            <path
              d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" />
          </svg>
          <div class="text-wrapper">
            <input type="file" #fileUpload hidden multiple (change)="changeFile($event)" />
            <a (click)="fileUpload.click()" class="primary-color">Choose a file</a>
            <div class="centered">or drag it here.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button class="u-width-140" mat-raised-button type="button" color="primary" (click)="save()">
      Add File & Reload
    </button>
  </div>
</mat-dialog-actions>
