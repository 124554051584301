<div>
    <div class="title-with-search">
      <p>Information Popups</p>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search" [(ngModel)]="searchText" (keyup.enter)="search(searchText)">
        <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="group-actions mb-3">
        <button mat-raised-button color="primary" (click)="add()" class="ml-4">
          {{ '+ Add' }}
        </button>
      </div>
    <table mat-table matSort #tableData [dataSource]="dataSource" class="mat-elevation-z8"
      style="width: 100%;" (matSortChange)="sortData($event)" matSortActive="id" matSortDirection="desc"
      matSortDisableClear aria-describedby="device management list">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-id" scope="row"> ID</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="message_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Message Name</th>
        <td mat-cell *matCellDef="let element"> {{element?.name}}</td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Active</th>
        <td mat-cell *matCellDef="let element"> {{element.getActivity()}} </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Type</th>
        <td mat-cell *matCellDef="let element"> {{element?.getType()}}</td>
      </ng-container>
      <ng-container matColumnDef="expired_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Expired date</th>
        <td mat-cell *matCellDef="let element"> {{element?.getDate()}}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element" class="fixed-width-action">
            <button mat-raised-button (click)="edit(element)" color="primary" class="btn-icon-text">
                <mat-icon>mode_edit</mat-icon> Edit
              </button>
              <button mat-button (click)="deletePopup(element.id)" class="btn-icon-text">
                <mat-icon>delete</mat-icon> Delete
              </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row no-data-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="isHadAction ? 7 : 6">No data available in table</td>
      </tr>
    </table>
    <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
      <mat-paginator (page)="getDataPage($event.pageIndex + 1)" [length]="totalItems" [pageSize]="pageSize">
      </mat-paginator>
    </div>
  </div>
