import { Component } from '@angular/core';
import { AppBaseComponent } from '@shared/components/app-component-base';
import SwiperCore, { Pagination } from 'swiper';
import { AppConstants } from '@core/constants';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AppBaseComponent {
  public v1URL: string = AppConstants.V1_URL;
  public selectedTestID: any = null;

  constructor() {
    super();
  }

  public onInitPage(): void {
    this.selectedTestID = localStorage.getItem('testID');
  }
}
