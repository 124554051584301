import { ChangeDetectionStrategy, Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { CoursesService } from "@core/services/courses/courses.service";
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { HelperService } from "@shared/services/helper.service";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

@Component({
  selector: 'app-suggested-courses',
  templateUrl: './suggested-courses.component.html',
  styleUrls: ['./suggested-courses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedCoursesComponent implements OnInit {
  @HostListener('window:resize', ['$event']) public onResize(event: any) {
    this.configSwiper = this.helperService.getSwiperConfig(event.target.innerWidth);
  }

  private helperService: HelperService = inject(HelperService);
  public configSwiper: any;

  @Input() public  suggestedCourses: any;
  @Input() public  title: string;
  @Input() public  isShowPadding: boolean = false;

  public ngOnInit(): void {
    this.configSwiper = this.helperService.getSwiperConfig(window.innerWidth);
  }
}
