import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ValidatorHelper } from '@shared/helpers/ValidatorHelper';
import * as _ from 'lodash-es';
import { ApiService } from '@core/services/api.service';
import { OrganizationDto } from '@core/services/organization/organization-dto';

@Component({
  selector: 'app-organization-statistics-dialog',
  templateUrl: './organization-statistics-dialog.component.html',
  styleUrls: ['./organization-statistics-dialog.component.scss'],
})
export class OrganizationStatisticsDialogComponent implements OnInit {
  public form: UntypedFormGroup = {} as UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<OrganizationStatisticsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrganizationDto,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {}
  public items: string[] = [
    'Users',
    'Athletes',
    'Tests LA',
    'Tests PPD',
    'Tests Manual',
    'Tests Virtual',
    'Events',
    'Simulations',
  ];

  public ngOnInit(): void {
    this.form = this.fb.group({
      Users: new UntypedFormControl(this.data.total_user, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      Athletes: new UntypedFormControl(this.data.total_athlete, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Tests LA': new UntypedFormControl(this.data.total_test_la, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Tests PPD': new UntypedFormControl(this.data.total_test_map, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Tests Manual': new UntypedFormControl(this.data.total_test_manual, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Tests Virtual': new UntypedFormControl(this.data.total_test_virtual, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      Events: new UntypedFormControl(this.data.total_test_event, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      Simulations: new UntypedFormControl(this.data.total_test_result, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Users-limit': new UntypedFormControl(this.data.max_user, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Athletes-limit': new UntypedFormControl(this.data.max_athlete, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Tests LA-limit': new UntypedFormControl(this.data.max_test_la, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Tests PPD-limit': new UntypedFormControl(this.data.max_test_map, ValidatorHelper.greaterOrEqualToZero),
      'Tests Manual-limit': new UntypedFormControl(this.data.max_test_manual, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Tests Virtual-limit': new UntypedFormControl(this.data.max_test_virtual, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Events-limit': new UntypedFormControl(this.data.max_test_event, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
      'Simulations-limit': new UntypedFormControl(this.data.max_test_result, [Validators.required, ValidatorHelper.greaterOrEqualToZero,]),
    });
  }
  public save(): void {
    if (this.form.valid) {
      this.prepareData();
      this.apiService
        .put('organizations/statistics/' + this.data.id + '/', this.data)
        .subscribe((res) => this.dialogRef.close(res));
    } else {
      this.snackBar.open('Form is invalid fields must be greater than 0', 'Ok');
    }
  }
  public close(): void {
    this.dialogRef.close(false);
  }

  public prepareData(): void {
    this.data.total_user = this.form.get('Users')?.value;
    this.data.total_athlete = this.form.get('Athletes')?.value;
    this.data.total_test_la = this.form.get('Tests LA')?.value;
    this.data.total_test_map = this.form.get('Tests PPD')?.value;
    this.data.total_test_manual = this.form.get('Tests Manual')?.value;
    this.data.total_test_virtual = this.form.get('Tests Virtual')?.value;
    this.data.total_test_event = this.form.get('Events')?.value;
    this.data.total_test_result = this.form.get('Simulations')?.value;
    this.data.max_user = this.form.get('Users-limit')?.value;
    this.data.max_athlete = this.form.get('Athletes-limit')?.value;
    this.data.max_test_la = this.form.get('Tests LA-limit')?.value;
    this.data.max_test_map = this.form.get('Tests PPD-limit')?.value;
    this.data.max_test_manual = this.form.get('Tests Manual-limit')?.value;
    this.data.max_test_virtual = this.form.get('Tests Virtual-limit')?.value;
    this.data.max_test_event = this.form.get('Events-limit')?.value;
    this.data.max_test_result = this.form.get('Simulations-limit')?.value;
  }

  public convertArrayOfObjectsToCSV(data: any): string | null {
    let columnDelimiter: string = ',';
    let lineDelimiter: string = '\n';
    let listKeys = [
      { name: 'Organization Name', field: 'name' },
      { name: 'Users count', field: 'total_user' },
      { name: 'Users limit', field: 'max_user' },
      { name: 'Athletes count', field: 'total_athlete' },
      { name: 'Athletes limit', field: 'max_athlete' },
      { name: 'Tests LA count', field: 'total_test_la' },
      { name: 'Tests LA limit', field: 'max_test_la' },
      { name: 'Tests PPD count', field: 'total_test_map' },
      { name: 'Tests PPD limit', field: 'max_test_map' },
      { name: 'Tests Manual count', field: 'total_test_manual' },
      { name: 'Tests Manual limit', field: 'max_test_manual' },
      { name: 'Tests Virtual count', field: 'total_test_virtual' },
      { name: 'Tests Virtual limit', field: 'max_test_virtual' },
      { name: 'Events count', field: 'total_test_event' },
      { name: 'Events limit', field: 'max_test_event' },
      { name: 'Results count', field: 'total_test_result' },
      { name: 'Results limit', field: 'max_test_result' },
    ];

    if (data == null) {
      return null;
    }

    let result: string = '';
    result += _.map(listKeys, 'name').join(columnDelimiter);
    result += lineDelimiter;

    let ctr: number = 0;
    listKeys.forEach(function (key): void {
      if (ctr > 0) result += columnDelimiter;

      result += data[key.field];
      ctr++;
    });
    result += lineDelimiter;

    return result;
  }

  public downloadCSV(): void {
    let data, filename, link;
    this.prepareData();
    let csv: string | null = this.convertArrayOfObjectsToCSV(this.data);
    if (csv == null) return;

    filename = 'export.csv';
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);
    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }
}
