import { Component, DestroyRef } from '@angular/core';
import { AppBaseComponent } from '@shared/components/app-component-base';
import SwiperCore, { Pagination } from 'swiper';
import { AppConstants } from '@core/constants';
import { ApiService } from "@core/services/api.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AppBaseComponent {
  public v1URL: string = AppConstants.V1_URL;
  public selectedTestID: any = null;
  public courses: any = [];

  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar,
              private router: Router,
              private destroyRef: DestroyRef,
              ) {
    super();
  }

  public onInitPage(): void {
    this.selectedTestID = localStorage.getItem('testID');
    if (this.rights.college_list) {
      const HARD_CODED_COURSE_ID_LIST: number[] = [191, 213, 150];
      const API_CALL_LIST: any = [];
      HARD_CODED_COURSE_ID_LIST.forEach((courseId: number): void => {
        API_CALL_LIST.push(this.apiService.get(AppConstants.API.COURSE.GET_BY_ID + courseId + '/'));
      });

      forkJoin(API_CALL_LIST)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError(() => of([])),
        )
        .subscribe((response: any): void => {
          this.courses = response.length > 3 ? response.slice(0, 3) : response;
          this.courses.push({ isFakeItem: true });
        }, (err: any): void => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(err, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        });
    }
  }

  public goToCatalogue(): void {
    this.router.navigate(['/home/college/catalog']);
  }
}
