export const selected_athlete = {
  "id": 3,
  "tags": [],
  "coaches": [],
  "first_name": "Vanessa",
  "last_name": "Williams",
  "gender": "male",
  "dob": "2004-06-12",
  "photo": null,
  "address_street_name": "",
  "address_street_number": "",
  "address_zip": "",
  "address_city": "",
  "address_state": "",
  "address_country_id": null,
  "phone": "",
  "phone2": "",
  "fax": "",
  "email": "vanessawilliams@example.com",
  "email2": "",
  "website": "",
  "facebook": "",
  "twitter": "",
  "instagram": "",
  "is_active": false,
  "default_weight": 70,
  "garmin_verified": false,
  "strava_id": null,
  "inactive_expiration": null,
  "is_count": false,
  "count_date": null,
  "plan_id": null,
  "active_purchase_detail_id": 3,
  "product_id": null,
  "link_item_id": null,
  "created_at": "2024-08-20T15:33:19.391497Z",
  "updated_at": "2024-08-21T11:39:34.819768Z",
  "deleted_at": null,
  "is_delete": false,
  "is_invisible": false,
  "display_id": 172416799938,
  "external_athlete_id": null,
  "organization": 2,
  "training_level": 1,
  "created_by": null,
  "training_level_value": "pro"
}
