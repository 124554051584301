<div class="tags-management-list">
  <div class="title-with-search">
    <p>TAGS</p>
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input
        matInput
        placeholder="Tag Name"
        [(ngModel)]="searchText"
        (keyup.enter)="search(searchText)"
      />
      <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
    </mat-form-field>
  </div>
  <div class="group-actions mb-3" *ngIf="!isOwnerOrAgent">
    <button mat-raised-button color="primary" (click)="addTag()" class="ml-4">
      {{ "Add Tag" }}
    </button>
  </div>

  <table
    mat-table
    matSort
    #tableData
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    style="width: 100%"
    (matSortChange)="sortData($event)"
    matSortActive="id"
    matSortDirection="desc"
    matSortDisableClear
    aria-describedby="tag list"
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        scope="row"
        mat-sort-header
        class="mat-column-id"
      >
        ID
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef scope="row" mat-sort-header>Tag</th>
      <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
    </ng-container>
    <ng-container matColumnDef="organization__name" *ngIf="isOwnerOrAgent">
      <th mat-header-cell *matHeaderCellDef scope="row" mat-sort-header>
        Organization
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.organization?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef scope="row">Action</th>
      <td mat-cell *matCellDef="let element" class="fixed-width-action">
        <button
          mat-raised-button
          color="primary"
          (click)="editTag(element)"
          class="btn-icon-text"
        >
          <mat-icon>mode_edit</mat-icon> Edit
        </button>
        <button
          mat-raised-button
          (click)="deleteTag(element.id)"
          class="btn-icon-text"
        >
          <mat-icon>delete</mat-icon> Delete
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="isOwnerOrAgent ? 4 : 3">
        No data available in table
      </td>
    </tr>
  </table>
  <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
    <mat-paginator
      [pageIndex]="pageIndex"
      (page)="getDataPage($event.pageIndex + 1)"
      [length]="totalItems"
      [pageSize]="pageSize">
    </mat-paginator>
  </div>
</div>
