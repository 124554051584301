import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PagedAndSortedRequestDto, PagedListingBaseComponent } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { AppConstants } from '@core/constants';
import { IRegionData, RegionSettingsDto, RegionSettingsResultDto } from '@core/services/region-settings/region-settings-dto';
import { RegionSettingsService } from '@core/services/region-settings/region-settings.service';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { ChangeRegionDialogComponent } from './change-region-dialog/change-region-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-region-settings',
  templateUrl: './region-settings.component.html',
  styleUrls: ['./region-settings.component.scss']
})
export class RegionSettingsComponent extends PagedListingBaseComponent<RegionSettingsDto> {
  public searchText: string = '';
  public dataSource: MatTableDataSource<IRegionData> = new MatTableDataSource<IRegionData>([]);
  public regions: RegionSettingsDto[] = [];
  public displayedColumns: string[] = [ 'id', 'region', 'currency', 'country', 'action'];
  public isHadAction: boolean = false;
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();

  constructor(
    private readonly regionSettingsService: RegionSettingsService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    super();
  }

  public onInitPage(): void {
    this.isHadAction = this.rights.admin_devices_update || this.rights.admin_devices_delete;
    if (!this.isHadAction) {
      this.displayedColumns = this.displayedColumns.filter(
        (item): boolean => item !== 'action'
      );
    }

    super.onInitPage();
  }

  protected getPagedRequestDto(): PagedAndSortedRequestDto {
    return this.request;
  }

  public applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public search(): void {
    this.dataSource.filter = this.searchText.trim().toLowerCase();
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number): void {
    request.pageIndex = pageNumber;
    this.regionSettingsService.list(request)
      .subscribe((res: RegionSettingsResultDto): void => {
        this.convertData(res);
        this.regions = res.items;
      });
  }

  public convertData(data: RegionSettingsResultDto) {
   return this.dataSource.data = data.items.map((item: RegionSettingsDto) => ({
      ...item,
      currency: item.currency?.name,
      countryArray: item.country?.map(country => country.name)
    }));
  }

  public changeRegion(): void {
    const dialogRef: MatDialogRef<ChangeRegionDialogComponent> = this.dialog.open(ChangeRegionDialogComponent, {
      panelClass: 'general-dialog',
      width: '650px',
      autoFocus: false,
      data: { regions: this.regions }
    });

    dialogRef.afterClosed().subscribe(
      (res): void => {
        if (res) {
          this.snackBar.open('Item updated successfully', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          this.refresh();
        }
      },
      (error): void => { this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);}
    );
  }

  public add(): void {
    this.openDialog(false);
  }

  public edit(id: number): void {
    this.openDialog(true, id);
  }

  public openDialog(isEdit: boolean, regionId?: number): void {
    const editMessage: string = 'Item updated successfully';
    const addMessage: string = 'Item created successfully';
    const dialogRef: MatDialogRef<AddDialogComponent> = this.dialog.open(AddDialogComponent, {
      panelClass: 'general-dialog',
      width: '650px',
      autoFocus: false,
      data: {
        isEdit,
        regionId
      }
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
      (res): void => {
        if(res) {
          if(isEdit){
            this.snackBar.open(editMessage, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
            this.refresh();

            return;
          }
          this.snackBar.open(addMessage, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          this.refresh();
        }
      },
      (error): void => { this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);}
    );
  }

  public deleteRegion(id: number): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.regionSettingsService.delete(id).subscribe(
            (): void => {
              this.snackBar.open('Region deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }
}
