import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Router } from "@angular/router";
import { IProfileItem } from "@shared/interfaces/profile-item.interface";

@Component({
  selector: 'app-profile-item',
  templateUrl: './profile-item.component.html',
  styleUrls: ['./profile-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileItemComponent {
  private router: Router = inject(Router);

  @Input() public config: IProfileItem;

  public navigateTo(): void {
    this.router.navigateByUrl(this.config.urlNavigateTo);
  }
}
