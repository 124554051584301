<div>
  <div class="border-class">Chart & Graph watermark</div>
  <div class="form" [formGroup]="photo_form">
    <div class="full-width photo">
      <img
        class="photo_img"
        [src]="photo_form.value?.photo"
        *ngIf="photo_form?.value?.photo"
        alt="watermark photo"
      />
      <input
        data-placement="right"
        title="Maximum watermark photo size is 100KB"
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
        (change)="changeImage($event)"
      />
    </div>
  </div>
  <div class="border-class">SMTP Settings</div>
  <div class="form" [formGroup]="form">
    <div class="smtp-first-row">
      <div class="field" style="flex-grow: 3">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>SMPT Server (*)</mat-label>
          <input matInput formControlName="smtp_server" />
        </mat-form-field>
        <mat-error
          *ngIf="
            form?.controls?.smtp_server?.errors &&
            form?.controls?.smtp_server?.touched
          "
        >
          <div
            [innerHTML]="
              form.controls.smtp_server.errors
                | errorFormFormat : { fieldName: 'SMTP server' }
            "
          ></div>
        </mat-error>
      </div>
      <div class="field" style="flex-grow: 1">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Port (*)</mat-label>
          <input type="number" matInput formControlName="smtp_port" />
        </mat-form-field>
        <mat-error
          *ngIf="
            form?.controls?.smtp_port?.errors &&
            form?.controls?.smtp_port?.touched
          "
        >
          <div
            [innerHTML]="
              form.controls.smtp_port.errors
                | errorFormFormat : { fieldName: 'SMTP port' }
            "
          ></div>
        </mat-error>
      </div>
    </div>
    <div class="field">
      <mat-form-field
        class="full-width"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Email (*)</mat-label>
        <input matInput formControlName="smtp_email" />
      </mat-form-field>
      <mat-error
        *ngIf="
          form?.controls?.smtp_email?.errors &&
          form?.controls?.smtp_email?.touched
        "
      >
        <div
          [innerHTML]="
            form.controls.smtp_email.errors
              | errorFormFormat : { fieldName: 'SMTP email' }
          "
        ></div>
      </mat-error>
    </div>
    <div class="field">
      <mat-form-field
        class="full-width"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Username (*)</mat-label>
        <input matInput formControlName="smtp_username" />
      </mat-form-field>
      <mat-error
        *ngIf="
          form?.controls?.smtp_username?.errors &&
          form?.controls?.smtp_username?.touched
        "
      >
        <div
          [innerHTML]="
            form.controls.smtp_username.errors
              | errorFormFormat : { fieldName: 'SMTP username' }
          "
        ></div>
      </mat-error>
    </div>
    <div class="field">
      <mat-form-field
        class="full-width"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Password (*)</mat-label>
        <input type="password" matInput formControlName="smtp_password" />
      </mat-form-field>
      <mat-error
        *ngIf="
          form?.controls?.smtp_password?.errors &&
          form?.controls?.smtp_password?.touched
        "
      >
        <div
          [innerHTML]="
            form.controls.smtp_password.errors
              | errorFormFormat : { fieldName: 'SMTP password' }
          "
        ></div>
      </mat-error>
    </div>
  </div>
</div>
