import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class AthleteTrainingLevelResultDto extends PagedResultDto {
  public items: AthleteTrainingLevelDto[] = [];
}

export class AthleteTrainingLevelDto extends Deserializable {
  public id: number | undefined;
  public value: string | undefined;
  public organization: any;
  public created_by: any;
  public is_default_level : boolean | undefined
}
