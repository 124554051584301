<h1 mat-dialog-title>Change Password</h1>
<form [formGroup]="changePasswordForm" class="change-password">
  <mat-dialog-content>
    <mat-form-field appearance="outline" floatLabel="always" class="full-width">
      <mat-label>Current Password</mat-label>
      <input matInput [type]="showPassword ? inputTextType : inputPasswordType" formControlName="current_password">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" class="full-width">
      <mat-label>New Password</mat-label>
      <input matInput [type]="showPassword ? inputTextType : inputPasswordType" formControlName="new_password">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" class="full-width">
      <mat-label>Confirm New Password</mat-label>
      <input matInput [type]="showPassword ? inputTextType : inputPasswordType" formControlName="confirm_password">
    </mat-form-field>
    <mat-error class="u-margin-bottom-20" *ngIf="changePasswordForm.hasError('matchPasswordError')">The new password has to be the same</mat-error>
    <mat-checkbox color="primary" class="u-margin-bottom-20" formControlName="isShowPassword">Show Password</mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions  align="center">
    <div class="full-width o-flex o-flex--justify-space-between">
      <button mat-button class="change-password--button-width change-password--button-border" (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" class="change-password--button-width" (click)="changePassword()" [disabled]="changePasswordFormRef.invalid">Change Password</button>
    </div>
  </mat-dialog-actions>
</form>
