import { Component, inject } from '@angular/core';
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: 'app-general-back-button',
  templateUrl: './general-back-button.component.html',
  styleUrls: ['./general-back-button.component.scss']
})
export class GeneralBackButtonComponent {
  private router: Router = inject(Router);
  private _location: Location = inject(Location);

  public back(): void {
    this.router.navigate([], {
      queryParams: {},
    });
    this._location.back();
  }
}
