import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { AppConstants } from "@core/constants";
import { HelperService } from "@shared/services/helper.service";

@Component({
  selector: 'app-loader-indicator',
  templateUrl: './loader-indicator.component.html',
  styleUrls: ['./loader-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderIndicatorComponent {
  private helperService: HelperService = inject(HelperService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);

  public hideLoading(): void {
    const isProductionEnvironment: boolean = this.helperService.isProductionEnvironment(location, AppConstants.ENVIRONMENT_URL.PROD);

    if (!isProductionEnvironment) {
      this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
    }
  }
}
