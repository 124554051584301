import { Component, DestroyRef, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { AppConstants } from "@core/constants";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-optimization-option-dialog',
  templateUrl: './optimization-option-dialog.component.html',
})
export class OptimizationOptionDialogComponent extends AppBaseComponent {
  public formData: UntypedFormGroup = this.fb.group({});
  public showConfirmTrigger: boolean = false;
  public currentPlanName: string;
  public data = {
    invoicePlans: [],
    invoiceProducts: [],
    options: [
      {
        value: 'Plan',
      },
      {
        value: 'Product',
      },
    ],
    defaultPlan: null,
    plan: null,
    product: null,
    option: {
      value: '',
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<OptimizationOptionDialogComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForm();
    const listApiRequest: Observable<Object>[] = [
      this.apiService.get('invoices/plans/'),
      this.apiService.get('invoices/products/'),
    ];

    forkJoin(listApiRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((responses: any[]) => {
      if (responses[0]) {
        // TODO: We need to rework the selection that decides the number of valid plans
        // otherwise some plans are ignored and the tests get auto-allocated. For now
        // as long as there are multiple plans returned, we should always get the allocation
        // popup
        //
        // this.getInvoicePlans(responses[0]); <-- this line has been disabled for now
        //@ts-ignore
        this.data.invoicePlans = responses[0];
      }

      if (responses[1]) {
        this.getInvoiceProducts(responses[1]);
      }

      this.checkProductsAndPlans();
    });
  }

  public initForm(): void {
    let productField = null;
    let planField = null;

    if (this.data.option && this.data.option.value == 'Product') {
      productField = new UntypedFormControl('', Validators.required);
      planField = new UntypedFormControl('');
    } else if (this.data.option && this.data.option.value == 'Plan') {
      productField = new UntypedFormControl('');
      planField = new UntypedFormControl('', Validators.required);
    }

    this.formData = this.fb.group({
      product: productField,
      plan: planField,
    });
  }

  public isFirstTestByProduct(product: any, testType: string): boolean {
    const initialCountTest: number = 0;
    const defaultFirstTestState: boolean = false;

    switch (testType) {
      case AppConstants.TEST_TYPES.ATHLETE:
        return product.total_athlete === initialCountTest;
      case AppConstants.TEST_TYPES.PPD:
        return product.total_test_map === initialCountTest;
      case AppConstants.TEST_TYPES.MANUAL:
        return product.total_test_manual === initialCountTest;
      case AppConstants.TEST_TYPES.VIRTUAL:
        return product.total_test_virtual === initialCountTest;
      case AppConstants.TEST_TYPES.USER:
        return product.total_user === initialCountTest;
      case AppConstants.TEST_TYPES.LACTATE:
        return product.total_test_la === initialCountTest;
      case AppConstants.TEST_TYPES.EVENT:
        return product.total_test_event === initialCountTest;
      case AppConstants.TEST_TYPES.RESULT:
        return product.total_test_result === initialCountTest;
      default:
        return defaultFirstTestState;
    }
  }

  public isExistsFreeTestByProduct(product: any, testType: string): boolean {
    const defaultFirstTestState: boolean = false;

    switch (testType) {
      case AppConstants.TEST_TYPES.ATHLETE:
        return product.total_athlete < product.max_athlete;
      case AppConstants.TEST_TYPES.PPD:
        return product.total_test_map < product.max_test_map;
      case AppConstants.TEST_TYPES.MANUAL:
        return product.total_test_manual < product.max_test_manual;
      case AppConstants.TEST_TYPES.VIRTUAL:
        return product.total_test_virtual < product.max_test_virtual;
      case AppConstants.TEST_TYPES.USER:
        return product.total_user < product.max_user;
      case AppConstants.TEST_TYPES.LACTATE:
        return product.total_test_la < product.max_test_la;
      case AppConstants.TEST_TYPES.EVENT:
        return product.total_test_event < product.max_test_event;
      case AppConstants.TEST_TYPES.RESULT:
        return product.total_test_result < product.max_test_result;
      default:
        return defaultFirstTestState;
    }
  }

  public closeModalWithProductOrPlan(hasProductFreeTest: boolean, item: any): void {
    if (hasProductFreeTest) {
      this.dialogRef.close({
        action: 'confirm',
        productId: item.id,
        planId: null,
      });
    } else {
      this.dialogRef.close({
        action: 'confirm',
        productId: null,
        planId: item.id,
      });
    }
  }


  public onSelectPlan(item: any): void {
    const isFirstTestCreation: boolean = this.isFirstTestByProduct(item, this.dialogData.type);
    const isExistsFreeTest: boolean = this.isExistsFreeTestByProduct(item, this.dialogData.type);
    this.currentPlanName = item.name;
    this.showFreePeriodByProduct(item, isFirstTestCreation);
    this.closeModalWithProductOrPlan(isExistsFreeTest, item);
  }

  public showFreePeriodByProduct(product: any, isFirstTest: boolean): void {
    const isTriggered: boolean = product?.triggers.indexOf(this.getType()) !== -1;

    if (!product.is_trigger && product.triggers && isFirstTest && isTriggered) {
      this.formData.get('product')?.setValue(product.id);
      this.showConfirmTrigger = true;
    }
  }

  public checkProductsAndPlans(): void {
    if (
      this.data.invoicePlans.length === 0 &&
      this.data.invoiceProducts.length === 0
    ) {
      this.snackBar.open(
        'There is no product & plan.',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
      this.cancel();
      return;
    }

    if (this.data.invoiceProducts.length) {
      if (this.data.invoicePlans.length > 1) {
        this.data.option.value = 'Plan';
        this.formData.controls.plan.setValidators(Validators.required);
      } else {
        const product = this.data.invoiceProducts[0] as any;
        const isFirstTestCreation: boolean = this.isFirstTestByProduct(product, this.dialogData.type);
        const isExistsFreeTest: boolean = this.isExistsFreeTestByProduct(product, this.dialogData.type);
        this.currentPlanName = product.name;
        this.showFreePeriodByProduct(product, isFirstTestCreation);
        if (!this.showConfirmTrigger) {
          this.closeModalWithProductOrPlan(isExistsFreeTest, product);
        }
      }
    } else {
      if (this.data.invoicePlans.length > 1) {
        this.data.option = {
          value: 'Plan',
        };
        this.formData.controls.plan.setValidators(Validators.required);
      } else if (this.data.invoicePlans.length == 1) {
        this.dialogRef.close({
          action: 'confirm',
          productId: null,
          //@ts-ignore
          planId: this.data.invoicePlans[0].id,
        });
      } else if (this.data.defaultPlan != null) {
        this.dialogRef.close({
          action: 'confirm',
          productId: null,
          //@ts-ignore
          planId: this.data.defaultPlan.id,
        });
      }
    }
  }

  public getType(): number | null {
    if (this.dialogData?.type == this.constant.TEST_TYPES.USER) {
      return this.constant.ITEMS[0].id;
    } else if (this.dialogData?.type == this.constant.TEST_TYPES.ATHLETE) {
      return this.constant.ITEMS[1].id;
    } else if (this.dialogData?.type == this.constant.TEST_TYPES.LACTATE) {
      return this.constant.ITEMS[2].id;
    } else if (this.dialogData?.type == this.constant.TEST_TYPES.PPD) {
      return this.constant.ITEMS[3].id;
    } else if (this.dialogData?.type == this.constant.TEST_TYPES.MANUAL) {
      return this.constant.ITEMS[4].id;
    } else if (this.dialogData?.type == this.constant.TEST_TYPES.VIRTUAL) {
      return this.constant.ITEMS[5].id;
    } else if (this.dialogData?.type == this.constant.TEST_TYPES.EVENT) {
      return this.constant.ITEMS[6].id;
    } else if (this.dialogData?.type == this.constant.TEST_TYPES.RESULT) {
      return this.constant.ITEMS[7].id;
    } else {
      return null;
    }
  }

  public getInvoicePlans(response: any): void {
    //@ts-ignore
    this.data.invoicePlans = _.filter(response, (item) => {
      if (this.dialogData?.type == this.constant.TEST_TYPES.USER) {
        return item.total_user < item.max_user;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.ATHLETE) {
        return item.total_athlete < item.max_athlete;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.LACTATE) {
        return item.total_test_la < item.max_test_la;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.PPD) {
        return item.total_test_map < item.max_test_map;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.MANUAL) {
        return item.total_test_manual < item.max_test_manual;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.VIRTUAL) {
        return item.total_test_virtual < item.max_test_virtual;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.EVENT) {
        return item.total_test_event < item.max_test_event;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.RESULT) {
        return item.total_test_result < item.max_test_result;
      }
      return false;
    });
    if (this.data.invoicePlans.length === 0 && response.length > 0) {
      //@ts-ignore
      this.data.invoicePlans =  this.filterInvoicePlansWithAdditional(response);
    }
  }

  private filterInvoicePlansWithAdditional(response : any){
    return _.filter(response, (item) => {
      if (this.dialogData?.type == this.constant.TEST_TYPES.USER) {
        return (
          item.total_user >= this.conditionIgoreAdditional(item, 'max_user')
        );
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.ATHLETE) {
        return (
          item.total_athlete >=
          this.conditionIgoreAdditional(item, 'max_athlete')
        );
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.LACTATE) {
        return (
          item.total_test_la >=
          this.conditionIgoreAdditional(item, 'max_test_la')
        );
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.PPD) {
        return (
          item.total_test_map >=
          this.conditionIgoreAdditional(item, 'max_test_map')
        );
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.MANUAL) {
        return (
          item.total_test_manual >= this.conditionIgoreAdditional(item, 'max_test_manual')
        );
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.VIRTUAL) {
        return (
          item.total_test_virtual >= this.conditionIgoreAdditional(item, 'max_test_virtual')
        );
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.EVENT) {
        return (
          item.total_test_event >= this.conditionIgoreAdditional(item, 'max_test_event')
        );
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.RESULT) {
        return (
          item.total_test_result >= this.conditionIgoreAdditional(item, 'max_test_result'));
      }
      return false;
    });
  }

  private conditionIgoreAdditional(item : any , param : string){
    return item[param] && !item.is_additional;
  }

  public getInvoiceProducts(response: any): void {
    //@ts-ignore
    this.data.invoiceProducts = _.filter(response, (item) => {
      if (
        item.expiration_trigger &&
        moment.utc(item.expiration_trigger).local() < moment()
      ) {
        return false;
      }
      if (this.dialogData?.type == this.constant.TEST_TYPES.USER) {
        return item.total_user < item.max_user;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.ATHLETE) {
        return item.total_athlete < item.max_athlete;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.LACTATE) {
        return item.total_test_la < item.max_test_la;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.PPD) {
        return item.total_test_map < item.max_test_map;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.MANUAL) {
        return item.total_test_manual < item.max_test_manual;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.VIRTUAL) {
        return item.total_test_virtual < item.max_test_virtual;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.EVENT) {
        return item.total_test_event < item.max_test_event;
      } else if (this.dialogData?.type == this.constant.TEST_TYPES.RESULT) {
        return item.total_test_result < item.max_test_result;
      }
      return false;
    });
  }

  public save(): void {
    if (this.formData.valid) {
      this.closeDialog('confirm');
    } else {
      this.formData.markAllAsTouched();
    }
  }

  public cancel(): void {
    this.closeDialog('cancel');
  }

  public closeDialog(action: any): void {
    if (this.data.option.value == 'Product') {
      this.data.plan = null;
    } else if (this.data.option.value == 'Plan') {
      this.data.product = null;
    }
    this.dialogRef.close({
      action: action,
      productId: this.formData?.value?.product || null,
      planId: this.formData?.value?.plan || null,
    });
  }
}
