<div class="device-dialog__section">
    <mat-dialog-actions align="end">
      <div>
        <button
            id="cancelButton"
            mat-button
            (click)="close()"
            type="button">
            X
        </button>
      </div>
    </mat-dialog-actions>
    <h3 mat-dialog-title>{{ "Data Used and Data Processing" }}</h3>
    <mat-dialog-content>
      <div>
          <h1>{{ "Data Used" }}</h1>
          <pre>{{dataUsed}}</pre>
      </div>
      <br>
      <div>
          <h1>{{ "Data Processing" }}</h1>
          <pre>{{dataProcessing}}</pre>
      </div>
    </mat-dialog-content>
</div>
