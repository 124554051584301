<div class="sport-dialog__section">
    <h2 mat-dialog-title>{{ "Change Region" }}</h2>
    <mat-dialog-content>
        <div class="field">
            <mat-form-field class="full-width" appearance="outline" floatLabel="always">
              <mat-label>Country (*)</mat-label>
              <mat-select [(ngModel)]="selectedValue" (ngModelChange)="onChange($event)">
                <mat-option *ngFor="let item of dataSource" [value]="item">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="full-width" appearance="outline" floatLabel="always">
              <mat-label>Current Region (*)</mat-label>
              <input matInput disabled value="{{selectedValue?.region.name}}" placeholder="">
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="full-width" appearance="outline" floatLabel="always">
              <mat-label>New Region (*)</mat-label>
              <mat-select [(ngModel)]="selectedNewValue">
                <mat-option *ngFor="let item of regions" [value]="item">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button
            id="cancelButton"
            mat-button
            (click)="close()"
            type="button">
            Cancel
        </button>
        <button
            [disabled]="!selectedValue || !selectedNewValue"
            id="saveButton"
            mat-raised-button
            color="primary"
            (click)="save()">
            Change
        </button>
      </div>
    </mat-dialog-actions>
  </div>
