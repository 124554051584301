import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartConstants } from "@modules/apc/apc/data/chart-modules";
import { ChartMappingData } from "@modules/apc/apc/data/chart-mapping-data";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import {
  PerformanceDevelopmentChartPreviewService
} from "@shared/services/performance-development-chart-preview/performance-development-chart-preview.service";
import { HelperService } from "@shared/services/helper.service";

@Component({
  selector: 'app-performance-development-chart-preview',
  templateUrl: './performance-development-chart-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceDevelopmentChartPreviewComponent implements OnInit, OnChanges {
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private pdChartPreviewService: PerformanceDevelopmentChartPreviewService = inject(PerformanceDevelopmentChartPreviewService);
  private helperService: HelperService = inject(HelperService);

  public uniqueChartPostfixId: string;
  public chartIdWithoutHash: string;

  @Input() public config: any;

  public ngOnInit(): void {
    this.uniqueChartPostfixId = ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID+'_'+ this.helperService.generateRandomString();
    this.chartIdWithoutHash = this.uniqueChartPostfixId.substring(1);
    this.generateChart(this.config, this.uniqueChartPostfixId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.config.previousValue) {
      this.generateChart(changes.config.currentValue, this.uniqueChartPostfixId);
    }
  }

  public generateChart(plot: any, elementId: string): void {
    const payload = {
      plot,
      testId: 10,
      elementId: elementId,
      type: ChartConstants.PERFORMANCE_DEVELOPMENT.ID,
      test_type: 'newtype',
      legend: 'legends',
      mappingData: ChartMappingData,
    };

    setTimeout(() => {
      this.customRendererService.empty(elementId);
      this.pdChartPreviewService.generatePerformanceDevelopmentChart(payload.plot, payload.elementId, payload.testId);
    })
  }
}
