import { Component, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { combineLatest } from 'rxjs';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { ChapterDto, CourseDto } from "@core/services/courses/course-dto";
import { CoursesService } from "@core/services/courses/courses.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: 'app-edit-courses-dialog',
  templateUrl: './edit-courses-dialog.component.html',
  styleUrls: ['./edit-courses-dialog.component.scss'],
})
export class EditCoursesDialogComponent extends AppBaseComponent {
  public isShowDiscount: boolean = true;
  public isShowChapter: boolean = true;
  public isShowSuggestedCourses: boolean = true;
  public isShowCourseUnits: boolean = true;
  public isShowPrice: boolean = true;
  public planOptions: any[] = [];
  public coursesOptions: any[] = [];
  public courseUnitsOptions: any[] = [];
  public userOptions: any[] = [];
  public regionOptions: any[] = [];
  public periodTypes: any;
  public infoForm: UntypedFormGroup = {} as UntypedFormGroup;
  public pricesForm: UntypedFormGroup = {} as UntypedFormGroup;
  public suggestedCourses: UntypedFormGroup = {} as UntypedFormGroup;
  public courseUnits: UntypedFormGroup = {} as UntypedFormGroup;
  public discountsForm: UntypedFormGroup = {} as UntypedFormGroup;
  public discountForm: UntypedFormGroup = {} as UntypedFormGroup;
  public chapterForm: UntypedFormGroup = {} as UntypedFormGroup;
  public chaptersForm: UntypedFormGroup = {} as UntypedFormGroup;
  public discountTypes = [
    {
      id: 1,
      name: '%',
    },
    {
      id: 2,
      name: 'Total',
    },
  ];

  // TODO: This list should be generated from the regions query - we might add/remove regions in the future
  public priceList = [
        {
          region: 1,
          initial_purchase: 0,
          ongoing_access: 0,
          course_id: this.data.course.id,
        },
        {
          region: 2,
          initial_purchase: 0,
          ongoing_access: 0,
          course_id: this.data.course.id,
        },
        {
          region: 3,
          initial_purchase: 0,
          ongoing_access: 0,
          course_id: this.data.course.id,
        },
        {
          region: 4,
          initial_purchase: 0,
          ongoing_access: 0,
          course_id: this.data.course.id,
        },
        {
          region: 5,
          initial_purchase: 0,
          ongoing_access: 0,
          course_id: this.data.course.id,
        },{
          region: 6,
          initial_purchase: 0,
          ongoing_access: 0,
          course_id: this.data.course.id,
        },
        {
          region: 7,
          initial_purchase: 0,
          ongoing_access: 0,
          course_id: this.data.course.id,
        },
      ];
  public textEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    width: `350px`,
    height: `200px`,
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: '2',
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      ['insertVideo'], ['toggleEditorMode'], ['backgroundColor'], ['customClasses'], ['fontName'], ['justifyLeft'],
      ['justifyCenter'], ['justifyRight'], ['justifyFull'], ['removeFormat'], ['link'], ['unlink'], ['insertImage']
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<EditCoursesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: UntypedFormBuilder,
    private readonly snackBar: MatSnackBar,
    private apiService: ApiService,
    private coursesService: CoursesService
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForms();
    this.initOptionAndValue();
  }

  public initForms(): void {
    this.infoForm = this.fb.group({
      name: new UntypedFormControl(''),
      id: new UntypedFormControl(''),
      parent: new UntypedFormControl(''),
      is_publish: new UntypedFormControl(false),
      entitled_for_days: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      offer_ongoing_access_for_days: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
      description_html: new UntypedFormControl(''),
      waitlist: new UntypedFormControl(false),
    });

    this.suggestedCourses = this.fb.group(
      {
        courses: new UntypedFormControl(''),
      }
    );

    this.courseUnits = this.fb.group(
      {
        courses: new UntypedFormControl(''),
      }
    );

    this.pricesForm = this.fb.group({
      prices: this.fb.array([]),
    });

    this.discountsForm = this.fb.group({
      discounts: this.fb.array([]),
    });

    this.discountForm = this.fb.group({
      discount_value: new UntypedFormControl('', [Validators.required]),
      discount_type: new UntypedFormControl(1, [Validators.required]),
      annual_revenue: new UntypedFormControl(undefined),
      plans: new UntypedFormControl(''),
      users: new UntypedFormControl(''),
      apply_to_any_plan: new UntypedFormControl(false),
    });

    this.chaptersForm = this.fb.group({
      chapters: this.fb.array([]),
    });

    this.chapterForm = this.fb.group({
      number: new UntypedFormControl(''),
      title: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(''),
      course_id: new UntypedFormControl(''),
      id: new UntypedFormControl(''),
    });
  }

  public prepareDiscounts(): void {
    const discounts = this.data.course.discounts ? this.data.course.discounts : undefined;
    if (discounts) {
      discounts.map((discount: any): void => {
        const formControls: Record<string, any> = {
          discount_value: new UntypedFormControl(discount.discount_value || ''),
          discount_type: new UntypedFormControl(discount.discount_type || 1),
          annual_revenue: new UntypedFormControl(discount.annual_revenue || undefined),
          plans: new UntypedFormControl(discount.plans.map((plan: any) => {
            const foundPlan = this.planOptions.find((item): boolean => item.value === plan);

            return this.mappingOptionValue(foundPlan, 'name');
          })),
          users: new UntypedFormControl(discount.users.map((user: any) => {
            const foundUser = this.userOptions.find((item): boolean => item.value === user);

            return this.mappingOptionValue(foundUser, 'name');
          })),
          apply_to_any_plan: new UntypedFormControl(discount.apply_to_any_plan ? discount.apply_to_any_plan : false),
        };

        if (discount.id) {
          formControls["id"] = new UntypedFormControl(discount.id);
        }
        const formGroup: UntypedFormGroup = this.fb.group(formControls);
        this.discounts.push(formGroup);
      });
    }
  }

  public prepareChapters(): void {
    const chapters = this.data.course.chapters ? this.data.course.chapters : undefined;
    if (chapters) {
      chapters.map((chapter: any): void => {
        const formControls: Record<string, any> = {
          number: new UntypedFormControl(chapter.number || ''),
          title: new UntypedFormControl(chapter.title || ''),
          description: new UntypedFormControl(chapter.description || ''),
          course_id: new UntypedFormControl(chapter.course_id || ''),
          id: new UntypedFormControl(chapter.id || ''),
        };

        if (chapter.id) {
          formControls["id"] = new UntypedFormControl(chapter.id);
        }
        const formGroup: UntypedFormGroup = this.fb.group(formControls);
        this.chapters.push(formGroup);
      });
    }
  }

  public getSuggestedCourses(): any {
    return this.data.courseList.filter((item: any): boolean => item.id !== this.data.course.id);
  }

  public getPotentialCourseUnits(): any {
    return this.data.courseList.filter((item: any) => {
      // Unit cannot be its own parent
      const notOwnParent = item.id !== this.data.course.id;
      // Unit cannot be a parent course
      const notAParent = !item.is_parent;
      // Unit cannot be assigned to any other parent
      const notExistingUnit = item.parent == null || item.parent === this.data.course.id;
      return notOwnParent && notAParent && notExistingUnit;
    });
  }

  public prepareSuggestedCourses(): void {
    const suggestedCourses = this.getSuggestedCourses();
    const courseList: any = [];

    suggestedCourses.forEach((course: any): void => {
      if (this.data.course.suggested_courses?.length) {
        this.data.course.suggested_courses.forEach((item: any): void => {
          if (course.id === item) {
            courseList.push(this.mappingOptionValue(course, 'name'));
          }
        });
      }
    });

    this.suggestedCourseList.setValue(courseList);
  }

  public prepareCourseUnits(): void {
    const potentialCourseUnits = this.getPotentialCourseUnits();
    const courseList: any = [];

    potentialCourseUnits.forEach((course: any): void => {
      if (this.data.course.units?.length) {
        this.data.course.units.forEach((unit_id: any): void => {
          if (course.id === unit_id) {
            courseList.push(this.mappingOptionValue(course, 'name'));
          }
        });
      }
    });

    this.courseUnitList.setValue(courseList);
  }

  public preparePrices(): void {
    if (!this.data.course.prices.length) {
      this.priceList.map((price: any): void => {
        this.addPriceField(price);
      });
    } else {
      // Any new regions should show up for old courses as well
      let currentPriceList = [...this.priceList];
      this.data.course.prices.forEach((price: any): void => {
        this.addPriceField(price);
        currentPriceList = currentPriceList.filter(value => value.region !== price.region);
      });
      currentPriceList.forEach((price: any): void => {
        this.addPriceField(price);
      });
    }
  }

  public prepareInfo(): void {
    this.infoForm = this.fb.group({
      name: new UntypedFormControl(this.data.course.name),
      id: new UntypedFormControl(this.data.course.id),
      waitlist: new UntypedFormControl(this.data.course.waitlist),
      parent: new UntypedFormControl(this.data.course.parent ?? "None"),
      is_publish: new UntypedFormControl(this.data.course.is_publish),
      entitled_for_days: new UntypedFormControl(this.data.course.entitled_for_days, [Validators.required, Validators.min(1)]),
      offer_ongoing_access_for_days: new UntypedFormControl(this.data.course.offer_ongoing_access_for_days, [Validators.required, Validators.min(1)]),
      description_html: new UntypedFormControl(this.data.course.description_html || this.data.course.description),
    });
  }

  public get courseName(): string {
    return this.infoForm.get('name')?.value;
  }

  public get courseId(): string {
    return this.infoForm.get('id')?.value;
  }

  public get parentId(): string {
    return this.infoForm.get('parent')?.value;
  }

  public isChildCourse(): boolean {
    return this.data.course.parent != null;
  }

  private initOptionAndValue(): void {
    this.periodTypes = AppConstants.PERIOD_TYPES;
    combineLatest([
      this.apiService.get(AppConstants.ACCOUNTS_URL.ACCOUNTS + '?ignorePagination=true'),
      this.apiService.get(AppConstants.ACCOUNTS_URL.PLANS),
      this.apiService.get(AppConstants.API.REGION_SETTINGS.LIST)
    ]).subscribe(
      ([accounts, plans, regions]: any) => {
        const suggestedCourses = this.getSuggestedCourses();
        const courseUnits = this.getPotentialCourseUnits();

        this.planOptions = plans.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        this.userOptions = accounts.results?.map((item: any) => {
          return this.mappingOptionValue(item, 'username');
        });

        this.regionOptions = regions.results?.map((item: any) => this.mappingOptionValue(item, 'name'));

        this.coursesOptions = suggestedCourses.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        this.courseUnitsOptions = courseUnits.map((item: any) => {
          return this.mappingOptionValue(item, 'name');
        });

        if (regions?.results != null) {
          this.priceList = regions.results.map((item: Record<string, any>) => ({
            region: item.id,
            initial_purchase: 0,
            ongoing_access: 0,
            course_id: this.data.course.id,
          }));
        }

        this.prepareInfo();
        this.preparePrices();
        this.prepareDiscounts();
        this.prepareChapters();
        this.prepareSuggestedCourses();
        this.prepareCourseUnits();
      }
    );
  }
  public getFormControlPlansFromArray(controlName: string, index: number): any {
    return (this.discountsForm.get('discounts') as UntypedFormArray).controls[index].get(controlName);
  }

  public onSelectDiscPlanType(value: any, index: number): void {
    this.getFormControlPlansFromArray('plans', index)?.setValue(value);
  }

  public onSelectDiscUser(value: any, index: number): void {
    this.getFormControlPlansFromArray('users', index)?.setValue(value);
  }

  public onSelectCourses(value: any): void {
    this.suggestedCourses.get('courses')?.setValue(value);
  }

  public onSelectCourseUnits(value: any): void {
    this.courseUnits.get('courses')?.setValue(value);
  }

  private mappingOptionValue(item: any, param: string) {
    return {
      value: item?.id ?? item?.value,
      name: item[param],
    };
  }

  public get prices(): UntypedFormArray {
    return this.pricesForm.get('prices') as UntypedFormArray;
  }

  public addPriceField(priceItem: any): void {
    const formControls = {
      initial_purchase: new UntypedFormControl(priceItem.initial_purchase ? priceItem.initial_purchase : 0),
      region: new UntypedFormControl(priceItem.region),
      ongoing_access: new UntypedFormControl(priceItem.ongoing_access ? priceItem.ongoing_access : 0),
      id: new UntypedFormControl(priceItem.id),
      course_id: new UntypedFormControl(priceItem.course_id),
    };

    if (priceItem.id) {
      formControls["id"] = priceItem.id;
    }
    const price: UntypedFormGroup = this.fb.group(formControls);
    this.prices.push(price);
  }

  public get discounts(): UntypedFormArray {
    return this.discountsForm.get('discounts') as UntypedFormArray;
  }

  public get chapters(): UntypedFormArray {
    return this.chaptersForm.get('chapters') as UntypedFormArray;
  }

  public get suggestedCourseList(): UntypedFormGroup {
    return this.suggestedCourses.get('courses') as UntypedFormGroup;
  }

  public get courseUnitList(): UntypedFormGroup {
    return this.courseUnits.get('courses') as UntypedFormGroup;
  }

  public get courses(): UntypedFormGroup {
    return this.suggestedCourses.get('courses') as UntypedFormGroup;
  }

  public getRegionName(regionId: number): string {
    return this.regionOptions.find(mapping => mapping.value === regionId)?.name ?? `Region ${regionId}`;
  }

  public removeDiscountField(index: number): void {
    this.discounts.removeAt(index);
  }

  public removeChapterField(index: number): void {
    this.chapters.removeAt(index);
  }
  public addDiscountForm(): void {
    const discount: UntypedFormGroup = this.fb.group({
      discount_value: new UntypedFormControl('', [Validators.required]),
      discount_type: new UntypedFormControl(1, [Validators.required]),
      annual_revenue: new UntypedFormControl(undefined),
      plans: new UntypedFormControl([]),
      users: new UntypedFormControl([]),
      apply_to_any_plan: new UntypedFormControl(false),
    });
    this.discounts.push(discount);
  }

  public addChapterForm(): void {
    const chapterLength: number = this.chapters.length;

    const chapter: UntypedFormGroup = this.fb.group({
      number: new UntypedFormControl(chapterLength + 1),
      title: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(''),
      course_id: new UntypedFormControl(this.data.course.id),
    });
    this.chapters.push(chapter);
  }

  public save(): void {
    if (
      this.infoForm.valid ||
      this.pricesForm.valid ||
      this.discountsForm.valid ||
      this.suggestedCourses.valid ||
      this.chaptersForm.valid ||
      this.courseUnits.valid
    ) {
      const course: CourseDto = new CourseDto();
      course.name = this.infoForm.get('name')?.value;
      course.id = this.infoForm.get('id')?.value;
      course.is_publish = this.infoForm.get('is_publish')?.value;
      course.waitlist = this.infoForm.get('waitlist')?.value;
      course.entitled_for_days = this.infoForm.get('entitled_for_days')?.value;
      course.offer_ongoing_access_for_days = this.infoForm.get('offer_ongoing_access_for_days')?.value;
      course.description_html = `<span>${this.infoForm.get('description_html')?.value}</span>`;
      course.suggested_courses = this.suggestedCourses.get('courses')?.value.map((item: any) => item.value);
      course.units = this.courseUnits.get('courses')?.value.map((item: any) => item.value);
      course.prices = this.pricesForm
        .get('prices')
        ?.value.map((price: any) => {
          const returned_price = {
            region: price.region,
            initial_purchase: price.initial_purchase,
            ongoing_access: price.ongoing_access,
            course_id: price.course_id,
            id: price.id,
          };
          if (price.id) {
            returned_price.id = price.id;
          }
          return returned_price;
        });
      course.discounts = this.discountsForm.get('discounts')?.value?.map((item: any) => {
          const discount: {id?: number, plans?: Array<number>, users: Array<number> | undefined, apply_to_any_plan: boolean | undefined, discount_value: any, discount_type: any, course_id: number, annual_revenue: number | string | undefined} = {
            plans: [],
            users: undefined,
            annual_revenue: undefined,
            apply_to_any_plan: undefined,
            discount_value: undefined,
            discount_type: undefined,
            course_id: this.data.course.id
          };

          // This will now be ignored in the backend if `apply_to_any_plan` is set to true
          discount.plans = item?.plans?.map(
            (plan: any) => plan.value
          );

          discount.users = item.users?.map(
            (user: any) => user.value
          );
          discount.apply_to_any_plan = item.apply_to_any_plan;
          discount.discount_value = item.discount_value;
          discount.discount_type = item.discount_type;
          discount.annual_revenue = item.annual_revenue || undefined;

          if (item.id) {
            discount.id = item.id;
          }

          return discount;
        });

      course.chapters = this.chaptersForm.get('chapters')?.value?.map((item: any, index: number): ChapterDto => {
          return {
            id: item.id,
            number: index + 1,
            course_id: item.course_id,
            title: item.title,
            description: item.description,
          };
        });

      this.coursesService.update(this.data.course.id, course)
        .subscribe((): void => {
          this.dialogRef.close(true);
        }, (error): void => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
        });
    } else {
      const message: string = 'Please fill all fields';
      this.snackBar.open(message, 'OK', this.constant.TOAST_CONFIG.ERROR);
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }
}
