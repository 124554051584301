export const environment = {
  production: true,
  name: 'Dev',
  api: 'https://api.dev.inscyd.com/api/',
  serverURL: 'https://api.dev.inscyd.com/',
  v1URL: 'https://api.dev.inscyd.com/',
  stravaClientId: '107217',
  stripeKey: 'pk_test_53PksKIm7gVzMrlVIL9ncuk8',
  mixpanelToken: '1ad2913e085bd47d7ce1b5a734ff842f',
};
