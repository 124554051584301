import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { DeviceManagementDto, DeviceManagementResultDto, IDeviceDetail } from './device-management-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from '@shared/models/api-request';

@Injectable({
  providedIn: 'root',
})
export class DeviceManagementService extends ServiceBase {
public list( request: PagedAndSortedRequestDto): Observable<DeviceManagementResultDto> {
    const apiUrl: string = this._appConstants.API.DEVICE_MANAGEMENT.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new DeviceManagementResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: DeviceManagementDto) =>
            new DeviceManagementDto().deserialize(item)
          ),
        });
      })
    );
  }
  public getById(id: number): Observable<IDeviceDetail> {
    const apiUrl: string = String.Format(
      this._appConstants.API.DEVICE_MANAGEMENT.GET,
      id
    );
    return this._apiService.get(apiUrl);
  }

  public update(input: IDeviceDetail): Observable<any> {
    const apiUrl: string = String.Format(
      this._appConstants.API.DEVICE_MANAGEMENT.UPDATE,
      input.id
    );
    const body = {};
    Object.assign(body, input);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({
      body,
      apiUrl,
    });
    return this._apiService.put(apiRequest.apiUrl, apiRequest.body);
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(
      this._appConstants.API.DEVICE_MANAGEMENT.DELETE,
      id
    );
    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }
}
