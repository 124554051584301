import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ISearch } from "@shared/interfaces/search.interface";

@Injectable()
export class SearchService {
  private pageSearch$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public getPageSearchObs(): BehaviorSubject<string> {
    return this.pageSearch$;
  }

  public getCreatedConfig(searchName: string, matIcon: string, placeholder: string): ISearch {
    return {
      searchName,
      matIcon,
      placeholder,
    };
  }
}
