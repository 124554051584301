import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { throwError as _observableThrow, Observable } from 'rxjs';
import { catchError as _observableCatch, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { String } from 'typescript-string-operations';
import { GroupDto, GroupResultDto, PricingPlansDto, PricingPlansResultDto } from './pricing-plans-dto';
import { AccumulatedTestDTO, ManageOrganizationDto } from "../manage-organizations/manage-organization-dto";
import { PlanActivatedDto } from "../plan-purchase/plan-dto";
import {ApiRequest} from "@shared/models/api-request";

@Injectable({
  providedIn: 'root'
})
export class PricingPlansService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto): Observable<PricingPlansResultDto> {
    const apiUrl: string = this._appConstants.API.PRICING_PLANS.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new PricingPlansResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: PricingPlansDto) =>
            new PricingPlansDto().deserialize(item)
          ),
        });
      })
    );
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(this._appConstants.API.PRICING_PLANS.DELETE, id);

    return this._apiService.delete(apiUrl).pipe(
      _observableCatch((response_: any) => {
        return <Observable<void>>(<any>_observableThrow(response_));
      })
    );
  }

  public getPlanById(id: number): Observable<PricingPlansDto> {
    const apiUrl: string = String.Format(this._appConstants.API.PRICING_PLANS.GET_BY_ID, id);

    return this._apiService
      .get(apiUrl)
      .pipe(map((item: Object) => new PricingPlansDto().deserialize(item)));
  }

  public getGroups(): Observable<GroupResultDto> {
    const apiUrl: string = this._appConstants.API.PRICING_PLANS.GROUPS;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new GroupResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: GroupDto) =>
            new GroupDto().deserialize(item)
          ),
        });
      })
    );
  }

  public update(id: number, input: PricingPlansDto): Observable<PricingPlansDto> {
    const apiUrl: string = String.Format(this._appConstants.API.PRICING_PLANS.UPDATE, id);

    return this._apiService.put(apiUrl, input)
    .pipe(map((item: Object) => new PricingPlansDto().deserialize(item)));
  }

  public create(input: any): Observable<PricingPlansDto> {
    const apiUrl: string = this._appConstants.API.PRICING_PLANS.ADD;

    return this._apiService.post(apiUrl, input)
    .pipe(map((item: Object) => new PricingPlansDto().deserialize(item)));
  }

  public getAccumulatedTests(plan: PlanActivatedDto | ManageOrganizationDto): number {
    // Only returns bundled count for now
    const INITIAL_ACCUMULATED_TESTS: number = 0;

    if (plan?.accumulated_tests) {
      for (const testKeyGroup in plan?.accumulated_tests) {
        if (testKeyGroup.includes("|")) {
          const accumulatedTests: AccumulatedTestDTO[] = plan?.accumulated_tests[testKeyGroup];
          return accumulatedTests.reduce((acc: number, test: AccumulatedTestDTO) => test.test_count + acc, INITIAL_ACCUMULATED_TESTS);
        }
      }
    }

    return INITIAL_ACCUMULATED_TESTS;
  }

}
