<form [formGroup]="form">
  <mat-form-field class="animated-dropdown" appearance="outline" floatLabel="always">
    <mat-label>{{ label }}</mat-label>
    <mat-select multiple formControlName="dataValue">
      <mat-option *ngFor="let data of optionList" [value]="data.value">
        {{data.name}}
        <span style="color: darkolivegreen; font-weight: 500" *ngIf="appliedOptionList.includes(data.value)">applied<mat-icon>touch_app</mat-icon></span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
