import { Component, inject, OnInit } from '@angular/core';
import { IIntegration } from "@shared/components/interfaces/integration.interface";
import { ActivatedRoute } from "@angular/router";
import { FeatureFlagEnum } from '@shared/enums/feature-flag.enum';
import { WaffleFeatureFlagsService } from '@shared/services/feature-flags/waffle-feature-flags.service';
import { IntegrationComponentState } from './integrations.component.state';
import { IIntegrationTemplate } from '@shared/interfaces/integration-template.interface';
import { IIntegrationLink } from '@shared/interfaces/integration-link.interface';
import { ApiInfoDto } from '@core/services/manage-organizations/manage-organization-dto';
import { AppConstants } from '@core/constants';
import { SessionService } from '@core/services/session.service';

const IntegrationSlugFeatureFlagMapping: Record<string, string> = {
  cardioworld: FeatureFlagEnum.CARDIOWORLD_INTEGRATION,
  vqv: FeatureFlagEnum.VQV_INTEGRATION
};

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  providers: [IntegrationComponentState],
})
export class IntegrationsComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private featureFlagService: WaffleFeatureFlagsService = inject(WaffleFeatureFlagsService);

  public integrationObject: IIntegration;
  public azumApiKey: string = '';

  public integrationTemplates: IIntegrationTemplate[] = [];
  public integrations: IIntegrationLink[] = [];
  public apiInfo: ApiInfoDto[] = [];

  constructor(
    private readonly state: IntegrationComponentState,
    private sessionService: SessionService,
  ) { }

  public ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.data.api_keys.length) {
      this.azumApiKey = this.activatedRoute.snapshot.data.data.api_keys[0].key || '';
      this.integrationObject = {
        key: this.azumApiKey,
        name: 'AZUM'
      };
    }

    this.state.loadState();
    this.state.integrationTemplates$.subscribe(templates => this.integrationTemplates = templates);
    this.state.integrations$.subscribe(links => this.integrations = links);
    this.state.apiInfo$.subscribe(apiInfo => {
      this.apiInfo = apiInfo.filter(api => api?.alias != null && AppConstants.VISIBLE_API_INFO_ALIASES.includes(api.alias));
    });
  }


  public showIntegration(slug: string): boolean {
    return this.featureFlagService.getValue(IntegrationSlugFeatureFlagMapping[slug]) as boolean ?? false;
  }

  public showAPIKeys(): boolean {
    const authString: string = this.sessionService.getCookie('auth');
    const auth = JSON.parse(authString);
    return auth.is_admin as boolean;
  }

  public getIntegrationTemplate(slug: string) {
    return this.integrationTemplates.find(t => t.slug === slug);
  }

  public getIntegrationForTemplate(slug: string) {
    const integrationTemplate = this.getIntegrationTemplate(slug);
    if (!integrationTemplate) {
      return undefined
    }
    return this.integrations.find(integration => integration.template === integrationTemplate.id);
  }
}
