<div class="page-container">
  <app-custom-back-button [customUrl]="customUrl"></app-custom-back-button>
  <span class="general-info--font-size-35 line-height-40 u-display-block">Visualization</span>
  <ng-container *ngIf="!metricList.length || !sportList.length; else metricContainer">
    <div class="visualization__try-for-free-container u-margin-top-20 o-flex o-flex--align-items-center o-flex--justify-center u-border-radius border-color u-padding-16 tile-color-background">
      <span>No Data</span>
    </div>
  </ng-container>
  <ng-template #metricContainer>
    <div class="u-margin-top-20 o-flex o-flex--justify-space-between">
      <div class="width-49-percent">
        <app-sport-selection *ngIf="currentSportId" [currentSportId]="currentSportId" [availableSportList]="sportOptionList" (sportChanged)="onSportChanged($event)"></app-sport-selection>
      </div>
      <div class="width-49-percent" [ngClass]="{'visualization--visibility-hidden': isHideMetricDropdown}">
        <app-metric-selection *ngIf="metricOptionList" [preselectedMetric]="metricId" [config]="metricOptionList" (metricChanged)="onMetricChanged($event)"></app-metric-selection>
      </div>
    </div>
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="assets/custom-icons/physiological-performance-benchmark.svg" alt="physiological performance benchmark icon" class="u-margin-right-10">
          <span>Physiological Performance Benchmark</span>
        </ng-template>
        <div *ngIf="isEnabledMetric; else tryForFree" class="u-margin-top-20 o-flex o-flex--justify-space-between">
          <div class="width-49-percent">
            <app-meter-configuration
              *ngIf="currentMeterConfig"
              [config]="currentMeterConfig"
              [baseMeterOptionList]="baseMeterOptionList"
              [metricOptionsConfig]="meterOptionList"
              (meterConfigChanged)="onMeterConfigChanged($event)"
            >
              <div togglebutton class="o-flex o-flex--justify-center margin-left-10">
                <mat-label class="visualization__label-before-slide u-font-weight-500">hide</mat-label>
                <mat-slide-toggle (ngModelChange)="toggleCurrentMetric(metricId)" [ngModel]="isShowCurrentMetric">show in Athlete performance center</mat-slide-toggle>
              </div>
            </app-meter-configuration>
          </div>
          <div class="width-49-percent">
            <div *ngIf="performanceLimitsConfig" class="visualization__performance-limits-full">
              <app-performance-limits class="visualization__performance-limits-tab-min-width"
                                      [config]="performanceLimitsConfig"
                                      [referenceSystemId]="getReferenceSystemId(currentMeterConfig.base.name)"
                                      [dataPointNumber]="dataPointNumber"
                                      [anglePositionNumber]="0"
                                      [isApplyOverlay]="false"
                                      [sportConfigData]="getSportConfigData()"
                                      (performanceLimitChanged)="onPerformanceLimitsChanged($event)">

              </app-performance-limits>
            </div>
          </div>
        </div>
        <div class="u-margin-top-50 o-flex o-flex--justify-space-between o-flex--align-items-center">
          <div>
            <span class="u-display-block u-font-weight-700 u-font-size-16 line-height-16 u-margin-bottom-8">Preview</span>
            <span class="u-display-block  u-font-weight-400 u-font-size-14 faded-text-color line-height-16">Preview the result of your select below</span>
          </div>
        </div>
        <hr>
        <div class="u-margin-top-30">
          <app-preview-meter [config]="previewMeterConfig"></app-preview-meter>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="assets/custom-icons/metabolic-fingerprint.svg" alt="metabolic fingerprint icon" class="u-margin-right-10">
          <span>Metabolic Fingerprint</span>
        </ng-template>
        <div class="u-margin-top-20">
          <ng-container *ngIf="isEnabledMetric; else tryForFree">
            <app-metabolic-configurator
              *ngIf="currentMetabolicAngleConfig"
              [config]="currentMetabolicAngleConfig"
              [metricOptionsConfig]="angleOptionListBySportType"
              [dataPointNumber]="dataPointNumber"
              [anglePositionNumber]="1"
              (fingerprintConfigChanged)="onMetabolicFingerprintChanged($event)"
            ></app-metabolic-configurator>
            <div class="o-flex o-flex--justify-space-between u-margin-top-20">
              <div class="width-33-percent">
                <app-performance-limits
                  *ngIf="performanceLimitsConfigAngle1"
                  class="visualization__performance-limits-tab-min-width"
                  [angleName]="'angle 1'"
                  [config]="performanceLimitsConfigAngle1"
                  [referenceSystemId]="getReferenceSystemId(currentMetabolicAngleConfig.left.name)"
                  [dataPointNumber]="dataPointNumber"
                  [anglePositionNumber]="1"
                  [isApplyOverlay]="true"
                  (performanceLimitChanged)="onPerformanceLimitsChangedAngle1($event)"
                ></app-performance-limits></div>
              <div class="width-33-percent">
                <app-performance-limits
                  *ngIf="performanceLimitsConfigAngle2"
                  class="visualization__performance-limits-tab-min-width"
                  [angleName]="'angle 2'"
                  [config]="performanceLimitsConfigAngle2"
                  [dataPointNumber]="dataPointNumber"
                  [anglePositionNumber]="2"
                  [isApplyOverlay]="true"
                  [referenceSystemId]="getReferenceSystemId(currentMetabolicAngleConfig.center.name)"
                  (performanceLimitChanged)="onPerformanceLimitsChangedAngle2($event)"
                ></app-performance-limits></div>
              <div class="width-33-percent">
                <app-performance-limits
                  *ngIf="performanceLimitsConfigAngle3"
                  class="visualization__performance-limits-tab-min-width"
                  [angleName]="'angle 3'"
                  [config]="performanceLimitsConfigAngle3"
                  [dataPointNumber]="dataPointNumber"
                  [anglePositionNumber]="3"
                  [isApplyOverlay]="true"
                  [referenceSystemId]="getReferenceSystemId(currentMetabolicAngleConfig.right.name)"
                  (performanceLimitChanged)="onPerformanceLimitsChangedAngle3($event)"
                ></app-performance-limits></div>
            </div>
          </ng-container>
<!--          <div class="u-margin-top-50 u-margin-bottom-30">-->
<!--            <span class="u-display-block u-font-weight-700 u-font-size-16 line-height-16 u-margin-bottom-8">Preview</span>-->
<!--            <span class="u-display-block  u-font-weight-400 u-font-size-14 faded-text-color line-height-16">Preview the result of your select below</span>-->
<!--          </div>-->
<!--          <app-preview-metabolic-fingerprint *ngIf="currentMetabolicAngleConfig" [config]="currentMetabolicAngleConfig"></app-preview-metabolic-fingerprint>-->
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="assets/custom-icons/performance-development.svg" alt="performance development icon" class="u-margin-right-10">
          <span>Performance Development</span>
        </ng-template>
        <ng-container *ngIf="isEnabledMetric; else tryForFree">
          <div class="u-margin-top-20 u-position-relative">
            <div class="o-flex o-flex--justify-center o-flex--align-items-center overlay">
              <span class="u-font-size-60 u-color-red u-font-weight-500">Coming soon</span>
            </div>
            <div id="performance_development"></div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab *ngIf="isShowPerformanceDevelopmentTab">
        <ng-template mat-tab-label>
          <img src="assets/custom-icons/performance-development.svg" alt="performance development icon" class="u-margin-right-10">
          <span>Performance Development - Beta</span>
        </ng-template>
        <ng-container *ngIf="isEnabledMetric; else tryForFree">
          <ng-container *ngIf="isShowPDCHart">
            <ng-container *ngFor="let config of performance_development; let i = index;">
              <app-performance-development-wrapper
                [isRemovable]="performance_development.length > 1"
                (onRemoveGraph)="onRemoveGraph($event)"
                (onWrapperChanged)="onPDGraphChanged($event)"
                [id]="i"
                [yAxisConfig]="config"
                [simulationType]="simulationType"
                [optionList]="Y_AXIS_DROPDOWN_CONFIG_LIST.optionList"
                [primaryType]="PRIMARY_TYPE"
                [secondaryType]="SECONDARY_TYPE"
              ></app-performance-development-wrapper>
            </ng-container>
          </ng-container>
          <div *ngIf="performance_development.length <= 2 && performance_development.length" class="u-text-align-right">
            <button class="u-margin-top-20" mat-raised-button color="primary" (click)="addGraph()">+ Add another graph</button>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>

<ng-template #tryForFree>
  <div class="visualization__try-for-free-container u-margin-top-20 o-flex o-flex--align-items-center o-flex--justify-center u-border-radius border-color u-padding-16 tile-color-background">
    <button color="primary" mat-raised-button (click)="purchaseMetric()">{{tryForFreeText()}}</button>
  </div>
</ng-template>
