import { Component, DestroyRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { AppConstants } from '@core/constants';
import { ApiTrackingDto, ApiTrackingResultDto } from '@core/services/api-tracking/api-tracking-dto';
import { ApiTrackingService } from '@core/services/api-tracking/api-tracking.service';
import { ApiTrackingDialogComponent } from './api-tracking-dialog/api-tracking-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-api-tracking',
  templateUrl: './api-tracking.component.html',
  styleUrls: ['./api-tracking.component.scss']
})
export class ApiTrackingComponent extends PagedListingBaseComponent<ApiTrackingDto> {
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: ApiTrackingDto[] = [];
  public searchText: string = '';
  public displayedColumns: string[] = [ 'id', 'name', 'data', 'training_planner', 'get_metrics', 'created_metrics', 'fuelling_metrics', 'actions'];
  public isHadAction: boolean = false;

  constructor(
    private readonly apiTrackingService: ApiTrackingService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number): void {
    request.sorting = 'organization_id';
    request.pageIndex = pageNumber;
    this.apiTrackingService.list(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: ApiTrackingResultDto): void => {
      this.dataSource = res.items;
      this.totalItems = res.totalCount;
    });
  }

  public onInitPage(): void {
    this.isHadAction = true;
    if (!this.isHadAction) {
      this.displayedColumns = this.displayedColumns.filter((item: String): boolean => item !== 'actions');
    }

    super.onInitPage();
  }

  public viewDialog(element: ApiTrackingDto): void {
    const dialogRef: MatDialogRef<ApiTrackingDialogComponent> = this.dialog.open(
      ApiTrackingDialogComponent, {
        panelClass: 'general-dialog',
        width: '1000px',
        autoFocus: false,
        disableClose: true,
        data: {
          id: element?.organization_id,
        },
      });

      dialogRef.afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
        (res): void => {
          if (res) {
            this.snackBar.open('Item updated successfully', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
            this.refresh();
          }
        },
        (error): void => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      );
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }
}
