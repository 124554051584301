import { Component, DestroyRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { ApiService } from '@core/services/api.service';
import { catchError } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TestDialogComponent } from '@shared/dialogs/test-dialog/test-dialog.component';
import { AppConstants } from '@core/constants';
import { OptimizationOptionDialogComponent} from '@shared/dialogs/optimization-option-dialog/optimization-option-dialog.component';
import { MetabolicService } from '@core/services/metabolic-service/metabolic.service';
import * as _ from 'lodash-es';
import { ChartMappingData} from '../../apc/apc/data/chart-mapping-data';
import { ChartService } from '@shared/services/chart.service';
import { ChartConstants } from '../../apc/apc/data/chart-modules';
import { ConfirmDialogComponent, ConfirmDialogModel} from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { String } from 'typescript-string-operations';
import { TestService } from '@core/services/test/test.service';
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-single-athlete-metabolic-profiling',
  templateUrl: './single-athlete-metabolic-profiling.component.html',
  styleUrls: ['./single-athlete-metabolic-profiling.component.scss']
})
export class SingleAthleteMetabolicProfilingComponent extends AppBaseComponent {
  public isRendered: boolean = false;
  public rawTestDataTable: any = null;
  public calculatedDataTable: any = null;
  public measuredValues: any[] = [];
  public weightedValues: any = {};
  public linearRelationship: any = null;
  public primary_unit: string = '';
  public chartData: any = null;
  public chart: any = null;
  public isSpeed: any = false;
  public isTrainingZoneTemplate: boolean = false;
  public isAcceptAndView: boolean = false;
  public isCallingAction: boolean = false;
  public metabolic: any;
  public testId: any;
  public action: any;
  public dataSource: any;
  public test: any;
  public data: any;
  public sport_type: null = null;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private readonly snackBar: MatSnackBar,
    private router: Router,
    private readonly dialog: MatDialog,
    private metabolicService: MetabolicService,
    private chartService: ChartService,
    private testService: TestService,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.initOptionAndValue();
  }

  public callbackTests(response: any): void {
    if(response.results.length){
      this.dataSource = response.results;
      this.test = this.dataSource[0];
      this.isTrainingZoneTemplate = !!this.dataSource[0].training_zone_template;
      this.sport_type = this.test.sport.simulation_type;
      this.render(this.metabolic);
    } else {
      this.snackBar.open('Tests not found', 'OK', this.constant.TOAST_CONFIG.ERROR);
    }
  }

  private loadTests(): void {
    if(this.action) {
      this.metabolicService.fetchTestDataDelete({testId: this.testId})
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: any): void => {
        this.callbackTests(res);
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
      });
    } else {
      this.metabolicService.fetchTestData({testId: this.testId}).pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        }),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((res: any): void => {
        this.callbackTests(res);
      });
    }
  }

  private initOptionAndValue(): void {
    this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
    this.testId = this.route.snapshot.paramMap.get('id');
    this.action = this.route.snapshot.paramMap.get('action');
      this.testService.getMetabolicTest(this.testId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((resMetabolicTest: Object): void => {
        this.metabolic = resMetabolicTest;
        this.loadTests();
      }
    );
  }

  private reset(): void {
    this.chart = null;
    this.rawTestDataTable = null;
    this.calculatedDataTable = null;
    this.measuredValues = [];
    this.weightedValues = {};
  }

  private render(chartData: any): void {
    if (this.test?.id != this.testId) {
      return;
    }

    this.reset();
    if (!chartData) return;
    this.isRendered = true;
    this.primary_unit = this.chartService.findXAxisLabel(this.test.sport.primary_type);
    const chartConstant = ChartConstants.TEST_DATA;
    this.customRendererService.empty(chartConstant.ELEMENT_ID);
    this.chartData = chartData[6];

    let request = {
      data: this.chartData,
      testId: this.test.id,
      elementId: chartConstant.ELEMENT_ID,
      type: chartConstant.ID,
      test_type: this.test.test_type,
      legend: 'legends',
      mappingData: ChartMappingData,
      test: this.test,
    };

    setTimeout((): void => {
      const isMissing: any = this.chartService.ChartBokeh(request);
      this.validateDataDF(isMissing);

      if (this.test?.sport) {
        this.isSpeed =
          this.test.sport.simulation_type == AppConstants.SIMULATE_TYPE.SPEED;
      }

      if (chartData[6].table_data) {
        if (this.test?.use_old_raw_test_data_table) {
          this.rawTestDataTable = JSON.parse(chartData[6].table_data);
          this.rawTestDataTable.data = _.map(
            this.rawTestDataTable.data,
          );
        } else {
          this.rawTestDataTable = JSON.parse(
            chartData[6].table_data.measured_values_table
          );
          this.rawTestDataTable.data = _.map(
            this.rawTestDataTable.data,
          );

          this.calculatedDataTable = JSON.parse(
            chartData[6].table_data.calculated_values_table
          );
          this.calculatedDataTable.data = _.map(
            this.calculatedDataTable.data,
          );
        }
      }
      this.linearRelationship = chartData[6].linear_relationship;
    }, 500);
  }

  private validateDataDF(isMissing: any): void {
    let noDataMessage: string = '';
    if (isMissing.data) {
      noDataMessage = ChartConstants.MESSAGES.NO_DATA;
    } else if (isMissing.speed) {
      noDataMessage = ChartConstants.MESSAGES.NO_SPEED_DATA;
    } else if (isMissing.power) {
      noDataMessage = ChartConstants.MESSAGES.NO_POWER_DATA;
    }
    if (noDataMessage) {
      this.snackBar.open(noDataMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
    }
  }

  public handleAcceptAndView(): void {
    this.isAcceptAndView = true;
    this.openOptimizationOptionsModal(true);
  }

  public handleAcceptView(): void {
    this.isAcceptAndView = false;
    this.openOptimizationOptionsModal(false);
  }

  public openOptimizationOptionsModal(isAcceptAndView: boolean): void {
    const type: string = AppConstants.TEST_TYPES.LACTATE;
    let modalUpdate: MatDialogRef<OptimizationOptionDialogComponent> = this.dialog.open(OptimizationOptionDialogComponent, {
      panelClass: 'general-dialog',
      disableClose: true,
      data: {
        type,
      },
    });

    modalUpdate.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any): void => {
      if (!data || data.action !== 'confirm') {
        return;
      }
      this.doTestOptimization(isAcceptAndView, data);
    });
  }

  public handleRedirect(testId: number, isAcceptView: boolean): void {
    if (!isAcceptView) {
      const createType: string | null = this.route.snapshot.queryParamMap.get('create');
      if(createType){
        this.router.navigateByUrl('home/manage/tests');
      }
    } else {
      this.router.navigateByUrl('home/apc/setcard/' + testId);
    }
  }

  public doTestOptimization(isAcceptView: boolean, data: any): void {
    let param_array: any[] = [];
    let params: string = '';

    if (data && data.action == "confirm") {
      if (data.productId) {
            param_array.push('product=' + data.productId);
      }
      if (data.planId) {
            param_array.push('plan=' + data.planId);
      }
      params = param_array.join('&');
    }
    const api: string = `tests/${this.testId}/accept/${params ? '?' + params : ''}`;
    this.apiService.put(api, {}).pipe(
      catchError((error) => {
        const paramErrors = error.split(' ').slice(1); // Ignore the initial 'Error: ' prefix
        if (error  === 'Error: TEST_ALREADY_ACTIVE') {
          this.router.navigateByUrl('home/metabolic-profile/' + this.testId + '/');
        }
        if (
          paramErrors.length > 0 &&
          paramErrors.indexOf('500') > -1
        ) {
          let testName = '';
          if (paramErrors[1] == 'newtype')
              testName = "PPD test";
          else if (paramErrors[1] == AppConstants.TEST_TYPES.LACTATE)
              testName = "Lactate test";
          else if (paramErrors[1] == AppConstants.TEST_TYPES.VIRTUAL)
              testName = "Virtual test";
          else if (paramErrors[1] == AppConstants.TEST_TYPES.CRITICAL_POWER)
              testName = "critical power test";
          else if (paramErrors[1] == AppConstants.TEST_TYPES.MANUAL)
              testName = 'Manual test';
          this.isCallingAction = false;
          this.popupPurchase(testName, error);
        } else {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
        }
        throw error;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((): void => {
      this.snackBar.open('Item updated successfully.', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
      this.handleRedirect(this.testId, isAcceptView);
    });
  }

  private popupPurchase(testName: string, error: string): void {
    const paramErrors: string[] = error.split(' ');
    const message: string = `Maximum number of ${testName}s has been exceeded. Do you want to purchase more?`;
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Confirmation', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result): void => {
      if (result) {
        const params1: string = '?plan=' + paramErrors[3] + '&type=' + paramErrors[2] + '&new_id=' + paramErrors[4];
        const apiUrl: string = String.Format(this.constant.API.INVOICES.PURCHASE_EXCEED, params1);
        this.apiService.post(apiUrl, {})
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
          (): void => {
            this.handleRedirect(this.testId, this.isAcceptAndView);
          },
          (errorPurchase): void => {
            this.snackBar.open(errorPurchase, 'OK', this.constant.TOAST_CONFIG.ERROR);
          }
        );
      }
    });
  }

  public handleCancelView(isManual: boolean, isPPD: boolean, isVirtual: boolean, isLactate: boolean): void {
    const id: string | null = this.route.snapshot.paramMap.get('id');
    this.router.navigate(['home/manage', 'tests'], {
      queryParams: {
        action: 'back',
        test_id: id,
        create: 'lactate'
      }
    });

    const dialogRef: MatDialogRef<TestDialogComponent> = this.dialog.open(TestDialogComponent, {
      width: '50%',
      height: '95%',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        isManual,
        isPPD,
        isVirtual,
        isLactate,
        isBack: true,
        testId: id
      }
    });
    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
      if (res) {
        let message: string = 'Item created successfully';
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      }
    });
  }
}
