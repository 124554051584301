import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConstants } from '@core/constants';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PagedAndSortedRequestDto, PagedListingBaseComponent } from '@shared/components/paged-listing-component-base';
import {DeviceManagementDto, DeviceManagementResultDto} from '@core/services/device-management/device-management-dto';
import { MyDevicesService } from '@core/services/device-management/my-devices.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-my-devices',
  templateUrl: './my-devices.component.html',
  styleUrls: ['./my-devices.component.scss']
})
export class MyDevicesComponent extends PagedListingBaseComponent<DeviceManagementDto> {
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: any;
  public displayedColumns: string[] = [
    'id',
    'organization__name',
    'name',
    'ip',
    'updated_at',
    'action',
  ];
  public isHadAction: boolean = false;

  constructor(
    private readonly deviceService: MyDevicesService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public onInitPage(): void {
    this.isHadAction =
      this.rights.admin_devices_update || this.rights.admin_devices_delete;
    if (!this.isHadAction) {
      this.displayedColumns = this.displayedColumns.filter(
        (item: string): boolean => item !== 'action'
      );
    }
    super.onInitPage();
  }

  protected getPagedRequestDto(): PagedAndSortedRequestDto {
    return this.request;
  }
  protected list(request: PagedAndSortedRequestDto, pageNumber: number): void {
    request.pageIndex = pageNumber;
    this.deviceService.list(request).subscribe((res: DeviceManagementResultDto): void => {
      this.dataSource = new MatTableDataSource(res.items);
      this.totalItems = res.totalCount;
    });
  }

  public deleteDevice(id: number): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.deviceService.delete(id).subscribe(
            (): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }
}
