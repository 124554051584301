export enum FitParseSpeedUnit {
  KilometersPerHour = "km/h",
}

export enum FitParserLengthUnit {
  Kilometer = "km",
}

export enum FitParserTemperatureUnit {
  Kelvin = "kelvin",
}

export enum FitParserMode {
  Both = "both",
}
