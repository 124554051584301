export interface TestTypeOption {
  value: string;
  name: string;
}

export const TEST_TYPE_OPTIONS: Array<TestTypeOption> = [
  {
    value: "test_create_lactate",
    name: "Lactate"
  },
  {
    value: "test_create_critical_power_test",
    name: "PPD"
  }
];

export const SPORT_OPTIONS = [
  {
    value: "sport_can_use_id_50_cycling",
    name: "Cycling"
  },
  {
    value: "sport_can_use_id_57_triathlon-cycling",
    name: "Triathlon-Cycling"
  },
  {
    value: "sport_can_use_id_53_running",
    name: "Running"
  },
  {
    value: "sport_can_use_id_86_trail_running",
    name: "Trail Running"
  },
  {
    value: "sport_can_use_id_56_triathlon-running",
    name: "Triathlon-Running"
  },
  {
    value: "sport_can_use_id_5_swimming_-_freestyle",
    name: "Swimming - Freestyle"
  },
  {
    value: "sport_can_use_id_60_swimming_\u2013_breaststroke",
    name: "Swimming - Breaststroke"
  },
  {
    value: "sport_can_use_id_59_swimming_\u2013_butterfly",
    name: "Swimming - Butterfly"
  },
  {
    value: "sport_can_use_id_58_swimming_\u2013_backstroke",
    name: "Swimming - Backstroke"
  },
  {
    value: "sport_can_use_id_81_xc_ski",
    name: "XC Ski"
  },
  {
    value: "sport_can_use_id_71_ski_-_roller_(treadmill)",
    name: "Ski - Roller (Treadmill)"
  },
  {
    value: "sport_can_use_id_83_ski_alpin_(rad)",
    name: "Ski Alpin (Rad)"
  },
  {
    value: "sport_can_use_id_63_kajak",
    name: "Kajak"
  },
  {
    value: "sport_can_use_id_68_kajak_speed",
    name: "Kajak Speed"
  },
  {
    value: "sport_can_use_id_61_rowing_(ergometer)",
    name: "Rowing (ergometer)"
  },
  {
    value: "sport_can_use_id_65_roller_skating",
    name: "Roller Skating"
  }
];
