import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {AppBaseComponent } from '@shared/components/app-component-base';
import { AnaerobicThresholdDialogComponent } from '../dialogs/anaerobic-threshold-dialog/anaerobic-threshold-dialog.component';
import { MaxEffortDialogComponent } from '../dialogs/max-effort-dialog/max-effort-dialog.component';
import { MeanMaxPowerDialogComponent } from '../dialogs/mean-max-power-dialog/mean-max-power-dialog.component';
import { SingleEffortDialogComponent } from '../dialogs/single-effort-dialog/single-effort-dialog.component';
import { SprintDialogComponent } from '../dialogs/sprint-dialog/sprint-dialog.component';
import { VlaMaxDialogComponent } from '../dialogs/vla-max-dialog/vla-max-dialog.component';
import { Vo2MaxDialogComponent } from '../dialogs/vo2-max-dialog/vo2-max-dialog.component';
import { AppConstants } from '@core/constants';
import { PpdService } from '../services/ppd.service';
import { ApiService } from '@core/services/api.service';
import { RampTestDialogComponent } from "../dialogs/ramp-test-dialog/ramp-test-dialog.component";
import { GENDER_ENUM } from "@shared/enums/gender.enum";
import { SPORT_NAME_ENUM } from "@shared/enums/sport-name.enum";
import { AddFitFileDialogComponent } from '../dialogs/add-fit-file-dialog/add-fit-file-dialog.component';
import { CypressHelperService } from "@shared/services/cypress-helper.service";
import { MatDialogRef } from "@angular/material/dialog";

export interface DialogsData {
  is_speed_sport?: boolean;
  duration_efforts?: number;
  selected_power?: number[];
  selected_speed?: number[];
  selected_distance?: number[];
  selected_time?: number[];
  average_power?: number;
  average_speed?: number;
  test?: any;
  lactate_rest_values?: number[];
  post_lactate_values?: number[];
  origin?: any;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent extends AppBaseComponent implements OnChanges {
  @Output() public updateProgressBaseOnNumberOfEntities: EventEmitter<any> = new EventEmitter<any>();
  @Input() public chartData: any;

  public dataSource: any = [];
  public dataSource2: any = [];
  public displayedColumns: string[] = ['type', 'power', 'duration', 'additional', 'action'];
  public sport_type?: number;
  public testID?: number;
  public items: any = [];
  public boxSelectionData: any = [];
  public sprintButtonDisabled: boolean = true;
  public maxEffortButtonDisabled: boolean = true;
  public selectedTabId: number = 0;
  public is_speed_sport: boolean = true;
  public athlete_weight!: number;
  public meanMaxPowerButtonDisabled: boolean = true;
  public singleEffortButtonDisabled: boolean = true;
  public rampTestButtonDisabled: boolean = true;
  public isRampTestButtonVisible: boolean = false;

  constructor(
    private readonly dialog: MatDialog,
    private ppdService: PpdService,
    private apiService: ApiService,
    private cypressHelperService: CypressHelperService,
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData'] && this.chartData.test != undefined) {
      this.prepareChartData(this.chartData);
      this.hydrateFromLocalStorage();
      if (this.chartData.test.sport.name === SPORT_NAME_ENUM.CYCLING || this.chartData.test.sport.name === SPORT_NAME_ENUM.TRIATHLON_CYCLING) {
        this.rampTestButtonDisabled = false;
        this.isRampTestButtonVisible = true;
      }
    }
  }

  public openMeanMaxDialog(): void {
    const dialogRef: MatDialogRef<MeanMaxPowerDialogComponent> = this.dialog.open(MeanMaxPowerDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: this.prepareSelectedData('MEAN_MAX'),
    });

    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res != false) {
        this.acceptMeanMaxPowerDialog(res);
      }
    });
  }

  public openSingleEffortDialog(): void {
    const dialogRef: MatDialogRef<SingleEffortDialogComponent> = this.dialog.open(SingleEffortDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: this.prepareSelectedData('SINGLE_EFFORT'),
    });

    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res != false) {
        this.acceptSingleEffortDialog(res);
      }
    });
  }

  public openRAMPTestDialog(): void {
    const dialogRef: MatDialogRef<RampTestDialogComponent> = this.dialog.open(RampTestDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res) {
        this.acceptRAMPTestDialog(res);
      }
    });
  }

  public openSprintDialog(): void {
    const dialogRef: MatDialogRef<SprintDialogComponent> = this.dialog.open(SprintDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: this.prepareSelectedData('SPRINT'),
    });

    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res != false) {
        this.acceptSprintdialog(res);
      }
    });
  }

  public openMaxEffortDialog(): void {
    const dialogRef: MatDialogRef<MaxEffortDialogComponent> = this.dialog.open(MaxEffortDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: this.prepareSelectedData('MAX_EFFORT'),
    });
    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res != false) {
        this.acceptMaxEffortDialog(res);
      }
    });
  }

  openVlaDialog() {
    const dialogRef: MatDialogRef<VlaMaxDialogComponent> = this.dialog.open(VlaMaxDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res != false) {
        this.processManualEntryVlamax(res);
      }
    });
  }

  openVo2Dialog() {
    const dialogRef: MatDialogRef<Vo2MaxDialogComponent> = this.dialog.open(Vo2MaxDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res != false) {
        this.processManualEntryVO2max(res);
      }
    });
  }

  public openAnaerobicThresholdDialog(): void {
    const dialogRef: MatDialogRef<AnaerobicThresholdDialogComponent> = this.dialog.open(AnaerobicThresholdDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: this.prepareSelectedData('ANAEROBIC_THRESHOLD'),
    });
    dialogRef.afterClosed().subscribe((res: any): void => {
      if (res != false) {
        this.processManualEntryAT(res);
      }
    });
  }

  public prepareSelectedData(dialog: string): DialogsData {
    let data!: DialogsData;
    switch (dialog) {
      case 'MEAN_MAX':
        data = {
          is_speed_sport: this.is_speed_sport,
          duration_efforts: this.boxSelectionData[this.selectedTabId].selectionInSeconds,
          selected_power: this.boxSelectionData[this.selectedTabId].selectedPower || [],
          selected_speed: this.boxSelectionData[this.selectedTabId].selectedSpeed || [],
          selected_distance: this.boxSelectionData[this.selectedTabId].selectedDistance || [],
          selected_time: this.boxSelectionData[this.selectedTabId].selectedTime || [],
          average_power: this.boxSelectionData[this.selectedTabId].averageSelectedPower,
          average_speed:
            Math.round(
              (this.boxSelectionData[this.selectedTabId].averageSelectedSpeed /
                3.6) *
                1000
            ) / 1000,
          test: this.chartData.test,
        };
        return data;
      case 'SPRINT':
        data = {
          is_speed_sport: this.is_speed_sport,
          duration_efforts: this.boxSelectionData[this.selectedTabId].selectionInSeconds,
          average_power: this.boxSelectionData[this.selectedTabId].averageSelectedPower,
          average_speed:
            Math.round(
              (this.boxSelectionData[this.selectedTabId].averageSelectedSpeed /
                3.6) *
                1000
            ) / 1000,
          lactate_rest_values: [],
          post_lactate_values: [],
        };
        return data;
      case 'SINGLE_EFFORT':
        data = {
          duration_efforts: this.boxSelectionData[this.selectedTabId].selectionInSeconds,
          average_power: this.boxSelectionData[this.selectedTabId].averageSelectedPower,
        };
        return data;
      case 'MAX_EFFORT':
        data = {
          is_speed_sport: this.is_speed_sport,
          duration_efforts: this.boxSelectionData[this.selectedTabId].selectionInSeconds,
          average_power: this.boxSelectionData[this.selectedTabId].averageSelectedPower,
          average_speed:
            Math.round(
              (this.boxSelectionData[this.selectedTabId].averageSelectedSpeed /
                3.6) *
                1000
            ) / 1000,
          lactate_rest_values: [],
          post_lactate_values: [],
        };
        return data;
      case 'ANAEROBIC_THRESHOLD':
        data = {
          is_speed_sport: this.is_speed_sport,
        };
        return data;
      default:
        return data;
    }
  }

  public removeMeasuredValue(id: number): void {
    this.dataSource2 = this.dataSource2.filter((item: any): boolean => item.id != id);
    this.dataSource = [...this.dataSource2];
    this.updateProgressBar();
  }

  public acceptMaxEffortDialog(data: any): void {
    if (this.is_speed_sport) {
      let median_lactate_rest_value: number = this.median(data.lactate_rest_values);
      let max_post_lactate_value: number = Math.max.apply(null, data.post_lactate_values);
      let lactate_distribution: number = this.chartData.test.origin.proz_lactat_dist_space_100_override / 100;
      let vo2_demand: number = this.ppdService.get_vo2tot(data.average_speed, this.chartData.test.origin);
      let delta_lactate: number = max_post_lactate_value - median_lactate_rest_value;
      data.range = 0.05; // VO Option 3 Range - Fixed in the example
      let la_per_min: number = delta_lactate / (data.duration_efforts / 60);
      let la_o2_equiv: number = 5.49 * lactate_distribution;
      let la_vo2: number = la_per_min * la_o2_equiv;

      // +1.5 for final VO2 calculation
      let target: number = vo2_demand - la_vo2 + 1.5;
      data.lower = parseFloat((target * (1.0 - data.range)).toFixed(3));
      data.upper = parseFloat((target * (1.0 + data.range)).toFixed(3));
      data.target = parseFloat(target.toFixed(3));

      // Push data to table
      let record: any = this.emptyMeasuredValuesRecord();
      record.id = new Date().getTime();
      record.type = 'Lactate';
      record.additionalValue = max_post_lactate_value;
      record.additionalValueString =
        max_post_lactate_value + ' (mmol/l)kg<sup>-1</sup>';
      record.power = data.average_power;
      record.speed = data.average_speed;
      record.powerTolerance = data.range;
      record.duration = data.duration_efforts;
      record.dataForPOST.entity = 'VO';
      record.dataForPOST.option = 3;
      record.dataForPOST.target = data.target;
      record.dataForPOST.range = data.range;
      record.dataForPOST.upper = data.upper;
      record.dataForPOST.lower = data.lower;

      this.dataSource2.push(record);
      this.dataSource = [...this.dataSource2];
      this.updateProgressBar();
    } else if (!this.is_speed_sport) {
      let median_lactate_rest_value = this.median(data.lactate_rest_values);
      let max_post_lactate_value = Math.max.apply(
        null,
        data.post_lactate_values
      );
      // Value receive from test

      let watto2eq = this.chartData.test.origin.get_o2_per_watt_eq_b;
      let lactate_distribution: number = this.chartData.test.origin.proz_lactat_dist_space_100_override / 100;

      let vo2_demand: number = (data.average_power * watto2eq) / this.athlete_weight;
      let delta_lactate: number = max_post_lactate_value - median_lactate_rest_value;
      data.range = 0.05; // VO Option 3 Range - Fixed in the example
      let la_per_min: number = delta_lactate / (data.duration_efforts / 60);
      let la_o2_equiv: number = 5.49 * lactate_distribution;
      let la_vo2: number = la_per_min * la_o2_equiv;

      let target: number = vo2_demand - la_vo2 + 1.5;
      data.lower = parseFloat((target * (1.0 - data.range)).toFixed(3));
      data.upper = parseFloat((target * (1.0 + data.range)).toFixed(3));
      data.target = parseFloat(target.toFixed(3));

      // Push data to table
      let record = this.emptyMeasuredValuesRecord();
      record.id = new Date().getTime();
      record.type = 'Lactate';
      record.additionalValue = max_post_lactate_value;
      record.additionalValueString =
        max_post_lactate_value + ' (mmol/l)kg<sup>-1</sup>';
      record.power = data.average_power;
      record.powerTolerance = data.range;
      record.duration = data.duration_efforts;
      record.dataForPOST.entity = 'VO';
      record.dataForPOST.option = 3;
      record.dataForPOST.target = data.target;
      record.dataForPOST.range = data.range;
      record.dataForPOST.upper = data.upper;
      record.dataForPOST.lower = data.lower;

      this.dataSource2.push(record);
      this.dataSource = [...this.dataSource2];
      this.updateProgressBar();
    }
  }
  public median(values: number[]): number {
    if (values.length === 0) return 0;

    values.sort((a: number, b: number) => {
      return a - b;
    });

    let half: number = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  public getVO2MaxRampTestBasedOnMale(power: number, weight: number): number {
    const SLOPE: number = 9.44;
    const OFFSET: number = 592;

    return (SLOPE * power + OFFSET) / weight;
  }

  public getVO2MaxRampTestBasedOnFemale(power: number, weight: number): number {
    const OFFSET: number = 23.82;
    const SLOPE: number = 0.11792;

    return (power + OFFSET) / (SLOPE * weight);
  }

  public acceptRAMPTestDialog(data: any): void {
    const DEFAULT_DURATION: number = 30;
    const GENDER: string = this.chartData.test.origin.athlete.gender;
    const WEIGHT: number = this.chartData.test.origin.mass;
    const TOLERANT_NUMBER_MALE: string = '5';
    const TOLERANT_NUMBER_FEMALE: string = '10';
    let toleranceNumber: string = '';
    let VO2Max: number = 0;
    let averagePower: number = data.average_power;

    if (GENDER === GENDER_ENUM.MALE) {
      VO2Max = this.getVO2MaxRampTestBasedOnMale(averagePower, WEIGHT);
      toleranceNumber = TOLERANT_NUMBER_MALE;
    } else if (GENDER === GENDER_ENUM.FEMALE) {
      VO2Max = this.getVO2MaxRampTestBasedOnFemale(averagePower, WEIGHT);
      toleranceNumber = TOLERANT_NUMBER_FEMALE;
    }

    let record: any = this.emptyMeasuredValuesRecord();
    record.id = new Date().getTime();
    record.type = 'Ramp Test';
    record.duration = DEFAULT_DURATION;
    record.additionalValue = VO2Max;
    record.power = averagePower;
    record.dataForPOST.entity = 'VO';
    record.dataForPOST.option = 1;
    record.dataForPOST.target = VO2Max;
    record.dataForPOST.range = parseFloat(toleranceNumber) / 100;
    record.dataForPOST.upper = parseFloat((VO2Max * (1 + parseFloat(toleranceNumber) / 100)).toFixed(3));
    record.dataForPOST.lower = parseFloat((VO2Max * (1 - parseFloat(toleranceNumber) / 100)).toFixed(3));
    this.dataSource2.push(record);
    this.dataSource = [...this.dataSource2];
    this.updateProgressBar();
  }

  public acceptSingleEffortDialog(data: any): void {
    let energyOfEffort = data.work;
    let energyWithoutW: number = energyOfEffort - data.manual_w;
    data.target = energyWithoutW / data.duration_efforts;
    data.range = 0.15; // AT Option 3 Range
    data.lower = parseFloat((data.target * (1.0 - data.range)).toFixed(3));
    data.upper = parseFloat((data.target * (1.0 + data.range)).toFixed(3));
    data.target = parseFloat(data.target.toFixed(3));

    let record = this.emptyMeasuredValuesRecord();
    record.id = new Date().getTime();
    record.type = 'Power Duration';
    record.additionalValue = data.manual_w;
    record.additionalValueString = (data.manual_w / 1000).toFixed(2) + ' (kJ)';
    record.power = data.average_power;
    record.duration = data.duration_efforts;
    record.work = data.work;
    record.dataForPOST.entity = 'AT';
    record.dataForPOST.option = 3;
    record.dataForPOST.target = data.target;
    record.dataForPOST.range = data.range;
    record.dataForPOST.upper = data.upper;
    record.dataForPOST.lower = data.lower;

    this.dataSource2.push(record);
    this.dataSource = [...this.dataSource2];
    this.updateProgressBar();
  }
  public acceptSprintdialog(data: any): void {
    let avg_lactate_rest_value: number = this.median(data.lactate_rest_values);
    let max_post_lactate_value: number = Math.max.apply(null, data.post_lactate_values);
    let delta_lactate: number = max_post_lactate_value - avg_lactate_rest_value;
    data.range = 0.2; // VL Option 3 Range - Fixed in the example of VL Option 3 - excel file

    let target = delta_lactate / (data.duration_efforts * 0.8);
    data.lower = parseFloat((target * (1.0 - data.range)).toFixed(3));
    data.upper = parseFloat((target * (1.0 + data.range)).toFixed(3));
    data.target = parseFloat(target.toFixed(3));

    let record = this.emptyMeasuredValuesRecord();
    record.id = new Date().getTime();
    record.type = 'Lactate';
    record.additionalValue = max_post_lactate_value;
    record.additionalValueString =
      max_post_lactate_value + ' (mmol/l)kg<sup>-1</sup>';
    record.power = data.average_power;
    record.speed = data.average_speed;
    record.powerTolerance = data.range;
    record.duration = data.duration_efforts;
    record.dataForPOST.entity = 'VL';
    record.dataForPOST.option = 3;
    record.dataForPOST.target = data.target;
    record.dataForPOST.range = data.range;
    record.dataForPOST.upper = data.upper;
    record.dataForPOST.lower = data.lower;

    // Store original values to check data overridden
    record.initial_average_speed = data.initial_average_speed;
    record.initial_average_power = data.initial_average_power;
    record.initial_duration_efforts = data.initial_duration_efforts;

    if (
      record.initial_average_power != record.power ||
      record.initial_duration_efforts != record.duration ||
      record.initial_average_speed != record.speed
    ) {
      this.ppdService.overrideSprintData(record.speed, record.duration);
    }

    this.dataSource2.push(record);
    this.dataSource = [...this.dataSource2];
    this.updateProgressBar();
  }

  public smoothingVL1(data: any, record: any, a: number, b: number, d: number, watto2eq: number, mass: number): void {
    //let smooth_api = CONSTANT.BACKEND_URL + "tests/smoothing_sprint_data/";
    // Have to slice (2) because offset for Selected Speed is (2+1) seconds - detail in single.component.js
    this.apiService.post('tests/smoothing_sprint_data/', {speeds: data.selected_speed.slice(2),})
      .subscribe((response: any): void => {
        let smooth_speeds = response;
        let smooth_times: any[] = [];
        for (let i = 0; i < smooth_speeds.length; i++) {
          smooth_times.push(i);
        }
        record.selected_power = this.ppdService.calc_MechanicalPower_SelectionCase_Smooth(a, b, d, smooth_speeds, smooth_times, watto2eq, mass);
        // REPEAT - Recalculate average power based on selected powers
        data.average_power = this.ppdService.average(record.selected_power);
        data.work = data.duration_efforts * data.average_power;
        record.power = data.average_power;
        record.duration = data.duration_efforts;
        record.work = data.work;
        record.dataForPOST.entity = 'VL';
        record.dataForPOST.option = 1;
        record.initial_average_speed = data.initial_average_speed;
        this.dataSource2.push(record);
        this.dataSource = [...this.dataSource2];
        this.updateProgressBar();
        this.hideLoading();
      });
  }
  public acceptMeanMaxPowerDialog(data: any): void {
    if (data.duration_efforts >= 150 && data.duration_efforts <= 1800) {
      if (this.is_speed_sport) {
        // Use speed to calculate power
        let vo2tot: number = this.ppdService.get_vo2tot(
          data.average_speed,
          this.chartData.test.origin
        );
        let mass: number = this.athlete_weight;
        let watto2eq = this.chartData.test.origin.watto2eq;
        let power: number = (vo2tot * mass) / watto2eq;
        data.average_power = Math.round(power * 100) / 100;
        data.work = data.duration_efforts * data.average_power;

        let record = this.emptyMeasuredValuesRecord();
        record.id = new Date().getTime();
        record.isSpecialAT = true; // Only consider as 1/3
        record.type = 'Power Duration';
        record.power = data.average_power;
        record.speed = data.average_speed;
        record.duration = data.duration_efforts;
        record.work = data.work;
        record.dataForPOST.entity = 'AT';
        record.dataForPOST.option = 4;
        record.initial_average_speed = data.initial_average_speed;
        this.dataSource2.push(record);
      } else if (!this.is_speed_sport) {
        // Push data to table
        let record = this.emptyMeasuredValuesRecord();
        record.id = new Date().getTime();
        record.isSpecialAT = true; // Only consider as 1/3
        record.type = 'Power Duration';
        record.power = data.average_power;
        record.duration = data.duration_efforts;
        record.work = data.work;
        record.dataForPOST.entity = 'AT';
        record.dataForPOST.option = 4;
        record.initial_average_power = data.initial_average_power;
        this.dataSource2.push(record);
      }
    }

    // Calculation VL Option 1
    if (data.duration_efforts >= 12 && data.duration_efforts <= 25) {
      if (this.is_speed_sport) {
        // Use speed to calculate power
        let test = this.chartData.test.origin;
        let d = test.du_override;
        let a = test.au_override;
        let b = test.bu_override;
        let vo2tot: number = this.ppdService.get_vo2tot(
          data.average_speed,
          this.chartData.test.origin
        );
        let mass: number = this.athlete_weight;
        let watto2eq = this.chartData.test.origin.watto2eq;
        let power: number = (vo2tot * mass) / watto2eq;
        data.average_power = Math.round(power * 100) / 100;
        data.work = data.duration_efforts * data.average_power;

        // Push data to table
        let record = this.emptyMeasuredValuesRecord();
        record.id = new Date().getTime();
        record.type = 'Power Duration';
        record.power = data.average_power;
        record.speed = data.average_speed;

        if (data.initial_average_speed != data.average_speed || data.initial_duration_efforts != data.duration_efforts) {
          let override = this.ppdService.overrideSprintData(data.average_speed, data.duration_efforts);
          record.selected_power = this.ppdService.calc_MechanicalPower_SelectionCase_Override(a, b, d, override.selected_speed, override.selected_time, watto2eq, mass);

          // REPEAT - Recalculate average power based on selected powers
          data.average_power = this.ppdService.average(record.selected_power);
          data.work = data.duration_efforts * data.average_power;
          record.power = data.average_power;
          record.duration = data.duration_efforts;
          record.work = data.work;
          record.dataForPOST.entity = 'VL';
          record.dataForPOST.option = 1;
          record.initial_average_speed = data.initial_average_speed;
          this.dataSource2.push(record);
        } else {
          this.smoothingVL1(data, record, a, b, d, watto2eq, mass);
        }
      } else if (!this.is_speed_sport) {
        // Push data to table
        let record = this.emptyMeasuredValuesRecord();
        record.id = new Date().getTime();
        record.type = 'Power Duration';
        record.power = data.average_power;
        record.selected_power = data.selected_power;
        record.duration = data.duration_efforts;
        record.work = data.work;
        record.dataForPOST.entity = 'VL';
        record.dataForPOST.option = 1;
        record.initial_average_power = data.initial_average_power;
        this.dataSource2.push(record);
      }
    }

    // Calculation VO Option 4 - Extra for new VO2 Max Calculation
    if (data.duration_efforts >= 135 && data.duration_efforts <= 195) {
      if (this.is_speed_sport) {
        // Use speed to calculate power
        let vo2tot: number = this.ppdService.get_vo2tot(data.average_speed, this.chartData.test.origin);
        let mass: number = this.athlete_weight;
        let watto2eq = this.chartData.test.origin.watto2eq;
        let power: number = (vo2tot * mass) / watto2eq;
        data.average_power = Math.round(power * 100) / 100;
        data.work = data.duration_efforts * data.average_power;

        // Need to pass values into VO2 Calculation
        data.mass = this.athlete_weight;
        let record = this.emptyMeasuredValuesRecord();
        record.id = new Date().getTime();
        record.type = 'VO<sub>2</sub> Max';
        record.hidden = false; // Hidden type
        record.power = data.average_power;
        record.speed = data.average_speed;
        record.powerTolerance = data.range;
        record.duration = data.duration_efforts;
        record.dataForPOST.entity = 'VO';
        record.dataForPOST.option = 4;
        this.dataSource2.push(record);
      } else if (!this.is_speed_sport) {
        // Need to pass values into VO2 Calculation
        data.mass = this.athlete_weight;
        let record = this.emptyMeasuredValuesRecord();
        record.id = new Date().getTime();
        record.type = 'VO<sub>2</sub> Max';
        record.hidden = false; // Hidden type
        record.power = data.average_power;
        record.powerTolerance = data.range;
        record.duration = data.duration_efforts;
        record.dataForPOST.entity = 'VO';
        record.dataForPOST.option = 4;
        this.dataSource2.push(record);
      }
    }
    this.dataSource = [...this.dataSource2];
    this.updateProgressBar();
  }
  public processManualEntryVlamax(data: any): void {
    let targetNumber = data.vla_max;
    let toleranceNumber = data.tolerance;

    let record = this.emptyMeasuredValuesRecord();
    record.id = new Date().getTime();
    record.type = 'Manual entry VLa<sub>max</sub>';
    record.additionalValue = targetNumber;
    record.additionalValueString = targetNumber + ' (mmol/l)s<sup>-1</sup>';
    record.dataForPOST.entity = 'VL';
    record.dataForPOST.option = 2;
    record.dataForPOST.target = parseFloat(targetNumber);
    record.dataForPOST.range = parseFloat(toleranceNumber) / 100;
    record.dataForPOST.upper = parseFloat(
      (targetNumber * (1 + parseFloat(toleranceNumber) / 100)).toFixed(3)
    );
    record.dataForPOST.lower = parseFloat(
      (targetNumber * (1 - parseFloat(toleranceNumber) / 100)).toFixed(3)
    );
    this.dataSource2.push(record);
    this.dataSource = [...this.dataSource2];
    this.updateProgressBar();
  }

  //VO 1 - Tolerance = Manual
  public processManualEntryVO2max(data: any): void {
    let targetNumber = data.vo2_max;
    let toleranceNumber = data.tolerance;

    let record: any = this.emptyMeasuredValuesRecord();
    record.id = new Date().getTime();
    record.type = 'Manual entry VO<sub>2max</sub>';
    record.additionalValue = targetNumber;
    record.additionalValueString = targetNumber + ' (ml/min)kg<sup>-1</sup>';
    record.dataForPOST.entity = 'VO';
    record.dataForPOST.option = 1;
    record.dataForPOST.target = parseFloat(targetNumber);
    record.dataForPOST.range = parseFloat(toleranceNumber) / 100;
    record.dataForPOST.upper = parseFloat((targetNumber * (1 + parseFloat(toleranceNumber) / 100)).toFixed(3));
    record.dataForPOST.lower = parseFloat((targetNumber * (1 - parseFloat(toleranceNumber) / 100)).toFixed(3));
    this.dataSource2.push(record);
    this.dataSource = [...this.dataSource2];

    this.updateProgressBar();
  }

  // AT 1 - Tolerance = Manual
  public processManualEntryAT(data: any): void {
    let toleranceNumber = data.tolerance;
    if (this.is_speed_sport) {
      // For Running
      let paceNumber = data.pace;
      let speedValue = null;

      let minValue = paceNumber.split(':')[0];
      let secondValue = paceNumber.split(':')[1];
      speedValue = 1000 / (parseInt(minValue) * 60 + parseInt(secondValue)); // Unit: meters per second
      speedValue = Math.round(speedValue * 100) / 100;

      let vo2tot: number = this.ppdService.get_vo2tot(speedValue, this.chartData.test.origin);
      let mass: number = this.athlete_weight;
      let watto2eq = this.chartData.test.origin.get_o2_per_watt_eq_b;
      let power: number = Math.round(((vo2tot * mass) / watto2eq) * 100) / 100;

      let record = this.emptyMeasuredValuesRecord();
      record.id = new Date().getTime();
      record.type = 'Manual entry Anaerobic Threshold';
      record.power = power;
      record.speed = speedValue;
      record.powerTolerance = toleranceNumber;
      record.dataForPOST.entity = 'AT';
      record.dataForPOST.option = 1;
      record.dataForPOST.target = power;
      record.dataForPOST.range = parseFloat(toleranceNumber) / 100;
      record.dataForPOST.upper = parseFloat((power * (1 + parseFloat(toleranceNumber) / 100)).toFixed(3));
      record.dataForPOST.lower = parseFloat((power * (1 - parseFloat(toleranceNumber) / 100)).toFixed(3));
      this.dataSource2.push(record);
      this.dataSource = [...this.dataSource2];
      this.updateProgressBar();
    } else {
      let targetNumber = data.threshold;
      let record = this.emptyMeasuredValuesRecord();
      record.id = new Date().getTime();
      record.type = 'Manual entry Anaerobic Threshold';
      record.power = targetNumber;
      record.powerTolerance = toleranceNumber;
      record.dataForPOST.entity = 'AT';
      record.dataForPOST.option = 1;
      record.dataForPOST.target = parseFloat(targetNumber);
      record.dataForPOST.range = parseFloat(toleranceNumber) / 100;
      record.dataForPOST.upper = parseFloat((targetNumber * (1 + parseFloat(toleranceNumber) / 100)).toFixed(3));
      record.dataForPOST.lower = parseFloat((targetNumber * (1 - parseFloat(toleranceNumber) / 100)).toFixed(3));
      this.dataSource2.push(record);
      this.dataSource = [...this.dataSource2];
      this.updateProgressBar();
    }
  }

  public evaluateButtonsState(data: any): void {
    if (!this.boxSelectionData) {
      this.boxSelectionData = {};
    }
    this.boxSelectionData[data.id] = data;
    this.items.forEach((item: any): void => {
      if (item.id == data.id) {
        item.booleans = this.boxSelectionData[data.id].booleans;
      }
    });
    this.sprintButtonDisabled = !this.boxSelectionData[data.id].booleans.sprint;
    this.maxEffortButtonDisabled = !this.boxSelectionData[data.id].booleans.max_effort;
    this.meanMaxPowerButtonDisabled = !this.boxSelectionData[data.id].booleans.mean_max_power;
    this.singleEffortButtonDisabled = !this.boxSelectionData[data.id].booleans.single_effort;
    // Always disable Single Effort for PPD Running
    if (this.is_speed_sport) {
      this.singleEffortButtonDisabled = true;
    }
  }

  public resetDisableButtons(event: any): void {
    this.selectedTabId = event.index;
    this.items.forEach((item: any) => {
      if (item.id == event.index) {
        this.sprintButtonDisabled = !item.booleans.sprint;
        this.maxEffortButtonDisabled = !item.booleans.max_effort;
        this.meanMaxPowerButtonDisabled = !item.booleans.mean_max_power;
        if (!this.is_speed_sport) {
          this.singleEffortButtonDisabled = !item.booleans.single_effort;
        }
      }
    });
  }

  public emptyMeasuredValuesRecord(): any {
    return {
      id: null,
      type: '',
      hidden: false,
      power: null,
      powerTolerance: null,
      duration: null,
      additionalValue: null,
      additionalValueUnit: '',
      additionalValueTolerance: null,
      dataForPOST: {
        entity: null,
        option: null,
        target: null,
        range: null,
        upper: null,
        lower: null,
      },
    };
  }

  public updateProgressBar(): void {
    this.updateProgressBaseOnNumberOfEntities.next(this.dataSource);
  }

  public prepareChartData(data: any): void {
    this.sport_type = data.test.sport.simulation_type;
    this.is_speed_sport = this.sport_type === AppConstants.SPORT_SIMULATION_TYPES[0].id;
    this.athlete_weight = data.test.origin.mass;
    this.testID = data.testID;
    data.data.map((elem: any, idx: number): void => {
      let fileName: string = 'N/A';
      if (
        data.test.origin.files &&
        data.test.origin.files.length > idx &&
        data.test.origin.files[idx] &&
        data.test.origin.files[idx].file_name
      ) {
        fileName = data.test.origin.files[idx].file_name;
      }

      let startDate: Date = this.toUTCDate(new Date(elem.table_data[0][0] * 1000));
      let endDate: Date = this.toUTCDate(
        new Date(elem.table_data[elem.table_data.length - 1][0] * 1000)
      );
      let dateString: string = startDate.getFullYear() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getDate();
      let timeString: string = startDate.toLocaleTimeString() + ' - ' + endDate.toLocaleTimeString();
      let item = {
        id: idx,
        date: dateString,
        fileName: fileName,
        athleteWeight: data.test.origin.mass,
        sport_type: this.sport_type,
        fromTill: timeString,
        lines: elem.plots[0]?.lines,
        table: elem.table_data,
        booleans: {
          mean_max_power: false,
          single_effort: false,
          sprint: false,
          max_effort: false,
        },
      };
      this.items.push(item);
    });
    // We add an empty item to enable the 'Add File' tab header
    this.items.push({id: data.data.length});
  }

  public toUTCDate(date: any): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  public removeTab(event: any, item: any): void {
    event.stopPropagation();
    this.items.splice(item.id, 1);
  }

  public writeToLocalStorage(): void {
    if (this.dataSource && Array.isArray(this.dataSource) && this.dataSource.length > 0 && this.testID) {
      localStorage.setItem(this.generateStorageKey(this.testID.toString()), JSON.stringify(this.dataSource));
    }
  }

  public hydrateFromLocalStorage(): void {
    if (this.testID == null) {
      return;
    }

    const storageKey: string = this.generateStorageKey(this.testID.toString());
    const rawStorageData: string | null = localStorage.getItem(storageKey);
    if (rawStorageData) {
      this.dataSource2 = JSON.parse(rawStorageData);
      this.dataSource = [...this.dataSource2];
      this.updateProgressBaseOnNumberOfEntities.next(this.dataSource);
    }
    localStorage.removeItem(storageKey);
  }

  private generateStorageKey(key: string): string {
    return `ppd-tabs-${key}`;
  }

  public addFile(): void {
    const test = this.chartData.test;
    const dialogRef: MatDialogRef<AddFitFileDialogComponent> = this.dialog.open(AddFitFileDialogComponent, {
      width: '750px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: false,
      data: {
        athleteId: test.origin.athlete.id,
        mass: test.origin.mass,
        testId: test.origin.id,
        testData: test.origin
      },
    });
    dialogRef.afterClosed().subscribe((res): void => {
      if (res) {
        this.writeToLocalStorage();
        window.location.reload();
      }
    });
  }

  public isGraphItem(item: any): boolean {
    return item.fileName != null;
  }

  public activateAllButtons(): void {
    this.meanMaxPowerButtonDisabled = this.cypressHelperService.activateCurrentButton();
    this.singleEffortButtonDisabled = this.cypressHelperService.activateCurrentButton();
    this.sprintButtonDisabled = this.cypressHelperService.activateCurrentButton();
    this.maxEffortButtonDisabled = this.cypressHelperService.activateCurrentButton();
  }

  public deActivateAllButtons(): void {
    this.meanMaxPowerButtonDisabled = this.cypressHelperService.deActivateCurrentButton();
    this.singleEffortButtonDisabled = this.cypressHelperService.deActivateCurrentButton();
    this.sprintButtonDisabled = this.cypressHelperService.deActivateCurrentButton();
    this.maxEffortButtonDisabled = this.cypressHelperService.deActivateCurrentButton();
  }

  public meanMaxFunction(): void {
    const arrayProperty: string[] = [
      'selectionInSeconds',
      'selectedPower',
      'selectedSpeed',
      'selectedDistance',
      'selectedTime',
      'averageSelectedPower',
      'averageSelectedSpeed',
    ];
    this.boxSelectionData = this.cypressHelperService.createObjectWithProperties(this.selectedTabId, arrayProperty, 100);
  }

  public sprintFunction(): void {
    const arrayProperty: string[] = [
      'selectionInSeconds',
      'averageSelectedPower',
      'averageSelectedSpeed',
    ];
    this.boxSelectionData = this.cypressHelperService.createObjectWithProperties(this.selectedTabId, arrayProperty, 100);
  }

  public singleEffortFunction(): void {
    const arrayProperty: string[] = [
      'selectionInSeconds',
      'averageSelectedPower',
    ];
    this.boxSelectionData = this.cypressHelperService.createObjectWithProperties(this.selectedTabId, arrayProperty, 100);
  }

  public maxEffortFunction(): void {
    const arrayProperty: string[] = [
      'selectionInSeconds',
      'averageSelectedPower',
      'averageSelectedSpeed',
    ];
    this.boxSelectionData = this.cypressHelperService.createObjectWithProperties(this.selectedTabId, arrayProperty, 100);
  }

  public resetData(): void {
    this.boxSelectionData = this.cypressHelperService.resetData();
  }
}
