<div>
  <h2 mat-dialog-title>Analytics - Owner View</h2>
  <mat-dialog-content>
    <fieldset class="personal-fieldset">
      <legend>Organization info</legend>
      <div [formGroup]="form" class="base-form-div">
        <div style="width: 50%; position:relative;">
          <div class="organization-photo-container">
            <img class="organization-photo" [src]="getFormPhotoOrganization" alt="organization-photo">
          </div>
          <div class="organization-info">
            <label class="organization-label">Name of Organization</label>
            <span class="organization-value">{{getFormNameOrganization}}</span>
          </div>
          <div class="organization-info">
            <label class="organization-label">Name of Head</label>
            <span class="organization-value">{{getFormHeadOrganization}}</span>
          </div>
          <div class="organization-info">
            <label class="organization-label">Email of Head</label>
            <span class="organization-value">{{getFormEmailOrganization}}</span>
          </div>
          <div class="organization-info">
            <label class="organization-label">Phone</label>
            <span class="organization-value">{{getFormPhoneOrganization}}</span>
          </div>
          <div class="organization-info">
            <label class="organization-label">Website</label>
            <span class="organization-value">{{getFormWebsiteOrganization}}</span>
          </div>
          <div class="organization-info">
            <label class="organization-label">Address</label>
            <span class="organization-value">{{getFormAddressOrganization}}</span>
          </div>
        </div>
        <div style="width: 50%">
          <div class="field">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Account type</mat-label>
              <mat-select
                placeholder="Select account type"
                formControlName="account_type">
                <mat-option
                  *ngFor="let item of account_types"
                  [value]="item.id"
                  >{{ item.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="field">
            <app-chips
              [label]="'Product Purchased'"
              [form]="form.get('product_purchased')"
              [options]="productOptions"
              [displayField]="'name'"
              (optionChange)="onSelectProduct($event)"
              (removeNewTag)="removeProduct($event)"
            ></app-chips>
          </div>
          <div class="field">
            <app-chips
              [label]="'Plan Purchased'"
              [form]="form.get('plan_purchased')"
              [options]="planOptions"
              [displayField]="'name'"
              (optionChange)="onSelectPlan($event)"
              (removeNewTag)="removePlan($event)"
            ></app-chips>
          </div>
          <div class="field">
            <app-chips
              [label]="'Feature'"
              [form]="form.get('features')"
              [options]="featureOptions"
              [displayField]="'name'"
              (optionChange)="onSelectFeature($event)"
            ></app-chips>
          </div>
          <div class="field">
            <mat-slide-toggle
              id="inv"
              formControlName="solvent"
              labelPosition="before"
              class="full-width">Solvent</mat-slide-toggle>
          </div>
          <div class="field">
            <mat-slide-toggle
              formControlName="enable_payment_methods"
              labelPosition="before"
              class="full-width">Enable payment methods</mat-slide-toggle>
          </div>
          <div class="field">
            <app-chips
              *ngIf="getEnablePaymentMethod"
              [label]="'Payment methods'"
              [form]="form.get('payment_methods')"
              [options]="paymentMethods"
              [displayField]="'name'"
              (optionChange)="onSelectPaymentMethod($event)"
            ></app-chips>
          </div>
          <div class="field">
            <mat-slide-toggle
              formControlName="is_multiple_exceed_invoice"
              labelPosition="before"
              class="full-width"
              (click)="showWarning()">Multiple Exceed Item</mat-slide-toggle>
          </div>
          <div class="field">
            <mat-slide-toggle
              formControlName="is_force_update_payment_method"
              labelPosition="before"
              class="full-width">Force User Update Payment Method</mat-slide-toggle>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="row row-display-text">
      <span class="org-display-text">Date Time display in UTC</span>
    </div>
    <div class="row">
      <span style="padding-left: 20px"><strong>M: </strong> Multiple</span>
      <span style="padding-left: 20px"><strong>O: </strong> Once</span>
    </div>
    <div>
      <table
        mat-table
        style="width: 100%"
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        multiTemplateDataRows>
        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef>Plan</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>Max User</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div
              *ngIf="showInputField(element)"
              style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong
                  >(Used {{ element.total_user ? element.total_user : 0 }} /
                  {{ element.max_user }})
                  {{ getMultipleOrOnce(element, 1) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_user')"
                  min="0"
                  type="number"/>
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="athlete">
          <th mat-header-cell *matHeaderCellDef>Max Athlete</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div
              *ngIf="showInputField(element)"
              style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong
                  >(Used
                  {{ element.total_athlete ? element.total_athlete : 0 }} /
                  {{ element.max_athlete }})
                  {{ getMultipleOrOnce(element, 2) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_athlete')"
                  min="0"
                  type="number"/>
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="lactate">
          <th mat-header-cell *matHeaderCellDef>Max Lactate</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div
              *ngIf="showInputField(element)"
              style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong
                  >(Used
                  {{ element.total_test_la ? element.total_test_la : 0 }} /
                  {{ element.max_test_la }})
                  {{ getMultipleOrOnce(element, 3) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_test_la')"
                  min="0"
                  type="number"/>
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="ppd">
          <th mat-header-cell *matHeaderCellDef>Max PPD</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div
              *ngIf="showInputField(element)"
              style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong
                  >(Used
                  {{ element.total_test_map ? element.total_test_map : 0 }} /
                  {{ element.max_test_map }})
                  {{ getMultipleOrOnce(element, 4) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_test_map')"
                  min="0"
                  type="number"
                />
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="manual">
          <th mat-header-cell *matHeaderCellDef>Max Manual</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div *ngIf="showInputField(element)" style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong>(Used
                  {{
                    element.total_test_manual ? element.total_test_manual : 0
                  }}
                  / {{ element.max_test_manual }})
                  {{ getMultipleOrOnce(element, 5) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_test_manual')"
                  min="0"
                  type="number"/>
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="virtual">
          <th mat-header-cell *matHeaderCellDef>Max Virtual</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div *ngIf="showInputField(element)" style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong>(Used
                  {{
                    element.total_test_virtual ? element.total_test_virtual : 0
                  }}
                  / {{ element.max_test_virtual }})
                  {{ getMultipleOrOnce(element, 6) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_test_virtual')"
                  min="0"
                  type="number"/>
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="event">
          <th mat-header-cell *matHeaderCellDef>Max Event</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div *ngIf="showInputField(element)" style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong
                  >(Used
                  {{ element.total_test_event ? element.total_test_event : 0 }}
                  / {{ element.max_test_event }})
                  {{ getMultipleOrOnce(element, 7) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_test_event')"
                  min="0"
                  type="number"/>
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef>Max Result</th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <div *ngIf="showInputField(element)" style="height: 44px; width: 70px; text-align: center">
              <small>
                <strong
                  >(Used
                  {{
                    element.total_test_result ? element.total_test_result : 0
                  }}
                  / {{ element.max_test_result }})
                  {{ getMultipleOrOnce(element, 8) }}
                </strong>
              </small>
            </div>
            <div class="field">
              <mat-form-field appearance="outline">
                <input
                  *ngIf="showInputField(element)"
                  matInput
                  [formControl]="getControl(i, 'max_test_result')"
                  min="0"
                  type="number"/>
                <input *ngIf="!showInputField(element)" matInput value="N/A" />
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="start-date">
          <th mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{ displayDateColumn(element.start_date, true) }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="next-period">
          <th mat-header-cell *matHeaderCellDef>Next Period starts</th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{ displayDateColumn(element.expiration, true) }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="cancellation">
          <th mat-header-cell *matHeaderCellDef>Cancellation possible until</th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{
                showLatestDate(
                  element.expiration,
                  element.cancel_notice_time,
                  true
                )
              }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="minimum-contract">
          <th mat-header-cell *matHeaderCellDef>Cancellation possible from</th>
          <td mat-cell *matCellDef="let element">
            <div>{{ element.minimum_contract_duration_expiry
                      | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="payment-method">
          <th mat-header-cell *matHeaderCellDef>Payment Method</th>
          <td mat-cell *matCellDef="let element">
            {{ getCurrentPaymentMethod(element.invoice.paid_method) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef>Balance in our favour</th>
          <td mat-cell *matCellDef="let element">
            {{ getCredit(element.invoice.pre_paid) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="discount-exp">
          <th mat-header-cell *matHeaderCellDef>Discount Expiration</th>
          <td mat-cell *matCellDef="let element">
            {{ displayDateColumn(element.invoice.discount_expiration, true) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="discount-amount">
          <th mat-header-cell *matHeaderCellDef>Discount Amount</th>
          <td mat-cell *matCellDef="let element">
            {{ getDiscountAmount(element) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="accumulated-tests">
          <th mat-header-cell *matHeaderCellDef>Accumulated Tests</th>
          <td mat-cell *matCellDef="let element">
            {{ getAccumulatedTests(element) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="extraColumn">
          <td mat-cell *matCellDef="let element" colspan="16">
            Bundled
            <div
              *ngFor="let item of element.purchase_alternative_items;index as i">
              <span>{{ i + 1 }}. </span>
              {{ getAlternativeItemData(item) }} -
              {{ getAlternativeItemTotal(element, item) }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="extraColumn2">
          <td mat-cell *matCellDef="let element; let i = dataIndex" colspan="16">
            Linked Items {{ getMultipleOrOnce(element, 9) }}
            <div
              *ngFor="let link_item of element.purchase_link_items; index as j">
              <small>
                <b
                  >{{ link_item.name_link_item }} (Used
                  {{ link_item.total_item ? link_item.total_item : 0 }} /
                  {{ link_item.max_item }})</b>
              </small>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="number"
                  [formControl]="
                    getLinkedItemsControls(i, 'link_item', j, 'max_item')
                  "
                  max="maxNumberValue"
                  min="0"
                  value="10"/>
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['extraColumn']; when: isExtraRow"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['extraColumn2']; when: isExtraRow2"
        ></tr>
      </table>
    </div>
    <div [formGroup]="analyticsOfPotentialForm">
      <fieldset class="personal-fieldset">
        <legend>Analytics of Potentials</legend>
        <div class="field">
          <mat-form-field style="width: 40%" appearance="outline">
            <mat-label>Potential athletes</mat-label>
            <input
              matInput
              formControlName="potential_athletes"
              type="number"
            />
            <span matSuffix>/{{
                organization.total_athlete ? organization.total_athlete : 0
              }}
              {{
                organization.potential_athlete_last_modified
                  ? "(Last modified : " +
                    displayDateColumn(
                      organization.potential_athlete_last_modified,
                      true
                    ) +
                    ")"
                  : ""
              }}</span>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field style="width: 40%" appearance="outline">
            <mat-label>Potential tests</mat-label>
            <input matInput formControlName="potential_tests" type="number" />
            <span matSuffix>
              /{{ organization.total_test ? organization.total_test : 0 }}
              {{
                organization.potential_test_last_modified
                  ? "(Last modified : " +
                    displayDateColumn(
                      organization.potential_test_last_modified,
                      true
                    ) +
                    ")"
                  : ""
              }}</span>
          </mat-form-field>
        </div>
      </fieldset>
    </div>
    <div [formGroup]="graphFilterForm">
      <div style="width: 100%; display: flex; flex-direction: row; gap: 2%">
        <div style="width: 100%">
          <div class="field">
            <app-chips
              [label]="'Select types'"
              [form]="graphFilterForm.get('filter1')"
              [options]="data.items"
              [displayField]="'NAME'"
              (optionChange)="onSelectItemLineChart($event)"
            ></app-chips>
          </div>
          <div class="field">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start date"
                  #dateRangeStart
                  (dateChange)="getOrganizationLineGraph()"
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End date"
                  #dateRangeEnd
                  (dateChange)="getOrganizationLineGraph()"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <canvas id="line-chart" #linechart></canvas>
        </div>
        <div style="width: 100%">
          <div class="field">
            <app-chips
              [label]="'Select items'"
              [form]="graphFilterForm.get('filter2')"
              [options]="data.items"
              [displayField]="'NAME'"
              (optionChange)="onSelectItemBarChart($event)"
            ></app-chips>
          </div>
          <canvas id="bar-chart" #barchart></canvas>
        </div>
      </div>
    </div>
    <div [formGroup]="statsForm">
      <div class="field">
        <mat-form-field
          style="width: 20%"
          appearance="outline"
          floatLabel="always">
          <mat-label>Select year</mat-label>
          <mat-select
            placeholder="Select year"
            formControlName="year"
            (selectionChange)="onFilterStatsTable()">
            <mat-option *ngFor="let item of yearOptions" [value]="item.id">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          style="width: 20%; margin-left: 2%"
          appearance="outline"
          floatLabel="always">
          <mat-label>Select period</mat-label>
          <mat-select
            placeholder="Select period"
            formControlName="period"
            (selectionChange)="onFilterStatsTable()"
          >
            <mat-option *ngFor="let item of periods" [value]="item.id">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <table
          style="width: 100%"
          mat-table
          [dataSource]="data.statistics"
          class="mat-elevation-z8">
          <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef>Period</th>
            <td mat-cell *matCellDef="let element">{{ element.index }}</td>
          </ng-container>
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total price</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_price }}
            </td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>Max User</th>
            <td mat-cell *matCellDef="let element">{{ element.total_user }}</td>
          </ng-container>
          <ng-container matColumnDef="athlete">
            <th mat-header-cell *matHeaderCellDef>Max Athlete</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_athlete }}
            </td>
          </ng-container>
          <ng-container matColumnDef="lactate">
            <th mat-header-cell *matHeaderCellDef>Max Lactate</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_test_la }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ppd">
            <th mat-header-cell *matHeaderCellDef>Max PPD</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_test_map }}
            </td>
          </ng-container>
          <ng-container matColumnDef="manual">
            <th mat-header-cell *matHeaderCellDef>Max Manual</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_test_manual }}
            </td>
          </ng-container>
          <ng-container matColumnDef="virtual">
            <th mat-header-cell *matHeaderCellDef>Max Virtual</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_test_virtual }}
            </td>
          </ng-container>
          <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>Max Event</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_test_event }}
            </td>
          </ng-container>
          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef>Max Result</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_test_result }}
            </td>
          </ng-container>
          <ng-container matColumnDef="link-item">
            <th mat-header-cell *matHeaderCellDef>Link Item</th>
            <td mat-cell *matCellDef="let element">
              {{ element.total_link_item }}
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsStaisticsTable"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsStaisticsTable"
          ></tr>
        </table>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button
        id="cancelButton"
        mat-raised-button
        (click)="close()"
        type="button">Cancel</button>
      <button
        id="saveButton"
        mat-raised-button
        color="primary"
        (click)="save()">Save</button>
    </div>
  </mat-dialog-actions>
</div>
