import { SelectionModel } from '@angular/cdk/collections';
import { Component, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as _ from 'lodash-es';
import * as moment from 'moment';
import { catchError, finalize } from 'rxjs/operators';
import { InvoiceDto, InvoiceResultDto } from '@core/services/invoice/invoice-dto';
import { InvoiceService } from '@core/services/invoice/invoice.service';
import { String } from 'typescript-string-operations';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto,} from '../paged-listing-component-base';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppConstants } from "@core/constants";

interface TaxRate {
  country: number,
  rates: {
    from: string,
    rate: number
  }[]
}

@Component({
  template: '',
})
export class InvoiceBaseComponent extends PagedListingBaseComponent<InvoiceDto> {
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: Array<InvoiceDto> = [];
  public selection: SelectionModel<InvoiceDto> = new SelectionModel<InvoiceDto>(true, []);
  public displayedColumns: string[] = [
    'select',
    'id',
    'invoice_date',
    'organization__id',
    'organization__name',
    'invoice_type',
    'name',
    'amount',
    'currency__name',
    'paid_method',
    'status',
    'reason',
    'update_status',
    'action',
  ];
  private BASE_RATE_FROM_DATE = "1970-01-01";
  private taxRates: TaxRate[] = [
    {
      country: 170,
      rates: [
        {
          from: "2024-01-01",
          rate: 8.1
        },
        {
          from: this.BASE_RATE_FROM_DATE,
          rate: 7.7
        }
      ]
    }
  ];
  public searchText = '';
  public formFilter!: UntypedFormGroup;
  public statusOptions!: Array<any>;
  public typeOptions!: Array<any>;
  public paymentMethodOptions!: Array<any>;
  public statusChangeOptions = [
    { value: 1, name: 'Pending' },
    {
      value: 2,
      name: 'Paid',
    },
  ];
  public isSelectedAll: boolean = false;
  public isHaveSelected: boolean = false;
  public listSelected: InvoiceDto[] = [];
  public listExcludes: number[] = [];

  constructor(
    public readonly invoiceService: InvoiceService,
    private readonly fb: UntypedFormBuilder,
    public readonly snackBar: MatSnackBar,
    public readonly dialog: MatDialog,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.initFormFilter();
    this.initOptionFilter();
    super.onInitPage();
  }

  private initFormFilter(): void {
    this.formFilter = this.fb.group({
      start: new UntypedFormControl(''),
      end: new UntypedFormControl(''),
      status: new UntypedFormControl(0),
      type: new UntypedFormControl(0),
      paymentMethod: new UntypedFormControl(''),
      filterLocalTime: new UntypedFormControl(false),
      amountZero: new UntypedFormControl(false),
    });
  }

  private initOptionFilter(): void {
    this.statusOptions = [
      {
        value: 0,
        name: 'ALL',
      },
      {
        value: this.constant.INVOICE_STATUS.PENDING,
        name: 'Pending',
      },
      {
        value: this.constant.INVOICE_STATUS.PAID,
        name: 'Paid',
      },
      {
        value: this.constant.INVOICE_STATUS.UP_DOWN,
        name: 'Cancelled',
      },
      {
        value: this.constant.INVOICE_STATUS.FAILED,
        name: 'Failed',
      },
      {
        value: this.constant.INVOICE_STATUS.PROCESSING,
        name: 'Processing',
      },
    ];
    this.typeOptions = [
      {
        value: 0,
        name: 'ALL',
      },
      {
        value: this.constant.INVOICE_TYPE.PLAN,
        name: 'Plan',
      },
      {
        value: this.constant.INVOICE_TYPE.PRODUCT,
        name: 'Product',
      },
      {
        value: this.constant.INVOICE_TYPE.COURSE,
        name: 'Course',
      },
      {
        value: this.constant.INVOICE_TYPE.UP_DOWN,
        name: 'Up/downgrade',
      },
    ];
    this.paymentMethodOptions = [
      {
        value: '',
        name: 'ALL',
      },
      {
        value: this.constant.PAYMENT_METHOD_TYPE.CREDIT_CARD,
        name: 'Credit Card',
      },
      {
        value: this.constant.PAYMENT_METHOD_TYPE.SEPA_DEBIT,
        name: 'SEPA Debit',
      },
      {
        value: this.constant.PAYMENT_METHOD_TYPE.ON_ACCOUNT,
        name: 'Pay By Invoice',
      },
    ];
  }

  protected list(
    request: PagedRequestDto,
    pageNumber: number,
    finishedCallback: Function
  ): void {
    request.pageIndex = pageNumber;
    const filterUrl: string = this.getQueryString();
    this.invoiceService
      .list(request, filterUrl)
      .pipe(
        finalize(() => finishedCallback),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res: InvoiceResultDto): void => {
        this.dataSource = res.items;
        this.totalItems = res.totalCount;
        this.dataSource = this.dataSource.map((item: InvoiceDto) => {
          return {
            ...item,
            statusName: this.getStatusName(item.status),
            invoiceTypeName: this.getInvoiceTypeName(item.invoice_type),
            statusDisplay:
              item.status === this.constant.INVOICE_STATUS.PAID ? 2 : 1,
            reasonDisplay: item?.note?.replace(/_/g, ' '),
          };
        });
        this.handleSelectedTable();
      });
  }

  private getStatusName(status: number | undefined): string {
    if (status) {
      switch (status) {
        case this.constant.INVOICE_STATUS.PENDING:
          return 'Pending';
        case this.constant.INVOICE_STATUS.PAID:
          return 'Paid';
        case this.constant.INVOICE_STATUS.UP_DOWN:
          return 'Cancelled';
        case this.constant.INVOICE_STATUS.FAILED:
          return 'Failed';
        case this.constant.INVOICE_STATUS.PROCESSING:
          return 'Processing';
        default:
          return 'Pending';
      }
    } else {
      return '';
    }
  }

  private getInvoiceTypeName(invoiceType: number | undefined): string {
    if (invoiceType) {
      switch (invoiceType) {
        case this.constant.INVOICE_TYPE.PLAN:
          return 'Plan';
        case this.constant.INVOICE_TYPE.PRODUCT:
          return 'Product';
        case this.constant.INVOICE_TYPE.COURSE:
          return 'Course';
        case this.constant.INVOICE_TYPE.UP_DOWN:
          return 'Up/downgrade';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  protected getPagedRequestDto(): PagedAndSortedRequestDto {
    return this.request;
  }

  private getQueryString(): string {
    const {
      start,
      end,
      status,
      type,
      paymentMethod,
      filterLocalTime,
      amountZero,
    } = this.formFilter.value;
    const offset: number = moment().utcOffset();
    let query: string = '';
    if (status !== 0) {
      query = query + '&status=' + status;
    }
    if (type !== 0) {
      query = query + '&invoice_type=' + type;
    }
    if (paymentMethod) {
      query = query + '&paid_method=' + paymentMethod;
    }
    if (start) {
      if (filterLocalTime) {
        query =
          query +
          '&from=' +
          moment(start, this.constant.DATE_FORMAT.APP_FULL_DATE)
            .startOf('day')
            .subtract(offset, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');
      } else {
        query =
          query +
          '&from=' +
          moment(start, this.constant.DATE_FORMAT.APP_FULL_DATE).format(
            'YYYY-MM-DD'
          );
      }
    }
    if (end) {
      if (filterLocalTime) {
        query =
          query +
          '&to=' +
          moment(end, this.constant.DATE_FORMAT.APP_FULL_DATE)
            .endOf('day')
            .subtract(offset, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss');
      } else {
        query =
          query +
          '&to=' +
          moment(end, this.constant.DATE_FORMAT.APP_FULL_DATE).format(
            'YYYY-MM-DD 23:59:59'
          );
      }
    }
    if (amountZero) {
      query = query + '&is_show_zero_invoice=' + amountZero;
    }
    return query;
  }

  public downloadAll(): void {
    const listSelectedInvoice: InvoiceDto[] = this.selection.selected;

    if (
      listSelectedInvoice.length ||
      this.isSelectedAll ||
      this.isHaveSelected
    ) {
      const data = {
        except_ids: this.listExcludes,
        ids: listSelectedInvoice.map((item: InvoiceDto) => item.id),
        is_download_all: this.isSelectedAll || this.isHaveSelected,
      };
      const param: string = this.getQueryString();
      const filterURl: string = param ? '&' + param.replace('&', '') : '';
      this.invoiceService
        .downloadSelectedInvoice(data, this.request, filterURl)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError((error) => {
            this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
            throw error;
          })
        )
        .subscribe((res): void => {
          const link: HTMLAnchorElement = document.createElement('a');
          link.setAttribute(
            'href',
            this.constant.API_HOST +
              this.constant.API.INVOICES.BULK_DOWNLOAD +
              '?file_name=' +
              res?.file_name
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
          (error): void => {
          this.snackBar.open(
            error,
            'OK',
            this.constant.TOAST_CONFIG.ERROR
          );
          },
          () => {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          });
    } else {
      this.snackBar.open(
        'Please select at least one invoice',
        'OK',
        this.constant.TOAST_CONFIG.ERROR
      );
    }
  }

  public download(invoiceId: number): void {
    this.invoiceService
      .downloadPdf(invoiceId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        })
      )
      .subscribe(() => {
        this.triggerDownload(invoiceId);
      });
  }

  private triggerDownload(id: number) {
    const link: HTMLAnchorElement = document.createElement('a');
    link.setAttribute(
      'href',
      this.constant.API_HOST +
        String.Format(this.constant.API.INVOICES.DOWNLOAD, id)
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  private handleSelectedTable(): void {
    this.listSelected = _.uniqBy(
      [...this.listSelected, ...this.selection.selected],
      (item) => item.id
    );
    if (this.isSelectedAll) {
      this.selectAll();
    } else {
      this.selection.clear();
      this.selection.select(
        ...this.dataSource.filter(
          (item) => this.listSelected.findIndex((i) => item.id === i.id) >= 0
        )
      );
    }
  }

  public masterToggle(): void {
    this.isSelectedAll = !this.isSelectedAll;
    if (this.isSelectedAll) {
      this.selectAll();
    } else {
      this.selection.clear();
    }
  }

  public itemToggle(item: InvoiceDto): void {
    this.selection.toggle(item);
    if (this.selection.isSelected(item)) {
      this.listExcludes = this.listExcludes.filter((i: number) : boolean=> i !== item.id);
    } else {
      this.listExcludes.push(item.id);
    }
    if (this.isSelectedAll && !this.selection.isSelected(item)) {
      this.isHaveSelected = true;
    }
    this.checkSelectedData();
  }

  public getInvoiceAmount(invoice: InvoiceDto): string {
    const invoiceAmount: number | undefined = invoice?.amount;
    const invoiceDate: string | Date | undefined = invoice?.invoice_date;

    if (invoiceAmount == null) {
      return this.formatPrice(0);
    }

    const invoiceCountry: number | undefined = invoice?.organization?.address_country_id;
    if (invoiceCountry == null) {
      return this.formatPrice(invoiceAmount);
    }

    const taxRate: TaxRate | undefined = this.taxRates.find(rate => rate.country === invoiceCountry);
    if (taxRate == null) {
      // This applies to every country that isn't ID 170 currently
      return this.formatPrice(invoiceAmount);
    }

    taxRate.rates.sort((first, second) => second.from.localeCompare(first.from));
    const baseRate = taxRate.rates.find(rate => rate.from === this.BASE_RATE_FROM_DATE) ?? {
      from: this.BASE_RATE_FROM_DATE,
      rate: 0
    };

    if (invoiceDate == null) {
      // Should never happen, but we cover the case anyway
      return this.formatPrice(invoiceAmount * (100 + baseRate.rate) / 100);
    }

    for (const rate of taxRate.rates) {
      if (invoiceDate >= rate.from) {
        return this.formatPrice(invoiceAmount * (100 + rate.rate) / 100);
      }
    }

    // Unreachable code - but added for typescript's benefit
    return this.formatPrice(invoiceAmount);
  }

  private formatPrice(price: number): string {
    return price.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 0 });
  }

  private selectAll(): void {
    this.selection.select(
      ...this.dataSource.filter((item) => item.download_link)
    );
    this.selection.deselect(
      ...this.dataSource.filter((item) => !item.download_link)
    );
  }

  private checkSelectedData(): void {
    const countSelected: number = this.selection.selected.length;
    const countDataHaveDownload: number = _.cloneDeepWith(this.dataSource).filter(
      (i: InvoiceDto) => i.download_link
    )?.length;

    if (countSelected > 0) {
      this.isSelectedAll = countSelected === countDataHaveDownload;
    } else {
      this.isSelectedAll = false;
      this.isHaveSelected = false;
    }
  }
}
