import { Injectable } from "@angular/core";

@Injectable()
export class CypressHelperService {
  public activateCurrentButton(): boolean {
    return false;
  }

  public deActivateCurrentButton(): boolean {
    return true;
  }

  public createObjectWithProperties(initialProperty: number, arrayProperty: string[], defaultValue: number): any {
    const ref = Object.assign({});
    arrayProperty.forEach((item: string) => {
      Object.defineProperty(ref, `${item}`, {
        value: defaultValue,
        writable: true,
      });
    });
    const newObject = Object.assign({});
    Object.defineProperty(newObject, `${initialProperty}`, {
      value: ref,
      writable: true,
    });

    return newObject;
  }

  public resetData(): Object {
    return Object.assign({});
  }
}
