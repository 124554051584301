<div class="maximize">
<!--  <span class="maximize__header u-font-weight-bold">Maximize</span>-->
  <form [formGroup]="maximizeForm" class="maximize__form">

    <mat-form-field class="maximize__select" appearance="fill">
      <mat-label>Maximize</mat-label>
      <mat-select formControlName="maximize">
        <mat-option *ngFor="let item of dataArray; let i = index" [value]="item.id" (click)="setValue(item)" [disabled]="item.isDisable">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="selectedValueItem">
      <div class="u-display-inline-block" *ngIf="selectedValue === selectedValueItem.name">
        <ng-container *ngIf="selectedValueItem.isShowCarbohydrateInput && isPowerSport">
          <ng-container *ngIf="selectedValueItem.isShow">
            <input class="maximize-form__input" matInput [placeholder]="selectedValueItem.placeholder" formControlName="carbohydrate">
            <span matPrefix>{{getCarbohydrateCurrentValue()}}</span>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowCarbohydrateInput && !isPowerSport">
          <ng-container *ngIf="selectedValueItem.isShow">
            <input class="maximize-form__input" matInput [placeholder]="selectedValueItem.placeholder" formControlName="carbohydrate">
            <span matPrefix>{{getCarbohydrateCurrentValue()}}</span>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowOverrideWattInput && isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input id="power-duration" class="maximize-form__input" matInput [placeholder]="selectedValueItem.placeholder" formControlName="initialValue">
          </mat-form-field>
          <span matPrefix>{{getDynamicPowerInitialValue()}}</span>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowOverrideWattInput && isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input id="power-duration2" class="maximize-form__input" matInput [placeholder]="selectedValueItem.placeholderOverrideWat" formControlName="overrideValue">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowOverrideWattInput && !isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="maximize-form__input" matInput [placeholder]="selectedValueItem.placeholder" formControlName="initialValue">
          </mat-form-field>
          <span matPrefix>{{getDynamicPowerInitialValue()}}</span>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowOverrideWattInput && !isPowerSport && isSpeedRef">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="maximize-form__input" matInput [placeholder]="selectedValueItem.placeholderOverrideWat" formControlName="overrideValue">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowOverrideWattInput && !isPowerSport && isPaceRef">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input id="override-pace-input" class="maximize-form__input" (click)="addMaskToOverridePaceInput()" matInput [placeholder]="selectedValueItem.placeholderOverrideWat" type="text" formControlName="overrideValue">
          </mat-form-field>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isShowToggle">
      <div class="maximize--margin-right-10">
        <span class="maximize-form__toggle-button">Use relative (Watt/kg) values</span>
        <mat-slide-toggle class="maximize--padding-right-left-10" formControlName="maximizeToggle"></mat-slide-toggle>
      </div>
    </ng-container>
  </form>
</div>
