<form [formGroup]="orgForm" (ngSubmit)="save()" class="page-container">
  <div class="top-toolbar">
    <div class="sub-title">SETTINGS</div>
    <div class="title">Account Info</div>
  </div>
  <div class="sport-dialog__section account-info">
    <mat-dialog-content>
      <div>
        <hr>
        <div class="full-width photo">
          <img class="account-info__logo u-margin-bottom-20" *ngIf="orgForm?.value?.photo" [src]="temporaryPhoto"/>
          <div class="o-flex o-flex--justify-center o-flex--align-items-center account-info__logo account-info__default-logo-background u-margin-bottom-20" *ngIf="!orgForm?.value?.photo">
            <span class="u-font-weight-500 u-font-size-20 line-height-24 u-text-align-center">YOUR LOGO HERE</span>
          </div>
          <div style="display: flex; flex-direction: row">
            <mat-label style="margin-right: 1%">Logo </mat-label>
            <input
              type="file"
              id="photo"
              name="organization"
              accept="image/png, image/jpeg"
              (change)="changeImage($event)"/>
          </div>
        </div>
        <br />
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name" (keypress)="onChangeName($event)"/>
            <mat-error *ngIf="orgForm.controls?.name?.errors && orgForm.controls?.name?.touched">
              <div [innerHTML]="orgForm.controls.name.errors | errorFormFormat : { fieldName: 'Name' }"></div>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
            <mat-label>Street</mat-label>
            <input matInput placeholder="Street Name" formControlName="address_street_name" (keypress)="onChangeStreet($event)"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Number</mat-label>
            <input matInput placeholder="Street Number" formControlName="address_street_number" (keypress)="onChangeNumber($event)"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>ZIP</mat-label>
            <input matInput placeholder="Zip code" formControlName="address_zip" (keypress)="onChangeZip($event)"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>City</mat-label>
            <input matInput placeholder="City name" formControlName="address_city" (keypress)="onChangeCity($event)"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>State</mat-label>
            <input matInput placeholder="State name" formControlName="address_state" (keypress)="onChangeState($event)"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>VAT</mat-label>
            <input matInput placeholder="VAT" formControlName="vat_number" (keypress)="onChangeVat($event)"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Country</mat-label>
            <mat-select placeholder="Select a country" formControlName="country" (selectionChange)="onChangeCountry()">
              <mat-option *ngFor="let item of countries" [value]="item.id">{{item.name }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="orgForm.controls?.country?.errors && orgForm.controls?.country?.touched">
              <div [innerHTML]="orgForm.controls.country.errors | errorFormFormat : { fieldName: 'Country' }"></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-slide-toggle id="inv" (change)="onChangeDefault($event)" formControlName="is_use_invoice_address" labelPosition="before" class="account-info__toggle-text">Use same address for reports/Address of office</mat-slide-toggle>
        </div>
        <br />
      </div>
      <br />
      <div>
        <span class="u-font-size-14 u-font-weight-700 line-height-normal">Address of Office / Facility to show on PDF reports</span>
        <hr>
        <br>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" [readonly]="isChecked" formControlName="invoice_org_name"/>
            <mat-error
              *ngIf="orgForm.controls?.invoice_org_name?.errors && orgForm.controls?.invoice_org_name?.touched">
              <div [innerHTML]="orgForm.controls.invoice_org_name.errors | errorFormFormat : { fieldName: 'Name' }"></div>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Street</mat-label>
            <input matInput placeholder="Street Name" [readonly]="isChecked" formControlName="invoice_address_street_name"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Number</mat-label>
            <input matInput placeholder="Street Number" [readonly]="isChecked" formControlName="invoice_address_street_number"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>ZIP</mat-label>
            <input matInput placeholder="Zip code" [readonly]="isChecked" formControlName="invoice_address_zip"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>City</mat-label>
            <input matInput placeholder="City name" [readonly]="isChecked" formControlName="invoice_address_city"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>State</mat-label>
            <input matInput placeholder="State name" [readonly]="isChecked" formControlName="invoice_address_state"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>VAT</mat-label>
            <input matInput placeholder="VAT" [readonly]="isChecked" formControlName="invoice_vat_number"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Country</mat-label>
            <mat-select [disabled]="isChecked" placeholder="Select a country" formControlName="invoice_country">
              <mat-option *ngFor="let item of invoice_countries" [value]="item.id">{{ item.name }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="orgForm.controls?.invoice_country?.errors && orgForm.controls?.invoice_country?.touched">
              <div [innerHTML]="orgForm.controls.invoice_country.errors | errorFormFormat : { fieldName: 'Country' }"></div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <br>
      <div>
        <span class="u-font-size-14 u-font-weight-700 line-height-normal">Contact</span>
        <hr>
        <br>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Phone</mat-label>
            <input matInput placeholder="+(00) 123-456789" formControlName="phone"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Phone 2</mat-label>
            <input matInput placeholder="+(00) 123-456789" formControlName="phone2"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>FAX</mat-label>
            <input matInput placeholder="+(00) 123-456789" formControlName="fax"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Email</mat-label>
            <input matInput placeholder="some@email.com" formControlName="email"/>
            <mat-error
              *ngIf="orgForm.controls?.email?.errors && orgForm.controls?.email?.touched">
              <div [innerHTML]="orgForm.controls.email.errors | errorFormFormat : { fieldName: 'Email' }"></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Email 2</mat-label>
            <input matInput placeholder="some@email.com" formControlName="email2"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Website</mat-label>
            <input matInput placeholder="www.iatac.com" formControlName="website"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Facebook</mat-label>
            <input matInput placeholder="" formControlName="facebook" />
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Instagram</mat-label>
            <input matInput placeholder="" formControlName="instagram" />
          </mat-form-field>
        </div>
      </div>
      <br>
      <div>
        <span class="u-font-size-14 u-font-weight-700 line-height-normal">Settings</span>
        <hr>
        <br>
        <div class="field">
          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
            <mat-label>Head of organization</mat-label>
            <mat-select formControlName="head">
              <mat-option *ngFor="let item of heads" [value]="item.id">{{item.username }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <br>
    <mat-dialog-actions align="center">
      <div class="full-width o-flex o-flex--justify-space-between">
        <button class="width-49-percent account-info--button-border" mat-button (click)="back()" type="button">Cancel</button>
        <button class="width-49-percent" mat-raised-button color="primary" type="submit">
          Save
          <span class="account-info__text-position">changes will apply upon next login</span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
