import { Injectable } from '@angular/core';

@Injectable()
export class MetricService {
  private SPEED_DROPDOWN_CONFIG_METRIC_DICTIONARY: any = {
    recovery_index: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Absolute',
          id: 'data_point-1',
        },
        {
          name: 'Relative',
          id: 'data_point-2',
        },
      ],
    },
    accumulation_index: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Absolute',
          id: 'data_point-1',
        },
        {
          name: 'Relative',
          id: 'data_point-2',
        },
      ],
    },
    w_prime: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [kJ]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [kJ/kg]',
          id: 'data_point-2',
        },
      ],
    },
    max_lactate: {
      rowNumber: 0,
      optionList: [
        {
          name: 'mmol/l',
          id: 'data_point-1',
        },
      ],
    },
    max_hr: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Maximum [bpm]',
          id: 'data_point-1',
        },
        {
          name: 'PWC150 [Watt]',
          id: 'data_point-2',
        },
      ],
    },
    economy: {
      rowNumber: 1,
      optionList: [
        {
          name: 'J/m',
          id: 'data_point-1',
        },
      ],
    },
    lt1: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [ml/min]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [ml/min/kg]',
          id: 'data_point-2',
        },
      ],
    },
    lt_1: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [ml/min]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [ml/min/kg]',
          id: 'data_point-2',
        },
      ],
    },
    vo2max: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [ml/min]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [ml/min/kg]',
          id: 'data_point-2',
        },
      ],
    },
    vlamax: {
      rowNumber: 0,
      optionList: [
        {
          name: 'mmol/l/s',
          id: 'data_point-1',
        },
      ],
    },
    anaerobic_threshold: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Unknown data',
          id: 'data_point-1',
        },
      ],
    },
    fat_max: {
      rowNumber: 0,
      optionList: [
        {
          name: '%',
          id: 'data_point-1',
        },
      ],
    },
    carb_max: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Primary reference system',
          id: 'data_point-1',
        },
        {
          name: 'Secondary reference system',
          id: 'data_point-2',
        },
      ],
    },
    available_glycogen: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [ml/min]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [ml/min/kg]',
          id: 'data_point-2',
        },
      ],
    },
    vo2_tau: {
      rowNumber: 1,
      optionList: [
        {
          name: 'Tau [s]',
          id: 'data_point-1',
        },
      ],
    },
    avg_power: {
      rowNumber: 0,
      optionList: [
        {
          name: 'primary reference system',
          id: 'data_point-1',
        },
        {
          name: 'secondary reference system',
          id: 'data_point-2',
        },
      ],
    },
    max_power: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Primary reference system',
          id: 'data_point-1',
        },
        {
          name: 'Secondary reference system',
          id: 'data_point-2',
        },
      ],
    },
    MLSS: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Primary reference system',
          id: 'data_point-1',
        },
        {
          name: 'Secondary reference system',
          id: 'data_point-2',
        },
      ],
    },
    body_fat: {
      rowNumber: 0,
      optionList: [
        {
          name: '%',
          id: 'data_point-1',
        },
      ],
    },
    MMSS: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Primary reference system',
          id: 'data_point-1',
        },
        {
          name: 'Secondary reference system',
          id: 'data_point-2',
        },
      ],
    },
  };

  private POWER_DROPDOWN_CONFIG_METRIC_DICTIONARY: any = {
    recovery_index: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Absolute',
          id: 'data_point-1',
        },
        {
          name: 'Relative',
          id: 'data_point-2',
        },
      ],
    },
    accumulation_index: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Absolute',
          id: 'data_point-1',
        },
        {
          name: 'Relative',
          id: 'data_point-2',
        },
      ],
    },
    w_prime: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [kJ]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [kJ/kg]',
          id: 'data_point-2',
        },
      ],
    },
    max_lactate: {
      rowNumber: 0,
      optionList: [
        {
          name: 'mmol/l',
          id: 'data_point-1',
        },
      ],
    },
    max_hr: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Maximum [bpm]',
          id: 'data_point-1',
        },
        {
          name: 'PWC150 [Watt]',
          id: 'data_point-2',
        },
      ],
    },
    economy: {
      rowNumber: 0,
      optionList: [],
    },
    lt1: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [Watt]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [Watt/kg]',
          id: 'data_point-2',
        },
      ],
    },
    lt_1: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [Watt]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [Watt/kg]',
          id: 'data_point-2',
        },
      ],
    },
    vo2max: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [ml/min]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [ml/min/kg]',
          id: 'data_point-2',
        },
      ],
    },
    vlamax: {
      rowNumber: 0,
      optionList: [
        {
          name: 'mmol/l/s',
          id: 'data_point-1',
        },
      ],
    },
    anaerobic_threshold: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Unknown data',
          id: 'data_point-1',
        },
      ],
    },
    fat_max: {
      rowNumber: 3,
      optionList: [
        {
          name: 'MFO absolute [kcal/h]',
          id: 'data_point-1',
        },
        {
          name: 'MFO relative [kcal/h/kg]',
          id: 'data_point-2',
        },
        {
          name: 'FatMax [Watt]',
          id: 'data_point-3',
        },
      ],
    },
    carb_max: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [Watt]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [Watt/kg]',
          id: 'data_point-2',
        },
      ],
    },
    available_glycogen: {
      rowNumber: 2,
      optionList: [
        {
          name: 'Absolute [g]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [g/kg]',
          id: 'data_point-2',
        },
      ],
    },
    vo2_tau: {
      rowNumber: 1,
      optionList: [
        {
          name: 'Tau [s]',
          id: 'data_point-1',
        },
      ],
    },
    avg_power: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Absolute [Watt]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [Watt/kg]',
          id: 'data_point-2',
        },
      ],
    },
    max_power: {
      rowNumber: 0,
      optionList: [
        {
          name: 'Absolute [Watt]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [Watt/kg]',
          id: 'data_point-2',
        },
      ],
    },
    MLSS: {
      rowNumber: 3,
      optionList: [
        {
          name: 'Absolute [Watt]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [Watt/kg]',
          id: 'data_point-2',
        },
        {
          name: '% VO2max [%]',
          id: 'data_point-3',
        },
      ],
    },
    body_fat: {
      rowNumber: 0,
      optionList: [
        {
          name: '%',
          id: 'data_point-1',
        },
      ],
    },
    MMSS: {
      rowNumber: 3,
      optionList: [
        {
          name: 'Absolute [Watt]',
          id: 'data_point-1',
        },
        {
          name: 'Relative [Watt/kg]',
          id: 'data_point-2',
        },
        {
          name: '% VO2max [%]',
          id: 'data_point-3',
        },
      ],
    },
  };

  public getSpeedDropdownConfigByMetric(metric: any): any {
    return this.SPEED_DROPDOWN_CONFIG_METRIC_DICTIONARY[metric.slug];
  }

  public getPowerDropdownConfigByMetric(metric: any): any {
    return this.POWER_DROPDOWN_CONFIG_METRIC_DICTIONARY[metric.slug];
  }
}
