import { Component, OnDestroy, OnInit } from '@angular/core';
import { MetabolicProfileFacade, Tab } from './metabolic-profile.facade';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ChartConstants } from '../../apc/apc/data/chart-modules';
import { ChartMappingData } from "@modules/apc/apc/data/chart-mapping-data";
import { ChartService } from '@shared/services/chart.service';
import { TestDto } from '@core/services/test/test-dto';
import { AppConstants } from '@core/constants';
import * as _ from "lodash-es";
import { TrainingZonesComponentService } from '@core/services/apc/training-zones-component.service';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-humango-metabolic-profile',
  templateUrl: './metabolic-profile.component.html',
  styleUrls: ['./metabolic-profile.component.scss'],
  providers: [MetabolicProfileFacade]
})
export class MetabolicProfileComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  public appConstant = AppConstants;
  public loading$: Observable<boolean>;
  public tabs$: Observable<Tab[]>;
  public metabolicResponse: any;
  public testData: TestDto;
  public bodyCompositionChart: any = null;
  public gaugeChartData: any = null;
  public metabolicFingerprintChart: any = null;
  public performanceDevelopmentChart: any = null;
  public metabolicProfileData: any = null;
  public bodyComposition: any;
  public measuredValues: any[] = [];
  public weightedValues: any = {};
  public isSpeed: boolean = false;
  public isPPD: boolean = false;
  public rawTestDataTable: any = null;
  public calculatedDataTable: any = null;
  public primary_unit: string = '';
  public trainingZoneJsonData: any = null;
  public trainingZoneTableData: any[] = [];
  public trainingZoneTableHtml: string;
  public MAX_LOW_UP_TARGET: number = 6;

  constructor(
    private readonly chartService: ChartService,
    private facade: MetabolicProfileFacade,
    private readonly activatedRoute: ActivatedRoute,
    private readonly trainingZonesService: TrainingZonesComponentService
  ) { }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngOnInit(): void {
    this.loading$ = this.facade.loading$;
    const apiCallId: string | null = this.getApiCallId();
    if (apiCallId == null) {
      return;
    }

    this.facade.fetchMetabolicProfile(apiCallId);
    this.facade.tabs$
      .pipe(takeUntil(this._destroy$))
      .subscribe((tabs:any) => {
        if (tabs != null && tabs.length > 0) {
          this.metabolicResponse = tabs;
          this.testData = (tabs[7] as Record<string, any>)?.test_data;
          this.bodyComposition = (tabs[0] as Record<string, any>)?.table_data;
          this.renderProfile();
        }
      });
  }

  private getApiCallId(): string | null {
    return this.activatedRoute.snapshot.queryParamMap.get('api_call_id');
  }

  private renderProfile(): void {
    const chartData = {
      id: this.testData.id,
      test_type: this.testData.test_type,
      metabolicResponse: this.metabolicResponse,
      test: this.testData,
      athlete: this.testData.athlete,
      test_settings: undefined,
      is_chart_changed: false
    };
    setTimeout((): void => {
      this._renderBodyCompositionChart(chartData);
      this._renderMetabolicFingerprintChart(chartData);
      this._renderPerformanceDevelopmentChart(chartData);
      this._renderMetabolicCapacitiesChart(chartData);
      this._renderMetabolicProfile(chartData);
      this._renderTestDataChart(chartData);
      this._renderTables(chartData);
      this._renderTrainingZone();
    }, 300);
  }

  private _renderBodyCompositionChart(chartData: any): void {
    try {
      const request = {
        data: chartData.metabolicResponse[0],
        testId: chartData.id,
        elementId: ChartConstants.BODY_COMPOSITION.ELEMENT_ID,
        type: ChartConstants.BODY_COMPOSITION.ID,
        test_type: chartData.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
        isPdfPreview: true
      };

      this.bodyCompositionChart = this.chartService.ChartBokeh(request, true);
      this._renderHumanBody(chartData);

    } catch (error) {
      console.log('renderBodyCompositionChart failed.', error);
    }
  }

  private _renderHumanBody(chartData: any): void {
    try {
      const tableData = chartData.metabolicResponse[0].table_data;
      const humanAttributes = [
        {
          name: 'non H2O',
          value: tableData['body_attributes']['non_h2o_space'],
        },
        {
          name: 'H2O',
          value: tableData['body_attributes']['h2o_space']
        },
        {
          name: 'passive lactate',
          value: tableData['body_attributes']['passive_lactate_space'],
        },
        {
          name: 'active muscle',
          value: tableData['body_attributes']['active_muscle_space'],
        },
      ];

      const canvasEle: HTMLElement | null = document.getElementById('humanBodyCtx');
      this.chartService.drawHumanBody(canvasEle, humanAttributes, 'LIGHT', 500, 600);

    } catch (e) {
      console.log('Cannot render human body shape chart.', e);
    }
  }

  private _renderMetabolicFingerprintChart(chartData: any): void {
    try {
      const request = {
        data: chartData.metabolicResponse[3],
        testId: chartData.id,
        elementId: ChartConstants.METABOLIC_FINGERPRINT.ELEMENT_ID,
        type: ChartConstants.METABOLIC_FINGERPRINT.ID,
        test_type: chartData.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
        isPdfPreview: true
      };

      this.metabolicFingerprintChart = this.chartService.ChartBokeh(request, true);
    } catch (error) {
      console.log('renderMetabolicFinderprintChart failed.', error);
    }
  }

  private _renderPerformanceDevelopmentChart(chartData: any): void {
    try {
      const request = {
        data: chartData.metabolicResponse[4],
        testId: chartData.id,
        elementId: ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID,
        type: ChartConstants.PERFORMANCE_DEVELOPMENT.ID,
        test_type: chartData.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
        isPdfPreview: true
      };

      this.performanceDevelopmentChart = this.chartService.ChartBokeh(request, true);
    } catch (error) {
      console.log('renderPerformanceDevelopmentChart failed.', error);
    }
  }

  private _renderMetabolicCapacitiesChart(chartData: any): void {
    try {
      const plots = chartData.metabolicResponse[1].plots;
      const gaugeChartItems: any = [];
      plots.forEach((currentPlot: any): void => {
        try {
          const gaugeChartItem = {
            min: 0,
            max: 100,
            current: currentPlot.circles[0].source.percentage[0],
            title: currentPlot.title_text,
            value: null,
            unit: null,
            prefix: null,
            value_alternative_1: null,
            unit_alternative_1: null,
            prefix_alternative_1: null,
            value_alternative_2: null,
            unit_alternative_2: null,
            prefix_alternative_2: null,
            is_heart_rate:
              currentPlot.title_text.toLowerCase().indexOf('heart rate') > -1,
          };

          // Parse prefix / value / unit
          const values = (currentPlot.circles[0].source.names[0] || '').split(
            ' ',
          );
          let isValueContainsPrefix =
            currentPlot.circles[0].source.names[0].indexOf('elative') > -1 ||
            currentPlot.circles[0].source.names[0].indexOf('otal') > -1;
          const param = {
            prefixField: 'prefix',
            valueField: 'value',
            unitField: 'unit',
          };
          this._parsePrefixValueUnit(gaugeChartItem, values, isValueContainsPrefix, param,);

          if (currentPlot.circles.length > 1) {
            // Parse prefix / value / unit
            const values_alt = (
              currentPlot.circles[1].source.names[0] || ''
            ).split(' ');
            isValueContainsPrefix =
              currentPlot.circles[1].source.names[0].indexOf('elative') > -1 ||
              currentPlot.circles[0].source.names[0].indexOf('otal') > -1;
            const param_atl = {
              prefixField: 'prefix_alternative_1',
              valueField: 'value_alternative_1',
              unitField: 'unit_alternative_1',
            };
            this._parsePrefixValueUnit(gaugeChartItem, values_alt, isValueContainsPrefix, param_atl,);
          }
          if (currentPlot.circles.length > 2) {
            // Parse prefix / value / unit
            const values_alt = (currentPlot.circles[2].source.names[0] || '').split(' ');
            isValueContainsPrefix = currentPlot.circles[2].source.names[0].indexOf('elative') > -1 || currentPlot.circles[0].source.names[0].indexOf('otal') > -1;
            const param_atl = {
              prefixField: 'prefix_alternative_2',
              valueField: 'value_alternative_2',
              unitField: 'unit_alternative_2',
            };
            this._parsePrefixValueUnit(gaugeChartItem, values_alt, isValueContainsPrefix, param_atl,);
          }
          gaugeChartItems.push(gaugeChartItem);
        } catch (e1) {
          console.log(e1);
        }
      });
      this.gaugeChartData = gaugeChartItems;

      const request = {
        data: this.gaugeChartData,
      };
      setTimeout((): void => {
        this.chartService.generateMetabolicCapacitiesGaugeChart(request);
      }, 200);
    } catch (error) {
      this.gaugeChartData = [];
      console.log('renderMetabolicCapacitiesChart failed.', error);
    }
  }

  private _parsePrefixValueUnit(
    gaugeChartItem: any,
    values: any,
    isValueContainsPrefix: boolean,
    param: { prefixField: string; valueField: string; unitField: string },
  ): void {
    if (values.length >= 3 && isValueContainsPrefix) {
      gaugeChartItem[param.prefixField] = values[0];
      gaugeChartItem[param.valueField] = values[1];
      gaugeChartItem[param.unitField] = values.slice(2).join(' ');
    } else if (values.length >= 3 && !isValueContainsPrefix) {
      gaugeChartItem[param.valueField] = values[0];
      gaugeChartItem[param.unitField] = values.slice(1).join(' ');
    } else if (values.length == 2) {
      gaugeChartItem[param.valueField] = values[0];
      gaugeChartItem[param.unitField] = values[1];
    } else {
      gaugeChartItem[param.valueField] = values[0];
    }
  }

  private _renderMetabolicProfile(chartData: any): void {
    this.metabolicProfileData = {
      data: chartData.metabolicResponse[2],
      testId: chartData.id,
      elementId: ChartConstants.LOAD_CHARACTERISTICS.ELEMENT_ID,
      type: ChartConstants.LOAD_CHARACTERISTICS.ID,
      test_type: chartData.test_type,
      legend: 'legends',
      mappingData: ChartMappingData,
      test: chartData.test,
      isPdfPreview: true
    };
  }

  private _renderTestDataChart(chartData: any): void {
    const testData = chartData.metabolicResponse[6];
    let test_type = chartData.test_type;
    if (testData && (testData['cp19'] || (testData[0] && testData[0].cp19))) {
      test_type = AppConstants.TEST_TYPES.PPD;
    }

    const request = {
      data: testData,
      testId: chartData.id,
      elementId: ChartConstants.TEST_DATA.ELEMENT_ID,
      type: ChartConstants.TEST_DATA.ID,
      test_type: test_type,
      legend: 'legends',
      mappingData: ChartMappingData,
      test: chartData.test,
      isPdfPreview: true
    };

    this.chartService.ChartBokeh(request);
  }

  private _renderTables(chartData: any): void {
    this.primary_unit = this.chartService.findXAxisLabel(chartData.test.sport.primary_type);
    if (chartData?.test?.sport) {
      this.isSpeed = chartData.test.sport.simulation_type == AppConstants.SIMULATE_TYPE.SPEED;
    }

    if (chartData?.test?.is_ppd) {
      this._renderTablePPD(chartData);
    } else if (chartData.metabolicResponse[6] && chartData.metabolicResponse[6].cp19 && chartData.metabolicResponse[6].cp19.measured_values) {
      this._renderTablePPD(chartData);
    } else if (chartData.metabolicResponse[6].table_data) {
      if (chartData?.test?.use_old_raw_test_data_table) {
        this.rawTestDataTable = JSON.parse(chartData.metabolicResponse[6].table_data);
        this.rawTestDataTable.data = _.map(
          this.rawTestDataTable.data,
          this._processTableData
        );
      } else {
        this.rawTestDataTable = JSON.parse(
          chartData.metabolicResponse[6].table_data.measured_values_table
        );
        this.rawTestDataTable.data = _.map(
          this.rawTestDataTable.data,
          this._processTableData
        );

        this.calculatedDataTable = JSON.parse(
          chartData.metabolicResponse[6].table_data.calculated_values_table
        );
        this.calculatedDataTable.data = _.map(
          this.calculatedDataTable.data,
          this._processTableData
        );
      }
    }
  }

  private _processTableData(item: any, key: number) {
    if (key === 0) {
      return item;
    }

    return item;
  }

  private _renderTablePPD(chartData: any): void {
    const cp19 = chartData.metabolicResponse[6]['cp19'] || chartData.metabolicResponse[6][0].cp19;
    if (cp19) {
      this.measuredValues = cp19.measured_values;
      this.weightedValues = cp19.weighted_values;
    } else {
      console.log('Cannot render table ppd, please check detail.');
    }
  }

  private _renderTrainingZone(): void {
    if (!this.trainingZoneJsonData) {
      this.trainingZoneJsonData = this.trainingZonesService.convertZeroValueToString(
        (this.metabolicResponse[5] as Record<string, any>).json_data
      );
      if (this.trainingZoneJsonData) {
        this.trainingZoneJsonData.map((item: any) => {
          item.upper_length = this.trainingZonesService.getColspan(item.upper);
          item.lower_length = this.trainingZonesService.getColspan(item.lower);
          item.target_length = this.trainingZonesService.getColspan(item.target);
          return item;
        });
      }
    }

    if (this.trainingZoneJsonData) {
      this.trainingZoneTableData = this.trainingZonesService.convertJsonDataToTable(
        this.trainingZoneJsonData
      );
    }

    if (!this.trainingZoneTableHtml) {
      this.trainingZoneTableHtml = (this.metabolicResponse[5] as Record<string, any>).table_data;
    }
  }

  public getWidthOutputValue() {
    return this.trainingZonesService.getWidthOutputValue(this.trainingZoneJsonData);
  }

  public getMaxOutputValues(): number {
    return this.trainingZonesService.getMaxOutputValues(this.trainingZoneJsonData);
  }

  public get maxLowUpTarget() {
    return this.MAX_LOW_UP_TARGET;
  }
}
