import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { FileHandle } from '@shared/directives/drag-file/drag-file.directive';
import { ApiService } from '@core/services/api.service';
import { Observable, of } from 'rxjs';
import { FilesService } from '@core/services/files/files.service';
import { TestService } from '@core/services/test/test.service';
import { catchError, finalize } from 'rxjs/operators';
import { FitFileParserService } from '@shared/services/fit-file-parser.service';
import { AddFileData } from './add-fit-file-dialog.interface';

@Component({
  selector: 'app-add-fit-file-dialog',
  templateUrl: './add-fit-file-dialog.component.html',
  styleUrls: ['./add-fit-file-dialog.component.scss']
})
export class AddFitFileDialogComponent extends AppBaseComponent implements OnInit {
  @ViewChild('fileUpload') public fileUpload!: ElementRef;

  public form!: UntypedFormGroup;
  public listFileUpload: Array<any> = [];
  public filteredTests: any;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddFitFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddFileData,
    private snackBar: MatSnackBar,
    private readonly filesService: FilesService,
    private apiService: ApiService,
    private testService: TestService,
    private readonly fitFileParserService: FitFileParserService
  ) {
    super();
  }

  public initForm(): void {
    this.form = this.fb.group({
      files: new UntypedFormControl([])
    });
  }

  public ngOnInit(): void {
    this.initForm();
    this.getTestsForAthlete(this.data.athleteId);
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();

      return;
    }

    this.testService.updateTest(this.data.testId, this.prepareData())
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);

          throw error;
        }),
        finalize((): boolean => (this.isDataLoading = false))
      )
      .subscribe((res: any): void => {
        this.dialogRef.close(this.form.value);
      });
  }

  public prepareData() {
    const testData = this.data.testData;
    const testFiles = testData.files.map((file: any) => file.id);
    const newFiles = this.form.get("files")?.value.map((file: any) => file.value);

    return {
      ...this.data.testData,
      athlete: testData.athlete.id,
      coach: testData.coach.id,
      sport: testData.sport.id,
      files: Array.from(new Set([...testFiles, ...newFiles]))
    };
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public onSelectFile(value: any): void {
    this.form.get('files')?.setValue(value);
  }

  public changeFile(event: any): void {
    if (this.validateBeforeUploadFile() && event?.target?.files) {
      this.listFileUpload = event?.target?.files;
      this.uploadFile();
    } else {
      this.fileUpload.nativeElement.value = '';
    }
  }

  private validateBeforeUploadFile(): boolean {
    return true;
  }

  public filesDropped(event: FileHandle[]): void {
    if (this.validateBeforeUploadFile()) {
      this.listFileUpload = event.map((item: FileHandle) => item.file);
      this.uploadFile();
    }
  }

  public uploadFile(): void {
    if (!this.listFileUpload || this.listFileUpload.length === 0) {
      this.snackBar.open('Select a file to upload.', 'OK', this.constant.TOAST_CONFIG.ERROR);

      return;
    }
    if (this.listFileUpload && this.listFileUpload.length > 20) {
      this.snackBar.open('At the moment the service is not able to process more than 20 files', 'OK', this.constant.TOAST_CONFIG.ERROR);

      return;
    }

    const extensionRegex: RegExp = /(?:\.([^.]+))?$/;
    let isValidExtension: boolean = true;
    let invalidFileName: string = '';
    for (let file of this.listFileUpload) {
      const ext = extensionRegex.exec(file.name)?.[1];
      if (!ext || ext.toUpperCase() !== 'FIT') {
        invalidFileName = file.name;
        isValidExtension = false;
        break;
      }
    }
    if (!isValidExtension) {
      this.snackBar.open(invalidFileName + ' is not a valid .fit file.', 'OK', this.constant.TOAST_CONFIG.ERROR);

      return;
    }

    for (let file of this.listFileUpload) {
      const reader: FileReader = new FileReader();
      const file_name = file.name;
      const athlete_id: number = this.data.athleteId;
      const weight: number = this.data.mass;
      reader.onload = (e: any): void => {
        this.fitFileParserService.parseFileBuffer(e.target.result, file_name, athlete_id, weight, this.callBE.bind(this));
      };
      reader.readAsArrayBuffer(file);
    }
  }

  private callBE(model: any): void {
    this.filesService.create(model).subscribe(
      (res: any): void => {
        this.getTestsForAthlete(res.athlete).subscribe((): void => {
          this.isDataLoading = false;
          const obj = {
            name: res.file_name,
            value: res.id,
          };
          const array = this.form.get('files')?.value ?? [];
          array.push(obj);
          this.form.get('files')?.setValue(array);
        });
        this.snackBar.open('Item created successfully', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
        this.fileUpload.nativeElement.value = '';
        this.listFileUpload = [];
      },
      (error: any): void => {
        this.isDataLoading = false;
        this.fileUpload.nativeElement.value = '';
        this.listFileUpload = [];
        this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
      }
    );
  }

  public getTestsForAthlete(id: any): Observable<any[]> {
    this.apiService
      .get('files/select2/?ordering=-id&athlete_id=' + id)
      .subscribe((res: any): void => {
        this.filteredTests = res.map((item: any) => {
          return {
            value: item.id,
            name: item.file_name,
          };
        });
      });

    return of(this.filteredTests);
  }
}
