import { Injectable } from "@angular/core";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { BehaviorSubject, delay, distinctUntilChanged, finalize, map, Subject } from "rxjs";
import { String } from 'typescript-string-operations';
import { PaymentLinkInterface } from "../interfaces/payment-link.interface";
import { AccountTypeDto } from "@core/services/account-types/account-type-dto";

@Injectable()
export class PaymentLinkState {
  private readonly _loading = new BehaviorSubject<boolean>(false);
  private readonly _paymentLink = new Subject<PaymentLinkInterface>();
  private readonly _accountTypes = new BehaviorSubject<AccountTypeDto[]>([]);

  public loading$ = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());
  public paymentLink$ = this._paymentLink.asObservable();
  public accountTypes$ = this._accountTypes.asObservable();

  constructor(private _apiService: ApiService) { }

  public generatePaymentLink(planId: number, accountTypeId?: number) {
    this._loading.next(true);

    const url = String.format(AppConstants.API.PRICING_PLANS.PURCHASE_SESSIONS.GENERATE, planId);
    return this._apiService.post(url, { account_type: accountTypeId }, false, undefined, false)
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe((paymentLink: any) => this._paymentLink.next(paymentLink as PaymentLinkInterface))
  }

  public emailPaymentUrl(planId: number, paymentLink: PaymentLinkInterface, email: string) {
    this._loading.next(true);

    const url = String.format(AppConstants.API.PRICING_PLANS.PURCHASE_SESSIONS.EMAIL, planId, paymentLink.id);
    const body = { via: "email", email };
    return this._apiService.post(url, body, false, undefined, false)
      .pipe(finalize(() => this._loading.next(false)))
  }

  public fetchAccountTypes() {
    this._loading.next(true);

    return this._apiService.get('account-type/')
      .pipe(
        map((res: any) => this._accountTypes.next(res.results as AccountTypeDto[])),
        finalize(() => this._loading.next(false))
      )
  }
}
