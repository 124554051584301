import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { SessionService } from "@core/services/session.service";
import { Observable } from "rxjs";

export const additionalInfoGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const sessionService: SessionService = inject(SessionService);
  const router: Router = inject(Router);

  const auth: Record<string, any> = JSON.parse(sessionService.getCookie('auth') || '{}');
  if (!auth) {
    window.location.href = "/";
    return false;
  }
  if (auth.account_setup_required) {
    return router.createUrlTree(['additional-info']);
  }
  return true;
}
