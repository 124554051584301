import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { AppConstants } from "@core/constants";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { ActivatedFeatureInterface } from "@shared/interfaces/activated-feature.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { CustomRendererService } from "@shared/services/custom-renderer.service";

@Component({
  selector: 'app-active-feature',
  templateUrl: './active-feature.component.html',
  styleUrls: ['./active-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export  class ActiveFeatureComponent implements OnInit {
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private customRendererService: CustomRendererService = inject(CustomRendererService);

  public constant = AppConstants;
  public listActiveFeature: ActivatedFeatureInterface[] = [];

  public ngOnInit(): void {
    this.getFeatureList();
  }

  public getFeatureList(): void {
    this.featureStoreService.getActivatedFeatureList()
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (featureList: ActivatedFeatureInterface[]): void => {
          this.listActiveFeature = featureList.map((feature: ActivatedFeatureInterface) => {
            return {
              ...feature,
              isShowDetail: false,
              period_type_clone: feature.period_type,
            };
          });
          this.cdr.markForCheck();

        },
        error: (error: string) => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
        }
      });
  }
}
