export const testSettings = [
  {
    "id": 8,
    "chart_settings": "",
    "introduction": "",
    "body_composition": "",
    "metabolic_capacities": "",
    "metabolic_profile": "",
    "metabolic_fingerprint": "",
    "performance_development": "",
    "training_zones": "",
    "training_zones_data": "",
    "test_data": "",
    "training_zones_json_data": "",
    "created_at": "2024-09-26T12:58:05.627442Z",
    "updated_at": "2024-09-26T12:58:05.627472Z",
    "is_delete": false,
    "test": 10,
    "training_zone_template": null
  }
]
