import { Component, Input } from '@angular/core';
import { AppBaseComponent } from '../app-component-base';

@Component({
  selector: 'app-progress-bar-usage-item',
  templateUrl: './progress-bar-usage-item.component.html',
  styleUrls: ['./progress-bar-usage-item.component.scss'],
})
export class ProgressBarUsageItemComponent extends AppBaseComponent {
  public percent!: number;

  @Input() public name?: string;
  @Input() public subTitle?: string;
  @Input() public max?: number;
  @Input() public use?: number;

  public onInitPage(): void {
    this.percent = this.use && this.max
      ? Number(((this.use / this.max) * 100).toFixed(0)) : 0;
  }
}
