import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { AppConstants } from '@core/constants';
import { OrganizationDefaultTextDto } from '@core/services/organization/organization-dto';
import { OrganizationSettingService } from '@core/services/organization/organization-setting.service';

@Component({
  selector: 'app-org-tab-description-text',
  templateUrl: './org-tab-description-text.component.html',
  styleUrls: ['./org-tab-description-text.component.scss'],
})
export class OrgTabDescriptionTextComponent extends AppBaseComponent {
  public data: OrganizationDefaultTextDto = {} as OrganizationDefaultTextDto;
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public constants = AppConstants;

  constructor(
    private readonly organizationSettingService: OrganizationSettingService,
    private readonly snackBar: MatSnackBar,
    private readonly fb: UntypedFormBuilder
  ) {
    super();
  }
  public onInitPage(): void {
    this.initForm();

    this.organizationSettingService.getDefaultText().subscribe(
      (res: OrganizationDefaultTextDto): void => {
        this.data = res;
        this.initValue();
      },
      (error): void => {
        this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    );
  }
  private initForm(): void {
    this.form = this.fb.group({
      body_composition: new UntypedFormControl(''),
      introduction: new UntypedFormControl(''),
      metabolic_capacities: new UntypedFormControl(''),
      metabolic_power: new UntypedFormControl(''),
      metabolic_fingerprint: new UntypedFormControl(''),
      metabolic_profile: new UntypedFormControl(''),
      performance_development: new UntypedFormControl(''),
      test_data: new UntypedFormControl(''),
      training_zones: new UntypedFormControl(''),
      heart_rate: new UntypedFormControl(''),
      economy: new UntypedFormControl(''),
      test_data_tables: new UntypedFormControl(''),
    });
  }

  private initValue(): void {
    const {
      body_composition,
      introduction,
      metabolic_capacities,
      metabolic_fingerprint,
      metabolic_profile,
      performance_development,
      test_data,
      training_zones,
      heart_rate,
      economy,
      metabolic_power,
      test_data_tables,
    } = this.data;
    this.form.patchValue({
      body_composition,
      introduction,
      metabolic_capacities,
      metabolic_fingerprint,
      metabolic_profile,
      performance_development,
      test_data,
      training_zones,
      heart_rate,
      economy,
      metabolic_power,
      test_data_tables,
    });
  }

  public save(): void {
    const {
      body_composition,
      introduction,
      metabolic_capacities,
      metabolic_fingerprint,
      metabolic_profile,
      performance_development,
      test_data,
      training_zones,
      heart_rate,
      economy,
      metabolic_power,
      test_data_tables,
    } = this.form.value;

    this.data = {
      ...this.data,
      body_composition,
      introduction,
      metabolic_capacities,
      metabolic_fingerprint,
      metabolic_profile,
      performance_development,
      test_data,
      training_zones,
      heart_rate,
      economy,
      metabolic_power,
      test_data_tables,
    } as OrganizationDefaultTextDto;
    this.organizationSettingService
      .updateDefaultText(this.data)
      .subscribe((): void => {
        this.isDataLoading = false;
        this.snackBar.open('Item updated successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      });
  }
}
