import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { E404Component } from '@modules/errors/e404/e404.component';
import { HomeComponent } from '@modules/home/home.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { PaymentMethodGuard } from '@core/guards/payment-method.guard';
import { LoginComponent } from '@modules/login/login.component';
import { NewAppComponent } from '@modules/new-app/new-app.component';
import { MyDevicesComponent } from '@modules/my-devices/my-devices.component';
import { PpdTestComponent } from '@modules/tests/ppd-test/ppd-test.component';
import { SingleAthleteMetabolicProfilingComponent } from '@modules/tests/single-athlete-metabolic-profiling/single-athlete-metabolic-profiling.component';
import { ForgotPasswordComponent } from '@modules/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@modules/login/reset-password/reset-password.component';
import { ForceUpdateDeviceComponent } from '@modules/login/force-update-device/force-update-device.component';
import { LoadAnalysisComponent } from '@modules/load-analysis/load-analysis/load-analysis.component';
import { LoginRegistrationComponent } from '@modules/login/login-registration/login-registration.component';
import { AdditionalInfoComponent } from '@modules/login/additional-info/additional-info.component';
import { ReportPreviewComponent } from "@modules/report-preview/report-preview/report-preview.component";
import { CountryResolveService } from '@shared/resolvers/country-resolve.service';
import { PrivacyPolicyComponent } from "@shared/components/privacy-policy/privacy-policy.component";
import { TermsComponent } from "@shared/components/terms/terms.component";
import { ForceUpdateConsentComponent } from "@modules/login/force-update-consent/force-update-consent.component";
import { ConfirmSharedDataComponent } from "@shared/components/confirm-shared-data/confirm-shared-data.component";
import { CourseCompleteComponent } from "@shared/components/course-complete/course-complete.component";
import { MetabolicProfileComponent } from '@modules/humango/metabolic-profile/metabolic-profile.component';
import { humangoMetabolicProfileGuard } from '@core/guards/humango-metabolic-profile.guard';
import { PaymentLinkPlanComponent } from '@modules/payment-link/plan/plan.component';
import { additionalInfoGuard } from '@core/guards/additional-info.guard';
import { MyInscydComponent } from "@shared/components/my-inscyd/my-inscyd.component";
import { DevelopmentOnlyGuard } from "@shared/guards/development-only.guard";
import { MyInscydFeatureItemResolver } from "@shared/resolvers/my-inscyd-feature-item.resolver";
import { MyProfileComponent } from "@shared/components/my-profile/my-profile.component";
import { MyProfileItemResolver } from "@shared/resolvers/my-profile-item.resolver";
import { IntegrationsComponent } from "@shared/components/integrations/integrations.component";
import { IntegrationListResolver } from "@shared/resolvers/integration-list.resolver";
import { AccountInfoComponent } from "@shared/components/account-info/account-info.component";
import { AccountInfoResolver } from "@shared/resolvers/account-info.resolver";
import { PersonalInfoComponent } from "@shared/components/personal-info/personal-info.component";
import { PersonalInfoResolver } from "@shared/resolvers/personal-info.resolver";
import { CurrentUserSessionResolver } from "@shared/resolvers/current-user-session.resolver";
import { CurrentUserSessionComponent } from "@shared/components/current-user-session/current-user-session.component";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'course-complete',
    component: CourseCompleteComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'register',
    component: LoginRegistrationComponent,
  },
  {
    path: 'additional-info',
    component: AdditionalInfoComponent,
    resolve: {
      countries: CountryResolveService,
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password/:key',
    component: ResetPasswordComponent,
  },
  {
    path: 'force-update-device',
    component: ForceUpdateDeviceComponent,
  },
  {
    path: 'force-update-consent',
    component: ForceUpdateConsentComponent,
  },
  {
    path: 'confirm-share-data',
    component: ConfirmSharedDataComponent,
  },
  {
    path: 'report-preview/:testId',
    component: ReportPreviewComponent
  },
  {
    path: 'external/humango',
    component: MetabolicProfileComponent,
    canActivate: [humangoMetabolicProfileGuard]
  },
  {
    path: 'payment_link/:guid',
    component: PaymentLinkPlanComponent,
  },
  {
    path: 'home',
    component: NewAppComponent,
    canActivate: [AuthGuard, additionalInfoGuard],
    children: [
      {
        path: 'integrations',
        component: IntegrationsComponent,
        canActivate: [AuthGuard],
        resolve: {
          data: IntegrationListResolver,
        },
      },
      {
        path: 'security',
        component: CurrentUserSessionComponent,
        canActivate: [AuthGuard],
        resolve: {
          data: CurrentUserSessionResolver,
        },
      },
      {
        path: 'account-info',
        component: AccountInfoComponent,
        canActivate: [AuthGuard],
        resolve: {
          data: AccountInfoResolver,
        },
      },
      {
        path: 'personal-info',
        component: PersonalInfoComponent,
        canActivate: [AuthGuard, DevelopmentOnlyGuard],
        resolve: {
          data: PersonalInfoResolver,
        },
      },
      {
        path: 'dashboard',
        component: HomeComponent,
        canActivate: [AuthGuard, PaymentMethodGuard],
      },
      {
        path: 'my-devices',
        component: MyDevicesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ppd/:id/:action',
        component: PpdTestComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'metabolic-profile/:id/:action',
        component: SingleAthleteMetabolicProfilingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ppd/:id/:action',
        component: PpdTestComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'apc',
        loadChildren: () =>
          import('@modules/apc/apc.module').then((m) => m.ApcModule),
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('@modules/administration/administration.module').then(
            (m) => m.AdministrationModule
          ),
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('@modules/manage/manage.module').then(
            (m) => m.ManageModule
          ),
      },
      {
        path: 'plan-subscription',
        loadChildren: () =>
          import(
            '@modules/plan-subscription/plan-subscription.module'
          ).then((m) => m.PlanSubscriptionModule),
      },
      {
        path: 'tac',
        loadChildren: () =>
          import(
            '@modules/teams-and-competitions/teams-and-competitions.module'
          ).then((m) => m.TeamsAndCompetitionsModule),
      },
      {
        path: 'dynamic-simulation',
        loadChildren: () =>
          import(
            '@modules/dynamic-simulation/dynamic-simulation.module'
          ).then((m) => m.DynamicSimulationModule),
      },
      {
        path: 'load-analysis',
        component: LoadAnalysisComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'college',
        loadChildren: () =>
          import('@modules/courses/college.module').then(
            (m) => m.CollegeModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'feature-store',
        loadChildren: () => import('@modules/feature-store/feature-store.module').then((m) => m.FeatureStoreModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-inscyd',
        component: MyInscydComponent,
        canActivate: [AuthGuard, DevelopmentOnlyGuard],
        resolve: {
          data: MyInscydFeatureItemResolver,
        },
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AuthGuard, DevelopmentOnlyGuard],
        resolve: {
          data: MyProfileItemResolver,
        },
      },
      {
        path: '**',
        component: E404Component,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
