import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { catchError, finalize } from 'rxjs/operators';
import { AthleteTrainingLevelDto, AthleteTrainingLevelResultDto } from '@core/services/athletes-management/athletes-training-level-dto';
import { AthletesService } from '@core/services/athletes-management/athletes.service';

@Component({
  selector: 'app-athlete-category',
  templateUrl: './athlete-category.component.html',
  styleUrls: ['./athlete-category.component.scss'],
})
export class AthleteCategoryComponent extends AppBaseComponent {
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public listTrainingLevel: Array<AthleteTrainingLevelDto> = [];

  constructor(
    public dialogRef: MatDialogRef<AthleteCategoryComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly athletesService: AthletesService,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  public onInitPage(): void {
    this.isDataLoading = true;
    this.athletesService.getAthleteTrainingLevel()
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        }),
        finalize((): boolean => (this.isDataLoading = false))
      )
      .subscribe((res: AthleteTrainingLevelResultDto): void => {
        this.listTrainingLevel = res.items;
        this.initValue();
      });
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      custom1: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom2: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom3: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom4: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom5: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
      custom6: new UntypedFormControl('', [
        Validators.maxLength(30),
        Validators.pattern(this.constant.REGEX.NO_SPECIAL),
        Validators.required,
      ]),
    });
  }

  private initValue(): void {
    const levelCustom: AthleteTrainingLevelDto[] = this.listTrainingLevel.filter(
      (item: AthleteTrainingLevelDto) => !item.is_default_level
    );
    this.form.patchValue({
      custom1: levelCustom[0]?.value,
      custom2: levelCustom[1]?.value,
      custom3: levelCustom[2]?.value,
      custom4: levelCustom[3]?.value,
      custom5: levelCustom[4]?.value,
      custom6: levelCustom[5]?.value,
    });
  }
  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (this.form.valid) {
      const input = this.listTrainingLevel
        .filter((item: AthleteTrainingLevelDto) => !item.is_default_level)
        .map((category: AthleteTrainingLevelDto, index: number) => {
          return {
            id: category.id,
            value: this.form.value[`custom${index + 1}`],
          } as { id: number; value: string };
        });
      this.isDataLoading = true;
      this.athletesService.updateAthleteTrainingLevel(input)
        .pipe(
          catchError((error) => {
            this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);

            throw error;
          }),
          finalize((): boolean => (this.isDataLoading = false))
        )
        .subscribe((): void => {
          this.dialogRef.close();
          this.snackBar.open('Items updated successfully', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
        });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
