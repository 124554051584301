import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { String } from 'typescript-string-operations';

@Component({
  selector: 'app-information-read',
  templateUrl: './information-read.component.html',
})
export class InformationReadComponent implements OnInit {
  public ready: boolean = false;
  public show_accept: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InformationReadComponent>,
    private apiService: ApiService,
  ) {}

  public ngOnInit(): void {
    this.ready = this.data.type == 1;
    this.show_accept = this.data.type == 2;
  }

  public ok(): void {
    const apiURL: string = String.Format(AppConstants.API.INFORMATION_POPUP.UPDATE_READ, this.data.id);
    this.apiService.put(apiURL, this.data)
      .subscribe((): void => {
        this.dialogRef.close('refresh');
      });
  }
}
