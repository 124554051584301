import { Component, DestroyRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { AuthService } from '@core/services/auth/auth.service';
import { OrganizationDto } from '@core/services/organization/organization-dto';
import { SessionService } from '@core/services/session.service';
import { UserDto } from '@core/services/users/user-dto';
import { IAuth } from '../login.component';
import { GoogleTagManagerHelperService } from "@shared/services/google-tag-manager-helper.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

declare global {
  interface Window { [key: string]: any; }
}

const globalThis = window;

export interface ILoginModel {
  name: string;
  username: string;
  password: string;
  uuid: string;
}

export interface IDevices {
  data: Array<IDeviceInfo>;
  max: number;
}

export interface IDeviceInfo {
  confirm_code: string;
  confirm_code_new: string;
  created_at: string;
  id: number;
  ip: string;
  is_confirm_new: boolean;
  is_delete: boolean;
  name: string;
  organization: OrganizationDto;
  updated_at: string;
  user: UserDto;
  uuid: string;
}

@Component({
  selector: 'app-force-update-device',
  templateUrl: './force-update-device.component.html',
  styleUrls: ['./force-update-device.component.scss']
})
export class ForceUpdateDeviceComponent implements OnInit {
  public form!: UntypedFormGroup;
  public model: ILoginModel = { name: '', username: '', uuid: '', password: '' };
  public devices!: IDevices;
  public max_devices: number = 0;
  public auth!: IAuth;
  public isSessionExpired: boolean = false;

  constructor(
    private authService: AuthService,
    private readonly fb: UntypedFormBuilder,
    private apiService: ApiService,
    private readonly snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private googleTagManagerHelperService: GoogleTagManagerHelperService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.isSessionExpired = this.activatedRoute.snapshot.queryParams.expired_session;

    this.initForm();
    const errorMessage: string = 'Session expired, please login again.';
    this.model.username = globalThis.FORCE_UPDATE_DEVICE ? (globalThis.FORCE_UPDATE_DEVICE.username || '') : '';
    this.model.password = globalThis.FORCE_UPDATE_DEVICE ? (globalThis.FORCE_UPDATE_DEVICE.password || '') : '';

    if (!this.model.username || !this.model.password) {
      const returnedPath: string | null = this.authService.getReturnPath();

      if (this.isSessionExpired && returnedPath) {
        from(this.router.navigateByUrl(returnedPath))
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({complete: (): void => {
              this.authService.removeReturnPath();
            }});
        this.snackBar.open(errorMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      } else {
        this.router.navigate(['']);
        this.snackBar.open(errorMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    }
  }

  public initForm(): void {
    const ControlsConfig = { name: new UntypedFormControl('', Validators.required)};
    this.form = this.fb.group(ControlsConfig);
  }

  public updateDeviceName(): void {
    const requiredMessage = 'Device name is required.';
    const api: string = 'accounts/update-new-device/';

    if(!this.form.valid){
      this.snackBar.open(requiredMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      return;
    }

    this.model.name = this.form.get('name')?.value;

    this.apiService.post(api, this.model)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: any): void => {
      delete globalThis.FORCE_UPDATE_DEVICE;
      this.sessionService.activateLoginStatus();
      this.googleTagManagerHelperService.setDataLayer(res);
      this.authService.setAuthAndGoToMainPage(res, this.isSessionExpired);
    },
    (error): void => {
      this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      if (error && error === "Error: Force update consent") {
        this.router.navigate(['/force-update-consent']);
      }
      if (error && error === "Error: Maximum device exceeded") {
        this.getDevices();
      }
    });
  }

  public getDevices(): void {
    const api: string = 'accounts/public/my-devices/';
    this.apiService.post(api, this.model)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: Object) => {
      const data: IDevices = response as IDevices;
      if (data && data.data) {
        this.devices = data;
        this.max_devices = data.max || 0;
      }
    }, error => {
      this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
    });
  }

  public deleteDevice(id: number): void {
    const api: string = `accounts/public/my-devices/${id}/`;
    this.apiService.put(api, this.model)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
      this.getDevices();
    }, error => {
      this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
    });
  }
}
