<div>
  <h2 mat-dialog-title>{{ data?.isEdit ? "Edit Plan" : "Add Plan" }}</h2>
  <mat-dialog-content>

    <div [formGroup]="firstForm">
      <div class="field">
        <mat-form-field class="full-width" appearance="outline" floatLabel="always">
          <mat-label>Name (*)</mat-label>
          <input matInput formControlName="name" placeholder="Name"/>
          <mat-error
            *ngIf="
            firstForm.controls?.name?.errors &&
            firstForm.controls?.name?.touched
            "
          >
            <div
              [innerHTML]="
              firstForm.controls.name.errors
                  | errorFormFormat: { fieldName: 'Name' }
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>

      <div style="display: flex; justify-content: space-between">
        <div style="flex: 1 1 50%" class="field">
          <mat-slide-toggle formControlName="is_publish" class="full-width">Published</mat-slide-toggle>
        </div>
        <div style="flex: 1 1 50%" class="field">
          <mat-slide-toggle formControlName="is_accumulated" class="full-width">Accumulated</mat-slide-toggle>
        </div>
      </div>
      <br>

      <div class="field">
        <mat-form-field class="full-width" appearance="outline" floatLabel="always">
          <mat-label>Group</mat-label>
          <mat-select placeholder="Please select" formControlName="group">
            <mat-option  *ngFor="let item of groupOptions" [value]="item.id">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field">
        <app-chips
          [label]="'Product'"
          [form]="firstForm.get('product_ids')"
          [options]="productOptions"
          [displayField]="'name'"
          (optionChange)="onSelectProduct($event)"
        ></app-chips>
      </div>
      <div class="field">
        <app-chips
          [label]="'Feature'"
          [form]="firstForm.get('feature_ids')"
          [options]="featureOptions"
          [displayField]="'name'"
          (optionChange)="onSelectFeature($event)"
        ></app-chips>
      </div>
      <div class="field field-toggle">
        <mat-slide-toggle
          style="margin-bottom: 2%"
          class="full-width"
          (change)="openCloseRights($event.checked)"
          [checked]="firstForm.get('is_show_setting')?.value"
          formControlName="is_show_setting"
        >Settings in Right Management</mat-slide-toggle>
      </div>
      <div
        class="setting__section"
        *ngIf="firstForm.get('is_show_setting')?.value"
      >
        <div class="sub-title border-color">
          <mat-icon
            *ngIf="!showRightManagement"
            (click)="showRightManagement = true"
          >keyboard_arrow_right</mat-icon
          >
          <mat-icon
            *ngIf="showRightManagement"
            (click)="showRightManagement = false"
          >keyboard_arrow_down</mat-icon
          >
          Right Management
        </div>
        <div *ngIf="showRightManagement">
          <div class="note">
            <span>YES</span>
            <span>NO</span>
          </div>
          <ng-container *ngIf="rightList.length">
            <fieldset class="dynamic-fieldset" *ngFor="let item of rightList">
              <legend>{{ item.name }}</legend>
              <div class="option" *ngFor="let optionItem of item.options">
                <div class="label">{{ optionItem?.name }}</div>
                <mat-radio-group
                  (change)="changedValueOfRadioButton(optionItem.path, $event)"
                  class="value"
                >
                  <mat-radio-button
                    style="margin-right: 12px"
                    color="primary"
                    [value]="true"
                    [checked]="isRadioChecked(optionItem.path, true)"
                  ></mat-radio-button>
                  <mat-radio-button
                    color="primary"
                    [value]="false"
                    [checked]="isRadioChecked(optionItem.path, false)"
                  ></mat-radio-button>
                </mat-radio-group>
              </div>
              <ng-container *ngFor="let subGroup of item.subGroup">
                <div class="sub-title">{{ subGroup.name }}</div>
                <div
                  class="option"
                  *ngFor="let subOptionItem of subGroup.options"
                >
                  <div class="label">{{ subOptionItem?.name }}</div>
                  <mat-radio-group
                    (change)="
                          changedValueOfRadioButton(subOptionItem.path, $event)
                        "
                    class="value"
                  >
                    <mat-radio-button
                      style="margin-right: 12px"
                      color="primary"
                      [value]="true"
                      [checked]="isRadioChecked(subOptionItem.path, true)"
                    ></mat-radio-button>
                    <mat-radio-button
                      color="primary"
                      [value]="false"
                      [checked]="isRadioChecked(subOptionItem.path, false)"
                    ></mat-radio-button>
                  </mat-radio-group>
                </div>
              </ng-container>
            </fieldset>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- PLAN DETAILS -->
    <div class="setting__section" [formGroup]="planForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowPlanSetting"
          (click)="isShowPlanSetting = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowPlanSetting"
          (click)="isShowPlanSetting = false"
        >keyboard_arrow_down</mat-icon
        >
        Plan details
      </div>
      <div class="content" *ngIf="isShowPlanSetting">
        <div class="field">
          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
            <mat-label>Description (*)</mat-label>
            <textarea rows="5" matInput formControlName="description"></textarea>
            <mat-error
              *ngIf="
                  planForm.controls?.description?.errors &&
                  planForm.controls?.description?.touched
                "
            >
              <div
                [innerHTML]="
                  planForm.controls.description.errors
                  | errorFormFormat: { fieldName: 'Description' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field" formArrayName="features"
             *ngFor="let feature of features.controls; index as i">
          <div class="field-col" [formGroupName]="i">
            <button mat-raised-button (click)="removeFeature(i)" color="warn" class="remove-btn" type="button">
              x
            </button>
            <mat-form-field class="full-width" appearance="outline" floatLabel="always">
              <mat-label>Feature {{ i + 1 }} (*)</mat-label>
              <input matInput type="text" name="feature" formControlName="feature"/>
            </mat-form-field>
          </div>
        </div>
        <div class="btn">
          <button mat-raised-button (click)="addFeatureField()">{{'+ Add feature'}}</button>
        </div>
      </div>
    </div>

    <!-- AVAILABILITY -->
    <div class="setting__section" [formGroup]="availabilityForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowAvailabilitySetting"
          (click)="isShowAvailabilitySetting = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowAvailabilitySetting"
          (click)="isShowAvailabilitySetting = false"
        >keyboard_arrow_down</mat-icon
        >
        Availability
      </div>
      <div class="content" *ngIf="isShowAvailabilitySetting">
        <div class="field">
          <app-chips
            [label]="'Account type it applies to'"
            [form]="availabilityForm.get('account_types')"
            [options]="accountOptions"
            [displayField]="'name'"
            (optionChange)="onSelectAccount($event)"
          ></app-chips>
        </div>
        <div class="field">
          <app-chips
            [label]="'Region'"
            [form]="availabilityForm.get('regions')"
            [options]="regionOptions"
            [displayField]="'name'"
            (optionChange)="onSelectRegion($event)"
          ></app-chips>
        </div>
        <div class="field">
          <app-chips
            [label]="'Organization it applies to'"
            [form]="availabilityForm.get('organizations')"
            [options]="organizationOptions"
            [displayField]="'name'"
            (optionChange)="onSelectOrganization($event)"
          ></app-chips>
        </div>
      </div>
    </div>

    <!-- DURATION -->
    <div class="setting__section" [formGroup]="durationForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowDurationSetting"
          (click)="isShowDurationSetting = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowDurationSetting"
          (click)="isShowDurationSetting = false"
        >keyboard_arrow_down</mat-icon
        >
        Duration
      </div>
      <div class="content" *ngIf="isShowDurationSetting && !isAccumulated">
        <div class="field">
          <div class="field-col">
            <mat-form-field  class="full-width" appearance="outline" floatLabel="always">
              <mat-label>Auto renewal (*)</mat-label>
              <input formControlName="renewal" matInput/>
            </mat-form-field>
            <mat-form-field style="width: 35%;" class="full-width" appearance="outline" floatLabel="always">
              <mat-label> Period type </mat-label>
              <mat-select formControlName="renewal_unit">
                <mat-option *ngFor="let periodType of periodTypes"
                            [value]="periodType.id">{{periodType.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="field">
          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
            <mat-label>Cancellation Notice Time (*)</mat-label>
            <input formControlName="cancel_notice_time" matInput placeholder=""/> Day(s)
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
            <mat-label>Minimum Contract Duration</mat-label>
            <input formControlName="minimum_contract_duration" matInput placeholder=""/> Month(s)
          </mat-form-field>
        </div>
      </div>

      <ng-container formArrayName="billing_period_details">
        <mat-tab-group *ngIf="isShowDurationSetting && isAccumulated">
          <mat-tab [formGroupName]="i" *ngFor="let durationDetail of durationPeriodDetails.controls; index as i">
            <ng-template mat-tab-label>{{ getBillingDetailPeriodName(durationDetail.value.period_type) }}</ng-template>

            <div class="field mt-3">
              <mat-form-field  class="full-width" appearance="outline" floatLabel="always">
                <mat-label>Auto renewal (*)</mat-label>
                <input formControlName="renewal" matInput/>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                <mat-label>Cancellation Notice Time (*)</mat-label>
                <input formControlName="cancel_notice_time" matInput placeholder=""/> Day(s)
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                <mat-label>Minimum Contract Duration</mat-label>
                <input formControlName="minimum_contract_duration" matInput placeholder=""/> Month(s)
              </mat-form-field>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </div>

    <!-- PRICING -->
    <div class="setting__section" [formGroup]="pricingForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowPricingSetting"
          (click)="isShowPricingSetting = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowPricingSetting"
          (click)="isShowPricingSetting = false"
        >keyboard_arrow_down</mat-icon
        >
        Pricing
      </div>
      <div class="content" *ngIf="isShowPricingSetting">
        <p>Active items: Linking Items to each another to trigger activation</p><br>
        <ng-container  formArrayName="link_items" *ngFor="let item of link_items.controls; index as j">
          <div class="discount-container">
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="removeLinkItem(j)"
              class="remove-btn"
            >
              x
            </button>
            <button
              type="button"
              mat-raised-button
              (click)="copyLinkForm(item)"
              class="remove-btn"
            >
              <mat-icon>file_copy</mat-icon>
            </button>
            <div [formGroupName]="j" class="discount-field">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <mat-label> Name of linked item {{j + 1}}</mat-label>
                  <input formControlName="name_link_item" matInput/>
                </mat-form-field>
              </div>

              <div class="field">
                <div class="field-col">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <mat-label>Master Item</mat-label>
                    <mat-select placeholder="" formControlName="master_item">
                      <mat-option *ngFor="let item of activation_items" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <mat-label>Current Period</mat-label>
                    <mat-select placeholder="Current Period" formControlName="current_period">
                      <mat-option *ngFor="let item of periodTypes" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="field">
                <app-chips
                  [label]="'Activation Items'"
                  [form]="getFormControl('activation_item', j)"
                  [options]="activationOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectActivationItem($event, j)"
                ></app-chips>
              </div>
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <mat-label> Idle Time Period </mat-label>
                  <input formControlName="idle_time_period" matInput/>
                </mat-form-field>
              </div>

              <div class="field">
                <div class="field-col">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <mat-label> Max </mat-label>
                    <input type="number" (change)="maxChange($event, j)" formControlName="max_item" matInput/>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <mat-label></mat-label>
                    <mat-select placeholder="Current period" formControlName="idle_time_unit">
                      <mat-option *ngFor="let item of periodTypes" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div>
                <ng-container  formArrayName="lots" *ngFor="let item1 of getLots(j).controls; index as i">
                  <div class="discount-container">
                    <button
                      type="button"
                      mat-raised-button
                      color="warn"
                      (click)="removeLotItem(i, j)"
                      class="remove-btn"
                    >
                      x
                    </button>
                    <div [formGroupName]="i" class="discount-field">
                      <mat-label> Lot {{i + 1}}</mat-label>
                      <br>
                      <div class="field">
                        <div class="field-col">
                          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                            <mat-label>From</mat-label>
                            <input [readonly]="true" matInput type="number" name="feature" formControlName="from_link_item"/>
                          </mat-form-field>

                          <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                            <mat-label>To</mat-label>
                            <input matInput type="number" (change)="lotToChange($event, j, i + 1)" name="feature" formControlName="to_link_item"/>
                          </mat-form-field>
                        </div>
                      </div>
                      <mat-radio-group formControlName="is_once_link_item" style="display: flex; flex-direction: column">
                        <mat-radio-button color="primary" style="margin-right: 12px; margin-left:10px" [value]="false">Multiple</mat-radio-button>
                        <mat-radio-button color="primary" style="margin-right: 12px; margin-left:10px" [value]="true">Once</mat-radio-button>
                      </mat-radio-group><br>
                      <div>
                        <ng-container *ngIf="isShowPricingRegions" formArrayName="lot_prices">
                          <ng-container>
                            <table>
                              <tbody *ngFor="let price of getLinkLotPrices(j, i).controls; let k = index;">
                              <tr [formGroupName]="k">
                                <td *ngIf="price.value.type_value === 1"><p class="linkPriceRegion">{{price.value.region_name}}</p></td>
                                <td *ngIf="price.value.type_value !== 1"><p></p></td>
                                <td><p class="linkPriceTd">{{price.value.type_name}}</p></td>
                                <td>
                                  <div class="field">
                                    <mat-form-field style="margin-bottom: -12%;" class="full-width" appearance="outline" floatLabel="always">
                                      <input [readonly]="(!item1.value.is_once_link_item && price.value.type_value === 4) || (item1.value.is_once_link_item && price.value.type_value !== 4)"
                                             formControlName="link_item" matInput type="number" min="0"  name="price"/>
                                    </mat-form-field>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="btn">
                <button mat-raised-button style="margin-top: 8%;" (click)="addLotForm(j)">{{'+ Add lot'}}</button>
              </div><br>

            </div>
          </div>
        </ng-container >
      </div>
      <div class="btn">
        <button mat-raised-button (click)="addLinkItemForm()">{{'+ Add link item'}}</button>
      </div>
    </div>


    <!-- FEE -->
    <div class="setting__section" [formGroup]="feeForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowFeeSetting"
          (click)="isShowFeeSetting = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowFeeSetting"
          (click)="isShowFeeSetting = false"
        >keyboard_arrow_down</mat-icon
        >
        Fee
      </div>
      <div class="content" *ngIf="isShowFeeSetting">
        <div class="field">
          <app-chips
            [label]="'Items included (*)'"
            [form]="feeForm.get('include_items')"
            [options]="itemsIncludedOptions"
            [displayField]="'name'"
            (optionChange)="onSelectItemIncluded($event)"
          ></app-chips>
        </div>
        <table *ngIf="!isAccumulated">
          <thead>
          <tr>
            <th *ngIf="isShowItemUser">Max User</th>
            <th *ngIf="isShowItemAthlete">Max Athletes</th>
            <th *ngIf="isShowItemLA">Max Lactate</th>
            <th *ngIf="isShowItemMap">Max PPD</th>
            <th *ngIf="isShowItemManual">Max Manual</th>
            <th *ngIf="isShowItemVirtual">Max Virtual</th>
            <th *ngIf="isShowItemEvent">Max Event</th>
            <th *ngIf="isShowItemResult">Max Result</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td *ngIf="isShowItemUser">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_user')" type="number" min="0" formControlName="max_user" matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemAthlete">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_athlete')" type="number" min="0" formControlName="max_athlete"  matInput />
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemLA">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_test_la')" type="number" min="0" formControlName="max_test_la"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemMap">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_test_map')" type="number" min="0" formControlName="max_test_map"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemManual">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_test_manual')" type="number" min="0" formControlName="max_test_manual"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemVirtual">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_test_virtual')" type="number" min="0" formControlName="max_test_virtual"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemEvent">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_test_event')" type="number" min="0" formControlName="max_test_event"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemResult">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input (change)="onChangeInputFee($event, 'from_test_result')" type="number" min="0" formControlName="max_test_result"  matInput/>
                </mat-form-field>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <ng-container formArrayName="billing_period_details">
          <table *ngIf="isAccumulated">
            <thead>
            <tr>
              <th>Period</th>
              <th *ngIf="isShowItemUser">Max User</th>
              <th *ngIf="isShowItemAthlete">Max Athletes</th>
              <th *ngIf="isShowItemLA">Max Lactate</th>
              <th *ngIf="isShowItemMap">Max PPD</th>
              <th *ngIf="isShowItemManual">Max Manual</th>
              <th *ngIf="isShowItemVirtual">Max Virtual</th>
              <th *ngIf="isShowItemEvent">Max Event</th>
              <th *ngIf="isShowItemResult">Max Result</th>
            </tr>
            </thead>
            <tbody [formGroupName]="i" *ngFor="let billingPeriodDetail of billingPeriodDetails.controls; index as i">
            <tr>
              <td><strong>{{ getBillingDetailPeriodName(billingPeriodDetail.value.period_type) }}</strong><br />&nbsp;</td>
              <td *ngIf="isShowItemUser">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_user')" min="0" formControlName="max_user" matInput/>
                  </mat-form-field>
                </div>
              </td>

              <td *ngIf="isShowItemAthlete">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_athlete')" min="0" formControlName="max_athlete"  matInput />
                  </mat-form-field>
                </div>
              </td>

              <td *ngIf="isShowItemLA">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_test_la')" min="0" formControlName="max_test_la"  matInput/>
                  </mat-form-field>
                </div>
              </td>

              <td *ngIf="isShowItemMap">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_test_map')" min="0" formControlName="max_test_map"  matInput/>
                  </mat-form-field>
                </div>
              </td>

              <td *ngIf="isShowItemManual">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_test_manual')" min="0" formControlName="max_test_manual"  matInput/>
                  </mat-form-field>
                </div>
              </td>

              <td *ngIf="isShowItemVirtual">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_test_virtual')" min="0" formControlName="max_test_virtual"  matInput/>
                  </mat-form-field>
                </div>
              </td>

              <td *ngIf="isShowItemEvent">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_test_event')" min="0" formControlName="max_test_event"  matInput/>
                  </mat-form-field>
                </div>
              </td>

              <td *ngIf="isShowItemResult">
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input type="number" (change)="onChangeInputFee($event, 'from_test_result')" min="0" formControlName="max_test_result"  matInput/>
                  </mat-form-field>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container formArrayName="plan_prices">
          <table style="margin-left:15%">
            <thead *ngIf="isShowColumns">
            <tr>
              <th>Region</th>
              <th>Annual</th>
              <th>Quarter</th>
              <th>Month</th>
            </tr>
            </thead>
            <tbody [formGroupName]="i" *ngFor="let price of plan_prices.controls; index as i">
            <tr>
              <td><p style="white-space: nowrap;">{{price.value.region_name}}</p></td>
              <td>
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input matInput type="number" min="0"  name="price" formControlName="annual"/>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input matInput type="number" min="0"  name="price" formControlName="quarter"/>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="field">
                  <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                    <input matInput type="number" min="0"   name="price" formControlName="month"/>
                  </mat-form-field>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <div class="field" formArrayName="alternative_items"
             *ngFor="let item of alternative_items.controls; index as i">
          <div class="field-col" [formGroupName]="i">
            <button mat-raised-button (click)="removeBundled(i)" color="warn" class="remove-btn" type="button">
              x
            </button>
            <div  class="full-width">
              <mat-label>Bundled {{ i + 1 }}</mat-label>
              <div class="field">
                <app-chips
                  [form]="getBundledFormControl('alternative_item', i)"
                  [options]="bundledOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectBundled($event, i)"
                ></app-chips>
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <button mat-raised-button (click)="addBundledField()">{{'+ Add Bundled'}}</button>
        </div>
      </div>
    </div>

    <!-- ITEM DISPLAY IN OVERVIEW -->
    <div class="setting__section" [formGroup]="itemDisplayForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowItemDisplaySetting"
          (click)="isShowItemDisplaySetting = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowItemDisplaySetting"
          (click)="isShowItemDisplaySetting = false"
        >keyboard_arrow_down</mat-icon
        >
        Item display in overview
      </div>
      <div class="content" *ngIf="isShowItemDisplaySetting">
        <div class="field">
          <app-chips
            [label]="'Items (Max to 3)'"
            [form]="itemDisplayForm.get('display_items')"
            [options]="itemDisplayOptions"
            [displayField]="'NAME'"
            (optionChange)="onSelectItemDisplay($event)"
          ></app-chips>
        </div>
      </div>
    </div>

    <!-- EXCESS ITEMS -->
    <div class="setting__section" [formGroup]="excessForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowExcessItems"
          (click)="isShowExcessItems = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowExcessItems"
          (click)="isShowExcessItems = false"
        >keyboard_arrow_down</mat-icon
        >
        Excess Items
      </div>
      <div class="content" *ngIf="isShowExcessItems">
        <ng-container formArrayName="lots_excess" *ngFor="let lot of lots_excess.controls; let j = index">
          <ng-container [formGroupName]="j">
            <div class="discount-container">
              <button
                type="button"
                mat-raised-button
                color="warn"
                (click)="removeExcess(j)"
                class="remove-btn"
              >
                x
              </button>
              <div class="discount-field">
                <mat-label> Lot {{j + 1}}</mat-label><br>
                <div style="display: flex;">

                  <div style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">From/To</p>
                    <p class="from">From</p>
                    <p class="to">To</p>
                  </div>

                  <div *ngIf="isShowItemUser" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">User</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_user" type="number" min="0"  name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_user', j)"  formControlName="to_user" type="number" min="0"  name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_user" style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div *ngIf="isShowItemAthlete" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">Athlete</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_athlete" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_athlete', j)"  formControlName="to_athlete" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_athlete"  style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div *ngIf="isShowItemLA" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">Lactate</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_test_la" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_test_la', j)"  formControlName="to_test_la" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_la"  style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div *ngIf="isShowItemMap" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">PPD</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_test_map" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_test_map', j)"  formControlName="to_test_map" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_map"  style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div *ngIf="isShowItemManual" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">Manual</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_test_manual" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_test_manual', j)"  formControlName="to_test_manual" type="number" min="0"  name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_manual"  style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div *ngIf="isShowItemVirtual" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">Virtual</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_test_virtual" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_test_virtual', j)"  formControlName="to_test_virtual" type="number" min="0"  name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_virtual"  style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div *ngIf="isShowItemEvent" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">Event</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_test_event" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_test_event', j)"  formControlName="to_test_event" type="number" min="0"  name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_event"  style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div  *ngIf="isShowItemResult" style="display: flex; flex-direction: column; margin-right: 1%;">
                    <p style="text-align: center;">Result</p>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always" style="margin-bottom: -10%;">
                      <input matInput [readonly]="true" formControlName="from_test_result" type="number" min="0" name="price"/>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                      <input matInput (change)="onChangeExcessInput($event, 'from_test_result', j)"  formControlName="to_test_result" type="number" min="0"  name="price"/>
                    </mat-form-field>
                    <mat-radio-group formControlName="is_once_result"  style="display: flex; flex-direction: column">
                      <mat-radio-button color="primary" [value]="false">Multiple</mat-radio-button>
                      <mat-radio-button color="primary" [value]="true">Once</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div><br>
              </div>
            </div><br>

            <ng-container formArrayName="lot_prices">
              <ng-container>

                <table style="margin-left:10%" *ngIf="isShowExcessRegions">
                  <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th *ngIf="isShowItemUser">User</th>
                    <th *ngIf="isShowItemAthlete">Athletes</th>
                    <th *ngIf="isShowItemLA">Lactate</th>
                    <th *ngIf="isShowItemMap">PPD</th>
                    <th *ngIf="isShowItemManual">Manual</th>
                    <th *ngIf="isShowItemVirtual">Virtual</th>
                    <th *ngIf="isShowItemEvent">Event</th>
                    <th *ngIf="isShowItemResult">Result</th>
                  </tr>
                  </thead>
                  <tbody *ngFor="let item1 of getLotPrices(j).controls; let i = index;">
                  <tr [formGroupName]="i">
                    <td *ngIf="item1.value.type_value === 1"><p style="white-space: nowrap; font-weight: bold;">{{item1.value.region_name}}</p></td>
                    <td *ngIf="item1.value.type_value !== 1"><p></p></td>
                    <td><p style="text-align: right;">{{item1.value.type_name}}</p></td>
                    <td *ngIf="isShowItemUser">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_user && item1.value.type_value === 4) || (lot.value.is_once_user && item1.value.type_value !== 4)"
                            formControlName="user" matInput type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngIf="isShowItemAthlete">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_athlete && item1.value.type_value === 4) || (lot.value.is_once_athlete && item1.value.type_value !== 4)"
                            matInput formControlName="athlete" type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngIf="isShowItemLA">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_la && item1.value.type_value === 4) || (lot.value.is_once_la && item1.value.type_value !== 4)"
                            matInput formControlName="test_la" type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngIf="isShowItemMap">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_map && item1.value.type_value === 4) || (lot.value.is_once_map && item1.value.type_value !== 4)"
                            matInput formControlName="test_map" type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngIf="isShowItemManual">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_manual && item1.value.type_value === 4) || (lot.value.is_once_manual && item1.value.type_value !== 4)"
                            matInput formControlName="test_manual" type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngIf="isShowItemVirtual">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_virtual && item1.value.type_value === 4) || (lot.value.is_once_virtual && item1.value.type_value !== 4)"
                            matInput formControlName="test_virtual" type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngIf="isShowItemEvent">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_event && item1.value.type_value === 4) || (lot.value.is_once_event && item1.value.type_value !== 4)"
                            matInput formControlName="test_event" type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngIf="isShowItemResult">
                      <div class="field">
                        <mat-form-field class="full-width" style="margin-bottom: -12%;" appearance="outline" floatLabel="always">
                          <input
                            [readonly]="(!lot.value.is_once_result && item1.value.type_value === 4) || (lot.value.is_once_result && item1.value.type_value !== 4)"
                            matInput formControlName="test_result" type="number" min="0" name="price"/>
                        </mat-form-field>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>

          </ng-container>
        </ng-container>
        <div class="btn">
          <button mat-raised-button (click)="addExcessForm()">{{'+ Add lot'}}</button>
        </div>
      </div>
    </div>

    <!-- RATIOS -->
    <div class="setting__section" [formGroup]="ratiosForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowRatios"
          (click)="isShowRatios = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowRatios"
          (click)="isShowRatios = false"
        >keyboard_arrow_down</mat-icon
        >
        Ratios
      </div>
      <div class="content" *ngIf="isShowRatios">
        <ng-container
          formArrayName="ratios"
          *ngFor="let ratio of ratios.controls; index as i"
        >
          <div class="ratio-container">
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="removeRatio(i)"
              class="remove-btn"
            >
              x
            </button>
            <div [formGroupName]="i" class="ratio-field">
              <p>Ratio {{ i + 1 }}</p>

              <div class="ratio-row-container">
                <div class="field" style="width: 33%;">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-select formControlName="item1" placeholder="Select a item">
                      <mat-option
                        *ngFor="let item of items"
                        [value]="item.id"
                      >
                        {{ item.NAME }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%;">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <input
                      placeholder="Quantity"
                      matInput
                      type="number"
                      id="quantity"
                      name="quantity"
                      formControlName="item1_quantity"
                    />
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%;">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <input
                      placeholder="Period Value"
                      matInput
                      type="number"
                      formControlName="period_value"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="ratio-row-container">
                <div class="field" style="width: 33%;">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-select formControlName="item2" placeholder="Select a item">
                      <mat-option
                        *ngFor="let item of items"
                        [value]="item.id"
                      >
                        {{ item.NAME }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%;">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <input
                      matInput
                      type="number"
                      name="item2_quantity"
                      value="1"
                      disabled
                    />
                  </mat-form-field>
                </div>
                <div class="field" style="width: 33%;">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-select formControlName="period_type" placeholder="Period type">
                      <mat-option
                        *ngFor="let periodType of periodTypes"
                        [value]="periodType.id"
                      >{{ periodType.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="btn">
          <button mat-raised-button (click)="addRatioForm()">{{'+ Add ratio'}}</button>
        </div>
      </div>
    </div>

    <!-- DISCOUNT -->
    <div class="setting__section" [formGroup]="discountsForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowDiscount"
          (click)="isShowDiscount = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowDiscount"
          (click)="isShowDiscount = false"
        >keyboard_arrow_down</mat-icon
        >
        Discount
      </div>
      <div class="content" *ngIf="isShowDiscount">
        <ng-container
          formArrayName="discounts"
          *ngFor="let discount of discounts.controls; index as i"
        >
          <div class="discount-container">
            <button
              type="button"
              mat-raised-button
              color="warn"
              (click)="removeDiscountField(i)"
              class="remove-btn"
            >
              x
            </button>
            <button
              type="button"
              mat-raised-button
              (click)="copyDiscountForm(discount)"
              class="remove-btn"
            >
              <mat-icon>file_copy</mat-icon>
            </button>
            <div [formGroupName]="i" class="discount-field">
              <div class="feature-field">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label> Discount {{ i + 1 }}</mat-label>
                  <input
                    matInput
                    id="discount"
                    name="discount"
                    formControlName="discount_value"
                  />
                </mat-form-field>
                <mat-form-field
                  style="width: 20%"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-select formControlName="discount_type" placeholder="Select a type">
                    <mat-option
                      *ngFor="let discType of discountTypes"
                      [value]="discType.id"
                    >
                      {{ discType.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="feature-field">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>Apply for</mat-label>
                  <input
                    matInput
                    formControlName="purchased_day"
                  />
                </mat-form-field>
                <p style="width: 20%;">days after purchased</p>
              </div>

              <p>Applies to:</p>

              <div class="field">
                <app-chips
                  [label]="'Account type it applies to'"
                  [form]="getFormControlFromArray('account_types', i)"
                  [options]="accountOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscAccountType($event, i)"
                ></app-chips>
              </div>
              <div class="field">
                <app-chips
                  [label]="'Region'"
                  [form]="getFormControlFromArray('regions', i)"
                  [options]="regionOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscRegion($event, i)"
                ></app-chips>
              </div>
              <div class="field">
                <app-chips
                  [label]="'Organization it applies to'"
                  [form]="getFormControlFromArray('organizations', i)"
                  [options]="organizationOptions"
                  [displayField]="'name'"
                  (optionChange)="onSelectDiscOrganization($event, i)"
                ></app-chips>
              </div>
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Valid to</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="valid_until"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <div class="btn">
          <button mat-raised-button (click)="addDiscountForm()">{{'+ Add discount'}}</button>
        </div>
      </div>
    </div>

    <!-- THRESHOLD -->
    <div class="setting__section" [formGroup]="thresholdForm">
      <div class="sub-title border-color">
        <mat-icon
          *ngIf="!isShowThreshold"
          (click)="isShowThreshold = true"
        >keyboard_arrow_right</mat-icon
        >
        <mat-icon
          *ngIf="isShowThreshold"
          (click)="isShowThreshold = false"
        >keyboard_arrow_down</mat-icon
        >
        Threshold Notification (%)
      </div>
      <div class="content" *ngIf="isShowThreshold">
        <table>
          <thead>
          <tr>
            <th *ngIf="isShowItemUser">Max User</th>
            <th *ngIf="isShowItemAthlete">Max Athletes</th>
            <th *ngIf="isShowItemLA">Max Lactate</th>
            <th *ngIf="isShowItemMap">Max PPD</th>
            <th *ngIf="isShowItemManual">Max Manual</th>
            <th *ngIf="isShowItemVirtual">Max Virtual</th>
            <th *ngIf="isShowItemEvent">Max Event</th>
            <th *ngIf="isShowItemResult">Max Result</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td *ngIf="isShowItemUser">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_user" matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemAthlete">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_athlete"  matInput />
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemLA">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_test_la"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemMap">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_test_map"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemManual">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_test_manual"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemVirtual">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_test_virtual"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemEvent">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_test_event"  matInput/>
                </mat-form-field>
              </div>
            </td>

            <td *ngIf="isShowItemResult">
              <div class="field">
                <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                  <input type="number" min="0" formControlName="notification_test_result"  matInput/>
                </mat-form-field>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div>
      <button
        id="cancelButton"
        mat-button
        (click)="cancel()"
        type="button">
        Cancel
      </button>
      <button
        id="saveButton"
        mat-raised-button
        color="primary"
        (click)="save()">
        {{data?.isEdit ? "Change" : "Add"}}
      </button>
    </div>
  </mat-dialog-actions>
</div>
