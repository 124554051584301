<div class="athlete-dialog__section">
  <h2 mat-dialog-title>Simulate Event - {{ data.id }}</h2>
  <mat-dialog-content>
    <div class="dialog-add-detail-form" [formGroup]="detailForm">
      <fieldset class="event-data-fieldset">
        <legend>Event/Athlete Data</legend>
        <div class="field">
          <mat-form-field class="full-width"
                          appearance="outline"
                          floatLabel="always">
            <mat-label>Description (*)</mat-label>
            <input type="text" matInput placeholder="" formControlName="description" />
            <mat-error *ngIf="
                detailForm?.controls?.description?.errors &&
                detailForm?.controls?.description?.touched">
              <div [innerHTML]="
                  detailForm.controls.description.errors | errorFormFormat: { fieldName: 'Description' }"></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field field-text">
          <mat-form-field appearance="outline"
                          floatLabel="always"
                          class="u-1/1">
            <mat-label>Athlete (*)</mat-label>
            <mat-select formControlName="athlete">
              <mat-option>
                <ngx-mat-select-search [formControl]="searchAthleteCtrl"
                                       placeholderLabel="Search Athletes"
                                       noEntriesFoundLabel="no matching athletes found"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let item of athletesFiltered"
                          [value]="item?.id"
                          (click)="getTestsBySelectedAthlete(item?.id)">
                {{ item?.first_name }} {{ item?.last_name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="
                detailForm?.controls?.athlete?.errors &&
                detailForm?.controls?.athlete?.touched">
              <div [innerHTML]="
                  detailForm.controls.athlete.errors | errorFormFormat: { fieldName: 'Athlete' }"></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field class="full-width"
                          appearance="outline"
                          floatLabel="always">
            <mat-label>Sport</mat-label>
            <input type="text"
                   matInput
                   placeholder=""
                   readonly
                   value="{{sport}}"/>
          </mat-form-field>
        </div>

        <div class="field field-text">
          <mat-form-field appearance="outline"
                          floatLabel="always"
                          class="full-width">
            <mat-label>Test (*)</mat-label>
            <mat-select formControlName="test">
              <mat-option *ngFor="let item of testOptions"
                          [value]="item?.id"
                          class="line-height-normal">
                <span>{{ item?.test_date }} </span><br />
                <span>ID: {{ item?.id }}, Type: {{ checkTestType(item?.test_type) }}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="
                detailForm?.controls?.test?.errors &&
                detailForm?.controls?.test?.touched">
              <div [innerHTML]="
                  detailForm.controls.test.errors | errorFormFormat: { fieldName: 'Test' }"></div>
            </mat-error>
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset class="event-data-fieldset">
        <legend>Simulation Parameters</legend>
        <div class="field">
          <mat-form-field appearance="outline"
                          floatLabel="always"
                          class="full-width"
          >
            <mat-label>Adapt load to DGATP </mat-label>
            <input hidden="true" matInput />
            <mat-checkbox (change)="showOptions($event)" labelPosition="before" formControlName="flag_forceDGATP"
            >Adapt load to DGATP</mat-checkbox
            >
          </mat-form-field>
        </div>
        <div class="field field-text">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>Model (*)</mat-label>
            <mat-select formControlName="example">
              <mat-option
                *ngFor="let item of modelTypes"
                [value]="item.id"
                (click)="modelOptionChanged(item.id)"
              >
                {{ item?.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                detailForm?.controls?.example?.errors &&
                detailForm?.controls?.example?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.example.errors
                    | errorFormFormat: { fieldName: 'Model' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field field-text">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>Glycolysis & Glycogenolysis Model (*)</mat-label>
            <mat-select formControlName="mod_glycPME">
              <mat-option *ngFor="let item of glyOptions" [value]="item?.id">
                {{ item?.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                detailForm?.controls?.mod_glycPME?.errors &&
                detailForm?.controls?.mod_glycPME?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.mod_glycPME.errors
                    | errorFormFormat
                      : { fieldName: 'Glycolysis & Glycogenolysis Model' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field field-text" *ngIf="showActOxydPhos">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>Activation of Oxyd Phosphorylation (*)</mat-label>
            <mat-select formControlName="act_oxyd_phos">
              <mat-option
                *ngFor="let item of actOxydPhosOptions"
                [value]="item?.id"
              >
                {{ item?.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                detailForm?.controls?.act_oxyd_phos?.errors &&
                detailForm?.controls?.act_oxyd_phos?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.act_oxyd_phos.errors
                    | errorFormFormat
                      : { fieldName: 'Activation of Oxyd Phosphorylation' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Simulation Step (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="stepsize"
            />
            <span matSuffix>s</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.stepsize?.errors &&
                detailForm?.controls?.stepsize?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.stepsize.errors
                    | errorFormFormat: { fieldName: 'Simulation step' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>DGL Solver: Relative Error (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="relerr"
            />
            <span matSuffix>s</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.relerr?.errors &&
                detailForm?.controls?.relerr?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.relerr.errors
                    | errorFormFormat
                      : { fieldName: 'DGL Solver: Relative Error' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>DGL Solver: Absolute Error (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="abserr"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.abserr?.errors &&
                detailForm?.controls?.abserr?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.abserr.errors
                    | errorFormFormat
                      : { fieldName: 'DGL Solver: Absolute Error' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ATP (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="atp_rest"
            />
            <span matSuffix>mmol/kg</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.atp_rest?.errors &&
                detailForm?.controls?.atp_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.atp_rest.errors
                    | errorFormFormat: { fieldName: 'ATP (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ADP (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="adp_rest"
            />
            <span matSuffix>mmol/kg</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.adp_rest?.errors &&
                detailForm?.controls?.adp_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.adp_rest.errors
                    | errorFormFormat: { fieldName: 'ADP (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>PCr (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="pcr_rest"
            />
            <span matSuffix>mmol/kg</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.pcr_rest?.errors &&
                detailForm?.controls?.pcr_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.pcr_rest.errors
                    | errorFormFormat: { fieldName: 'PCr (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Cr (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="cr_rest"
            />
            <span matSuffix>mmol/kg</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.cr_rest?.errors &&
                detailForm?.controls?.cr_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.cr_rest.errors
                    | errorFormFormat: { fieldName: 'Cr (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>PI (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="pi_rest"
            />
            <span matSuffix>mmol/kg</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.pi_rest?.errors &&
                detailForm?.controls?.pi_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.pi_rest.errors
                    | errorFormFormat: { fieldName: 'PI (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>AMP (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="amp_rest"
            />
            <span matSuffix>mmol/kg</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.amp_rest?.errors &&
                detailForm?.controls?.amp_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.amp_rest.errors
                    | errorFormFormat: { fieldName: 'AMP (initial value) (*)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>LaM (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="CLaM_rest"
            />
            <span matSuffix>mmol/l</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.CLaM_rest?.errors &&
                detailForm?.controls?.CLaM_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.CLaM_rest.errors
                    | errorFormFormat: { fieldName: 'LaM (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>LaB (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="CLaB_rest"
            />
            <span matSuffix>mmol/l</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.CLaB_rest?.errors &&
                detailForm?.controls?.CLaB_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.CLaB_rest.errors
                    | errorFormFormat: { fieldName: 'LaB (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>pH (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="pH_rest"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.pH_rest?.errors &&
                detailForm?.controls?.pH_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.pH_rest.errors
                    | errorFormFormat: { fieldName: 'pH (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>conz_F6MP (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="conz_F6MP_rest"
            />
            <span matSuffix>mmol/l</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.conz_F6MP_rest?.errors &&
                detailForm?.controls?.conz_F6MP_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.conz_F6MP_rest.errors
                    | errorFormFormat
                      : { fieldName: 'conz_F6MP (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>conz_PEP (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="conz_PEP_rest"
            />
            <span matSuffix>mmol/l</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.conz_PEP_rest?.errors &&
                detailForm?.controls?.conz_PEP_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.conz_PEP_rest.errors
                    | errorFormFormat: { fieldName: 'conz_PEP (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vO2 (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vo2_rest"
            />
            <span matSuffix>mmol/l/kgMM</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.vo2_rest?.errors &&
                detailForm?.controls?.vo2_rest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vo2_rest.errors
                    | errorFormFormat: { fieldName: 'vO2 (initial value' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vo2_passive_tissue (initial value) (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vo2_passive_tissue"
            />
            <span matSuffix>ml/min/kg</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.vo2_passive_tissue?.errors &&
                detailForm?.controls?.vo2_passive_tissue?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vo2_passive_tissue.errors
                    | errorFormFormat
                      : { fieldName: 'vo2_passive_tissue (initial value)' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset class="event-data-fieldset">
        <legend>Constants Dynamic</legend>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vent_est_slope (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vent_est_slope"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.vent_est_slope?.errors &&
                detailForm?.controls?.vent_est_slope?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vent_est_slope.errors
                    | errorFormFormat: { fieldName: 'vent_est_slope' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vent_est_offset (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vent_est_offset"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.vent_est_offset?.errors &&
                detailForm?.controls?.vent_est_offset?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vent_est_offset.errors
                    | errorFormFormat: { fieldName: 'vent_est_offset' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vo2_vent_slope (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vo2_vent_slope"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.vo2_vent_slope?.errors &&
                detailForm?.controls?.vo2_vent_slope?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vo2_vent_slope.errors
                    | errorFormFormat: { fieldName: 'vo2_vent_slope' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vo2_vent_offset (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vo2_vent_offset"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.vo2_vent_offset?.errors &&
                detailForm?.controls?.vo2_vent_offset?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vo2_vent_offset.errors
                    | errorFormFormat: { fieldName: 'vo2_vent_offset' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>base_pmf (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="base_pmf"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.base_pmf?.errors &&
                detailForm?.controls?.base_pmf?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.base_pmf.errors
                    | errorFormFormat: { fieldName: 'base_pmf' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>dGFakt_Mod1 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="dGFakt_Mod1"
            />

            <mat-error
              *ngIf="
                detailForm?.controls?.dGFakt_Mod1?.errors &&
                detailForm?.controls?.dGFakt_Mod1?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.dGFakt_Mod1.errors
                    | errorFormFormat: { fieldName: 'dGFakt_Mod1' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>dGFakt_Mod2 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="dGFakt_Mod2"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.dGFakt_Mod2?.errors &&
                detailForm?.controls?.dGFakt_Mod2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.dGFakt_Mod2.errors
                    | errorFormFormat: { fieldName: 'dGFakt_Mod2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>dGFakt (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="dGFakt"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.dGFakt?.errors &&
                detailForm?.controls?.dGFakt?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.dGFakt.errors
                    | errorFormFormat: { fieldName: 'dGFakt' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>DGOX (*)</mat-label>
            <input type="text" matInput placeholder="" formControlName="DGOX" />
            <mat-error
              *ngIf="
                detailForm?.controls?.DGOX?.errors &&
                detailForm?.controls?.DGOX?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.DGOX.errors
                    | errorFormFormat: { fieldName: 'DGOX' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>evo2 (*)</mat-label>
            <input type="text" matInput placeholder="" formControlName="evo2" />
            <mat-error
              *ngIf="
                detailForm?.controls?.evo2?.errors &&
                detailForm?.controls?.evo2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.evo2.errors
                    | errorFormFormat: { fieldName: 'evo2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>fakt_vssPME (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="fakt_vssPME"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.fakt_vssPME?.errors &&
                detailForm?.controls?.fakt_vssPME?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.fakt_vssPME.errors
                    | errorFormFormat: { fieldName: 'fakt_vssPME' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>free_CArest (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="free_CArest"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.free_CArest?.errors &&
                detailForm?.controls?.free_CArest?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.free_CArest.errors
                    | errorFormFormat: { fieldName: 'free_CArest' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>joule_pcreq (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="joule_pcreq"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.joule_pcreq?.errors &&
                detailForm?.controls?.joule_pcreq?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.joule_pcreq.errors
                    | errorFormFormat: { fieldName: 'joule_pcreq' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>kh_F6MP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="kh_F6MP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.kh_F6MP?.errors &&
                detailForm?.controls?.kh_F6MP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.kh_F6MP.errors
                    | errorFormFormat: { fieldName: 'kh_F6MP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>konv_lapcr (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="konv_lapcr"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.konv_lapcr?.errors &&
                detailForm?.controls?.konv_lapcr?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.konv_lapcr.errors
                    | errorFormFormat: { fieldName: 'konv_lapcr' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>pcr_mlo2 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="pcr_mlo2"
              (change)="pcrMlo2OptionChanged()"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.pcr_mlo2?.errors &&
                detailForm?.controls?.pcr_mlo2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.pcr_mlo2.errors
                    | errorFormFormat: { fieldName: 'pcr_mlo2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>konv_o2pcr (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="konv_o2pcr"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.konv_o2pcr?.errors &&
                detailForm?.controls?.konv_o2pcr?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.konv_o2pcr.errors
                    | errorFormFormat: { fieldName: 'konv_o2pcr' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_F6MPADP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_F6MPADP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_F6MPADP?.errors &&
                detailForm?.controls?.ks_F6MPADP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_F6MPADP.errors
                    | errorFormFormat: { fieldName: 'ks_F6MPADP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_F6MPadpCA (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_F6MPadpCA"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_F6MPadpCA?.errors &&
                detailForm?.controls?.ks_F6MPadpCA?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_F6MPadpCA.errors
                    | errorFormFormat: { fieldName: 'ks_F6MPadpCA' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_F6MPamp (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_F6MPamp"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_F6MPamp?.errors &&
                detailForm?.controls?.ks_F6MPamp?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_F6MPamp.errors
                    | errorFormFormat: { fieldName: 'ks_F6MPamp' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_F6MPampCOOP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_F6MPampCOOP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_F6MPampCOOP?.errors &&
                detailForm?.controls?.ks_F6MPampCOOP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_F6MPampCOOP.errors
                    | errorFormFormat: { fieldName: 'ks_F6MPampCOOP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_F6MPCa (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_F6MPCa"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_F6MPCa?.errors &&
                detailForm?.controls?.ks_F6MPCa?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_F6MPCa.errors
                    | errorFormFormat: { fieldName: 'ks_F6MPCa' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_lapH (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_lapH"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_lapH?.errors &&
                detailForm?.controls?.ks_lapH?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_lapH.errors
                    | errorFormFormat: { fieldName: 'ks_lapH' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_oxpi (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_oxpi"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_oxpi?.errors &&
                detailForm?.controls?.ks_oxpi?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_oxpi.errors
                    | errorFormFormat: { fieldName: 'ks_oxpi' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_pfkF6MP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_pfkF6MP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_pfkF6MP?.errors &&
                detailForm?.controls?.ks_pfkF6MP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_pfkF6MP.errors
                    | errorFormFormat: { fieldName: 'ks_pfkF6MP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_piPME (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_piPME"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_piPME?.errors &&
                detailForm?.controls?.ks_piPME?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_piPME.errors
                    | errorFormFormat: { fieldName: 'ks_piPME' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_vlaresADP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_vlaresADP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_vlaresADP?.errors &&
                detailForm?.controls?.ks_vlaresADP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_vlaresADP.errors
                    | errorFormFormat: { fieldName: 'ks_vlaresADP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_vlaresCLa (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_vlaresCLa"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_vlaresCLa?.errors &&
                detailForm?.controls?.ks_vlaresCLa?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_vlaresCLa.errors
                    | errorFormFormat: { fieldName: 'ks_vlaresCLa' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_vo2_Mod1 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_vo2_Mod1"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_vo2_Mod1?.errors &&
                detailForm?.controls?.ks_vo2_Mod1?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_vo2_Mod1.errors
                    | errorFormFormat: { fieldName: 'ks_vo2_Mod1' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_vo2 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_vo2"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_vo2?.errors &&
                detailForm?.controls?.ks_vo2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_vo2.errors
                    | errorFormFormat: { fieldName: 'ks_vo2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_vo2ADP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_vo2ADP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_vo2ADP?.errors &&
                detailForm?.controls?.ks_vo2ADP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_vo2ADP.errors
                    | errorFormFormat: { fieldName: 'ks_vo2ADP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks_vPMEres (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks_vPMEres"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks_vPMEres?.errors &&
                detailForm?.controls?.ks_vPMEres?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks_vPMEres.errors
                    | errorFormFormat: { fieldName: 'ks_vPMEres' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks2_laADP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks2_laADP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks2_laADP?.errors &&
                detailForm?.controls?.ks2_laADP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks2_laADP.errors
                    | errorFormFormat: { fieldName: 'ks2_laADP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks2_laAMP (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks2_laAMP"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks2_laAMP?.errors &&
                detailForm?.controls?.ks2_laAMP?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks2_laAMP.errors
                    | errorFormFormat: { fieldName: 'ks2_laAMP' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks2_pi (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks2_pi"
            />

            <mat-error
              *ngIf="
                detailForm?.controls?.ks2_pi?.errors &&
                detailForm?.controls?.ks2_pi?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks2_pi.errors
                    | errorFormFormat: { fieldName: 'ks2_pi' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>ks2_piPME (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="ks2_piPME"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.ks2_piPME?.errors &&
                detailForm?.controls?.ks2_piPME?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.ks2_piPME.errors
                    | errorFormFormat: { fieldName: 'ks2_piPME' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>laexp_pi (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="laexp_pi"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.laexp_pi?.errors &&
                detailForm?.controls?.laexp_pi?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.laexp_pi.errors
                    | errorFormFormat: { fieldName: 'laexp_pi' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>m2 (*)</mat-label>
            <input type="text" matInput placeholder="" formControlName="m2" />
            <mat-error
              *ngIf="
                detailForm?.controls?.m2?.errors &&
                detailForm?.controls?.m2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.m2.errors
                    | errorFormFormat: { fieldName: 'm2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>m3 (*)</mat-label>
            <input type="text" matInput placeholder="" formControlName="m3" />
            <mat-error
              *ngIf="
                detailForm?.controls?.m3?.errors &&
                detailForm?.controls?.m3?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.m3.errors
                    | errorFormFormat: { fieldName: 'm3' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>pH_mit (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="pH_mit"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.pH_mit?.errors &&
                detailForm?.controls?.pH_mit?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.pH_mit.errors
                    | errorFormFormat: { fieldName: 'pH_mit' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>phy_const (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="phy_const"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.phy_const?.errors &&
                detailForm?.controls?.phy_const?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.phy_const.errors
                    | errorFormFormat: { fieldName: 'phy_const' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>reduce_alpha1mV (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="reduce_alpha1mV"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.reduce_alpha1mV?.errors &&
                detailForm?.controls?.reduce_alpha1mV?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.reduce_alpha1mV.errors
                    | errorFormFormat: { fieldName: 'reduce_alpha1mV' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vlaresmax (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vlaresmax"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.vlaresmax?.errors &&
                detailForm?.controls?.vlaresmax?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vlaresmax.errors
                    | errorFormFormat: { fieldName: 'vlaresmax' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vpme_resmax (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vpme_resmax"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.vpme_resmax?.errors &&
                detailForm?.controls?.vpme_resmax?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vpme_resmax.errors
                    | errorFormFormat: { fieldName: 'vpme_resmax' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>zk_vo2 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="zk_vo2"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.zk_vo2?.errors &&
                detailForm?.controls?.zk_vo2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.zk_vo2.errors
                    | errorFormFormat: { fieldName: 'zk_vo2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>zk_vo2mod3 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="zk_vo2mod3"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.zk_vo2mod3?.errors &&
                detailForm?.controls?.zk_vo2mod3?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.zk_vo2mod3.errors
                    | errorFormFormat: { fieldName: 'zk_vo2mod3' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>vo2max_const (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="vo2max_const"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.vo2max_const?.errors &&
                detailForm?.controls?.vo2max_const?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.vo2max_const.errors
                    | errorFormFormat: { fieldName: 'vo2max_const' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>force_kjlow1 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="force_kjlow1"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.force_kjlow1?.errors &&
                detailForm?.controls?.force_kjlow1?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.force_kjlow1.errors
                    | errorFormFormat: { fieldName: 'force_kjlow1' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>force_kjlow0 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="force_kjlow0"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.force_kjlow0?.errors &&
                detailForm?.controls?.force_kjlow0?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.force_kjlow0.errors
                    | errorFormFormat: { fieldName: 'force_kjlow0' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Temperature (*)</mat-label>
            <input type="text" matInput placeholder="" formControlName="temp" />
            <span matSuffix>K</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.temp?.errors &&
                detailForm?.controls?.temp?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.temp.errors
                    | errorFormFormat: { fieldName: 'Temperature' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>slyke (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="slyke"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.slyke?.errors &&
                detailForm?.controls?.slyke?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.slyke.errors
                    | errorFormFormat: { fieldName: 'slyke' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>slyke1 (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="slyke1"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.slyke1?.errors &&
                detailForm?.controls?.slyke1?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.slyke1.errors
                    | errorFormFormat: { fieldName: 'slyke1' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>kel_ox (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="kel_ox"
            />
            <span matSuffix>mmol/l</span>
            <mat-error
              *ngIf="
                detailForm?.controls?.kel_ox?.errors &&
                detailForm?.controls?.kel_ox?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.kel_ox.errors
                    | errorFormFormat: { fieldName: 'kel_ox' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
      </fieldset></div
    ></mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button id="cancelButton" mat-button (click)="cancel()" type="button">
        Cancel
      </button>
      <button
        id="saveButton"
        mat-raised-button
        color="primary"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
