<div class="statistics-section">
  <table
    mat-table
    matSort
    #tableData
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    style="width: 100%"
    (matSortChange)="sortData($event)"
    matSortActive="id"
    matSortDirection="desc"
    matSortDisableClear
    aria-describedby="org-tab list">
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-column-id"
        scope="row">ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef scope="row">Active</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.is_active | translateBooleanText }}
      </td>
    </ng-container>
    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef scope="row">
        Users <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_user }} / {{ element?.max_user }}
      </td>
    </ng-container>
    <ng-container matColumnDef="athletes">
      <th mat-header-cell *matHeaderCellDef scope="row">
        Athletes <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_athlete }} / {{ element?.max_athlete }}
      </td>
    </ng-container>
    <ng-container matColumnDef="la">
      <th mat-header-cell *matHeaderCellDef scope="row">
        LA <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_test_la }} / {{ element?.max_test_la }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ppd">
      <th mat-header-cell *matHeaderCellDef scope="row">
        PPD <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_test_map }} / {{ element?.max_test_map }}
      </td>
    </ng-container>
    <ng-container matColumnDef="manual">
      <th mat-header-cell *matHeaderCellDef scope="row">
        Manual <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_test_manual }} / {{ element?.max_test_manual }}
      </td>
    </ng-container>
    <ng-container matColumnDef="virtual">
      <th mat-header-cell *matHeaderCellDef scope="row">
        Virtual <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_test_virtual }} / {{ element?.max_test_virtual }}
      </td>
    </ng-container>
    <ng-container matColumnDef="events">
      <th mat-header-cell *matHeaderCellDef scope="row">
        Events <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_test_event }} / {{ element?.max_test_event }}
      </td>
    </ng-container>
    <ng-container matColumnDef="results">
      <th mat-header-cell *matHeaderCellDef scope="row">
        Results <br />
        (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_test_result }} / {{ element?.max_test_result }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" colspan="11">No data available in table</td>
    </tr>
  </table>
  <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
    <mat-paginator
      [pageIndex]="pageIndex"
      (page)="getDataPage($event.pageIndex + 1)"
      [length]="totalItems"
      [pageSize]="pageSize">
    </mat-paginator>
  </div>
</div>
