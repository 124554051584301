import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationComponent } from './administration.component';
import { RightsManagementComponent } from './rights-management/rights-management.component';
import { RightsManagementDialogComponent } from './rights-management/rights-management-dialog/rights-management-dialog.component';
import { DeviceManagementComponent } from './device-management/device-management.component';
import { DeviceManagementDialogComponent } from './device-management/device-management-dialog/device-management-dialog.component';
import { TagsManagementComponent } from './tags-management/tags-management.component';
import { TagDialogComponent } from './tags-management/tag-dialog/tag-dialog.component';
import { InformationReadComponent } from './information-popup/information-read/information-read.component';
import { OrganizationCommonSettingComponent } from './organization-common-setting/organization-common-setting.component';
import { OrgTabDescriptionTextComponent } from './organization-common-setting/org-tab-description-text/org-tab-description-text.component';
import { OrgTabStatisticsComponent } from './organization-common-setting/org-tab-statistics/org-tab-statistics.component';
import { SportsComponent } from './sports/sports.component';
import { SportDetailComponent } from './sports/sport-detail/sport-detail.component';
import { AthleteCategoryComponent } from './sports/athlete-category/athlete-category.component';
import { TrainingZoneTemplateComponent } from './training-zone-template/training-zone-template.component';
import { TemplateBuilderDialogComponent } from './training-zone-template/template-builder/template-builder-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { ApiTrackingComponent } from './api-tracking/api-tracking.component';
import { AccountTypesComponent } from './account-types/account-types.component';
import { RegionSettingsComponent } from './region-settings/region-settings.component';
import { ProductsComponent } from './products/products.component';
import { ChangeRegionDialogComponent } from './region-settings/change-region-dialog/change-region-dialog.component';
import { ApiTrackingDialogComponent } from './api-tracking/api-tracking-dialog/api-tracking-dialog.component';
import { AddDialogComponent } from './region-settings/add-dialog/add-dialog.component';
import { InformationPopupComponent } from './information-popup/information-read/information-popup/information-popup.component';
import { PricingPlansComponent } from './pricing-plans/pricing-plans.component';
import { AddInformationDialogComponent } from './information-popup/information-read/information-popup/add-information-dialog/add-information-dialog.component';
import { PricingPlansDialogComponent } from './pricing-plans/pricing-plans-dialog/pricing-plans-dialog.component';
import { ManageOrganizationsComponent } from './manage-organizations/manage-organizations.component';
import { ManageOrganizationsDialogComponent } from './manage-organizations/manage-organizations-dialog/manage-organizations-dialog.component';
import { HistoryLogsComponent } from './history-logs/history-logs.component';
import { HistoryLogsDetailComponent } from './history-logs/history-logs-detail/history-logs-detail.component';
import { OrganizationStatisticsDialogComponent } from './manage-organizations/organization-statistics-dialog/organization-statistics-dialog.component';
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { GeneralSettingsComponent } from './system-settings/general-settings/general-settings.component';
import { AddProductDialogComponent } from './products/add-product-dialog/add-product-dialog.component';
import { OrganizationAnalyticsDialogComponent } from './manage-organizations/organization-analytics-dialog/organization-analytics-dialog.component';
import { ReportTemplateComponent} from './report-template/report-template.component';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { TemplateReportBuilderComponent } from './report-template/template-report-builder/template-report-builder.component';
import { AngularEditorModule } from "@kolkov/angular-editor";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextEditorComponent } from './report-template/text-editor/text-editor.component';
import { DeleteReportTemplateDialogComponent, ReportTemplatesListComponent } from './report-templates-list/report-templates-list.component';
import { CoursesComponent } from "./courses/courses.component";
import { EditCoursesDialogComponent } from "./courses/edit-courses-dialog/edit-courses-dialog.component";
import { PaymentLinkDialogComponent } from './pricing-plans/payment-link-dialog/payment-link-dialog.component';
import { ClonePlanDialogComponent } from './pricing-plans/clone-plan-dialog/clone-plan-dialog.component';

@NgModule({
  declarations: [
    AdministrationComponent,
    RightsManagementComponent,
    RightsManagementDialogComponent,
    DeviceManagementComponent,
    DeviceManagementDialogComponent,
    TagsManagementComponent,
    TagDialogComponent,
    InformationReadComponent,
    OrganizationCommonSettingComponent,
    OrgTabDescriptionTextComponent,
    OrgTabStatisticsComponent,
    SportsComponent,
    SportDetailComponent,
    AthleteCategoryComponent,
    TrainingZoneTemplateComponent,
    TemplateBuilderDialogComponent,
    ApiTrackingComponent,
    AccountTypesComponent,
    RegionSettingsComponent,
    ProductsComponent,
    ChangeRegionDialogComponent,
    ApiTrackingDialogComponent,
    AddDialogComponent,
    InformationPopupComponent,
    PricingPlansComponent,
    AddInformationDialogComponent,
    PricingPlansDialogComponent,
    ManageOrganizationsComponent,
    ManageOrganizationsDialogComponent,
    HistoryLogsComponent,
    HistoryLogsDetailComponent,
    OrganizationStatisticsDialogComponent,
    SystemSettingsComponent,
    GeneralSettingsComponent,
    AddProductDialogComponent,
    OrganizationAnalyticsDialogComponent,
    ReportTemplateComponent,
    TemplateReportBuilderComponent,
    TextEditorComponent,
    ReportTemplatesListComponent,
    DeleteReportTemplateDialogComponent,
    CoursesComponent,
    EditCoursesDialogComponent,
    PaymentLinkDialogComponent,
    ClonePlanDialogComponent,
  ],
  exports: [
    TextEditorComponent
  ],
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    KtdGridModule,
    DragDropModule,
    AngularEditorModule
  ]
})
export class AdministrationModule {}
