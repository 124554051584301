import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { IEconomyEfficiencySettings, IEconomyEfficiencySettingsUnit } from "@shared/interfaces/economy-efficiency-settings.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppConstants } from "@core/constants";
import { EconomyEfficientSettingsEnum } from "@shared/enums/economy-efficient-settings.enum";

@Component({
  selector: 'app-economy-efficiency-settings',
  templateUrl: './economy-efficiency-settings.component.html',
  styleUrls: ['./economy-efficiency-settings.component.scss']
})
export class EconomyEfficiencySettingsComponent implements OnInit {
  public fb: FormBuilder = inject(FormBuilder);
  public economyEfficientSettingsForm: FormGroup | undefined;
  public referenceOptionsList: Array<any> = [];

  @Input() public availableEconomyEfficientSettingsList: IEconomyEfficiencySettings[];
  @Input() public economyEfficientSettingsListFromResponse: IEconomyEfficiencySettings & IEconomyEfficiencySettingsUnit;
  @Output() public updateEconomyEfficientSettings: EventEmitter<any> = new EventEmitter<any>();

  public selectedItem: IEconomyEfficiencySettings | undefined;
  public economyEfficientSettingsSelection: IEconomyEfficiencySettings | undefined;

  public ngOnInit(): void {
    if (!this.economyEfficientSettingsListFromResponse) return;

    if (this.economyEfficientSettingsListFromResponse.name === EconomyEfficientSettingsEnum.CREATE_CUSTOM) {
      this.createEconomyEfficientSettingsForm();
      this.economyEfficientSettingsForm?.patchValue({
        unit_type: this.economyEfficientSettingsListFromResponse.unit_type,
        unit_value: this.economyEfficientSettingsListFromResponse.unit_value,
      })
    }

    this.referenceOptionsList = [...AppConstants.REFERENCE_SYSTEMS_SPEED, ...AppConstants.REFERENCE_SYSTEMS_POWER,];
    this.selectedItem = this.availableEconomyEfficientSettingsList.find((setting: IEconomyEfficiencySettings) => setting.id === this.economyEfficientSettingsListFromResponse.id);
  }

  public createEconomyEfficientSettingsForm(): void {
    this.economyEfficientSettingsForm = this.fb.group({
      unit_value: ['', Validators.required],
      unit_type: ['', Validators.required],
    });
  }

  public onUpdateSelection(item: IEconomyEfficiencySettings): void {
    this.economyEfficientSettingsSelection = item;

    if (item.name === EconomyEfficientSettingsEnum.CREATE_CUSTOM) {
      this.createEconomyEfficientSettingsForm();
    } else if (this.economyEfficientSettingsForm) {
      this.deactivateEconomyEfficientSettingsForm();
    }

    this.updateEconomyEfficientSettings.emit({ item});
  }

  public onUpdateUnitValueType(): void {
    if (this.unit_value && this.unit_type) {
      this.updateEconomyEfficientSettings.emit(
        {
          item:
            {
              ...this.economyEfficientSettingsSelection,
              unit_value: this.unit_value,
              unit_type: this.unit_type,
            },
          isValidForm: this.economyEfficientSettingsForm?.valid }
      );
    }
  }

  public deactivateEconomyEfficientSettingsForm(): void {
    this.economyEfficientSettingsForm = undefined;
  }

  public get unit_value(): string {
    return this.economyEfficientSettingsForm?.get('unit_value')?.value;
  }

  public get unit_type(): string {
    return this.economyEfficientSettingsForm?.get('unit_type')?.value;
  }
}
