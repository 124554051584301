import { Injectable } from '@angular/core';
import * as _ from "lodash-es";
import { AppConstants } from "@core/constants";

declare let Bokeh: any;

let BORDER_COLOR: string = '#444444';
let GRID_LINE_COLOR: string = '#444444';
let CHART_BORDER_FILL_COLOR: string = '#282929';
let CHART_BACKGROUND_FILL_COLOR: string = '#111111';
let LEGEND_BACKGROUND_FILL_ALPHA: number = 0.75;
let LABEL_TEXT_COLOR: string = '#aaa';
let CHART_TITLE_COLOR: string = '#ddd';
let LEGEND_BORDER_WIDTH: number = 0;


@Injectable({
  providedIn: 'root'
})
export class PerformanceDevelopmentChartPreviewService {
  public plottingAPI = Bokeh.Plotting;

  public updateColorTheme(currentTheme: string, isPdfPreview = false): void {
    if (currentTheme == AppConstants.THEME.LIGHT) {
      BORDER_COLOR = '#BBBBBB';
      GRID_LINE_COLOR = '#BBBBBB';
      CHART_BORDER_FILL_COLOR = isPdfPreview ? '#FFF' : '#FBFBFB';
      CHART_BACKGROUND_FILL_COLOR = isPdfPreview ? '#FFF' : '#FBFBFB';
      LABEL_TEXT_COLOR = '#444444';
      CHART_TITLE_COLOR = '#222222';
    } else {
      BORDER_COLOR = '#444444';
      GRID_LINE_COLOR = '#444444';
      CHART_BORDER_FILL_COLOR = isPdfPreview ? '#FFF' : '#282929';
      CHART_BACKGROUND_FILL_COLOR = isPdfPreview ? '#FFF' : '#111111';
      LABEL_TEXT_COLOR = '#aaa';
      CHART_TITLE_COLOR = '#ddd';
    }
  }

  public generatePerformanceDevelopmentChart(plot: any, elementId: any, testId: any, isPdf: boolean = false,): void {
    this.updateColorTheme(localStorage.getItem('theme')!);
    if (!plot) {
      return;
    }

    const TOOLS: boolean | string = isPdf ? false : 'pan,box_zoom';
    //// let line_colors = ['blue', 'red', 'green', 'magenta', 'lightblue', 'black'];

    let lineObject: { y_range_name: any } | undefined;

    let plotData: any;
    plotData = {
      plot_width: 1160,
      plot_height: 380,
      tools: TOOLS,
      x_axis_type: plot.x_axis_type,
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
      outline_line_color: BORDER_COLOR,
      sizing_mode: 'scale_width',
      toolbar_location: isPdf ? null : 'above'
    };

    this.handleXAxisRange(plotData, plot, testId); // IAT-2354 let x axis auto scaling
    this.handleYAxisRange(plotData, plot, testId);

    if (plot.extra_y_ranges) {
      plotData.extra_y_ranges = this.createExtraRange(plot.extra_y_ranges);
    }

    const y_extra_list: string[] = [];
    for (let key in plot.extra_y_ranges) y_extra_list.push(key.toString());

    let chart = this.plottingAPI.figure(plotData);
    // chart.yaxis.formatter = new Bokeh.FuncTickFormatter({
    //   code: `return convertNumberToString(tick)`,
    // });

    this.drawPerformanceDevelopment(plot, lineObject, chart, y_extra_list);

    if (plot.layouts && plot.layouts.length > 0) {
      _.each(plot.layouts, (res): void => {
        chart = this.createLayout(res, chart);
      });
    }

    let performanceXCallbackCode: string = 'let data = this.attributes.args.data; saveSettingsForPD(cb_obj);';
    // //let performanceCallbackCode = 'let data = this.attributes.args.data;';
    chart.x_range.callback = new Bokeh.CustomJS({
      code: performanceXCallbackCode,
      args: new Bokeh.ColumnDataSource({
        data: plot,
      }),
    });

    let performanceYCallbackCode: string = 'let data = this.attributes.args.data; saveYSettingsForPD(cb_obj);';
    chart.y_range.callback = new Bokeh.CustomJS({
      code: performanceYCallbackCode,
      args: new Bokeh.ColumnDataSource({
        data: plot,
      }),
    });

    chart.title.text = plot['title_text'];
    chart.title.align = 'center';
    chart.title.text_font_size = '12pt';
    chart.title.text_color = CHART_TITLE_COLOR;
    // Legend Styling
    chart._legend.location = 'top_left';
    chart._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
    chart._legend.border_line_width = LEGEND_BORDER_WIDTH;
    chart._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
    chart._legend.label_text_color = LABEL_TEXT_COLOR;
    chart.yaxis.axis_label = plot.y_axis_label;
    chart.yaxis.axis_label_text_font_size = '10pt';
    chart.yaxis.axis_label_text_font_style = 'normal';
    chart.yaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    chart.yaxis.major_label_text_color = LABEL_TEXT_COLOR
    chart.xaxis.axis_label_text_font_size = '10pt';
    chart.xaxis.axis_label_text_font_style = 'normal';
    chart.xaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    chart.xaxis.major_label_text_color = LABEL_TEXT_COLOR;

    if (chart.right) {
      _.each(chart.right, (right): void => {
        right.attributes.axis_label_text_font_size = '10pt';
        right.attributes.axis_label_text_font_style = 'normal';
        right.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
        right.attributes.major_label_text_color = LABEL_TEXT_COLOR;
      });
    }

    if (chart.left) {
      _.each(chart.left, (left): void => {
        left.attributes.axis_label_text_font_style = 'normal';
        left.attributes.axis_label_text_font_size = '10pt';
        left.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
        left.attributes.major_label_text_color = LABEL_TEXT_COLOR;
      });
    }

    //remove grid lines in chart
    // Grid Styling
    chart.xgrid.grid_line_color = GRID_LINE_COLOR;
    chart.ygrid.grid_line_color = GRID_LINE_COLOR;

    this.plottingAPI.show(chart, elementId);
  }

  private handleXAxisRange(plotData: any, plot: any, testId: any): void {
    let xVal: any = localStorage.getItem('PDsettings_' + testId);
    if (xVal) {
      xVal = JSON.parse(xVal);
      plotData.x_range = new Bokeh.Range1d({
        start: new Date(xVal.plot_range[0]).getTime(),
        end: new Date(xVal.plot_range[1]).getTime(),
      });
    } else {
      if (plot.is_one_day) {
        plotData.x_range = new Bokeh.Range1d({
          start: new Date(plot.x_range.plot_range[0]).getTime(),
          end: new Date(plot.x_range.plot_range[1]).getTime(),
        });
      }
    }
  }

  private handleYAxisRange(plotData: any, plot: any, testId: any): void {
    if (plot.y_range) {
      plotData.y_range = new Bokeh.Range1d({
        start: plot.y_range.plot_range[0],
        end: plot.y_range.plot_range[1],
      });
    }
  }

  public createExtraRange(range: any) {
    let extraRangeList: any = {};
    for (let range_name in range) {
      if (range.hasOwnProperty(range_name)) {
        extraRangeList[range_name] = new Bokeh.Range1d({
          start: range[range_name].plot_range[0],
          end: range[range_name].plot_range[1],
          bounds: range[range_name].bounds,
        });
      }
    }

    return extraRangeList;
  }

  private drawPerformanceDevelopment(plot: any, lineObject: any, chart: any, y_extra_list: any): void {
    const all_renderer: any[] = [];
    const all_legends: any[] = [];
    let legend, circleObject, source, tooltip, layout;

    if (plot.lines && plot.circles && plot.layouts && plot.lines.length > 0 && plot.circles.length > 0 && plot.layouts.length > 0 && plot.extra_tools.length > 0) {
      _.each(plot.lines, (line, index: string): void => {
        if (line.source.x) {
          line.source.x = line.source.x.map((res: string | number | Date) => {
            return new Date(res).getTime();
          });
        }

        source = new Bokeh.ColumnDataSource({data: line.source,});
        layout = plot.layouts[plot.layouts.length - 1];
        legend = layout.legends[index][0];
        tooltip = plot.extra_tools[index].tooltips;
        lineObject = chart.line({
          x: {
            field: 'x',
          },
          y: {
            field: 'y',
          },
          source: source,
          line_width: line.line_width,
          line_color: line.line_color,
          legend: legend,
        });
        //mysterious here for extra y-axis display
        if (y_extra_list.indexOf(line.y_range_name) != -1)
          lineObject.y_range_name = line.y_range_name;

        circleObject = chart.circle({
          x: {field: 'x',},
          y: {field: 'y',},
          source: source,
          color: line.line_color,
          fill_color: plot.circles[index].fill_color,
          legend: legend,
          size: plot.circles[index].size,
        });
        if (y_extra_list.indexOf(line.y_range_name) != -1)
          circleObject.y_range_name = line.y_range_name;

        all_renderer.push(circleObject);
        all_legends.push(
          new Bokeh.LegendItem({
            label: legend,
            renderers: [lineObject, circleObject],
          })
        );
        chart.add_tools(
          new Bokeh.HoverTool({
            renderers: [circleObject],
            tooltips: tooltip,
          })
        );
      });
    }
  }

  public createLayout(layout: any, chart: any) {
    for (let type in layout) {
      if (type != 'axis_position' && layout.hasOwnProperty(type)) {
        if (layout[type] != null) {
          switch (type) {
            case 'linear_axis':
              chart = this.createLinearAxis(
                layout[type],
                chart,
                layout['axis_position']
              );
              break;
          }
        }
      }
    }

    return chart;
  }

  public createLinearAxis(
    layout: {
      y_range_name: any;
      axis_label: any;
      axis_label_text_font_size: any;
    },
    chart: { add_layout: (arg0: any, arg1: any) => void },
    position: any
  ) {
    chart.add_layout(
      new Bokeh.LinearAxis({
        y_range_name: layout.y_range_name,
        axis_label: layout.axis_label,
        axis_label_text_font_size: layout.axis_label_text_font_size,
        formatter: new Bokeh.FuncTickFormatter({
          code: `return convertNumberToString(tick)`,
        })
      }),
      position
    );

    return chart;
  }
}
