<div id="training-zone-list">
  <div class="title">{{ "Training Zone" }}</div>
  <div class="group-actions mb-3">
    <button mat-raised-button color="primary" (click)="addTemplate()">
      {{ "Add Template" }}
    </button>
  </div>
  <table
    mat-table
    matSort
    #tableData
    [dataSource]="trainingZones"
    class="mat-elevation-z8"
    style="width: 100%"
    (matSortChange)="sortData($event)"
    aria-describedby="tranning zone list"
    matSortActive="id"
    matSortDirection="desc"
    matSortDisableClear
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-column-id"
        scope="row"
      >
        ID
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Description
      </th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>
    <ng-container matColumnDef="sport__name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Sports
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.sport.name }}</td>
    </ng-container>
    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Active
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.isActiveStr }}</td>
    </ng-container>
    <ng-container matColumnDef="is_default">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Default
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.isDefaultStr }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef scope="row">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          color="primary"
          (click)="editTemplate(element)"
          class="btn-icon-text"
        >
          <mat-icon>mode_edit</mat-icon> Edit
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="copyTemplate(element)"
          class="btn-icon-text"
        >
          <mat-icon>file_copy</mat-icon> Copy
        </button>
        <button
          mat-raised-button
          (click)="deleteItem(element.id)"
          class="btn-icon-text"
        >
          <mat-icon>delete</mat-icon> Delete
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" colspan="7">No data available in table</td>
    </tr>
  </table>
  <div class="paging-display mt-2" *ngIf="trainingZones.length > 0">
    <mat-paginator
      [pageIndex]="pageIndex"
      (page)="getDataPage($event.pageIndex + 1)"
      [length]="totalItems"
      [pageSize]="pageSize"
    ></mat-paginator>
  </div>
</div>
