import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class ProductResultDto extends PagedResultDto {
  public items: ProductDto[] = [];
}

export class ProductDto extends Deserializable {
  public account_types: number[] | undefined;
  public created_at: Date | undefined;
  public description: string | undefined;
  public discounts: DiscountDto[] | undefined;
  public duration: number | undefined;
  public features: string | undefined;
  public id: number | undefined;
  public is_delete: boolean | undefined;
  public is_publish: boolean | undefined;
  public is_show_setting: boolean | undefined;
  public max_athlete: number | undefined;
  public max_test_event: number | undefined;
  public max_test_la: number | undefined;
  public max_test_manual: number | undefined;
  public max_test_map: number | undefined;
  public max_test_result: number | undefined;
  public max_test_virtual: number | undefined;
  public max_user: number | undefined;
  public name: string | undefined;
  public organizations: number[] | undefined;
  public plan_bill_at: number | undefined;
  public plans: any[] | undefined;
  public prices: ProductPriceDto[] | undefined;
  public ratios: RatioDto[] | undefined;
  public regions: number[] | undefined;
  public right_management: string | undefined;
  public triggers: string | undefined;
  public updated_at: Date | undefined;
}

export class DiscountDto {
  public id: number | undefined;
  public discount_type: number | undefined;
  public discount_value: number | undefined;
  public valid_until: Date | undefined;
  public purchased_day: number | undefined;
  public plan: undefined;
  public product: number | undefined;
  public account_types: number[] | undefined;
  public regions: number[] | undefined;
  public organizations: number[] | undefined;
}

export class ProductPriceDto {
  public id: number | undefined;
  public price: number | undefined;
  public region: number | undefined;
  public product: number | undefined;
}

export class RatioDto {
  public id: number | undefined;
  public item1: number | undefined;
  public item1_quantity: number | undefined;
  public item2: number | undefined;
  public item2_quantity: number | undefined;
  public period_type: number | undefined;
  public period_value: number | undefined;
  public plan: undefined;
  public product: number | undefined;
}
