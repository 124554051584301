import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ErrorFormFormatPipe } from './pipes/error-form-format.pipe';
import { TranslateBooleanTextPipe } from './pipes/translate-boolean-text.pipe';
import { AthleteDetailDialogComponent } from './dialogs/athlete-detail-dialog/athlete-detail-dialog.component';
import { MaterialModule } from './angular-material-module/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OptimizationOptionDialogComponent } from './dialogs/optimization-option-dialog/optimization-option-dialog.component';
import { ThousandPipe } from './pipes/thousand.pipe';
import { DragFileDirective } from './directives/drag-file/drag-file.directive';
import { TestTypeFormat } from './pipes/test-type-format.pipe';
import { CommentFormatPipe } from './pipes/comment-format.pipe';
import { HeaderComponent } from './components/header/header.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ConvertNameAlternativePipe } from './pipes/convert-name-alternative.pipe';
import { ProgressBarUsageItemComponent } from './components/progress-bar-usage-item/progress-bar-usage-item.component';
import { PricePlanPeriodPipe } from './pipes/price-plan-period.pipe';
import { AutocomplateComponent } from './components/autocomplate/autocomplate.component';
import { ChipsComponent } from './components/chips/chips.component';
import { GrossEfficiencyPipe } from './pipes/gross-efficiency.pipe';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { ConvertPrimaryTypeToUnit } from './pipes/convert-primary-type-to-unit.pipe';
import { InvoiceBaseComponent } from './components/invoice-base/invoice-base.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { HasPermissionDirective } from './directives/permission/permission.directive';
import { CreateTeamDialogComponent } from './dialogs/create-team-dialog/create-team-dialog.component';
import { CreateEventDialogComponent } from './dialogs/create-event-dialog/create-event-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SwiperModule } from 'swiper/angular';
import { CdkTableModule } from '@angular/cdk/table';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { RouterModule } from '@angular/router';
import { CreateCompetitionDialogComponent } from './dialogs/create-competition-dialog/create-competition-dialog.component';
import { AccountTypeDialogComponent } from './dialogs/account-type-dialog/account-type-dialog.component';
import { SimulateEventDialogComponent } from './dialogs/simulate-event-dialog/simulate-event-dialog.component';
import { TestDialogComponent } from './dialogs/test-dialog/test-dialog.component';
import { MetabolicProfileComponent } from './components/charts/metabolic-profile/metabolic-profile.component';
import { DevelopmentOnlyGuardService } from "@shared/guards/development-only.guard";
import { TableComponent } from './components/table/table.component';
import { ItemCountPlanPeriodPipe } from './pipes/item-count-plan-period.pipe';
import { CypressHelperService } from "@shared/services/cypress-helper.service";
import { RangeSliderComponent } from "@shared/components/range-slider/range-slider.component";
import { MatSliderModule } from "@angular/material/slider";
import { PerformanceProjectionControlsService } from "@shared/services/performance-projection-controls/performance-projection-controls.service";
import { MaximizeComponent } from "@shared/components/performance-projection-maximize/maximize.component";
import { ConstraintsComponent } from "@shared/components/performance-projection-constraints/constraints.component";
import { MultipleXAxisChartComponent } from "@modules/home/multiple-x-axis-chart/multiple-x-axis-chart.component";
import { MaximizeConstraintService } from "@shared/services/maximize-constraint.service";
import {
  PerformanceProjectionMetricControlComponent
} from "@shared/components/performance-projection-metric-control/performance-projection-metric-control.component";
import { RightSideNavService } from "@shared/services/right-side-nav.service";
import { ActiveFeatureComponent } from "@shared/components/active-feature/active-feature.component";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { TileComponent } from "@shared/components/tile/tile.component";
import { TileService } from "@shared/services/tile/tile.service";
import { LabelComponent } from "@shared/components/label/label.component";
import { ChapterComponent } from "@shared/components/chapter/chapter.component";
import { LoadingHtmlComponent } from "@shared/components/loading-html/loading-html.component";
import { SearchComponent } from "@shared/components/search/search.component";
import { SearchService } from "@shared/services/search/search.service";
import { TabComponent } from './components/tab/tab.component';
import { TestimonialCardComponent } from './components/testimonial-card/testimonial-card.component';
import { ReferenceLinkComponent } from './components/reference-link/reference-link.component';
import { GeneralInfoListComponent } from './components/general-info-list/general-info-list.component';
import { UnorderedListComponent } from './components/unordered-list/unordered-list.component';
import { TryForFreeButtonComponent } from './components/try-for-free-button/try-for-free-button.component';
import { WaitListButtonComponent } from './components/wait-list-button/wait-list-button.component';
import { SuggestedCoursesComponent } from './components/suggested-courses/suggested-courses.component';
import { CardCourseCatalogComponent } from "@shared/components/card-course-catalog/card-course-catalog.component";
import {
  CoursePriceDiscountComponent
} from "@modules/courses/shared/components/course-price-discount/course-price-discount.component";
import { SuggestedFeaturesComponent } from "@shared/components/suggested-features/suggested-features.component";
import { RelatedVideosComponent } from "@shared/components/related-videos/related-videos.component";
import {
  PaymentSummaryDialogComponent
} from "@shared/components/payment-summary-dialog/payment-summary-dialog.component";
import { FeaturePriceListComponent } from './components/feature-price-list/feature-price-list.component';
import {
  UnorderedListWithPriceComponent
} from "@shared/components/unordered-list-with-price/unordered-list-with-price.component";
import { TotalPriceComponent } from './components/total-price/total-price.component';
import { FeatureMultiSelectComponent } from './components/feature-multi-select/feature-multi-select.component';
import { MyInscydComponent } from './components/my-inscyd/my-inscyd.component';
import { MyInscydFeatureItemResolver } from "@shared/resolvers/my-inscyd-feature-item.resolver";
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MyProfileService } from "@shared/services/my-profile/my-profile.service";
import { MyInscydService } from "@shared/services/my-inscyd/my-inscyd.service";
import { MyProfileItemResolver } from "@shared/resolvers/my-profile-item.resolver";
import { ProfileItemComponent } from './components/profile-item/profile-item.component';
import { MatIconRegistryService } from "@shared/services/mat-icon-registry/mat-icon-registry.service";
import { LoaderIndicatorComponent } from '@shared/components/loader-indicator/loader-indicator.component';
import { DeviceManagementOverviewComponent } from './components/device-management-overview/device-management-overview.component';
import { IntegrationListResolver } from "@shared/resolvers/integration-list.resolver";
import { AccountInfoComponent } from '@shared/components/account-info/account-info.component';
import { AccountInfoResolver } from "@shared/resolvers/account-info.resolver";
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { CurrentAccountService } from "@shared/services/current-account/current-account.service";
import { PersonalInfoResolver } from "@shared/resolvers/personal-info.resolver";
import { IntegrationItemComponent } from "@shared/components/integration-item/integration-item.component";
import { ChangePasswordDialogComponent } from './dialogs/change-password-dialog/change-password-dialog/change-password-dialog.component';
import { CurrentUserSessionComponent } from './components/current-user-session/current-user-session.component';
import { CurrentUserSessionResolver } from "@shared/resolvers/current-user-session.resolver";
import { DeactivateAccountDialogComponent } from './dialogs/deactivate-account-dialog/deactivate-account-dialog.component';
import { DeleteAccountDialogComponent } from './dialogs/delete-account-dialog/delete-account-dialog.component';
import { GeneralBackButtonComponent } from './components/general-back-button/general-back-button.component';
import { CustomBackButtonComponent } from './components/custom-back-button/custom-back-button.component';
import { UrlBuilderService } from "@shared/services/url-builder.service";
import { CustomAthleteCategoryComponent } from './components/custom-athlete-category/custom-athlete-category.component';
import { SpecificOrganizationGuardService } from "@shared/guards/specific-organization.guard";
import { SPECIFIC_LIVE_ORGANIZATION_TOKEN_LIST } from "@tokens/specific-ogranization-token-list.token";
import { LIVE_ORGANIZATION_ID_LIST, } from "@shared/constant-list/organization-id.constant";
import { ElementVisibilityDirective } from './directives/element-visibility/element-visibility.directive';
import { SportConfigurationComponent } from './components/sport-configuration/sport-configuration.component';
import { EconomyEfficiencySettingsComponent } from './components/economy-efficiency-settings/economy-efficiency-settings.component';
import { VisualizationComponent } from './components/visualization/visualization.component';
import { PerformanceLimitsComponent } from './components/performance-limits/performance-limits.component';
import { VisualizationService } from "@shared/services/visualization.service";
import { SportMetricVisualizationResolver } from "@shared/resolvers/sport-metric-visualization.resolver";
import { SportSelectionComponent } from './components/sport-selection/sport-selection.component';
import { MetricSelectionComponent } from './components/metric-selection/metric-selection.component';
import { MeterConfigurationComponent } from './components/meter-configuration/meter-configuration.component';
import { PreviewMeterComponent } from './components/preview-meter/preview-meter.component';
import { MetabolicConfiguratorComponent } from './components/metabolic-configurator/metabolic-configurator.component';
import { PreviewMetabolicFingerprintComponent } from './components/preview-metabolic-fingerprint/preview-metabolic-fingerprint.component';
import { AnimatedDropdownMenuComponent } from './components/animated-dropdown-menu/animated-dropdown-menu.component';
import { MetricService } from "@shared/services/metric.service";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const providers = [];
const components = [
  DateFormatPipe,
  ErrorFormFormatPipe,
  ThousandPipe,
  TranslateBooleanTextPipe,
  AthleteDetailDialogComponent,
  OptimizationOptionDialogComponent,
  TestTypeFormat,
  DragFileDirective,
  TestTypeFormat,
  CommentFormatPipe,
  HeaderComponent,
  TruncatePipe,
  ConvertNameAlternativePipe,
  ProgressBarUsageItemComponent,
  PricePlanPeriodPipe,
  AutocomplateComponent,
  ChipsComponent,
  GrossEfficiencyPipe,
  SnackbarComponent,
  ConvertPrimaryTypeToUnit,
  InvoiceBaseComponent,
  ConfirmDialogComponent,
  HasPermissionDirective,
  CreateTeamDialogComponent,
  CreateEventDialogComponent,
  ConfirmationDialogComponent,
  SidebarMenuComponent,
  CreateCompetitionDialogComponent,
  SimulateEventDialogComponent,
  AccountTypeDialogComponent,
  MetabolicProfileComponent,
  TableComponent,
  ItemCountPlanPeriodPipe,
  TestDialogComponent,
  RangeSliderComponent,
  MaximizeComponent,
  ConstraintsComponent,
  MultipleXAxisChartComponent,
  PerformanceProjectionMetricControlComponent,
  ActiveFeatureComponent,
  TileComponent,
  LabelComponent,
  ChapterComponent,
  LoadingHtmlComponent,
  SearchComponent,
  TabComponent,
  TestimonialCardComponent,
  ReferenceLinkComponent,
  GeneralInfoListComponent,
  UnorderedListComponent,
  TryForFreeButtonComponent,
  WaitListButtonComponent,
  SuggestedCoursesComponent,
  CardCourseCatalogComponent,
  CoursePriceDiscountComponent,
  SuggestedFeaturesComponent,
  RelatedVideosComponent,
  PaymentSummaryDialogComponent,
  FeaturePriceListComponent,
  UnorderedListWithPriceComponent,
  TotalPriceComponent,
  FeatureMultiSelectComponent,
  MyInscydComponent,
  MyProfileComponent,
  ProfileItemComponent,
  LoaderIndicatorComponent,
  DeviceManagementOverviewComponent,
  AccountInfoComponent,
  PersonalInfoComponent,
  IntegrationItemComponent,
  ChangePasswordDialogComponent,
  CurrentUserSessionComponent,
  DeactivateAccountDialogComponent,
  DeleteAccountDialogComponent,
  GeneralBackButtonComponent,
  CustomBackButtonComponent,
  CustomAthleteCategoryComponent,
  ElementVisibilityDirective,
  SportConfigurationComponent,
  EconomyEfficiencySettingsComponent,
  VisualizationComponent,
  PerformanceLimitsComponent,
  SportSelectionComponent,
  MetricSelectionComponent,
  MeterConfigurationComponent,
  PreviewMeterComponent,
  MetabolicConfiguratorComponent,
  PreviewMetabolicFingerprintComponent,
  AnimatedDropdownMenuComponent,
];

@NgModule({
  declarations: [...components],
  exports: [
    ...components,
    MaterialModule,
    InfiniteScrollModule,
    SwiperModule,
    CdkTableModule,
    CommonModule,
  ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        InfiniteScrollModule,
        SwiperModule,
        CdkTableModule,
        RouterModule,
        NgOptimizedImage,
        MatSliderModule,
    ],
  providers: [
    ConvertNameAlternativePipe,
    ErrorFormFormatPipe,
    CypressHelperService,
    PerformanceProjectionControlsService,
    FeatureStoreService,
    SearchService,
    CurrentAccountService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: SPECIFIC_LIVE_ORGANIZATION_TOKEN_LIST, useValue: LIVE_ORGANIZATION_ID_LIST },
    DevelopmentOnlyGuardService,
    SpecificOrganizationGuardService,
    MaximizeConstraintService,
    RightSideNavService,
    TileService,
    MyInscydFeatureItemResolver,
    MyProfileService,
    MyInscydService,
    MyProfileItemResolver,
    MatIconRegistryService,
    IntegrationListResolver,
    AccountInfoResolver,
    PersonalInfoResolver,
    CurrentUserSessionResolver,
    UrlBuilderService,
    VisualizationService,
    SportMetricVisualizationResolver,
    MetricService,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...providers],
    };
  }
}
