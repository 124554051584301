import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MODAL_WINDOW_TITLE } from "../enums/modal-window-title.enum";

@Component({
  selector: 'app-ramp-test-dialog',
  templateUrl: './ramp-test-dialog.component.html',
  styleUrls: ['./ramp-test-dialog.component.scss'],
})
export class RampTestDialogComponent implements OnInit {
  protected modalWindowTitle: MODAL_WINDOW_TITLE = MODAL_WINDOW_TITLE.POWER_ONLY_EFFORTS;
  protected wattForMale: number = 25;
  protected hintForMale: string = ' W increments every 30s';
  public form!: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<RampTestDialogComponent>, private fb: UntypedFormBuilder,) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    this.form = this.fb.group({
      average_power: [null, [Validators.required, Validators.min(0)]],
    });
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}
