import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { EntityDto } from '@shared/models/entity-dto';

export class OrganizationDto extends EntityDto {
  public id: number | undefined;
  public country: string | undefined;
  public api: any[] = [];
  public name: string | undefined;
  public photo: string | undefined;
  public is_active: boolean | undefined;
  public max_devices: number | undefined;
  public total_user: number | undefined;
  public max_user: number | undefined;
  public total_athlete: number | undefined;
  public max_athlete: number | undefined;
  public total_test_la: number | undefined;
  public max_test_la: number | undefined;
  public total_test_map: number | undefined;
  public max_test_map: number | undefined;
  public total_test_manual: number | undefined;
  public max_test_manual: number | undefined;
  public total_test_virtual: number | undefined;
  public max_test_virtual: number | undefined;
  public total_test_event: number | undefined;
  public max_test_event: number | undefined;
  public total_test_result: number | undefined;
  public max_test_result: number | undefined;
  public address_street_name: string | undefined;
  public address_street_number: string | undefined;
  public address_zip: number | undefined;
  public address_country_id: number | undefined;
  public phone: string | undefined;
  public email: string | undefined;
  public website: string | undefined;
  public invoice_address_city: string | undefined;
  public invoice_address_state: string | undefined;
  public is_solvent: boolean | undefined;
  public paid_method: string | undefined;
  public is_check_invoice: boolean | undefined;
  public created_at: string | undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
  public head: number | undefined;
  public account_type: number | undefined;

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  public clone(): OrganizationDto {
    return new OrganizationDto().deserialize(this);
  }
}

export class OrganizationResultDto extends PagedResultDto {
  public items: OrganizationDto[] = [];
}

export class OrganizationDefaultTextDto extends EntityDto {
  public body_composition: string | undefined;
  public comment: string | undefined;
  public created_at: string | undefined;
  public introduction: string | undefined;
  public metabolic_capacities: string | undefined;
  public metabolic_fingerprint: string | undefined;
  public metabolic_profile: string | undefined;
  public performance_development: string | undefined;
  public test_data: string | undefined;
  public training_zones: string | undefined;
  public updated_at: string | undefined;
  public heart_rate: string | undefined;
  public economy: string | undefined;
  public metabolic_power: string | undefined;
  public test_data_tables: string | undefined;
}
