<div class="page-container">
  <div class="top-toolbar">
    <div class="sub-title">SETTINGS</div>
    <div class="title">Integrations</div>
  </div>

  <div *ngIf="showAPIKeys()">
    <ng-container *ngIf="integrationObject?.key; else noApiKey">
      <app-integration-item [config]="integrationObject" [apiInfo]="apiInfo"></app-integration-item>
    </ng-container>
    <ng-template #noApiKey>
      <div class="u-text-align-center">
        There is no API Key
      </div>
    </ng-template>
  </div>

  <div *ngIf="showIntegration('cardioworld')">
    <app-integration-cardioworld
      [template]="getIntegrationTemplate('cardioworld')"
      [integration]="getIntegrationForTemplate('cardioworld')"
    ></app-integration-cardioworld>
  </div>

  <div *ngIf="showIntegration('vqv')">
    <app-integration-vqv
      [template]="getIntegrationTemplate('vqv')"
      [integration]="getIntegrationForTemplate('vqv')"
    ></app-integration-vqv>
  </div>
</div>