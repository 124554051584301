import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { HistoryLogDto, HistoryLogResultDto, IHistoryLogDetail } from './history-log-dto';
import { ApiRequest } from "@shared/models/api-request";
import { String } from "typescript-string-operations";

@Injectable({
  providedIn: 'root'
})
export class HistoryLogService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto | any): Observable<HistoryLogResultDto> {
    const apiUrl: string = this._appConstants.API.HISTORY_LOG.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new HistoryLogResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: HistoryLogDto) =>
            new HistoryLogDto().deserialize(item)
          )
        });
      })
    );
  }

  public getById(id: number): Observable<IHistoryLogDetail> {
    const apiUrl: string = String.Format( this._appConstants.API.HISTORY_LOG.GET, id);
    return this._apiService.get(apiUrl);
  }

}
