<div>
  <div class="title-with-search">
    <p>Account Types</p>
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input
        matInput
        placeholder="Team Name"
        [(ngModel)]="searchText"
        (keyup.enter)="search(searchText)"
      />
      <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
    </mat-form-field>
  </div>
  <div class="group-actions mb-3">
    <button
      *ngIf="rights.athletes_create"
      mat-raised-button
      color="primary"
      (click)="addAccount()"
      class="ml-4"
    >
      + Add
    </button>
  </div>
  <table
    mat-table
    matSort
    #tableData
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    style="width: 100%"
    (matSortChange)="sortData($event)"
    matSortActive="-id"
    matSortDirection="desc"
    matSortDisableClear
  >
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name of account</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Activity status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.is_active ? "YES" : "NO" }}
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          color="primary"
          (click)="editAccount(element)"
          class="btn-icon-text"
        >
          <mat-icon>mode_edit</mat-icon> Edit
        </button>
        <button
          mat-button
          class="btn-icon-text"
          (click)="deleteAccount(element.id)"
        >
          <mat-icon>delete</mat-icon> Delete
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" colspan="9">No data available in table</td>
    </tr>
  </table>

  <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
    <mat-paginator
      (page)="getDataPage($event.pageIndex + 1)"
      [length]="totalItems"
      [pageSize]="pageSize"
    >
    </mat-paginator>
  </div>
</div>
