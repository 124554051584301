import { EntityDto } from '@shared/models/entity-dto';

export class ZoneDto extends EntityDto {
  public index: number | undefined;
  public name: string | undefined;
  public code: string | undefined;
  public reference_system: string | undefined;
  public master_metric: string | undefined;
  public output_value: string | undefined;
  public template: any | undefined;
  public zone_input_respected_values: any | undefined;
}

export class TrainingZoneTemplateDto extends EntityDto {
  public id: number | undefined;
  public name: string | undefined;
  public description: string | undefined;
  public sport: any | undefined;
  public zones: ZoneDto[] = [];
  public is_active: boolean | undefined;
  public is_default: boolean | undefined;
  public is_public: boolean | undefined;
  public organization: number | undefined;

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  public clone(): TrainingZoneTemplateDto {
    return new TrainingZoneTemplateDto().deserialize(this);
  }
}
