import {ElementRef, Injectable} from '@angular/core';
import {AppConstants} from '@core/constants';
import {ChartConstants} from '@modules/apc/apc/data/chart-modules';
import {CustomRendererService} from "@shared/services/custom-renderer.service";
import * as _ from 'lodash-es';

declare let Bokeh: any, Chart: any;

let BORDER_COLOR: string = '#444444';
let GRID_LINE_COLOR: string = '#444444';
let CROSSHAIR_LINE_COLOR: string = '#888888';
let CHART_BORDER_FILL_COLOR: string = '#282929';
let CHART_BACKGROUND_FILL_COLOR: string = '#111111';
let PIE_CHART_BACKGROUND_FILL_COLOR: string = '#282929';
let LEGEND_BORDER_FILL_COLOR: string = '#282929';
let LEGEND_BACKGROUND_FILL_COLOR: string = '#111111';
let LEGEND_BACKGROUND_FILL_ALPHA: number = 0.75;
let LABEL_TEXT_COLOR: string = '#aaa';
let CHART_TITLE_COLOR: string = '#ddd';
let CHART_AXIS_LABEL_TEXT_COLOR: string = '#999999';
let LEGEND_BORDER_WIDTH: number = 0;
let CHART_FULL_WIDTH: number = 1160;
let CHART_HALF_WIDTH: number = 580;
let CHART_HEIGHT: number = 380;

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  public plottingAPI = Bokeh.Plotting;
  public isReport: boolean = true;
  public event: any = null;
  public power_types: number[] = [
    AppConstants.REFERENCE_SYSTEM.POWER_WATT,
    AppConstants.REFERENCE_SYSTEM.POWER_WATT_KG,
  ];
  public speed_types: number[] = [
    AppConstants.REFERENCE_SYSTEM.SPEED_MS,
    AppConstants.REFERENCE_SYSTEM.SPEED_KM_H,
    AppConstants.REFERENCE_SYSTEM.SPEED_MP_H,
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_KM,
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_MILE,
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_100M,
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_500M,
  ];
  public pace_types: number[] = [
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_KM,
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_MILE,
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_100M,
    AppConstants.REFERENCE_SYSTEM.SPEED_MIN_SEC_500M,
  ];

  constructor(private customRendererService: CustomRendererService) {}

  public ChartBokeh(args: any, report: boolean = false) {
    this.isReport = report || false;
    setTimeout((): void => {
      this.customRendererService.addClass('.bk-canvas-events', ['water-mark']);
      this.customRendererService.removeClass('#body_composition .bk-canvas-events', ['water-mark']);
    }, 200);

    if (args.isPdfPreview) {
      this.updateColorTheme(AppConstants.THEME.LIGHT, true);
    } else {
      this.updateColorTheme(localStorage.getItem('theme')!);
    }

    switch (args.type) {
      case 'body_composition':
        return this.generateBodyComposition(args.data, args.elementId, args.isPdfPreview);
      case 'metabolic_capacities':
        return this.generateMetabolicCapacities(args.data, args.elementId, args.test_type, args.isPdfPreview);
      case 'metabolic_profile':
        return this.generateMetabolicProfile(args.data, args.elementId, args.mappingData, args.testId, args.useDefault, args.test);
      case 'metabolic_fingerprint':
        return this.generateMetabolicFingerprintRadar(args.data, args.elementId,);
      case 'performance_development':
        return this.generatePerformanceDevelopment(args.data, args.elementId, args.testId, args.isPdfPreview);
      case 'speed_relationships':
        return this.generateRelationshipChart(args.data, args.elementId, args.test);
      case 'training_zones':
        return this.generateTrainingZones();
      case 'test_data':
        return this.generateTestData(args.data, args.elementId, args.test_type, args.testId, args.test, args.isPdfPreview);
      case 'crossing':
        return this.generateCrossing(args.data, args.elementId, args.mappingData, args.test);
      case 'dynamic':
        return args?.data?.length ? this.generateDynamicAnalyze(args.data, args.elementId, args.mappingData) : null;
      case 'experimental':
        return args?.data?.length ? this.generateDynamicExperimental(args.data, args.elementId, args.mappingData) : null;
      default:
        return null;
    }
  }

  public updateColorTheme(currentTheme: string, isPdfPreview = false): void {
    if (currentTheme == AppConstants.THEME.LIGHT) {
      BORDER_COLOR = '#BBBBBB';
      GRID_LINE_COLOR = '#BBBBBB';
      CROSSHAIR_LINE_COLOR = '#888888';
      CHART_BORDER_FILL_COLOR = isPdfPreview ? '#FFF' : '#FBFBFB';
      CHART_BACKGROUND_FILL_COLOR = isPdfPreview ? '#FFF' : '#FBFBFB';
      PIE_CHART_BACKGROUND_FILL_COLOR = isPdfPreview ? '#FFF' : '#FBFBFB';
      LEGEND_BORDER_FILL_COLOR = '#CCCCCC';
      LEGEND_BACKGROUND_FILL_COLOR = '#EEEEEE';
      LABEL_TEXT_COLOR = '#444444';
      CHART_TITLE_COLOR = '#222222';
      CHART_AXIS_LABEL_TEXT_COLOR = '#444444';
    } else {
      BORDER_COLOR = '#444444';
      GRID_LINE_COLOR = '#444444';
      CROSSHAIR_LINE_COLOR = '#888888';
      CHART_BORDER_FILL_COLOR = isPdfPreview ? '#FFF' : '#282929';
      CHART_BACKGROUND_FILL_COLOR = isPdfPreview ? '#FFF' : '#111111';
      PIE_CHART_BACKGROUND_FILL_COLOR = isPdfPreview ? '#FFF' : '#282929';
      LEGEND_BORDER_FILL_COLOR = '#282929';
      LEGEND_BACKGROUND_FILL_COLOR = '#111111';
      LABEL_TEXT_COLOR = '#aaa';
      CHART_TITLE_COLOR = '#ddd';
      CHART_AXIS_LABEL_TEXT_COLOR = '#999999';
    }
  }

  public isChartSettingChange(): boolean | "" | null {
    let isChanged: string | null = localStorage.getItem('chart_changed');

    return isChanged && isChanged == 'true';
  }

  public generateDynamicAnalyze(athleteData: string | any[], elementId: any,  mappingData: any): any[] {
    let legendData: any, plotReturn;
    let listPlots: any[] = [];
    let legends: any[] = [];

    _.each(athleteData, (data): void => {
      if (!data?.plots?.length) {
        return;
      }

      _.each(data.plots, (rowData: any, index: any): void => {
        let chart = {
          plot_width: CHART_HALF_WIDTH,
          plot_height: CHART_HEIGHT,
          tools: 'box_zoom,crosshair,reset,pan',
          x_axis_type: rowData['x_axis_type'],
          toolbar_location: 'above',
          background_fill_color: CHART_BACKGROUND_FILL_COLOR,
          border_fill_color: CHART_BORDER_FILL_COLOR,
          outline_line_color: BORDER_COLOR
        };
        let x_range = listPlots[index - 1]
          ? listPlots[index - 1].x_range
          : undefined;
        plotReturn = this.generateBokehPlot(rowData, chart, x_range);
        plotReturn._legend.location = !rowData['show_legend'] ? null : plotReturn._legend.location;

        let attr = {
          id: plotReturn.id,
          name: rowData.data_names[0],
          width: plotReturn.attributes.width,
          valueText: rowData['x_axis_label'],
          action: rowData['x_legend'],
        };

        legendData = this.createLegend(attr);

        if (!rowData?.data_names?.length) {
          return;
        }

        legendData.dataInfo = [];

        _.each(rowData.data_names, (name, idx: any): void => {
          const typeString: string = this.getTypeString(idx);

          legendData.dataInfo[idx] = {
            name: name,
            index: idx,
            typeString: typeString,
            typeClass: 'continued_line',
            lines: [],
          };

          this.handelLimitLine(rowData, idx, legendData, mappingData);
          this.handelLimitPatchs(rowData, idx, mappingData, legendData);
        });

        legends.push(legendData);
        listPlots.push(plotReturn);
      });
    });

    listPlots = this.createGridPlot(listPlots);
    let combinedChart = this.plottingAPI.gridplot(listPlots, {
      sizing_mode: 'scale_width',
    });

    this.plottingAPI.show(combinedChart, elementId);
    this.splitUpChartInfoLayout(elementId);

    return legends;
  }

  private getTypeString(idx: number): string {
    let typeString: string = '';
    switch (idx % 3) {
      case 0:
        typeString = '___';
        break;
      case 1:
        typeString = '---';
        break;
      case 2:
        typeString = '...';
        break;
    }

    return typeString;
  }

  public generateDynamicExperimental(athleteData: any, elementId: any, mappingData: any): any[] {
    let legendData: any, plotReturn;
    let listPlots: any[] = [];
    let legends: any[] = [];

    _.each(athleteData, (data): void => {
      if (!data?.plots?.length) {
        return;
      }
      _.each(data.plots, (rowData: any, index: any): void => {
        let chart = {
          plot_width: 900,
          plot_height: 500,
          tools: 'box_zoom,crosshair,reset,pan',
          x_axis_type: rowData['x_axis_type'],
          toolbar_location: 'above',
          background_fill_color: CHART_BACKGROUND_FILL_COLOR,
          border_fill_color: CHART_BORDER_FILL_COLOR,
          outline_line_color: BORDER_COLOR
        };

        let x_range = listPlots[index - 1] ? listPlots[index - 1].x_range : undefined;
        plotReturn = this.generateBokehPlot(rowData, chart, x_range);
        plotReturn._legend.location = !rowData['show_legend'] ? null : plotReturn._legend.location;

        let attr = {
          id: plotReturn.id,
          name: rowData.data_names[0],
          width: plotReturn.attributes.width,
          valueText: rowData['x_axis_label'],
          action: rowData['x_legend'],
        };

        legendData = this.createLegend(attr);

        if (!rowData?.data_names?.length) {
          return;
        }

        legendData.dataInfo = [];

        _.each(rowData.data_names, (name, idx: any) => {
          const typeString = this.getTypeString(idx);
          legendData.dataInfo[idx] = {
            name: name,
            index: idx,
            typeString: typeString,
            typeClass: 'continued_line',
            lines: [],
          };

          this.handelLimitLine(rowData, idx, legendData, mappingData);
          this.handelLimitPatchs(rowData, idx, mappingData, legendData);
        });
        legends.push(legendData);
        listPlots.push(plotReturn);
      });
    });

    listPlots = this.createGridPlot(listPlots);

    let combinedChart = this.plottingAPI.gridplot(listPlots, {
      sizing_mode: 'scale_width',
    });

    this.plottingAPI.show(combinedChart, elementId);
    this.splitUpChartInfoLayout(elementId);

    return legends;
  }

  private handelLimitLine(rowData: any, idx: any, legendData: any, mappingData: any): void {
    const limit_line: number = rowData.lines.length / rowData.data_names.length;
    _.each(rowData.lines, (lineLegend, idx_line: any) => {
      if (idx_line < (idx + 1) * limit_line && (idx_line >= idx * limit_line || idx == 0)) {
        let text: string = '';
        if (lineLegend.y_range_name == 'default') {
          text = rowData['y_axis_label'];
        } else {
          for (let row of rowData['layouts']) {
            if (row.linear_axis.y_range_name == lineLegend.y_range_name) {
              text = row.linear_axis.axis_label;
            }
          }
        }
        const infoData = this.createLegendInfo(
          legendData,
          lineLegend,
          mappingData,
          text
        );

        legendData.dataInfo[idx].lines.push(infoData);
      }
    });
  }

  private handelLimitPatchs(rowData: any, idx: any, mappingData: any, legendData: any): void {
    const limit_patches: number = rowData.patches.length / rowData.data_names.length;
    _.each(rowData.patches, (patch, idx_patch: any): void => {
      if (
        idx_patch < (idx + 1) * limit_patches &&
        (idx_patch >= idx * limit_patches || idx == 0)
      ) {
        let info: any[] = _.filter(mappingData, (res): boolean => {
          return res.name == patch.y_name;
        });
        let infoData = {
          id: info[0].name,
          legendText: info[0].legend,
          valueText: info[0].unit,
          style: {
            color: patch.fill_color,
          },
          type: 'solid',
        };

        legendData.dataInfo[idx].lines.push(infoData);
      }
    });
  }

  public generateCrossing(athleteData: any, elementId: any, mappingData: any, test: any): any[] {
    let legendData: any, plotReturn;
    let listPlots: any[] = [];
    let legends: any[] = [];

    _.each(athleteData, (data): void => {
      if (!data?.plots?.length) {
        return;
      }
      _.each(data.plots, (rowData: any, index: any): void => {
        let chart = {
          plot_width: CHART_HALF_WIDTH,
          plot_height: CHART_HEIGHT,
          tools: 'pan,box_zoom,reset',
          x_axis_type: rowData['x_axis_type'],
          toolbar_location: 'above',
          background_fill_color: CHART_BACKGROUND_FILL_COLOR,
          border_fill_color: CHART_BORDER_FILL_COLOR,
          outline_line_color: BORDER_COLOR,
        };

        if (!rowData?.lines?.length) {
          return;
        }

        let x_range = listPlots[index - 1] ? listPlots[index - 1].x_range : undefined;
        let extra_x_ranges = listPlots[index - 1] ? listPlots[index - 1].extra_x_ranges : undefined;

        if (index == 3) {
          plotReturn = this.generateBokehPlotMPFatChart(rowData, chart, x_range, null, null, null, null, test, extra_x_ranges, false);
        } else {
          plotReturn = this.generateBokehPlot(rowData, chart, x_range, null, null, null, null, test, extra_x_ranges, false);
        }

        plotReturn.add_tools(
          new Bokeh.CrosshairTool({
            line_color: CROSSHAIR_LINE_COLOR,
          })
        );

        let attr = {
          id: plotReturn.id,
          name: rowData.data_names[0],
          width: plotReturn.attributes.width,
          valueText: rowData['x_axis_label'],
          action: rowData['x_legend'],
        };

        legendData = this.createLegend(attr);

        if (!rowData?.data_names?.length) {
          return;
        }

        const same_legend_lines: any[] = this.getSameLegendLine(index, rowData);

        legendData.dataInfo = [];
        this.handelDrawCrossing(rowData, legendData, same_legend_lines, mappingData);
        legends.push(legendData);
        listPlots.push(plotReturn);
      });
    });

    listPlots = this.createGridPlot(listPlots);
    let combinedChart = this.plottingAPI.gridplot(listPlots, {
      sizing_mode: 'scale_width',
    });

    this.plottingAPI.show(combinedChart, elementId);
    this.splitUpChartInfoLayout(elementId);

    return legends;
  }

  private getSameLegendLine(index: any, rowData: any): any[] {
    let same_legend_lines: any[];
    if (index != 3) {
      same_legend_lines = _.filter(rowData.lines, (line): boolean => {
        return line.legend != null;
      });
      same_legend_lines = _.uniqBy(
        same_legend_lines,
        (line: { legend: any }) => {
          return line.legend;
        }
      );
    } else {
      same_legend_lines = _.uniqBy(rowData.lines, (line: { legend: any }) => {
        return line.legend;
      });
    }

    return same_legend_lines;
  }

  private handelDrawCrossing(rowData: any, legendData: any, same_legend_lines: any, mappingData: any): void {
    _.each(rowData.data_names, (name, idx: any): void => {
      const typeString: string = this.getTypeString(idx);
      legendData.dataInfo[idx] = {
        name: name,
        index: idx,
        typeString: typeString,
        typeClass: 'continued_line',
        lines: [],
      };
      _.each(same_legend_lines, (lineLegend): void => {
        let text: string = '';
        if (lineLegend.y_range_name == 'default') {
          text = rowData['y_axis_label'];
        } else {
          for (let row of rowData['layouts']) {
            if (row.linear_axis.y_range_name == lineLegend.y_range_name) {
              text = row.linear_axis.axis_label;
            }
          }
        }
        let infoData = this.createLegendInfo(legendData, lineLegend, mappingData, text);

        legendData.dataInfo[idx].lines.push(infoData);
      });
    });
  }

  public generateTrainingZones(): void {
    // $(elementId).html(data.table_data);
    console.error('If Training Zone doesnot work, uncomment this code');
  }

  public generateBodyComposition(athleteData: { plots: string | any[] }, elementId: any, isPdf: boolean): void {
    if (athleteData.plots && athleteData.plots.length > 0) {
      const pieChart = this.drawPieChart(athleteData.plots[0], isPdf);
      const combinedChart = this.plottingAPI.gridplot(
        [
          [pieChart], // Hide barChart
        ],
        {
          toolbar_location: null,
          sizing_mode: 'scale_width',
        }
      );
      this.plottingAPI.show(combinedChart, elementId);
    }
  }

  public drawPieChart(plot: any, isPdf: boolean) {
    let pieChart: any;
    if (plot && plot.wedges && plot.wedges.length > 0) {
      // create range for chart
      let xdr = null;
      let ydr = null;

      if (plot.x_range && plot.x_range.length > 1) {
        xdr = Bokeh.Range1d(plot.x_range[0], plot.x_range[1]);
      }
      if (plot.y_range && plot.y_range.length > 1) {
        ydr = Bokeh.Range1d(plot.y_range[0], plot.y_range[1]);
      }

      //Create a raw plot chart with default width,height and range
      pieChart = this.plottingAPI.figure({
        plot_width:
        AppConstants.METABOLIC_PROFILE.BODY_COMPOSITION.PIE_CHART.WIDTH,
        plot_height:
        AppConstants.METABOLIC_PROFILE.BODY_COMPOSITION.PIE_CHART.HEIGHT,
        x_range: xdr,
        y_range: ydr,
        tools: false,
        background_fill_color: PIE_CHART_BACKGROUND_FILL_COLOR,
        border_fill_color: CHART_BORDER_FILL_COLOR,
        outline_line_color: null,
        sizing_mode: 'scale_width',
      });

      if (pieChart) {
        //Convert to a BokehJS API param object
        let wedgeParams;
        _.each(plot.wedges, (wedge): void => {
          wedgeParams = {
            x: wedge.x,
            y: wedge.y,
            radius: wedge.radius,
            start_angle: wedge.start_angle,
            end_angle: wedge.end_angle,
            color: wedge.color,
            legend: wedge.legend,
          };

          pieChart.wedge(wedgeParams);
        });

        //Reset legend position and orientation for first chart
        pieChart._legend.orientation = 'horizontal';
        pieChart._legend.location = 'bottom_left';
        pieChart._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
        pieChart._legend.border_line_width = LEGEND_BORDER_WIDTH;
        pieChart._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
        pieChart._legend.label_text_color = LABEL_TEXT_COLOR;
        if (isPdf) {
          pieChart._legend.label_text_font_size = '10px';
          pieChart._legend.padding = 4;
        }

        //Hide both chart's axis
        pieChart.xaxis.visible = false;
        pieChart.yaxis.visible = false;
        //Reset min border value
        // pieChart.min_border = 20;

        //remove grid lines in chart
        pieChart.xgrid.grid_line_color = null;
        pieChart.ygrid.grid_line_color = null;
      }
    }

    return pieChart;
  }

  public createLineChart(myCanvas: ElementRef<HTMLCanvasElement>, payload: any) {
    let lineColors: any = {
      user: '#4dc9f6',
      athlete: '#f67019',
      lactate: '#f53794',
      ppd: '#537bc4',
      manual: '#acc236',
      virtual: '#166a8f',
      event: '#00a950',
      result: '#58595b',
    };
    let datasets: any[] = [];
    let min: number = 0;
    let max: number = 0;
    let space: number = 10;
    _.forEach(payload.details, function (detail): void {
      datasets.push({
        label: detail.label,
        data: detail.data,
        fill: false,
        borderColor: lineColors[detail.label],
        backgroundColor: lineColors[detail.label],
      });
      _.forEach(detail.data, function (value): void {
        min = value;
        max = value;
        if (min > value) {
          min = value;
        }
        if (max < value) {
          max = value;
        }
      });
    });

    let lineConfig = {
      type: 'line',
      data: {
        labels: payload.labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: '',
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                suggestedMin: min + space,
                suggestedMax: max + space,
              },
            },
          ],
        },
      },
    };

    let lineCtx: CanvasRenderingContext2D | null = myCanvas.nativeElement.getContext('2d');

    return new Chart(lineCtx, lineConfig);
  }

  public createBarChart(myCanvas: ElementRef<HTMLCanvasElement>, payload: any) {
    let barColors: any = {
      User: '#4dc9f6',
      Athlete: '#f67019',
      Lactate: '#f53794',
      PPD: '#537bc4',
      Manual: '#acc236',
      Virtual: '#166a8f',
      Event: '#00a950',
      Result: '#58595b',
    };
    let datasets: any[] = [];
    _.forEach(payload.details, function (detail): void {
      datasets.push({
        label: detail.label,
        data: detail.data,
        borderWidth: 1,
        yAxisID: detail.position,
        backgroundColor: barColors[detail.label],
      });
    });
    let barChartData = {
      labels: payload.labels,
      datasets: datasets,
    };
    let barCtx: CanvasRenderingContext2D | null = myCanvas.nativeElement.getContext('2d');

    return new Chart(barCtx, {
      type: 'bar',
      data: barChartData,
      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: '',
        },
        scales: {
          yAxes: [
            {
              id: 'LEFT',
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
            },
            {
              id: 'RIGHT',
              position: 'right',
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  public drawChartBySquad(arrayBarParams: any, barChart: any) {
    // Using algorithm to re calculate the ordering of value of array Bar chart params
    // Reason for using this due to different method used to draw chart between Bokeh python
    // ( using Bar() method which is not inherritted here in BokehJS version, so we use quad() method instead)
    let left: number = 1,
      right: number = 4,
      bottom: number = 0,
      top: number = 0,
      lastBottom: number,
      lastTop: number;

    _.each(arrayBarParams, (bar, i: any): void => {
      top = bar.value;
      if (i == arrayBarParams.length - 1) {
        top = 100;
        bottom = lastTop;
      } else {
        const value = this.handleGetTopAndBottomSquad(
          arrayBarParams,
          i,
          top,
          lastTop,
          bar,
          lastBottom
        );
        top = value.top1;
        bottom = value.bottom;
      }

      lastBottom = bottom;
      lastTop = top;

      let source = new Bokeh.ColumnDataSource({
        data: {
          top: [top],
          bottom: [bottom == 0 ? 0 : bottom + 0.5],
          left: [left],
          right: [right],
        },
      });

      barChart.quad({
        top: {
          field: 'top',
        },
        bottom: {
          field: 'bottom',
        },
        left: {
          field: 'left',
        },
        right: {
          field: 'right',
        },
        source: source,
        color: bar.color,
        legend: bar.legend,
      });
    });

    return barChart;
  }

  private handleGetTopAndBottomSquad(arrayBarParams: any, i: number, top: any, lastTop: any, bar: any, lastBottom: any) {
    let top1, bottom;
    top1 = top;
    if (top < 0 && lastBottom) {
      if (lastBottom == 0) {
        bottom = _.clone(top);
        top1 = 0;
      } else if (lastBottom != 0) {
        top1 = lastBottom;
        bottom = bar.value;
      }
    } else if (top > 0) {
      if (arrayBarParams[i + 1].value) {
        bottom = i == 0 ? 0 : lastTop;
        top1 = i == 0 ? top : top + lastTop;
      } else {
        bottom = 0;
      }
    }

    return {top1, bottom};
  }

  public generateMetabolicCapacities(athleteData: any, elementId: any, test_type: any, isPdf: boolean): void {
    if (athleteData?.plots?.length) {
      let plotChart: any[][] = [];
      let objResize = this.calculateAspectRatioFit(
        900,
        200,
        document.getElementById('metabolic-profiling')!.offsetWidth / 2 - 50,
        document.getElementById('metabolic-profiling')!.offsetHeight
      );

      let isResize: boolean = false;
      //resize plot plot_width, plot_height to fit screen
      if (objResize.width >= 800 && !this.isReport) {
        isResize = true;
        const order: string[] = ['vo2max', 'fatmax', 'vlamax', 'carbmax', 'at'];
        athleteData.plots = _.sortBy(athleteData.plots, (obj) => {
          return _.indexOf(order, obj.type);
        });
      } else {
        isResize = false;
        objResize = {
          width: 900,
          height: 200,
        };
      }

      _.each(athleteData.plots, (plotData): void => {
        const plot = this.generateMetabCapPlots(plotData, objResize);
        this.handelAddColumn(plotData, plot);
        if (plotData?.circles?.length) {
          _.each(plotData.circles, (circle): void => {
            const source = new Bokeh.ColumnDataSource({
              data: circle.source,
            });

            plot.circle({
              x: {
                field: circle.x,
              },
              y: {
                field: circle.y,
              },
              size: 10,
              source: source,
            });
          });
        }

        //remove grid lines in chart
        plot.xgrid.grid_line_color = null;
        plot.ygrid.grid_line_color = null;
        plot.title.render_mode = 'css';

        if (isResize) {
          plotChart.push(plot);
        } else {
          plotChart.push([plot]);
        }
      });

      // //plotChart[0].title.text ="VO<sub>2max</sub> - aerobic capacity";
      // //plotChart[1].title.text ="VLa<sub>max</sub> - anaerobic capacity";
      if (isResize) {
        plotChart = this.createGridPlot(plotChart);
      }

      let combinedChart = this.plottingAPI.gridplot(plotChart, {});
      this.plottingAPI.show(combinedChart, elementId);
    }
  }

  private handelAddColumn(plotData: any, plot: any): void {
    if (plotData?.layouts?.length) {
      let layoutIndex: number = 0;
      _.each(plotData.layouts, (layout) => {
        const label_set = layout.label_set;
        const columnObject = this.generateColumnSource(layoutIndex, label_set);
        if (columnObject.source) {
          plot.add_layout(columnObject.labels);
        }
        layoutIndex++;
      });
    }
  }

  public getCookie(cname: string): string {
    let name: string = cname + '=';
    let ca: string[] = document.cookie.split(';');
    for (let item of ca) {
      let c: string = item.trim();
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }

    return '';
  }

  public generateMetabCapPlots(plotData: any, data: any) {
    let xdr = new Bokeh.Range1d({start: -10, end: 160,});
    let ydr = Bokeh.Range1d(0, 60);
    let plot = this.plottingAPI.figure({
      plot_width: data.width,
      plot_height: data.height,
      x_range: xdr,
      y_range: ydr,
      tools: false,
    });

    plot.xaxis.visible = false;
    plot.yaxis.visible = false;
    plot.image_url({url: './assets/images/grad_red_green.png', x: 0, y: 40, h: 20, w: 100, global_alpha: 0.5,});
    plot.title.text = plotData.title_text;
    plot.title.align = 'center';
    plot.title.text_font_size = '12pt';

    return plot;
  }

  public generateColumnSource(index: number, setData: any) {
    if (index == 0) {
      setData.source.y = setData.source.y.map((val: number) => {
        return val + 5;
      });
    }

    let chartSource = new Bokeh.ColumnDataSource({
      data: setData.source,
    });
    let labels = new Bokeh.LabelSet({
      x: {field: setData.x,},
      y: {field: setData.y,},
      source: chartSource,
      text: {field: setData.text,},
      level: setData.level,
      x_offset: setData.x_offset,
      y_offset: setData.y_offset,
      render_mode: setData.render_mode,
      text_baseline: setData.text_baseline,
      text_font_size: setData.text_font_size,
    });

    return {
      source: chartSource,
      labels: labels,
    };
  }

  public generateMetabolicProfile(athleteData: any, elementId: any, mappingData: any, testId: any, useDefault: any, test: any): any[] {
    let legendData: any, plotReturn;
    let listPlots: any[] = [];
    let legends: any[] = [];
    let data = athleteData;
    let chart = {
      plot_width: CHART_HALF_WIDTH,
      plot_height: CHART_HEIGHT,
      tools: 'pan,box_zoom,wheel_zoom,crosshair',
      x_axis_type: athleteData['x_axis_type'],
      toolbar_location: 'above',
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
      outline_line_color: BORDER_COLOR,
    };

    _.each(data.plots, (rowData, index: any): void => {
      let x_range = listPlots[index - 1] ? listPlots[index - 1].x_range : undefined;
      let extra_x_ranges = listPlots[index - 1] ? listPlots[index - 1].extra_x_ranges : undefined;
      if (index === 5 || index === 4) {
        let min_data: number = Math.min(...rowData.data_df.x);
        let max_data: number = Math.max(...rowData.data_df.x);
        x_range = [min_data, max_data];
        extra_x_ranges = {
          primary: new Bokeh.Range1d({
            start: min_data,
            end: max_data,
          }),
          secondary: new Bokeh.Range1d({
            start: min_data,
            end: max_data,
          }),
        };
      }

      if (index == 3) {
        plotReturn = this.generateBokehPlotMPFatChart(rowData, chart, x_range, 'metabolic_profile', testId, useDefault, index, test, extra_x_ranges);
      } else {
        plotReturn = this.generateBokehPlot(rowData, chart, x_range, 'metabolic_profile', testId, useDefault, index, test, extra_x_ranges);
      }

      plotReturn.add_tools(
        new Bokeh.CrosshairTool({
          line_color: CROSSHAIR_LINE_COLOR,
        })
      );

      let attr = {
        id: plotReturn.id,
        name: rowData.data_names[0],
        width: plotReturn.attributes.width,
        valueText: rowData['x_axis_label'],
        action: rowData['x_legend'],
      };

      // Callback for x range
      let xRangeCallbackCode: string = 'let data = this.attributes.args.data; saveSettingsForMP(data, cb_obj);';
      plotReturn.x_range.callback = new Bokeh.CustomJS({
        code: xRangeCallbackCode,
        args: new Bokeh.ColumnDataSource({
          data: rowData.data_df,
        }),
      });

      // Callback for y range
      let yRangeCallbackCode: string = 'let data = this.attributes.args.chart_index; saveYSettingsForMP(data, cb_obj);';
      plotReturn.y_range.callback = new Bokeh.CustomJS({
        code: yRangeCallbackCode,
        args: {
          chart_index: index,
        },
      });

      // Callback for extra y ranges (only for MPFatChart)
      if (index == 3 && plotReturn?.extra_y_ranges?.y1) {
        let extraYRangeCallbackCode: string = 'let data = this.attributes.args.chart_index; saveExtraYSettingsForMP(data, "y1", cb_obj);';
        plotReturn.extra_y_ranges.y1.callback = new Bokeh.CustomJS({
          code: extraYRangeCallbackCode,
          args: {
            chart_index: index,
          },
        });
      }

      legendData = this.createLegend(attr);
      if (rowData?.data_names?.length) {
        legendData.dataInfo = [];
        if (elementId.indexOf('_rp') == -1) {
          legendData = this.generateLegendInfoTag(
            rowData,
            legendData,
            mappingData
          );
        }
      }

      if (
        rowData.data_df.power &&
        rowData.data_df.power[0] == null &&
        test.sport.simulation_type == AppConstants.SIMULATE_TYPE.SPEED &&
        (_.indexOf(this.power_types, test.sport.primary_type) != -1 ||
          _.indexOf(this.power_types, test.sport.secondary_type) != -1)
      ) {
        legendData.isMissingPower = true;
      } else if (
        rowData.data_df.vms &&
        rowData.data_df.vms[0] == null &&
        test.sport.simulation_type == AppConstants.SIMULATE_TYPE.POWER &&
        (_.indexOf(this.speed_types, test.sport.primary_type) != -1 ||
          _.indexOf(this.speed_types, test.sport.secondary_type) != -1)
      ) {
        legendData.isMissingSpeed = true;
      }

      this.generateUniqueDataComponentValue(legendData, rowData.title_text);

      legends.push(legendData);
      plotReturn.title.render_mode = 'css';
      listPlots.push(plotReturn);
    });

    //overwrite the first chart title
    //listPlots[0].title.text ="Metabolic demand & VO2";
    listPlots = this.createGridPlot(listPlots);

    let combinedChart = this.plottingAPI.gridplot(listPlots, {
      sizing_mode: 'scale_width',
    });
    this.plottingAPI.show(combinedChart, elementId);
    this.splitUpChartInfoLayout(elementId);

    return legends;
  }

  public generateUniqueDataComponentValue(legendData: any, graphTitle: string): void {
    const metabolicDemandVO2 = 'Metabolic demand & VO2';
    const metabolicDemandVO2AttrValue = 'metabolic-demand-VO2';
    const lactateRecoveryAccumulation = 'Lactate: recovery & accumulation';
    const lactateRecoveryAccumulationAttrValue = 'lactate-recovery-accumulation';
    const lactateProductionMaxOxidation = 'Lactate – production & max. oxidation';
    const lactateProductionMaxOxidationAttrValue = 'lactate-production-max-oxidation';
    const fatCarbohydrateCombustion = 'Fat & carbohydrate combustion';
    const fatCarbohydrateCombustionAttrValue = 'fat-carbohydrate-combustion';
    const aerobicAnaerobic = '% Aerobic & Anaerobic';
    const aerobicAnaerobicAttrValue = 'aerobic-anaerobic';
    const fatCarbohydrate = '% Fat & Carbohydrate';
    const fatCarbohydrateAttrValue = 'fat-arbohydrate';

    switch(graphTitle) {
      case metabolicDemandVO2:
        legendData.dataComponent = metabolicDemandVO2AttrValue;
        break;
      case lactateRecoveryAccumulation:
        legendData.dataComponent = lactateRecoveryAccumulationAttrValue;
        break;
      case lactateProductionMaxOxidation:
        legendData.dataComponent = lactateProductionMaxOxidationAttrValue;
        break;
      case fatCarbohydrateCombustion:
        legendData.dataComponent = fatCarbohydrateCombustionAttrValue;
        break;
      case aerobicAnaerobic:
        legendData.dataComponent = aerobicAnaerobicAttrValue;
        break;
      case fatCarbohydrate:
        legendData.dataComponent = fatCarbohydrateAttrValue;
        break;
    }
  }

  public generateLegendInfoTag(rowData: any, legendData: any, mappingData: any) {
    let same_legend_lines: { legend: any }[] = _.uniqBy(rowData.lines, (line: { legend: any }) => {
      return line.legend;
    });

    _.each(rowData.data_names, (name, idx: string): void => {
      legendData.dataInfo[idx] = {
        name: name,
        index: idx,
        typeString: '___',
        typeClass: 'continued_line',
        lines: [],
      };
      _.each(same_legend_lines, (lineLegend: any): void => {
        let text: string = '';
        if (lineLegend.y_range_name == 'default') {
          text = rowData['y_axis_label'];
        } else {
          for (let row of rowData['layouts']) {
            if (row.linear_axis.y_range_name == lineLegend.y_range_name) {
              text = row.linear_axis.axis_label;
            }
          }
        }
        let infoData = this.createLegendInfo(
          legendData,
          lineLegend,
          mappingData,
          text
        );
        legendData.dataInfo[idx].lines.push(infoData);

        _.each(rowData.lines, (sameLine) => {
          if (
            lineLegend.line_color == sameLine.line_color &&
            lineLegend.legend != sameLine.legend &&
            sameLine.line_dash &&
            sameLine.line_dash != 'solid'
          ) {
            legendData.dataInfo[idx].typeClass = sameLine.line_dash + '_line';
            legendData.dataInfo[idx].typeString =
              this.getTypeStringLegendInfoTag(sameLine);
          }
        });
      });
    });

    return legendData;
  }

  private getTypeStringLegendInfoTag(sameLine: any): string {
    return sameLine.line_dash == 'dashed' ? '---' : '...';
  }

  public generateMetabolicFingerprintRadar(athleteData: any, elementId: string,): void {
    if (athleteData.plots && athleteData.plots.length > 0) {
      this.customRendererService.css(['#vo2max', '#vlamax', '#at_ana', '#fatmax', '#cardmax'], 'display', 'inline-block');
      this.customRendererService.css(['#vo2max_report', '#vlamax_report', '#at_ana_report', '#fatmax_report', '#cardmax_report'], 'display', 'inline-block');

      let data = athleteData.plots[0];
      let labels: any[] = [];
      let ctx: Element | null = this.customRendererService.select(elementId + '_radar');

      if (data.texts && data.texts.length > 0) {
        labels = data.texts[1].text;
      }

      let totalLabel = labels.length;
      if (totalLabel % 2 == 0) {
        labels[totalLabel / 2] = [labels[totalLabel / 2], ''];
      }

      let datasets: any[] = _.map(data.radars, (obj) => {
        _.each(obj.data, (res, index) => {
          obj.data[index] = parseFloat(res).toFixed(2);
        });

        return _.extend({}, obj, {
          backgroundColor: 'rgba(255,255,255,0)',
          strokeColor: '#000000',
          borderColor: '#000',
          pointBackgroundColor: '#000000',
          pointBorderColor: '#000000',
          radius: 3,
          pointRadius: 3,
        });
      });

      const fullDatasets = [
        {
          backgroundColor: 'rgba(255,255,255,0)',
          borderColor: '#444',
          borderWidth: 2,
          strokeColor: 'rgba(0,0,0,0)',
          pointColor: 'rgba(0,0,0,0)',
          pointStrokeColor: 'rgba(0,0,0,0)',
          data: [
            '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0',
          ].splice(0, datasets[0].data.length),
          radius: 0,
          pointRadius: 0,
        },
        {
          backgroundColor: 'rgba(255,255,255,0)',
          borderColor: '#444',
          borderWidth: 2,
          strokeColor: 'rgba(0,0,0,0)',
          pointColor: 'rgba(0,0,0,0)',
          pointStrokeColor: 'rgba(0,0,0,0)',
          data: [
            '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100', '0', '100',
          ].splice(0, datasets[0].data.length),
          radius: 0,
          pointRadius: 0,
        },
        {
          backgroundColor: 'rgba(200,105,104,1)',
          borderColor: '#444',
          borderWidth: 2,
          strokeColor: 'rgba(0,0,0,0)',
          pointColor: 'rgba(0,0,0,0)',
          pointStrokeColor: 'rgba(0,0,0,0)',
          data: [
            '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25', '25',
          ].splice(0, datasets[0].data.length),
          radius: 0,
          pointRadius: 0,
        },
        {
          backgroundColor: 'rgba(255,255,255,1)',
          borderColor: '#444',
          borderWidth: 2,
          strokeColor: 'rgba(0,0,0,0)',
          pointColor: 'rgba(0,0,0,0)',
          pointStrokeColor: 'rgba(0,0,0,0)',
          data: [
            '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50', '50',
          ].splice(0, datasets[0].data.length),
          radius: 0,
          pointRadius: 0,
        },
        {
          backgroundColor: 'rgba(174,174,174,1)',
          borderColor: '#444',
          borderWidth: 2,
          strokeColor: 'rgba(0,0,0,0)',
          pointColor: 'rgba(0,0,0,0)',
          pointStrokeColor: 'rgba(0,0,0,0)',
          data: [
            '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75', '75',
          ].splice(0, datasets[0].data.length),
          radius: 0,
          pointRadius: 0,
        },
        {
          backgroundColor: 'rgba(137,137,137,1)',
          borderColor: '#444',
          borderWidth: 2,
          strokeColor: 'rgba(0,0,0,0)',
          pointColor: 'rgba(0,0,0,0)',
          pointStrokeColor: 'rgba(0,0,0,0)',
          data: [
            '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100', '100',
          ].splice(0, datasets[0].data.length),
          radius: 0,
          pointRadius: 0,
        },
      ];
      fullDatasets.unshift(datasets[0]);

      //custom the label
      const data_radar = {
        labels: labels,
        datasets: fullDatasets,
      };

      // Fill colors
      try {
        if (data_radar.datasets[0].data.length > 0) {
          // //data_radar.datasets[0].fill_color = '#228bdf';
        }
      } catch (error) {
        console.log('RadarChart error cannot fill colors.');
      }

      const configObj = {
        type: 'radar',
        data: data_radar,
        options: {
          scale: {
            afterFit: (scale: any): void => {
              let ticks: string[] = ['0', '25', '50', '75', '100'];
              let ticksAsNumbers: number[] = [0, 25, 50, 75, 100];
              scale.ticks = ticks;
              scale.ticksAsNumbers = ticksAsNumbers;
            },
            reverse: false,
            ticks: {
              beginAtZero: true,
              fixedStepSize: 20,
              max: 100,
              showLabelBackdrop: false,
              display: false,
            },
            pointLabels: {
              fontSize: 13,
              fontColor: CHART_TITLE_COLOR,
              fontStyle: 'normal',
              fontFamily: '"Helvetica Neue", "Open Sans", sans-serif',
            },
            gridLines: {
              color: '#999',
              zeroLineColor: '#999',
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          animation: {
            onComplete: () => {
              // funtion
            },
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, chart: any) => {
                return chart.datasets[0].data_tooltips[
                  tooltipItem.index
                  ].toFixed(2);
              },
            },
          },
        },
      };

      new Chart(ctx, configObj);
    }
  }

  public generatePerformanceDevelopment(athleteData: any, elementId: any, testId: any, isPdf: boolean): void {
    let data = athleteData;
    if (!data.plots.length) {
      return;
    }

    _.each(data.plots, (plot): void => {
      const TOOLS: boolean | string = isPdf ? false : 'pan,box_zoom';
      //// let line_colors = ['blue', 'red', 'green', 'magenta', 'lightblue', 'black'];

      let lineObject: { y_range_name: any } | undefined;

      let plotData: any;
      plotData = {
        plot_width: CHART_FULL_WIDTH,
        plot_height: CHART_HEIGHT,
        tools: TOOLS,
        x_axis_type: plot.x_axis_type,
        background_fill_color: CHART_BACKGROUND_FILL_COLOR,
        border_fill_color: CHART_BORDER_FILL_COLOR,
        outline_line_color: BORDER_COLOR,
        sizing_mode: 'scale_width',
        toolbar_location: isPdf ? null : 'above'
      };

      this.handelxValPeromanceChart(plotData, plot, testId); // IAT-2354 let x axis auto scaling
      this.handelyValPeromanceChart(plotData, plot, testId);

      if (plot.extra_y_ranges) {
        plotData.extra_y_ranges = this.createExtraRangeForPerformanceDevelopment(plot.extra_y_ranges, testId);
      }

      const y_extra_list: string[] = [];
      for (let key in plot.extra_y_ranges) y_extra_list.push(key.toString());

      let chart = this.plottingAPI.figure(plotData);

      this.drawPerformanceDevelopment(plot, lineObject, chart, y_extra_list);

      if (plot.layouts && plot.layouts.length > 0) {
        _.each(plot.layouts, (res): void => {
          chart = this.createLayout(res, chart);
        });
      }

      let performanceXCallbackCode: string = 'let data = this.attributes.args.data; saveSettingsForPD(cb_obj);';
      // //let performanceCallbackCode = 'let data = this.attributes.args.data;';
      chart.x_range.callback = new Bokeh.CustomJS({
        code: performanceXCallbackCode,
        args: new Bokeh.ColumnDataSource({
          data: plot,
        }),
      });

      let performanceYCallbackCode: string = 'let data = this.attributes.args.data; saveYSettingsForPD(cb_obj);';
      chart.y_range.callback = new Bokeh.CustomJS({
        code: performanceYCallbackCode,
        args: new Bokeh.ColumnDataSource({
          data: plot,
        }),
      });

      chart.title.text = plot['title_text'];
      chart.title.align = 'center';
      chart.title.text_font_size = '12pt';
      chart.title.text_color = CHART_TITLE_COLOR;
      // Legend Styling
      chart._legend.location = 'top_left';
      chart._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
      chart._legend.border_line_width = LEGEND_BORDER_WIDTH;
      chart._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
      chart._legend.label_text_color = LABEL_TEXT_COLOR;
      chart.yaxis.axis_label = plot.y_axis_label;
      chart.yaxis.axis_label_text_font_size = '10pt';
      chart.yaxis.axis_label_text_font_style = 'normal';
      chart.yaxis.axis_label_text_color = LABEL_TEXT_COLOR;
      chart.yaxis.major_label_text_color = LABEL_TEXT_COLOR
      chart.xaxis.axis_label_text_font_size = '10pt';
      chart.xaxis.axis_label_text_font_style = 'normal';
      chart.xaxis.axis_label_text_color = LABEL_TEXT_COLOR;
      chart.xaxis.major_label_text_color = LABEL_TEXT_COLOR;

      if (chart.right) {
        _.each(chart.right, (right): void => {
          right.attributes.axis_label_text_font_size = '10pt';
          right.attributes.axis_label_text_font_style = 'normal';
          right.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
          right.attributes.major_label_text_color = LABEL_TEXT_COLOR;
        });
      }

      if (chart.left) {
        _.each(chart.left, (left): void => {
          left.attributes.axis_label_text_font_style = 'normal';
          left.attributes.axis_label_text_font_size = '10pt';
          left.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
          left.attributes.major_label_text_color = LABEL_TEXT_COLOR;
        });
      }

      //remove grid lines in chart
      // Grid Styling
      chart.xgrid.grid_line_color = GRID_LINE_COLOR;
      chart.ygrid.grid_line_color = GRID_LINE_COLOR;

      this.plottingAPI.show(chart, elementId);
    });
  }

  public generateSeparatePerformanceDevelopment(plot: any, elementId: any, testId: any, isPdf: boolean = false, index: number): void {
    if (!plot) {
      return;
    }

    this.updateColorTheme(localStorage.getItem('theme')!);

    const TOOLS: boolean | string = isPdf ? false : 'pan,box_zoom';
    //// let line_colors = ['blue', 'red', 'green', 'magenta', 'lightblue', 'black'];

    let lineObject: { y_range_name: any } | undefined;

    let plotData: any;
    plotData = {
      plot_width: CHART_FULL_WIDTH,
      plot_height: CHART_HEIGHT,
      tools: TOOLS,
      x_axis_type: plot.x_axis_type,
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
      outline_line_color: BORDER_COLOR,
      sizing_mode: 'scale_width',
      toolbar_location: isPdf ? null : 'above'
    };

    this.handelSeparatexValPeromanceChart(plotData, plot, testId, index); // IAT-2354 let x axis auto scaling
    this.handelSeparateyValPeromanceChart(plotData, plot, testId, index);

    if (plot.extra_y_ranges) {
      plotData.extra_y_ranges = this.createExtraRangeForPerformanceDevelopment(plot.extra_y_ranges, testId);
    }

    const y_extra_list: string[] = [];
    for (let key in plot.extra_y_ranges) y_extra_list.push(key.toString());

    let chart = this.plottingAPI.figure(plotData);

    this.drawPerformanceDevelopment(plot, lineObject, chart, y_extra_list);

    if (plot.layouts && plot.layouts.length > 0) {
      _.each(plot.layouts, (res): void => {
        chart = this.createLayout(res, chart);
      });
    }

    let performanceXCallbackCode: string = 'let data = this.attributes.args.data; saveSettingsForPD' + index + '(cb_obj);';
    // //let performanceCallbackCode = 'let data = this.attributes.args.data;';
    chart.x_range.callback = new Bokeh.CustomJS({
      code: performanceXCallbackCode,
      args: new Bokeh.ColumnDataSource({
        data: plot,
      }),
    });
    let performanceYCallbackCode: string = 'let data = this.attributes.args.data; saveYSettingsForPD' + index + '(cb_obj);';
    chart.y_range.callback = new Bokeh.CustomJS({
      code: performanceYCallbackCode,
      args: new Bokeh.ColumnDataSource({
        data: plot,
      }),
    });

    chart.title.text = plot['title_text'];
    chart.title.align = 'center';
    chart.title.text_font_size = '12pt';
    chart.title.text_color = CHART_TITLE_COLOR;
    // Legend Styling
    chart._legend.location = 'top_left';
    chart._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
    chart._legend.border_line_width = LEGEND_BORDER_WIDTH;
    chart._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
    chart._legend.label_text_color = LABEL_TEXT_COLOR;
    chart.yaxis.axis_label = plot.y_axis_label;
    chart.yaxis.axis_label_text_font_size = '10pt';
    chart.yaxis.axis_label_text_font_style = 'normal';
    chart.yaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    chart.yaxis.major_label_text_color = LABEL_TEXT_COLOR
    chart.xaxis.axis_label_text_font_size = '10pt';
    chart.xaxis.axis_label_text_font_style = 'normal';
    chart.xaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    chart.xaxis.major_label_text_color = LABEL_TEXT_COLOR;

    if (chart.right) {
      _.each(chart.right, (right): void => {
        right.attributes.axis_label_text_font_size = '10pt';
        right.attributes.axis_label_text_font_style = 'normal';
        right.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
        right.attributes.major_label_text_color = LABEL_TEXT_COLOR;
      });
    }

    if (chart.left) {
      _.each(chart.left, (left): void => {
        left.attributes.axis_label_text_font_style = 'normal';
        left.attributes.axis_label_text_font_size = '10pt';
        left.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
        left.attributes.major_label_text_color = LABEL_TEXT_COLOR;
      });
    }

    //remove grid lines in chart
    // Grid Styling
    chart.xgrid.grid_line_color = GRID_LINE_COLOR;
    chart.ygrid.grid_line_color = GRID_LINE_COLOR;

    this.plottingAPI.show(chart, elementId);
  }

  private drawPerformanceDevelopment(plot: any, lineObject: any, chart: any, y_extra_list: any): void {
    const all_renderer: any[] = [];
    const all_legends: any[] = [];
    let legend, circleObject, source, tooltip, layout;

    if (plot.lines && plot.circles && plot.layouts && plot.lines.length > 0 && plot.circles.length > 0 && plot.layouts.length > 0 && plot.extra_tools.length > 0) {
      _.each(plot.lines, (line, index: string): void => {
        if (line.source.x) {
          line.source.x = line.source.x.map((res: string | number | Date) => {
            return new Date(res).getTime();
          });
        }

        source = new Bokeh.ColumnDataSource({data: line.source,});
        layout = plot.layouts[plot.layouts.length - 1];
        legend = layout.legends[index][0];
        tooltip = plot.extra_tools[index].tooltips;
        lineObject = chart.line({
          x: {
            field: 'x',
          },
          y: {
            field: 'y',
          },
          source: source,
          line_width: line.line_width,
          line_color: line.line_color,
          legend: legend,
        });
        //mysterious here for extra y-axis display
        if (y_extra_list.indexOf(line.y_range_name) != -1)
          lineObject.y_range_name = line.y_range_name;

        circleObject = chart.circle({
          x: {field: 'x',},
          y: {field: 'y',},
          source: source,
          color: line.line_color,
          fill_color: plot.circles[index].fill_color,
          legend: legend,
          size: plot.circles[index].size,
        });
        if (y_extra_list.indexOf(line.y_range_name) != -1)
          circleObject.y_range_name = line.y_range_name;

        all_renderer.push(circleObject);
        all_legends.push(
          new Bokeh.LegendItem({
            label: legend,
            renderers: [lineObject, circleObject],
          })
        );
        chart.add_tools(
          new Bokeh.HoverTool({
            renderers: [circleObject],
            tooltips: tooltip,
          })
        );
      });
    }
  }

  public generateRelationshipChart(data: any, elementId: any, test: any): void {
    if (!data) {
      return;
    }

    let plotData: any = {
      plot_width: CHART_FULL_WIDTH,
      plot_height: 570,
      tools: 'pan,box_zoom,reset',
      x_axis_type: 'linear',
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
      outline_line_color: BORDER_COLOR,
      sizing_mode: 'scale_width',
      toolbar_location: 'above',
    };

    this.initExtraAxisSpeedRelationships(data, plotData, test);

    let chart = this.plottingAPI.figure(plotData);
    this.addExtraAxisSpeedRelationships(chart, test, data);
    this.drawSpeedRelationships(data, chart);

    // Legend Styling
    chart._legend.location = 'top_left';
    chart._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
    chart._legend.border_line_width = LEGEND_BORDER_WIDTH;
    chart._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
    chart._legend.label_text_color = LABEL_TEXT_COLOR;
    chart.xaxis.axis_label = data.primary_type;
    chart.yaxis.axis_label = data.y_left_label;
    chart.yaxis.axis_label_text_font_size = '10pt';
    chart.yaxis.axis_label_text_font_style = 'normal';
    chart.yaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    chart.yaxis.major_label_text_color = LABEL_TEXT_COLOR;
    chart.xaxis.axis_label_text_font_size = '10pt';
    chart.xaxis.axis_label_text_font_style = 'normal';
    chart.xaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    chart.xaxis.major_label_text_color = LABEL_TEXT_COLOR;

    if (chart.right) {
      _.each(chart.right, (right): void => {
        right.attributes.axis_label_text_font_size = '10pt';
        right.attributes.axis_label_text_font_style = 'normal';
        right.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
        right.attributes.major_label_text_color = LABEL_TEXT_COLOR;
      });
    }

    if (chart.left) {
      _.each(chart.left, (left): void => {
        left.attributes.axis_label_text_font_style = 'normal';
        left.attributes.axis_label_text_font_size = '10pt';
        left.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
        left.attributes.major_label_text_color = LABEL_TEXT_COLOR;
      });
    }

    //remove grid lines in chart
    // Grid Styling
    chart.xgrid.grid_line_color = GRID_LINE_COLOR;
    chart.ygrid.grid_line_color = GRID_LINE_COLOR;

    this.plottingAPI.show(chart, elementId);
  }

  private addExtraAxisSpeedRelationships(chart: any, test: any, data: any): void {
    let {combinedXData, combinedYData} = this.combineXYData(data);

    chart.xaxis.formatter = new Bokeh.FuncTickFormatter({
      code: `
      let combineXYData = {
        x_data: ${JSON.stringify(combinedXData)},
        y_data: ${JSON.stringify(combinedYData)}
      };
      return calculateSpeedRelationshipTicker(tick, ${
        AppConstants.SIMULATE_TYPE.POWER
      }, ${test.sport.primary_type}, combineXYData, ${test.mass});`,
    });

    chart.yaxis.formatter = new Bokeh.FuncTickFormatter({
      code: `
      let combineXYData = {
        x_data: ${JSON.stringify(combinedXData)},
        y_data: ${JSON.stringify(combinedYData)}
      };
      return calculateSpeedRelationshipTicker(tick, ${
        AppConstants.SIMULATE_TYPE.SPEED
      }, ${test.sport.secondary_type}, combineXYData, ${test.mass});`,
    });

    const common_layout = {
      axis_label_text_font_size: '10pt',
      axis_label_text_font_style: 'normal',
      axis_label_text_color: LABEL_TEXT_COLOR,
      major_label_text_color: LABEL_TEXT_COLOR,
    };

    if (_.indexOf(this.power_types, test.sport.primary_type) != -1) {
      let axisLabel: string = this.findAxisLabelSpeedRelationships(
        test.sport.primary_type
      );

      let secondary_x_layout: any = {
        x_range_name: 'secondary_x',
        axis_label: axisLabel,
        ...common_layout,
        formatter: new Bokeh.FuncTickFormatter({
          code: `
          let combineXYData = {
            x_data: ${JSON.stringify(combinedXData)},
            y_data: ${JSON.stringify(combinedYData)}
          };
          return calculateSpeedRelationshipTicker(tick, ${
            AppConstants.SIMULATE_TYPE.POWER
          }, ${test.sport.primary_type}, combineXYData, ${test.mass}, true);`,
        }),
      };

      chart.add_layout(new Bokeh.LinearAxis(secondary_x_layout), 'below');
    }

    if (_.indexOf(this.power_types, test.sport.secondary_type) != -1) {
      let axisLabel: string = this.findAxisLabelSpeedRelationships(
        test.sport.secondary_type
      );

      let secondary_y_layout: any = {
        y_range_name: 'third_y',
        axis_label: axisLabel,
        ...common_layout,
        formatter: new Bokeh.FuncTickFormatter({
          code: `
          let combineXYData = {
            x_data: ${JSON.stringify(combinedXData)},
            y_data: ${JSON.stringify(combinedYData)}
          };
          return calculateSpeedRelationshipTicker(tick, ${
            AppConstants.SIMULATE_TYPE.SPEED
          }, ${test.sport.secondary_type}, combineXYData, ${test.mass}, true);`,
        }),
      };

      chart.add_layout(new Bokeh.LinearAxis(secondary_y_layout), 'left');
    }

    if (data.markers && data.markers.length) {
      let secondary_y_layout: any = {
        y_range_name: 'secondary_y',
        axis_label: data.y_right_label,
        ...common_layout,
      };
      chart.add_layout(new Bokeh.LinearAxis(secondary_y_layout), 'right');
    }
  }

  private findAxisLabelSpeedRelationships(type: any): string {
    let axisLabel: string = '';
    if (type == AppConstants.REFERENCE_SYSTEM.POWER_WATT) {
      axisLabel = this.findXAxisLabel(
        AppConstants.REFERENCE_SYSTEM.POWER_WATT_KG
      );
    } else {
      axisLabel = this.findXAxisLabel(AppConstants.REFERENCE_SYSTEM.POWER_WATT);
    }

    return axisLabel;
  }

  private initExtraAxisSpeedRelationships(data: any, plotData: any, test: any): void {
    if (_.indexOf(this.power_types, test.sport.primary_type) != -1) {
      let {start, end} = this.findMinMaxX(data);
      plotData['x_range'] = new Bokeh.Range1d({
        start: Math.floor(start),
        end: Math.ceil(end),
      });
      plotData['extra_x_ranges'] = {
        secondary_x: new Bokeh.Range1d({
          start: Math.floor(start),
          end: Math.ceil(end),
        }),
      };
    }

    if (data.lines && data.lines.length) {
      let {start, end} = this.findMinMaxY(data.lines);
      plotData['y_range'] = new Bokeh.Range1d({
        start: Math.floor(start),
        end: Math.ceil(end),
      });

      if (_.indexOf(this.power_types, test.sport.secondary_type) != -1) {
        plotData['extra_y_ranges'] = {
          third_y: new Bokeh.Range1d({
            start: Math.floor(start),
            end: Math.ceil(end),
          }),
        };
      }
    }

    if (data.markers && data.markers.length) {
      let {start, end} = this.findMinMaxY(data.markers);
      if (plotData['extra_y_ranges']) {
        plotData['extra_y_ranges']['secondary_y'] = new Bokeh.Range1d({
          start: Math.floor(start),
          end: Math.ceil(end),
        });
      } else {
        plotData['extra_y_ranges'] = {
          secondary_y: new Bokeh.Range1d({
            start: Math.floor(start),
            end: Math.ceil(end),
          }),
        };
      }
    }
  }

  private findMinMaxX(data: any) {
    let start: number = 999999;
    let end: number = 0;
    _.forEach(data.lines, (item): void => {
      if (Array.isArray(item.source.data.x)) {
        let minValue: number = Math.min(...item.source.data.x);
        if (start > minValue) {
          start = minValue;
        }
        let maxValue: number = Math.max(...item.source.data.x);
        if (end < maxValue) {
          end = maxValue;
        }
      } else {
        if (start > item.source.data.y) {
          start = item.source.data.y;
        }
        if (end < item.source.data.y) {
          end = item.source.data.y;
        }
      }
    });
    _.forEach(data.markers, (item): void => {
      if (Array.isArray(item.source.data.x)) {
        let minValue: number = Math.min(...item.source.data.x);
        if (start > minValue) {
          start = minValue;
        }
        let maxValue: number = Math.max(...item.source.data.x);
        if (end < maxValue) {
          end = maxValue;
        }
      } else {
        if (start > item.source.data.y) {
          start = item.source.data.y;
        }
        if (end < item.source.data.y) {
          end = item.source.data.y;
        }
      }
    });
    start = start - start / 10;
    end = end + end / 10;

    return {start, end};
  }

  private findMinMaxY(data: any) {
    let start: number = 999999;
    let end: number = 0;
    _.forEach(data, (item): void => {
      if (Array.isArray(item.source.data.y)) {
        let minValue: number = Math.min(...item.source.data.y);
        if (start > minValue) {
          start = minValue;
        }
        let maxValue: number = Math.max(...item.source.data.y);
        if (end < maxValue) {
          end = maxValue;
        }
      } else {
        if (start > item.source.data.y) {
          start = item.source.data.y;
        }
        if (end < item.source.data.y) {
          end = item.source.data.y;
        }
      }
    });
    start = start - start / 10;
    end = end + end / 10;

    return {start, end};
  }

  private drawSpeedRelationships(data: any, chart: any): void {
    if (data.lines && data.lines.length) {
      _.each(data.lines, (line): void => {
        let source = new Bokeh.ColumnDataSource({data: line.source.data,});
        let legend: string = line.key + ' ' + line.test_data_type;
        chart.line({
          x: {field: 'x',},
          y: {field: 'y',},
          source: source,
          line_width: line.line_width,
          line_color: line.line_color,
          line_dash: line.line_dash,
          legend: legend,
        });

        if (line.circle) {
          chart.circle({
            x: {field: 'x',},
            y: {field: 'y',},
            source: new Bokeh.ColumnDataSource({data: line.circle.source.data,}),
            color: line.line_color,
            size: 8,
            legend: legend,
          });
        }
      });
    }

    if (data.markers && data.markers.length) {
      let markerTypes: any[] = _.uniq(_.map(data.markers, 'field'));
      _.each(data.markers, (line): void => {
        let source = new Bokeh.ColumnDataSource({data: line.source.data,});
        let index: number = _.indexOf(markerTypes, line.field);
        let markerObject;
        let markerSettings = {
          x: {field: 'x',},
          y: {field: 'y',},
          source: source,
          color: line.color,
          size: 10,
          legend: line.key + ' ' + line.field,
        };

        switch (index) {
          case 0:
            markerObject = chart.square(markerSettings);
            break;
          case 1:
            markerObject = chart.diamond(markerSettings);
            break;
          case 2:
            markerObject = chart.triangle(markerSettings);
            break;
          case 3:
            markerObject = chart.asterisk(markerSettings);
            break;
          case 4:
            markerObject = chart.cross(markerSettings);
            break;
          case 5:
            markerObject = chart.x(markerSettings);
            break;
        }

        markerObject.y_range_name = 'secondary_y';
      });
    }
  }

  private combineXYData(data: any) {
    let combinedXData: any[] = [];
    let combinedYData: any[] = [];
    if (data.lines && data.lines.length) {
      combinedXData = data.lines[0].source.data.x;
      combinedYData = data.lines[0].source.data.y;
    }

    combinedXData.sort((a, b) => a - b);
    combinedYData.sort((a, b) => a - b);

    return {combinedXData, combinedYData};
  }

  private handelSeparatexValPeromanceChart(plotData: any, plot: any, testId: any, index: number): void {
    let xVal: any = localStorage.getItem(index + '_PDXsettings_' + testId);
    if (xVal) {
      xVal = JSON.parse(xVal);
      plotData.x_range = new Bokeh.Range1d({
        start: new Date(xVal.plot_range[0]).getTime(),
        end: new Date(xVal.plot_range[1]).getTime(),
      });
    } else {
      if (plot.is_one_day) {
        plotData.x_range = new Bokeh.Range1d({
          start: new Date(plot.x_range.plot_range[0]).getTime(),
          end: new Date(plot.x_range.plot_range[1]).getTime(),
        });
      }
    }
  }

  private handelxValPeromanceChart(plotData: any, plot: any, testId: any): void {
    let xVal: any = localStorage.getItem('PDsettings_' + testId);
    if (xVal) {
      xVal = JSON.parse(xVal);
      plotData.x_range = new Bokeh.Range1d({
        start: new Date(xVal.plot_range[0]).getTime(),
        end: new Date(xVal.plot_range[1]).getTime(),
      });
    } else {
      if (plot.is_one_day) {
        plotData.x_range = new Bokeh.Range1d({
          start: new Date(plot.x_range.plot_range[0]).getTime(),
          end: new Date(plot.x_range.plot_range[1]).getTime(),
        });
      }
    }
  }

  private handelyValPeromanceChart(plotData: any, plot: any, testId: any): void {
    if (plot.y_range) {
      // IAT-2354 Use default value [0,100], not depending on plots data
      let xVal: any = localStorage.getItem('PDYsettings_' + testId);
      let start: number = ChartConstants.RANGE.START;
      let end: number = ChartConstants.RANGE.END;
      if (xVal) {
        xVal = JSON.parse(xVal);
        start = xVal.plot_range[0];
        end = xVal.plot_range[1];
      }
      plotData.y_range = new Bokeh.Range1d({
        start: start,
        end: end,
      });
    }
  }

  private handelSeparateyValPeromanceChart(plotData: any, plot: any, testId: any, index: number): void {
    if (plot.y_range) {
      // IAT-2354 Use default value [0,100], not depending on plots data
      let xVal: any = localStorage.getItem(index + '_PDYsettings_' + testId);
      let start: number = ChartConstants.RANGE.START;
      let end: number = ChartConstants.RANGE.END;
      if (xVal) {
        xVal = JSON.parse(xVal);
        start = xVal.plot_range[0];
        end = xVal.plot_range[1];
      }
      plotData.y_range = new Bokeh.Range1d({
        start: start,
        end: end,
      });
    }
  }

  public generateTestData(tabs: any, elementId: any, test_type: string, testId: any, test: any, isPDFPreview?: any) {
    let isMissing = {
      power: false,
      speed: false,
      data: false,
    };

    if (test_type == 'newtype') {
      this.generateTestDataNew(tabs, elementId, isPDFPreview,);
    } else {
      this.generateTestDataOld(tabs, elementId, testId, test, isMissing);
    }
    // Generate Heart Rate Chart
    if (tabs && Array.isArray(tabs) && tabs.length && tabs[0].linear_relationship) {
      this.generateHeartRatePlot(tabs[0].linear_relationship, test);
    } else if (tabs && !Array.isArray(tabs) && tabs.linear_relationship) {
      this.generateHeartRatePlot(tabs.linear_relationship, test);
    }

    return isMissing;
  }

  public generateTestDataOld(athleteData: any, elementId: any, testId: any, test: any, isMissing: any): void {
    let data = athleteData;
    let listPlot: any[] = [];

    // IAT-2347 - Resolved: V2 - Test Data -
    // There was no Lactate Curves chart when analyze the new lactate template test
    // if (data?.plots?.length) {
    //   return;
    // }
    this.handelDrawTestDataOld(data, testId, listPlot, test, isMissing);
    this.handelListPlot(listPlot, elementId);
    setTimeout((): void => {
      const targetElement: Element | null = this.customRendererService.select(elementId);
      const childNodes: NodeListOf<Element> | undefined = this.customRendererService.getChildNodes(targetElement, "div[class*='bk-root']");

      if (childNodes?.length) {
        childNodes.forEach((containerEle: Element, index: number): void => {
          if (index == 0 && data.plots.length > 2) {
            let htmlText: string = '<div id="" class="chart-text-header"><b>Determination of metabolic demand</b>';
            htmlText += `<h5>${data.plots[index].header_text}</h5>` + '</div>';
            this.customRendererService.insertBefore(targetElement, htmlText, containerEle);

            // IAT-376 In Lactate Test, we need possibility to show text instead of plot
            if (data.plots[index].display_chart === false) {
              this.customRendererService.hide('#test_data .bk-root');
              this.customRendererService.hide('#test_data_rp .bk-root');
            }
          } else if (index == 0 && data.plots.length == 1) {
            let htmlText: string = '<div class="chart-text-header"><b>Determination of critical power</b>';
            const htmlTextArg = data.plots[index].header_text.replace('<sub>', '<sup>').replace('</sub>', '</sup>').replace('R', 'r');
            htmlText += `<h5>${htmlTextArg}</h5>` + '</div>';
            this.customRendererService.insertBefore(targetElement, htmlText, containerEle);
          } else {
            let htmlText: string = '<div class="chart-text-header"><b>Determination of lactate accumulation</b>';
            htmlText += `<h5>${data.plots[index].header_text}</h5>` + '</div>';
            this.customRendererService.insertBefore(targetElement, htmlText, containerEle);
          }
        });
      }
    }, 100);
  }

  private handelDrawTestDataOld(data: any, testId: any, listPlot: any, test: any, isMissing: any): void {
    let TOOLS: string = 'pan,box_zoom,reset';

    _.each(data.plots, (rowData, idx: any): void => {
      let p: any, x_range: boolean = false, y_range;
      const xKeyStorage: string = 'TDXsettings';
      const yKeyStorage: string = 'TDYsettings';
      let extra_x_ranges = undefined;

      if (data.plots.length === 3 && idx === 2) {
        extra_x_ranges = listPlot[1].extra_x_ranges;
      } else if (data.plots.length === 2 && idx === 1) {
        extra_x_ranges = listPlot[0].extra_x_ranges;
      }

      if (!rowData.isReset) {
        let testDataXVal: any = localStorage.getItem(
          `${xKeyStorage}${idx}_${testId}`
        );
        if (testDataXVal) {
          testDataXVal = JSON.parse(testDataXVal);
          if (testDataXVal) {
            x_range = new Bokeh.Range1d({
              start: testDataXVal.plot_range[0],
              end: testDataXVal.plot_range[1],
            });
          }
        }

        let testDataYVal: any = localStorage.getItem(
          `${yKeyStorage}${idx}_${testId}`
        );
        if (testDataYVal) {
          testDataYVal = JSON.parse(testDataYVal);
          if (testDataYVal) {
            y_range = new Bokeh.Range1d({
              start: testDataYVal.plot_range[0],
              end: testDataYVal.plot_range[1],
            });
          }
        }
      }

      let plotData: any = {
        // y_axis_type:"log",
        plot_width: data.plots.length === 3 && idx === 0 ? CHART_FULL_WIDTH : CHART_HALF_WIDTH,
        plot_height: CHART_HEIGHT,
        tools: TOOLS,
        background_fill_color: CHART_BACKGROUND_FILL_COLOR,
        border_fill_color: CHART_BORDER_FILL_COLOR,
        outline_line_color: BORDER_COLOR,
        sizing_mode: 'scale_width',
      };

      if (rowData.y_range) {
        y_range = new Bokeh.Range1d({
          start: rowData.y_range.plot_range[0],
          end: rowData.y_range.plot_range[1],
        });
      }
      if (data.plots.length === 3 && idx === 2) {
        x_range = listPlot[1].x_range;
        y_range = listPlot[1].y_range;
      } else if (data.plots.length === 2 && idx === 1) {
        x_range = listPlot[0].x_range;
        y_range = listPlot[0].y_range;
      }
      if (x_range) {
        plotData.x_range = x_range;
      }

      if (y_range) {
        plotData.y_range = y_range;
      }

      // IAT-588 Lactate Accumulation - Test Curves > apply min/max of sport settings to x-axis
      if (test.is_show_test_data && !plotData.x_range && rowData.x_range) {
        if (rowData.circles && rowData.circles.length > 0) {
          _.each(rowData.circles, function (circle): void {
            if (rowData.x_range.plot_range[0] > circle.x[0]) {
              rowData.x_range.plot_range[0] = Math.floor(circle.x[0]);
            }
            if (rowData.x_range.plot_range[1] < circle.x[0]) {
              rowData.x_range.plot_range[1] = Math.ceil(circle.x[0]);
            }
          });
        }
        plotData.x_range = this.createRange(rowData.x_range);
      }

      this.mappingDataDF(test, rowData);

      if (!rowData.data_df) {
        isMissing.data = true;
      } else if (
        rowData.data_df.power &&
        rowData.data_df.power[0] == null &&
        test.sport.simulation_type == AppConstants.SIMULATE_TYPE.SPEED &&
        (_.indexOf(this.power_types, test.sport.primary_type) != -1 ||
          _.indexOf(this.power_types, test.sport.secondary_type) != -1)
      ) {
        isMissing.power = true;
      } else if (
        rowData.data_df.vms &&
        rowData.data_df.vms[0] == null &&
        test.sport.simulation_type == AppConstants.SIMULATE_TYPE.POWER &&
        (_.indexOf(this.speed_types, test.sport.primary_type) != -1 ||
          _.indexOf(this.speed_types, test.sport.secondary_type) != -1)
      ) {
        isMissing.speed = true;
      }

      this.initExtraXRanges(extra_x_ranges, plotData, test, false);

      p = this.plottingAPI.figure(plotData);

      this.addSecondaryXAxis(test, p, rowData.data_df, false);
      this.handleRowDataLine(rowData, p);
      this.handleRowDatacircles(rowData, p);

      const testDataCallbackCode = 'saveSettingsForTD(cb_obj,' + idx + ');';
      p.x_range.callback = new Bokeh.CustomJS({
        code: testDataCallbackCode,
      });

      const testDataYCallbackCode = 'saveYSettingsForTD(cb_obj,' + idx + ');';
      p.y_range.callback = new Bokeh.CustomJS({
        code: testDataYCallbackCode,
      });

      p.yaxis.axis_label = rowData['y_axis_label'];
      p.xaxis.axis_label = rowData['x_axis_label'] || 'Seconds';
      if (!(idx == 0 && (data.plots.length > 2 || data.plots.length == 1))) {
        p.title.text = rowData['title_text'];
      }
      p.title.text_color = CHART_TITLE_COLOR;
      p._legend.location = rowData['legend_location'] || 'top_left';
      p._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
      p._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
      p._legend.label_text_color = LABEL_TEXT_COLOR;
      p.toolbar_location = 'right';

      //remove grid lines in chart
      // p.xgrid.grid_line_color = null;
      // p.ygrid.grid_line_color = null;

      //override chart axis attributes
      p.yaxis.axis_label_text_font_size = '10pt';
      p.yaxis.axis_label_text_font_style = 'normal';
      p.yaxis.axis_label_text_color = LABEL_TEXT_COLOR;
      p.yaxis.major_label_text_color = LABEL_TEXT_COLOR;

      //// p.y_axis_type = "log";

      p.xaxis.axis_label_text_font_size = '10pt';
      p.xaxis.axis_label_text_font_style = 'normal';
      p.xaxis.axis_label_text_color = LABEL_TEXT_COLOR;
      p.xaxis.major_label_text_color = LABEL_TEXT_COLOR;

      if (test) {
        // Hide original X axis
        p.xaxis.visible = false;
      }

      p.xgrid.grid_line_color = GRID_LINE_COLOR;
      p.ygrid.grid_line_color = GRID_LINE_COLOR;

      if (p.right) {
        _.each(p.right, (right): void => {
          right.attributes.axis_label_text_font_style = 'normal';
          right.attributes.axis_label_text_font_size = '10pt';
          right.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
          right.attributes.major_label_text_color = LABEL_TEXT_COLOR;
        });
      }

      if (p.left) {
        _.each(p.left, (left): void => {
          left.attributes.axis_label_text_font_size = '10pt';
          left.attributes.axis_label_text_font_style = 'normal';
          left.attributes.axis_label_text_color = LABEL_TEXT_COLOR;
          left.attributes.major_label_text_color = LABEL_TEXT_COLOR;
        });
      }

      listPlot.push(p);
    });
  }

  public mappingDataDF(test: any, data: any): void {
    if (!test) {
      return;
    }

    if (!data.data_df) {
      data.data_df = {};
    }

    if (test.data_df_power) {
      data.data_df['power'] = test.data_df_power;
    }

    if (test.data_df_vms) {
      data.data_df['vms'] = test.data_df_vms;
    }

    if (!data.data_df['x']) {
      if (test.sport.simulation_type == AppConstants.SIMULATE_TYPE.POWER) {
        data.data_df['x'] = test.data_df_power;
      } else if (
        test.sport.simulation_type == AppConstants.SIMULATE_TYPE.SPEED
      ) {
        data.data_df['x'] = test.data_df_vms;
      }
    }
  }

  private handleRowDatacircles(rowData: any, p: any): void {
    if (rowData.circles && rowData.lines.length > 0) {
      _.each(rowData.circles, (circle) => {
        let ds = {
          x: circle.x,
          y: circle.y,
        };

        let source = new Bokeh.ColumnDataSource({data: ds,});

        p.circle({
          x: {field: 'x',},
          y: {field: 'y',},
          fill_color: circle.fill_color,
          line_color: circle.line_color,
          size: circle.size,
          source: source,
          legend: circle.legend,
        });
      });
    }
  }

  private handleRowDataLine(rowData: any, p: any): void {
    if (!rowData?.lines?.length) {
      return;
    }
    _.each(rowData.lines, (line): void => {
      let ds = {x: line.x, y: line.y,};

      let source = new Bokeh.ColumnDataSource({data: ds,});

      switch (line.legend) {
        case 'Messwerte':
          line.legend = 'Measured values';
          break;
        case 'Berechnet':
          line.legend = 'Calculated';
          break;
      }

      let glyph = p.line({
        x: {field: 'x',},
        y: {field: 'y',},
        source: source,
        line_color: line.line_color,
        line_width: 2,
        legend: line.legend,
      });
      if (line.line_dash == 'dashed') {
        glyph.attributes.glyph.line_dash = [5, 6];
      }
      if (line.line_dash == 'dotted') {
        glyph.attributes.glyph.line_dash = [2, 4];
      }
    });
  }

  private handelListPlot(listPlot: any, elementId: any): void {
    if (listPlot.length == 3) {
      this.plottingAPI.show(listPlot[0], elementId);
      const combinedChart = this.plottingAPI.gridplot(
        [[listPlot[1], listPlot[2]]],
        {
          toolbar_location: 'right',
          sizing_mode: 'scale_width',
        }
      );
      this.plottingAPI.show(combinedChart, elementId);
    } else if (listPlot.length == 2) {
      const combinedChart = this.plottingAPI.gridplot([listPlot], {
        toolbar_location: 'right',
        sizing_mode: 'scale_width',
      });
      this.plottingAPI.show(combinedChart, elementId);
    } else {
      const combinedChart = new Bokeh.Row({
        children: listPlot,
      });
      this.plottingAPI.show(combinedChart, elementId);
    }
  }

  public generateTestDataNew(tabs: any, elementId: any, isPDFPreview: any,): void {
    if (tabs && Array.isArray(tabs) && tabs.length && tabs[0].cp19) {
      const optimizedValues = tabs[0].cp19.calculated_values;
      this.generatePPDChart(elementId, optimizedValues, true, isPDFPreview);
    } else if (tabs && !Array.isArray(tabs) && tabs.cp19) {
      const optimizedValues = tabs.cp19.calculated_values;
      this.generatePPDChart(elementId, optimizedValues, true, isPDFPreview);
    }
  }

  public generatePPDChart(elementId: any, optimizedValues: any, showLabel: any, isPDFPreview: boolean = false): void {
    let data = optimizedValues;
    this.updateColorTheme(localStorage.getItem('theme')!, isPDFPreview);

    let VLsource = new Bokeh.ColumnDataSource({
      data: {
        x: [data.VL_x],
        y: [data.VL_y],
        radius: [5],
        color: ['#C10020'],
      },
    });

    let VOsource = new Bokeh.ColumnDataSource({
      data: {
        x: [data.VO_x],
        y: [data.VO_y],
        radius: [5],
        color: ['#00538A'],
      },
    });

    let ATsource = new Bokeh.ColumnDataSource({
      data: {
        x: [data.AT_x],
        y: [data.AT_y],
        radius: [5],
        color: ['#007D34'],
      },
    });

    let xValues: any[] = [data.VL_x, data.VO_x, data.AT_x];
    let yValues: any[] = [data.VL_y, data.VO_y, data.AT_y];
    let errorLow: number = 0.95;
    let errorHigh: number = 1.05;
    let globalMin: number = 0.8 * Math.min(...xValues, ...yValues);
    let globalMax: number = 1.2 * Math.max(...xValues, ...yValues);

    let plt = Bokeh.Plotting;
    let figure_config = {
      tools: 'pan, box_zoom, wheel_zoom, reset,save',
      width: CHART_FULL_WIDTH,
      height: CHART_HEIGHT,
      toolbar_location: 'above',
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
      outline_line_color: BORDER_COLOR,
      sizing_mode: 'scale_width',
    };
    let p = plt.figure(figure_config);

    let crossLineSource = new Bokeh.ColumnDataSource({
      data: {
        x: [globalMin, globalMax],
        y: [globalMin, globalMax],
      },
    });

    let vLErrorLineSource = new Bokeh.ColumnDataSource({
      data: {
        x: [data.VL_x, data.VL_x],
        y: [data.VL_y_lower || data.VL_y * errorLow, data.VL_y_upper || data.VL_y * errorHigh,],
      },
    });

    let vOErrorLineSource = new Bokeh.ColumnDataSource({
      data: {
        x: [data.VO_x, data.VO_x],
        y: [data.VO_y_lower || data.VO_y * errorLow, data.VO_y_upper || data.VO_y * errorHigh,],
      },
    });

    let aTErrorLineSource = new Bokeh.ColumnDataSource({
      data: {
        x: [data.AT_x, data.AT_x],
        y: [data.AT_y_lower || data.AT_y * errorLow, data.AT_y_upper || data.AT_y * errorHigh,],
      },
    });

    if (showLabel) {
      p.yaxis.axis_label = 'Measured value in Power [Watt]';
      p.xaxis.axis_label = 'Calculated value in Power [Watt]';
      p.title.text = 'Cross validation';
      p.title.text_color = CHART_AXIS_LABEL_TEXT_COLOR;
      p.yaxis.axis_label_text_font_size = '10pt';
      p.yaxis.axis_label_text_font_style = 'normal';
      p.yaxis.axis_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
      p.yaxis.major_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
      p.xaxis.axis_label_text_font_size = '10pt';
      p.xaxis.axis_label_text_font_style = 'normal';
      p.xaxis.axis_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
      p.xaxis.major_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
    } else {
      p.xaxis.visible = false;
      p.yaxis.visible = false;
    }

    // B. Real line
    p.line(
      {field: 'x',},
      {field: 'y',},
      {
        source: crossLineSource,
        line_color: '#3c3c3d',
        line_width: 1,
      }
    );

    // C. Error lines
    p.line(
      {field: 'x',},
      {field: 'y',},
      {
        source: vLErrorLineSource,
        line_color: '#C10020',
        line_width: 3,
      }
    );
    p.line(
      {field: 'x',},
      {field: 'y',},
      {
        source: vOErrorLineSource,
        line_color: '#00538A',
        line_width: 3,
      }
    );
    p.line(
      {field: 'x',},
      {field: 'y',},
      {
        source: aTErrorLineSource,
        line_color: '#007D34',
        line_width: 3,
      }
    );

    // Add 3 circles
    // VOcircles
    p.circle(
      {
        field: 'x',
      },
      {
        field: 'y',
      },
      {
        source: VOsource,
        size: 10,
        fill_color: VOsource.data.color,
        line_color: null,
        legend: 'VO2max',
      }
    );
    // VLcircles
    p.circle(
      {
        field: 'x',
      },
      {
        field: 'y',
      },
      {
        source: VLsource,
        size: 10,
        fill_color: VLsource.data.color,
        line_color: null,
        legend: 'VLamax',
      }
    );
    // ATcircles
    p.circle(
      {
        field: 'x',
      },
      {
        field: 'y',
      },
      {
        source: ATsource,
        size: 10,
        fill_color: ATsource.data.color,
        line_color: null,
        legend: 'Maximum metabolic steady state',
      }
    );

    p._legend.location = 'top_left';
    p._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
    p._legend.border_line_width = LEGEND_BORDER_WIDTH;
    p._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
    p._legend.label_text_color = LABEL_TEXT_COLOR;
    p.xgrid.grid_line_color = GRID_LINE_COLOR;
    p.ygrid.grid_line_color = GRID_LINE_COLOR;

    plt.show(p, elementId);
  }

  public generateBokehPlot(
    data: any = null,
    chart: any = null,
    x_range: any = null,
    type: any = null,
    testId: any = null,
    useDefault: any = null,
    chart_index: any = null,
    test: any = null,
    extra_x_ranges: any = null,
    has_secondary_x: boolean = true
  ) {
    let glyphLine: {
      attributes: { glyph: { line_dash: number[] } };
      y_range_name: any;
    };
    this.handelXRangeBokehPlot(x_range, chart, data, type, useDefault, testId);
    this.handelYRangeBokehPlot(chart, data, type, useDefault, testId, chart_index);

    if (data.extra_y_ranges) {
      chart.extra_y_ranges = this.createExtraRange(data.extra_y_ranges);
    }

    this.initExtraXRanges(extra_x_ranges, chart, test, has_secondary_x);

    chart = this.createFigureChart(chart);

    this.mappingDataDF(test, data);
    this.addSecondaryXAxis(test, chart, data.data_df, has_secondary_x);

    if (data?.lines?.length) {
      _.each(data.lines, (line): void => {
        let objectReturn = this.createLine(line, chart);
        chart = objectReturn.chart;
        glyphLine = objectReturn.glyph;
        // Manually override the line in glyph object attributes based on line-dash attributes
        // due to BokehJS not supported for line_dash attribute
        if (line.line_dash == 'dashed') {
          glyphLine.attributes.glyph.line_dash = [5, 6];
        }
        if (line.line_dash == 'dotted') {
          glyphLine.attributes.glyph.line_dash = [2, 4];
        }

        glyphLine.y_range_name = line.y_range_name;
      });
    }

    if (data?.layouts?.length) {
      _.each(data.layouts, (layout): void => {
        chart = this.createLayout(layout, chart);
      });
    }

    if (data?.patches?.length) {
      _.each(data.patches, (patch): void => {
        if (patch.line_alpha == null) {
          chart.patch({
            x: patch.x,
            y: patch.y,
            legend: patch.legend,
            fill_alpha: patch.fill_alpha,
            line_width: patch.line_width,
            fill_color: patch.fill_color,
          });
        } else {
          chart.patch({
            x: patch.x,
            y: patch.y,
            legend: patch.legend,
            fill_alpha: patch.fill_alpha,
            line_alpha: patch.line_alpha,
            line_width: patch.line_width,
            fill_color: patch.fill_color,
          });
        }
      });
    }

    if (data.data_df) {
      chart = this.addCallBackHoverEventToChartMetabolicProfile(
        data.data_df,
        chart,
        test
      );
    }
    chart = this.postRenderChartLayout(chart, data);

    if (test) {
      chart.xaxis.visible = false;
    }

    return chart;
  }

  private addSecondaryXAxis(test: any, chart: any, data: any, has_secondary_x: boolean = true): void {
    if (test) {
      const primary_label: string = this.findXAxisLabel(test.sport.primary_type);
      const secondary_label: string = this.findXAxisLabel(test.sport.secondary_type);
      const common_layout = {
        axis_label_text_font_size: '10pt',
        axis_label_text_font_style: 'normal',
        axis_label_text_color: LABEL_TEXT_COLOR,
        major_label_text_color: LABEL_TEXT_COLOR,
      };
      let primary_layout: any = {
        x_range_name: 'primary',
        axis_label: primary_label,
        ...common_layout,
        formatter: new Bokeh.FuncTickFormatter({
          code: `
          let listDataX = ${JSON.stringify(data)};
          return calculateTicker(tick, ${test.sport.primary_type}, ${
            test.sport.simulation_type
          }, ${test.mass}, listDataX);`,
        }),
      };

      chart.add_layout(new Bokeh.LinearAxis(primary_layout), 'below');

      if (test?.sport?.secondary_type && has_secondary_x) {
        let secondary_layout: any = {
          x_range_name: 'secondary',
          axis_label: secondary_label,
          ...common_layout,
          formatter: new Bokeh.FuncTickFormatter({
            code: `
            let listDataX = ${JSON.stringify(data)};
            return calculateTicker(tick, ${test.sport.secondary_type}, ${
              test.sport.simulation_type
            }, ${test.mass}, listDataX);`,
          }),
        };

        chart.add_layout(new Bokeh.LinearAxis(secondary_layout), 'below');
      }
    }
  }

  public findXAxisLabel(id: number): string {
    const s_power = _.find(AppConstants.REFERENCE_SYSTEMS_POWER, {id: id});
    const s_speed = _.find(AppConstants.REFERENCE_SYSTEMS_SPEED, {id: id});
    let label: string = '';
    if (s_power) {
      label = s_power.unit;
    } else if (s_speed) {
      label = s_speed.unit;
    }
    return label;
  }

  private initExtraXRanges(extra_x_ranges: any, chart: any, test: any, has_secondary_x: boolean = true): void {
    if (extra_x_ranges) {
      chart.extra_x_ranges = extra_x_ranges;
    } else {
      chart.extra_x_ranges = {
        primary: new Bokeh.Range1d({
          start: chart.x_range.min,
          end: chart.x_range.max,
        }),
      };

      if (test?.sport?.secondary_type && has_secondary_x) {
        chart.extra_x_ranges['secondary'] = new Bokeh.Range1d({
          start: chart.x_range.min,
          end: chart.x_range.max,
        });
      }
    }
  }

  private handelXRangeBokehPlot(x_range: any, chart: any, data: any, type: any, useDefault: boolean, testId: any): void {
    if (x_range) {
      chart.x_range = x_range;
    } else {
      if (data.x_range) {
        //Firstly test with metabolic profile chart
        if (type && type == 'metabolic_profile' && !useDefault) {
          // //localStorage.setItem('defaultMPsettings', JSON.stringify(data.x_range));
          const xVal: string | null = localStorage.getItem('MPXsettings_' + testId);
          if (xVal) {
            data.x_range = JSON.parse(xVal);
          }
        }
        chart.x_range = this.createRange(data.x_range);
      }
    }
  }

  private handelYRangeBokehPlot(chart: any, data: any, type: any, useDefault: boolean, testId: any, chart_index: any): void {
    if (data.y_range) {
      //Firstly test with metabolic profile chart
      if (type && type == 'metabolic_profile' && !useDefault) {
        // //localStorage.setItem('defaultMPsettings', JSON.stringify(data.x_range));
        let yVal: string | null = localStorage.getItem(`MPYsettings${chart_index}_${testId}`);
        if (yVal) {
          data.y_range = JSON.parse(yVal);
        }
      }
      chart.y_range = this.createRange(data.y_range);
    }
  }

  public drawLinesPatches(data: any, chart: any) {
    let glyphLine;
    if (data.lines && data.lines.length > 0) {
      _.each(data.lines, (line): void => {
        const objectReturn = this.createLine(line, chart);
        chart = objectReturn.chart;
        glyphLine = objectReturn.glyph;
        // Manually override the line in glyph object attributes based on line-dash attributes
        // due to BokehJS not supported for line_dash attribute
        if (line.line_dash == 'dashed') {
          glyphLine.attributes.glyph.line_dash = [5, 6];
        }
        if (line.line_dash == 'dotted') {
          glyphLine.attributes.glyph.line_dash = [2, 4];
        }

        glyphLine.y_range_name = line.y_range_name;
      });
    }

    if (data.patches && data.patches.length > 0) {
      _.each(data.patches, (patch): void => {
        if (patch.line_alpha == null) {
          chart.patch({
            x: patch.x,
            y: patch.y,
            legend: patch.legend,
            fill_alpha: patch.fill_alpha,
            fill_color: patch.fill_color,
            line_width: patch.line_width,
          });
        } else {
          chart.patch({
            x: patch.x,
            y: patch.y,
            legend: patch.legend,
            fill_alpha: patch.fill_alpha,
            line_alpha: patch.line_alpha,
            line_width: patch.line_width,
            fill_color: patch.fill_color,
          });
        }
      });
    }
    return {
      chart: chart,
      glyphLine: glyphLine,
    };
  }

  public generateBokehPlotMPFatChart(
    data: any,
    chart: any,
    x_range: any = null,
    type: any = null,
    testId: any = null,
    useDefault: any = null,
    chart_index: any = null,
    test: any = null,
    extra_x_ranges: any = null,
    has_secondary_x: boolean = true
  ) {
    let glyphLine: any;
    this.handleXRangeBokehPlotMPFatChar(x_range, chart, data, type, useDefault, testId);
    this.handleYRangeBokehPlotMPFatChar(data, type, useDefault, chart_index, testId, chart);

    if (data.extra_y_ranges) {
      if (type && !useDefault) {
        // //localStorage.setItem('defaultMPsettings', JSON.stringify(data.x_range));
        let extraYVal: string | null = localStorage.getItem(`MPYsettings${chart_index}_y1_${testId}`);

        if (extraYVal) {
          data.extra_y_ranges.y1 = JSON.parse(extraYVal);
        }
      }

      chart.extra_y_ranges = this.createExtraRange(data.extra_y_ranges);
    }

    this.initExtraXRanges(extra_x_ranges, chart, test, has_secondary_x);

    chart = this.createFigureChart(chart);

    this.mappingDataDF(test, data);
    this.addSecondaryXAxis(test, chart, data.data_df, has_secondary_x);

    if (data?.lines?.length && data?.patches?.length) {
      glyphLine = this.handelDrawBokehPlotMPFatChar(data, chart);
    } else {
      let chartData = this.drawLinesPatches(data, chart);
      chart = chartData.chart;
      glyphLine = chartData.glyphLine;
    }

    if (data.layouts && data.layouts.length > 0) {
      _.each(data.layouts, (layout): void => {
        chart = this.createLayout(layout, chart);
      });
    }

    if (data.data_df && !test) {
      chart = this.addCallBackHoverEventToChart(data.data_df, chart);
    } else {
      chart = this.addCallBackHoverEventToChartMetabolicProfile(
        data.data_df,
        chart,
        test
      );
    }

    chart = this.postRenderChartLayout(chart, data);

    if (test) {
      // Hide original X axis
      chart.xaxis.visible = false;
    }

    return chart;
  }

  private handelDrawBokehPlotMPFatChar(data: any, chart: any): void {
    let maxLength: number = 0;
    let linesLength = data.lines.length;
    let patchesLength = data.lines.length;
    let glyphLine;
    if (linesLength >= patchesLength) {
      maxLength = linesLength;
    } else {
      maxLength = patchesLength;
    }

    for (let i = 0; i < maxLength; i++) {
      let line = data.lines[i];
      if (!line) {
        return;
      }
      let objectReturn = this.createLine(line, chart);
      chart = objectReturn.chart;
      glyphLine = objectReturn.glyph;
      // Manually override the line in glyph object attributes based on line-dash attributes
      // due to BokehJS not supported for line_dash attribute
      if (line.line_dash == 'dashed') {
        glyphLine.attributes.glyph.line_dash = [5, 6];
      }
      if (line.line_dash == 'dotted') {
        glyphLine.attributes.glyph.line_dash = [2, 4];
      }

      glyphLine.y_range_name = line.y_range_name;

      let patch = data.patches[i];
      if (patch) {
        if (patch.line_alpha == null) {
          chart.patch({
            x: patch.x,
            y: patch.y,
            legend: patch.legend,
            fill_alpha: patch.fill_alpha,
            line_width: patch.line_width,
            fill_color: patch.fill_color,
          });
        } else {
          chart.patch({
            x: patch.x,
            y: patch.y,
            legend: patch.legend,
            fill_alpha: patch.fill_alpha,
            line_alpha: patch.line_alpha,
            line_width: patch.line_width,
            fill_color: patch.fill_color,
          });
        }
      }
    }
  }

  private handleXRangeBokehPlotMPFatChar(x_range: any, chart: any, data: any, type: any, useDefault: any, testId: any): void {
    if (x_range) {
      chart.x_range = x_range;
    } else {
      if (data.x_range) {
        //Firstly test with metabolic profile chart
        if (type && !useDefault) {
          // //localStorage.setItem('defaultMPsettings', JSON.stringify(data.x_range));
          let xVal: string | null = localStorage.getItem('MPXsettings_' + testId);
          if (xVal) {
            data.x_range = JSON.parse(xVal);
          }
        }
        chart.x_range = this.createRange(data.x_range);
      }
    }
  }

  private handleYRangeBokehPlotMPFatChar(data: any, type: any, useDefault: any, chart_index: any, testId: any, chart: any): void {
    if (data.y_range) {
      //Firstly test with metabolic profile chart
      if (type && !useDefault) {
        // //localStorage.setItem('defaultMPsettings', JSON.stringify(data.x_range));
        let yVal: string | null = localStorage.getItem(`MPYsettings${chart_index}_${testId}`);
        if (yVal) {
          data.y_range = JSON.parse(yVal);
        }
      }
      chart.y_range = this.createRange(data.y_range);
    }
  }

  public addCallBackHoverEventToChartMetabolicProfile(data: any, chart: { id: any; add_tools: (arg0: any) => void }, test: any = null) {
    if (test) {
      const primary_unit: string = this.findXAxisLabel(test.sport.primary_type);
      const secondary_unit: string = this.findXAxisLabel(test.sport.secondary_type);
      let test_data = _.cloneDeep(test);
      test_data.primary_unit = primary_unit;
      test_data.secondary_unit = secondary_unit;
      let code: string =
        'let data = this.attributes.args.data.data; let id = this.attributes.id ;let geometry = cb_data["geometry"]; let test = this.attributes.args.data.test_data; bokeh_callback(data, geometry, id, test);';
      let callback = new Bokeh.CustomJS({
        id: chart.id,
        code: code,
        args: new Bokeh.ColumnDataSource({
          data: {
            data,
            test_data,
          },
        }),
      });

      chart.add_tools(
        new Bokeh.HoverTool({
          callback: callback,
        })
      );
      return chart;
    } else {
      return this.addCallBackHoverEventToChart(data, chart);
    }
  }

  public addCallBackHoverEventToChart(data: any, chart: { id: any; add_tools: (arg0: any) => void }) {
    let code: string = 'let data = this.attributes.args.data; let id = this.attributes.id ;let geometry = cb_data["geometry"]; bokeh_callback(data, geometry, id);';
    let callback = new Bokeh.CustomJS({
      id: chart.id,
      code: code,
      args: new Bokeh.ColumnDataSource({
        data: data,
      }),
    });

    chart.add_tools(
      new Bokeh.HoverTool({
        callback: callback,
      })
    );

    return chart;
  }

  public createLegendInfo(legendData: any, plot: { y: any; line_dash: any }, configData: any, labelText: any) {
    let info: any[] = _.filter(configData, (data) => {
      return data.name == plot.y;
    });

    return {
      id: plot.y,
      legendText: info[0].legend,
      valueText: labelText,
      style: {color: this.color(info[0].color),},
      type: plot.line_dash,
    };
  }

  public createLegend(attr: any) {
    return {
      lines: [],
      id: attr.id,
      dataName: attr.dataName,
      style: {
        width: attr.width,
      },
      x: {
        valueText: attr.valueText,
        action: attr.action,
        name: 'x_disp',
        style: {
          color: '#000000',
        },
      },
      isMissingPower: false,
      isMissingSpeed: false,
    };
  }

  public createLayout(layout: any, chart: any) {
    for (let type in layout) {
      if (type != 'axis_position' && layout.hasOwnProperty(type)) {
        if (layout[type] != null) {
          switch (type) {
            case 'linear_axis':
              chart = this.createLinearAxis(
                layout[type],
                chart,
                layout['axis_position']
              );
              break;
          }
        }
      }
    }

    return chart;
  }

  public createLine(
    line: {
      source: { [x: string]: any };
      x: string | number;
      y: string | number;
      line_color: any;
      line_dash: any;
      legend: any;
      alpha: any;
    },
    chart: {
      line: (arg0: {
        x: { field: string };
        y: { field: string };
        source: any;
        line_width: number;
        line_color: any;
        line_dash: any[];
        legend: any;
        alpha: any;
      }) => any;
    }
  ) {
    let glyph = chart.line({
      x: {field: 'x',},
      y: {field: 'y',},
      source: new Bokeh.ColumnDataSource({
        data: {
          x: line.source[line.x], //.map(function(val) { return val == null ? NaN : val }),
          y: line.source[line.y], //.map(function(val) { return val == null ? NaN : val })
        },
      }),
      line_width: 2,
      line_color: line.line_color,
      line_dash: [line.line_dash],
      legend: line.legend,
      alpha: line.alpha,
    });

    return {
      chart: chart,
      glyph: glyph,
    };
  }

  public createRange(range: { plot_range: any[]; bounds: any }) {
    return new Bokeh.Range1d({
      start: range.plot_range[0],
      end: range.plot_range[1],
      bounds: range.bounds,
    });
  }

  public createExtraRange(range: any) {
    let extradr: any = {};
    for (let range_name in range) {
      if (range.hasOwnProperty(range_name)) {
        // IAT-2354 Use default value [0,100], not depending on plots data
        // extradr[range_name] = new Bokeh.Range1d({
        //   start: ChartConstants.RANGE.START,
        //   end: ChartConstants.RANGE.END,
        //   // Remove bounding to NOT prevent the user from
        //   // panning/zooming/etc
        //   // bounds: range[range_name].bounds,
        // });

        extradr[range_name] = new Bokeh.Range1d({
          start: range[range_name].plot_range[0],
          end: range[range_name].plot_range[1],
          bounds: range[range_name].bounds,
        });
      }
    }

    return extradr;
  }

  public createExtraRangeForPerformanceDevelopment(range: any, testId: any) {
    let extradr: any = {};
    for (let range_name in range) {
      if (range.hasOwnProperty(range_name)) {
        // IAT-2354 Use default value [0,100], not depending on plots data
        let xVal: any = localStorage.getItem('PDYsettings_' + testId);
        let start: number = ChartConstants.RANGE.START;
        let end: number = ChartConstants.RANGE.END;
        if (xVal) {
          xVal = JSON.parse(xVal);
          start = xVal.plot_range[0];
          end = xVal.plot_range[1];
        }
        extradr[range_name] = new Bokeh.Range1d({
          start: start,
          end: end,
          // Remove bounding to NOT prevent the user from
          // panning/zooming/etc
          // bounds: range[range_name].bounds,
        });

        // extradr[range_name] = new Bokeh.Range1d({
        //   start: range[range_name].plot_range[0],
        //   end: range[range_name].plot_range[1],
        //   bounds: range[range_name].bounds,
        // });
      }
    }

    return extradr;
  }

  public createLinearAxis(
    layout: {
      y_range_name: any;
      axis_label: any;
      axis_label_text_font_size: any;
    },
    chart: { add_layout: (arg0: any, arg1: any) => void },
    position: any
  ) {
    chart.add_layout(
      new Bokeh.LinearAxis({
        y_range_name: layout.y_range_name,
        axis_label: layout.axis_label,
        axis_label_text_font_size: layout.axis_label_text_font_size,
      }),
      position
    );

    return chart;
  }

  public createFigureChart(attrs: any) {
    return this.plottingAPI.figure(attrs);
  }

  public postRenderChartLayout(chart: any, data: any) {
    chart.yaxis.axis_label = data['y_axis_label'] || data['yaxis.axis_label'];
    chart.yaxis.axis_label_text_font_size = '10pt';
    chart.yaxis.axis_label_text_font_style = 'normal';
    chart.yaxis.axis_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
    chart.yaxis.major_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
    chart.xaxis.axis_label = data['x_axis_label'] || data['xaxis.axis_label'];
    chart.xaxis.axis_label_text_font_size = '10pt';
    chart.xaxis.axis_label_text_font_style = 'normal';
    chart.xaxis.axis_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
    chart.xaxis.major_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;

    if (chart.right) {
      _.each(chart.right, (right): void => {
        right.attributes.axis_label_text_font_size = '10pt';
        right.attributes.axis_label_text_font_style = 'normal';
        right.attributes.axis_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
        right.attributes.major_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
      });
    }

    if (chart.left) {
      _.each(chart.left, (left): void => {
        left.attributes.axis_label_text_font_size = '10pt';
        left.attributes.axis_label_text_font_style = 'normal';
        left.attributes.axis_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
        left.attributes.major_label_text_color = CHART_AXIS_LABEL_TEXT_COLOR;
      });
    }

    //override chart title attributes
    chart.title.text = data['title_text'];
    chart.title.align = data['title_align'] || data['title.align'];
    chart.title.text_font_size = '12pt';
    chart.title.text_color = CHART_TITLE_COLOR;

    //override chart legend attributes
    chart._legend.location = 'top_left';
    chart._legend.background_fill_color = CHART_BACKGROUND_FILL_COLOR;
    chart._legend.border_line_width = LEGEND_BORDER_WIDTH;
    chart._legend.background_fill_alpha = LEGEND_BACKGROUND_FILL_ALPHA;
    chart._legend.label_text_color = LABEL_TEXT_COLOR;

    //override chart border attributes
    chart.min_border_left = 40;
    chart.min_border_right = 40;

    //remove grid lines in chart
    chart.xgrid.grid_line_color = GRID_LINE_COLOR;
    chart.ygrid.grid_line_color = GRID_LINE_COLOR;

    return chart;
  }

  public createGridPlot(listPlots: string | any[]): any[][] {
    let gridPlots: any[] = [], plot1, plot2, plotArray;

    for (let j = 0; j < listPlots.length; j += 2) {
      plotArray = [];
      plot1 = listPlots[j];
      plot2 = listPlots[j + 1];
      plotArray.push(plot1);
      if (plot2) {
        plotArray.push(plot2);
      }
      gridPlots.push(plotArray);
    }

    return gridPlots;
  }

  public splitUpChartInfoLayout(elementId: any): void {
    setTimeout((): void => {
      const targetElement: Element | null = this.customRendererService.select(elementId);
      const childNodeList: NodeListOf<Element> | undefined = this.customRendererService.getChildNodes(targetElement, "div[id*='modelid_Row-']");
      let chartWidth: string = '500';

      childNodeList?.forEach((ele: Element): void => {
        const appendIndex: number[] = [];
        const elementList: NodeListOf<ChildNode> = ele.childNodes;
        elementList.forEach((element: any): void => {
          chartWidth = String(element.offsetWidth);
          const childId = element.id.split('modelid_')[1];
          const legends: NodeListOf<HTMLElement> = this.customRendererService.selectAll('.bokeh-legend');
          legends.forEach((legend: any, index: number): void => {
            if (legend.id == childId) {
              appendIndex.push(index);
            }
          });
        });

        appendIndex.forEach((indexValue: number): void => {
          let legendEle: HTMLElement = this.customRendererService.selectAll('.bokeh-legend')[indexValue];
          legendEle.style.width = chartWidth;
          this.customRendererService.appendNode(ele, legendEle);

        });
      });
    }, 500);
  }

  public color(color: any): string {
    return this.colorToHex(color);
  }

  public colorToRGBA(color: any): Uint8ClampedArray | null {
    // Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
    // color must be a valid canvas fillStyle. This will cover most anything
    // you'd want to use.
    // Examples:
    // colorToRGBA('red')  # [255, 0, 0, 255]
    // colorToRGBA('#f00') # [255, 0, 0, 255]
    let cvs, ctx;
    cvs = document.createElement('canvas');
    cvs.height = 1;
    cvs.width = 1;
    ctx = cvs.getContext('2d');
    if (ctx) {
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, 1, 1);

      return ctx.getImageData(0, 0, 1, 1).data;
    } else {
      return null;
    }
  }

  public byteToHex(num: any): string {
    // Turns a number (0-255) into a 2-character hex number (00-ff)
    return ('0' + num.toString(16)).slice(-2);
  }

  public colorToHex(color: any): string {
    // Convert any CSS color to a hex representation
    // Examples:
    // colorToHex('red')            # '#ff0000'
    // colorToHex('rgb(255, 0, 0)') # '#ff0000'
    let rgba: any, hex: any;
    rgba = this.colorToRGBA(color);
    hex = [0, 1, 2]
      .map((idx: number) => {
        return this.byteToHex(rgba[idx]);
      }).join('');

    return '#' + hex;
  }

  public calculateAspectRatioFit(srcWidth: any, srcHeight: any, maxWidth: any, maxHeight: any) {
    let ratio: number = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return {
      width: srcWidth * ratio,
      height: srcHeight * ratio,
    };
  }

  public drawHumanBody(canvas: any, data: any, currentTheme: string = localStorage.getItem('theme')!, width: number = 240, height: number = 330): void {
    const self = this;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    // // const step_fill_styles = ['rgba(161, 161, 161, 0.4)', 'rgba(0, 114, 188, 0.4)', 'rgba(242, 108, 79, 0.4)', 'rgba(229, 50, 39, 0.4)'];
    const step_fill_styles_vector = [
      [161, 161, 161, 0.4],
      [0, 114, 188, 0.4],
      [242, 108, 79, 0.4],
      [229, 50, 39, 0.4],
    ];
    const imageObj: HTMLImageElement = new Image();
    imageObj.onload = function (): void {
      // Width & Height of original image for body shape
      let img_width: number = 872;
      let img_height: number = 1020;
      ////ctx.drawImage(imageObj, 0, 0, img_width, img_height, 0, 0, canvas.width, canvas.height);
      ctx.drawImage(imageObj, img_width / 4, img_height / 16, (img_width * 3) / 4, (img_height * 14) / 16, 0, 0, canvas.width, canvas.height);
      const frame_start_x: number = 0;
      const frame_start_y: number = 0;
      const frame_width: number = width - 5;
      const frame_height: number = height - 5;
      let sum: number = 0;
      const top_value: number[] = [0];
      const display_text_pos_x: number = frame_start_x + frame_width * 0.65;
      const display_text_pos_y: any[] = [];
      const display_text_difference_y: number = frame_height * 0.04;
      const circle_pos_x: number = frame_start_x + frame_width * 0.4;
      const circle_pos_y: any[] = [];
      for (let i = 0; i < data.length; i++) {
        display_text_pos_y.push(frame_start_y + sum + ((frame_height * data[i].value) / 100.0) * 0.45);
        circle_pos_y.push(display_text_pos_y[i] + frame_height * 0.01);
        sum += (frame_height * data[i].value) / 100.0;
        top_value.push(parseInt(sum.toString()));
      }
      top_value[top_value.length - 1] = img_height;
      const imgd = ctx.getImageData(0, 0, width, height);
      const pix = imgd.data;

      self.handleColorHumanBody(pix, data, top_value, width, currentTheme, step_fill_styles_vector);
      ctx.putImageData(imgd, 0, 0);
      self.handleNoteHumanBody(data, ctx, currentTheme, display_text_pos_x, display_text_pos_y, display_text_difference_y, circle_pos_x, circle_pos_y);
    };
    imageObj.crossOrigin = 'anonymous';

    if (currentTheme == AppConstants.THEME.LIGHT) {
      imageObj.src = '/../assets/images/human_body_light.png';
    } else {
      imageObj.src = '/../assets/images/human_body_dark.png';
    }
  }

  private handleNoteHumanBody(
    data: any,
    ctx: any,
    currentTheme: any,
    display_text_pos_x: any,
    display_text_pos_y: any,
    display_text_difference_y: any,
    circle_pos_x: any,
    circle_pos_y: any
  ): void {
    for (let i = 0; i < data.length; i++) {
      ctx.save();
      if (currentTheme == AppConstants.THEME.DARK) {
        ctx.fillStyle = '#ddd';
      } else {
        ctx.fillStyle = '#333333';
      }
      ctx.font = '12px Arial';
      ctx.fillText(data[i].name, display_text_pos_x, display_text_pos_y[i]);
      ctx.fillText(data[i].value + ' %', display_text_pos_x, display_text_pos_y[i] + display_text_difference_y);
      ctx.beginPath();
      ctx.moveTo(circle_pos_x, circle_pos_y[i]);
      ctx.lineTo(display_text_pos_x - 2, circle_pos_y[i]);
      ctx.arc(circle_pos_x, circle_pos_y[i], 4, 0, Math.PI * 2, false);
      ctx.fill();
      if (currentTheme == AppConstants.THEME.DARK) {
        ctx.strokeStyle = '#dddddd';
      } else {
        ctx.strokeStyle = '#333333';
      }
      ctx.stroke();
      ctx.restore();
    }
  }

  private handleColorHumanBody(pix: any, data: any, top_value: any, width: any, currentTheme: any, step_fill_styles_vector: any): void {
    const n = pix.length;
    for (let i = 0; i < n; i += 4) {
      const color_idx: number = this.getColorIdxHumanBody(data, i, top_value, width);
      // Replace absolute black (0,0,0) to colored colors (blue, gray, orange, red)
      // Improvement anti-alias: replace (black->gray) to gray with increasing opacity in else part
      if ((currentTheme == AppConstants.THEME.DARK && pix[i] == 0) || (currentTheme == AppConstants.THEME.LIGHT && pix[i] == 255)) {
        pix[i] = step_fill_styles_vector[color_idx][0];
        pix[i + 1] = step_fill_styles_vector[color_idx][1];
        pix[i + 2] = step_fill_styles_vector[color_idx][2];
        pix[i + 3] = 255;
      } else if (pix[i] < 41) {
        pix[i] = 41;
        pix[i + 1] = 41;
        pix[i + 2] = 41;
        pix[i + 3] = pix[i] * 5;
      }
    }
  }

  private getColorIdxHumanBody(data: any, i: any, top_value: any, width: any): number {
    let color_idx: number = 0;
    for (let j = 0; j < data.length; j++) {
      if (i <= top_value[j + 1] * width * 4) {
        color_idx = j;
        break;
      }
    }

    return color_idx;
  }

  public generateMetabolicCapacitiesGaugeChart(args: any): void {
    args.data.forEach((value: any, key: number) => {
      let ctx: any = this.customRendererService.select(`#gauge-${key}`);
      let context = ctx.getContext('2d');
      let backgroundColor = [
        ChartConstants.COLOR.RED,
        ChartConstants.COLOR.BLUE,
      ];

      return new Chart(context, {
        type: 'gauge',
        data: {
          datasets: [
            {
              value: value.current,
              minValue: value.min,
              data: [value.current, value.max],
              backgroundColor: backgroundColor,
              borderWidth: 0,
            },
          ],
        },
        options: {
          needle: {
            radiusPercentage: 2,
            widthPercentage: 3.2,
            lengthPercentage: 80,
            color: '#888888',
          },
          valueLabel: {
            display: false,
          },
          layout: {
            padding: {
              bottom: 15,
            },
          },
        },
      });
    });
  }

  public replaceCorrectPhotoURL(photoURL: string): string {
    if (!photoURL) return '';

    return photoURL.replace('media/uploads', 'dist');
  }

  public generateHeartRatePlot(data: any, test: any, width = CHART_HALF_WIDTH, height = CHART_HEIGHT): void {
    const elementId: string = '#td-heart-rate-chart';
    const plt = Bokeh.Plotting;
    let x = data.x;
    let y = data.y;
    let circleSource = new Bokeh.ColumnDataSource({
      data: {
        x: x, // Display Heart Rate on x
        y: y, // Display Speed / Power on y
        radius: [5],
        color: '#FFB300',
      },
    });

    // make a plot with some tools
    const plot = this.plottingAPI.figure({
      tools: 'box_zoom,crosshair,reset,pan',
      toolbar_location: 'above',
      height,
      width,
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
    });
    plot.title.text = 'Heart rate vs. intensity';
    plot.title.text_font_size = '10pt';

    plot.circle(
      {field: 'x'},
      {field: 'y'},
      {
        source: circleSource,
        size: 6,
        fill_color: circleSource.data.color,
        line_color: null,
        // legend: "Data Point",
        fill_alpha: 1,
      }
    );

    if (_.indexOf(this.pace_types, test.sport.primary_type) != -1) {
      plot.xaxis.formatter = new Bokeh.FuncTickFormatter({
        code: `return calculateHeartRateTicker(tick, ${test.sport.primary_type});`,
      });
    }

    plot.yaxis.axis_label_text_font_size = '10pt';
    plot.yaxis.axis_label_text_font_style = 'normal';
    plot.yaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    plot.yaxis.major_label_text_color = LABEL_TEXT_COLOR;
    plot.xaxis.axis_label_text_font_size = '10pt';
    plot.xaxis.axis_label_text_font_style = 'normal';
    plot.xaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    plot.xaxis.major_label_text_color = LABEL_TEXT_COLOR;
    plot.xgrid.grid_line_color = GRID_LINE_COLOR;
    plot.ygrid.grid_line_color = GRID_LINE_COLOR;
    plot.title.text_color = CHART_TITLE_COLOR;

    let lineSource = new Bokeh.ColumnDataSource({
      data: {
        x: x,
        y: x.map((item: any) => {
          return data.slope * item + data.intercept;
        }),
      },
    });

    plot.line(
      {field: 'x'},
      {field: 'y'},
      {
        source: lineSource,
        line_width: 2,
        line_color: '#C10020',
      }
    );
    plot.yaxis.axis_label = 'Heart Rate [bpm]';
    plot.xaxis.axis_label = data.label;
    // show the plot, appending it to the end of the current section
    this.customRendererService.empty(elementId);
    plt.show(plot, elementId);

    setTimeout((): void => {
      this.customRendererService.addClass('.bk-canvas-events', ['water-mark']);
    }, 200);
  }

  public generateMetabolicPower(data: any) {
    let ctx: HTMLElement | null = document.getElementById('metabolic_power');
    const backgroundColors: string[] = [];
    const secondBackgroundColors: string[] = [];
    let labels: string[] = [];
    const dataSets: any[] = [];
    const axisLabel = this.findXAxisLabel(data.test.sport.primary_type);
    const data_df = {
      power: data.test.data_df_power,
      vms: data.test.data_df_vms,
    };

    _.forEach(data.data, (item: any, index: number): void => {
      labels = [];
      const data1: any[] = [];
      if (!item) {
        return;
      }

      if (item.vlamax !== undefined) {
        data1.push(item.vlamax);
        backgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.vlamax.first);
        secondBackgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.vlamax.second);
        labels.push('VLamax');
      }

      if (item.vo2max !== undefined) {
        data1.push(item.vo2max);
        backgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.vo2max.first);
        secondBackgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.vo2max.second);

        labels.push('VO2max');
      }
      if (item.at !== undefined) {
        data1.push(item.at);
        backgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.at.first);
        secondBackgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.at.second);

        labels.push('Anaerobic Threshold');
      }
      if (item.fatmax !== undefined) {
        data1.push(item.fatmax);
        backgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.fatmax.first);
        secondBackgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.fatmax.second);

        labels.push('FatMax');
      }
      if (item.carbmax !== undefined) {
        data1.push(item.carbmax);
        backgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.carbmax.first);
        secondBackgroundColors.push(AppConstants.METABOLIC_MAPPING_COLOR.carbmax.second);

        labels.push('Carbmax');
      }
      dataSets.push({
        backgroundColor: index === 0 ? backgroundColors : secondBackgroundColors,
        data: data1,
      });
    });

    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: dataSets,
      },

      options: {
        legend: {
          display: false,
          position: 'top',
          labels: {
            fontColor: '#000080',
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: LABEL_TEXT_COLOR,
                beginAtZero: true,
                callback: (value: any) => {
                  return this.convertUnit(
                    value,
                    data.test.sport.primary_type,
                    data.test.sport.simulation_type,
                    data.test.mass,
                    data_df
                  );
                },
              },
              scaleLabel: {
                fontColor: LABEL_TEXT_COLOR,
                display: true,
                labelString: axisLabel,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: LABEL_TEXT_COLOR,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  public closest(list: Array<number>, x: number) {
    let min, chosen: any = 0;

    for (const i in list) {
      min = Math.abs(list[chosen] - x);
      if (Math.abs(list[i] - x) < min) {
        chosen = i;
      }
    }

    return chosen;
  }

  public convertUnit(value: any, type: any, simulation_type: any, mass: any, data: any = null) {
    let result: any = parseFloat(_.cloneDeep(value));
    let closestIndex = this.closest(data['power'], value);

    if (!data?.power || !data?.vms) {
      return 'N/A';
    }

    if ([6, 7, 8, 9].indexOf(type) != -1 && value == 0) {
      return '59:59';
    } else if (value == 0) {
      return 0;
    }

    if (simulation_type == 1) {
      closestIndex = this.closest(data['vms'], value);
    }
    if (type == 1 && simulation_type == 2) {
      // Case POWER_WATT to POWER_WATT
      result = result.toFixed(0);
    } else if (type == 3 && simulation_type == 1) {
      // Case SPEED_MS to SPEED_MS
      result = result.toFixed(2);
    } else if (type == 1 && simulation_type == 1) {
      // Case SPEED_MS to POWER_WATT
      if (data['power'][closestIndex]) {
        result = data['power'][closestIndex].toFixed(0);
      } else {
        result = 'N/A';
      }
    } else if (type == 2 && simulation_type == 1) {
      // Case SPEED_MS to POWER_WATT_KG
      if (data['power'][closestIndex]) {
        result = (data['power'][closestIndex] / mass).toFixed(2);
      } else {
        result = 'N/A';
      }
    } else if (type == 2 && simulation_type == 2) {
      // Case POWER_WATT to POWER_WATT_KG
      result = (result / mass).toFixed(2);
    } else if (type == 3 && simulation_type == 2) {
      // Case POWER_WATT to SPEED_MS
      if (data['vms'][closestIndex]) {
        result = data['vms'][closestIndex].toFixed(2);
      } else {
        result = 'N/A';
      }
    } else if (type == 4 && simulation_type == 2) {
      // Case POWER_WATT to SPEED_KM_H
      if (data['vms'][closestIndex]) {
        result = (data['vms'][closestIndex] * 3.6).toFixed(1);
      } else {
        result = 'N/A';
      }
    } else if (type == 5 && simulation_type == 2) {
      // Case POWER_WATT to SPEED_MP_H
      if (data['vms'][closestIndex]) {
        result = ((data['vms'][closestIndex] * 3.6) / 1.61).toFixed(1);
      } else {
        result = 'N/A';
      }
    } else if (type == 6 && simulation_type == 2) {
      // Case POWER_WATT to SPEED_MIN_SEC_KM
      if (data['vms'][closestIndex]) {
        result = this.convertValueToTime(60 / (data['vms'][closestIndex] * 3.6)).join(':');
      } else {
        result = 'N/A';
      }
    } else if (type == 7 && simulation_type == 2) {
      // Case POWER_WATT to SPEED_MIN_SEC_MILE
      if (data['vms'][closestIndex]) {
        result = this.convertValueToTime((60 / (data['vms'][closestIndex] * 3.6)) * 1.61).join(':');
      } else {
        result = 'N/A';
      }
    } else if (type == 8 && simulation_type == 2) {
      // Case POWER_WATT to SPEED_MIN_SEC_100M
      if (data['vms'][closestIndex]) {
        result = this.convertValueToTime(60 / (data['vms'][closestIndex] * 3.6) / 10).join(':');
      } else {
        result = 'N/A';
      }
    } else if (type == 9 && simulation_type == 2) {
      // Case POWER_WATT to SPEED_MIN_SEC_500M
      if (data['vms'][closestIndex]) {
        result = this.convertValueToTime(60 / (data['vms'][closestIndex] * 3.6) / 2).join(':');
      } else {
        result = 'N/A';
      }
    } else if (type == 4) {
      // Case SPEED_MS to SPEED_KM_H
      result = (result * 3.6).toFixed(1);
    } else if (type == 5) {
      // Case SPEED_MS to SPEED_MP_H
      result = ((result * 3.6) / 1.61).toFixed(1);
    } else if (type == 6) {
      // Case SPEED_MS to SPEED_MIN_SEC_KM
      result = this.convertValueToTime(60 / (result * 3.6)).join(':');
    } else if (type == 7) {
      // Case SPEED_MS to SPEED_MIN_SEC_MILE
      result = this.convertValueToTime((60 / (result * 3.6)) * 1.61).join(':');
    } else if (type == 8) {
      // Case SPEED_MS to SPEED_MIN_SEC_100M
      result = this.convertValueToTime(60 / (result * 3.6) / 10).join(':');
    } else if (type == 9) {
      // Case SPEED_MS to SPEED_MIN_SEC_500M
      result = this.convertValueToTime(60 / (result * 3.6) / 2).join(':');
    }

    return result;
  }

  public convertValueToTime(value: any) {
    if (value === 0) {
      return ['00', '00'];
    }

    let result = value.toFixed(2);
    result = result.split('.');

    if (result[0].length == 1) {
      result[0] = '0' + result[0];
    }
    if (result.length > 1) {
      result[1] = Math.round(parseFloat('0.' + result[1]) * 60).toString();
      if (result[1].length == 1) {
        result[1] = '0' + result[1];
      }
    }

    return result;
  }

  public dataSetEconomyChart(data: any): any[] {
    const dataSets: any[] = [
      {
        backgroundColor: '#000080',
        label: '',
        data: [],
      },
    ];
    _.forEach(data.economy_values, (item): void => {
      if (data.isConvert) {
        if (data.simulation_type == AppConstants.SIMULATE_TYPE.SPEED) {
          dataSets[0].data.push(item.speed_percent_value);
        } else {
          dataSets[0].data.push(item.power_percent_value);
        }
      } else {
        dataSets[0].data.push(item.vo2_tot_percent_value);
      }
    });

    return dataSets;
  }

  public generateEconomyChart(data: any) {
    let ctx: HTMLElement | null = document.getElementById('economy-chart');
    const labels: any[] = [];
    _.forEach(data.economy_values, (item): void => {
      labels.push(item.label);
    });

    return new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: this.dataSetEconomyChart(data),
      },
      options: {
        legend: {
          display: false,
          position: 'top',
          labels: {
            fontColor: '#4876ff',
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: LABEL_TEXT_COLOR,
                beginAtZero: true,
              },
              scaleLabel: {
                fontColor: LABEL_TEXT_COLOR,
                display: true,
                labelString:
                  '% difference in measured energy demand vs. standard value',
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: LABEL_TEXT_COLOR,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  public makeLabelSet(data: any) {
    const label = new Bokeh.LabelSet({
      x: {field: 'x',},
      y: {field: 'y',},
      source: new Bokeh.ColumnDataSource({
        data: {
          x: data.x,
          y: data.y,
          text: data.texts,
        },
      }),
      text: {
        field: 'text',
      },
      render_mode: 'canvas',
      x_offset: 5,
      y_offset: 5,
      text_color: data.color,
      text_font_size: data.text_font_size,
    });
    label.y_range_name = 'y2';

    return label;
  }

  public generateTimeToDepletionChart(data: any) {
    const elementId: string = '#time-to-depletion-chart';
    const plt = Bokeh.Plotting;
    const x = data.x;

    this.updateColorTheme(localStorage.getItem('theme')!);
    // make a plot with some tools
    let plot = Bokeh.Plotting.figure({
      tools: 'pan, box_zoom, crosshair, reset',
      toolbar_location: 'above',
      height: 450,
      width: 900,
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
      x_range: new Bokeh.Range1d({start: 0, end: Math.max(...x)}),
      y_range: new Bokeh.Range1d({start: 0, end: 400}),
      extra_y_ranges: {y2: new Bokeh.Range1d({start: 0, end: 6})},
      sizing_mode: 'scale_width',
    });
    plot.add_tools(
      new Bokeh.CrosshairTool({
        line_color: CROSSHAIR_LINE_COLOR,
      })
    );

    this.mappingDataDF(data.test, data);

    plot.title.text = 'TTD Chart';
    plot.title.text_font_size = '10pt';
    // setup the style for chart
    plot.yaxis.axis_label_text_font_size = '10pt';
    plot.yaxis.axis_label_text_font_style = 'normal';
    plot.yaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    plot.yaxis.major_label_text_color = LABEL_TEXT_COLOR;
    plot.xaxis.axis_label_text_font_size = '10pt';
    plot.xaxis.axis_label_text_font_style = 'normal';
    plot.xaxis.axis_label_text_color = LABEL_TEXT_COLOR;
    plot.xaxis.major_label_text_color = LABEL_TEXT_COLOR;
    plot.xgrid.grid_line_color = GRID_LINE_COLOR;
    plot.ygrid.grid_line_color = GRID_LINE_COLOR;
    plot.title.text_color = CHART_TITLE_COLOR;

    let lineSource = new Bokeh.ColumnDataSource({
      data: {
        x: x,
        y: data.yleft,
      },
    });
    let lineObject = new Bokeh.Line({
      x: {field: 'x'},
      y: {field: 'y'},
      line_color: AppConstants.TIME_TO_DEPLETION_COLORS.CARBOHYDRATES,
      line_width: 2,
    });
    plot.add_glyph(lineObject, lineSource);
    const dataHover = {
      data_0carbohydrate: lineSource.data.y,
      data_0fueling1: data.yright[0],
      data_0fueling2: data.yright[1],
      x: lineSource.data.x,
      x_disp: lineSource.data.x,
      power: data?.data_df?.power,
      vms: data?.data_df?.vms,
    };
    plot = this.addCallBackHoverEventToChartMetabolicProfile(
      dataHover,
      plot,
      data.test
    );

    plot.yaxis.axis_label = 'Carbohydrate [g/h]';
    plot.xaxis.axis_label = this.findXAxisLabel(data.test.sport.primary_type);
    plot.xaxis.formatter = new Bokeh.FuncTickFormatter({
      code: `
      let listDataX = ${JSON.stringify(data.data_df)};
      return calculateTicker(tick, ${data.test.sport.primary_type}, ${
        data.test.sport.simulation_type
      }, ${data.test.mass}, listDataX);`,
    });

    // make fueling line 1
    let ttdFueling1 = new Bokeh.ColumnDataSource({
      data: {
        x: x,
        y: data.yright[0],
      },
    });
    const fuelingLine1 = plot.line(
      {field: 'x'},
      {field: 'y'},
      {
        source: ttdFueling1,
        line_color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD1,
        line_width: 2,
      }
    );
    fuelingLine1.y_range_name = 'y2';

    if (data.eventNames[1]) {
      // make fueling line 2
      let ttdFueling2 = new Bokeh.ColumnDataSource({
        data: {
          x: x,
          y: data.yright[1],
        },
      });
      const fuelingLine2 = plot.line(
        {field: 'x'},
        {field: 'y'},
        {
          source: ttdFueling2,
          line_color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD2,
          line_width: 2,
        }
      );
      fuelingLine2.y_range_name = 'y2';
    }

    // make label for 1 lines
    const horizontalLabel1 = this.makeLabelSet({
      x: [
        Math.max(...x) - data.horizontalData[0][0],
        Math.max(...x) - data.horizontalData[0][0],
      ],
      y: [data.horizontalData[0][1], data.horizontalData[0][1]],
      color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD1,
      texts: [data.labels[0]],
      text_font_size: '8pt',
    });
    horizontalLabel1.y_range_name = 'y2';
    plot.add_layout(horizontalLabel1);

    if (data.eventNames[1]) {
      // make label for 2 lines
      const horizontalLabel2 = this.makeLabelSet({
        x: [data.horizontalData[1][0], data.horizontalData[1][0]],
        y: [data.horizontalData[1][1], data.horizontalData[1][1]],
        color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD2,
        texts: [data.labels[1]],
        text_font_size: '8pt',
      });
      horizontalLabel2.y_range_name = 'y2';
      plot.add_layout(horizontalLabel2);
    }

    // # make data point for fueling line 1
    let ttdFuelingHorizontal1 = new Bokeh.ColumnDataSource({
      data: {
        x: [data.horizontalData[0][0], data.horizontalData[0][0]],
        y: [-3, data.horizontalData[0][1]],
      },
    });
    const fuelingLineHorizontal1 = plot.line(
      {field: 'x'},
      {field: 'y'},
      {
        source: ttdFuelingHorizontal1,
        line_color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD1,
        line_dash: [4, 4],
        line_width: 2,
      }
    );
    fuelingLineHorizontal1.y_range_name = 'y2';
    let ttdFuelingVertical1 = new Bokeh.ColumnDataSource({
      data: {
        x: [data.horizontalData[0][0], Math.max(...x)],
        y: [data.horizontalData[0][1], data.horizontalData[0][1]],
      },
    });
    const fuelingLineVertical1 = plot.line(
      {field: 'x'},
      {field: 'y'},
      {
        source: ttdFuelingVertical1,
        line_color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD1,
        line_dash: [4, 4],
        line_width: 2,
      }
    );
    fuelingLineVertical1.y_range_name = 'y2';

    if (data.eventNames[1]) {
      // # make data point for fueling line 2
      let ttdFuelingHorizontal2 = new Bokeh.ColumnDataSource({
        data: {
          x: [data.horizontalData[1][0], data.horizontalData[1][0]],
          y: [-3, data.horizontalData[1][1]],
        },
      });

      let ttdFuelingVertical2 = new Bokeh.ColumnDataSource({
        data: {
          x: [data.horizontalData[1][0], Math.max(...x)],
          y: [data.horizontalData[1][1], data.horizontalData[1][1]],
        },
      });
      const fuelingLineHorizontal2 = plot.line(
        {field: 'x'},
        {field: 'y'},
        {
          source: ttdFuelingHorizontal2,
          line_color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD2,
          line_dash: [4, 4],
          line_width: 2,
        }
      );
      fuelingLineHorizontal2.y_range_name = 'y2';
      const fuelingLineVertical2 = plot.line(
        {field: 'x'},
        {field: 'y'},
        {
          source: ttdFuelingVertical2,
          line_color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD2,
          line_dash: [4, 4],
          line_width: 2,
        }
      );
      fuelingLineVertical2.y_range_name = 'y2';
    }

    plot.add_layout(
      new Bokeh.LinearAxis({
        y_range_name: 'y2',
        axis_label: 'Time to depletion',
        axis_label_text_color: LABEL_TEXT_COLOR,
        major_label_text_color: LABEL_TEXT_COLOR,
      }),
      'right'
    );

    // Format TTD Y Right Axis to hh:mm style
    if (plot.right && plot.right.length > 0 && plot.right[0].attributes) {
      plot.right[0].attributes.formatter.doFormat = this.formatTTDYRightTicks;
    }
    plt.show(plot, elementId);
    const attr = {
      id: plot.id,
      name: data.name,
      width: plot.attributes.width,
      valueText: plot.xaxis.axis_label,
      action:
        _.indexOf(this.power_types, data.test.sport.primary_type) != -1
          ? 'Power'
          : 'Speed',
    };
    const legend: any = this.createLegend(attr);
    const lines = [
      {
        id: 'carbohydrate',
        legendText: 'Carbohydrate Combustion',
        style: {color: AppConstants.TIME_TO_DEPLETION_COLORS.CARBOHYDRATES},
        type: 'solid',
        valueText: 'g/h',
      },
      {
        id: 'fueling1',
        legendText: 'TTD 1',
        style: {color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD1},
        type: 'solid',
        valueText: '',
      },
    ];
    if (data.eventNames[1]) {
      lines.push({
        id: 'fueling2',
        legendText: 'TTD 2',
        style: {color: AppConstants.TIME_TO_DEPLETION_COLORS.TTD2},
        type: 'solid',
        valueText: '',
      });
    }
    legend['dataInfo'] = [
      {
        index: 0,
        lines: lines,
        name: attr.name,
        typeClass: 'continued_line',
        typeString: '___',
      },
    ];
    setTimeout(() => {
      this.customRendererService.addClass('.bk-canvas-events', ['water-mark']);
    }, 200);

    return legend;
  }

  public formatTTDYRightTicks(ticks: any[]): any[] {
    try {
      return ticks.map((x) => String(x).padStart(2, '0') + ':00');
    } catch (e) {
      console.log('formatTTDYRightTicks', e);
      return ticks;
    }
  }

  public initChartSetting(): void {
    if (!this.event.test_settings.chart_settings) {
      this.event.test_settings.chart_settings = {};
    } else if (typeof this.event.test_settings.chart_settings === 'string' || this.event.test_settings.chart_settings instanceof String) {
      this.event.test_settings.chart_settings = JSON.parse(
        this.event.test_settings.chart_settings
      );
    }
  }
}
