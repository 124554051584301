import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "../services/api.service";
import { AppConstants } from "../constants";
import { map } from "rxjs/operators";

interface StatusResponse {
  api_call_id: number
  status: "paid" | "failed"
}

export const humangoMetabolicProfileGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const apiCallId: string | null = route.queryParamMap.get('api_call_id');
  if (apiCallId == null) {
    return false;
  }

  return true;
};
