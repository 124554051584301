import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReadReportTemplates, ReportTemplate, ReportTemplateService } from "@core/services/report-template/report-template.service";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { CdkColumnDef } from "@angular/cdk/table";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { TranslateBooleanTextPipe } from "@shared/pipes/translate-boolean-text.pipe";
import { MatDialogRef} from "@angular/material/dialog";


interface ReportTemplateForTable extends Omit<ReportTemplate, 'is_active'> {
  is_active: string;
}

@Component({
  selector: 'app-report-templates-list',
  templateUrl: './report-templates-list.component.html',
  styleUrls: ['./report-templates-list.component.scss'],
  providers: [CdkColumnDef, TranslateBooleanTextPipe]
})
export class ReportTemplatesListComponent implements OnInit, OnChanges {
  @Input() public searchTerm: string | undefined;

  @Output() public editReport: EventEmitter<ReportTemplate> = new EventEmitter<ReportTemplate>();
  @Output() public copyReport: EventEmitter<ReportTemplate> = new EventEmitter<ReportTemplate>();

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;

  public dataSource: MatTableDataSource<ReportTemplateForTable>;
  public dataColumns: string[] = ['id', 'name', 'description', 'active', 'actions'];
  public reportTemplates: Array<ReportTemplate> = [];

  constructor(private readonly reportTemplateService: ReportTemplateService,
              private readonly dialog: MatDialog,
              private readonly translateBooleanPipe: TranslateBooleanTextPipe) { }

  public ngOnInit(): void {
    this.load();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchTerm && !changes.searchTerm.firstChange && changes.searchTerm.previousValue !== changes.searchTerm.currentValue) {
      let filterValue = changes.searchTerm.currentValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
    }
  }

  public load(): void {
    this.reportTemplateService.getReportTemplates().subscribe((response: ReadReportTemplates) => {
      this.reportTemplates = response.results;

      const templatesForTable: Array<ReportTemplateForTable> = this.reportTemplates.map((item: ReportTemplate)=> ({
        id: item.id,
        name: item.name,
        description: item.description,
        is_active: this.translateBooleanPipe.transform(item.is_active),
        is_delete: item.is_delete,
        fields: item.fields,
        organization: item.organization
      }));

      this.dataSource =
        new MatTableDataSource<ReportTemplateForTable>(templatesForTable);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public onDeleteTemplate(id: number): void {
    const dialogRef: MatDialogRef<DeleteReportTemplateDialogComponent> = this.dialog.open(DeleteReportTemplateDialogComponent, {
        width: '550px'
      });

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'yes') {
        this.reportTemplateService.deleteReportTemplate(id).subscribe(() => {
          this.load();
        });
      }
    });
  }

  public onEditTemplate(id: number): void {
    const template: ReportTemplate | undefined = this.reportTemplates.find((templateItem: ReportTemplate): boolean => id === templateItem.id);
    this.editReport.emit(template);
  }

  public onCopyTemplate(id: number): void {
    const template: ReportTemplate | undefined = this.reportTemplates.find(templateItem => id === templateItem.id);
    this.copyReport.emit(template);
  }
}


@Component({
  selector: 'app-delete-report-template-dialog',
  template: `
    <h2 mat-dialog-title>Delete report template</h2>
      <mat-dialog-content>
        <p class="t-5">Are you sure you want to this?</p>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button type="button" mat-dialog-close
                tabindex="-1">CANCEL</button>
        <button cdkFocusInitial mat-raised-button color="warn" type="button"  [mat-dialog-close]="'yes'">Delete</button>
      </mat-dialog-actions>`
})
export class DeleteReportTemplateDialogComponent {

}
