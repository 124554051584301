import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnChanges,
  inject,
  Output, EventEmitter
} from '@angular/core';
import { IChartDataType, IChartValues } from "@shared/services/d3/multiple-x-axis-chart/d3-multiple-x-axis-chart.interface";
import { IChartDimension } from "@shared/services/d3/multiple-x-axis-chart/d3-multiple-x-axis-chart-dimension.interface";
import * as d3 from "d3";
import { CustomRendererService } from "@shared/services/custom-renderer.service";

@Component({
  selector: 'app-multiple-x-axis-chart',
  templateUrl: './multiple-x-axis-chart.component.html',
  styleUrls: ['./multiple-x-axis-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleXAxisChartComponent implements OnInit, OnChanges {
  public customRendererService: CustomRendererService = inject(CustomRendererService);

  @Input() public maxYAxisDomain: number;
  @Input() public minYAxisDomain: number;
  @Input() public chartData: Array<IChartDataType | number[]>;
  @Input() public chartDimensions: IChartDimension;

  @Output() public onChartGenerated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public getDashedParts(axisValues: IChartValues[], isDashed: boolean): IChartValues[] {
    const results: IChartValues[] = [];
    let previousWasMatch: boolean = false;

    axisValues.forEach((v: IChartValues, i: number): void => {
      if(isDashed === !!v.is_dashed) {
        if (!previousWasMatch && i > 0) {
          results.push(axisValues[i - 1]);
        }
        results.push(v);
        previousWasMatch = true;
      } else {
        results.push({ x: v.x, y: NaN });
        previousWasMatch = false;
      }
    });

    return results;
  }

  public ngOnInit(): void {
    this.generateChart();
  }

  public ngOnChanges(): void {
    this.generateChart();
  }

  private generateChart(): void {
    this.clearGraph();
    setTimeout((): void => {
      const chartData: any = this.chartData[0] as IChartDataType;
      const YRange: number[] = this.chartData[1] as unknown as number[];
      const xAxisCount: string[] = Object.keys(this.chartData[0]);
      const xAxisResultArray: any = [];
      const width: number = this.chartDimensions.chartConfig.width - this.chartDimensions.chartConfig.margins.left - this.chartDimensions.chartConfig.margins.right;
      const height: number = this.chartDimensions.chartConfig.height - this.chartDimensions.chartConfig.margins.top - this.chartDimensions.chartConfig.margins.bottom;
      const y = d3.scaleLinear().range(YRange).domain([this.minYAxisDomain, this.maxYAxisDomain]);

      this.chartData.pop();

      if (this.chartDimensions && this.chartData) {
        const svg = d3.select(`#${this.chartDimensions.chartConfig.elementId}`)
          .append("svg")
          .attr("width", width + this.chartDimensions.chartConfig.margins.left + this.chartDimensions.chartConfig.margins.right)
          .attr("height", height + this.chartDimensions.chartConfig.margins.top + this.chartDimensions.chartConfig.margins.bottom)
          .append("g")
          .attr("transform", `translate(${this.chartDimensions.chartConfig.margins.left},${this.chartDimensions.chartConfig.margins.top})`);

        svg.append("text")
          .attr("transform", `translate(${-30}, ${(height / 2) - 20}) rotate(-90)`)
          .attr("fill", '#15d5b8');
          // .text('Power');

        xAxisCount.forEach((value: string): void => {
          let xDomain: [number | undefined | any, number | undefined | any] = d3.extent(chartData[value].values, (d: IChartValues) => d.x);
          xAxisResultArray.push({
            xAxis: d3.scaleLinear().range([0, width]).domain(xDomain),
            xAxisName: value,
          });
        });

        xAxisResultArray.forEach(( axis: any, index: number): void => {
          const natureOrder: number = index + 1;

          if (chartData[axis.xAxisName].position) {
            if (natureOrder <= 3) {
              svg.append("g")
                .attr("class", chartData[axis.xAxisName].styles.xAxisClass)
                .attr("transform", `translate(0,${chartData[axis.xAxisName].position.xAxis})`)
                .call(d3.axisBottom(axis.xAxis).ticks(10));
            } else if (natureOrder > 2) {
              svg.append("g")
                .attr("class", chartData[axis.xAxisName].styles.xAxisClass)
                .attr("transform", `translate(0,${chartData[axis.xAxisName].position.xAxis})`)
                .call(d3.axisTop(axis.xAxis).ticks(10));
            }
          }

          let line: any = d3
            .line()
            .defined((d: any) => !isNaN(d.y))
            .x((d: any) => axis.xAxis(d.x))
            .y((d: any) => y(d.y));

          let lines = svg.append("g").attr("class", "lines");
          let lineGroups = lines
            .selectAll(".line-group")
            .data(this.chartData)
            .enter();

          svg.append("text")
            .attr("transform", `translate(${chartData[axis.xAxisName].position.xLabel}, ${chartData[axis.xAxisName].position.yLabel})`)
            .attr("fill", chartData[axis.xAxisName].styles.lineLabelTextColor)
            .text(chartData[axis.xAxisName].name);

          lineGroups
            .append("path")
            .attr("class", "line")
            .attr("d", (d: any) => line(this.getDashedParts(d[axis.xAxisName].values, false)));

          lineGroups
            .append("path")
            .attr("class", "line line-dashed")
            .attr("d", (d: any) => line(this.getDashedParts(d[axis.xAxisName].values, true)));

          lineGroups.each(function(d, i: number) {
            const lineParts = d3.select(this).selectAll("path");
            lineParts.style("stroke", chartData[axis.xAxisName].styles.lineColorStroke)
          });

          svg.append("path")
            .datum(this.chartData)
            .attr("stroke-width", 1)
            .attr("d", line);
        });

        svg.append("g")
          .attr("class", this.chartDimensions.chartConfig.yAxisClass)
          .call(d3.axisLeft(y));

      }

      this.onChartGenerated.emit(true);
    }, 1000)
  }

  private clearGraph(): void {
    this.customRendererService.empty(`#${this.chartDimensions.chartConfig.elementId}`);
  }
}
