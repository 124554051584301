<div>
  <div class="title-with-search">
    <p>Report templates</p>
    <mat-form-field appearance="outline">
      <input matInput
             [formControl]="searchFormControl"
             placeholder="Search Templates..."/>
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="group-actions mb-3">
    <button mat-raised-button color="primary"
            (click)="openAddTemplateDialog(null)">
      Add Template
    </button>
  </div>
  <app-report-templates-list [searchTerm]="searchTerm"
                             (editReport)="onEditReport($event)"
                             (copyReport)="onCopyReport($event)"></app-report-templates-list>
</div>
