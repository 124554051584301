<div>
    <div class="title-with-search">
        <p>Regions Pricing</p>
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput placeholder="Search" [(ngModel)]="searchText" (keyup.enter)="applyFilter($event)">
          <mat-icon matPrefix (click)="search()">search</mat-icon>
        </mat-form-field>
    </div>
    <div class="group-actions mb-3">
      <button mat-raised-button color="primary" class="ml-4" (click)="changeRegion()">{{ 'Change Region' }}</button>
      <button mat-raised-button color="primary" class="ml-4" (click)="add()">{{ 'Add' }}</button>
    </div>
    <table mat-table matSort #tableData [dataSource]="dataSource" class="mat-elevation-z8"
    style="width: 100%;" (matSortChange)="sortData($event)" matSortActive="id" matSortDirection="desc"
    matSortDisableClear aria-describedby="Regions pricing list">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-id" scope="row"> ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Name Of Regions</th>
      <td mat-cell *matCellDef="let element"> {{element?.name}}</td>
    </ng-container>
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Currency</th>
      <td mat-cell *matCellDef="let element"> {{element?.currency}} </td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Country</th>
      <td mat-cell *matCellDef="let element">
        <div class="region-section">
            <div class="region-name shadow-bg" *ngFor="let item of element?.countryArray">
              {{item}}
            </div>
          </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="action" *ngIf="isHadAction">
      <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element" class="fixed-width-action">
        <button *ngIf="rights.admin_devices_update" mat-raised-button color="primary" (click)="edit(element.id)" class="btn-icon-text">
          <mat-icon>mode_edit</mat-icon> Edit
        </button>
        <button *ngIf="rights.admin_devices_delete" mat-button (click)="deleteRegion(element.id)" class="btn-icon-text">
          <mat-icon>delete</mat-icon> Delete
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="isHadAction ? 7 : 6">No data available in table</td>
    </tr>
  </table>
  <div class="paging-display mt-2" *ngIf="dataSource.data.length">
    <mat-paginator (page)="getDataPage($event.pageIndex + 1)" [length]="totalItems" [pageSize]="pageSize">
    </mat-paginator>
  </div>
</div>
