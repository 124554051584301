import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConstants } from '@core/constants';
import { DialogsData } from '../../tabs/tabs.component';

@Component({
  selector: 'app-anaerobic-threshold-dialog',
  templateUrl: './anaerobic-threshold-dialog.component.html',
  styleUrls: ['./anaerobic-threshold-dialog.component.scss'],
})
export class AnaerobicThresholdDialogComponent implements OnInit {
  public form!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AnaerobicThresholdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogsData,
    private snackBar: MatSnackBar
  ) {}

  public initForm(): void {
    this.form = this.fb.group({
      threshold: new UntypedFormControl(0),
      pace: new UntypedFormControl(''),
      tolerance: new UntypedFormControl(0),
    });
  }
  public ngOnInit(): void {
    this.initForm();
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.data.is_speed_sport) {
      this.dialogRef.close(this.form.value);
      return;
    }
    if (
      this.form.get('pace')?.value.match(/[0-9]:[0-5]\d/) ||
      this.form.get('pace')?.value.match(/0[0-9]:[0-5]\d/)
    ) {
      this.dialogRef.close(this.form.value);
    } else {
      this.snackBar.open('Pace must be between 0:00 and 9:59', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
  }
  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}
