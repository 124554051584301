import {Component, DestroyRef, Inject, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AccountTypesService } from '@core/services/account-types/account-types.service';
import { AccountTypeDto } from "@core/services/account-types/account-type-dto";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-account-type-dialog',
  templateUrl: './account-type-dialog.component.html',
})
export class AccountTypeDialogComponent implements OnInit {
  public accountTypeForm: UntypedFormGroup = this.fb.group({});

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AccountTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountTypesService: AccountTypesService,
    private destroyRef: DestroyRef,
    ) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    if (!this.data.isEdit) {
      this.accountTypeForm = this.fb.group({
        name: new UntypedFormControl('', Validators.required),
        is_active: new UntypedFormControl(true, Validators.required),
      });
    } else {
      this.accountTypeForm = this.fb.group({
        name: new UntypedFormControl(this.data.accountType.name, Validators.required),
        is_active: new UntypedFormControl(
          this.data.accountType.is_active,
          Validators.required
        ),
      });
    }
  }
  public saveAction(): void {
    if (this.accountTypeForm.valid) {
      if (!this.data.isEdit) {
        this.accountTypesService.create(this.accountTypeForm.value)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((res: AccountTypeDto) => this.dialogRef.close(res));
      } else {
        this.accountTypesService.update({
            ...this.accountTypeForm.value,
            id: this.data.accountType.id,
          })
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((res: Object) => this.dialogRef.close(res));
      }
    } else {
      this.accountTypeForm.markAllAsTouched();
    }
  }
  public closeDialog(): void {
    this.dialogRef.close();
  }
}
