import { inject, Injectable } from '@angular/core';
import { ApiService } from "@core/services/api.service";
import { Observable } from "rxjs";
import { IFeature } from "@shared/components/interfaces/feature.interface";
import { AppConstants } from "@core/constants";

@Injectable()
export class MyInscydService {
  private apiService: ApiService = inject(ApiService);

  public getMyInscydFeatureList(): Observable<IFeature[]> {
    return this.apiService.get(AppConstants.API.MY_INSCYD_FEATURE_ITEM.GET);
  }
}
