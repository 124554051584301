<div class="sport-dialog__section">
    <h2 mat-dialog-title>{{ "Regions pricing" }}</h2>
    <mat-dialog-content [formGroup]="form">
        <div class="field">
            <mat-form-field class="full-width" appearance="outline" floatLabel="always">
              <mat-label>Name of Region (*)</mat-label>
              <input matInput placeholder="Name of Region" formControlName="name">
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="full-width" appearance="outline" floatLabel="always">
              <mat-label>Currency(*)</mat-label>
              <mat-select placeholder="Select a currency" formControlName="currency">
                <mat-option *ngFor="let item of dataSource" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="field">
            <app-chips
            [label]="'Country'"
            [form]="form.get('countries')"
            [options]="countryOptions"
            [displayField]="'name'"
            (optionChange)="onSelectCountry($event)"
          ></app-chips>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button
            id="cancelButton"
            mat-button
            (click)="close()"
            type="button">
            Cancel
        </button>
        <button
            id="saveButton"
            mat-raised-button
            color="primary"
            (click)="save()">
            Save
        </button>
      </div>
    </mat-dialog-actions>
  </div>
