import { Deserializable } from '@shared/models/deserializable';
import { ProductPriceDto } from '../product-purchase/product-dto';
import { AccumulatedTestDTO } from "../manage-organizations/manage-organization-dto";

export class PlanPriceResultDto extends Deserializable {
  public list_products: ProductPriceDto[] | undefined;
  public plan: PlanPriceDto | undefined;
}

export class PlanPriceDto extends Deserializable {
  public additional_fee: number | undefined;
  public alternative_items: Array<any> | undefined;
  public currency: number | undefined;
  public currency_name: string | undefined;
  public description: string | undefined;
  public discount: number | undefined;
  public discount_expiration: string | undefined;
  public features: string | undefined;
  public group_id: number | undefined;
  public group_name: string | undefined;
  public is_purchased: boolean | undefined;
  public list_month_prices: Array<IListMonthPrices> | undefined;
  public list_plan_discounts:
    | Array<{
        account_types: Array<number> | undefined;
        organizations: Array<number> | undefined;
        regions: Array<number> | undefined;
        discount_type: number | undefined;
        discount_value: number | undefined;
        purchased_day: number | undefined;
        valid_until: string | undefined;
      }>
    | undefined;
  public max_athlete: number | undefined;
  public max_test_event: number | undefined;
  public max_test_la: number | undefined;
  public max_test_manual: number | undefined;
  public max_test_map: number | undefined;
  public max_test_result: number | undefined;
  public max_test_virtual: number | undefined;
  public max_user: number | undefined;
  public name: string | undefined;
  public one_time_fee: number | undefined;
  public organization: number | undefined;
  public period_price: number | undefined;
  public plan: number | undefined;
  public start_date: number | undefined;
  public tobe_credited: number | undefined;
  public up_downgrade: number | undefined;
  public one_time_fee_before_discount: number | undefined;
  public one_time_fee_discount_amount: number | undefined;
  public list_plan_item_counts: Array<IListPlanItemCounts> | undefined;
  public minimum_contract_duration_expiry: string | undefined;
}

export interface IListMonthPrices {
  month_price: number | undefined;
  period: number | undefined;
  total_plan_price: number | undefined;
  discount_price: number | undefined;
}

export interface IListPlanItemCounts {
  period: number | undefined;
  test_count: number | undefined;
}

export class PlanActivatedDto extends Deserializable {
  public cancellation_possible_until: string | undefined;
  public minimum_contract_duration: number | undefined;
  public minimum_contract_duration_expiry: string | undefined;
  public created_at: string | undefined;
  public currency_name: string | undefined;
  public description: string | undefined;
  public display_items: string | undefined;
  public expiration: string | undefined;
  public features: string | undefined;
  public group_id: number | undefined;
  public id: number | undefined;
  public include_items: string | undefined;
  public list_month_prices: Array<IListMonthPrices> | undefined;
  public max_athlete: number | undefined;
  public max_test_event: number | undefined;
  public max_test_la: number | undefined;
  public max_test_manual: number | undefined;
  public max_test_map: number | undefined;
  public max_test_result: number | undefined;
  public max_test_virtual: number | undefined;
  public max_user: number | undefined;
  public name: string | undefined;
  public period_type: number | undefined;
  public plan_id: number | undefined;
  public plan_price: number | undefined;
  public purchase_alternative_items:
    | Array<{
        alternative_item: string | undefined;
        is_display: boolean | undefined;
        master_item: number | undefined;
      }>
    | undefined;
  public purchased_linked_items:
    | Array<{
        activation_item: string | undefined;
        current_period: number | undefined;
        idle_time_period: number | undefined;
        idle_time_unit: number | undefined;
        is_display: boolean | undefined;
        linked_item: number | undefined;
        master_item: number | undefined;
        max_item: number | undefined;
        name_link_item: string | undefined;
        purchase_lots: Array<any> | undefined;
        total_item: number | undefined;
        total_period_item: number | undefined;
      }>
    | undefined;
  public start_date: string | undefined;
  public total_athlete: number | undefined;
  public total_test_event: number | undefined;
  public total_test_la: number | undefined;
  public total_test_manual: number | undefined;
  public total_test_map: number | undefined;
  public total_test_result: number | undefined;
  public total_test_virtual: number | undefined;
  public total_user: number | undefined;
  public updated_at: string | undefined;

  // extra
  public oldPeriod: number | undefined;
  public listUsage: Array<IUsage> | undefined;
  public listUsageInDetail: Array<IUsage> | undefined;
  public listPeriodOption:
    | Array<{ name: string | undefined; value: number | undefined }>
    | undefined;
  public isShowDetail: boolean | undefined;
  public period_type_clone: number | undefined;
  public accumulated_tests: Record<string, Array<AccumulatedTestDTO>> | undefined;
  public is_accumulated: boolean | undefined;
  public accumulatedTestsCount: number;
  public lot_prices: Record<number, number> | undefined;
}

export interface IUsage {
  name: string;
  total: number;
  max: number;
  subTitle: string;
}
