import {Component} from "@angular/core";

@Component({
  selector: 'app-course-complete',
  templateUrl: 'course-complete.component.html',
  styleUrls: ['course-complete.component.scss'],
})

export class CourseCompleteComponent  {
  constructor() {}

}
