<div class="courses-list">
  <div class="title-with-search">
    <p (click)="editCourse({name: 'Name of course'})">Courses</p>
  </div>
  <table
    mat-table
    matSort
    #tableData
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    style="width: 100%"
    (matSortChange)="sortData($event)"
    matSortActive="id"
    matSortDirection="desc"
    matSortDisableClear>
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-column-id"
        scope="row">ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Title
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="published">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Published in catalog
      </th>
      <td mat-cell *matCellDef="let element" [ngClass]="{ 'active-status': !element.hide_from_catalog, 'inactive-status': element.hide_from_catalog }">
        {{ !element.hide_from_catalog ? "YES" : "NO" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element" class="fixed-width-action">
        <button
          *ngIf="rights.admin_devices_update"
          mat-raised-button
          color="primary"
          (click)="editCourse(element)"
          class="btn-icon-text">
          <mat-icon>mode_edit</mat-icon> Edit
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell">No data available in table</td>
    </tr>
  </table>
  <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
    <mat-paginator
      (page)="getDataPage($event.pageIndex + 1)"
      [length]="totalItems"
      [pageSize]="pageSize">
    </mat-paginator>
  </div>
</div>
