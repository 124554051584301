import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  public transform(
    text: string | undefined,
    length: number = 20,
    suffix: string = '...'
  ): string {
    if (text && text.length > length) {
      const truncated = text.substring(0, length).trim();
      return `
      <span class="comment-format-warpper">${truncated}
        <span class='btn-readmore-comment'>${suffix}
          <div class="popover-comment">
            ${text}
          </div>
        </span>
      </span>`;
    }

    return text || '';
  }
}
