import { Injectable } from "@angular/core";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { BehaviorSubject, delay, distinctUntilChanged, finalize } from "rxjs";
import { ClonePlanParamsInterface } from "../interfaces/clone-plan.interface";

@Injectable()
export class ClonePlanState {
  private readonly _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());

  constructor(private _apiService: ApiService) { }

  public clonePlan(planId: number, params?: ClonePlanParamsInterface) {
    this._loading.next(true);

    const bodyParams = {
      ...(params ?? {}),
      plan_id: planId
    }

    // We are not concerned with the return value - we will just reload the plan
    // list page
    return this._apiService.post(AppConstants.API.PRICING_PLANS.CLONE, bodyParams, false, undefined, false)
      .pipe(finalize(() => this._loading.next(false)))
  }
}