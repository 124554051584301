import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { CountriesDto, CountriesResultDto } from './countries-dto';
import { IRegionDetail, IRegionSettingsDetail, RegionSettingsDto, RegionSettingsResultDto } from './region-settings-dto';
import { String } from 'typescript-string-operations';
import { CurrencyDto, CurrencyResultDto } from './currency-dto';
import { ApiRequest } from '@shared/models/api-request';
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { AppConstants } from "@core/constants";

@Injectable({
  providedIn: 'root'
})
export class RegionSettingsService extends ServiceBase {

  constructor(private customRendererService: CustomRendererService,) {
    super();
  }

  public list(request: PagedAndSortedRequestDto): Observable<RegionSettingsResultDto> {
    const apiUrl: string = this._appConstants.API.REGION_SETTINGS.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return this.mappingDataRightList(res);
      })
    );
  }

  public mappingDataRightList(res: any): RegionSettingsResultDto {
    return new RegionSettingsResultDto().deserialize({
      totalCount: res.count,
      items: res.results.map((item: RegionSettingsDto) =>
        new RegionSettingsDto().deserialize(item)
      ),
    });
  }

  public listCountries() : Observable<any> {
    const apiUrl: string = this._appConstants.API.COUNTRIES.LIST;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new CountriesResultDto().deserialize({
          totalCount: res.count,
          items: res.list_countries.map((item: CountriesDto) =>
            new CountriesDto().deserialize(item)
          ),
        });
      }),
    );
  }

  public currencyList(): Observable<any> {
    const apiUrl: string = this._appConstants.API.CURRENCY.LIST;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new CurrencyResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: CurrencyDto) =>
            new CurrencyDto().deserialize(item)
          ),
        });
      }),
    );
  }

  public updateCountryRegion(input: IRegionDetail): Observable<RegionSettingsDto> {
    const apiUrl: string = String.Format(this._appConstants.API.REGION_SETTINGS.UPDATE);
    this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);

    return this._apiService
      .put(apiUrl, input)
      .pipe(map((item: Object) => new RegionSettingsDto().deserialize(item)),
      finalize((): void => {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
      }));
  }

  public addRegion(input: unknown): Observable<RegionSettingsDto> {
    const apiUrl: string = this._appConstants.API.REGION_SETTINGS.ADD;

    return this._apiService
    .post(apiUrl, input)
    .pipe(map((item: Object) => new RegionSettingsDto().deserialize(item)));
  }

  public update(id: number, input: IRegionSettingsDetail): Observable<RegionSettingsDto> {
    const apiUrl: string = String.Format(this._appConstants.API.REGION_SETTINGS.GET, id);
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    Object.assign(body, input);

    return this._apiService.put(apiRequest.apiUrl, apiRequest.body).pipe(map((item: Object) => new RegionSettingsDto().deserialize(item)));
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(this._appConstants.API.REGION_SETTINGS.DELETE, id);

    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }

  public getCountries() : Observable<ICountryList> {
    const apiUrl: string = this._appConstants.API.COUNTRIES.GET;

    return <Observable<ICountryList>> this._apiService.get(apiUrl);
  }

  public getRegionById(region_id: number) : Observable<RegionSettingsDto> {
    const apiUrl: string = String.Format(this._appConstants.API.REGION_SETTINGS.GET, region_id);

    return <Observable<RegionSettingsDto>> this._apiService.get(apiUrl);
  }

}

export interface ICountryList { results: CountriesDto[] }

