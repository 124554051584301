<form autocomplete="off"
      class="createNew">
  <div>
    <div class="mt-3">
      <h1 mat-dialog-title *ngIf="data.isPPD">Add Power-Performance Decoder</h1>
      <h1 mat-dialog-title *ngIf="data.isManual">Add Manual Test</h1>
      <h1 mat-dialog-title *ngIf="data.isVirtual">Add Virtual Test</h1>
      <h1 mat-dialog-title *ngIf="data.isLactate">
        {{!back ? 'Add Lactate test' : 'Edit Lactate Test'}}
      </h1>
    </div>
    <mat-tab-group #tabGroup
                   mat-dialog-content
                   [formGroup]="testForm"
                   [selectedIndex]="tabIndex"
                   (selectedTabChange)="tabChanged($event)">
      <mat-tab label="General"
               [disabled]="!(tabGroup.selectedIndex === 0)">
        <br />
        <div class="form-group row">
          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Athlete (*)</mat-label>
              <mat-select placeholder="Select an athlete"
                          formControlName="athlete"
                          (selectionChange)="onChangeAthlete($event.value)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="searchAthleteCtrl"
                                         placeholderLabel="Search Athletes"
                                         noEntriesFoundLabel="no matching athletes found"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let athlete of athletes"
                            [value]="athlete?.id">
                  {{ athlete?.first_name }} {{ athlete?.last_name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  testForm.controls?.athlete?.errors &&
                  testForm.controls?.athlete?.touched">
                <div [innerHTML]="testForm.controls.athlete.errors| errorFormFormat : { fieldName: 'Athlete'}"></div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Sport (*)</mat-label>
              <mat-select placeholder="Select a sport"
                          formControlName="sport"
                          (selectionChange)="onChangeSport($event.value)">
                <mat-option *ngFor="let sport of allSports" [value]="sport?.id">
                  {{ sport?.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  testForm.controls?.sport?.errors &&
                  testForm.controls?.sport?.touched">
                <div [innerHTML]="
                    testForm.controls.sport.errors
                      | errorFormFormat : { fieldName: 'Sport' }"></div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Date(*)</mat-label>
              <input matInput
                     formControlName="test_date"
                     [matDatepicker]="picker"/>
              <mat-datepicker-toggle matSuffix
                                     [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="
                  testForm.controls?.test_date?.errors &&
                  testForm.controls?.test_date?.touched">
                <div [innerHTML]="
                    testForm.controls.test_date.errors
                      | errorFormFormat : { fieldName: 'Test date' }"></div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Coach (*)</mat-label>
              <mat-select placeholder="Select a coach"
                          formControlName="coach">
                <mat-option *ngFor="let coach of coaches"
                            [value]="coach?.id">
                  {{ coach?.username }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  testForm.controls?.coach?.errors &&
                  testForm.controls?.coach?.touched"
              >
                <div
                  [innerHTML]="
                    testForm.controls.coach.errors
                      | errorFormFormat : { fieldName: 'Coach' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Location</mat-label>
              <input matInput
                     formControlName="location" />
            </mat-form-field>
          </div>

          <mat-checkbox color="primary"
                        class="virtualCheckBox"
                        *ngIf="data.isManual">Virtual</mat-checkbox>

          <div class="field">
            <app-chips *ngIf="backTestDataLoaded && initDataLoaded"
                       [label]="'Tags'"
                       [form]="testForm.get('tags')"
                       [options]="allTags"
                       [displayField]="'name'"
                       [autocompleteType]="autocompleteTagsEnum.TAGS"
                       (optionChange)="onSelectTag($event)"
                       (selectNewTag)="onSelectNewTag($event)"
                       (removeNewTag)="onRemoveNewTag($event)"></app-chips>
            <mat-error *ngIf="
                  testForm.controls?.tags?.errors &&
                  testForm.controls?.tags?.touched">
              <div>{{testForm.controls.tags.errors?.errorTags}}</div>
            </mat-error>
          </div>

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Comment</mat-label>
              <textarea placeholder="Comments"
                        rows="5"
                        matInput
                        formControlName="comment"></textarea>
            </mat-form-field>
          </div>

          <h3 class="sub-title border-color">Body composition</h3>
          <br />

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Weight (*)</mat-label>
              <input data-component="add-ppd-lactate-test-weight" type="number" matInput formControlName="mass" />
              <span matSuffix>kg</span>
              <mat-error *ngIf="
                  testForm.controls?.mass?.errors &&
                  testForm.controls?.mass?.touched">
                <div [innerHTML]="
                    testForm.controls.mass.errors
                      | errorFormFormat : { fieldName: 'Weight', min: 10, max: 200 }"></div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Height (*)</mat-label>
              <input matInput
                     type="number"
                     formControlName="height" />
              <span matSuffix>cm</span>
              <mat-error *ngIf="
                  testForm.controls?.height?.errors &&
                  testForm.controls?.height?.touched">
                <div [innerHTML]="
                    testForm.controls.height.errors
                      | errorFormFormat : { fieldName: 'Height', min:  100, max: 300}"></div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field-col">
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>Fat (*)</mat-label>
              <input matInput
                     type="number"
                     formControlName="proz_body_fat_100"
                     (change)="onChangeFat($event)"/>
              <span matSuffix>% of body mass</span>
              <mat-error *ngIf="
                  testForm.controls?.proz_body_fat_100?.errors &&
                  testForm.controls?.proz_body_fat_100?.touched">
                <div [innerHTML]="
                    testForm.controls.proz_body_fat_100.errors
                      | errorFormFormat : { fieldName: 'Fat %', min: 3, max: 40 }"></div>
              </mat-error>
            </mat-form-field>
          </div>
          <br />

          <div class="setting__section">
            <h3 class="sub-title border-color">
              <mat-icon *ngIf="!isShowAdvancedSetting"
                        (click)="isShowAdvancedSetting = true">
                keyboard_arrow_right
              </mat-icon>
              <mat-icon *ngIf="isShowAdvancedSetting"
                        (click)="isShowAdvancedSetting = false">
                keyboard_arrow_down
              </mat-icon>
              Advanced Body Composition
            </h3>
            <div class="content"
                 *ngIf="isShowAdvancedSetting">
              <section class="mt-3 mb-3 ml-3">
                <mat-checkbox color="primary"
                              (change)="showOptions($event)"
                              formControlName="auto_calc_body_com">
                  Automatic body composition calculation</mat-checkbox>
              </section>
              <div class="field-col">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>Body Water</mat-label>
                  <input (input)="bodyWaterOnChanged()"
                         [readonly]="isChecked"
                         matInput
                         type="number"
                         formControlName="proz_body_water_100_override"/>
                  <span matSuffix>% of body mass</span>
                  <mat-error *ngIf="
                      testForm.controls?.proz_body_water_100_override?.errors &&
                      testForm.controls?.proz_body_water_100_override?.touched">
                    <div [innerHTML]="
                        testForm.controls.proz_body_water_100_override.errors
                          | errorFormFormat : { fieldName: 'Body water' }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-col">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>Body Muscle</mat-label>
                  <input [readonly]="isChecked"
                         matInput
                         type="number"
                         formControlName="proz_body_muscle_100_override"/>
                  <span matSuffix>% of body mass</span>
                  <mat-error *ngIf="
                      testForm.controls?.proz_body_muscle_100_override
                        ?.errors &&
                      testForm.controls?.proz_body_muscle_100_override?.touched">
                    <div [innerHTML]="
                        testForm.controls.proz_body_muscle_100_override.errors
                          | errorFormFormat : { fieldName: 'Body muscle' }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-col">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>Muscle Mass Used</mat-label>
                  <input matInput
                         type="number"
                         [readonly]="isChecked"
                         formControlName="proz_muscle_mass_used_100_override"/>
                  <span matSuffix>% of muscle mass</span>
                  <mat-error *ngIf="
                      testForm.controls?.proz_muscle_mass_used_100_override
                        ?.errors &&
                      testForm.controls?.proz_muscle_mass_used_100_override?.touched">
                    <div [innerHTML]="
                        testForm.controls.proz_muscle_mass_used_100_override.errors | errorFormFormat : { fieldName: 'Muscle Mass Used' }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-col">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>Lactate Distribution Space</mat-label>
                  <input matInput
                         type="number"
                         [readonly]="isChecked"
                         (input)="updateLao2eq(testForm.controls.proz_lactat_dist_space_100_override.value)"
                         formControlName="proz_lactat_dist_space_100_override"/>
                  <span matSuffix>% of body mass</span>
                  <mat-error *ngIf="
                      testForm.controls?.proz_lactat_dist_space_100_override
                        ?.errors &&
                      testForm.controls?.proz_lactat_dist_space_100_override">
                    <div [innerHTML]="
                        testForm.controls.proz_lactat_dist_space_100_override.errors | errorFormFormat
                            : { fieldName: 'Lactate Distribution Space', min: 1, max: 99 }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-col" *ngIf="!isChecked">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>Glycogen Content</mat-label>
                  <input matInput
                         type="number"
                    [readonly]="isChecked"
                    formControlName="glycogen_content"/>
                  <span matSuffix>g/kg MM</span>
                  <mat-error *ngIf="
                      testForm.controls?.glycogen_content?.errors &&
                      testForm.controls?.glycogen_content?.touched">
                    <div [innerHTML]="
                        testForm.controls.glycogen_content.errors
                          | errorFormFormat : { fieldName: 'Glycogen Content', min: 13, max: 28 }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-col" *ngIf="isChecked">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>Glycogen Content</mat-label>
                  <input matInput
                         [readonly]="isChecked"
                         value="automatic" />
                  <span matSuffix>g/kg MM</span>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Test Data"
               [disabled]="!(tabGroup.selectedIndex === 1)">
        <br />
        <div class="setting__section" *ngIf="data.isPPD">
          <h3 class="sub-title border-color">Test Data</h3>
          <div class="content">
            <div class="field" *ngIf="data.isPPD">
              <app-chips [label]="'Files'"
                         [form]="testForm.get('files')"
                         [options]="filteredTests"
                         [displayField]="'name'"
                         (optionChange)="onSelectFile($event)"></app-chips>
            </div>
            <div class="file-upload-section border-color"
                 appDragFile
                 (files)="filesDropped($event)">
              <svg class="box__icon"
                   xmlns="http://www.w3.org/2000/svg"
                   width="50"
                   height="43"
                   viewBox="0 0 50 43">
                <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"/>
              </svg>
              <div class="text-wrapper">
                <input type="file"
                       #fileUpload
                       hidden
                       multiple
                       (change)="changeFile($event)"/>
                <a (click)="fileUpload.click()"
                   class="primary-color">Choose a file</a>
                <div class="centered">or drag it here.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="setting__section"
             *ngIf="data.isLactate">
          <h3 class="sub-title border-color">Test Data</h3>
          <div class="content">
            <div class="form-group">
              <mat-label>Lactate Test File</mat-label>
              <input type="file"
                     id="file"
                     (change)="handleFileInput($event)" />
            </div>
          </div>
        </div>
        <div class="setting__section"
             *ngIf="data.isManual || data.isVirtual">
          <h3 class="sub-title border-color">Metabolic Capacities</h3>
          <div class="content">
            <div class="field-col">
              <mat-form-field class="full-width"
                              appearance="outline"
                              floatLabel="always">
                <mat-label>VO2max (*)</mat-label>
                <input matInput
                       type="number"
                       formControlName="vo2max_min"
                       [required]="data.isManual"/>
                <span matSuffix>ml/min/kg body mass</span>
                <mat-error *ngIf="
                    testForm.controls?.vo2max_min?.errors &&
                    testForm.controls?.vo2max_min?.touched">
                  <div [innerHTML]="
                      testForm.controls.vo2max_min.errors
                        | errorFormFormat : { fieldName: 'VO2max', min: 1, max: 150 }"></div>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="field-col">
              <mat-form-field class="full-width"
                              appearance="outline"
                              floatLabel="always">
                <mat-label>VLamax (*)</mat-label>
                <input matInput
                       type="number"
                       formControlName="vlamax"
                       [required]="data.isManual"/>
                <span matSuffix>mmol/l/s</span>
                <mat-error *ngIf="
                    testForm.controls?.vlamax?.errors &&
                    testForm.controls?.vlamax?.touched">
                  <div [innerHTML]="
                      testForm.controls.vlamax.errors
                        | errorFormFormat : { fieldName: 'VLamax', min: 0, max: 3 }"></div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Expert settings"
               [disabled]="!(tabGroup.selectedIndex === 2)">
        <br />
        <div class="setting__section">
          <h3 class="sub-title border-color">
            <mat-icon *ngIf="!isShowExpertSetting"
                      (click)="isShowExpertSetting = true">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="isShowExpertSetting"
                      (click)="isShowExpertSetting = false">keyboard_arrow_down</mat-icon>
            Expert & Energy Demand Settings
          </h3>
          <div class="content"
               *ngIf="isShowExpertSetting">
            <p style="margin-top: 2%">Energy demand Equation (EDE)</p>
            <p class="subheading">Test specific EDE Values</p>
            <div class="group">
              <div class="field-small">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>A (constant)</mat-label>
                  <input matInput
                         type="number"
                         formControlName="au_override"
                         placeholder="Override value"/>
                  <mat-error *ngIf="
                      testForm.controls?.au_override?.errors &&
                      testForm.controls?.au_override?.touched">
                    <div [innerHTML]="
                        testForm.controls.au_override.errors
                          | errorFormFormat
                            : { fieldName: 'Energy demand equation: term A', min: -1, max: 20 }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>term B (linear)</mat-label>
                  <input matInput
                         type="number"
                         formControlName="bu_override"
                         placeholder="Override value"/>
                  <mat-error *ngIf="
                      testForm.controls?.bu_override?.errors &&
                      testForm.controls?.bu_override?.touched">
                    <div [innerHTML]="
                        testForm.controls.bu_override.errors
                          | errorFormFormat
                            : { fieldName: 'Energy demand equation: term B' }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field class="full-width"
                                appearance="outline"
                                floatLabel="always">
                  <mat-label>D (cubic)</mat-label>
                  <input matInput
                         type="number"
                         formControlName="du_override"
                         placeholder="Override value"/>
                  <mat-error *ngIf="
                      testForm.controls?.du_override?.errors &&
                      testForm.controls?.du_override?.touched">
                    <div [innerHTML]="
                        testForm.controls.du_override.errors
                          | errorFormFormat
                            : { fieldName: 'Energy demand equation: term D' }"></div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <p *ngIf="data.isVirtual" class="subheading">Used Values</p>
            <div *ngIf="data.isVirtual" class="group" style="margin-bottom: 2%">
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>A (constant)</mat-label>
                  <input
                    matInput
                    type="number"
                    [readonly]="true"
                    value="{{ this.testForm.get('au_override')?.value }}"
                    matTooltip="If empty, default value will be used."
                  />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>term B (linear)</mat-label>
                  <input
                    matInput
                    type="number"
                    [readonly]="true"
                    value="{{ this.testForm.get('bu_override')?.value }}"
                    matTooltip="If empty, default value will be used."
                  />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>D (cubic)</mat-label>
                  <input
                    matInput
                    type="number"
                    [readonly]="true"
                    value="{{ this.testForm.get('du_override')?.value }}"
                    matTooltip="If empty, default value will be used."
                  />
                </mat-form-field>
              </div>
            </div>
            <p class="subheading" style="margin-top: -2%">Default EDE Values</p>
            <div class="group">
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>A (constant):</mat-label> {{ egbA }}
                  <input [readonly]="true" matInput />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>B (linear):</mat-label> {{ egbB }}
                  <input [readonly]="true" matInput />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>D (cubic):</mat-label> {{ egbD }}
                  <input [readonly]="true" matInput />
                </mat-form-field>
              </div>
            </div>

            <p style="margin-top: 2%">O2 per Watt Equation</p>
            <p class="subheading">Test specific values</p>
            <div class="group">
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>A (constant)</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="o2_per_watt_eq_a"
                    placeholder="Override value"
                  />
                  <mat-error
                    *ngIf="
                      testForm.controls?.o2_per_watt_eq_a?.errors &&
                      testForm.controls?.o2_per_watt_eq_a?.touched
                    "
                  >
                    <div
                      [innerHTML]="
                        testForm.controls.o2_per_watt_eq_a.errors
                          | errorFormFormat : { fieldName: 'O2 per watt EQ A' }
                      "
                    ></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>B (linear)</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="o2_per_watt_eq_b"
                    placeholder="Override value"
                  />
                  <mat-error
                    *ngIf="
                      testForm.controls?.o2_per_watt_eq_b?.errors &&
                      testForm.controls?.o2_per_watt_eq_b?.touched
                    "
                  >
                    <div
                      [innerHTML]="
                        testForm.controls.o2_per_watt_eq_b.errors
                          | errorFormFormat : { fieldName: 'O2 per watt EQ B' }
                      "
                    ></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>D (cubic)</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="o2_per_watt_eq_d"
                    placeholder="Override value"
                  />
                  <mat-error
                    *ngIf="
                      testForm.controls?.o2_per_watt_eq_d?.errors &&
                      testForm.controls?.o2_per_watt_eq_d?.touched
                    "
                  >
                    <div
                      [innerHTML]="
                        testForm.controls.o2_per_watt_eq_d.errors
                          | errorFormFormat : { fieldName: 'O2 per watt EQ D' }
                      "
                    ></div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="field-col">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>O2 constant term</mat-label>
                <input matInput type="number" [readonly]="true" value="16" />
              </mat-form-field>
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Gross Efficiency</mat-label>
                <input
                  matInput
                  type="number"
                  [readonly]="true"
                  value="{{
                    calculateGrossEfficiency(
                      this.testForm.get('o2_per_watt_eq_b')?.value
                    )
                  }}"
                />
              </mat-form-field>
            </div>

            <p *ngIf="data.isVirtual" class="subheading">Used values</p>
            <div *ngIf="data.isVirtual" class="group">
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>A (constant)</mat-label>
                  <input
                    matInput
                    type="number"
                    [readonly]="true"
                    value="{{ this.testForm.get('o2_per_watt_eq_a')?.value }}"
                    matTooltip="If empty, default value will be used."
                  />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>B (linear)</mat-label>
                  <input
                    matInput
                    type="number"
                    [readonly]="true"
                    value="{{ this.testForm.get('o2_per_watt_eq_b')?.value }}"
                    matTooltip="If empty, default value will be used."
                  />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>D (cubic)</mat-label>
                  <input
                    matInput
                    type="number"
                    [readonly]="true"
                    value="{{ this.testForm.get('o2_per_watt_eq_d')?.value }}"
                    matTooltip="If empty, default value will be used."
                  />
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="data.isVirtual" class="field-col">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>O2 constant term</mat-label>
                <input
                  matInput
                  type="number"
                  [readonly]="true"
                  value="16"
                  matTooltip="If empty, default value will be used."
                />
              </mat-form-field>
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Gross Efficiency</mat-label>
                <input
                  matInput
                  type="number"
                  [readonly]="true"
                  value="{{
                    calculateGrossEfficiency(
                      this.testForm.get('o2_per_watt_eq_b')?.value
                    )
                  }}"
                  matTooltip="If empty, default value will be used."
                />
              </mat-form-field>
            </div>

            <p class="subheading" style="margin-top: 0%">Default values</p>
            <div class="group">
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>A (constant):</mat-label> {{ eqA }}
                  <input type="number" [readonly]="true" matInput />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>B (linear):</mat-label> {{ eqB }}
                  <input type="number" [readonly]="true" matInput />
                </mat-form-field>
              </div>
              <div class="field-small">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>D (cubic):</mat-label> {{ eqD }}
                  <input type="number" [readonly]="true" matInput />
                </mat-form-field>
              </div>
            </div>

            <div class="field-col" style="margin-bottom: 4%">
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>O2 Constant term:</mat-label>
                <input type="number" [readonly]="true" matInput value="16" />
              </mat-form-field>
              <mat-form-field
                class="full-width"
                appearance="outline"
                floatLabel="always"
              >
                <mat-label>Gross Efficiency:</mat-label>
                <input
                  [readonly]="true"
                  type="number"
                  matInput
                  value="{{
                    calculateGrossEfficiency(
                      this.testForm.get('o2_per_watt_eq_b')?.value
                    )
                  }}"
                />
              </mat-form-field>
            </div>

            <div class="group">
              <div class="field-small" *ngIf="!data.isLactate">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>O2 per Watt equivalent</mat-label>
                  <input type="number" matInput formControlName="watto2eq" />
                  <span matSuffix>ml/W</span>
                </mat-form-field>
              </div>
              <div class="field-small" *ngIf="rights.test_lactate_oxidation_list">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>Lactate @ 50% of max. La-Ox</mat-label>
                  <input type="number" matInput formControlName="kel" />
                  <mat-error
                    *ngIf="
                      testForm.controls?.kel?.errors &&
                      testForm.controls?.kel?.touched
                    "
                  >
                    <div
                      [innerHTML]="
                        testForm.controls.kel.errors
                          | errorFormFormat
                            : { fieldName: 'Lactate concentration', min: 1, max: 13 }
                      "
                    ></div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field-small" *ngIf="rights.test_o2_kinetics_list">
                <mat-form-field
                  class="full-width"
                  appearance="outline"
                  floatLabel="always"
                >
                  <mat-label>O2 time const (mono exp.)</mat-label>
                  <input type="number" matInput formControlName="tau" />
                  <span matSuffix>s</span>
                  <mat-error
                    *ngIf="
                      testForm.controls?.tau?.errors &&
                      testForm.controls?.tau?.touched
                    "
                  >
                    <div
                      [innerHTML]="
                        testForm.controls.tau.errors
                          | errorFormFormat
                            : { fieldName: 'O2 kinetics time constant ' }
                      "
                    ></div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="setting__section"
             [formGroup]="formConditions"
             *ngIf="rights.test_condition_field_list">
          <h3 class="sub-title border-color">
            <mat-icon *ngIf="!isShowEnergySetting"
              (click)="isShowEnergySetting = true">
              keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="isShowEnergySetting"
              (click)="isShowEnergySetting = false">
              keyboard_arrow_down</mat-icon>
            Energy Demand Conditions
          </h3>
          <div class="content" *ngIf="isShowEnergySetting">
            <div
              formArrayName="conditions"
              *ngFor="let condition of conditions.controls; let i = index"
            >
              <ng-container [formGroupName]="i">
                <div class="field-col">
                  <button
                    type="button"
                    mat-raised-button
                    color="warn"
                    class="remove-btn"
                    (click)="removeCondition(i)"
                  >
                    X
                  </button>
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label>Condition {{ i + 1 }}</mat-label>
                    <input matInput formControlName="name" />
                  </mat-form-field>
                </div>
                <p style="margin-top: 2%">Energy demand Equation (EDE)</p>
                <p style="margin-left: 5px">Condition specific EDE Values</p>
                <div class="group" style="margin-bottom: 2%">
                  <div class="field-small">
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>A (constant)</mat-label>
                      <input type="number" matInput formControlName="ebg_factor_a" />
                    </mat-form-field>
                  </div>
                  <div class="field-small">
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>B (linear)</mat-label>
                      <input type="number" matInput formControlName="ebg_factor_b" />
                    </mat-form-field>
                  </div>
                  <div class="field-small">
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>D (cubic)</mat-label>
                      <input type="number" matInput formControlName="ebg_factor_d" />
                    </mat-form-field>
                  </div>
                </div>

                <p>O2 per Watt Equation</p>
                <p style="margin-left: 5px">Test specific values</p>
                <div class="group">
                  <div class="field-small">
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>A (constant)</mat-label>
                      <input type="number" matInput formControlName="o2_per_watt_eq_a" />
                    </mat-form-field>
                  </div>
                  <div class="field-small">
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>B (constant)</mat-label>
                      <input type="number" matInput formControlName="o2_per_watt_eq_b" />
                    </mat-form-field>
                  </div>
                  <div class="field-small">
                    <mat-form-field
                      class="full-width"
                      appearance="outline"
                      floatLabel="always"
                    >
                      <mat-label>D (constant)</mat-label>
                      <input type="number" matInput formControlName="o2_per_watt_eq_d" />
                    </mat-form-field>
                  </div>
                  <br />
                </div>
                <div class="field-col" style="width: 50%">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always"
                  >
                    <mat-label>Gross Efficiency</mat-label>
                    <input
                      matInput
                      type="number"
                      value="{{
                        calculateGrossEfficiency(
                          this.condition.get('o2_per_watt_eq_b')?.value
                        )
                      }}"
                    />
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
            <button
              mat-raised-button
              (click)="addCondition()"
              class="btn"
              type="button"
              color="primary"
            >
              Add Condition
            </button>
          </div>
        </div>
        <div class="setting__section">
          <h3 class="sub-title border-color">
            <mat-icon
              *ngIf="!isShowTestSetting"
              (click)="isShowTestSetting = true"
              >keyboard_arrow_right</mat-icon
            >
            <mat-icon
              *ngIf="isShowTestSetting"
              (click)="isShowTestSetting = false"
              >keyboard_arrow_down</mat-icon
            >
            Test Environment
          </h3>
          <div class="content" *ngIf="isShowTestSetting">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Power meter</mat-label>
              <input matInput formControlName="power_meter" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Lactate analyzer</mat-label>
              <input matInput formControlName="lactate_analyzer" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Spirograph</mat-label>
              <input matInput formControlName="spirograph" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="">Heart rate device</mat-label>
              <input matInput formControlName="heart_rate_device" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Ergometer</mat-label>
              <input matInput formControlName="ergometer" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Temperature</mat-label
              >
              <input matInput formControlName="temperature" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Air Pressure</mat-label
              >
              <input matInput formControlName="air_pressure" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Humidity</mat-label
              >
              <input matInput formControlName="humidity" />
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Altitude</mat-label
              >
              <input matInput formControlName="altitude" />
            </mat-form-field>
          </div>
        </div>
        <div class="setting__section" *ngIf="rights.test_constants_list">
          <h3 class="sub-title border-color">
            <mat-icon
              *ngIf="!isShowConstantsSetting"
              (click)="isShowConstantsSetting = true"
              >keyboard_arrow_right</mat-icon
            >
            <mat-icon
              *ngIf="isShowConstantsSetting"
              (click)="isShowConstantsSetting = false"
              >keyboard_arrow_down</mat-icon
            >
            Constants
          </h3>
          <div class="content" *ngIf="isShowConstantsSetting">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>klavo2</mat-label>
              <input matInput type="number" formControlName="klavo2" />
              <mat-error
                *ngIf="
                  testForm.controls?.klavo2?.errors &&
                  testForm.controls?.klavo2?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.klavo2.errors
                      | errorFormFormat : { fieldName: 'klavo2' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>ks_lapH</mat-label>
              <input type="number" matInput formControlName="ks_lapH" />
              <mat-error
                *ngIf="
                  testForm.controls?.ks_lapH?.errors &&
                  testForm.controls?.ks_lapH?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.ks_lapH.errors
                      | errorFormFormat : { fieldName: 'ks_lapH' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>ks_vo2</mat-label>
              <input type="number" matInput formControlName="ks_vo2" />
              <mat-error
                *ngIf="
                  testForm.controls?.ks_vo2?.errors &&
                  testForm.controls?.ks_vo2?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.ks_vo2.errors
                      | errorFormFormat : { fieldName: 'ks_vo2' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="">ks2_laADP</mat-label>
              <input type="number" matInput formControlName="ks2_laADP" />
              <mat-error
                *ngIf="
                  testForm.controls?.ks2_laADP?.errors &&
                  testForm.controls?.ks2_laADP?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.ks2_laADP.errors
                      | errorFormFormat : { fieldName: 'ks2_laADP' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>lao2eq</mat-label>
              <input type="number" matInput formControlName="lao2eq" />
              <mat-error
                *ngIf="
                  testForm.controls?.lao2eq?.errors &&
                  testForm.controls?.lao2eq?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.lao2eq.errors
                      | errorFormFormat : { fieldName: 'lao2eq' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >pcro2eq</mat-label
              >
              <input type="number" matInput formControlName="pcro2eq" />
              <mat-error
                *ngIf="
                  testForm.controls?.pcro2eq?.errors &&
                  testForm.controls?.pcro2eq?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.pcro2eq.errors
                      | errorFormFormat : { fieldName: 'pcro2eq' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >vla_rest</mat-label
              >
              <input type="number" matInput formControlName="vla_rest" />
              <mat-error
                *ngIf="
                  testForm.controls?.vla_rest?.errors &&
                  testForm.controls?.vla_rest?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.vla_rest.errors
                      | errorFormFormat : { fieldName: 'vla_rest' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Muscle male grad</mat-label
              >
              <input type="number" matInput formControlName="f_vs_musc_male_grad" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_musc_male_grad?.errors &&
                  testForm.controls?.f_vs_musc_male_grad?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_musc_male_grad.errors
                      | errorFormFormat : { fieldName: 'f_vs_musc_male_grad' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Muscle male intercept</mat-label
              >
              <input type="number" matInput formControlName="f_vs_musc_male_b" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_musc_male_b?.errors &&
                  testForm.controls?.f_vs_musc_male_b?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_musc_male_b.errors
                      | errorFormFormat : { fieldName: 'f_vs_musc_male_b' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Muscle female grad</mat-label
              >
              <input type="number" matInput formControlName="f_vs_musc_female_grad" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_musc_female_grad?.errors &&
                  testForm.controls?.f_vs_musc_female_grad?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_musc_female_grad.errors
                      | errorFormFormat : { fieldName: 'f_vs_musc_female_grad' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Muscle female intercept</mat-label
              >
              <input type="number" matInput formControlName="f_vs_musc_female_b" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_musc_female_b?.errors &&
                  testForm.controls?.f_vs_musc_female_b?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_musc_female_b.errors
                      | errorFormFormat : { fieldName: 'f_vs_musc_female_b' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Water male grad</mat-label
              >
              <input type="number" matInput formControlName="f_vs_h2o_male_grad" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_h2o_male_grad?.errors &&
                  testForm.controls?.f_vs_h2o_male_grad?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_h2o_male_grad.errors
                      | errorFormFormat : { fieldName: 'f_vs_h2o_male_grad' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Water male intercept</mat-label
              >
              <input type="number" matInput formControlName="f_vs_h2o_male_b" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_h2o_male_b?.errors &&
                  testForm.controls?.f_vs_h2o_male_b?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_h2o_male_b.errors
                      | errorFormFormat : { fieldName: 'f_vs_h2o_male_b' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Muscle female grad</mat-label
              >
              <input type="number" matInput formControlName="f_vs_h2o_female_grad" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_h2o_female_grad?.errors &&
                  testForm.controls?.f_vs_h2o_female_grad?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_h2o_female_grad.errors
                      | errorFormFormat : { fieldName: 'f_vs_h2o_female_grad' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >Fat vs. Water female intercept</mat-label
              >
              <input type="number" matInput formControlName="f_vs_h2o_female_b" />
              <mat-error
                *ngIf="
                  testForm.controls?.f_vs_h2o_female_b?.errors &&
                  testForm.controls?.f_vs_h2o_female_b?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.f_vs_h2o_female_b.errors
                      | errorFormFormat : { fieldName: 'f_vs_h2o_female_b' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label class="col-sm-2 control-label-content"
                >tau_vo2 (s)</mat-label
              >
              <input type="number" matInput formControlName="tau_vo2" />
              <mat-error
                *ngIf="
                  testForm.controls?.tau_vo2?.errors &&
                  testForm.controls?.tau_vo2?.touched
                "
              >
                <div
                  [innerHTML]="
                    testForm.controls.tau_vo2.errors
                      | errorFormFormat : { fieldName: 'tau_vo2' }
                  "
                ></div>
              </mat-error>
              <span matSuffix>s</span>
            </mat-form-field>
            <div class="">
              <h3>Additional Constants</h3>
              <hr />
              <br />
            </div>
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>konv_lapcr</mat-label>
              <input type="number" matInput formControlName="konv_lapcr" />
            </mat-form-field>
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>PCR exhaustion</mat-label>
              <input type="number" matInput formControlName="pcr_exhaustion" />
            </mat-form-field>
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>PCR initial value</mat-label>
              <input type="number" matInput formControlName="pcr_initial" />
            </mat-form-field>
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label class="">pcr_mlo2</mat-label>
              <input type="number" matInput formControlName="pcr_mlo2" />
            </mat-form-field>
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label>unit_tau</mat-label>
              <input type="number" matInput formControlName="unit_tau" />
            </mat-form-field>
            <mat-form-field class="full-width"
                            appearance="outline"
                            floatLabel="always">
              <mat-label class="col-sm-2 control-label-content">vo2_initial</mat-label>
              <input type="number" matInput formControlName="vo2_initial" />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <mat-dialog-actions align="start">
      <div>
        <button class="u-width-40"
                *ngIf="tabIndex === 2 || tabIndex === 1"
                mat-raised-button
                color="primary"
                type="button"
                (click)="goPrev()">
          Prev
        </button>
        <button class="u-width-40"
                *ngIf="tabIndex === 0 || tabIndex === 1"
                mat-raised-button
                type="button"
                color="primary"
                (click)="goNext()">
          Next
        </button>
      </div>
    </mat-dialog-actions>
    <mat-dialog-actions align="end">
      <div *ngIf="data.isManual || data.isVirtual">
        <button mat-raised-button
                type="button"
                (click)="closeDialog()">
          Cancel
        </button>
        <button *ngIf="tabIndex === 2 || tabIndex === 1"
                mat-raised-button
                type="button"
                color="primary"
                (click)="save(true)">
          Save&View
        </button>
        <button *ngIf="tabIndex === 2 || tabIndex === 1"
                mat-raised-button
                type="button"
                color="primary"
                (click)="save(false)">
          Save
        </button>
      </div>
      <div *ngIf="data.isLactate || data.isPPD">
        <button class="u-width-140"
                mat-raised-button
                type="button"
                (click)="closeDialog()">
          Cancel
        </button>
        <button class="u-width-140"
                *ngIf="tabIndex === 2 || tabIndex === 1"
                mat-raised-button
                type="button"
                color="primary"
                (click)="save(false)">
          Show Optimization
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
