import { Injectable } from '@angular/core';
import { CourseDetailInterface } from "@modules/courses/course-item/interfaces/course-detail.interface";
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { ApiRequest } from "@shared/models/api-request";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { String } from 'typescript-string-operations';
import { AppConstants } from "../../constants";
import { ServiceBase } from '../service-base';
import {
  CourseCatalogDto,
  CourseDto,
  CourseEnrollmentStatus,
  CourseEntitlementStatus,
  CoursePurchaseDto,
  CourseResultDto,
  ICourseEnrollInput,
  ICoursePurchaseInput,
} from './course-dto';
import { UserWaitlistDto } from '../users/user-waitlist-dto';

@Injectable({
  providedIn: 'root',
})
export class CoursesService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto | any): Observable<CourseResultDto> {
    const apiUrl: string = this._appConstants.API.COURSE.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(
      apiUrl,
      request
    );

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new CourseResultDto().deserialize({
          totalCount: res.length,
          items: res.map((item: CourseDto) =>
            new CourseDto().deserialize(item)
          ),
        });
      })
    );
  }

  public update(id: number, product: any): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.COURSE.UPDATE, id);
    return this._apiService.put(apiUrl, product);
  }

  public getUserCoursesDashboard(): Observable<Object> {
    return this._apiService.get(AppConstants.ACCOUNTS_URL.USER_COURSES_DASHBOARD);
  }

  public getUserCourseDetail(id: string | null): Observable<CourseDetailInterface> {
    const apiUrl: string = String.Format(AppConstants.ACCOUNTS_URL.LMS_USER_COURSE_DETAIL, id);
    return this._apiService.get(apiUrl) as Observable<CourseDetailInterface>;
  }

  public joinCourseWaitlist(id: string | null): Observable<UserWaitlistDto> {
    const apiUrl: string = String.Format(AppConstants.ACCOUNTS_URL.USER_COURSES_WAITLIST, id);
    return this._apiService.post(apiUrl) as Observable<UserWaitlistDto>;
  }

  public purchaseCourse(input: ICoursePurchaseInput): Observable<CoursePurchaseDto> {
    const apiUrl: string = this._appConstants.API.INVOICES.PURCHASE_COURSE;
    return this._apiService.post(apiUrl, input).pipe(
      map((item: Object) => new CoursePurchaseDto().deserialize(item))
    );
  }

  public enrollIntoCourse(input: ICourseEnrollInput): Observable<any> {
    const apiUrl: string = this._appConstants.API.COURSE.ENROLL;
    return this._apiService.post(apiUrl, input);
  }

  public getEnrollmentStatus(course: CourseCatalogDto): CourseEnrollmentStatus {
    // All courses count as created. Once a course is purchased the status
    // transitions to `PURCHASED`. Once a user enrolls onto the course the
    // status transitions to `ENROLLED`
    if (course.enrolled_on != null) {
      return CourseEnrollmentStatus.ENROLLED;
    }

    if (course.is_purchased) {
      return CourseEnrollmentStatus.PURCHASED;
    }

    return CourseEnrollmentStatus.CREATED;
  }

  public getEntitlementStatus(course: CourseCatalogDto): CourseEntitlementStatus {
    // Once a user purchases a course, they are entitled to that course for
    // as long as they are not in the ongoing access period.
    const now: number = new Date().getTime();
    const entitledUntil: number | undefined = course.entitled_until ? new Date(course.entitled_until).getTime() : undefined;
    const ongoingAccessUntil: number | undefined = course.ongoing_access_until ? new Date(course.ongoing_access_until).getTime() : undefined;

    if (!course.is_purchased) {
      return CourseEntitlementStatus.NONE;
    }

    if (entitledUntil == null || ongoingAccessUntil == null) {
      // Course purchased but there is no entitlement limit
      // If the user is enrolled they then they are in the subscription (ongoing access)
      // state. If they are not enrolled then they are entitled to enroll
      return course.enrolled_on != null ? CourseEntitlementStatus.ONGOING_ACCESS : CourseEntitlementStatus.ENTITLED;
    }

    if (now >= entitledUntil && now <= ongoingAccessUntil) {
      return CourseEntitlementStatus.OFFER_ONGOING_ACCESS;
    }

    return CourseEntitlementStatus.ENTITLED;
  }
}
