<h4 mat-dialog-title>{{modalWindowTitle}}</h4>
<h2 style="text-align: center">Mean Maximum Power</h2>
<mat-dialog-content>
  <div [formGroup]="form" class="form-box">
    <p>Effort</p>
    <mat-form-field appearance="outline" floatLabel="always" style="width: 25%">
      <mat-label>Time [sec]</mat-label>
      <input
        matInput
        min="12"
        max="1800"
        type="number"
        formControlName="duration_efforts"
      />
      <mat-error
        *ngIf="
          form.get('duration_efforts')?.errors &&
          form.get('duration_efforts')?.touched
        "
        >Duration is between 12 and 1800
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="data.is_speed_sport"
      appearance="outline"
      floatLabel="always"
      style="width: 25%"
    >
      <mat-label>Average Speed [m/s]</mat-label>
      <input
        matInput
        type="number"
        min="0.1"
        max="20"
        formControlName="average_speed"
      />
      <mat-error
        *ngIf="
          form.get('average_speed')?.errors &&
          form.get('average_speed')?.touched
        "
        >Average speed goes from 0.1 to 20
      </mat-error></mat-form-field
    >
    <mat-form-field
      *ngIf="!data.is_speed_sport"
      appearance="outline"
      floatLabel="always"
      style="width: 25%"
    >
      <mat-label>Average Power [W]</mat-label>
      <input matInput type="number" formControlName="average_power" />
      <mat-error
        *ngIf="
          form.get('average_power')?.errors &&
          form.get('average_power')?.touched
        "
        >Average power must be between 0 and 5000
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Work [Joule]</mat-label>
      <input readonly matInput type="number" formControlName="work"
    /></mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">OK</button>
  </div>
</mat-dialog-actions>
