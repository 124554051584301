import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { AppConstants } from '@core/constants';
import { RightsManagementDto, RightsManagementResultDto } from '@core/services/rights-management/rights-management-dto';
import { RightsManagementService } from '@core/services/rights-management/rights-management.service';
import { RightsManagementDialogComponent } from './rights-management-dialog/rights-management-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-rights-management',
  templateUrl: './rights-management.component.html',
})
export class RightsManagementComponent extends PagedListingBaseComponent<RightsManagementDto> {
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: RightsManagementDto[] = [];
  public displayedColumns: string[] = [
    'id',
    'name',
    'organization__name',
    'is_active',
    'action',
  ];
  public isOwnerOrAgent: boolean = false;

  constructor(
    private readonly rightManagementService: RightsManagementService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  public onInitPage(): void {
    this.isOwnerOrAgent =
      this.auth.role.is_owner || this.auth.role.is_agent_role;
    if (!this.isOwnerOrAgent) {
      this.displayedColumns = this.displayedColumns.filter(
        (item: string): boolean => item !== 'organization__name'
      );
    }
    this.request.sortByDesc = false;
    this.request.sorting = 'id';
    super.onInitPage();
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number,): void {
    request.pageIndex = pageNumber;
    this.rightManagementService
      .list(request)
      .subscribe((res: RightsManagementResultDto): void => {
        this.dataSource = res.items;
        this.totalItems = res.totalCount;
      });
  }

  protected getPagedRequestDto(): PagedAndSortedRequestDto {
    return this.request;
  }

  public addRights(): void {
    this.openDialog({ id: '' });
  }

  public edit(event: any): void {
    this.openDialog({ id: event?.id });
  }

  public copy(event: any): void {
    this.openDialog({
      id: event?.id,
      isCopy: true,
    });
  }

  private openDialog(param: any): void {
    const dialogRef: MatDialogRef<RightsManagementDialogComponent> = this.dialog.open(RightsManagementDialogComponent, {
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { ...param },
    });

    dialogRef.afterClosed().subscribe((res): void => {
      if (res) {
        let message: string = 'Item created successfully';
        if (param?.id && !param?.isCopy) {
          message = 'Item updated successfully';
        }
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.refresh();
      }
    });
  }
  public deleteRight(id: number): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.rightManagementService.delete(id).subscribe(
            (): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }
}
