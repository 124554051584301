<div class="deactivate-account-modal">
  <h1 mat-dialog-title>{{title}}</h1>
  <div mat-dialog-content><p>{{message}}</p></div>
  <mat-dialog-actions align="center">
    <div class="full-width o-flex o-flex--justify-space-between">
      <button class="deactivate-account-modal--button-width" mat-button mat-raised-button (click)="onCancel()">Don't Deactivate</button>
      <button class="deactivate-account-modal--button-width" mat-raised-button color="primary" (click)="onConfirm()">Yes, Deactivate My Account</button>
    </div>
  </mat-dialog-actions>

</div>
