<h1 mat-dialog-title>Choose Metrics For {{ data.title }}</h1>
<div mat-dialog-content>
  <app-chips
    [label]="'Choose metrics'"
    [form]="dataPointListControl"
    [options]="optionList"
    [displayField]="'name'"
    (optionChange)="onChanged($event)"></app-chips>
</div>
<div class="o-flex o-flex--justify-right" mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</div>
