import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class InformationPopupResultDto extends PagedResultDto {
  public items: InformationPopupDto[] = [];
}

export class InformationPopupDto extends Deserializable {
  public id: number | undefined;
  public organizations: | Array<{
    id: number | undefined;
    name: string | undefined;
    photo: string | undefined;
    is_solvent: boolean | undefined;
    address_country_id: number | undefined;
    is_check_invoice: boolean | undefined;
  }> | undefined;
  public name: string | undefined;
  public title: string | undefined;
  public text_content: string | undefined;
  public is_active: boolean | undefined;
  public type: number | undefined;
  public expiration: string | undefined;
  public is_all_organizations: boolean | undefined;
  public created_at: string | undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
  public users: | Array<Number> | undefined;

  public getDate(): string {
    let date: Date | null = this.expiration ? new Date(this.expiration) : null;
    const converted: string | undefined = date?.toLocaleDateString('en-US').replace(/\//g, '.');

    return converted + "";
  }

  public getActivity(): string {
    let value = "";
    if (this.is_active == true) {
      value = "Yes"
    } else if (this.is_active == false) {
      value = "No"
    }
    return value;
  }

  public getType(): string {
    let value = "";
    if (this.is_active == true) {
      value = "Read"
    } else if (this.is_active == false) {
      value = "Read & Accept"
    }
    return value;
  }
}
