import { Injectable } from "@angular/core";
import { WaffleFeatureFlagsService } from "../feature-flags/waffle-feature-flags.service";
import { FeatureFlagValue, FeatureFlags, Features } from "../feature-flags/interfaces/feature-flags.interface";
import { SessionService } from "@core/services/session.service";
import { FeatureRightsMapping } from "./interfaces/feature-rights-mapping.interface";

const featureRightsMappings: Array<FeatureRightsMapping> = [
  {
    slug: "swimming_freestyle",
    rights: ["sport_can_use_id_5_swimming_-_freestyle"],
    feature_flag: "feature_swimming_freestyle"
  },
  {
    slug: "lt1",
    rights: [],
    feature_flag: "feature_lt1"
  }
];

@Injectable({
  providedIn: "root"
})
export class FeatureManagementService {
  constructor(
    private readonly _featureFlagsService: WaffleFeatureFlagsService,
    private readonly _sessionService: SessionService
  ) { }

  private getMappingForRight = (right: string): FeatureRightsMapping | undefined =>
    featureRightsMappings.find(mapping => mapping.rights.some(rightName => rightName === right))

  private getMappingForSlug = (slug: string): FeatureRightsMapping | undefined => 
    featureRightsMappings.find(mapping => mapping.slug === slug)

  private getRightsManagementValue = (right: string): boolean => this._sessionService.rights[right] ?? false;

  private getFeatureValue = (slug: string): boolean => this._sessionService.features[slug] ?? false;

  public isEnabled(right: string): boolean {
    const mapping = this.getMappingForRight(right);
    const rightsValue = this.getRightsManagementValue(right);
    if (!mapping) {
      return rightsValue;
    }

    const featureFlags: FeatureFlags | undefined = this._featureFlagsService.getFlags();
    if (!featureFlags) {
      return rightsValue;
    }

    const flagValue: FeatureFlagValue = featureFlags[mapping.feature_flag];
    if (!flagValue) {
      // We default to using rights management if there is no enabled feature flag
      return rightsValue;
    }

    // If there is an enabled feature flag, we use the feature dictionary instead
    return this.getFeatureValue(mapping.slug);
  }

  /**
   * Returns the feature value. If there is a feature flag and the feature is
   * not enabled then it returns the value of the feature flag instead
   */
  public isFeatureEnabled(slug: string): boolean {
    const featureFlags: FeatureFlags | undefined = this._featureFlagsService.getFlags();
    const mapping = this.getMappingForSlug(slug);
    const featureValue = this.getFeatureValue(slug);

    if (!featureFlags) {
      return featureValue
    }

    if (!mapping) {
      return featureValue
    }

    const featureFlag = featureFlags[mapping.feature_flag];
    if (featureFlag != null) {
      return featureValue ?? featureFlag as boolean;
    }

    return featureValue;
  }
}
