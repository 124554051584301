import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { throwError as _observableThrow, of as _observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRequest } from '@shared/models/api-request';
import { ServiceBase } from '../service-base';
import { String } from 'typescript-string-operations';
import { TimeToDepletionDto } from './time-to-depletion-dto';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { TestPagedResultDto } from './test-paged-result-dto';
import { TestDto } from './test-dto';
import { TestConstants } from "../../test-constants";

@Injectable()
export class TestService extends ServiceBase {
  public selectedTests: any[] = [];

  public formatTestType(testType: string): string {
    testType = (testType || '').toUpperCase();
    switch (testType) {
      case TestConstants.Type.PPD:
        return TestConstants.TypeDisplay.PPD;
      case TestConstants.Type.LACTATE:
        return TestConstants.TypeDisplay.LACTATE;
      case TestConstants.Type.MANUAL:
        return TestConstants.TypeDisplay.MANUAL;
      case TestConstants.Type.VIRTUAL:
        return TestConstants.TypeDisplay.VIRTUAL;
      default:
        return TestConstants.TypeDisplay.UNKNOWN;
    }
  }

  public genChartTimeToDepletion(data: any): Observable<TimeToDepletionDto> {
    const apiUrl: string = this._appConstants.API.TEST.TIME_TO_DEPLETION;
    const body = {};
    Object.assign(body, data);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService.post(apiRequest.apiUrl, apiRequest.body).pipe(
      _observableMergeMap((response_: any) => {
        const elementPagedResultDto: TimeToDepletionDto = new TimeToDepletionDto().deserialize(response_);

        return _observableOf(elementPagedResultDto);
      })
    );
  }

  public genChartRecoveryMatrix(data: any): Observable<TimeToDepletionDto> {
    const apiUrl: string = this._appConstants.API.TEST.RECOVERY_MATRIX;
    const body = {};
    Object.assign(body, data);
    const apiRequest:ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService.post(apiRequest.apiUrl, apiRequest.body).pipe(
      map((response_: any) => {
        return new TimeToDepletionDto().deserialize(response_);
      })
    );
  }

  public genChartTZBHeartRate(data: any): Observable<any> {
    const apiUrl: string = this._appConstants.API.TEST.TZB_HEART_RATE;
    const body = {};
    Object.assign(body, data);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService.post(apiRequest.apiUrl, apiRequest.body).pipe(
      map((response_: any) => {
        return new TimeToDepletionDto().deserialize(response_);
      })
    );
  }

  public list(request: PagedAndSortedRequestDto): Observable<TestPagedResultDto> {
    const apiUrl: string = this._appConstants.API.TEST.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      _observableMergeMap((response_: any): Observable<TestPagedResultDto> => {
        if (response_.length === 0) {
          return _observableOf(new TestPagedResultDto());
        }
        const elementPagedResultDto: TestPagedResultDto = new TestPagedResultDto().deserialize({
          totalCount: response_.count,
          items: response_.results.map((item: TestDto) =>
            new TestDto().deserialize(item)
          ),
        });
        return _observableOf(elementPagedResultDto);
      })
    );
  }

  public delete(id: number): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.DELETE, id);
    return this._apiService
      .delete(apiUrl)
      .pipe(
        _observableMergeMap((response_: any) => {
          return _observableOf(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          return <Observable<void>>(<any>_observableThrow(response_));
        })
      );
  }

  public getByAthleteAndSport(athleteId: number, sportId: number): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.GET_BY_ATHLETE_SPORT, athleteId, sportId);

    return this._apiService
      .get(apiUrl)
      .pipe(
        _observableMergeMap((response_: any) => {
          return _observableOf(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          return <Observable<void>>(<any>_observableThrow(response_));
        })
      );
  }

  public downloadFile(id: number): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.DOWNLOAD_FILE, id);

    return this._apiService.getFile(apiUrl).pipe(
      _observableCatch((response_: any) => {
        return <Observable<void>>(<any>_observableThrow(response_));
      })
    );
  }

  public downloadSSEReport(id: number): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.DOWNLOAD_SSE_REPORT, id);

    return this._apiService.getFile(apiUrl).pipe(
      _observableCatch((error: any) => {
        throw error;
      })
    );
  }

  public getSpeedRelationshipChartData(id: number, data: any): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.SPEED_RELATIONSHIP_CHART, id);
    const body = {};
    Object.assign(body, data);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService.post(apiRequest.apiUrl, apiRequest.body).pipe(
      _observableCatch((response_: any) => {
        return <Observable<void>>(<any>_observableThrow(response_));
      })
    );
  }

  public createTest(input: unknown): Observable<Object> {
    const apiUrl: string = this._appConstants.API.TEST.LIST;

    return this._apiService.xhrCall(apiUrl, input, 'POST');
  }

  public updateTest(id: number, input: any): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.UPDATE,id);
    const body = {};
    Object.assign(body, input);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService.xhrCall(apiUrl, apiRequest.body, 'PUT');
  }

  public getTestById(id: number): Observable<TestDto> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.GET_BY_ID, id);

    return this._apiService
      .get(apiUrl)
      .pipe(map((item) => new TestDto().deserialize(item)));
  }

  public getMetabolicTest(id: number): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.TEST.METABOLIC, id);

    return this._apiService.get(apiUrl);
  }

  public getBackTestById(id: any): Observable<any> {
    const apiUrl: string = this._appConstants.API.TEST.LIST + '?id=' + id + '&test_action=Back';

    return this._apiService
      .get(apiUrl)
      .pipe(map((item) => new TestDto().deserialize(item)));
  }
}
