export const default_text = [
  {
    "id": 1,
    "comment": "r",
    "introduction": "reeeeeeeeeeeeeeeeeee",
    "body_composition": "r",
    "metabolic_capacities": "r",
    "metabolic_profile": "r",
    "metabolic_fingerprint": "r",
    "performance_development": "r",
    "training_zones": "r",
    "test_data": "r",
    "heart_rate": "r",
    "economy": "r",
    "metabolic_power": "r",
    "test_data_tables": "r",
    "created_at": "2024-08-20T15:38:26.454694Z",
    "updated_at": "2024-11-18T16:33:22.949387Z"
  }
]
