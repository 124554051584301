import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { CurrentAccountService } from "@shared/services/current-account/current-account.service";
import { ICurrentAccount } from "@shared/interfaces/current-account.interface";

@Injectable()
export class PersonalInfoResolver {
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private currentAccountService: CurrentAccountService = inject(CurrentAccountService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);

  public resolve(): Observable<ICurrentAccount | null> {
    return this.currentAccountService.getCurrentAccount()
      .pipe(
        catchError(error => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          return of(null);
        }
        )
      )
  }
}
