<div class="page-container">
  <div class="top-toolbar">
    <div class="sub-title">SETTINGS</div>
    <div class="title">Security</div>
  </div>
  <div>
    <span class="u-font-size-14 u-font-weight-700 line-height-normal">Your INSCYD Sessions & login</span>
    <hr>
    <br>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
    <ng-container matColumnDef="device">
      <th scope="row" mat-header-cell *matHeaderCellDef class="mat-column-id">Device name</th>
      <td mat-cell *matCellDef="let element">{{ getDeviceName(element) }}</td>
    </ng-container>
    <ng-container matColumnDef="device_info">
      <th scope="row" mat-header-cell *matHeaderCellDef class="mat-column-id">Device info</th>
      <td mat-cell *matCellDef="let element">{{ getDeviceInfo(element) }}</td>
    </ng-container>
    <ng-container matColumnDef="ip">
      <th scope="row" mat-header-cell *matHeaderCellDef>Location (IP Address)</th>
      <td mat-cell *matCellDef="let element" class="hide-long-text">{{ getLocationIp(element) }}</td>
    </ng-container>
    <ng-container matColumnDef="last_login">
      <th scope="row" mat-header-cell *matHeaderCellDef>Last Login (UTC)</th>
      <td mat-cell *matCellDef="let element">{{ element.last_login | date: constants.DATE_FORMAT.DATE_TIME_SECOND}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th scope="row" mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element" class="fixed-width-action">
        <span *ngIf="element.is_current">Current Session</span>
        <button mat-raised-button color="primary" (click)="logoutFromSession(element)" class="btn-icon-text" *ngIf="!element.is_current">Logout</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row [ngClass]="{'highlighted-table-row': row.is_current}" *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" colspan="11">No data available in table</td>
    </tr>
  </table>
  <div class="o-flex o-flex--justify-right">
    <button mat-raised-button color="primary" (click)="logoutFromSessionAllExceptCurrenSession()" class="u-float-right u-margin-top-10" *ngIf="dataSource.length > 1">Logout All</button>
  </div>
  <div *ngIf="!auth.is_admin" class="u-margin-top-50">
    <span class="u-font-size-14 u-font-weight-700 line-height-normal">Your INSYCD User Account</span>
    <hr>
    <button mat-raised-button color="primary" (click)="deleteCurrentAccount()" class="u-display-block u-margin-top-20">Delete My INSCYD User Account</button>
    <button mat-raised-button color="primary" (click)="deactivateAccount()" class="u-display-block u-margin-top-20">Deactivate My INSCYD User Account</button>
  </div>
</div>
