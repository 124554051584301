<form autocomplete="off" [formGroup]="teamForm" class="createNew">
  <div>
    <div class="">
      <h1 *ngIf="!data.isEditTeam" mat-dialog-title>Add Team</h1>
      <h1 *ngIf="data.isEditTeam" mat-dialog-title>Edit Team</h1>
    </div>
    <div class="form-group row">
      <div>
        <mat-form-field floatLabel="always" class="full-width" appearance="outline">
          <mat-label>Name(*)</mat-label>
          <input placeholder="Name" matInput formControlName="name" />
          <mat-error> </mat-error>
          <mat-error
            *ngIf="
              teamForm.get('name')?.hasError('required') &&
              teamForm.controls.name.touched
            "
          >
            Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="always" class="full-width"  appearance="outline">
          <mat-label>Year(*)</mat-label>
          <input
            placeholder="Year"
            formControlName="selectYear"
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker
            (yearSelected)="chosenYearHandler($event, picker)"
          ></mat-datepicker>
          <mat-error
            *ngIf="
              teamForm.get('selectYear')?.hasError('required') &&
              teamForm.controls.selectYear.touched
            "
          >
            Year must be a valid integer between 1900 and 2100
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <div>
          <mat-form-field floatLabel="always" class="full-width"  appearance="outline">
            <mat-label>Coaches(*)</mat-label>
            <mat-select placeholder="Select coaches" multiple formControlName="coaches">
              <mat-option
                *ngFor="let option of filteredCoaches"
                [value]="option?.id"
              >
                {{ option?.username }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                teamForm.get('coaches')?.hasError('required') &&
                teamForm.controls.coaches.touched
              "
            >
              Coaches field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field floatLabel="always" class="full-width"  appearance="outline">
          <mat-label>Athletes(*)</mat-label>
          <mat-select placeholder="Select athletes" multiple formControlName="athletes">
            <mat-option
              *ngFor="let option of filteredAthletes"
              [value]="option?.id"
            >
              {{ option?.first_name }} {{ option?.last_name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              teamForm.get('athletes')?.hasError('required') &&
              teamForm.controls.athletes.touched
            "
          >
            Athletes field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="multiple-select">
        <div class="theme-switcher-container">
          Active
          <mat-slide-toggle formControlName="isActive">&nbsp;</mat-slide-toggle>
        </div>
        <mat-error *ngIf="teamForm.get('isActive')?.hasError('required')">
          Please complete field
        </mat-error>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <div class="mt-3">
        <button
        mat-raised-button
        color="primary"
        (click)="saveAction()"
      >
        Save
      </button>
      <button mat-raised-button id="cancelButton" type="button" (click)="closeDialog()">
        Cancel
      </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
