import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { CountriesDto } from '@core/services/region-settings/countries-dto';
import { IRegionDetail, RegionSettingsDto } from '@core/services/region-settings/region-settings-dto';
import { RegionSettingsService } from '@core/services/region-settings/region-settings.service';

@Component({
  selector: 'app-change-region-dialog',
  templateUrl: './change-region-dialog.component.html',
})
export class ChangeRegionDialogComponent extends AppBaseComponent {
  public dataSource: CountriesDto[] = [];
  public selectedValue: any;
  public selectedNewValue: any;
  public regions: any;
  public form: UntypedFormGroup = this.fb.group( {} );
  public currentCountry: IRegionDetail = {};

  constructor(
    public dialogRef: MatDialogRef<ChangeRegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly snackBar: MatSnackBar,
    private readonly regionSettingsService: RegionSettingsService,
    private readonly fb: UntypedFormBuilder,
    private readonly dialog: MatDialog) {
    super();
  }

  public onInitPage(): void {
    this.loadCountries();
  }

  public loadCountries(): void {
    this.regionSettingsService.listCountries().subscribe((res): void => {
      this.dataSource = res.items;
    });
  }

  public prepareData(data: RegionSettingsDto, isFromRegion: any) {
    let dataCountry: number[] = data.country?.map((item) => item.id);
    if(isFromRegion) {
      dataCountry = dataCountry?.filter((item: number): boolean => item != this.selectedValue.id);
    } else {
      dataCountry?.push(this.selectedValue.id);
      dataCountry?.sort();
    }

    return {
      id: data.id,
      name: data.name,
      price: data.price,
      currency: data.currency?.id,
      country: dataCountry
    };
  }

  public update(): void {
    this.currentCountry.from_region = this.prepareData(this.selectedValue.region, true);
    this.currentCountry.to_region = this.prepareData(this.selectedNewValue, false);
    this.currentCountry.country_id = this.selectedValue.id;
    this.regionSettingsService
      .updateCountryRegion(this.currentCountry)
      .subscribe((res: RegionSettingsDto): void => {
        this.dialogRef.close(res);
        this.snackBar.open('Region updated successfully.', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
      });
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    const title: string = 'Change Region';
    const message: string = 'Changing region will affect the currency and price of future invoices. Are you sure to proceed?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel(title, message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .subscribe((result): void => {
        if(result) {
          this.update();
        }
      });
  }

  public onChange(newValue: any): void {
    this.regions = this.data.regions;
    this.regions = this.regions.filter((item:any): boolean => item.name != newValue.region.name);
  }
}
