import { Injectable } from "@angular/core";
import { ReportField, ReportTemplate } from "../report-template/report-template.service";

export interface ReportPreviewPage {
  fields: Array<ReportField>
}

@Injectable({
  providedIn: 'root'
})
export class ReportPreviewService {
  public PAGE_BREAK_SEARCH_STRING = 'page_break';

  public getPagesFromTemplate(template: ReportTemplate): ReportPreviewPage[] {
    let pages: ReportPreviewPage[] = [];
    // Sort fields by Y position
    const templateCopy = {...template};
    templateCopy.fields?.sort((itemA: ReportField, itemB: ReportField) => itemA.y - itemB.y);

    const page: ReportPreviewPage = {
      fields: []
    };

    let lastItem;
    if (templateCopy && templateCopy.fields) {
      lastItem = templateCopy.fields[templateCopy.fields.length - 1];
    }

    if (template.fields?.some((item: ReportField) => item.id.includes(this.PAGE_BREAK_SEARCH_STRING))) {
      // If we have page break added by user,
      // first we need to reset top position of each field that is under pb
      let pageBreakTopPosition: number = 0;
      for (let field of templateCopy.fields!) {
        if (field.id.includes(this.PAGE_BREAK_SEARCH_STRING)) {
          pageBreakTopPosition = field.y;
        }
        field.y = field.y - pageBreakTopPosition;
      }
    }

    // Create pages regarding page break elements added by user
    for (let field of templateCopy.fields!) {
      if (field.id.includes(this.PAGE_BREAK_SEARCH_STRING)) {
        const pageCopy = Object.assign({}, page);
        pages = [
          ...pages,
          pageCopy
        ];
        page.fields = [];

      } else if (field.id === lastItem?.id) {
        page.fields.push(field);
        const pageCopy: ReportPreviewPage = Object.assign({}, page);
        pages = [
          ...pages,
          pageCopy
        ];
        page.fields = [];
      } else {
        page.fields.push(field);
      }
    }
    return pages;
  }

  public calculatePageHeight(template: ReportTemplate, heightUnit: number, defaultHeight: number, scaleFactor: number = 2): number {
    if (template && template.fields) {
      // Find the furthest field
      let furthestField: ReportField = template.fields[template.fields.length - 1];

      for (let field of template.fields) {
        if (field.y + field.height > furthestField?.y + furthestField?.height) {
          furthestField = field;
        }
      }
      return (furthestField.y * heightUnit + furthestField.height * heightUnit) + 50;
    }
    return defaultHeight;
  }

}
