<div>
  <div class="title-with-search">
    <p *ngIf="!isDeletedView">Organizations</p>
    <p *ngIf="isDeletedView">Deleted Organizations</p>
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input
        matInput
        placeholder="Organization Name"
        [(ngModel)]="searchText" (keyup.enter)="applyFilter($event)"/>
      <mat-icon matPrefix (click)="search()">search</mat-icon>
    </mat-form-field>
  </div>
  <div class="group-actions mb-3">
    <button
      *ngIf="!isDeletedView"
      mat-raised-button
      class="ml-4"
      (click)="viewDeletedOrganizations()">
      {{ " View Deleted Organizations" }}
    </button>
    <button
      *ngIf="isDeletedView"
      mat-raised-button
      class="ml-4"
      (click)="viewActiveOrganizations()">
      {{ " View Active Organizations" }}
    </button>
    <button
      *ngIf="!isDeletedView"
      mat-raised-button
      color="primary"
      class="ml-4"
      (click)="add()">
      {{ " + Add" }}
    </button>
  </div>
  <table
    mat-table
    matSort
    #tableData
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    style="width: 100%"
    (matSortChange)="sortData($event)"
    (contentChanged)="onHideLoading()"
    matSortActive="-id"
    matSortDirection="desc"
    matSortDisableClear
    aria-describedby="organization management list">
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-column-id"
        scope="row">ID</th>
      <td mat-cell *matCellDef="let element">{{ element?.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Active
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.is_active }}
      </td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Country
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.country }}</td>
    </ng-container>
    <ng-container matColumnDef="allowed_devices">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Allowed Devices
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.max_devices }}</td>
    </ng-container>
    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Users (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.user }}
      </td>
    </ng-container>
    <ng-container matColumnDef="athletes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Athletes (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.athlete }}
      </td>
    </ng-container>
    <ng-container matColumnDef="la">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        LA (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.test_la }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ppd">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        PPD (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.test_map }}
      </td>
    </ng-container>
    <ng-container matColumnDef="manual">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Manual (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.test_manual }}
      </td>
    </ng-container>
    <ng-container matColumnDef="virtual">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Virtual (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.test_virtual }}
      </td>
    </ng-container>
    <ng-container matColumnDef="events">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Events (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.test_event }}
      </td>
    </ng-container>
    <ng-container matColumnDef="simulations">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Simulations (Used/Limit)
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.test_result }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element" class="fixed-width-action">
        <div class="action-btns">
          <button
            *ngIf="!isDeletedView"
            mat-raised-button
            color="primary"
            (click)="openStatistics(element)"
            class="btn-icon-text">
            <mat-icon>query_stats</mat-icon> Statistics
          </button>
          <button
            *ngIf="!isDeletedView"
            mat-raised-button
            color="primary"
            (click)="openAnalytics(element.id)"
            class="btn-icon-text">
            <mat-icon>query_stats</mat-icon> Analytics</button>
          <button
            *ngIf="!isDeletedView"
            mat-raised-button
            color="primary"
            (click)="edit(element.id)"
            class="btn-icon-text"><mat-icon>mode_edit</mat-icon> Edit</button>
          <button
            *ngIf="!isDeletedView"
            mat-button
            (click)="deleteOrganization(element.id)"
            class="btn-icon-text"><mat-icon>delete</mat-icon> Delete</button>
          <button
            *ngIf="isDeletedView"
            mat-button
            (click)="restoreOrganization(element.id)"
            class="btn-icon-text"><mat-icon>restore</mat-icon> Restore</button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" colspan="13">No data available in table</td>
    </tr>
  </table>
</div>
