import { Component, inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { AppConstants } from '@core/constants';
import { CourseCatalogDto, CourseEnrollmentStatus, CourseEntitlementStatus } from '@core/services/courses/course-dto';
import { CoursesService } from '@core/services/courses/courses.service';
import { SessionService } from "@core/services/session.service";
import { CollegeStatusEnum } from "@modules/courses/college-status.enum";
import { CustomRendererService } from "@shared/services/custom-renderer.service";

@Component({
  selector: 'app-card-course-catalog',
  templateUrl: './card-course-catalog.component.html',
  styleUrls: ['./card-course-catalog.component.scss'],
})
export class CardCourseCatalogComponent implements OnInit, AfterViewInit {
  private customRendererService: CustomRendererService = inject(CustomRendererService);

  public auth = JSON.parse(this.sessionService.getCookie('auth'));
  public constant = AppConstants;
  public isStartCourse: boolean;
  public isResumeCourse: boolean;
  public isStartOverCourse: boolean;
  public isLearnMore: boolean;
  public isPurchasedAndNotEnrolled: boolean;

  @Input() public course: any;

  constructor(private router: Router, private sessionService: SessionService, private coursesService: CoursesService,) {}

  public ngOnInit(): void {
    const entitlementStatus: CourseEntitlementStatus = this.coursesService.getEntitlementStatus(this.course as CourseCatalogDto);

    this.isStartCourse = this.course.completion_status === CollegeStatusEnum.NOT_ATTEMPTED;
    this.isResumeCourse = this.course.completion_status === CollegeStatusEnum.INCOMPLETE || entitlementStatus === CourseEntitlementStatus.ONGOING_ACCESS;
    this.isStartOverCourse = this.course.completion_status === CollegeStatusEnum.COMPLETED;
    this.isLearnMore = this.course.user_price === 0 || entitlementStatus !== CourseEntitlementStatus.OFFER_ONGOING_ACCESS;
    this.isPurchasedAndNotEnrolled = this.course.completion_status === null && this.course.is_purchased;
  }

  public ngAfterViewInit(): void {
    // setTimeout((): void => {
    //   const descriptionContentElement: Node = this.customRendererService.createHtmlFromString(this.course.description_html || this.course.description);
    //   this.customRendererService.append(`#description_${this.course.id}`, descriptionContentElement);
    // });
  }

  public handleCourseAction(): void {
    if (this.isStartCourse || this.isResumeCourse || this.isStartOverCourse) {
      this.runCourse();
    } else if (this.isLearnMore  || this.isPurchasedAndNotEnrolled) {
      this.goToCourseDetail();
    }
  }

  public goToCourseDetail(): void {
    this.router.navigate(['/home/college/course'], {
      queryParams: {
        course_id: this.course.id
      }
    });
  }

  public getPurchaseButtonColor(): string {
    let state: string = '';
    if (this.course.is_purchased || (this.course.user_price === 0 && this.course.enrolled_on)) {
      state = "c-btn-green";
    } else {
      state = "c-btn-red";
    }

    return state;
  }

  public getPurchaseButtonText(): string {
    const enrollmentStatus: CourseEnrollmentStatus = this.coursesService.getEnrollmentStatus(this.course as CourseCatalogDto);

    if (enrollmentStatus == CourseEnrollmentStatus.CREATED) {
      if (this.isLearnMore) {
        return 'Learn More';
      }
    }

    if (this.isStartCourse || this.isPurchasedAndNotEnrolled) {
      return "Start course";
    } else if (this.isResumeCourse) {
      return 'Resume';
    } else if (this.isStartOverCourse) {
      return 'Start over';
    } else {
      return 'N/A';
    }
  }

  private runCourse(): void {
    this.router.navigate(['/home/college/consumed-course'], {
      queryParams: {
        course_id: this.course.id
      }
    });
  }
}
