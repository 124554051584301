import { ChangeDetectorRef, Component, DestroyRef, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { combineLatest, of } from 'rxjs';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { AthleteDto } from '@core/services/athletes-management/athletes-dto';
import { AthletesService } from '@core/services/athletes-management/athletes.service';
import * as _ from 'lodash-es';
import countries from '../../json/countries.json';
import { String } from 'typescript-string-operations';
import * as moment from 'moment';
import { OptimizationOptionDialogComponent } from '../optimization-option-dialog/optimization-option-dialog.component';
import { environment } from 'src/environments/environment';
import { HelperService } from '@shared/services/helper.service';
import { catchError } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-athlete-detail-dialog',
  templateUrl: './athlete-detail-dialog.component.html',
})
export class AthleteDetailDialogComponent extends AppBaseComponent {
  public isShowDetail: boolean = false;
  public emailForm: UntypedFormGroup = {} as UntypedFormGroup;
  public detailForm: UntypedFormGroup = {} as UntypedFormGroup;
  public currentAthlete: AthleteDto = new AthleteDto();
  public genderOptions: Array<any> = [];
  public trainingLevelOptions: Array<any> = [];
  public coachesOptions: Array<any> = [];
  public countriesOptions: Array<any> = [];
  public tagOptions: Array<any> = [];
  public imgFile: any;
  public address_country_control!: UntypedFormControl;

  constructor(
    public dialogRef: MatDialogRef<AthleteDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: UntypedFormBuilder,
    private readonly apiService: ApiService,
    private readonly athletesService: AthletesService,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private helperService: HelperService,
    private destroyRef: DestroyRef,
  ) {
    super();
  }
  public onInitPage(): void {
    this.isShowDetail = this.data?.isShowDetail;
    this.initForm();
    this.initOptionAndValue();
  }
  private initForm(): void {
    this.emailForm = this.fb.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
    const inforControlsConfig = {
      photo: new UntypedFormControl(''),
      first_name: new UntypedFormControl('', Validators.required),
      last_name: new UntypedFormControl('', Validators.required),
      gender: new UntypedFormControl('', Validators.required),
      default_weight: new UntypedFormControl('', Validators.required),
      dob: new UntypedFormControl('', Validators.required),
      training_level: new UntypedFormControl('', Validators.required),
      coaches: new UntypedFormControl([]),
      is_active: new UntypedFormControl({value: false, disabled: true}),
    };
    const addressControlsConfig = {
      address_street_name: new UntypedFormControl(''),
      address_street_number: new UntypedFormControl(''),
      address_zip: new UntypedFormControl(''),
      address_city: new UntypedFormControl(''),
      address_state: new UntypedFormControl(''),
      address_country: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      phone2: new UntypedFormControl(''),
      fax: new UntypedFormControl(''),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      email2: new UntypedFormControl('', Validators.email),
    };
    const additionalControlsConfig = {
      website: new UntypedFormControl(''),
      facebook: new UntypedFormControl(''),
      twitter: new UntypedFormControl(''),
      instagram: new UntypedFormControl(''),
      tags: new UntypedFormControl(''),
    };
    const garminControlsConfig = {
      garmin_email: new UntypedFormControl('', Validators.email),
      garmin_password: new UntypedFormControl(''),
    };
    const ControlsConfig = {
      ...inforControlsConfig,
      ...addressControlsConfig,
      ...additionalControlsConfig,
      ...garminControlsConfig,
    };
    this.detailForm = this.fb.group(ControlsConfig);
    this.getAddressControl();
  }

  private getAddressControl() {
    this.address_country_control = this.detailForm.get(
      'address_country'
    ) as UntypedFormControl;
  }

  private initOptionAndValue() {
    this.genderOptions = AppConstants.GENDER;
    this.countriesOptions = countries.map((item: any) => {
      return {
        value: item?.id,
        name: item?.name,
      };
    });
    combineLatest([
      this.apiService.get('tags/all/'),
      this.apiService.get('accounts/coaches/'),
      this.athletesService.getAthleteTrainingLevel(),
      this.data.id ? this.athletesService.getById(this.data.id) : of(null),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([resTags, resCoaches, resTrainingLevels, resDetail]: any) => {
      this.tagOptions = resTags?.results?.map((item: any) => {
        return this.mappingOptionValue(item, 'name');
      });
      this.coachesOptions = resCoaches?.results?.map((item: any) => {
        return {
          first_name: item.first_name,
          id: item.id,
          last_name: item.last_name,
          username: item.username,
        };
      });
      this.trainingLevelOptions = resTrainingLevels.items;
      if (resDetail) {
        this.currentAthlete = resDetail;
        this.initValueAthlete();
      }
      this.getAddressControl();
    });
  }

  private mappingOptionValue(item: any, param: string) {
    return {
      value: item?.id,
      name: item[param],
    };
  }

  private initValueAthlete(): void {
    const result: Partial<AthleteDto> = _.pickBy(this.currentAthlete, (value, key: string) => {
      return this.detailForm.value.hasOwnProperty(key);
    });

    result.tags = result.tags?.map((item: any) => {
      return this.tagOptions.find((tag): boolean => tag.value == item);
    });

    const photo: string = result?.photo
      ? this.helperService.replaceCorrectPhotoURL(result.photo)
      : '';
    this.detailForm.patchValue({
      ...result,
      photo,
    });

    const currentCountry = this.countriesOptions.find(
      (item): boolean => item.value === this.currentAthlete.address_country_id
    );
    this.detailForm.patchValue({
      address_country: currentCountry,
    });

    if (this.currentAthlete.is_active) {
      this.detailForm.get('is_active')?.enable();
      this.detailForm.patchValue({is_active: true});
    }
  }

  public next(): void {
    if (this.emailForm.valid) {
      this.athletesService.checkEmailExist(this.emailForm.value.email)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          (res: any): void => {
            if (res.is_the_same) {
              this.snackBar.open(
                res.message,
                'OK',
                AppConstants.TOAST_CONFIG.WARNING
              );
            }
          },
          (): void => {
            this.isShowDetail = true;
            this.detailForm.patchValue({
              email: this.emailForm.value.email,
            });
          }
        );
    } else {
      this.emailForm.markAllAsTouched();
    }
  }

  public save(): void {
    if (this.detailForm.valid) {
      if (this.data.id) {
        this.update();
      } else {
        this.openOptimizationOptionsDialog();
      }
    } else {
      this.detailForm.markAllAsTouched();
    }
  }

  private update(): void {
    const input = this.prepareData();
    this.athletesService
      .update(input)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((error) => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          throw error;
        })
      )
      .subscribe((res: Object): void => {
        this.dialogRef.close(res);
      });
  }

  private prepareData() {
    const data = {
      ...this.currentAthlete,
      ...this.detailForm.value,
      organization: this.auth?.organization?.id,
      photo: this.imgFile ? this.imgFile : undefined,
      dob: this.detailForm.value?.dob
        ? moment(this.detailForm.value?.dob).format('YYYY-MM-DD')
        : this.currentAthlete?.dob,
      tags: this.detailForm.value?.tags?.length
        ? this.detailForm.value?.tags
        : undefined,
      address_country_id: this.detailForm?.value?.address_country?.value,
    };
    if (data.coaches && data.coaches.length) {
      data.coaches = data.coaches.map((coach: any) => coach.id);
    }

    if (data.tags) {
      data.tags = data.tags.map((tag: any) => tag.value);
    }
    return data;
  }

  private openOptimizationOptionsDialog() {
    const dialogRef: MatDialogRef<OptimizationOptionDialogComponent> = this.dialog.open(OptimizationOptionDialogComponent, {
      panelClass: 'general-dialog',
      disableClose: true,
      data: {
        type: this.constant.TEST_TYPES.ATHLETE,
      },
    });
    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
      if (res.action !== 'confirm') {
        return;
      }
      const param_array = [];
      let params: string = '';
      if (res.productId) {
        param_array.push('product=' + res.productId);
      }
      if (res.planId) {
        param_array.push('plan=' + res.planId);
      }
      params = param_array.join('&');
      if (params) {
        params = '?' + params;
      }
      this.checkMultipleExceedItem(params);
    });
  }

  private checkMultipleExceedItem(params: string): void {
    this.athletesService
      .checkAthleteAcceptMultiple(params)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        })
      )
      .subscribe((res: any): void => {
        if (res?.is_multiple) {
          const message = `Exceeding maximum amount of "Athlete". You will be charged an amount of ${res?.currency} ${res?.price} for this item for each billing period. In case of deletion or inactivity, this item will be billed in next subscription invoice only. Are you sure you want to continue?`;
          const dialogData: ConfirmDialogModel = new ConfirmDialogModel(
            'Confirmation',
            message,
            'Yes, I Agree',
            'No',
            false
          );
          const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            disableClose: true,
          });
          dialogRef.afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data): void => {
            if (data) {
              this.create(params);
            }
          });
        } else {
          this.create(params);
        }
      });
  }

  private create(param: string): void {
    const input = this.prepareData();
    this.athletesService.create(input, param)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
      (res): void => {
        this.dialogRef.close(res);
      },
      (error): void => {
        this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    );
  }

   public close(): void {
    this.dialogRef.close(false);
  }

  public changeImage(event: any): void {
    const files: File[] = event.target.files;
    if (files.length > 1 || !files.length) {
      event.target.value = '';
    }
    const readFile = async (file: File): Promise<void> => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        this.detailForm.controls.photo.setValue(e.target?.result);
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    };
    if (files.length) {
      if (files[0]?.size <= AppConstants.ATHLETE_PHOTO_MAX_SIZE) {
        this.imgFile = files[0];
        readFile(files[0]);
      } else {
        event.target.value = '';
        this.detailForm.controls.photo.setValue('');
        this.cdr.detectChanges();
        this.snackBar.open(
          'Photo size must be less than 100KB',
          'OK',
          AppConstants.TOAST_CONFIG.ERROR
        );
      }
    }
  }

  public verifyGarmin(): void {
    const { garmin_email, garmin_password } = this.detailForm.value;
    if (garmin_email && garmin_password) {
      const input = this.prepareData();
      this.athletesService.garminVerify(input)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
        (res): void => {
          this.dialogRef.close('success');
        },
        (error): void => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
        }
      );
    } else {
      this.snackBar.open(
        'Garmin email and garmin password is required',
        'OK',
        AppConstants.TOAST_CONFIG.ERROR
      );
    }
  }
  public revokeGarmin(): void {
    this.athletesService.revokeGarmin(this.data.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Object): void => {
      this.dialogRef.close(res);
    });
  }

  public verifyStrava(): void {
    const redirectUrl: string = encodeURIComponent(String.Format(document.location.origin + AppConstants.STRAVA_INFO.REDIRECT_URL, this.data.id));
    const stravaClientId: string = environment?.stravaClientId ? environment?.stravaClientId : AppConstants.STRAVA_INFO.CLIENT_ID;
    const stravaUrl = '?client_id={0}&response_type=code&redirect_uri={1}&approval_prompt=force&scope=activity:write,activity:read'
    const url: string = String.Format(AppConstants.STRAVA_INFO.AUTH_URL + stravaUrl , stravaClientId, redirectUrl);
    window.open(url, '_self');
  }

  public revokeStrava(): void {
    this.athletesService.revokeStrava(this.data.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {
      this.dialogRef.close('success');
    });
  }

  public onSelectCoach(value: any): void {
    this.detailForm.get('coaches')?.setValue(value);
  }

  public onSelectTag(value: any): void {
    this.detailForm.get('tags')?.setValue(value);
  }
}
