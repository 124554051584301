import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { finalize } from 'rxjs/operators';
import { AppConstants } from '@core/constants';
import { ProductDto } from '@core/services/products/product-dto';
import { ProductService } from '@core/services/products/product.service';
import { AddProductDialogComponent } from './add-product-dialog/add-product-dialog.component';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
})
export class ProductsComponent extends PagedListingBaseComponent<ProductDto> {
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: ProductDto[] = [];
  public displayedColumns: string[] = ['id', 'name', 'published', 'action'];

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }
  protected list(request: PagedAndSortedRequestDto, pageNumber: number, finishedCallback: Function): void {
    request.pageIndex = pageNumber;
    this.productService.list(request)
      .pipe(finalize(() => finishedCallback()))
      .subscribe((res: any): void => {
        this.dataSource = res.items;
        this.totalItems = res.totalCount;
      });
  }

  constructor(
    private productService: ProductService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  public addProduct(): void {
    this.openDialog(false);
  }
  public editProduct(product: ProductDto): void {
    this.openDialog(true, product);
  }
  public deleteProduct(id: number): void {
    const message: string = 'Are you sure you want to delete?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.productService.delete(id).subscribe(
            (): void => {
              this.snackBar.open(
                'Item deleted successfully.',
                'OK',
                AppConstants.TOAST_CONFIG.SUCCESS
              );
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }

  private openDialog(isEdit: boolean, product?: ProductDto): void {
    const dialogRef: MatDialogRef<AddProductDialogComponent> = this.dialog.open(AddProductDialogComponent, {
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { product, isEdit },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let message: string = 'Product created successfully';
        if (isEdit) {
          message = 'Product updated successfully';
        }
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.refresh();
      }
    });
  }
}
