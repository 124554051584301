import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { SportDto } from '@core/services/sports/sport-dto';
import { SportService } from '@core/services/sports/sport.service';
import { AthleteCategoryComponent } from './athlete-category/athlete-category.component';
import { SportDetailComponent } from './sport-detail/sport-detail.component';
import { SportPagedResultDto } from "@core/services/sports/sport-paged-result-dto";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
})
export class SportsComponent extends PagedListingBaseComponent<SportDto> {
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: SportDto[] = [];
  public searchText: string = '';
  public displayedColumns: string[] = [
    'id',
    'name',
    'simulation_type_text',
    'ebg_factor_a',
    'ebg_factor_b',
    'ebg_factor_d',
    'muscle_usage_percentage',
    'is_delete',
    'actions',
  ];

  constructor(
    private readonly sportService: SportService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number, finishedCallback: Function): void {
    request.pageIndex = pageNumber;
    this.sportService.list(request)
      .pipe(finalize(() => finishedCallback))
      .subscribe((res: SportPagedResultDto): void => {
        this.dataSource = res.items.map((item: SportDto) => {
          const listSportType = this.constant.SPORT_SIMULATION_TYPES;
          const simulation_type = listSportType.find(
            (sp): boolean => sp.id === item.simulation_type
          );

          return {
            ...item,
            simulation_type_text: simulation_type?.name,
          } as SportDto;
        });
        this.totalItems = res.totalCount;
      });
  }

  protected getPagedRequestDto(): PagedAndSortedRequestDto {
    return this.request;
  }

  public add(): void {
    this.openDialog({ id: '' });
  }

  public edit(id: number): void {
    this.openDialog({ id });
  }

  private openDialog(param: { id?: number | string }): void {
    const dialogRef: MatDialogRef<SportDetailComponent> = this.dialog.open(SportDetailComponent, {
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { ...param },
    });

    dialogRef.afterClosed().subscribe((res): void => {
      if (res) {
        let message: string = 'Item created successfully';
        if (param?.id) {
          message = 'Item updated successfully';
        }
        this.snackBar.open(message, 'OK', this.constant.TOAST_CONFIG.SUCCESS);
        this.refresh();
      }
    });
  }

  public deleteSport(id: number): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((item) => {
          if (item) {
            return this.sportService.deleteSport(id);
          }
          return of('');
        }),
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        })
      )
      .subscribe((): void => {
        this.snackBar.open('Item deleted successfully.', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
        this.refresh();
      });
  }

  public manageAthleteCategory() {
    const dialogRef: MatDialogRef<AthleteCategoryComponent> = this.dialog.open(AthleteCategoryComponent, {
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe();
  }
}
