export const selected_test = {
  "id": 10,
  "athlete": {
    "id": 3,
    "first_name": "Vanessa",
    "last_name": "Williams",
    "gender": "male",
    "coaches": [],
    "photo": null
  },
  "coach": {
    "id": 2,
    "first_name": "Jasmine",
    "last_name": "Garza",
    "username": "jasminegarza"
  },
  "sport": {
    "id": 50,
    "name": "Cycling",
    "vo_weight": 1,
    "vl_weight": 1,
    "at_weight": 1,
    "simulation_type": 2,
    "min_value": 50,
    "max_value": 600,
    "primary_type": 1,
    "primary_min": "50",
    "primary_max": "600",
    "secondary_type": 0,
    "secondary_min": "",
    "secondary_max": "",
    "reference_system_settings": null,
    "primary_type_display": "watt",
    "secondary_type_display": "N/A",
    "training_level": [],
    "sport_settings": null
  },
  "test_type": "newtype",
  "test_date": "2024-09-26",
  "vo2max_min_display": "54.16",
  "vlamax_display": "1.26",
  "mass": 80,
  "height": 180,
  "comment": "",
  "tags": [],
  "sse_report": null,
  "excel_file": null,
  "created_at": "2024-09-26T12:50:37.532099Z",
  "display_id": 172735503740,
  "is_show_speed_relationship": false,
  "is_show_test_data": true,
  "is_show_economy": false,
  "data_df_power": [
    50.032563487878924,
    50.390510512375315,
    50.74853089044832,
    51.10662484432197,
    51.46479259860569,
    51.823034380289116,
    52.181350418737246,
    52.53974094568617,
    52.89820619523925,
    53.25674640386359,
    53.61536181038715,
    53.97405265599609,
    54.33281918423264,
    54.69166164099328,
    55.050580274527334,
    55.409575335436074,
    55.76864707667193,
    56.12779575353832,
    56.48702162368971,
    56.846324947131954,
    57.20570598622325,
    57.565165005675084,
    57.92470227255373,
    58.284318056282046,
    58.64401262864148,
    59.0037862637745,
    59.36363923818733,
    59.72357183075286,
    60.08358432271399,
    60.44367699768726,
    60.8038501416666,
    61.16410404302762,
    61.524438992531984,
    61.88485528333207,
    62.24535321097605,
    62.60593307341301,
    62.96659517099861,
    63.32733980650071,
    63.68816728510544,
    64.04907791442356,
    64.41007200449687,
    64.77114986780505,
    65.13231181927266,
    65.49355817627638,
    65.85488925865258,
    66.21630538870504,
    66.57780689121287,
    66.93939409343876,
    67.3010673251375,
    67.66282691856445,
    68.02467320848464,
    68.38660653218177,
    68.74862722946769,
    69.11073564269181,
    69.47293211675093,
    69.83521699909939,
    70.19759063975914,
    70.56005339133027,
    70.92260560900168,
    71.28524765056186,
    71.6479798764101,
    72.01080264956781,
    72.37371633568992,
    72.73672130307673,
    73.09981792268574,
    73.463006568144,
    73.8262876157602,
    74.18966144453746,
    74.55312843618609,
    74.91668897513645,
    75.28034344855232,
    75.64409224634414,
    76.00793576118281,
    76.37187438851335,
    76.73590852656903,
    77.10003857638559,
    77.46426494181566,
    77.82858802954344,
    78.19300824909959,
    78.55752601287621,
    78.92214173614225,
    79.28685583705891,
    79.65166873669531,
    80.01658085904451,
    80.38159263103952,
    80.74670448256973,
    81.11191684649725,
    81.47723015867392,
    81.84264485795808,
    82.20816138623181,
    82.5737801884183,
    82.93950171249946,
    83.3053264095337,
    83.67125473367402,
    84.03728714218614,
    84.40342409546709,
    84.76966605706383,
    85.1360134936921,
    85.50246687525558,
    85.86902667486524,
    86.23569336885883,
    86.60246743682077,
    86.96934936160201,
    87.33633962934039,
    87.70343872948095,
    88.0706471547968,
    88.43796540140985,
    88.80539396881198,
    89.17293335988654,
    89.54058408092979,
    89.90834664167278,
    90.2762215553035,
    90.64420933848903,
    91.01231051139834,
    91.38052559772474,
    91.74885512470911,
    92.11729962316325,
    92.48585962749316,
    92.85453567572291,
    93.22332830951873,
    93.59223807421313,
    93.96126551882949,
    94.33041119610677,
    94.69967566252456,
    95.06905947832831,
    95.43856320755498,
    95.80818741805868,
    96.17793268153675,
    96.54779957355622,
    96.91778867358022,
    97.28790056499493,
    97.65813583513669,
    98.02849507531941,
    98.39897888086223,
    98.76958785111745,
    99.14032258949881,
    99.51118370351,
    99.88217180477342,
    100.25328750905932,
    100.62453143631512,
    100.99590421069512,
    101.36740646059049,
    101.73903881865942,
    102.11080192185784,
    102.48269641147016,
    102.85472293314042,
    103.22688213690391,
    103.59917467721887,
    103.97160121299848,
    104.34416240764351,
    104.7168589290748,
    105.08969144976656,
    105.46266064677957,
    105.83576720179495,
    106.2090118011482,
    106.5823951358636,
    106.95591790168888,
    107.32958079913027,
    107.70338453348788,
    108.07732981489154,
    108.45141735833678,
    108.82564788372129,
    109.20002211588178,
    109.57454078463107,
    109.94920462479564,
    110.32401437625354,
    110.69897078397265,
    111.07407459804928,
    111.44932657374721,
    111.82472747153713,
    112.20027805713626,
    112.57597910154874,
    112.95183138110606,
    113.327835677508,
    113.70399277786407,
    114.08030347473526,
    114.45676856617625,
    114.83338885577777,
    115.21016515271002,
    115.5870982717657,
    115.96418903340407,
    116.34143826379524,
    116.71884679486482,
    117.09641546433907,
    117.47414511579063,
    117.85203659868439,
    118.23009076842412,
    118.60830848639944,
    118.98669062003314,
    119.36523804282913,
    119.74395163442088,
    120.12283228062017,
    120.50188087346642,
    120.88109831127656,
    121.26048549869527,
    121.64004334674584,
    122.01977277288142,
    122.39967470103686,
    122.7797500616811,
    123.15999979186992,
    123.54042483529936,
    123.92102614235962,
    124.30180467018953,
    124.68276138273144,
    125.06389725078697,
    125.44521325207268,
    125.82671037127722,
    126.20838960011824,
    126.59025193740018,
    126.97229838907275,
    127.35452996828974,
    127.73694769546877,
    128.11955259835113,
    128.50234571206286,
    128.8853280791758,
    129.26850074976974,
    129.65186478149502,
    130.0354212396357,
    130.41917119717337,
    130.8031157348518,
    131.18725594124206,
    131.57159291280826,
    131.95612775397422,
    132.34086157719048,
    132.72579550300225,
    133.11093066011793,
    133.49626818547833,
    133.88180922432673,
    134.2675549302793,
    134.65350646539682,
    135.03966500025663,
    135.42603171402544,
    135.812607794533,
    136.19939443834662,
    136.5863928508459,
    136.97360424629915,
    137.3610298479397,
    137.74867088804353,
    138.1365286080075,
    138.52460425842852,
    138.91289909918345,
    139.30141439950975,
    139.69015143808718,
    140.0791115031202,
    140.46829589242117,
    140.85770591349473,
    141.24734288362254,
    141.6372081299495,
    142.02730298957044,
    142.41762880961787,
    142.80818694735058,
    143.19897877024349,
    143.5900056560779,
    143.981268993033,
    144.3727701797787,
    144.7645106255686,
    145.15649175033465,
    145.5487149847826,
    145.9411817704884,
    146.33389355999577,
    146.72685181691432,
    147.12005801601973,
    147.51351364335383,
    147.9072201963266,
    148.3011791838188,
    148.69539212628607,
    149.0898605558636,
    149.48458601647263,
    149.87957006392745,
    150.27481426604393,
    150.6703202027492,
    151.06608946619238,
    151.46212366085646,
    151.85842440367176,
    152.25499332413006,
    152.65183206440062,
    153.04894227944678,
    153.44632563714438,
    153.84398381840128,
    154.24191851727812,
    154.6401314411104,
    155.0386243106321,
    155.43739886010042,
    155.8364568374219,
    156.23580000428043,
    156.63543013626568,
    157.035349023004,
    157.43555846829014,
    157.8360602902206,
    158.23685632132836,
    158.6379484087195,
    159.03933841421073,
    159.44102821446896,
    159.84301970115223,
    160.24531478105214,
    160.64791537623807,
    161.050823424203,
    161.45404087801055,
    161.85756970644442,
    162.26141189415898,
    162.66556944183154,
    163.0700443663166,
    163.47483870080197,
    163.879954494966,
    164.28539381513744,
    164.69115874445654,
    165.0972513830381,
    165.50367384813669,
    165.91042827431318,
    166.31751681360387,
    166.72494163569095,
    167.13270492807536,
    167.5408088962514,
    167.94925576388337,
    168.35804777298432,
    168.767187184097,
    169.17667627647646,
    169.58651734827538,
    169.99671271673097,
    170.40726471835464,
    170.8181757091232,
    171.22944806467294,
    171.6410841804956,
    172.05308647213675,
    172.4654573753964,
    172.8781993465323,
    173.29131486246513,
    173.70480642098653,
    174.11867654096946,
    174.53292776258093,
    174.94756264749748,
    175.36258377912293,
    175.7779937628092,
    176.19379522607903,
    176.60999081885205,
    177.02658321367338,
    177.44357510594466,
    177.86096921415822,
    178.27876828013385,
    178.69697506925849,
    179.11559237072888,
    179.5346229977971,
    179.9540697880189,
    180.37393560350546,
    180.79422333117773,
    181.21493588302434,
    181.63607619636207,
    182.05764723410016,
    182.47965198500745,
    182.90209346398268,
    183.3249747123287,
    183.74829879802925,
    184.17206881602985,
    184.5962878885219,
    185.02095916523,
    185.44608582370333,
    185.87167106961022,
    186.29771813703678,
    186.72423028878907,
    187.15121081669872,
    187.57866304193303,
    188.00659031530824,
    188.43499601760738,
    188.86388355990164,
    189.29325638387598,
    189.72311796215897,
    190.1534717986564,
    190.58432142888955,
    191.01567042033767,
    191.44752237278433,
    191.87988091866896,
    192.31274972344224,
    192.74613248592635,
    193.1800329386796,
    193.6144548483662,
    194.04940201612996,
    194.48487827797376,
    194.92088750514324,
    195.35743360451565,
    195.79452051899355,
    196.2321522279043,
    196.67033274740368,
    197.10906613088562,
    197.5483564693969,
    197.9882078920574,
    198.4286245664856,
    198.86961069923004,
    199.3111705362061,
    199.7533083631387,
    200.19602850601095,
    200.63933533151837,
    201.0832332475295,
    201.52772670355233,
    201.97282019120718,
    202.4185182447057,
    202.86482544133622,
    203.3117464019557,
    203.75928579148837,
    204.20744831943065,
    204.6562387403634,
    205.1056618544706,
    205.5557225080653,
    206.00642559412256,
    206.45777605281967,
    206.9097788720837,
    207.3624390881463,
    207.8157617861063,
    208.26975210050026,
    208.7244152158798,
    209.17975636739808,
    209.63578084140346,
    210.09249397604165,
    210.5499011618664,
    211.008007842458,
    211.46681951505084,
    211.9263417311696,
    212.38658009727413,
    212.84754027541348,
    213.30922798388863,
    213.77164899792535,
    214.23480915035535,
    214.69871433230801,
    215.16337049391115,
    215.62878364500207,
    216.09495985584834,
    216.56190525787903,
    217.02962604442618,
    217.49812847147678,
    217.96741885843542,
    218.43750358889795,
    218.90838911143626,
    219.380081940394,
    219.85258865669385,
    220.32591590865638,
    220.80007041283116,
    221.2750589548389,
    221.7508883902267,
    222.22756564533506,
    222.70509771817814,
    223.18349167933607,
    223.66275467286084,
    224.14289391719504,
    224.6239167061047,
    225.10583040962484,
    225.58864247501987,
    226.07236042775753,
    226.55699187249746,
    227.0425444940945,
    227.52902605861667,
    228.01644441437813,
    228.5048074929882,
    228.99412331041532,
    229.48439996806704,
    229.97564565388683,
    230.46786864346643,
    230.96107730117564,
    231.45528008130904,
    231.95048552924956,
    232.4467022826504,
    232.9439390726346,
    233.4422047250125,
    233.94150816151833,
    234.44185840106545,
    234.94326456102084,
    235.44573585849895,
    235.9492816116759,
    236.45391124112365,
    236.9596342711648,
    237.46646033124836,
    237.97439915734694,
    238.48346059337572,
    238.9936545926334,
    239.5049912192658,
    240.01748064975217,
    240.53113317441523,
    241.04595919895436,
    241.56196924600374,
    242.07917395671421,
    242.59758409236088,
    243.11721053597606,
    243.6380642940079,
    244.16015649800573,
    244.68349840633266,
    245.20810140590453,
    245.73397701395814,
    246.26113687984682,
    246.78959278686554,
    247.31935665410532,
    247.85044053833778,
    248.38285663593015,
    248.9166172847914,
    249.45173496635067,
    249.98822230756676,
    250.52609208297233,
    251.06535721674993,
    251.60603078484365,
    252.14812601710418,
    252.69165629947085,
    253.23663517618866,
    253.78307635206286,
    254.3309936947504,
    254.88040123709055,
    255.43131317947365,
    255.98374389224992,
    256.53770791817914,
    257.0932199749207,
    257.65029495756687,
    258.208947941218,
    258.76919418360166,
    259.3310491277368,
    259.89452840464287,
    260.45964783609526,
    261.02642343742826,
    261.5948714203858,
    262.1650081960217,
    262.7368503776494,
    263.3104147838432,
    263.88571844149163,
    264.46277858890346,
    265.04161267896785,
    265.62223838237037,
    266.2046735908644,
    266.78893642060103,
    267.37504521551654,
    267.9630185507799,
    268.55287523630216,
    269.14463432030675,
    269.73831509296394,
    270.33393709008976,
    270.9315200969118,
    271.5310841519009,
    272.1326495506735,
    272.7362368499621,
    273.34186687165965,
    273.9495607069353,
    274.5593397204253,
    275.17122555450084,
    275.7852401336122,
    276.4014056687138,
    277.019744661769,
    277.64027991033873,
    278.26303451225385,
    278.88803187037377,
    279.51529569743377,
    280.1448500209814,
    280.776719188405,
    281.4109278720569,
    282.0475010744706,
    282.68646413367713,
    283.327842728621,
    283.9716628846779,
    284.617950979277,
    285.26673374762925,
    285.91803828856507,
    286.5718920704827,
    287.22832293741016,
    287.8873591151835,
    288.54902921774317,
    289.2133622535522,
    289.88038763213723,
    290.55013517075656,
    291.22263510119825,
    291.8979180767088,
    292.5760151790582,
    293.2569579257424,
    293.9407782773278,
    294.6275086449386,
    295.3171818978932,
    296.00983137149035,
    296.7054908749507,
    297.4041946995148,
    298.1059776267032,
    298.81087493674147,
    299.51892241715393,
    300.23015637152935,
    300.9446136284644,
    301.6623315506862,
    302.38334804436045,
    303.1077015685886,
    303.8354311450981,
    304.5665763681299,
    305.30117741452955,
    306.0392750540443,
    306.780910659833,
    307.52612621919184,
    308.2749643445035,
    309.0274682844121,
    309.78368193523204,
    310.54364985259343,
    311.3074172633329,
    312.0750300776327,
    312.84653490141596,
    313.6219790490027,
    314.4014105560349,
    315.1848781926752,
    315.97243147708616,
    316.7641206891978,
    317.5599968847697,
    318.36011190975495,
    319.16451841497275,
    319.97326987109847,
    320.78642058397816,
    321.60402571027464,
    322.4261412734556,
    323.2528241801294,
    324.0841322367399,
    324.92012416662647,
    325.7608596274612,
    326.60639922907006,
    327.45680455164904,
    328.31213816438554,
    329.17246364449414,
    330.03784559667724,
    330.9083496730226,
    331.78404259334746,
    332.6649921660014,
    333.5512673091391,
    334.4429380724759,
    335.340075659537,
    336.24275245041486,
    337.15104202504693,
    338.0650191870265,
    338.9847599879621,
    339.9103417523981,
    340.8418431033122,
    341.7793439882051,
    342.7229257057963,
    343.6726709333437,
    344.62866375460277,
    345.5909896884413,
    346.5597357181296,
    347.5349903213204,
    348.5168435007407,
    349.5053868156114,
    350.50071341381647,
    351.5029180648404,
    352.51209719349515,
    353.5283489144576,
    354.55177306764074,
    355.5824712544184,
    356.6205468747302,
    357.66610516508774,
    358.71925323750895,
    359.78010011940484,
    360.84875679444417,
    361.9253362444258,
    363.0099534921823,
    364.10272564554805,
    365.20377194241746,
    366.3132137969263,
    367.43117484678623,
    368.5577810018053,
    369.6931604936269,
    370.8374439267234,
    371.99076433067654,
    373.153257213786,
    374.3250606180367,
    375.50631517547197,
    376.69716416600494,
    377.89775357671357,
    379.108232162661,
    380.3287515092845,
    381.5594660963977,
    382.8005333638547,
    384.0521137789209,
    385.31437090540305,
    386.58747147458774,
    387.87158545804124,
    389.1668861423257,
    390.47355020568733,
    391.7917577967735,
    393.12169261543926,
    394.4635419957056,
    395.81749699092984,
    397.1837524612576,
    398.5625071634199,
    399.95396384294827,
    401.35832932887695,
    402.77581463100813,
    404.2066350398166,
    405.65101022907083,
    407.1091643612543,
    408.581326195869,
    410.067729200709,
    411.5686116661929,
    413.08421682284717,
    414.61479296203527,
    416.1605935600323,
    417.72187740554335,
    419.29890873077386,
    420.89195734615527,
    422.50129877884063,
    424.12721441508336,
    425.7699916466172,
    427.42992402115897,
    429.1073113971615,
    430.8024601029437,
    432.51568310033457,
    434.24730015296547,
    435.99763799935647,
    437.7670305309398,
    439.5558189751739,
    441.36435208390213,
    443.1929863271174,
    445.04208609229755,
    446.9120238894799,
    448.80318056225485,
    450.71594550485423,
    452.650716885521,
    454.60790187635473,
    456.5879168898254,
    458.5911878221614,
    460.6181503038168,
    462.6692499572354,
    464.74494266212855,
    466.84569482849344,
    468.9719836776066,
    471.12429753122706,
    473.30313610925623,
    475.5090108361053,
    477.74244515602504,
    480.0039748576668,
    482.29414840813735,
    484.6135272968297,
    486.96268638930843,
    489.34221429154144,
    491.75271372476857,
    494.19480191131146,
    496.6691109716278,
    499.176288332924,
    501.71699714964024,
    504.29191673613275,
    506.9017430118771,
    509.54718895952317,
    512.228985096137,
    514.9478799579628,
    517.704640599048,
    520.5000531040661,
    523.3349231156849,
    526.2100763768134,
    529.1263592880674,
    532.0846394807926,
    535.085806405971,
    538.130771939337,
    541.2204710030202,
    544.35586220402,
    547.5379284898054,
    550.7676778213204,
    554.046143863653,
    557.3743866946104,
    560.7534935314121,
    564.1845794756897,
    567.6687882769429,
    571.207293114574,
    574.8012973985623,
    578.452035588811,
    582.1607740331268,
    585.9288118237411,
    589.757481672199,
    593.6481508023786,
    597.6022218612941,
    601.6211338472534,
    605.7063630548159,
    609.8594240358809,
    614.0818705760882,
    618.375296685567,
    622.7413376028862,
    627.1816708108782,
    631.6980170627876,
    636.2921414169602,
    640.9658542780403,
    645.7210124423324,
    650.5595201446843,
    655.4833301038874,
    660.4944445632018,
    665.5949163221799,
    670.7868497554985,
    676.0724018139736,
    681.453783002369,
    686.9332583279672,
    692.5131482131771,
    698.1958293646879,
    703.9837355908289,
    709.8793585578765,
    715.8852484750191,
    722.0040146965918,
    728.2383262289361,
    734.5909121279262,
    741.0645617717007,
    747.6621249915311,
    754.3865120419862,
    761.240693389604,
    768.2276992971542,
    775.3506191782595,
    782.6126006945846,
    790.0168485650299,
    797.56662305333,
    805.2652380971323,
    813.1160590380209,
    821.1224999079991,
    829.2880202236477,
    837.6161212344836,
    846.1103415669771,
    854.7742522001037,
    863.6114507023289,
    872.6255546533772,
    881.8201941670653,
    891.1990034238028,
    900.7656111130866,
    910.5236296773437,
    920.4766432388117,
    930.62819408073,
    940.9817675428981,
    951.5407751796145,
    962.3085360150918,
    973.2882557176065,
    984.483003498846,
    995.8956865291736,
    1007.5290216426871,
    1019.385504088142,
    1031.4673730628688,
    1043.776573746772,
    1056.3147155323497,
    1069.0830261243204,
    1082.0823011589341,
    1095.3128489682283,
    1108.7744300883874,
    1122.4661910837594,
    1136.3865922288599,
    1150.5333285595123,
    1164.9032437707365,
    1179.4922364024294,
    1194.2951577133347,
    1209.3057005978833,
    1224.516278847206,
    1239.9178959919439,
    1255.5000028864436,
    1271.250343095332,
    1287.1547850158886,
    1303.197139500789,
    1319.3589615180429,
    1335.6193340728557,
    1351.9546321826328,
    1368.338264086944,
    1384.740386008056,
    1401.1275855324795,
    1417.4625268727114,
    1433.7035485981783,
    1449.8042004267822,
    1465.712699573571,
    1481.3712776561865,
    1496.715373968051,
    1511.6726058599484,
    1526.161403976302,
    1540.0891228243665,
    1553.3492900010851,
    1565.8173558990786,
    1577.3436265950427,
    1587.7403243216331,
    1596.7543691205658,
    1603.9952169373794,
    1608.5993612170842
  ],
  "data_df_vo2tot": [
    7.817588044981083,
    7.873517267558643,
    7.92945795163255,
    7.985410131925307,
    8.041373843532138,
    8.097349121920175,
    8.153336002927695,
    8.209334522763465,
    8.265344718006132,
    8.321366625603686,
    8.377400282872992,
    8.43344572749939,
    8.48950299753635,
    8.5455721314052,
    8.601653167894897,
    8.657746146161887,
    8.71385110572999,
    8.769968086490362,
    8.826097128701516,
    8.882238272989367,
    8.938391560347382,
    8.994557032136731,
    9.050734730086521,
    9.106924696294069,
    9.16312697322523,
    9.219341603714765,
    9.27556863096677,
    9.331808098555134,
    9.38806005042406,
    9.444324530888634,
    9.500601584635406,
    9.556891256723066,
    9.613193592583123,
    9.669508638020636,
    9.725836439215007,
    9.782177042720784,
    9.838530495468532,
    9.894896844765736,
    9.951276138297725,
    10.007668424128683,
    10.064073750702637,
    10.120492166844539,
    10.176923721761352,
    10.233368465043183,
    10.289826446664465,
    10.346297716985163,
    10.40278232675201,
    10.459280327099808,
    10.515791769552733,
    10.572316706025694,
    10.628855188825725,
    10.685407270653403,
    10.741973004604327,
    10.798552444170594,
    10.855145643242333,
    10.91175265610928,
    10.968373537462366,
    11.025008342395356,
    11.081657126406512,
    11.138319945400289,
    11.194996855689078,
    11.25168791399497,
    11.308393177451551,
    11.365112703605739,
    11.421846550419648,
    11.478594776272498,
    11.53535743996253,
    11.59213460070898,
    11.648926318154075,
    11.705732652365072,
    11.762553663836298,
    11.819389413491272,
    11.876239962684814,
    11.93310537320521,
    11.989985707276412,
    12.046881027560248,
    12.103791397158696,
    12.160716879616164,
    12.21765753892181,
    12.274613439511908,
    12.331584646272226,
    12.388571224540453,
    12.445573240108642,
    12.502590759225706,
    12.559623848599927,
    12.61667257540152,
    12.673737007265196,
    12.7308172122928,
    12.78791325905595,
    12.845025216598719,
    12.90215315444036,
    12.95929714257804,
    13.01645725148964,
    13.073633552136565,
    13.130826115966585,
    13.188035014916734,
    13.245260321416223,
    13.30250210838939,
    13.359760449258685,
    13.417035417947693,
    13.474327088884193,
    13.531635537003247,
    13.588960837750315,
    13.646303067084434,
    13.7036623014814,
    13.761038617937,
    13.818432093970289,
    13.875842807626874,
    13.933270837482272,
    13.990716262645279,
    14.048179162761372,
    14.105659618016169,
    14.163157709138913,
    14.22067351740599,
    14.278207124644489,
    14.3357586132358,
    14.393328066119258,
    14.450915566795805,
    14.508521199331705,
    14.566145048362301,
    14.623787199095801,
    14.681447737317107,
    14.739126749391682,
    14.79682432226946,
    14.854540543488799,
    14.912275501180467,
    14.970029284071668,
    15.027801981490118,
    15.08559368336816,
    15.143404480246907,
    15.201234463280457,
    15.259083724240108,
    15.316952355518659,
    15.374840450134723,
    15.4327481017371,
    15.49067540460919,
    15.548622453673438,
    15.606589344495847,
    15.664576173290518,
    15.722583036924236,
    15.780610032921112,
    15.838657259467263,
    15.896724815415535,
    15.954812800290288,
    16.01292131429221,
    16.07105045830319,
    16.129200333891237,
    16.187371043315448,
    16.245562689531013,
    16.3037753761943,
    16.36200920766794,
    16.420264289026026,
    16.478540726059308,
    16.53683862528046,
    16.595158093929406,
    16.65349923997869,
    16.711862172138886,
    16.770246999864106,
    16.82865383335748,
    16.887082783576805,
    16.945533962240123,
    17.00400748183145,
    17.062503455606528,
    17.121021997598604,
    17.179563222624317,
    17.238127246289615,
    17.29671418499573,
    17.3553241559452,
    17.413957277148004,
    17.472613667427677,
    17.53129344642754,
    17.589996734616992,
    17.64872365329782,
    17.707474324610626,
    17.76624887154126,
    17.825047417927387,
    17.883870088465038,
    17.942717008715277,
    18.00158830511094,
    18.06048410496339,
    18.119404536469386,
    18.178349728718008,
    18.237319811697628,
    18.296314916302983,
    18.355335174342287,
    18.414380718544436,
    18.47345168256627,
    18.532548200999912,
    18.59167040938018,
    18.65081844419205,
    18.709992442878264,
    18.769192543846902,
    18.828418886479128,
    18.887671611136962,
    18.946950859171135,
    19.00625677292904,
    19.065589495762723,
    19.12494917203701,
    19.184335947137672,
    19.243749967479676,
    19.303191380515525,
    19.36266033474369,
    19.422156979717112,
    19.48168146605179,
    19.54123394543546,
    19.600814570636356,
    19.660423495512067,
    19.720060875018476,
    19.77972686521878,
    19.839421623292615,
    19.899145307545272,
    19.958898077416997,
    20.018680093492367,
    20.07849151750982,
    20.138332512371218,
    20.198203242151525,
    20.2581038721086,
    20.318034568693076,
    20.377995499558338,
    20.43798683357059,
    20.49800874081907,
    20.55806139262629,
    20.61814496155847,
    20.678259621436013,
    20.7384055473441,
    20.798582915643426,
    20.85879190398099,
    20.919032691301048,
    20.97930545785614,
    21.039610385218253,
    21.099947656290098,
    21.160317455316473,
    21.220719967895782,
    21.281155380991656,
    21.34162388294467,
    21.40212566348424,
    21.46266091374058,
    21.523229826256802,
    21.58383259500117,
    21.644469415379458,
    21.705140484247416,
    21.7658459999234,
    21.82658616220112,
    21.88736117236253,
    21.94817123319081,
    22.00901654898355,
    22.06989732556602,
    22.13081377030461,
    22.191766092120382,
    22.25275450150279,
    22.313779210523528,
    22.374840432850544,
    22.43593838376217,
    22.49707328016141,
    22.558245340590425,
    22.61945478524509,
    22.680701835989787,
    22.74198671637228,
    22.803309651638816,
    22.86467086874934,
    22.926070596392865,
    22.98750906500308,
    23.048986506774035,
    23.110503155676028,
    23.172059247471687,
    23.233655019732197,
    23.29529071185369,
    23.356966565073847,
    23.418682822488663,
    23.480439729069364,
    23.542237531679564,
    23.60407647909256,
    23.665956822008823,
    23.727878813073712,
    23.78984270689532,
    23.851848760062598,
    23.913897231163556,
    23.97598838080381,
    24.0381224716252,
    24.100299768324707,
    24.1625205376735,
    24.224785048536265,
    24.287093571890686,
    24.349446380847173,
    24.411843750668815,
    24.474285958791512,
    24.536773284844376,
    24.599306010670333,
    24.661884420346965,
    24.724508800207555,
    24.78717943886242,
    24.849896627220428,
    24.912660658510777,
    24.975471828305036,
    25.038330434539397,
    25.101236777537203,
    25.164191160031717,
    25.22719388718915,
    25.290245266631942,
    25.35334560846234,
    25.416495225286177,
    25.479694432236972,
    25.542943547000306,
    25.606242889838438,
    25.669592783615226,
    25.732993553821334,
    25.796445528599705,
    25.859949038771358,
    25.923504417861434,
    25.987112002125603,
    26.05077213057671,
    26.114485145011773,
    26.178251390039282,
    26.242071213106776,
    26.305944964528802,
    26.369872997515152,
    26.43385566819945,
    26.497893335668024,
    26.561986361989213,
    26.62613511224291,
    26.6903399545505,
    26.754601260105147,
    26.818919403202436,
    26.88329476127137,
    26.94772771490569,
    27.012218647895672,
    27.076767947260173,
    27.141376003279145,
    27.206043209526477,
    27.27076996290327,
    27.33555666367148,
    27.40040371548796,
    27.46531152543894,
    27.530280504074845,
    27.59531106544563,
    27.660403627136464,
    27.725558610303853,
    27.79077643971222,
    27.856057543770913,
    27.92140235457164,
    27.986811307926388,
    28.052284843405793,
    28.11782340437795,
    28.183427438047726,
    28.249097395496523,
    28.31483373172255,
    28.380636905681573,
    28.44650738032815,
    28.512445622657413,
    28.578452103747296,
    28.644527298801357,
    28.710671687192068,
    28.77688575250467,
    28.84316998258155,
    28.90952486956719,
    28.975950909953642,
    29.042448604626593,
    29.109018458911997,
    29.17566098262329,
    29.24237669010918,
    29.309166100302036,
    29.376029736766913,
    29.44296812775115,
    29.509981806234627,
    29.577071309980624,
    29.64423718158734,
    29.71147996854006,
    29.778800223263996,
    29.84619850317776,
    29.91367537074755,
    29.981231393542025,
    30.048867144287854,
    30.11658320092599,
    30.18438014666869,
    30.252258570057215,
    30.320219065020307,
    30.388262230933403,
    30.456388672678635,
    30.524599000705567,
    30.592893831092745,
    30.66127378561005,
    30.729739491781825,
    30.798291582950878,
    30.866930698343268,
    30.93565748313397,
    31.004472588513377,
    31.073376671754694,
    31.142370396282203,
    31.211454431740425,
    31.28062945406421,
    31.349896145549746,
    31.419255194926482,
    31.48870729743005,
    31.558253154876123,
    31.627893475735267,
    31.697628975208783,
    31.767460375305582,
    31.83738840492006,
    31.90741379991104,
    31.97753730318178,
    32.04775966476103,
    32.118081641885205,
    32.18850399908165,
    32.25902750825308,
    32.329652948763076,
    32.400381107522854,
    32.47121277907911,
    32.54214876570317,
    32.61318987748122,
    32.68433693240595,
    32.75559075646929,
    32.82695218375651,
    32.89842205654163,
    32.97000122538406,
    33.04169054922669,
    33.11349089549525,
    33.185403140199085,
    33.25742816803336,
    33.3295668724826,
    33.40182015592584,
    33.47418892974302,
    33.546674114423126,
    33.619276639673615,
    33.69199744453157,
    33.764837477476306,
    33.8377976965436,
    33.91087906944159,
    33.984082573668246,
    34.05740919663053,
    34.13085993576531,
    34.20443579866192,
    34.27813780318657,
    34.35196697760841,
    34.425924360727564,
    34.50001100200487,
    34.57422796169358,
    34.64857631097292,
    34.7230571320836,
    34.797671518465336,
    34.87242057489626,
    34.9473054176345,
    35.022327174561724,
    35.09748698532886,
    35.17278600150388,
    35.248225386721856,
    35.323806316837114,
    35.39952998007773,
    35.475397577202266,
    35.55141032165885,
    35.62756943974658,
    35.7038761707794,
    35.78033176725239,
    35.85693749501048,
    35.93369463341982,
    36.010604475541626,
    36.087668328308695,
    36.16488751270454,
    36.24226336394524,
    36.319797231664126,
    36.39749048009916,
    36.4753444882832,
    36.55336065023724,
    36.63154037516647,
    36.70988508765951,
    36.78839622789046,
    36.86707525182436,
    36.94592363142557,
    37.0249428548695,
    37.104134426757554,
    37.18349986833546,
    37.26304071771496,
    37.34275853009897,
    37.42265487801028,
    37.50273135152378,
    37.582989558502376,
    37.66343112483662,
    37.74405769468809,
    37.824870930736594,
    37.90587251443139,
    37.987064146246254,
    38.06844754593873,
    38.1500244528134,
    38.231796625989475,
    38.31376584467258,
    38.39593390843096,
    38.47830263747606,
    38.56087387294774,
    38.64364947720396,
    38.72663133411528,
    38.809821349364086,
    38.89322145074866,
    38.97683358849229,
    39.06065973555731,
    39.14470188796442,
    39.22896206511718,
    39.31344231013182,
    39.39814469017253,
    39.48307129679232,
    39.56822424627948,
    39.65360568000982,
    39.73921776480475,
    39.8250626932954,
    39.911142684292756,
    39.99745998316405,
    40.084016862215485,
    40.17081562108136,
    40.25785858711983,
    40.34514811581531,
    40.43268659118776,
    40.52047642620887,
    40.60852006322545,
    40.69681997438988,
    40.78537866209817,
    40.87419865943529,
    40.96328253062839,
    41.05263287150771,
    41.1422523099755,
    41.23214350648307,
    41.32230915451616,
    41.412751981088725,
    41.50347474724536,
    41.594480248572566,
    41.68577131571892,
    41.77735081492445,
    41.86922164855936,
    41.961386755672216,
    42.05384911254793,
    42.14661173327561,
    42.23967767032653,
    42.33305001514247,
    42.42673189873452,
    42.520726492292724,
    42.61503700780658,
    42.70966669869682,
    42.80461886045864,
    42.89989683131646,
    42.995503992890754,
    43.09144377087691,
    43.18771963573653,
    43.2843351034014,
    43.381293735990425,
    43.478599142539665,
    43.576254979745904,
    43.67426495272402,
    43.77263281577834,
    43.87136237318828,
    43.970457480008896,
    44.06992204288603,
    44.16976002088705,
    44.26997542634703,
    44.370572325730926,
    44.471554840512034,
    44.57292714806707,
    44.674693482588296,
    44.77685813601292,
    44.87942545897034,
    44.98239986174742,
    45.085785815272374,
    45.189587852117526,
    45.29381056752144,
    45.398458620430716,
    45.503536734562225,
    45.609049699485745,
    45.715002371727834,
    45.821399675897254,
    45.92824660583247,
    46.035548225771656,
    46.14330967154581,
    46.25153615179537,
    46.36023294921105,
    46.46940542179919,
    46.57905900417237,
    46.68919920886586,
    46.7998316276803,
    46.91096193305147,
    47.022595879447564,
    47.13473930479472,
    47.24739813193132,
    47.36057837009197,
    47.474286116421574,
    47.588527557520294,
    47.70330897102024,
    47.81863672719443,
    47.934517290598905,
    48.05095722174873,
    48.16796317882867,
    48.285541919439396,
    48.40370030238,
    48.52244528946772,
    48.641783947395766,
    48.76172344963011,
    48.88227107834624,
    49.00343422640667,
    49.12522039938045,
    49.2476372176055,
    49.37069241829471,
    49.49439385768715,
    49.61874951324527,
    49.74376748589921,
    49.86945600233949,
    49.995823417359134,
    50.12287821624658,
    50.25062901723041,
    50.37908457397744,
    50.50825377814522,
    50.638145661990606,
    50.768769401035385,
    50.90013431679081,
    51.0322498795422,
    51.16512571119516,
    51.29877158818525,
    51.433197444452205,
    51.56841337448081,
    51.70442963640978,
    51.84125665521054,
    51.97890502593772,
    52.117385517052995,
    52.25670907382436,
    52.396886821802646,
    52.537930070377314,
    52.67985031641358,
    52.822659247972894,
    52.96636874811908,
    53.110990898812204,
    53.25653798489253,
    53.40302249815704,
    53.55045714153067,
    53.698854833334956,
    53.848228711656674,
    53.99859213881896,
    54.14995870595775,
    54.30234223770631,
    54.455756796990734,
    54.61021668993928,
    54.76573647090883,
    54.922330947631316,
    55.080015186483614,
    55.238804517884006,
    55.39871454181887,
    55.559761133502874,
    55.7219604491766,
    55.885328932044956,
    56.04988331836077,
    56.215640643657,
    56.38261824913191,
    56.55083378819153,
    56.72030523315349,
    56.89105088211688,
    57.06308936600272,
    57.23643965576973,
    57.41112106981035,
    57.58715328153208,
    57.76455632712921,
    57.94335061355052,
    58.12355692666821,
    58.305196439654054,
    58.488290721568234,
    58.6728617461675,
    58.85893190093827,
    59.0465239963615,
    59.23566127541579,
    59.4263674233257,
    59.618666577562145,
    59.81258333810229,
    60.008142777956394,
    60.20537045396922,
    60.40429241790433,
    60.60493522781894,
    60.8073259597384,
    61.011492219638654,
    61.21746215574585,
    61.42526447116238,
    61.634928436829,
    61.84648390483279,
    62.0599613220715,
    62.27539174428436,
    62.492806850460674,
    62.71223895763702,
    62.93372103609502,
    63.15728672497134,
    63.38297034829232,
    63.610806931445985,
    63.840832218104524,
    64.07308268761078,
    64.30759557284264,
    64.54440887856987,
    64.783561400318,
    65.02509274375504,
    65.26904334461615,
    65.51545448918341,
    65.76436833533675,
    66.01582793419385,
    66.26987725235678,
    66.52656119478394,
    66.78592562830609,
    67.04801740580648,
    67.31288439108496,
    67.58057548442727,
    67.85114064890085,
    68.12463093739944,
    68.40109852045934,
    68.68059671487092,
    68.9631800131097,
    69.2489041136121,
    69.53782595192149,
    69.83000373273123,
    70.12549696285232,
    70.42436648513348,
    70.72667451336265,
    71.03248466818043,
    71.34186201403523,
    71.65487309721271,
    71.97158598497137,
    72.29207030581804,
    72.61639729095758,
    72.9446398169521,
    73.27687244962604,
    73.61317148925423,
    73.95361501707129,
    74.29828294314146,
    74.64725705562891,
    75.00062107151044,
    75.35846068877146,
    75.72086364012964,
    76.08791974832944,
    76.45972098305336,
    76.83636151949509,
    77.21793779864241,
    77.60454858931685,
    77.99629505201938,
    78.39328080463129,
    78.79561199002075,
    79.2033973456058,
    79.6167482749255,
    80.0357789212714,
    80.46060624343167,
    80.89135009360125,
    81.32813329751032,
    81.77108173682578,
    82.2203244338771,
    82.67599363876053,
    83.13822491887385,
    83.60715725093297,
    84.08293311552141,
    84.56569859422191,
    85.05560346937813,
    85.55280132653209,
    86.05744965958131,
    86.56970997869578,
    87.08974792103288,
    87.61773336428314,
    88.15384054307651,
    88.69824816827233,
    89.25113954915219,
    89.81270271852536,
    90.38313056075171,
    90.96262094267607,
    91.55137684745954,
    92.14960651128109,
    92.75752356287165,
    93.37534716582721,
    94.00330216363334,
    94.64161922731498,
    95.29053500560639,
    95.9502922775138,
    96.62114010711986,
    97.30333400045097,
    97.99713606419972,
    98.70281516606056,
    99.42064709640003,
    100.15091473094381,
    100.89390819411445,
    101.64992502260691,
    102.41927032873241,
    103.20225696300028,
    103.99920567534062,
    104.81044527429663,
    105.63631278343337,
    106.47715359412017,
    107.33332161374489,
    108.20517940830894,
    109.0930983382325,
    109.997458686067,
    110.9186497746682,
    111.85707007422175,
    112.81312729634246,
    113.78723847327126,
    114.77983001998848,
    115.79133777682824,
    116.82220702992672,
    117.87289250656033,
    118.94385834212562,
    120.03557801518033,
    121.14853424660305,
    122.28321885852884,
    123.44013258828593,
    124.6197848520828,
    125.8226934526769,
    127.04938422469075,
    128.30039061062487,
    129.57625315994494,
    130.87751894288806,
    132.20474086984018,
    133.5584769062662,
    134.9392891722389,
    136.34774291459019,
    137.78440533860396,
    139.24984428496919,
    140.74462673641978,
    142.26931713708495,
    143.82447550606432,
    145.41065532511408,
    147.0284011785778,
    148.67824612181477,
    150.3607087523581,
    152.076289955876,
    153.82546929669468,
    155.6087010201834,
    157.42640963166986,
    159.2789850137722,
    161.16677704107326,
    163.09008964793315,
    165.04917430192964,
    167.04422283192508,
    169.07535955608347,
    171.14263265128565,
    173.2460047013105,
    175.3853423568374,
    177.56040503575937,
    179.77083258742377,
    182.01613183917758,
    184.2956619378796,
    186.60861839270854,
    188.95401571841927,
    191.33066856987594,
    193.73717124874122,
    196.1718754510068,
    198.6328661086456,
    201.1179351587326,
    203.6245530469983,
    206.1498377371942,
    208.69052094888372,
    211.2429112785364,
    213.802853763585,
    216.36568531375875,
    218.92618523944992,
    221.47851982386115,
    224.01617946846534,
    226.5319063166847,
    229.01760930837042,
    231.46426213377913,
    233.86177718250798,
    236.19884466561692,
    238.46271937129717,
    240.63892544130726,
    242.71082656266955,
    244.65896185923103,
    246.4599416554754,
    248.08442567525515,
    249.4928701750884,
    250.62425264646552,
    251.34365019016943
  ],
  "data_df_vms": [
    -0.20909890019042782,
    -0.2041409942278039,
    -0.19915515820103402,
    -0.1941416030208301,
    -0.18910056415506432,
    -0.18403230234717144,
    -0.17893710430597487,
    -0.17381528336198993,
    -0.16866718008509052,
    -0.16349316285825022,
    -0.1582936284019455,
    -0.15306900224372144,
    -0.14781973912735735,
    -0.14254632335608008,
    -0.13724926906430568,
    -0.13192912041249472,
    -0.1265864516998686,
    -0.12122186738994833,
    -0.11583600204416544,
    -0.11042952015914786,
    -0.10500311590368894,
    -0.0995575127519166,
    -0.09409346300970578,
    -0.0886117472320076,
    -0.08311317352944059,
    -0.07759857676322268,
    -0.07206881762829465,
    -0.06652478162532471,
    -0.06096737792312412,
    -0.05539753811389636,
    -0.04981621486463583,
    -0.04422438046888266,
    -0.0386230253039368,
    -0.033013156199495075,
    -0.027395794724503486,
    -0.021771975399812793,
    -0.016142743844926533,
    -0.010509154867813009,
    -0.00487227050730088,
    0.0007668419619294431,
    0.006407112051363351,
    0.01204746809701307,
    0.017686839303284296,
    0.02332415778145669,
    0.028958360571951336,
    0.03458839163972427,
    0.04021320383240912,
    0.045831760791228415,
    0.05144303880515788,
    0.0570460285994272,
    0.06263973705008019,
    0.0682231888170498,
    0.0737954278889894,
    0.07935551903392524,
    0.08490254915066764,
    0.09043562851680442,
    0.09595389192997983,
    0.10145649974007931,
    0.10694263877078991,
    0.1124115231298809,
    0.11786239490835448,
    0.12329452476939418,
    0.12870721242876748,
    0.1340997870290065,
    0.13947160741029951,
    0.14482206228157657,
    0.15015057029574053,
    0.15545658003343021,
    0.16073956990002225,
    0.16599904794087916,
    0.17123455158005896,
    0.17644564728785994,
    0.18163193018267038,
    0.18679302357263844,
    0.19192857844267502,
    0.1970382728922387,
    0.2021218115292822,
    0.20717892482558237,
    0.2122093684385462,
    0.21721292250438,
    0.22218939090730472,
    0.2271386005292802,
    0.23206040048445753,
    0.2369546613423253,
    0.2418212743432676,
    0.24666015060998298,
    0.2514712203579559,
    0.2562544321079196,
    0.261009751902982,
    0.265737162532847,
    0.27043666276732087,
    0.275108266601054,
    0.27975200251125554,
    0.28436791272989803,
    0.2889560525317293,
    0.29351648953922566,
    0.2980493030454355,
    0.3025545833555001,
    0.30703243114748713,
    0.3114829568530252,
    0.3159062800581064,
    0.3203025289242933,
    0.32467183963046875,
    0.32901435583516675,
    0.333330228159429,
    0.33761961369006044,
    0.34188267550308643,
    0.34611958220714506,
    0.35033050750650946,
    0.35451562978337064,
    0.3586751316989852,
    0.3628091998132543,
    0.3669180242222708,
    0.3710017982133493,
    0.3750607179370365,
    0.37909498209558856,
    0.38310479164738426,
    0.3870903495267442,
    0.3910518603786183,
    0.3949895303076056,
    0.39890356664077137,
    0.4027941777037288,
    0.4066615726094617,
    0.41050596105937087,
    0.41432755315603303,
    0.41812655922717595,
    0.42190318966037904,
    0.42565765474802825,
    0.42939016454205614,
    0.4331009287180217,
    0.43679015644809105,
    0.44045805628249424,
    0.44410483603905526,
    0.44773070270039195,
    0.451335862318411,
    0.4549205199257308,
    0.4584848794536746,
    0.46202914365650194,
    0.46555351404154516,
    0.4690581908049456,
    0.47254337277268615,
    0.47600925734663485,
    0.4794560404553255,
    0.48288391650921647,
    0.48629307836016994,
    0.4896837172649239,
    0.4930560228523183,
    0.4964101830940672,
    0.49974638427886464,
    0.5030648109896305,
    0.5063656460837083,
    0.5096490706758369,
    0.5129152641237277,
    0.5161644040160849,
    0.5193966661629195,
    0.5226122245880089,
    0.5258112515233673,
    0.5289939174055964,
    0.5321603908739904,
    0.5353108387702838,
    0.538445426139927,
    0.5415643162347837,
    0.5446676705171603,
    0.5477556486650602,
    0.5508284085785855,
    0.5538861063873981,
    0.5569288964591607,
    0.5599569314088841,
    0.5629703621091118,
    0.5659693377008734,
    0.5689540056053441,
    0.5719245115361542,
    0.5748809995122899,
    0.5778236118715346,
    0.5807524892844029,
    0.5836677707685162,
    0.5865695937033829,
    0.5894580938455359,
    0.5923334053439956,
    0.5951956607560147,
    0.5980449910630778,
    0.6008815256871209,
    0.6037053925069398,
    0.6065167178747627,
    0.6093156266329581,
    0.6121022421308562,
    0.6148766862416575,
    0.6176390793794106,
    0.6203895405160389,
    0.6231281871983937,
    0.6258551355653235,
    0.6285705003647359,
    0.6312743949706429,
    0.6339669314001717,
    0.6366482203305314,
    0.6393183711159208,
    0.6419774918043685,
    0.6446256891544928,
    0.6472630686521759,
    0.6498897345271369,
    0.6525057897694042,
    0.6551113361456731,
    0.6577064742155441,
    0.6602913033476382,
    0.6628659217355799,
    0.6654304264138442,
    0.667984913273465,
    0.6705294770775982,
    0.6730642114769356,
    0.6755892090249698,
    0.6781045611931031,
    0.6806103583856007,
    0.6831066899543836,
    0.6855936442136623,
    0.6880713084544066,
    0.6905397689586525,
    0.6929991110136433,
    0.6954494189258055,
    0.6978907760345576,
    0.7003232647259527,
    0.7027469664461543,
    0.7051619617147427,
    0.7075683301378567,
    0.7099661504211674,
    0.7123555003826859,
    0.7147364569654038,
    0.7171090962497707,
    0.7194734934660048,
    0.7218297230062405,
    0.7241778584365142,
    0.7265179725085884,
    0.728850137171613,
    0.7311744235836302,
    0.7334909021229195,
    0.7357996423991862,
    0.7381007132645956,
    0.7403941828246519,
    0.7426801184489243,
    0.7449585867816257,
    0.7472296537520369,
    0.7494933845847875,
    0.7517498438099872,
    0.7539990952732165,
    0.7562412021453702,
    0.7584762269323628,
    0.7607042314846945,
    0.7629252770068778,
    0.7651394240667291,
    0.7673467326045271,
    0.7695472619420366,
    0.7717410707914025,
    0.7739282172639173,
    0.7761087588786562,
    0.7782827525709912,
    0.7804502547009807,
    0.7826113210616343,
    0.7847660068870617,
    0.7869143668604994,
    0.7890564551222223,
    0.7911923252773398,
    0.7933220304034795,
    0.7954456230583568,
    0.797563155287238,
    0.7996746786302904,
    0.8017802441298314,
    0.8038799023374666,
    0.8059737033211287,
    0.808061696672013,
    0.8101439315114121,
    0.8122204564974527,
    0.8142913198317356,
    0.8163565692658792,
    0.8184162521079679,
    0.820470415228912,
    0.8225191050687105,
    0.8245623676426308,
    0.8266002485472936,
    0.8286327929666777,
    0.8306600456780332,
    0.8326820510577162,
    0.8346988530869367,
    0.8367104953574285,
    0.8387170210770363,
    0.8407184730752272,
    0.8427148938085238,
    0.84470632536586,
    0.8466928094738644,
    0.8486743875020685,
    0.8506511004680455,
    0.8526229890424735,
    0.8545900935541337,
    0.8565524539948376,
    0.8585101100242865,
    0.8604631009748672,
    0.8624114658563793,
    0.864355243360702,
    0.8662944718663945,
    0.8682291894432375,
    0.870159433856712,
    0.8720852425724209,
    0.8740066527604486,
    0.8759237012996652,
    0.8778364247819752,
    0.8797448595165063,
    0.8816490415337488,
    0.8835490065896379,
    0.8854447901695839,
    0.8873364274924513,
    0.8892239535144867,
    0.8911074029331967,
    0.8929868101911758,
    0.8948622094798888,
    0.8967336347434026,
    0.8986011196820731,
    0.9004646977561862,
    0.9023244021895553,
    0.9041802659730716,
    0.9060323218682137,
    0.9078806024105158,
    0.9097251399129903,
    0.9115659664695124,
    0.9134031139581644,
    0.9152366140445404,
    0.9170664981850107,
    0.9188927976299508,
    0.920715543426931,
    0.9225347664238717,
    0.9243504972721592,
    0.9261627664297305,
    0.9279716041641214,
    0.9297770405554793,
    0.9315791054995469,
    0.9333778287106083,
    0.9351732397244079,
    0.9369653679010334,
    0.9387542424277728,
    0.9405398923219369,
    0.9423223464336548,
    0.9441016334486395,
    0.9458777818909267,
    0.9476508201255824,
    0.9494207763613869,
    0.9511876786534901,
    0.9529515549060408,
    0.9547124328747907,
    0.956470340169673,
    0.9582253042573583,
    0.9599773524637811,
    0.9617265119766509,
    0.9634728098479319,
    0.9652162729963067,
    0.9669569282096137,
    0.968694802147265,
    0.9704299213426428,
    0.9721623122054751,
    0.9738920010241896,
    0.9756190139682518,
    0.9773433770904796,
    0.9790651163293416,
    0.9807842575112367,
    0.9825008263527545,
    0.9842148484629205,
    0.9859263493454209,
    0.9876353544008143,
    0.9893418889287253,
    0.9910459781300214,
    0.9927476471089762,
    0.9944469208754187,
    0.9961438243468635,
    0.997838382350631,
    0.9995306196259524,
    1.0012205608260605,
    1.002908230520269,
    1.004593653196038,
    1.0062768532610271,
    1.0079578550451374,
    1.0096366828025414,
    1.0113133607137017,
    1.012987912887379,
    1.0146603633626308,
    1.0163307361107967,
    1.017999055037478,
    1.0196653439845051,
    1.0213296267318976,
    1.0229919269998142,
    1.0246522684504953,
    1.0263106746901969,
    1.0279671692711179,
    1.0296217756933186,
    1.0312745174066338,
    1.0329254178125773,
    1.034574500266243,
    1.0362217880781968,
    1.037867304516364,
    1.0395110728079133,
    1.041153116141133,
    1.0427934576673032,
    1.0444321205025653,
    1.0460691277297858,
    1.047704502400416,
    1.0493382675363507,
    1.0509704461317817,
    1.052601061155048,
    1.0542301355504868,
    1.055857692240278,
    1.0574837541262916,
    1.0591083440919284,
    1.0607314850039653,
    1.0623531997143942,
    1.063973511062265,
    1.0655924418755243,
    1.0672100149728574,
    1.06882625316553,
    1.0704411792592283,
    1.072054816055904,
    1.0736671863556166,
    1.0752783129583812,
    1.0768882186660151,
    1.078496926283988,
    1.0801044586232769,
    1.081710838502218,
    1.0833160887483695,
    1.0849202322003726,
    1.0865232917098178,
    1.0881252901431155,
    1.0897262503833725,
    1.0913261953322702,
    1.0929251479119517,
    1.0945231310669117,
    1.0961201677658925,
    1.0977162810037884,
    1.099311493803554,
    1.10090582921812,
    1.102499310332318,
    1.1040919602648094,
    1.1056838021700264,
    1.1072748592401165,
    1.1088651547068997,
    1.1104547118438322,
    1.1120435539679796,
    1.1136317044420014,
    1.1152191866761414,
    1.1168060241302349,
    1.118392240315719,
    1.1199778587976599,
    1.1215629031967893,
    1.1231473971915518,
    1.124731364520165,
    1.126314828982694,
    1.1278978144431349,
    1.1294803448315158,
    1.1310624441460089,
    1.1326441364550561,
    1.1342254458995116,
    1.1358063966947975,
    1.1373870131330746,
    1.1389673195854304,
    1.1405473405040796,
    1.142127100424587,
    1.143706623968102,
    1.1452859358436114,
    1.146865060850213,
    1.1484440238794038,
    1.150022849917389,
    1.151601564047408,
    1.153180191452084,
    1.1547587574157885,
    1.1563372873270303,
    1.1579158066808626,
    1.1594943410813134,
    1.1610729162438378,
    1.1626515579977905,
    1.164230292288924,
    1.1658091451819088,
    1.1673881428628772,
    1.1689673116419916,
    1.1705466779560392,
    1.1721262683710503,
    1.1737061095849426,
    1.1752862284301948,
    1.1768666518765436,
    1.1784474070337096,
    1.1800285211541546,
    1.1816100216358618,
    1.1831919360251515,
    1.184774292019521,
    1.1863571174705208,
    1.187940440386658,
    1.1895242889363327,
    1.1911086914508073,
    1.1926936764272087,
    1.194279272531565,
    1.195865508601874,
    1.1974524136512106,
    1.199040016870868,
    1.2006283476335342,
    1.2022174354965085,
    1.203807310204954,
    1.2053980016951897,
    1.2069895400980217,
    1.2085819557421138,
    1.2101752791574008,
    1.211769541078542,
    1.2133647724484182,
    1.2149610044216719,
    1.21655826836829,
    1.2181565958772351,
    1.2197560187601186,
    1.221356569054924,
    1.222958279029775,
    1.2245611811867545,
    1.226165308265771,
    1.2277706932484769,
    1.2293773693622367,
    1.23098537008415,
    1.2325947291451236,
    1.2342054805340017,
    1.2358176585017504,
    1.2374312975656965,
    1.2390464325138266,
    1.2406630984091418,
    1.2422813305940745,
    1.2439011646949643,
    1.2455226366265952,
    1.2471457825967978,
    1.2487706391111135,
    1.2503972429775245,
    1.2520256313112514,
    1.253655841539617,
    1.2552879114069804,
    1.2569218789797398,
    1.2585577826514094,
    1.2601956611477667,
    1.2618355535320753,
    1.2634774992103832,
    1.2651215379368992,
    1.2667677098194445,
    1.2684160553249908,
    1.2700666152852715,
    1.271719430902483,
    1.2733745437550665,
    1.2750319958035796,
    1.2766918293966494,
    1.2783540872770238,
    1.2800188125877061,
    1.2816860488781863,
    1.283355840110767,
    1.2850282306669845,
    1.2867032653541282,
    1.288380989411861,
    1.2900614485189406,
    1.2917446888000454,
    1.2934307568327053,
    1.2951196996543413,
    1.2968115647694147,
    1.2985064001566884,
    1.3002042542766001,
    1.3019051760787566,
    1.303609215009541,
    1.3053164210198447,
    1.3070268445729198,
    1.3087405366523595,
    1.310457548770203,
    1.3121779329751735,
    1.313901741861047,
    1.3156290285751586,
    1.317359846827042,
    1.319094250897217,
    1.3208322956461112,
    1.3225740365231364,
    1.324319529575907,
    1.3260688314596134,
    1.32782199944655,
    1.3295790914358003,
    1.3313401659630808,
    1.3331052822107525,
    1.334874500017996,
    1.3366478798911583,
    1.3384254830142732,
    1.3402073712597584,
    1.341993607199293,
    1.3437842541148783,
    1.345579376010088,
    1.3473790376215071,
    1.349183304430368,
    1.3509922426743848,
    1.3528059193597888,
    1.3546244022735743,
    1.3564477599959504,
    1.3582760619130134,
    1.3601093782296338,
    1.3619477799825699,
    1.3637913390538077,
    1.3656401281841346,
    1.3674942209869512,
    1.3693536919623233,
    1.3712186165112807,
    1.3730890709503716,
    1.3749651325264685,
    1.376846879431841,
    1.3787343908194911,
    1.3806277468187693,
    1.382527028551257,
    1.3844323181469465,
    1.3863436987606976,
    1.388261254589001,
    1.3901850708870351,
    1.3921152339860345,
    1.3940518313109727,
    1.3959949513985606,
    1.397944683915578,
    1.3999011196775362,
    1.4018643506676793,
    1.4038344700563337,
    1.405811572220613,
    1.4077957527644858,
    1.409787108539209,
    1.4117857376641412,
    1.4137917395479385,
    1.415805214910145,
    1.4178262658031815,
    1.4198549956347448,
    1.421891509190625,
    1.4239359126579452,
    1.4259883136488398,
    1.4280488212245759,
    1.4301175459201265,
    1.4321945997692072,
    1.4342800963297855,
    1.43637415071007,
    1.4384768795949945,
    1.4405884012732033,
    1.4427088356645474,
    1.4448383043481081,
    1.4469769305907516,
    1.4491248393762315,
    1.4512821574348471,
    1.4534490132736715,
    1.4556255372073599,
    1.4578118613895512,
    1.4600081198448742,
    1.4622144485015731,
    1.4644309852247632,
    1.4666578698503314,
    1.4688952442194934,
    1.471143252214025,
    1.473402039792178,
    1.4756717550252931,
    1.477952548135135,
    1.4802445715319505,
    1.4825479798532752,
    1.4848629300035026,
    1.4871895811942237,
    1.48952809498537,
    1.4918786353271558,
    1.4942413686028564,
    1.496616463672421,
    1.4990040919169585,
    1.501404427284094,
    1.5038176463342265,
    1.5062439282877047,
    1.508683455072938,
    1.5111364113754613,
    1.5136029846879775,
    1.516083365361394,
    1.5185777466568762,
    1.521086324798934,
    1.5236092990295746,
    1.526146871663526,
    1.52869924814457,
    1.5312666371029944,
    1.5338492504141958,
    1.5364473032584531,
    1.5390610141818901,
    1.5416906051586663,
    1.5443363016543994,
    1.546998332690863,
    1.5496769309119727,
    1.552372332651092,
    1.5550847779996824,
    1.5578145108773203,
    1.5605617791031157,
    1.5633268344685503,
    1.5661099328117674,
    1.5689113340933403,
    1.5717313024735469,
    1.5745701063911768,
    1.5774280186439018,
    1.580305316470237,
    1.5832022816331222,
    1.586119200505149,
    1.5890563641554685,
    1.5920140684384023,
    1.5949926140837936,
    1.597992306789118,
    1.601013457313392,
    1.6040563815729059,
    1.607121400738803,
    1.6102088413365492,
    1.6133190353473037,
    1.6164523203112333,
    1.6196090394327918,
    1.6227895416879912,
    1.6259941819336956,
    1.6292233210189608,
    1.6324773258984449,
    1.6357565697479173,
    1.6390614320818888,
    1.642392298873382,
    1.6457495626758667,
    1.6491336227473785,
    1.6525448851768405,
    1.6559837630126015,
    1.6594506763932066,
    1.6629460526804127,
    1.6664703265944556,
    1.670023940351581,
    1.6736073438038395,
    1.6772209945811425,
    1.6808653582355872,
    1.6845409083880285,
    1.6882481268769047,
    1.6919875039092829,
    1.6957595382141148,
    1.6995647371976732,
    1.7034036171011342,
    1.7072767031602687,
    1.7111845297671957,
    1.7151276406341402,
    1.719106588959138,
    1.7231219375936078,
    1.7271742592117185,
    1.7312641364814474,
    1.7353921622372308,
    1.739558939654091,
    1.7437650824230997,
    1.7480112149280462,
    1.7522979724231242,
    1.7566260012114894,
    1.7609959588244568,
    1.76540851420114,
    1.7698643478682827,
    1.7743641521200184,
    1.7789086311972682,
    1.7834985014664577,
    1.7881344915972042,
    1.792817342738596,
    1.797547808693643,
    1.8023266560914513,
    1.807154664556623,
    1.8120326268753482,
    1.8169613491576,
    1.8219416509947988,
    1.8269743656122592,
    1.8320603400156614,
    1.8372004351307412,
    1.8423955259353129,
    1.8476465015826702,
    1.8529542655153255,
    1.8583197355679741,
    1.8637438440584526,
    1.8692275378653942,
    1.8747717784911446,
    1.880377542108406,
    1.8860458195889398,
    1.8917776165125353,
    1.8975739531542937,
    1.903435864448127,
    1.9093643999242071,
    1.915360623617898,
    1.9214256139475474,
    1.9275604635582508,
    1.9337662791285197,
    1.9400441811365146,
    1.946395303582246,
    1.952820793661865,
    1.9593218113898518,
    1.9658995291645887,
    1.9725551312724376,
    1.979289813325072,
    1.9861047816243877,
    1.993001252448882,
    1.9999804512549066,
    2.0070436117856874,
    2.014191975080443,
    2.0214267883753574,
    2.0287493038874813,
    2.0361607774719905,
    2.0436624671424437,
    2.051255631442922,
    2.0589415276600254,
    2.0667214098618283,
    2.074596526749851,
    2.082568119309074,
    2.0906374182398473,
    2.098805641154329,
    2.1070739895187542,
    2.1154436453214123,
    2.1239157674447027,
    2.13249148771797,
    2.141171906626105,
    2.149958088646985,
    2.158851057188854,
    2.167851789096534,
    2.176961208693129,
    2.1861801813213426,
    2.1955095063459797,
    2.2049499095763156,
    2.2145020350640836,
    2.2241664362295968,
    2.2339435662651232,
    2.243833767761007,
    2.2538372614961633,
    2.2639541343304446,
    2.274184326132022,
    2.2845276156682495,
    2.2949836053835186,
    2.3055517049823475,
    2.3162311137303053,
    2.32702080137937,
    2.3379194876179135,
    2.3489256199385924,
    2.360037349810052,
    2.371252507030282,
    2.3825685721307885,
    2.3939826466911414,
    2.4054914214128873,
    2.417091141789939,
    2.4287775711991704,
    2.4405459512194834,
    2.452390958969678,
    2.464306661234166,
    2.4762864651200163,
    2.4883230649575494,
    2.5004083851180474,
    2.5125335183733717,
    2.524688659360354,
    2.536863032632814,
    2.549044814679772,
    2.561221049150981,
    2.5733775543478883,
    2.5854988217920636,
    2.5975679043488484,
    2.6095662919251916,
    2.6214737721236894,
    2.633268272339665,
    2.64492567851238,
    2.656419623894628,
    2.66772123848274,
    2.678798845652011,
    2.6896175862250655,
    2.700138940178517,
    2.7103200997511228,
    2.720113119641671,
    2.7294637196791323,
    2.738309519725978,
    2.746577290882697,
    2.7541783665618875,
    2.7610002298563674,
    2.7668888246119487,
    2.7716017078982937,
    2.77459045708152
  ]
}
