import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { ITab } from "@shared/interfaces/tab.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private destroyRef: DestroyRef = inject(DestroyRef);

  public returnTabIndex: number;
  public DEFAULT_TAB_INDEX: number = 0;

  @Input() public config: ITab[];

  public ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params: Params): void => {
        this.returnTabIndex = params.tab_index_to_return || this.DEFAULT_TAB_INDEX;
      });
  }

  public onTabChanged(event: MatTabChangeEvent): void {
    this.DEFAULT_TAB_INDEX = event.index;
    this.router.navigate([], {
      queryParams: {},
    });
  }
}
