import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITotalPrice } from "@shared/interfaces/total-price-dialog.interface";
import { NumberFormatService } from '@shared/services/number-format.service';

@Component({
  selector: 'app-total-price',
  templateUrl: './total-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalPriceComponent {
  @Input() public config: ITotalPrice;
  public NumberFormatService = NumberFormatService;

  public getUserCount(): string {
    if (!this.config.userCount) {
      return ""
    }

    if (this.config.userCount === 1) {
      return `${this.config.userCount} user`
    }

    return `${this.config.userCount} users`
  }
}
