import { Component, Inject, OnInit, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConstants } from '@core/constants';
import { PpdService } from '../../services/ppd.service';
import { DialogsData } from '../../tabs/tabs.component';
import { SPORT_NAME_ENUM } from "@shared/enums/sport-name.enum";
import { MODAL_WINDOW_TITLE } from "../enums/modal-window-title.enum";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-mean-max-power-dialog',
  templateUrl: './mean-max-power-dialog.component.html',
  styleUrls: ['./mean-max-power-dialog.component.scss'],
})
export class MeanMaxPowerDialogComponent implements OnInit {
  protected modalWindowTitle: MODAL_WINDOW_TITLE = MODAL_WINDOW_TITLE.POWER_DURATION;
  public form!: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<MeanMaxPowerDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogsData,
    private ppdService: PpdService,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {}

  public initForm(): void {
    this.form = this.fb.group({
      duration_efforts: new UntypedFormControl(
        this.data.duration_efforts,
        Validators.required
      ),
      average_speed: new UntypedFormControl(
        this.data.average_speed,
        Validators.required
      ),
      average_power: new UntypedFormControl(
        this.data.average_power,
        Validators.required
      ),
      work: new UntypedFormControl(
        this.ppdService.calculateWorkMeanMaxPowerdialog(
          this.data.is_speed_sport!,
          this.data.duration_efforts!,
          this.data.average_power!,
          this.data.average_speed!,
          this.data.test
        )
      ),
    });
    this.form.get('duration_efforts')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((change): void => {
        let updatedWork = this.ppdService.calculateWorkMeanMaxPowerdialog(
          this.data.is_speed_sport!,
          change,
          this.form.value.average_power,
          this.form.value.average_speed,
          this.data.test
        );
        this.form.patchValue({ work: updatedWork });
      });
    this.form.get('average_speed')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((change): void => {
        let updatedWork = this.ppdService.calculateWorkMeanMaxPowerdialog(
          this.data.is_speed_sport!,
          this.form.value.duration_efforts,
          this.form.value.average_power,
          change,
          this.data.test
        );
        this.form.patchValue({ work: updatedWork });
      });
    this.form.get('average_power')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((change): void => {
        let updatedWork = this.ppdService.calculateWorkMeanMaxPowerdialog(
          this.data.is_speed_sport!,
          this.form.value.duration_efforts,
          change,
          this.form.value.average_speed,
          this.data.test
        );
        this.form.patchValue({ work: updatedWork });
      });
  }
  public ngOnInit(): void {
    this.initForm();

    if (this.data.test.sport.name === SPORT_NAME_ENUM.CYCLING || this.data.test.sport.name === SPORT_NAME_ENUM.TRIATHLON_CYCLING) {
      this.modalWindowTitle = MODAL_WINDOW_TITLE.POWER_ONLY_EFFORTS;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }
  public save(): void {
    let meanMaxDialogData = {
      ...this.form.value,
      initial_average_speed: this.data.average_speed,
      initial_average_power: this.data.average_power,
      initial_duration_efforts: this.data.duration_efforts,
      selected_speed: this.data.selected_speed,
      selected_power: this.data.selected_power,
      selected_time: this.data.selected_time,
    };
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.form.value.time > 25 && this.form.value.time < 135) {
      this.snackBar.open('Time must be in range of 12 to 25 seconds or 135 to 1800 seconds', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }
    this.dialogRef.close(meanMaxDialogData);
  }
}
