import { Component, OnInit, ViewChild } from "@angular/core";
import { ReportTemplate } from "@core/services/report-template/report-template.service";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TemplateReportBuilderComponent } from "./template-report-builder/template-report-builder.component";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ReportTemplatesListComponent } from "../report-templates-list/report-templates-list.component";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-report-template",
  templateUrl: "./report-template.component.html",
})
export class ReportTemplateComponent implements OnInit {
  @ViewChild(ReportTemplatesListComponent)

  public reportTemplates: ReportTemplatesListComponent;
  public searchTerm: string;
  public searchFormControl: UntypedFormControl;

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.searchFormControl = new UntypedFormControl(this.searchTerm);
    this.searchFormControl.valueChanges.pipe(
      debounceTime(450),
      distinctUntilChanged()
    ).subscribe(newValue => {
      this.searchTerm = newValue;
    });
  }

  public openAddTemplateDialog(reportTemplate: ReportTemplate | null, isEdit: boolean = false, isCopy: boolean = false): void  {
    const dialogRef: MatDialogRef<TemplateReportBuilderComponent> = this.dialog.open(
      TemplateReportBuilderComponent,
      {
        disableClose: true,
        data: {
          reportTemplate,
          isCopy,
          isEdit
        }
      }
    );

    dialogRef.afterClosed().subscribe(event => {
      if (event === 'SUCCESS') {
        this.reportTemplates.load();
      }
    });
  }

  public onEditReport(template: ReportTemplate): void {
    this.openAddTemplateDialog(template, true, false);
  }

  public onCopyReport(template: ReportTemplate): void {
    this.openAddTemplateDialog(template, false, true);
  }
}
