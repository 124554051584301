<div class="page-container">
  <app-custom-back-button [customUrl]="customUrl"></app-custom-back-button>
  <div class="o-flex o-flex--align-items-center u-margin-bottom-50 u-margin-top-10">
    <div class="general-info--font-size-35 line-height-28 u-font-weight-400 u-margin-right-10">{{sportName}}</div>
    <app-label class="u-right-padding-5" [text]="'Sport'"></app-label>
  </div>
  <form [formGroup]="sportConfigurationForm">
    <mat-dialog-content>
      <div class="u-margin-bottom-30">
        <span class="u-font-size-16 u-font-weight-700 line-height-16 u-display-block u-margin-bottom-8">Customize x-axis in graphs</span>
        <span class="u-font-size-14 u-font-weight-400 line-height-16 u-display-block sport-configuration--color">select your units: m/s, km/h, mph, Watt or Watt /kg and many more</span>
        <hr>
        <br>
        <span class="u-display-block u-font-weight-700 line-height-16 u-font-size-14 u-margin-bottom-8 sport-configuration--color">Reference System: </span>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Primary Type</mat-label>
            <mat-select formControlName="primary_type">
              <mat-option [value]="item.id" *ngFor="let item of referenceOptions">{{ item.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="sportConfigurationForm.controls?.primary_type?.errors && sportConfigurationForm.controls?.primary_type?.touched">
              <div [innerHTML]="sportConfigurationForm.controls.primary_type.errors | errorFormFormat: { fieldName: 'Primary type' }"></div>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
            <mat-label>Secondary Type</mat-label>
            <mat-select formControlName="secondary_type">
              <mat-option
                [value]="item.id"
                *ngFor="let item of secondaryReferenceOptions"
              >{{ item.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="sportConfigurationForm.controls?.secondary_type?.errors && sportConfigurationForm.controls?.secondary_type?.touched">
              <div [innerHTML]="sportConfigurationForm.controls.secondary_type.errors | errorFormFormat: { fieldName: 'Secondary type' }"></div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="u-margin-bottom-30">
        <span class="u-font-size-16 u-font-weight-700 line-height-16 u-display-block u-margin-bottom-8">Choose your custom athlete category</span>
        <hr>
        <br>
        <span class="u-display-block u-font-weight-700 line-height-16 u-font-size-14 u-margin-bottom-8 sport-configuration--color">Custom Athlete Category: </span>
        <div class="o-flex o-flex--justify-space-between">
          <div class="width-100-percent">
            <app-chips
              [label]="'Custom Category'"
              [form]="sportConfigurationForm.get('custom_athlete_category')"
              [options]="athleteCategoryOptions"
              [displayField]="'name'"
              (optionChange)="onSelectCustomCategory($event)"
            ></app-chips>
          </div>
        </div>
      </div>
      <div *ngIf="false" class="u-margin-bottom-30">
        <span class="u-font-size-16 u-font-weight-700 line-height-16 u-display-block u-margin-bottom-8">Economy & Efficiency settings</span>
        <span class="u-font-size-14 u-font-weight-400 line-height-16 u-margin-bottom-8 u-display-block sport-configuration--color">Select the intensity at which economy or efficiency should be benchmarked (e.g. used for the metabolic fingerprint or meter charts).</span>
        <hr>
        <br>
        <app-economy-efficiency-settings *ngIf="economyEfficientSettingsControl" [economyEfficientSettingsListFromResponse]="economyEfficientSettingsControl" [availableEconomyEfficientSettingsList]="economyEfficientSettingsConfig" (updateEconomyEfficientSettings)="updateEconomyEfficientSettings($event)"></app-economy-efficiency-settings>
      </div>
      <div class="u-margin-bottom-30">
        <span class="line-height-18 u-font-weight-700 line-height-18 u-display-block u-margin-bottom-8">Default Energy Demand Settings</span>
        <hr>
        <br>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent">
            <mat-label>Default Name</mat-label>
            <input matInput placeholder="Name" formControlName="default_name"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>EDE Factor A (Constant)</mat-label>
            <input matInput placeholder="Enter Value" formControlName="ede_factor_a"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>EDE Factor B (Linear)</mat-label>
            <input matInput placeholder="Enter Value" formControlName="ede_factor_b"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>EDE Factor D (Cubic)</mat-label>
            <input matInput placeholder="Enter Value" formControlName="ede_factor_d"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>02 Per Watt EQ A</mat-label>
            <input matInput placeholder="Enter Value" formControlName="per_watt_qw_a"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>02 Per Watt EQ B</mat-label>
            <input matInput placeholder="Enter Value" formControlName="per_watt_qw_b"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>02 Per Watt EQ D</mat-label>
            <input matInput placeholder="Enter Value" formControlName="per_watt_qw_d"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent">
            <mat-label>Gross Efficiency</mat-label>
            <input matInput placeholder="Enter Value" formControlName="gross_efficiency"/>
          </mat-form-field>
        </div>
      </div>
      <div class="u-margin-bottom-30" [formGroup]="formConditions">
        <span class="u-font-size-16 u-font-weight-700 line-height-16 u-display-block u-margin-bottom-8">Set conditional energy demand values</span>
        <hr>
        <br>
        <div class="u-cursor-pointer o-flex o-flex--justify-right o-flex--align-items-center" (click)="addCondition()">
          <mat-icon color="primary" class="u-margin-right-5">add_box</mat-icon>
          <span class="u-font-size-14 u-font-weight-500 line-height-16 u-color-red-faded">Add Condition</span>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent">
            <mat-label>Condition Name</mat-label>
            <input matInput placeholder="Name" formControlName="condition_name"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>EDE Factor A (Constant)</mat-label>
            <input matInput placeholder="Enter Number" formControlName="ede_factor_a"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>EDE Factor B (Linear)</mat-label>
            <input matInput placeholder="Enter Number" formControlName="ede_factor_b"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>EDE Factor D (Cubic)</mat-label>
            <input matInput placeholder="Enter Number" formControlName="ede_factor_d"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>02 Per Watt EQ A</mat-label>
            <input matInput placeholder="Enter Number" formControlName="per_watt_qw_eq_a"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>02 Per Watt EQ B</mat-label>
            <input matInput placeholder="Enter Number" formControlName="per_watt_qw_eq_b"/>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
            <mat-label>02 Per Watt EQ D</mat-label>
            <input matInput placeholder="Enter Number" formControlName="per_watt_qw_eq_d"/>
          </mat-form-field>
        </div>
        <div class="o-flex o-flex--justify-space-between">
          <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent">
            <mat-label>Gross Efficiency</mat-label>
            <input matInput placeholder="Enter Number" formControlName="gross_efficiency"/>
          </mat-form-field>
        </div>
      </div>
      <div [formGroup]="formConditions">
        <div class="content" formArrayName="conditions">
          <ng-container *ngFor="let condition of conditions.controls; let i = index">
            <mat-accordion [formGroupName]="i" class="example-headers-align u-margin-bottom-8 u-display-block">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="u-margin-left-10 u-font-size-14 u-font-weight-700 line-height-16">{{condition.get('condition_name')?.value}}</span>
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="o-flex width-100-percent o-flex--justify-right u-margin-right-10">
                      <button mat-raised-button color="primary" (click)="removeConditionById(i, $event)">Remove</button>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <div class="o-flex o-flex--justify-space-between">
                    <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent">
                      <mat-label>Condition Name</mat-label>
                      <input matInput placeholder="Name" formControlName="condition_name"/>
                    </mat-form-field>
                  </div>
                  <div class="o-flex o-flex--justify-space-between">
                    <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
                      <mat-label>EDE Factor A (Constant)</mat-label>
                      <input matInput placeholder="Enter Number" formControlName="ede_factor_a"/>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
                      <mat-label>EDE Factor B (Linear)</mat-label>
                      <input matInput placeholder="Enter Number" formControlName="ede_factor_b"/>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
                      <mat-label>EDE Factor D (Cubic)</mat-label>
                      <input matInput placeholder="Enter Number" formControlName="ede_factor_d"/>
                    </mat-form-field>
                  </div>
                  <div class="o-flex o-flex--justify-space-between">
                    <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
                      <mat-label>02 Per Watt EQ A</mat-label>
                      <input matInput placeholder="Enter Number" formControlName="per_watt_qw_eq_a"/>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
                      <mat-label>02 Per Watt EQ B</mat-label>
                      <input matInput placeholder="Enter Number" formControlName="per_watt_qw_eq_b"/>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always" class="width-33-percent">
                      <mat-label>02 Per Watt EQ D</mat-label>
                      <input matInput placeholder="Enter Number" formControlName="per_watt_qw_eq_d"/>
                    </mat-form-field>
                  </div>
                  <div class="o-flex o-flex--justify-space-between">
                    <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent">
                      <mat-label>Gross Efficiency</mat-label>
                      <input matInput placeholder="Enter Number" formControlName="gross_efficiency"/>
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="u-margin-top-30 u-display-block">
      <div class="full-width o-flex o-flex--justify-space-between">
        <button id="cancelButton" class="width-49-percent sport-configuration--button-border" mat-button (click)="back()" type="button">Cancel</button>
        <button id="saveButton" class="width-49-percent" mat-raised-button color="primary" (click)="save()">Save</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
