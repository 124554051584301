import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class AccountTypeResultDto extends PagedResultDto {
  public items: AccountTypeDto[] = [];
}
export class AccountTypeDto extends Deserializable {
  public id: number | undefined;
  public name: string | undefined;
  public is_active: boolean | undefined;
}
