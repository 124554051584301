<div class="device-management-list">
  <div class="title">Organization Settings</div>
  <mat-tab-group>
    <mat-tab label="Description Texts">
      <ng-template matTabContent>
        <app-org-tab-description-text></app-org-tab-description-text>
      </ng-template>
    </mat-tab>
    <mat-tab label="Statistics">
      <ng-template matTabContent>
        <app-org-tab-statistics></app-org-tab-statistics>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
