import { Component, ElementRef, inject, Inject, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import * as moment from 'moment';
import { combineLatest, Subscription } from 'rxjs';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { ManageOrganizationsService } from '@core/services/manage-organizations/manage-organizations.service';
import * as _ from 'lodash-es';
import { ChartService } from '@shared/services/chart.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from "@shared/dialogs/confirm-dialog/confirm-dialog.component";
import { ManageOrganizationDto } from "@core/services/manage-organizations/manage-organization-dto";
import { PricingPlansService } from "@core/services/pricing-plans/pricing-plans.service";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";


@Component({
  selector: 'app-organization-analytics-dialog',
  templateUrl: './organization-analytics-dialog.component.html',
  styleUrls: ['./organization-analytics-dialog.component.scss'],
})
export class OrganizationAnalyticsDialogComponent extends AppBaseComponent {
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);

  @ViewChild('linechart') public canvasLineChart: ElementRef<HTMLCanvasElement> | undefined;
  @ViewChild('barchart') public canvasBarChart: ElementRef<HTMLCanvasElement> | undefined;

  public periods = AppConstants.PERIOD_ANALYTICS;
  public organization: any = {};
  public displayedColumns: string[] = [
    'plan',
    'user',
    'athlete',
    'lactate',
    'ppd',
    'manual',
    'virtual',
    'event',
    'result',
    'start-date',
    'next-period',
    'cancellation',
    'minimum-contract',
    'payment-method',
    'balance',
    'discount-exp',
    'discount-amount',
  ];
  public displayedColumnsStaisticsTable: string[] = [
    'index',
    'total',
    'user',
    'athlete',
    'lactate',
    'ppd',
    'manual',
    'virtual',
    'event',
    'result',
    'link-item',
  ];
  public dataSource: any = [];
  public statisticsData: any;
  public data: any = {
    statisticDatasource: [],
    lineChartDataSource: [],
    heads: [],
    head: null,
    statistics: [],
    invoicePlans: [],
    invoiceProducts: [],
    products: [],
    productIds: [],
    productSource: [],
    planSource: [],
    plans: [],
    planIds: [],
    periods: this.periods,
    years: [],
    year: null,
    period: this.periods[0],
    barChartItems: [],
    items: [],
    keyItems: [],
    lineChartType: null,
    paymentMethods: '',
    purchase_removes: [],
    currentPaymentMethods: [],
  };
  public lineChart: any = null;
  public barChart: any = null;
  public paymentMethods = AppConstants.PAYMENT_METHODS;
  public account_types: any = [];
  public productOptions: any = [];
  public planOptions: any = [];
  public featureOptions: any = [];
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public analyticsOfPotentialForm: UntypedFormGroup = {} as UntypedFormGroup;
  public graphFilterForm: UntypedFormGroup = {} as UntypedFormGroup;
  public statsForm: UntypedFormGroup = {} as UntypedFormGroup;
  public selectedPlans: any = [];
  public selectedProducts: any = [];
  public selectedFeature: any = [];
  public yearOptions: any[] = [];
  public formArray: UntypedFormArray = new UntypedFormArray([]);
  public isExtraRow = (index: number, element: any): boolean => element.purchase_alternative_items.length > 0;
  public isExtraRow2 = (index: number, element: any): boolean => element.purchase_link_items.length > 0;

  constructor(
    private manageOrganizationService: ManageOrganizationsService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public orgId: number,
    private fb: UntypedFormBuilder,
    private chartService: ChartService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OrganizationAnalyticsDialogComponent>,
    private readonly dialog: MatDialog,
    private readonly planPricingService: PricingPlansService,
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForms();
    this.initOptionAndValue();
  }

  public removePlan(item: any): void {
    const planId = this.data.invoicePlans.find((element: any): boolean => element.plan === item.option.value);

    if (planId) {
      this.data.purchase_removes.push(planId?.id);
    }
  }


  public removeProduct(item: any): void {
    const productId = this.data.invoiceProducts.find((element: any): boolean => element.product === item.option.value);

    if (productId) {
      this.data.purchase_removes.push(productId?.id);
    }
  }

  private initForms(): void {
    this.form = this.fb.group({
      name: new UntypedFormControl(''),
      head: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      photo: new UntypedFormControl(''),
      website: new UntypedFormControl(''),
      address: new UntypedFormControl(''),
      account_type: new UntypedFormControl(''),
      product_purchased: new UntypedFormControl([]),
      plan_purchased: new UntypedFormControl([]),
      features: new UntypedFormControl([]),
      solvent: new UntypedFormControl(false),
      enable_payment_methods: new UntypedFormControl(false),
      payment_methods: new UntypedFormControl([]),
      is_multiple_exceed_invoice: new UntypedFormControl(false),
      is_force_update_payment_method: new UntypedFormControl(false),
      vat_number: new UntypedFormControl(''),
      facebook: new UntypedFormControl(''),
      instagram: new UntypedFormControl(''),
    });
    this.analyticsOfPotentialForm = this.fb.group({
      potential_athletes: new UntypedFormControl(),
      potential_tests: new UntypedFormControl(),
    });
    this.statsForm = this.fb.group({
      year: new UntypedFormControl(0),
      period: new UntypedFormControl(0),
    });
    this.graphFilterForm = this.fb.group({
      filter1: new UntypedFormControl([]),
      filter2: new UntypedFormControl([]),
      start: new UntypedFormControl([]),
      end: new UntypedFormControl([]),
    });
  }
  private initOptionAndValue(): void {
    combineLatest([
      this.featureStoreService.getActivatedFeatureList(),
      this.manageOrganizationService.getOrganizationById(this.orgId),
      this.manageOrganizationService.getHead(this.orgId),
      this.manageOrganizationService.getAccountTypes(),
      this.apiService.get('products/publish/?organization=' + this.orgId),
      this.apiService.get('plans/publish/?organization=' + this.orgId),
      this.apiService.get('invoices/products/?organization=' + this.orgId),
      this.apiService.get('invoices/plans/?organization=' + this.orgId),
      this.apiService.get('organizations/' + this.orgId + '/statistic/'),
      this.apiService.get('organizations/' + this.orgId + '/line_graph/'),
      this.apiService.get('features/'),
    ]).subscribe(
      ([
        purchasedFeatureList,
        resOrgnization,
        resHeads,
        resAccountTypes,
        resProducts,
        resPlans,
        resInvoicedProducts,
        resInvoicedPlans,
        resStats,
        resLineGraph,
        features
      ]: any): void => {
        this.organization = resOrgnization;
        if (AppConstants.COUNTRY_SUPPORT_SEPA.indexOf(this.organization.country.name) === -1) {
          this.paymentMethods = [AppConstants.PAYMENT_METHODS[0], AppConstants.PAYMENT_METHODS[2],];
        } else {
          this.paymentMethods = [AppConstants.PAYMENT_METHODS[0], AppConstants.PAYMENT_METHODS[1], AppConstants.PAYMENT_METHODS[2],];
        }

        this.account_types = resAccountTypes.items;
        this.data.lineChartDataSource = resLineGraph;
        this.data.lineChartDataSource.map((item: any): void => {
          if (item.test_type == 'newtype') {
            item.test_type = 'ppd';
          }
        });
        const items = AppConstants.ITEMS.map((item: any) => {
          return {
            id: item.id,
            NAME: item.NAME,
          };
        });

        this.data.items = items.filter((item: any): boolean => {
          return item.id < 9;
        });

        this.onFilterLineChart();
        this.data.head = resHeads.items.find(
          (head: any): boolean => head.id === resOrgnization.head
        );
        this.data.invoicePlans = resInvoicedPlans;
        this.data.invoicePlans.forEach((invoicePlan: any): void => {
          if (!invoicePlan.is_additional) {
            this.data.plans.push({
              planId: invoicePlan.id,
              id: invoicePlan.plan,
              name: invoicePlan.name,
            });
            invoicePlan.includeItem = this.getIncludeItemInvoicePlan(
              invoicePlan.include_items
            );
          }
        });

        this.data.invoiceProducts = resInvoicedProducts;
        this.data.invoiceProducts.forEach((invoiceProduct: any): void => {
          if (!invoiceProduct.is_additional) {
            this.data.products.push({
              productId: invoiceProduct.id,
              id: invoiceProduct.plan,
              name: invoiceProduct.name,
            });
            invoiceProduct.includeItem = this.getIncludeItemInvoiceProduct(
              invoiceProduct.include_items
            );
          }
        });

        this.dataSource = resInvoicedPlans;
        this.data.products = resInvoicedProducts;
        this.formArray = new UntypedFormArray(
          this.dataSource.map(
            (x: any) =>
              new UntypedFormGroup({
                plan_id: new UntypedFormControl(x.id),
                max_user: new UntypedFormControl(x.max_user, Validators.required),
                max_athlete: new UntypedFormControl(
                  x.max_athlete,
                  Validators.required
                ),
                max_test_la: new UntypedFormControl(
                  x.max_test_la,
                  Validators.required
                ),
                max_test_map: new UntypedFormControl(
                  x.max_test_map,
                  Validators.required
                ),
                max_test_manual: new UntypedFormControl(
                  x.max_test_manual,
                  Validators.required
                ),
                max_test_virtual: new UntypedFormControl(
                  x.max_test_virtual,
                  Validators.required
                ),
                max_test_event: new UntypedFormControl(
                  x.max_test_event,
                  Validators.required
                ),
                max_test_result: new UntypedFormControl(
                  x.max_test_result,
                  Validators.required
                ),
                link_item: new UntypedFormArray(
                  x.purchase_link_items.map(
                    (item: any) =>
                      new UntypedFormGroup({
                        activation_item: new UntypedFormControl(item.activation_item),
                        created_at: new UntypedFormControl(item.created_at),
                        current_period: new UntypedFormControl(item.current_period),
                        id: new UntypedFormControl(item.id),
                        idle_time_period: new UntypedFormControl(
                          item.idle_time_period
                        ),
                        idle_time_unit: new UntypedFormControl(item.idle_time_unit),
                        is_delete: new UntypedFormControl(item.is_delete),
                        linked_item: new UntypedFormControl(item.linked_item),
                        master_item: new UntypedFormControl(item.master_item),
                        max_item: new UntypedFormControl(
                          item.max_item,
                          Validators.required
                        ),
                        name_link_item: new UntypedFormControl(item.name_link_item),
                        purchase_detail: new UntypedFormControl(item.purchase_detail),
                        purchase_lots: new UntypedFormControl(item.purchase_lots),
                        total_item: new UntypedFormControl(item.total_item),
                        total_period_item: new UntypedFormControl(
                          item.total_period_item
                        ),
                        updated_at: new UntypedFormControl(item.updated_at),
                      })
                  )
                ),
              })
          )
        );
        this.statisticsData = resStats;
        this.data.statistics = this.statisticsData.map((statistic: any): void => {
          let period = this.data.periods.find((period: any): boolean => {
            return period.id == statistic.period_type;
          });
          if (period) {
            statistic.period_type_name = period.name;
          }
        });
        this.initYearOptions();
        this.productOptions = resProducts.map((item: any) => {
          return {
            value: item.id,
            name: item.name,
          };
        });
        this.planOptions = resPlans.map((item: any) => {
          return {
            value: item.id,
            name: item.name,
          };
        });

        this.featureOptions = features.results.map((item: any) => {
          return {
            value: item.id,
            name: item.name,
          };
        });

        this.selectedPlans = resInvoicedPlans.map((invoice: any) => {
          return {
            value: invoice.plan,
            name: invoice.name,
          };
        });
        this.selectedProducts = resInvoicedProducts.map((invoice: any) => {
          return {
            value: invoice.product,
            name: invoice.name,
          };
        });

        this.selectedFeature = purchasedFeatureList.results.map((feature: any) => {
          return {
            value: feature.feature,
            name: feature.name,
          };
        });

        const address =
          (this.organization.address_street_number ?? "") +
          ' ' +
          (this.organization.address_street_name ?? "") +
          ' ' +
          (this.organization.invoice_address_city ?? "") +
          ' ' +
          (this.organization.address_zip ?? "") +
          ' ' +
          (this.organization.invoice_address_state ?? "") + 
          ' ' +
          this.organization.country.name;

        let selectedPaymentMethods: any[] = [];
        if (this.organization.paid_method) {
          selectedPaymentMethods = this.organization.paid_method?.split(',')
            .map((item: any) => {
              const findItem = this.paymentMethods.find(
                (pm: any) => pm.value === item
              );
              return { value: item, name: findItem?.name };
            });
        }

        this.form.setValue({
          name: this.organization.name,
          head: this.data.head ? this.data.head.first_name : '',
          email: this.organization.email,
          phone: this.organization.phone,
          photo: this.organization.photo,
          website: this.organization.website,
          address: address,
          account_type: this.organization.account_type,
          product_purchased: this.selectedProducts,
          plan_purchased: this.selectedPlans,
          features: this.selectedFeature,
          solvent: this.organization.is_solvent,
          enable_payment_methods: this.organization.is_check_invoice,
          payment_methods: selectedPaymentMethods,
          is_multiple_exceed_invoice: this.organization.is_multiple_exceed_invoice,
          is_force_update_payment_method: this.organization.is_force_update_payment_method,
          facebook: this.organization.facebook,
          instagram: this.organization.instagram,
          vat_number: this.organization.vat_number
        });

        this.analyticsOfPotentialForm.setValue({
          potential_athletes: this.organization.potential_athlete_number
            ? this.organization.potential_athlete_number
            : 0,
          potential_tests: this.organization.potential_test_number
            ? this.organization.potential_test_number
            : 0,
        });

        this.statsForm.setValue({
          year: moment(this.organization.created_at).format('YYYY'),
          period: 1,
        });
        this.onFilterStatsTable();
      }
    );
  }
  public getIncludeItemInvoicePlan(includeItem: any) {
    return includeItem ? includeItem.split('|') : [];
  }

  public getIncludeItemInvoiceProduct(includeItem: any) {
    return includeItem ? includeItem.split('|') : [];
  }
  public getControl(index: number, controlName: string): UntypedFormControl {
    return (this.formArray.at(index) as UntypedFormGroup).get(controlName) as UntypedFormControl;
  }
  public getLinkedItemsControls(index: number, controlName: string, j: number, controlname2: string): UntypedFormControl {
    const array: UntypedFormArray = (this.formArray.at(index) as UntypedFormGroup).get(controlName) as UntypedFormArray;

    return (array.at(j) as UntypedFormGroup).get(controlname2) as UntypedFormControl;
  }

  public initYearOptions(): void {
    const organizationCreatedAt: string = moment(this.organization.created_at).format(
      'YYYY'
    );
    const currentYear: string = moment().format('YYYY');
    for (let year = +organizationCreatedAt; year <= +currentYear; year++) {
      this.yearOptions.push({
        id: year.toString(),
        name: year.toString(),
      });
    }
  }

  public getOrganizationLineGraph(): Subscription {
    let param_array = [];
    if (this.graphFilterForm.get('filter1')?.value != '') {
      let types = this.graphFilterForm.get('filter1')?.value.map((item: any) => item.NAME.toLowerCase());
      param_array.push('type=' + types.join(','));
    }
    if (this.graphFilterForm.get('start')?.value != '') {
      param_array.push('from=' + this.graphFilterForm.get('start')?.value.format('YYYY-MM-DD'));
    }
    if (this.graphFilterForm.get('end')?.value != '') {
      param_array.push('to=' + this.graphFilterForm.get('end')?.value.format('YYYY-MM-DD'));
    }
    let params = param_array.join('&');

    return this.apiService
      .get('organizations/' + this.orgId + '/line_graph/' + '?' + params)
      .subscribe((res: Object): void => {
        this.data.lineChartDataSource = res;
        this.data.lineChartDataSource.map((item: any): void => {
          if (item.test_type == 'newtype') {
            item.test_type = 'ppd';
          }
        });
        this.onFilterLineChart();
      });
  }

  public onFilterLineChart(): void {
    let payload: any = {
      labels: [],
      details: [],
    };
    let labelGroups = _.groupBy(this.data.lineChartDataSource, 'created_date');
    Object.keys(labelGroups).map((key: any): void => {
      let label = moment(key).format('DD.MM.YYYY');
      payload.labels.push(label);
    });

    let groups = _.groupBy(this.data.lineChartDataSource, 'test_type');
    Object.keys(groups).map((key: any): void => {
      let data: any[] = [];
      Object.keys(labelGroups).map((labelGroup: any): void => {
        let result = groups[key].find((group: any): boolean => {
          return group.created_date == labelGroup;
        });
        data.push(result != null ? result.total_test : 0);
      });
      let detail = {
        label: key,
        data: data,
      };
      payload.details.push(detail);
    });
    if (this.lineChart) {
      this.lineChart.destroy();
    }

    if (this.canvasLineChart) {
      this.lineChart = this.chartService.createLineChart(
        this.canvasLineChart,
        payload
      );
    }
  }

  public onFilterBarChart(): void {
    let payload: any = {
      labels: [],
      details: [],
    };
    const period = this.statsForm.get('period')?.value;

    if (period) {
      if (period === this.periods[0].id) {
        payload.labels = ['Annual'];
      } else if (period === this.periods[1].id) {
        payload.labels = ['1', '2', '3', '4'];
      } else if (period === this.periods[2].id) {
        payload.labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',];
      }
    }
    let groups = _.groupBy(this.graphFilterForm.get('filter2')?.value, 'NAME');
    Object.keys(groups).map((key: any): void => {
      let data: any = [];
      payload.labels.map((index: number): void => {
        let itemStat = this.data.statistics[index];
        let itemId = groups[key][0].NAME;
        if (itemId === 'User') {
          data.push(itemStat.total_user);
        } else if (itemId === 'Athlete') {
          data.push(itemStat.total_athlete);
        } else if (itemId === 'Lactate') {
          data.push(itemStat.total_test_la);
        } else if (itemId === 'PPD') {
          data.push(itemStat.total_test_map);
        } else if (itemId === 'Manual') {
          data.push(itemStat.total_test_manual);
        } else if (itemId === 'Virtual') {
          data.push(itemStat.total_test_virtual);
        } else if (itemId === 'Event') {
          data.push(itemStat.total_test_event);
        } else if (itemId === 'Result') {
          data.push(itemStat.total_test_result);
        }
      });
      let detail = {
        label: key,
        data: data,
        position: 'LEFT',
      };
      payload.details.push(detail);
    });
    let pricingDetail = {
      label: 'Total Price',
      data: payload.labels.map((label: any, index: number) => {
        return this.data.statistics[index].total_price;
      }),
      position: 'RIGHT',
    };
    payload.details.push(pricingDetail);
    if (this.barChart) {
      this.barChart.destroy();
    }

    if (this.canvasBarChart) {
      this.barChart = this.chartService.createBarChart(
        this.canvasBarChart,
        payload
      );
    }
  }

  public onFilterStatsTable(): void {
    const period = this.statsForm.get('period')?.value;
    const year = this.statsForm.get('year')?.value;
    const statsByYear = this.statisticsData.filter(
      (stat: any): boolean =>
        year === moment(stat.invoice__start_date ? stat.invoice__start_date : 0).format('YYYY'));
    if (year === 0 && period === 0) {
      this.data.statistics = this.statisticsData;
      this.onFilterBarChart();

      return;
    }
    let statistics = [];

    if (period && period === AppConstants.PERIOD_ANALYTICS[0].id) {
      statistics.push(this.calculateTotals(statsByYear, 1));
    } else if (period && period === AppConstants.PERIOD_ANALYTICS[1].id) {
      let firstQuarterList: any[] = [];
      let secondQuarterList: any[] = [];
      let thirdQuarterList: any[] = [];
      let fourthQuarterList: any[] = [];
      statsByYear.map((stat: any): void => {
        let invoiceStartDate = moment(stat.invoice__start_date).utc();

        if (invoiceStartDate.quarter() === 1) {
          firstQuarterList.push(stat);
        } else if (invoiceStartDate.quarter() === 2) {
          secondQuarterList.push(stat);
        } else if (invoiceStartDate.quarter() === 3) {
          thirdQuarterList.push(stat);
        } else if (invoiceStartDate.quarter() === 4) {
          fourthQuarterList.push(stat);
        }
      });
      statistics.push(this.calculateTotals(firstQuarterList, 1));
      statistics.push(this.calculateTotals(secondQuarterList, 2));
      statistics.push(this.calculateTotals(thirdQuarterList, 3));
      statistics.push(this.calculateTotals(fourthQuarterList, 4));
    } else if (period && period === AppConstants.PERIOD_ANALYTICS[2].id) {
      let statisticDatasourceFilter = this.statisticsData.filter(
        (item: any): any | null => {
          let itemYear = moment(item.invoice__start_date).format('YYYY');
          if (itemYear === year) {
            return item;
          }

          return null;
        }
      );

      const statictisData = this.groupDateBy(statisticDatasourceFilter, 'month');
      let hasJan = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-01-01');
      let hasFeb = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-02-01');
      let hasMar = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-03-01');
      let hasApril = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-04-01');
      let hasMay = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-05-01');
      let hasJune = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-06-01');
      let hasJuly = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-07-01');
      let hasAug = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-08-01');
      let hasSep = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-09-01');
      let hasOct = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-10-01');
      let hasNov = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-11-01');
      let hasDec = statictisData.filter((item: any): boolean => item.invoice__start_date === year + '-12-01');
      statistics.push(this.calculateTotals(hasJan, 1));
      statistics.push(this.calculateTotals(hasFeb, 2));
      statistics.push(this.calculateTotals(hasMar, 3));
      statistics.push(this.calculateTotals(hasApril, 4));
      statistics.push(this.calculateTotals(hasMay, 5));
      statistics.push(this.calculateTotals(hasJune, 6));
      statistics.push(this.calculateTotals(hasJuly, 7));
      statistics.push(this.calculateTotals(hasAug, 8));
      statistics.push(this.calculateTotals(hasSep, 9));
      statistics.push(this.calculateTotals(hasOct, 10));
      statistics.push(this.calculateTotals(hasNov, 11));
      statistics.push(this.calculateTotals(hasDec, 12));
    } else {
      this.data.statistics = this.statisticsData.filter((item: any) => {
        let statisticYear: string = moment(item.invoice__start_date).format('YYYY');
        if (year != 0 && period != 0) {
          return year == statisticYear && period == item.invoice__period_type;
        } else if (year != 0) {
          return year == statisticYear;
        } else if (period != 0) {
          return period == item.invoice__period_type;
        }

        return false;
      });
    }
    this.data.statistics = statistics;
    this.onFilterBarChart();
  }

  public groupDateBy(elements: any, duration: any): any {
    let data = _.cloneDeep(elements);

    return data.map((elem: any) => {
      elem.invoice__start_date = moment(elem.invoice__start_date)
        .startOf(duration)
        .format('YYYY-MM-DD');
      return elem;
    });
  }
  public calculateTotals(items: any, index: number) {
    let totalAthlete: number = 0,
      totalTestEvent: number = 0,
      totalTestLA: number = 0,
      totalTestManual: number = 0,
      totalTestPPD: number = 0,
      totalTestResult: number = 0,
      totalTestVirtual: number = 0,
      totalUser: number = 0,
      totalPrice: number = 0,
      totalLinkItem: number = 0;
    if (items) {
      items.map((item: any): void => {
        totalPrice = totalPrice + item.invoice__amount;
        totalAthlete = totalAthlete + item.total_athlete;
        totalTestEvent = totalTestEvent + item.total_test_event;
        totalTestLA = totalTestLA + item.total_test_la;
        totalTestManual = totalTestManual + item.total_test_manual;
        totalTestPPD = totalTestPPD + item.total_test_map;
        totalTestResult = totalTestResult + item.total_test_result;
        totalTestVirtual = totalTestVirtual + item.total_test_virtual;
        totalUser = totalUser + item.total_user;
        totalLinkItem = totalLinkItem + item.total_link_item;
      });

      return {
        index: index,
        total_price: totalPrice,
        total_athlete: totalAthlete,
        total_test_event: totalTestEvent,
        total_test_la: totalTestLA,
        total_test_manual: totalTestManual,
        total_test_map: totalTestPPD,
        total_test_result: totalTestResult,
        total_test_virtual: totalTestVirtual,
        total_user: totalUser,
        total_link_item: totalLinkItem,
      };
    }

    return {
      index: 1,
      total_price: 0,
      total_athlete: 0,
      total_test_event: 0,
      total_test_la: 0,
      total_test_manual: 0,
      total_test_map: 0,
      total_test_result: 0,
      total_test_virtual: 0,
      total_user: 0,
      total_link_item: 0,
    };
  }

  public onSelectProduct(value: any): void {
    this.form.get('product_purchased')?.setValue(value);
  }
  public onSelectPlan(value: any): void {
    this.form.get('plan_purchased')?.setValue(value);
  }

  public onSelectFeature(value: any): void {
    this.form.get('features')?.setValue(value);
  }

  public onSelectPaymentMethod(value: any): void {
    this.form.get('payment_methods')?.setValue(value);
  }
  public onSelectItemBarChart(value: any): void {
    this.graphFilterForm.get('filter2')?.setValue(value);
    this.onFilterBarChart();
  }
  public onSelectItemLineChart(value: any): void {
    this.graphFilterForm.get('filter1')?.setValue(value);
    this.getOrganizationLineGraph();
  }

  public displayDateColumn(data: any, utc: boolean = false): string {
    if (data) {
      let d = moment(data);
      if (utc) {
        return d.utc().format('DD.MM.YYYY HH:mm');
      }
      return d.format('DD.MM.YYYY HH:mm');
    }

    return '';
  }

  public showLatestDate(data: any, numberDays: number, utc = false): string {
    let d = moment(data).subtract(numberDays, 'days');

    if (utc) {
      return d.utc().format('DD.MM.YYYY HH:mm');
    }

    return d.format('DD.MM.YYYY HH:mm');
  }

  public getCurrentPaymentMethod(paymentMethod: string): string {
    // Invoices use 'card' for credit card payments. Organizations use 'credit_card'
    const organizationPaymentMethod: string = paymentMethod === 'card' ? 'credit_card' : paymentMethod;
    const pm = this.paymentMethods.find(
      (payment: any): boolean => payment.value === organizationPaymentMethod);
    return pm ? pm.name : '';
  }

  public getCredit(data: number): string {
    return ((data && data > 0 ? data : 0) +' ' + (this.organization.organization_currency_text || ''));
  }
  public getDiscountAmount(plan: any): string {
    if (plan?.invoice?.discount_expiration) {
      if (plan?.purchase_discounts?.length) {
        const discount = plan.purchase_discounts[0];
        const unit = discount?.discount_type == 1 ? '%' : this.organization.organization_currency_text || '';

        return ((discount.discount_value && discount.discount_value > 0 ? discount.discount_value : 0) + ' ' + unit);
      }
    }

    return '';
  }

  public getAccumulatedTests(plan: ManageOrganizationDto): number {
    return this.planPricingService.getAccumulatedTests(plan);
  }

  public getMultipleOrOnce(invoicePlans: any, item: number): any {
    if (invoicePlans) {
      const purchase_lot = invoicePlans?.purchase_lots[0];
      if (purchase_lot) {
        switch (item) {
          case AppConstants.ITEMS[0].id:
            return purchase_lot.is_once_user ? '(O)' : '(M)';
          case AppConstants.ITEMS[1].id:
            return purchase_lot.is_once_athlete ? '(O)' : '(M)';
          case AppConstants.ITEMS[2].id:
            return purchase_lot.is_once_la ? '(O)' : '(M)';
          case AppConstants.ITEMS[3].id:
            return purchase_lot.is_once_map ? '(O)' : '(M)';
          case AppConstants.ITEMS[4].id:
            return purchase_lot.is_once_manual ? '(O)' : '(M)';
          case AppConstants.ITEMS[5].id:
            return purchase_lot.is_once_virtual ? '(O)' : '(M)';
          case AppConstants.ITEMS[6].id:
            return purchase_lot.is_once_event ? '(O)' : '(M)';
          case AppConstants.ITEMS[7].id:
            return purchase_lot.is_once_result ? '(O)' : '(M)';
          case 9:
            return purchase_lot.is_once_link_item ? '(O)' : '(M)';
          default:
            return '';
        }
      }
    }
  }

  public getAlternativeItemData(item: any): string {
    let strAlternative: string = '';
    if (item && item.alternative_item) {
      let alternatives = item.alternative_item.split('|');
      alternatives.map((itemAlt: any): void => {
        let itemAltInt: number = parseInt(itemAlt);
        if (itemAltInt === AppConstants.ITEMS_INCLUDED[0].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[0].name;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[1].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[1].name;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[2].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[2].name;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[3].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[3].name;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[4].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[4].name;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[5].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[5].name;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[6].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[6].name;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[7].id) {
          strAlternative = strAlternative + ', ' + AppConstants.ITEMS_INCLUDED[7].name;
        }
      });
    }

    return strAlternative.substring(1);
  }

  public getAlternativeItemTotal(plan: any, item: any): string {
    let total: number = 0;
    let max: number = 0;
    if (item && item.alternative_item) {
      let alternatives = item.alternative_item.split('|');
      alternatives.map((itemAlt: any): void => {
        let itemAltInt = parseInt(itemAlt);
        if (itemAltInt === AppConstants.ITEMS_INCLUDED[0].id) {
          total = total + plan.total_user;
          max = max + plan.max_user;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[1].id) {
          total = total + plan.total_athlete;
          max = max + plan.max_athlete;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[2].id) {
          total = total + plan.total_test_la;
          max = max + plan.max_test_la;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[3].id) {
          total = total + plan.total_test_map;
          max = max + plan.max_test_map;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[4].id) {
          total = total + plan.total_test_manual;
          max = max + plan.max_test_manual;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[5].id) {
          total = total + plan.total_test_virtual;
          max = max + plan.max_test_virtual;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[6].id) {
          total = total + plan.total_test_event;
          max = max + item.max_test_event;
        } else if (itemAltInt === AppConstants.ITEMS_INCLUDED[7].id) {
          total = total + plan.total_test_result;
          max = max + plan.max_test_result;
        }
      });
    }
    if (plan.is_accumulated && plan.accumulated_tests != null) {
      // Any list is fine - they should all have the same length
      const accumulatedTestCount = plan.accumulated_tests[Object.keys(plan.accumulated_tests)[0]].length;
      max = max * accumulatedTestCount;
    }

    return `(Used ${total} / ${max})`;
  }

  public showInputField(element: any): boolean {
    return (element.include_items?.includes('1') != undefined && element.include_items.includes('1'));
  }
  public close(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    let model: any = {
      is_solvent: this.getFormSolvent,
      purchase_removes: this.data.purchase_removes,
    };

    model.account_type_id = this.form.get('account_type')?.value;

    const purchasedProductIds = this.data.products.map(
      (product: any) => product.id
    );

    const purchasedPlanIds = this.data.plans.map(
      (plan: any) => plan.planId
    );

    const selectedProductIds = this.form.get('product_purchased')?.value.map((product: any) => product.value);

    const selectedPlansIds = this.form.get('plan_purchased')?.value.map((plan: any) => plan.value);

    let productIds = selectedProductIds.filter((id: number) => {
      return !purchasedProductIds.includes(id);
    });

    const invoicePlanIds = Array.from(
      new Set(this.data.invoicePlans.filter((item: any) => !item.is_additional).map((item: any) => item.plan))
    );

    const invoiceProductIds = Array.from(
      new Set(this.data.invoiceProducts.filter((item: any) => !item.is_additional).map((item: any) => item.product))
    );
    const filteredPlans = this.form.get('plan_purchased')?.value.filter((plan: any) => !invoicePlanIds.includes(plan.value));
    const filteredProducts = this.form.get('product_purchased')?.value.filter((product: any) => !invoiceProductIds.includes(product.value));

    model.plans = filteredPlans.map((plan: any) => plan.value);
    model.features = this.form.get('features')?.value.map((plan: any) => plan.value);
    model.products = filteredProducts.map((product: any) => product.value);
    model.paid_method = this.form.get('payment_methods')?.value.map((paymentMethod: any) => paymentMethod.value).join(',');
    model.is_check_invoice = this.getEnablePaymentMethod;
    model.update_plans = this.formArray.value;
    model.potential_athlete_number = this.analyticsOfPotentialForm.get('potential_athletes')?.value;
    model.potential_test_number = this.analyticsOfPotentialForm.get('potential_tests')?.value;
    model.is_multiple_exceed_invoice =  this.getFormIsMultipleExceedInvoice;
    model.is_force_update_payment_method = this.getFormIsForceUpdatePaymentMethod;

    if (this.orgId) {
      this.update(this.orgId, model);
    }
  }

  public update(id: number, model: any): void {
    this.apiService.put('organizations/' + id + '/update/', model).subscribe(
      (res: Object): void => {
        this.dialogRef.close(res);
        this.snackBar.open('Item updated successfully', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      },
      (error): void => {
        this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    );
  }

  public showWarning(): void {
    if (!this.getFormIsMultipleExceedInvoice) {
      const dialogData: ConfirmDialogModel = new ConfirmDialogModel(
        'Warning',
        'Turn on the toggle will trigger the next billing period for multiple exceed item. It will be generated on every plan\'s billing period automatically. Once you turn on you can not revert.',
        'OK',
        '',
        true
      );

      this.dialog.open(ConfirmDialogComponent, {
        width: '400px',
        data: dialogData,
        disableClose: true,
      });
    }
  }

  public get getEnablePaymentMethod(): UntypedFormControl {
    return this.form.get('enable_payment_methods')?.value;
  }

  public get getFormSolvent(): UntypedFormControl {
    return this.form.get('solvent')?.value;
  }

  public get getFormIsMultipleExceedInvoice(): UntypedFormControl {
    return this.form.get('is_multiple_exceed_invoice')?.value;
  }

  public get getFormIsForceUpdatePaymentMethod(): UntypedFormControl {
    return this.form.get('is_force_update_payment_method')?.value;
  }

  public get getFormNameOrganization(): UntypedFormControl {
    return this.form.get('name')?.value;
  }

  public get getFormEmailOrganization(): UntypedFormControl {
    return this.form.get('email')?.value;
  }

  public get getFormHeadOrganization(): UntypedFormControl {
    return this.form.get('head')?.value;
  }

  public get getFormPhoneOrganization(): UntypedFormControl {
    return this.form.get('phone')?.value;
  }

  public get getFormWebsiteOrganization(): UntypedFormControl {
    return this.form.get('website')?.value;
  }

  public get getFormAddressOrganization(): UntypedFormControl {
    return this.form.get('address')?.value;
  }

  public get getFormVATOrganization(): UntypedFormControl {
    return this.form.get('vat_number')?.value;
  }

  public get getFormFacebookOrganization(): UntypedFormControl {
    return this.form.get('facebook')?.value;
  }

  public get getFormInstagramOrganization(): UntypedFormControl {
    return this.form.get('instagram')?.value;
  }

  public get getFormPhotoOrganization(): UntypedFormControl {
    const defaultPhotoURL = this.isDarkMode
      ? "assets/images/app-logo-header-dark-theme.png"
      : "assets/images/app-logo-header.png";
    return this.form.get('photo')?.value ?? defaultPhotoURL;
  }
}
