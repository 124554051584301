import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {
  DeactivateAccountDialogComponent
} from "@shared/dialogs/deactivate-account-dialog/deactivate-account-dialog.component";

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss']
})
export class DeleteAccountDialogComponent {
  public title: string;
  public message: string;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: any, public dialogRef: MatDialogRef<DeactivateAccountDialogComponent>,) {
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
  }

  public onDeactivate(): void {
    this.dialogRef.close('deactivate');
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onDelete(): void {
    this.dialogRef.close(true);
  }
}
