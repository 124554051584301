import { IRightList } from '../rights-management-dialog/rights-management-dialog.component';

export const RightConstants = {
  DEFAULT_RIGHTS: {
    athletes_list: true,
    athletes_create: true,
    athletes_update: true,
    athletes_delete: true,
    athletes_list_all: true,
    athletes_assign_coach: true,
    files_list: true,
    files_create: true,
    files_update: true,
    files_delete: true,
    test_list: true,
    test_create_critical_power_test: true,
    test_create_lactate: true,
    test_manual_test: true,
    test_create_virtual: true,
    test_delete: true,
    test_advanced_body_composition_1_list: true,
    test_advanced_body_composition_2_list: true,
    test_energy_demand_list: true,
    test_condition_field_list: false,
    test_o2_kinetics_list: true,
    test_lactate_oxidation_list: true,
    test_constants_list: true,
    test_calculated_body_composition_list: true,
    metabolic_profiling_single_athlete_list: true,
    metabolic_profiling_body_composition_list: true,
    metabolic_profiling_capacities_list: true,
    metabolic_profiling_metabolic_profile_list: true,
    metabolic_profiling_metabolic_fingerprint_list: true,
    metabolic_profiling_performance_development_list: true,
    metabolic_profiling_trainings_zones_list: true,
    metabolic_profiling_test_data_list: true,
    metabolic_profiling_create_report: true,
    metabolic_profiling_multi_athlete: true,
    metabolic_profiling_metabolic_power: true,
    metabolic_profiling_economy: true,
    metabolic_profiling_time_to_depletion: true,
    metabolic_profiling_recovery_matrix: true,
    metabolic_profiling_aerobic_anaerobic: true,
    metabolic_profiling_fat_carbohydrate: true,
    metabolic_profiling_speed_relationships: false,
    dynamic_simulation_list: true,
    dynamic_simulation_event_list: true,
    dynamic_simulation_result_list: true,
    dynamic_simulation_result_export: true,
    can_manage_sport_settings: false,
    can_access_athlete_categories: false,
    teams_n_competitions_list: false,
    teams_list: true,
    teams_create: true,
    teams_update: true,
    teams_delete: true,
    competitions_list: true,
    competitions_create: true,
    competitions_update: true,
    competitions_delete: true,
    participations_list: true,
    participations_update: true,
    loads_list: true,
    admin_list: true,
    admin_org_settings_list: true,
    admin_users_list: true,
    admin_users_create: true,
    admin_users_delete: true,
    admin_users_update: true,
    admin_tags_list: true,
    admin_tags_delete: true,
    admin_rights_list: false,
    admin_rights_create: false,
    admin_rights_update: false,
    admin_rights_delete: false,
    admin_rights_copy: false,
    admin_devices_list: true,
    admin_devices_update: true,
    admin_devices_delete: true,
    admin_view_all_history_log: false,
    tracking_api_access: true,
    training_zone_builder: false,
    courses_panel: false,
    subscription_view_plan: false,
    subscription_view_product: false,
    subscription_view_invoice_page: false,
    subscription_view_subscription_page: false,
    subscription_view_payment_method_page: false,
    report_template_create: true,
    college_list: false,
    performance_projection_page: false,
    athletes_is_active_athlete: true,
    test_body_composition_list: true,
    admin_system_settings_list: true,
    admin_org_list: true,
    admin_org_create: true,
    admin_org_delete: true,
    admin_org_update: true,
    admin_org_restore: true,
    admin_org_set_license: true,
    admin_org_update_statistic: true,
    admin_users_restore: true,
    admin_sports_list: true,
    admin_sports_create: true,
    admin_sports_delete: true,
    admin_sports_update: true,
    admin_history_log_list: true,
  },
  RIGHTS_LIST_DISPLAY: [
    {
      name: 'Athletes',
      options: [],
      subGroup: [
        {
          name: 'Athletes Overview',
          options: [
            {
              name: 'View Overview',
              path: 'athletes_list',
            },
            {
              name: 'Create Athlete',
              path: 'athletes_create',
            },
            {
              name: 'Edit Athlete',
              path: 'athletes_update',
            },
            {
              name: 'Delete Athlete',
              path: 'athletes_delete',
            },
            {
              name: 'View all Athlete',
              path: 'athletes_list_all',
            },
          ],
        },
        {
          name: 'Add/Edit Athlete',
          options: [
            {
              name: 'Assign Coach',
              path: 'athletes_assign_coach',
            },
          ],
        },
      ],
    },
    {
      name: 'Files',
      subGroup: [
        {
          name: 'Files Overview',
          options: [
            {
              name: 'View Overview',
              path: 'files_list',
            },
            {
              name: 'Upload File',
              path: 'files_create',
            },
            {
              name: 'Edit File',
              path: 'files_update',
            },
            {
              name: 'Delete File',
              path: 'files_delete',
            },
          ],
        },
      ],
    },
    {
      name: 'Tests',
      subGroup: [
        {
          name: 'Test Overview',
          options: [
            {
              name: 'View Overview',
              path: 'test_list',
            },
            {
              name: 'Create PPD Test',
              path: 'test_create_critical_power_test',
            },
            {
              name: 'Create Lactate Test',
              path: 'test_create_lactate',
            },
            {
              name: 'Create Manual Test',
              path: 'test_manual_test',
            },
            {
              name: 'Create Virtual',
              path: 'test_create_virtual',
            },
            {
              name: 'Delete Test',
              path: 'test_delete',
            },
          ],
        },
        {
          name: 'Test Creation',
          options: [
            {
              name: 'View Advanced Body composition I Fields',
              path: 'test_advanced_body_composition_1_list',
            },
            {
              name: 'View Advanced Body composition II Fields',
              path: 'test_advanced_body_composition_2_list',
            },
            {
              name: 'View Energy demand Fields',
              path: 'test_energy_demand_list',
            },
            {
              name: 'View Condition Fields',
              path: 'test_condition_field_list',
            },
            {
              name: 'View O2  kinetics Fields',
              path: 'test_o2_kinetics_list',
            },
            {
              name: 'View Lactate Oxidation Fields',
              path: 'test_lactate_oxidation_list',
            },
            {
              name: 'View Constants Fields',
              path: 'test_constants_list',
            },
            {
              name: 'View Calculated Body Composition Fields',
              path: 'test_calculated_body_composition_list',
            },
          ],
        },
      ],
    },
    {
      name: 'Metabolic Profiling',
      subGroup: [
        {
          name: 'Single Athlete Metabolic Profiling',
          options: [
            {
              name: 'View Single Athlete Metabolic Profiling',
              path: 'metabolic_profiling_single_athlete_list',
            },
            {
              name: 'View Body Composition',
              path: 'metabolic_profiling_body_composition_list',
            },
            {
              name: 'View Physiological Performance Benchmarks',
              path: 'metabolic_profiling_capacities_list',
            },
            {
              name: 'View Load Characteristics',
              path: 'metabolic_profiling_metabolic_profile_list',
            },
            {
              name: 'View Metabolic Fingerprint',
              path: 'metabolic_profiling_metabolic_fingerprint_list',
            },
            {
              name: 'View Performance Development',
              path: 'metabolic_profiling_performance_development_list',
            },
            {
              name: 'View Trainings Zones',
              path: 'metabolic_profiling_trainings_zones_list',
            },
            {
              name: 'View Test Data',
              path: 'metabolic_profiling_test_data_list',
            },
            {
              name: 'Create Report',
              path: 'metabolic_profiling_create_report',
            },

            {
              name: 'View Metabolic Power',
              path: 'metabolic_profiling_metabolic_power',
            },
            {
              name: 'View Economy',
              path: 'metabolic_profiling_economy',
            },
            {
              name: 'View Time To Depletion',
              path: 'metabolic_profiling_time_to_depletion',
            },
            {
              name: 'View Recovery Matrix',
              path: 'metabolic_profiling_recovery_matrix',
            },
            {
              name: 'View Aerobic & Anaerobic',
              path: 'metabolic_profiling_aerobic_anaerobic',
            },
            {
              name: 'View Fat & Carbohydrate',
              path: 'metabolic_profiling_fat_carbohydrate',
            },
            {
              name: 'View Speed Relationships',
              path: 'metabolic_profiling_speed_relationships',
            },
            {
              name: 'View Performance Projection Page',
              path: 'performance_projection_page',
            },
          ],
        },
        {
          name: 'Multi Athlete Metabolic Profiling',
          options: [
            {
              name: 'View Multi Athlete Metabolic Profiling',
              path: 'metabolic_profiling_multi_athlete',
            },
          ],
        },
      ],
    },
    {
      name: 'Dynamic Simulation',
      options: [
        {
          name: 'View Dynamic Simulation',
          path: 'dynamic_simulation_list',
        },
      ],
      subGroup: [
        {
          name: 'Event Overview',
          options: [
            {
              name: 'View Overview',
              path: 'dynamic_simulation_event_list',
            },
          ],
        },
        {
          name: 'Result Overview',
          options: [
            {
              name: 'View Overview',
              path: 'dynamic_simulation_result_list',
            },
            {
              name: 'Export result',
              path: 'dynamic_simulation_result_export',
            },
          ],
        },
      ],
    },
    {
      name: 'Sports',
      options: [
        {
          name: 'Can manage sport settings',
          path: 'can_manage_sport_settings',
        },
        {
          name: 'Can access athlete categories',
          path: 'can_access_athlete_categories',
        },
      ],
      subGroup: [
        {
          name: 'Use Sport',
          options: [],
        },
      ],
    },
    {
      name: 'Teams & Competitions',
      options: [
        {
          name: 'View Teams & Competitions',
          path: 'teams_n_competitions_list',
        },
      ],
      subGroup: [
        {
          name: 'Team',
          options: [
            {
              name: 'View Overview',
              path: 'teams_list',
            },
            {
              name: 'Create Team',
              path: 'teams_create',
            },
            {
              name: 'Edit Team',
              path: 'teams_update',
            },
            {
              name: 'Delete Team',
              path: 'teams_delete',
            },
          ],
        },
        {
          name: 'Competition',
          options: [
            {
              name: 'View Overview',
              path: 'competitions_list',
            },
            {
              name: 'Create Competition',
              path: 'competitions_create',
            },
            {
              name: 'Edit Competition',
              path: 'competitions_update',
            },
            {
              name: 'Delete Competition',
              path: 'competitions_delete',
            },
          ],
        },
        {
          name: 'Participation',
          options: [
            {
              name: 'View Overview',
              path: 'participations_list',
            },
            {
              name: 'Update Participation',
              path: 'participations_update',
            },
          ],
        },
        {
          name: 'Load Analysis',
          options: [
            {
              name: 'View Overview',
              path: 'loads_list',
            },
          ],
        },
      ],
    },
    {
      name: 'Admin',
      options: [
        {
          name: 'View Admin',
          path: 'admin_list',
        },
      ],
      subGroup: [
        {
          name: 'Settings',
          options: [
            {
              name: 'View Org Settings',
              path: 'admin_org_settings_list',
            },
          ],
        },
        {
          name: 'Users',
          options: [
            {
              name: 'View Users',
              path: 'admin_users_list',
            },
            {
              name: 'Add User',
              path: 'admin_users_create',
            },
            {
              name: 'Delete User',
              path: 'admin_users_delete',
            },
            {
              name: 'Edit User',
              path: 'admin_users_update',
            },
          ],
        },
        {
          name: 'Tags',
          options: [
            {
              name: 'View Tags',
              path: 'admin_tags_list',
            },
            {
              name: 'Delete Tags',
              path: 'admin_tags_delete',
            },
          ],
        },
        {
          name: 'Rights Management',
          options: [
            {
              name: 'View Roles',
              path: 'admin_rights_list',
            },
            {
              name: 'Add Role',
              path: 'admin_rights_create',
            },
            {
              name: 'Edit Role',
              path: 'admin_rights_update',
            },
            {
              name: 'Delete Role',
              path: 'admin_rights_delete',
            },
            {
              name: 'Copy Role',
              path: 'admin_rights_copy',
            },
          ],
        },
        {
          name: 'Device Management',
          options: [
            {
              name: 'View Devices',
              path: 'admin_devices_list',
            },
            {
              name: 'Edit Device',
              path: 'admin_devices_update',
            },
            {
              name: 'Delete Device',
              path: 'admin_devices_delete',
            },
          ],
        },
        {
          name: 'History Log Management',
          options: [
            {
              name: 'View All History Log',
              path: 'admin_view_all_history_log',
            },
          ],
        },
        {
          name: 'Tracking API Management',
          options: [
            {
              name: 'View API Tracking',
              path: 'tracking_api_access',
            },
          ],
        },
        {
          name: 'Training Zone Builder',
          options: [
            {
              name: 'View Training Zone Builder',
              path: 'training_zone_builder',
            },
          ],
        },
        {
          name: 'Courses Panel',
          options: [
            {
              name: 'View Courses Panel',
              path: 'courses_panel',
            },
          ],
        },
      ],
    },
    {
      name: 'Plan & Subscription',
      options: [
        {
          name: 'View Plan',
          path: 'subscription_view_plan',
        },
        {
          name: 'View Product',
          path: 'subscription_view_product',
        },
        {
          name: 'View Invoice Page',
          path: 'subscription_view_invoice_page',
        },
        {
          name: 'View My Subscription Page',
          path: 'subscription_view_subscription_page',
        },
        {
          name: 'View My Payment Method Page',
          path: 'subscription_view_payment_method_page',
        },
      ],
    },
    {
      name: 'Report Templates',
      options: [
        {
          name: 'Create Template',
          path: 'report_template_create'
        }
      ]
    },
    {
      name: 'College',
      options: [
        {
          name: 'College',
          path: 'college_list'
        }
      ]
    }
  ] as Array<IRightList>,
  RIGHTS_LIST_DISPLAY_PRODUCT_DIALOGS: [
    {
      name: 'Athletes',
      options: [],
      subGroup: [
        {
          name: 'Athletes Overview',
          options: [
            {
              name: 'View Overview',
              path: 'athletes_list',
            },
            {
              name: 'Create Athlete',
              path: 'athletes_create',
            },
            {
              name: 'Edit Athlete',
              path: 'athletes_update',
            },
            {
              name: 'Delete Athlete',
              path: 'athletes_delete',
            },
            {
              name: 'View all Athlete',
              path: 'athletes_list_all',
            },
          ],
        },
        {
          name: 'Add/Edit Athlete',
          options: [
            {
              name: 'Assign Coach',
              path: 'athletes_assign_coach',
            },
          ],
        },
      ],
    },
    {
      name: 'Files',
      subGroup: [
        {
          name: 'Files Overview',
          options: [
            {
              name: 'View Overview',
              path: 'files_list',
            },
            {
              name: 'Upload File',
              path: 'files_create',
            },
            {
              name: 'Edit File',
              path: 'files_update',
            },
            {
              name: 'Delete File',
              path: 'files_delete',
            },
          ],
        },
      ],
    },
    {
      name: 'Tests',
      subGroup: [
        {
          name: 'Test Overview',
          options: [
            {
              name: 'View Overview',
              path: 'test_list',
            },
            {
              name: 'Create PPD Test',
              path: 'test_create_critical_power_test',
            },
            {
              name: 'Create Lactate Test',
              path: 'test_create_lactate',
            },
            {
              name: 'Create Manual Test',
              path: 'test_manual_test',
            },
            {
              name: 'Create Virtual',
              path: 'test_create_virtual',
            },
            {
              name: 'Delete Test',
              path: 'test_delete',
            },
          ],
        },
        {
          name: 'Test Creation',
          options: [
            {
              name: 'View Advanced Body composition I Fields',
              path: 'test_advanced_body_composition_1_list',
            },
            {
              name: 'View Advanced Body composition II Fields',
              path: 'test_advanced_body_composition_2_list',
            },
            {
              name: 'View Energy demand Fields',
              path: 'test_energy_demand_list',
            },
            {
              name: 'View O2  kinetics Fields',
              path: 'test_o2_kinetics_list',
            },
            {
              name: 'View Lactate Oxidation Fields',
              path: 'test_lactate_oxidation_list',
            },
            {
              name: 'View Constants Fields',
              path: 'test_constants_list',
            },
            {
              name: 'View Calculated Body Composition Fields',
              path: 'test_calculated_body_composition_list',
            },
          ],
        },
      ],
    },
    {
      name: 'Metabolic Profiling',
      subGroup: [
        {
          name: 'Single Athlete Metabolic Profiling',
          options: [
            {
              name: 'View Single Athlete Metabolic Profiling',
              path: 'metabolic_profiling_single_athlete_list',
            },
            {
              name: 'View Body Composition',
              path: 'metabolic_profiling_body_composition_list',
            },
            {
              name: 'View Physiological Performance Benchmarks',
              path: 'metabolic_profiling_capacities_list',
            },
            {
              name: 'View Load Characteristics',
              path: 'metabolic_profiling_metabolic_profile_list',
            },
            {
              name: 'View Metabolic Fingerprint',
              path: 'metabolic_profiling_metabolic_fingerprint_list',
            },
            {
              name: 'View Performance Development',
              path: 'metabolic_profiling_performance_development_list',
            },
            {
              name: 'View Trainings Zones',
              path: 'metabolic_profiling_trainings_zones_list',
            },
            {
              name: 'View Test Data',
              path: 'metabolic_profiling_test_data_list',
            },
            {
              name: 'Create Report',
              path: 'metabolic_profiling_create_report',
            },
            {
              name: 'View Performance Projection Page',
              path: 'performance_projection_page',
            },
          ],
        },
        {
          name: 'Multi Athlete Metabolic Profiling',
          options: [
            {
              name: 'View Multi Athlete Metabolic Profiling',
              path: 'metabolic_profiling_multi_athlete',
            },
          ],
        },
      ],
    },
    {
      name: 'Dynamic Simulation',
      options: [
        {
          name: 'View Dynamic Simulation',
          path: 'dynamic_simulation_list',
        },
      ],
      subGroup: [
        {
          name: 'Event Overview',
          options: [
            {
              name: 'View Overview',
              path: 'dynamic_simulation_event_list',
            },
          ],
        },
        {
          name: 'Result Overview',
          options: [
            {
              name: 'View Overview',
              path: 'dynamic_simulation_result_list',
            },
            {
              name: 'Export result',
              path: 'dynamic_simulation_result_export',
            },
          ],
        },
      ],
    },
    {
      name: 'Sports',
      subGroup: [
        {
          name: 'Use Sport',
          options: [],
        },
      ],
    },
    {
      name: 'Teams & Competitions',
      options: [
        {
          name: 'View Teams & Competitions',
          path: 'teams_n_competitions_list',
        },
      ],
      subGroup: [
        {
          name: 'Team',
          options: [
            {
              name: 'View Overview',
              path: 'teams_list',
            },
            {
              name: 'Create Team',
              path: 'teams_create',
            },
            {
              name: 'Edit Team',
              path: 'teams_update',
            },
            {
              name: 'Delete Team',
              path: 'teams_delete',
            },
          ],
        },
        {
          name: 'Competition',
          options: [
            {
              name: 'View Overview',
              path: 'competitions_list',
            },
            {
              name: 'Create Competition',
              path: 'competitions_create',
            },
            {
              name: 'Edit Competition',
              path: 'competitions_update',
            },
            {
              name: 'Delete Competition',
              path: 'competitions_delete',
            },
          ],
        },
        {
          name: 'Participation',
          options: [
            {
              name: 'View Overview',
              path: 'participations_list',
            },
            {
              name: 'Update Participation',
              path: 'participations_update',
            },
          ],
        },
        {
          name: 'Load Analysis',
          options: [
            {
              name: 'View Overview',
              path: 'loads_list',
            },
          ],
        },
      ],
    },
    {
      name: 'Admin',
      options: [
        {
          name: 'View Admin',
          path: 'admin_list',
        },
      ],
      subGroup: [
        {
          name: 'Settings',
          options: [
            {
              name: 'View Org Settings',
              path: 'admin_org_settings_list',
            },
          ],
        },
        {
          name: 'Users',
          options: [
            {
              name: 'View Users',
              path: 'admin_users_list',
            },
            {
              name: 'Add User',
              path: 'admin_users_create',
            },
            {
              name: 'Delete User',
              path: 'admin_users_delete',
            },
            {
              name: 'Edit User',
              path: 'admin_users_update',
            },
          ],
        },
        {
          name: 'Tags',
          options: [
            {
              name: 'View Tags',
              path: 'admin_tags_list',
            },
            {
              name: 'Delete Tags',
              path: 'admin_tags_delete',
            },
          ],
        },
        {
          name: 'Rights Management',
          options: [
            {
              name: 'View Roles',
              path: 'admin_rights_list',
            },
            {
              name: 'Add Role',
              path: 'admin_rights_create',
            },
            {
              name: 'Edit Role',
              path: 'admin_rights_update',
            },
            {
              name: 'Delete Role',
              path: 'admin_rights_delete',
            },
            {
              name: 'Copy Role',
              path: 'admin_rights_copy',
            },
          ],
        },
        {
          name: 'Device Management',
          options: [
            {
              name: 'View Devices',
              path: 'admin_devices_list',
            },
            {
              name: 'Edit Device',
              path: 'admin_devices_update',
            },
            {
              name: 'Delete Device',
              path: 'admin_devices_delete',
            },
          ],
        },
        {
          name: 'History Log Management',
          options: [
            {
              name: 'View All History Log',
              path: 'admin_view_all_history_log',
            },
          ],
        },
      ],
    },
  ] as Array<IRightList>,
};
