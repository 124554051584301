<div class="deactivate-account-modal">
  <h1 mat-dialog-title>{{title}}</h1>
  <div mat-dialog-content><p>{{message}}</p></div>
  <mat-dialog-actions align="center">
    <div class="full-width o-flex o-flex--justify-space-between">
      <button mat-button mat-raised-button (click)="onDeactivate()">Deactivate instead of Deletion</button>
      <button mat-button mat-raised-button (click)="onCancel()">Don't Delete</button>
      <button mat-raised-button color="primary" (click)="onDelete()">Delete forever</button>
    </div>
  </mat-dialog-actions>

</div>
