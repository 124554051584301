import { Component, Input } from '@angular/core';
import { ChartService } from "@shared/services/chart.service";
import { AppBaseComponent } from "@shared/components/app-component-base";

@Component({
  selector: 'app-metabolic-profile',
  templateUrl: './metabolic-profile.component.html',
})
export class MetabolicProfileComponent extends AppBaseComponent {
  @Input() public chartData: any;
  @Input() public selectedTest: any;
  @Input() public external: boolean = false;

  public legends: any = [];

  constructor(private readonly chartService: ChartService) {
    super();
  }

  public onInitPage(): void {
    this.legends = this.chartService.ChartBokeh(this.chartData);

    if (!this.rights.metabolic_profiling_aerobic_anaerobic && !this.external) {
      const chartElement: HTMLElement = document.getElementById('metabolic_profile')!;
      chartElement.classList.add('hide-aerobic');
    }

    if (!this.rights.metabolic_profiling_fat_carbohydrate&& !this.external) {
      const chartElement: HTMLElement = document.getElementById('metabolic_profile')!;
      if (this.chartData.data.plots.length >= 6) {
        chartElement.classList.add('hide-fat');
      } else {
        chartElement.classList.add('hide-aerobic');
      }
    }
  }
}
