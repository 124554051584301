<mat-form-field appearance="outline" class="full-width" *ngIf="inputControl" [ngClass]="{'mat-form-field-invalid': inputControl.errors && inputControl.touched}">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-list #chipList [disabled]="isDisabled">
    <mat-chip *ngFor="let option of selectedOptions"
              (removed)="remove(option)">
      {{option[displayField]}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input #chipInput
           [formControl]="inputControl"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="add($event)"
    >
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async"
                [value]="option">
      {{option[displayField]}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
