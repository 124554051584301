import {Component, DestroyRef, Input} from '@angular/core';
import { EventService } from '@shared/services/event.service';
import { HelperService } from '@shared/services/helper.service';
import { EventConstants } from '@core/event-constants';
import { SessionService } from '@core/services/session.service';
import { AppBaseComponent } from '../app-component-base';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppConstants } from "@core/constants";

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
})
export class SidebarMenuComponent extends AppBaseComponent {
  public v1Link: string = this.constant.V1_URL;
  public isLoggedIn: boolean = false;
  public isShowTestMenu: boolean = false;
  public isShowEconomy: boolean = false;
  public isShowSpeedRelationshipMenu: boolean = false;
  public isProductionEnvironment: boolean = this.helperService.isProductionEnvironment(location, AppConstants.ENVIRONMENT_URL.PROD);

  @Input() public currentTestId: string = '';

  constructor(
    public sessionService: SessionService,
    private eventService: EventService,
    private readonly helperService: HelperService,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.isLoggedIn = this.sessionService.isLoggedIn;
    this.eventService.GetEvent(EventConstants.APC.SELECT_TEST)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event): void => {
        this.isShowTestMenu = this.helperService.isShowTestDataMenu(event);
        this.isShowEconomy = this.helperService.isShowEconomyMenu(event);
        this.isShowSpeedRelationshipMenu =
          this.helperService.isShowSpeedRelationshipMenu(event);
      });
  }
}
