import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";


export class ApiTrackingResultDto extends PagedResultDto {
  public items: ApiTrackingDto[] = [];
}

export class ApiTrackingDto extends Deserializable {
  public organization_id : number | undefined;
  public organization__name : string | undefined;
}
