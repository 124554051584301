<div>
    <div class="title-with-search">
      <p>History Logs</p>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input
          matInput
          placeholder="Search"
          [(ngModel)]="searchText"
          (keyup.enter)="search(searchText)"
        />
        <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
      </mat-form-field>
    </div>
    <mat-checkbox (change)="onSelectCheckBox($event)">Select all column</mat-checkbox>
    <div class="field" [formGroup]="form">
        <app-chips
          [form]="form.get('actions')"
          [options]="actionOptions"
          [displayField]="'name'"
          (optionChange)="onSelectAction($event)"
        ></app-chips>
    </div>
    <div>
      <table
        mat-table
        matSort
        #tableData
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        style="width: 100%"
        (matSortChange)="sortData($event)"
        matSortActive="-id"
        matSortDirection="desc"
        matSortDisableClear
        aria-describedby="event table">
        <ng-container [matColumnDef]="option.columnDef"  *ngFor="let option of showingActionOptions">
          <th scope="row" mat-header-cell *matHeaderCellDef mat-sort-header>
            {{option.name}}
          </th>
          <td mat-cell *matCellDef="let element">{{ element[option.attributeName] }}</td>
        </ng-container>
        <ng-container matColumnDef="organization_id">
          <th mat-header-cell *matHeaderCellDef scope="row" mat-sort-header>
            Organization
          </th>
        <td mat-cell *matCellDef="let element">{{ element?.organization?.name }}</td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef scope="row" mat-sort-header>
            Timestamp of upload (Zone)
          </th>
        <td mat-cell *matCellDef="let element">{{ element?.getDate() }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element" class="fixed-width-action">
                <button mat-raised-button color="primary" class="btn-icon-text" (click)="viewDialog(element.id)">
                    <mat-icon>remove_red_eye</mat-icon> View
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row no-data-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">No data available in table</td>
        </tr>
      </table>
      <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
        <mat-paginator
          (page)="getDataPage($event.pageIndex + 1)"
          [length]="totalItems"
          [pageSize]="pageSize">
        </mat-paginator>
      </div>
    </div>
  </div>

