import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConstants } from '@core/constants';
import { PricingPlansDto, PricingPlansResultDto } from '@core/services/pricing-plans/pricing-plans-dto';
import { PricingPlansService } from '@core/services/pricing-plans/pricing-plans.service';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { ClonePlanDialogComponent } from './clone-plan-dialog/clone-plan-dialog.component';
import { PaymentLinkDialogComponent } from './payment-link-dialog/payment-link-dialog.component';
import { PricingPlansDialogComponent } from './pricing-plans-dialog/pricing-plans-dialog.component';

@Component({
  selector: 'app-pricing-plans',
  templateUrl: './pricing-plans.component.html',
})
export class PricingPlansComponent  extends PagedListingBaseComponent<PricingPlansDto> {
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: PricingPlansDto[] = [];
  public isHadAction: boolean = false;
  public displayedColumns: string[] = [
    'id',
    'name',
    'group',
    'published',
    'action'
  ];

  constructor(
    private readonly plansService: PricingPlansService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  public onInitPage(): void {
    this.isHadAction = this.rights.admin_devices_update || this.rights.admin_devices_delete;
    if (!this.isHadAction) {
      this.displayedColumns = this.displayedColumns.filter(
        (item: string): boolean => item !== 'action'
      );
    }
    super.onInitPage();
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number): void {
    request.pageIndex = pageNumber;
    this.plansService.list(request).subscribe((res: PricingPlansResultDto): void => {
      this.dataSource = res.items;
      this.totalItems = res.totalCount;
    });
  }

  public openDialog(isEdit: boolean, plan?: PricingPlansDto): void {
    const dialogRef: MatDialogRef<PricingPlansDialogComponent> = this.dialog.open(PricingPlansDialogComponent, {
      panelClass: 'general-dialog',
      width: '740px',
      autoFocus: false,
      data: { plan, isEdit }
    });

    dialogRef.afterClosed().subscribe(
      (res): void => {
        if (res) {
          let message = 'Item created successfully';
          if (isEdit) {
            message = 'Item updated successfully';
          }
          this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          this.refresh();
        }
      },
      (error): void => { this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);}
    );
  }

  public add(): void {
    this.openDialog(false);
  }

  public edit(id: number): void {
    this.plansService.getPlanById(id).subscribe((res:any): void => {
      this.openDialog(true, res);
    });

  }

  public clone(id: number) : void {
    const plan = this.dataSource.find(plan => plan.id === id);
    if (!plan) { return }

    const dialogRef: MatDialogRef<ClonePlanDialogComponent> = this.dialog.open(ClonePlanDialogComponent, {
      width: '740px',
      data: plan
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      const [result, message] = res;
      if (!result) {
        if (message) {
          this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
        return;
      }
      window.location.reload();
    });
  }

  public enablePaymentLink(id: number): boolean {
    const plan = this.dataSource.find(plan => plan.id === id);
    if (!plan) { return false }

    return plan?.is_publish || false;
  }

  public paymentLink(id: number) : void {
    const plan = this.dataSource.find(plan => plan.id === id);
    const dialogRef: MatDialogRef<PaymentLinkDialogComponent> = this.dialog.open(PaymentLinkDialogComponent, {
      width: '740px',
      data: { plan: plan }
    });

    dialogRef.afterClosed().subscribe();
  }

  public deletePlan(id: number): void {
    const message: string = 'Do you want to delete this plan?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.plansService.delete(id).subscribe(
            (): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }
}
