<app-loader-indicator></app-loader-indicator>
<app-header
  [currentTestId]="currentTestId"
  (menuBarChange)="toggleMenuBar()"
></app-header>
<mat-sidenav-container autosize>
  <mat-sidenav #sidenav mode="side" opened="true">
    <app-sidebar-menu [currentTestId]="currentTestId"></app-sidebar-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-general-back-button *ngIf="generalBackButton"></app-general-back-button>
    <div class="app-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
