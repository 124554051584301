import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class HistoryLogResultDto extends PagedResultDto {
  public items: HistoryLogDto[] = [];
}

export class HistoryLogDto extends Deserializable {
  public id: number | undefined;
  public organization:
    |
        {
            id: number | undefined;
            name: string | undefined;
            photo: string | undefined;
            is_solvent: boolean | undefined;
            address_country_id: number | undefined;
            is_check_invoice: boolean | undefined;
            is_force_update_payment_method: boolean | undefined;
            active_payment_method_id: string | undefined;
            active_payment_method_type: string | undefined;
        }
    | undefined;
  public object_table: string | undefined;
  public object_name: string | undefined;
  public object_id: string | undefined;
  public action: string | undefined;
  public json_data: string | undefined;
  public user_tz: string | undefined;
  public user_ip: string | undefined;
  public test_sport_name: string | undefined;
  public test_vo2max: number | undefined;
  public test_vlamax: number | undefined;
  public test_mass: number | undefined;
  public test_lao2eq: number | undefined;
  public payment: number | undefined;
  public payment_date: number | undefined;
  public test_date: number | undefined;
  public created_at: string | undefined;
  public user: number | undefined;

     public getDate(): string {
      let date: Date | null = this.created_at ? new Date(this.created_at) : null;
      const time: string | undefined = date?.toLocaleTimeString('it-IT');
      const fullDate: string | undefined = date?.toLocaleDateString('it-IT').replace(/\//g, '.');

      return fullDate + " " + time;
    }

}

export interface IHistoryLogDetail {
    id?: number;
    json_data?: string;
    json_processing_data?: string;
}
