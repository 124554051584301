<div *ngIf="config; else noPlotsData">
  <h1 class="section-title">Performance Development</h1>

  <div class="section section-chart">
    <mat-icon class="reset-btn-setcard" (click)="resetGraph()">refresh</mat-icon>
    <div [id]="chartIdWithoutHash"></div>
    <br />
    <mat-accordion class="apc-comment">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Comment</mat-panel-title>
        </mat-expansion-panel-header>
              <textarea matInput rows="8" [(ngModel)]="performanceDevelopmentComment" (ngModelChange)="processComment()"></textarea>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<ng-template #noPlotsData>
  <h1>There is not plots data</h1>
</ng-template>
