<h4 mat-dialog-title>Lactate Test: All out sprint</h4>
<h2 style="text-align: center">All out sprint</h2>
<mat-dialog-content>
  <div [formGroup]="form" class="form-box">
    <div class="form-row">
      <p>Duration of efforts [s]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Duration of efforts [s]</mat-label>
        <input
          matInput
          type="number"
          min="12"
          max="25"
          formControlName="duration_efforts"
        />
        <mat-error
          *ngIf="
            form.get('duration_efforts')?.errors &&
            form.get('duration_efforts')?.touched
          "
          >Duration must be between 12 and 25 seconds
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="data.is_speed_sport" class="form-row">
      <p>Average Speed [m/s]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Average Speed [m/s]</mat-label>
        <input matInput type="number" formControlName="average_speed"
      /></mat-form-field>
    </div>
    <div *ngIf="!data.is_speed_sport" class="form-row">
      <p>Average Power [W]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Average Power [W]</mat-label>
        <input matInput type="number" formControlName="average_power"
      /></mat-form-field>
    </div>
    <hr />
    <div class="form-row">
      <p>Lactate Rest 1 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Lactate Rest 1 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="lactate_rest_1"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Lactate Rest 2 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Lactate Rest 2 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="lactate_rest_2"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Lactate Rest 3 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Lactate Rest 3 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="lactate_rest_3"
      /></mat-form-field>
    </div>
    <hr />
    <div class="form-row">
      <p>Post Lactate 1 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 1 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_1"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 2 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 2 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_2"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 3 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 3 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_3"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 4 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 4 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_4"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 5 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 5 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_5"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 6 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 6 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_6"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 7 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 7 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_7"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 8 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 8 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_8"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 9 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 9 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_9"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p>Post Lactate 10 [mmol/l]</p>
      <mat-form-field
        style="width: 60%"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Post Lactate 10 [mmol/l]</mat-label>
        <input matInput type="number" formControlName="post_lactate_10"
      /></mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">OK</button>
  </div>
</mat-dialog-actions>
