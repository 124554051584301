<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content class="content">
  <mat-form-field appearance="fill" class="dropdown">
    <mat-select [value]="data.textSettingType" (selectionChange)="onChangeTextSettingsType($event.value, data.id)">
      <mat-option *ngFor="let type of textSettingList" [value]="type.value">
        {{type.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-text-editor [id]="data.id"
                   [content]="data.textContent"
                   [initHeight]="data.height * 10"
                   [maxHeight]="data.height * 10"
                   [width]="data.width * 72.3"
                   [type]="data.type"
                   (textChanged)="onTextChangeModal($event)"></app-text-editor>
</div>

<mat-dialog-actions class="actions-text-editor">
  <button mat-raised-button (click)="onDismiss()">{{cancelButton}}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{saveButton}}</button>
</mat-dialog-actions>
