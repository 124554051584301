import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ApiService } from "@core/services/api.service";
import { Observable } from "rxjs";
import { AppConstants } from "@core/constants";

@Injectable({
  providedIn: 'root',
})
export class CountryResolveService {
  constructor( private apiService: ApiService ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.apiService.get(AppConstants.ACCOUNTS_URL.ACCOUNTS + AppConstants.ACCOUNTS_URL.GET.COUNTRY);
  }
}
