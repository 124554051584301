<div class="page-container">
  <div class="top-toolbar">
    <div class="sub-title">POWER-PERFORMANCE DECODER OPTIMIZATION</div>
    <div class="title">
      <p>Power-Performance Decoder</p>
    </div>
  </div>
  <mat-tab-group>
    <mat-tab label="Optimization">
      <ng-template matTabContent>
        <app-tabs
          [chartData]="itemData"
          (updateProgressBaseOnNumberOfEntities)="
            updateProgressBaseOnNumberOfEntities($event)
          "></app-tabs>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <mat-tab-group>
    <mat-tab label="Completeness">
      <ng-template matTabContent>
        <div class="progress_div">
          <mat-progress-bar
            style="height: 30px; width: 100%"
            mode="determinate"
            [value]="progressValue"
          ></mat-progress-bar>
          <span class="progress_bar" [ngStyle]="{ left: progressValue / 2 + '%' }">{{ progressValue }}%</span>
          <mat-icon [ngStyle]="{ left: progressValue + 1 + '%' }" *ngIf="showStar" style="position: absolute; color: #f44336; top: 3.5px">star</mat-icon>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <mat-tab-group *ngIf="progressValue >= 100">
    <mat-tab label="Weighted regression">
      <ng-template matTabContent>
        <div class="row" [formGroup]="regressionForm">
          <div class="column">
            <p>VLa<sub>max</sub></p>
            <mat-form-field class="mat-field-optimization" appearance="outline">
              <mat-label>VLa<sub>max</sub></mat-label>
              <input matInput type="number" min="1" max="10" formControlName="vla_max" />
              <mat-error
                *ngIf="regressionForm.get('vla_max')?.errors && regressionForm.get('vla_max')?.touched"
              >VLa<sub>max</sub> must be between 1 and 10
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column">
            <p>VO<sub>2max</sub></p>
            <mat-form-field class="mat-field-optimization" appearance="outline">
              <mat-label>VO<sub>2max</sub></mat-label>
              <input matInput type="number" min="1" max="10" formControlName="vo2_max" />
              <mat-error
                *ngIf="regressionForm.get('vo2_max')?.errors && regressionForm.get('vo2_max')?.touched"
              >V02<sub>max</sub> must be between 1 and 10
              </mat-error>
            </mat-form-field>
          </div>
          <div class="column">
            <p>Anaerobic Threshold</p>
            <mat-form-field class="mat-field-optimization" appearance="outline">
              <mat-label>Anaerobic Threshold</mat-label>
              <input
                matInput
                type="number"
                min="1"
                max="10"
                formControlName="anaerobicThreshold"
              />
              <mat-error
                *ngIf="regressionForm.get('anaerobicThreshold')?.errors && regressionForm.get('anaerobicThreshold')?.touched"
              >Anaerobic threshold must be between 1 and 10
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="ppd-perform-button">
          <button mat-raised-button (click)="performCPTest()">
            Perform PPD Test
          </button>
        </div>
        <div>
          <div data-component="cross-validation-ppd-graph" #chartPPD id="chartPPD"></div>
          <br />
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class="actions-div">
    <button
      mat-raised-button
      (click)="handleCancelView(false, true, false, false)"
    >
      Decline
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="isFinalCPCalculated"
      (click)="accept(false)"
      mat-button
    >
      Accept
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="isFinalCPCalculated"
      (click)="accept(true)"
    >
      Accept and View
    </button>
  </div>
</div>
