import { inject, Injectable } from '@angular/core';
import { IPerformanceLimitsConfig } from "@shared/interfaces/performance-limits.interface";
import { AppConstants } from "@core/constants";
import { HelperService } from "@shared/services/helper.service";
import {
  POWER_DROPDOWN_Y_AXIS_CONFIG,
  SPEED_DROPDOWN_Y_AXIS_CONFIG
} from "@shared/components/visualization/data-point-list.constant";
import { String } from "typescript-string-operations";
import { PerformanceDevelopmentYRangeNameEnum } from "@shared/enums/performance-development-y-range-name.enum";

@Injectable()
export class VisualizationService {
  private helperService: HelperService = inject(HelperService);
  private customAthleteCategoryObject: any = {};
  private defaultPDChartSettings: any = {
    tools: 'pan,box_zoom',
    x_axis_type: 'datetime',
    y_axis_label: 'Left Y axis 2',
    y_range: {bounds: ['auto'], plot_range: [0, 500]},
    x_range: {bounds: ['auto'],},
    extra_y_ranges: {
      leftY1: {bounds: ['auto'], plot_range: [0, 200]},
      rightY1: {bounds: ['auto'], plot_range: [0, 200]},
    },
  };
  private fillColorList: string[] = ['yellow', 'pink', 'orange', 'red', 'blue', 'black'];
  private Y_LEFT_1_LABEL: string = 'Left Y axis 1';
  private Y_LEFT_2_LABEL: string = 'Left Y axis 2';
  private Y_RIGHT_1_LABEL: string = 'Right Y axis 1';

  public resetCustomAthleteCategoryObject(): void {
    this.customAthleteCategoryObject = {};
  }

  public generateCustomAthleteCategoryObject(customAthleteCategoryList: any[]): any {
    this.resetCustomAthleteCategoryObject();

    customAthleteCategoryList.forEach((customCategory: any) => {
      this.customAthleteCategoryObject[customCategory.name] = {
        id: customCategory.value,
        name: customCategory.name,
        male_lower: 1,
        male_upper: 1,
        male_stdDev: 1,
        female_lower: 1,
        female_upper: 1,
        female_stdDev: 1,
      };
    });

    return this.customAthleteCategoryObject;
  }

  public getDefaultPerformanceLimitsTabConfig(metricName: string, customAthleteCategoryList: any[]): IPerformanceLimitsConfig {
    return {
      custom_tab_object: this.generateCustomAthleteCategoryObject(customAthleteCategoryList),
      tabs: {
        pro: {
          name: 'Pro',
          male_upper: 1,
          male_lower: 1,
          male_stdDev: 1,
          female_upper: 1,
          female_lower: 1,
          female_stdDev: 1,
        },
        amateur: {
          name: 'Amateur',
          male_lower: 1,
          male_upper: 1,
          male_stdDev: 1,
          female_lower: 1,
          female_upper: 1,
          female_stdDev: 1,
        },
        recreational: {
          name: 'Recreational',
          male_lower: 1,
          male_upper: 1,
          male_stdDev: 1,
          female_lower: 1,
          female_upper: 1,
          female_stdDev: 1,
        },
      },
      metricName: metricName,
      description: AppConstants.VISUALIZATION_PAGE.PERFORMANCE_LIMITS_DESCRIPTION,
    }
  }

  public getMetricNameWithUnit(tempMetricName: string, primaryType: string | undefined, secondaryType: string | undefined): string {
    let metricName: string | undefined;

    if (tempMetricName.includes('{0}')) {
      metricName = String.Format(tempMetricName, primaryType);
    } else if (tempMetricName.includes('{1}')) {
      metricName = String.Format(tempMetricName, secondaryType);
    } else {
      metricName = tempMetricName;
    }

    return metricName;
  }

  public generateExtraToolByYaxis(yAxis: any, simulationType: number, primaryType: string | undefined, secondaryType: string | undefined, yAxisLabel: string): any[] {
    return yAxis.map((item: any) => {
      const isSpeedBasedSport: boolean = this.helperService.isSpeedBasedSport(simulationType);
      let metricName: string | undefined = '';

      if (isSpeedBasedSport) {
        metricName = SPEED_DROPDOWN_Y_AXIS_CONFIG.optionList.find((setting: any) => setting.value === item.value)?.name;
      } else {
        const tempMetricName: string = POWER_DROPDOWN_Y_AXIS_CONFIG.optionList.find((setting: any) => setting.value === item.value)?.name || '';

        metricName = this.getMetricNameWithUnit(tempMetricName, primaryType, secondaryType);
      }

      return {
        tooltips: [
          [metricName, ` @value ${yAxisLabel}`],
          ['test_date', ' @date']
        ]
      }
    });
  }

  public generateExtraTools(yObjectConfig: any, simulationType: number, primaryType: string | undefined, secondaryType: string | undefined): any {
    const y1ExtraTools: any[] = this.generateExtraToolByYaxis(yObjectConfig.y1Value, simulationType, primaryType, secondaryType, this.Y_LEFT_1_LABEL);
    const y2ExtraTools: any[] = this.generateExtraToolByYaxis(yObjectConfig.y2Value, simulationType, primaryType, secondaryType, this.Y_LEFT_2_LABEL);
    const y3ExtraTools: any[] = this.generateExtraToolByYaxis(yObjectConfig.y3Value, simulationType, primaryType, secondaryType, this.Y_RIGHT_1_LABEL);

    return [...y1ExtraTools, ...y2ExtraTools, ...y3ExtraTools];
  }

  public getRandomIntegerMinMax(min: number, max: number): number {
    let rand: number = min + Math.random() * (max + 1 - min);

    return Math.floor(rand);
  }

  public getRandomColor(colorList: string[]): string {
    const min: number = 0;
    const max: number = colorList.length;
    const randomIndex: number = Math.floor(Math.random() * (max - min + 1)) + min;

    return colorList[randomIndex];

  }

  public getRandomSourceData(): any {
    const min: number = 0;
    const max: number = 200;
    const randomMaxValue: number = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomMinValue: number = Math.floor(Math.random() * (max - min + 1)) + min;
    const currentDateInNumbers: Date = new Date();
    const currentDay: number = currentDateInNumbers.getDate();
    const currentMonth: number = currentDateInNumbers.getMonth();
    const currentYear: number = currentDateInNumbers.getFullYear();
    const dateInString: string = `${currentDay}-${currentMonth}-${currentYear}`;

    const pastDateTemp: Date = new Date();
    pastDateTemp.setFullYear(pastDateTemp.getFullYear() - this.getRandomIntegerMinMax(1, 5));
    pastDateTemp.setMonth((pastDateTemp.getMonth() - this.getRandomIntegerMinMax(1, 5) === 0 ? 1 : pastDateTemp.getMonth() - this.getRandomIntegerMinMax(1, 5)));
    pastDateTemp.setDate((pastDateTemp.getDate() - this.getRandomIntegerMinMax(1, 5) === 0 ? 1 : pastDateTemp.getDate() - this.getRandomIntegerMinMax(1, 5)));
    const pastYear: number = pastDateTemp.getFullYear();
    const pastMonth: number = pastDateTemp.getMonth();
    const pastDate: number = pastDateTemp.getDate();
    const pastDateInString: string = `${pastDate}-${pastMonth}-${pastYear}`;


    return {
      x: [pastDateTemp.getTime(), currentDateInNumbers],
      y: [randomMinValue, randomMaxValue],
      date: [pastDateInString, dateInString],
      value: [randomMinValue, randomMaxValue]
    }
  }

  public generateLineByYAxis(yAxis: any, yRangeName: string): any[] {
    return yAxis.map((axis: any, index: number) => ({
      source: this.getRandomSourceData(),
      line_color: this.getRandomColor(this.fillColorList),
      line_width: 1,
      y_range_name: yRangeName,
    }))
  }

  public generateLines(yObjectConfig: any): any[] {
    const y1: any[] = this.generateLineByYAxis(yObjectConfig.y1Value, PerformanceDevelopmentYRangeNameEnum.LEFT_Y_1);
    const y2: any[] = this.generateLineByYAxis(yObjectConfig.y2Value, PerformanceDevelopmentYRangeNameEnum.LEFT_Y_2);
    const y3: any[] = this.generateLineByYAxis(yObjectConfig.y3Value, PerformanceDevelopmentYRangeNameEnum.RIGHT_Y_1);


    return [...y1, ...y2, ...y3];
  }

  public generateCircleByYRange(yAxis: any[], yRangeName: string): any[] {
    return yAxis.map(() => ({
      fill_color: 'white',
      size: 8,
      y_range_name: yRangeName,
    }))
  }

  public generateCircleList(yObjectConfig: any): any[] {
    const y1: any[] = this.generateCircleByYRange(yObjectConfig.y1Value, PerformanceDevelopmentYRangeNameEnum.LEFT_Y_1);
    const y2: any[] = this.generateCircleByYRange(yObjectConfig.y2Value, PerformanceDevelopmentYRangeNameEnum.LEFT_Y_2);
    const y3: any[] = this.generateCircleByYRange(yObjectConfig.y3Value, PerformanceDevelopmentYRangeNameEnum.RIGHT_Y_1);

    return [...y1, ...y2, ...y3];
  }

  public generateLayoutLegendList(yObjectConfig: any, simType: number, primaryType: string | undefined, secondaryType: string | undefined): any {
    const isSpeedBasedSport: boolean = this.helperService.isSpeedBasedSport(simType);
    const yAxisList: any[] = [];
    yObjectConfig.y1Value.forEach((yAxis: any) => {
      yAxis.yAxisPosition = 'Left Y axis 1';
      yAxisList.push(yAxis);
    });
    yObjectConfig.y2Value.forEach((yAxis: any) => {
      yAxis.yAxisPosition = 'Left Y axis 2';
      yAxisList.push(yAxis);
    });
    yObjectConfig.y3Value.forEach((yAxis: any) => {
      yAxis.yAxisPosition = 'Right Y axis 1';
      yAxisList.push(yAxis);
    });
    const layoutList: any[] = [];

    yAxisList.forEach((yAxis: any) => {
      if (yAxis.hasOwnProperty('value')) {
        if (isSpeedBasedSport) {
          const metricName: string | undefined = SPEED_DROPDOWN_Y_AXIS_CONFIG.optionList.find((setting: any) => setting.value === yAxis.value)?.name;
          if (metricName) {
            layoutList.push([metricName + ' ' + yAxis.yAxisPosition]);
          }
        } else {
          const tempMetricName: string = POWER_DROPDOWN_Y_AXIS_CONFIG.optionList.find((setting: any) => setting.value === yAxis.value)?.name + ' ' + yAxis.yAxisPosition || '';

          layoutList.push([this.getMetricNameWithUnit(tempMetricName, primaryType, secondaryType)]);
        }
      } else {
        layoutList.push(['N|A']);
      }
    });

    return {
      legends: layoutList,
    }
  }

  public generateChartConfig(y1Value: any, y2Value: any, y3Value: any, simulationType: number, primaryType: string | undefined, secondaryType: string | undefined): any {
    const yAxisObjectConfig: any = {y1Value, y2Value, y3Value};
    const legendObject: any = this.generateLayoutLegendList(yAxisObjectConfig, simulationType, primaryType, secondaryType);

    const config: any = {
      ...this.defaultPDChartSettings,
      extra_tools: this.generateExtraTools(yAxisObjectConfig, simulationType, primaryType, secondaryType),
      lines: this.generateLines(yAxisObjectConfig),
      circles: this.generateCircleList(yAxisObjectConfig),
      layouts: [
        {
          axis_position: 'right',
          linear_axis: {
            axis_label_text_font_size: '10pt',
            y_range_name: PerformanceDevelopmentYRangeNameEnum.RIGHT_Y_1,
            axis_label: 'Right Y axis 1'
          },
        },
        {
          axis_position: 'left',
          linear_axis: {
            axis_label_text_font_size: "10pt",
            y_range_name: PerformanceDevelopmentYRangeNameEnum.LEFT_Y_1,
            axis_label: 'Left Y axis 1'
          },
        },
      ],
    };
    config.layouts.push(legendObject);

    return config;
  }
}
