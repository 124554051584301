import { Component, DestroyRef, inject, Input } from '@angular/core';
import { PaymentSummaryDialogComponent } from "@shared/components/payment-summary-dialog/payment-summary-dialog.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { AppConstants } from "@core/constants";
import { IGeneralInfoList } from "@shared/interfaces/general-info-list.interface";
import { IExtendPaymentModalDialog } from "@shared/interfaces/extend-payment-modal-dialog.interface";
import { IResponsePaymentModalDialog } from "@shared/interfaces/response-payment-modal-dialog.interface";
import { ApiService } from "@core/services/api.service";
import { MatSidenav } from "@angular/material/sidenav";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { String } from 'typescript-string-operations';
import { IFeature } from '../interfaces/feature.interface';
import { SessionService } from '@core/services/session.service';
import { IAuth } from '@modules/login/login.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-try-for-free-button',
  templateUrl: './try-for-free-button.component.html',
  styleUrls: ['./try-for-free-button.component.scss']
})
export class TryForFreeButtonComponent {
  private dialog: MatLegacyDialog = inject(MatLegacyDialog);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private apiService: ApiService = inject(ApiService);
  private sessionService: SessionService = inject(SessionService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private router: Router = inject(Router);

  public trialButtonName: string = 'Try For Free';
  public resubscribeButtonName: string = 'Resubscribe';
  public trialPriceText: string = '{0}-day free trial, then {1} {2} /mo';
  public priceText: string = '{0} {1} /mo';

  @Input() public tryForFreeModalDialogConfig: IGeneralInfoList & IExtendPaymentModalDialog;
  @Input() public feature: IFeature;

  public tryForFree(): void {
    this.tryForFreeModalDialogConfig.wasPurchasedBefore = this.wasFeaturePurchasedBefore();
    this.dialog.open(PaymentSummaryDialogComponent, {
      data: this.tryForFreeModalDialogConfig,
    })
      .afterClosed()
      .subscribe({
        next: (dialogResponse: IResponsePaymentModalDialog): void => {
          if (!dialogResponse) {
            return
          }

          this.apiService.post(
            AppConstants.API.FEATURE_PURCHASE.POST,
            { feature_id: dialogResponse.featureId, additional_dependencies: dialogResponse.additionalDependencies }
          )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: (res: any): void => {
                this.snackBar.open('Item has been purchased successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
                // Update session features
                const auth = this.sessionService.getCookie("auth");
                if (!auth) {
                  return
                }
                const authJSON: IAuth = JSON.parse(auth);
                authJSON.features = res.features;
                this.sessionService.setCookie("auth", JSON.stringify(authJSON));

                if (this.feature.category_name === "Metrics") {
                  // Redirect to visualization
                  this.router.navigate(["home", "visualization"], {
                    queryParams: {
                      feature_id: this.feature.id,
                      tab_index_to_return: 0
                    }
                  })
                } else if (this.feature.category_name === "Sports") {
                  // Redirect to sport configuration
                  this.router.navigate(["home", "sport-configuration"], {
                    queryParams: {
                      feature_id: this.feature.id,
                      tab_index_to_return: 0
                    }
                  })
                } else if (this.feature.slug === "training_zone_builder") {
                  // Redirect to training zone builder customization
                  this.router.navigate(["home", "administration", "training-zone-template"], {
                    queryParams: { tab_index_to_return: 0 }
                  })
                } else if (this.feature.slug === "custom_athlete_categories") {
                  // Redirect to custom athlete category customization
                  this.router.navigate(["home", "custom-athlete-category"], {
                    queryParams: { tab_index_to_return: 0 }
                  })
                } else {
                  // Reload page to update the try-for-free button
                  window.location.reload();
                }
              },
              error: () => {
                this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
                this.snackBar.open('Error', 'OK', AppConstants.TOAST_CONFIG.ERROR);
              }
            });
        },
        error: (error: string): void => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      });
  }

  public getButtonName(): string {
    if (this.wasFeaturePurchasedBefore()) {
      return this.resubscribeButtonName;
    }
    return this.trialButtonName;
  }

  public getPriceText(): string {
    if (!this.feature?.price) {
      return ""
    }

    if (this.wasFeaturePurchasedBefore()) {
      return String.Format(
        this.priceText,
        this.feature.currency_code,
        this.feature.price
      );
    }

    return String.Format(
      this.trialPriceText,
      AppConstants.FEATURES.TRIAL_PERIOD_DAYS,
      this.feature.currency_code,
      this.feature.price
    );
  }

  private wasFeaturePurchasedBefore(): boolean {
    const previousPurchaseDates = this.feature?.previous_purchase_dates;
    return Array.isArray(previousPurchaseDates) && previousPurchaseDates.length > 0;
  }
}
