import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '@core/constants';

@Pipe({
  name: 'convertNameAlternative',
})
export class ConvertNameAlternativePipe implements PipeTransform {
  public transform(value: string, prefix: string = ', '): string {
    if (value) {
      const listValue = value.split('|');
      const listName = listValue.map((item) => {
        const itemIncluded = AppConstants?.ITEMS_INCLUDED.find(
          (i) => i.id === Number(item)
        );
        return itemIncluded?.name;
      });
      return listName.join(prefix);
    } else {
      return '';
    }
  }
}
