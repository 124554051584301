import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AppConstants } from "@core/constants";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { UserWaitlistDto } from "@core/services/users/user-waitlist-dto";

@Component({
  selector: 'app-wait-list-button',
  templateUrl: './wait-list-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitListButtonComponent {
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private snackBar: MatSnackBar = inject(MatSnackBar);

  public joinWaitListButton: string = 'Join waitlist';
  public onWaitListButton: string = 'On waitlist';

  @Input() featureId: number;
  @Input() featureInWaitList: UserWaitlistDto | undefined;
  @Output() updateButtonStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  public joinWaitList(): void {
    if (!this.featureInWaitList) {
      this.featureStoreService.setToWaitList(this.featureId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (): any => {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
            this.updateButtonStatus.emit(true);
          },
          error: (error: any): void => {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
            this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        });
    }
  }
}
