import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grossEfficiency',
})
export class GrossEfficiencyPipe implements PipeTransform {
  public transform(value: any): string {
    if (!value) {
      return 'Infinity';
    }
    const ge: number = 1 / (((value / 100) * 20.9) / 60);
    return ge.toFixed(2);
  }
}
