import { Component, DestroyRef, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { TestService } from '@core/services/test/test.service';
import { combineLatest } from 'rxjs';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { AthleteDto } from '@core/services/athletes-management/athletes-dto';
import { String } from 'typescript-string-operations';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { OptimizationOptionDialogComponent } from '../optimization-option-dialog/optimization-option-dialog.component';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-simulate-event-dialog',
  templateUrl: './simulate-event-dialog.component.html',
})
export class SimulateEventDialogComponent extends AppBaseComponent {
  public detailForm: UntypedFormGroup = {} as UntypedFormGroup;
  public athletesOptions: Array<any> = [];
  public athletesFiltered: Array<any> = [];
  public testOptions: Array<any> = [];
  public dataModel = AppConstants.RESULT_DEFAULT_CONSTANTS;
  public showActOxydPhos: boolean = true;
  public glyOptions: Array<any> = [];
  public actOxydPhosOptions: Array<any> = [];
  public modelTypes: Array<any> = AppConstants.MODEL_TYPES;
  public event: any;
  public sport: string = '';
  public searchAthleteCtrl: UntypedFormControl = new UntypedFormControl('');

  constructor(
    public dialogRef: MatDialogRef<SimulateEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private testService: TestService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private apiService: ApiService,
    private readonly snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForm();
    this.initOptionAndValue();
    this.searchAthleteCtrl.valueChanges.pipe(
      debounceTime(450),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((): void => {
      this._applyFilter();
    });
  }

  private initOptionAndValue(): void {
    this.getEvent(this.data.id);
    combineLatest([this.apiService.get('athletes/')])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
      ([resAthletes]: any): void => {
        let coachId = this.auth.id;
        let athletes;

        if (this.sessionService.rights.athletes_list_all) {
          athletes = resAthletes?.results;
        } else {
          athletes = resAthletes?.results.filter((a: AthleteDto) =>
            a?.coaches?.find((c): boolean => c.id === coachId)
          );
        }

        this.athletesOptions = athletes;
        this._setupFilter();
      }
    );
  }

  private _setupFilter(): void {
    this.athletesFiltered = this.athletesOptions.slice();
    this._applyFilter();
  }

  private _applyFilter(): void {
    let search = this.searchAthleteCtrl.value;
    if (!search) {
      this.athletesFiltered = this.athletesOptions.slice();
    } else {
      search = search.toLowerCase();

      this.athletesFiltered = this.athletesOptions.filter((field: any) => (field.first_name + ' ' + field.last_name)
        .toLowerCase().includes(search));
    }
  }

  public getEvent(id: number): void {
    const api = 'events/?id=' + id;
    this.apiService.get(api)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: any): void => {
      if(response.results.length){
        this.event = response.results[0];
        this.sport = response.results[0].sport.name;
      } else {
        this.event = null;
      }
    }, error => {
      this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
    });
  }

  public getTestsBySelectedAthlete(athleteId: number): void {
    this.testService.getByAthleteAndSport(athleteId, this.data.sport.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
        this.testOptions = res.results;
      });
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    if (!this.detailForm.valid) {
      this.detailForm.markAllAsTouched();
    } else {
      this.openOptimizationOptionsModal();
    }
  }

  public modelOptionChanged(modelId: number): void {
    if (modelId == 1) {
      this.glyOptions = this.constant.MODEL_1_SUBTYPES;
      this.showActOxydPhos = false;
    } else if (modelId == 2) {
      this.glyOptions = this.constant.MODEL_2_SUBTYPES;
      this.showActOxydPhos = true;
      this.actOxydPhosOptions = this.constant.MODEL_2_SUBTYPES_2;
    } else {
      this.glyOptions = this.constant.MODEL_3_SUBTYPES;
      this.showActOxydPhos = true;
      this.actOxydPhosOptions = this.constant.MODEL_3_SUBTYPES_2;
    }
  }

  public openOptimizationOptionsModal(): void {
    const dialogRef: MatDialogRef<OptimizationOptionDialogComponent> = this.dialog.open(OptimizationOptionDialogComponent, {
      width: '600px',
      panelClass: 'general-dialog',
      data: {
        type: this.constant.TEST_TYPE_CHARS.result,
      },
    });
    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result): void => {
      if (result.action !== 'confirm') {
        return;
      }
      this.insert(this.prepareDataForSave(), result);
    });
  }

  public checkTestType(type: string): string {
    switch (type) {
      case 'map':
        return 'Critical Power';
      case 'lactate':
        return 'Lactate';
      case 'newtype':
        return 'PPD';
      case 'manual':
        return 'Manual';
      case 'virtual':
        return 'Virtual';
      default:
        return type;
    }
  }

  public prepareDataForSave() {
    const preparedData = {
      event: this.event.id,
      coach: this.auth.id,
      organization: this.auth.organization.id,
      ...this.detailForm.value,
    };
    return preparedData;
  }

  public pcrMlo2OptionChanged(): void {
    const pcrMlo2Control = this.detailForm.get('pcr_mlo2');
    if(pcrMlo2Control?.value && !isNaN(parseFloat(pcrMlo2Control?.value))) {
      pcrMlo2Control?.value.setValue(parseFloat(pcrMlo2Control?.value));
      const watt_o2_equivalent = 12.5;
      pcrMlo2Control?.value.setValue(1 / pcrMlo2Control?.value);
      this.detailForm.get('joule_pcreq')?.value.setValue(pcrMlo2Control?.value / (watt_o2_equivalent / 60));
    }
  }

  public insert(model: any, data: any): void {
    let param_array = [];
    let params: string = '';
    if (data.productId) {
      param_array.push('product=' + data.productId);
    }
    if (data.planId) {
      param_array.push('plan=' + data.planId);
    }
    params = param_array.join('&');
    const p: string =  params ? '?' + params : '';
    const api: string = String.Format('results/{0}', p);
    this.apiService.post(api, model)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: Object): void => {
      this.hideLoading();
      this.snackBar.open('Item created successfully.', 'OK', this.constant.TOAST_CONFIG.SUCCESS);
      this.dialogRef.close(response);
    }, (error): void => {
      this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
    });
  }

  public showOptions(event:MatCheckboxChange): void {
    if(event.checked){
      this.detailForm.get('flag_forceDGATP')?.setValue(1);
      return;
    }
    this.detailForm.get('flag_forceDGATP')?.setValue(0);
  }

  public initForm(): void {
    this.modelOptionChanged(this.dataModel.example);

    const eventAthleteDataControlsConfig = {
      description: new UntypedFormControl('', Validators.required),
      athlete: new UntypedFormControl('', Validators.required),
      test: new UntypedFormControl('', Validators.required),
      flag_forceDGATP: new UntypedFormControl(this.dataModel.flag_forceDGATP),
      example: new UntypedFormControl(this.dataModel.example, Validators.required),
      mod_glycPME: new UntypedFormControl(
        this.dataModel.mod_glycPME,
        Validators.required
      ),
      act_oxyd_phos: new UntypedFormControl(
        this.dataModel.act_oxyd_phos,
        Validators.required
      ),
      stepsize: new UntypedFormControl(this.dataModel.stepsize, Validators.required),
      relerr: new UntypedFormControl(this.dataModel.relerr, Validators.required),
      abserr: new UntypedFormControl(this.dataModel.abserr, Validators.required),
      atp_rest: new UntypedFormControl(this.dataModel.atp_rest, Validators.required),
      adp_rest: new UntypedFormControl(this.dataModel.adp_rest, Validators.required),
      pcr_rest: new UntypedFormControl(this.dataModel.pcr_rest, Validators.required),
      cr_rest: new UntypedFormControl(this.dataModel.cr_rest, Validators.required),
      pi_rest: new UntypedFormControl(this.dataModel.pi_rest, Validators.required),
      amp_rest: new UntypedFormControl(this.dataModel.amp_rest, Validators.required),
      CLaM_rest: new UntypedFormControl(this.dataModel.CLaM_rest, Validators.required),
      CLaB_rest: new UntypedFormControl(this.dataModel.CLaB_rest, Validators.required),
      pH_rest: new UntypedFormControl(this.dataModel.pH_rest, Validators.required),
      conz_F6MP_rest: new UntypedFormControl(
        this.dataModel.conz_F6MP_rest,
        Validators.required
      ),
      conz_PEP_rest: new UntypedFormControl(
        this.dataModel.conz_PEP_rest,
        Validators.required
      ),
      vo2_rest: new UntypedFormControl(this.dataModel.vo2_rest, Validators.required),
      vo2_passive_tissue: new UntypedFormControl(
        this.dataModel.vo2_passive_tissue,
        Validators.required
      ),
      vent_est_slope: new UntypedFormControl(
        this.dataModel.vent_est_slope,
        Validators.required
      ),
      vent_est_offset: new UntypedFormControl(
        this.dataModel.vent_est_offset,
        Validators.required
      ),
      vo2_vent_slope: new UntypedFormControl(
        this.dataModel.vo2_vent_slope,
        Validators.required
      ),
      vo2_vent_offset: new UntypedFormControl(
        this.dataModel.vo2_vent_offset,
        Validators.required
      ),
      base_pmf: new UntypedFormControl(this.dataModel.base_pmf, Validators.required),
      dGFakt_Mod1: new UntypedFormControl(
        this.dataModel.dGFakt_Mod1,
        Validators.required
      ),
      dGFakt_Mod2: new UntypedFormControl(
        this.dataModel.dGFakt_Mod2,
        Validators.required
      ),
      dGFakt: new UntypedFormControl(this.dataModel.dGFakt, Validators.required),
      DGOX: new UntypedFormControl(this.dataModel.DGOX, Validators.required),
      evo2: new UntypedFormControl(this.dataModel.evo2, Validators.required),
      fakt_vssPME: new UntypedFormControl(
        this.dataModel.fakt_vssPME,
        Validators.required
      ),
      free_CArest: new UntypedFormControl(
        this.dataModel.free_CArest,
        Validators.required
      ),
      joule_pcreq: new UntypedFormControl(
        this.dataModel.joule_pcreq,
        Validators.required
      ),
      kh_F6MP: new UntypedFormControl(this.dataModel.kh_F6MP, Validators.required),
      konv_lapcr: new UntypedFormControl(
        this.dataModel.konv_lapcr,
        Validators.required
      ),
      pcr_mlo2: new UntypedFormControl(this.dataModel.pcr_mlo2, Validators.required),
      konv_o2pcr: new UntypedFormControl(
        this.dataModel.konv_o2pcr,
        Validators.required
      ),
      ks_F6MPADP: new UntypedFormControl(
        this.dataModel.ks_F6MPADP,
        Validators.required
      ),
      ks_F6MPadpCA: new UntypedFormControl(
        this.dataModel.ks_F6MPadpCA,
        Validators.required
      ),
      ks_F6MPamp: new UntypedFormControl(
        this.dataModel.ks_F6MPamp,
        Validators.required
      ),
      ks_F6MPampCOOP: new UntypedFormControl(
        this.dataModel.ks_F6MPampCOOP,
        Validators.required
      ),
      ks_F6MPCa: new UntypedFormControl(this.dataModel.ks_F6MPCa, Validators.required),
      ks_lapH: new UntypedFormControl(this.dataModel.ks_lapH, Validators.required),
      ks_oxpi: new UntypedFormControl(this.dataModel.ks_oxpi, Validators.required),
      ks_pfkF6MP: new UntypedFormControl(
        this.dataModel.ks_pfkF6MP,
        Validators.required
      ),
      ks_piPME: new UntypedFormControl(this.dataModel.ks_piPME, Validators.required),
      ks_vlaresADP: new UntypedFormControl(
        this.dataModel.ks_vlaresADP,
        Validators.required
      ),
      ks_vlaresCLa: new UntypedFormControl(
        this.dataModel.ks_vlaresCLa,
        Validators.required
      ),
      ks_vo2_Mod1: new UntypedFormControl(
        this.dataModel.ks_vo2_Mod1,
        Validators.required
      ),
      ks_vo2: new UntypedFormControl(this.dataModel.ks_vo2, Validators.required),
      ks_vo2ADP: new UntypedFormControl(this.dataModel.ks_vo2ADP, Validators.required),
      ks_vPMEres: new UntypedFormControl(
        this.dataModel.ks_vPMEres,
        Validators.required
      ),
      ks2_laADP: new UntypedFormControl(this.dataModel.ks2_laADP, Validators.required),
      ks2_laAMP: new UntypedFormControl(this.dataModel.ks2_laAMP, Validators.required),
      ks2_pi: new UntypedFormControl(this.dataModel.ks2_pi, Validators.required),
      ks2_piPME: new UntypedFormControl(this.dataModel.ks2_piPME, Validators.required),
      laexp_pi: new UntypedFormControl(this.dataModel.laexp_pi, Validators.required),
      m2: new UntypedFormControl(this.dataModel.m2, Validators.required),
      m3: new UntypedFormControl(this.dataModel.m3, Validators.required),
      pH_mit: new UntypedFormControl(this.dataModel.pH_mit, Validators.required),
      phy_const: new UntypedFormControl(this.dataModel.phy_const, Validators.required),
      reduce_alpha1mV: new UntypedFormControl(
        this.dataModel.reduce_alpha1mV,
        Validators.required
      ),
      vlaresmax: new UntypedFormControl(this.dataModel.vlaresmax, Validators.required),
      vpme_resmax: new UntypedFormControl(
        this.dataModel.vpme_resmax,
        Validators.required
      ),
      zk_vo2: new UntypedFormControl(this.dataModel.zk_vo2, Validators.required),
      zk_vo2mod3: new UntypedFormControl(
        this.dataModel.zk_vo2mod3,
        Validators.required
      ),
      vo2max_const: new UntypedFormControl(
        this.dataModel.vo2max_const,
        Validators.required
      ),
      force_kjlow1: new UntypedFormControl(
        this.dataModel.force_kjlow1,
        Validators.required
      ),
      force_kjlow0: new UntypedFormControl(
        this.dataModel.force_kjlow0,
        Validators.required
      ),
      temp: new UntypedFormControl(this.dataModel.temp, Validators.required),
      slyke: new UntypedFormControl(this.dataModel.slyke, Validators.required),
      slyke1: new UntypedFormControl(this.dataModel.slyke1, Validators.required),
      kel_ox: new UntypedFormControl(this.dataModel.kel_ox, Validators.required),
    };
    this.detailForm = this.formBuilder.group(eventAthleteDataControlsConfig);
  }
}
