import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

@Component({
  selector: 'app-related-videos',
  templateUrl: './related-videos.component.html',
  styleUrls: ['./related-videos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedVideosComponent {
  @Input() public  relatedVideoList: any;
  @Input() public  title: string;
}
