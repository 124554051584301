<div class="athlete-dialog__section">
  <h2 mat-dialog-title>{{ data?.id ? "Edit Athletes" : "Add Athletes" }}</h2>
  <mat-dialog-content>
    <div class="email-form" *ngIf="!isShowDetail" [formGroup]="emailForm">
      <mat-form-field
        class="full-width"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Email (*)</mat-label>
        <input type="text" matInput placeholder="" formControlName="email" />
        <mat-error
          *ngIf="
            emailForm.controls?.email?.errors &&
            emailForm.controls?.email?.touched
          "
        >
          <div
            [innerHTML]="
              emailForm.controls.email.errors
                | errorFormFormat: { fieldName: 'Email' }
            "
          ></div>
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="dialog-add-detail-form"
      *ngIf="isShowDetail"
      [formGroup]="detailForm"
    >
      <fieldset class="personal-fieldset">
        <legend>Personal</legend>
        <div class="full-width photo">
          <img
            *ngIf="detailForm?.value?.photo"
            [src]="detailForm.value?.photo"
            alt="athlete logo"
          />
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png, image/jpeg"
            (change)="changeImage($event)"
          />
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>First Name (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="first_name"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.first_name?.errors &&
                detailForm?.controls?.first_name?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.first_name.errors
                    | errorFormFormat: { fieldName: 'First Name' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Last Name (*)</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="last_name"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.last_name?.errors &&
                detailForm?.controls?.last_name?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.last_name.errors
                    | errorFormFormat: { fieldName: 'Last Name' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Email 1</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="email"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.email?.errors &&
                detailForm?.controls?.email?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.email.errors
                    | errorFormFormat: { fieldName: 'Email 1' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field field-text">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>Gender (*)</mat-label>
            <mat-select formControlName="gender">
              <mat-option
                *ngFor="let item of genderOptions"
                [value]="item?.value"
              >
                {{ item?.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                detailForm?.controls?.gender?.errors &&
                detailForm?.controls?.gender?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.gender.errors
                    | errorFormFormat: { fieldName: 'Gender' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Default Weight (*)</mat-label>
            <input
              type="number"
              matInput
              placeholder=""
              formControlName="default_weight"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.default_weight?.errors &&
                detailForm?.controls?.default_weight?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.default_weight.errors
                    | errorFormFormat: { fieldName: 'Default Weight' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Date of birth (*)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="dob" />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="
                detailForm?.controls?.dob?.errors &&
                detailForm?.controls?.dob?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.dob.errors
                    | errorFormFormat: { fieldName: 'Date of birth' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field field-text">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>Athlete Category (*)</mat-label>
            <mat-select data-component="add-athlete-category" formControlName="training_level">
              <mat-option
                *ngFor="let item of trainingLevelOptions"
                [value]="item?.id"
              >
                {{ item?.value }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                detailForm?.controls?.training_level?.errors &&
                detailForm?.controls?.training_level?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.training_level.errors
                    | errorFormFormat: { fieldName: 'Athlete Category' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field field-text">
          <app-chips
            data-component="add-athlete-coach"
            [label]="'Coaches'"
            [form]="detailForm.get('coaches')"
            [options]="coachesOptions"
            [displayField]="'username'"
            (optionChange)="onSelectCoach($event)"
          ></app-chips>
        </div>

        <div class="field field-toggle">
          <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
        </div>
      </fieldset>

      <fieldset class="address-fieldset">
        <legend>Address</legend>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Street</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="address_street_name"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.address_street_name?.errors &&
                detailForm?.controls?.address_street_name?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.address_street_name.errors
                    | errorFormFormat: { fieldName: 'Street' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Street Number</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="address_street_number"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.address_street_number?.errors &&
                detailForm?.controls?.address_street_number?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.address_street_number.errors
                    | errorFormFormat: { fieldName: 'Street Number' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Zip</mat-label>
            <input
              type="number"
              matInput
              placeholder=""
              formControlName="address_zip"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.address_zip?.errors &&
                detailForm?.controls?.address_zip?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.address_zip.errors
                    | errorFormFormat: { fieldName: 'Zip' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>City</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="address_city"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.address_city?.errors &&
                detailForm?.controls?.address_city?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.address_city.errors
                    | errorFormFormat: { fieldName: 'City' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>State</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="address_state"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.address_state?.errors &&
                detailForm?.controls?.address_state?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.address_state.errors
                    | errorFormFormat: { fieldName: 'State' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field field-text">
          <app-autocomplate
            [options]="countriesOptions"
            [form]="this.detailForm.get('address_country')"
            [label]="'Country'"
          ></app-autocomplate>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Phone 1</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="phone"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.phone?.errors &&
                detailForm?.controls?.phone?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.phone.errors
                    | errorFormFormat: { fieldName: 'Phone 1' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Phone 2</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="phone2"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.phone2?.errors &&
                detailForm?.controls?.phone2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.phone2.errors
                    | errorFormFormat: { fieldName: 'Phone 2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Fax</mat-label>
            <input type="text" matInput placeholder="" formControlName="fax" />
            <mat-error
              *ngIf="
                detailForm?.controls?.fax?.errors &&
                detailForm?.controls?.fax?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.fax.errors
                    | errorFormFormat: { fieldName: 'Fax' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Email 2</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="email2"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.email2?.errors &&
                detailForm?.controls?.email2?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.email2.errors
                    | errorFormFormat: { fieldName: 'Email 2' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset class="additional-info-fieldset">
        <legend>Additional Info</legend>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Website</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="website"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.website?.errors &&
                detailForm?.controls?.website?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.website.errors
                    | errorFormFormat: { fieldName: 'Website' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Facebook</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="facebook"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.facebook?.errors &&
                detailForm?.controls?.facebook?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.facebook.errors
                    | errorFormFormat: { fieldName: 'Facebook' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Twitter</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="twitter"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.twitter?.errors &&
                detailForm?.controls?.twitter?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.twitter.errors
                    | errorFormFormat: { fieldName: 'Twitter' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Instagram</mat-label>
            <input
              type="text"
              matInput
              placeholder=""
              formControlName="instagram"
            />
            <mat-error
              *ngIf="
                detailForm?.controls?.instagram?.errors &&
                detailForm?.controls?.instagram?.touched
              "
            >
              <div
                [innerHTML]="
                  detailForm.controls.instagram.errors
                    | errorFormFormat: { fieldName: 'Instagram' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field field-text">
          <app-chips
            [label]="'Tags'"
            [form]="detailForm.get('tags')"
            [options]="tagOptions"
            (optionChange)="onSelectTag($event)"
          ></app-chips>
        </div>
      </fieldset>

      <fieldset class="garmin-connect-fieldset" *ngIf="data?.id">
        <legend>Garmin Connect</legend>
        <ng-container *ngIf="!currentAthlete?.garmin_verified">
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Email</mat-label>
              <input
                autocomplete="new-email"
                type="text"
                matInput
                placeholder=""
                formControlName="garmin_email"
              />
              <mat-error
                *ngIf="
                  detailForm?.controls?.garmin_email?.errors &&
                  detailForm?.controls?.garmin_email?.touched
                "
              >
                <div
                  [innerHTML]="
                    detailForm.controls.garmin_email.errors
                      | errorFormFormat: { fieldName: 'Email Garmin' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always"
            >
              <mat-label>Password</mat-label>
              <input
                autocomplete="new-password"
                type="password"
                matInput
                placeholder=""
                formControlName="garmin_password"
              />
              <mat-error
                *ngIf="
                  detailForm?.controls?.garmin_password?.errors &&
                  detailForm?.controls?.garmin_password?.touched
                "
              >
                <div
                  [innerHTML]="
                    detailForm.controls.garmin_password.errors
                      | errorFormFormat: { fieldName: 'Password Garmin' }
                  "
                ></div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="action full-width">
            <button mat-raised-button color="primary" (click)="verifyGarmin()">
              Verify Connection
            </button>
          </div>
        </ng-container>
        <div class="action full-width" *ngIf="currentAthlete?.garmin_verified">
          <button mat-raised-button color="primary" (click)="revokeGarmin()">
            Revoke Connection
          </button>
        </div>
      </fieldset>

      <fieldset class="strava-fieldset" *ngIf="data?.id">
        <legend>Strava</legend>
        <div class="action full-width" *ngIf="!currentAthlete?.strava_id">
          <button mat-raised-button color="primary" (click)="verifyStrava()">
            Verify Connection
          </button>
        </div>
        <div class="action full-width" *ngIf="currentAthlete?.strava_id">
          <button mat-raised-button color="primary" (click)="revokeStrava()">
            Revoke Connection
          </button>
        </div>
      </fieldset>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div>
      <button
        id="cancelButton"
        mat-raised-button
        (click)="close()"
        type="button"
      >
        Cancel
      </button>
      <button
        id="saveButton"
        *ngIf="isShowDetail"
        mat-raised-button
        color="primary"
        (click)="save()"
      >
        Save
      </button>
      <button
        id="nextButton"
        *ngIf="!isShowDetail"
        mat-raised-button
        color="primary"
        (click)="next()"
      >
        Next
      </button>
    </div>
  </mat-dialog-actions>
</div>
