<!-- > These buttons need only to help to perform Cypress tests <-->
<button style="display: none" id="ACTIVATE_ALL_BUTTONS" (click)="activateAllButtons()"></button>
<button style="display: none" id="DEACTIVATE_ALL_BUTTONS" (click)="deActivateAllButtons()"></button>
<button style="display: none" id="RESET_DATA" (click)="resetData()"></button>
<button style="display: none" id="MEAN_MAX" (click)="meanMaxFunction()"></button>
<button style="display: none" id="SPRINT" (click)="sprintFunction()"></button>
<button style="display: none" id="SINGLE_EFFORT" (click)="singleEffortFunction()"></button>
<button style="display: none" id="MAX_EFFORT" (click)="maxEffortFunction()"></button>
<button style="display: none" id="ANAEROBIC_THRESHOLD"></button>
<div style="width: 100%">
  <div class="row">
    <p style="width: 20%">Power only efforts:</p>
    <button
      style="width: 20%"
      (click)="openMeanMaxDialog()"
      mat-raised-button
      [disabled]="meanMaxPowerButtonDisabled"
    >
      Mean max. power
    </button>
    <button
      style="width: 20%"
      (click)="openSingleEffortDialog()"
      mat-raised-button
      [disabled]="singleEffortButtonDisabled"
    >
      Single effort + W'
    </button>
    <button
      style="width: 20%"
      (click)="openRAMPTestDialog()"
      mat-raised-button
      [disabled]="rampTestButtonDisabled"
      *ngIf="isRampTestButtonVisible"
    >
      Ramp Test
    </button>
  </div>
  <div class="row">
    <p style="width: 20%">Lactate tests:</p>
    <button
      style="width: 20%"
      (click)="openSprintDialog()"
      [disabled]="sprintButtonDisabled"
      mat-raised-button
    >
      Sprint
    </button>
    <button
      style="width: 20%"
      (click)="openMaxEffortDialog()"
      mat-raised-button
      [disabled]="maxEffortButtonDisabled"
    >
      Max. effort
    </button>
  </div>
  <div class="row">
    <p style="width: 20%">Manual entry:</p>
    <button style="width: 20%" (click)="openVlaDialog()" mat-raised-button>
      VLa<sub>max</sub>
    </button>
    <button style="width: 20%" (click)="openVo2Dialog()" mat-raised-button>
      VO<sub>2max</sub>
    </button>
    <button
      style="width: 20%"
      (click)="openAnaerobicThresholdDialog()"
      mat-raised-button
    >Critical Power</button>
  </div>
  <table
    style="width: 100%; margin-bottom: 2%"
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">
        <div [innerHtml]="element.type"></div>
      </td>
    </ng-container>
    <ng-container matColumnDef="power">
      <th mat-header-cell *matHeaderCellDef>
        <div *ngIf="is_speed_sport">Average speed</div>
        <div *ngIf="!is_speed_sport">Average power</div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="is_speed_sport">
          {{ element.speed }}
        </div>
        <div *ngIf="!is_speed_sport">
          {{ element.power }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef>Duration</th>
      <td mat-cell *matCellDef="let element">{{ element.duration }}</td>
    </ng-container>
    <ng-container matColumnDef="additional">
      <th mat-header-cell *matHeaderCellDef>Additional Value</th>
      <td mat-cell *matCellDef="let element">
        <div [innerHtml]="element.additionalValueString"></div>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="removeMeasuredValue(element.id)">
          Delete
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-tab-group (selectedTabChange)="resetDisableButtons($event)">
    <mat-tab *ngFor="let item of items" [disabled]="!isGraphItem(item)">
      <ng-template *ngIf="isGraphItem(item)" mat-tab-label>
        {{ item.fileName }} <br />
        ({{ item.date }} {{ item.fromTill }})
        <button mat-button (click)="removeTab($event, item)" color="accent">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
      <ng-template *ngIf="!isGraphItem(item)" mat-tab-label>
        <button mat-button (click)="addFile()" color="primary">
          <span class="material-icons">add</span>
          Add file
        </button>
      </ng-template>
      <app-single
        [serie]="item"
        (evaluateButtonsState)="evaluateButtonsState($event)"
      ></app-single>
    </mat-tab>
  </mat-tab-group>
</div>
