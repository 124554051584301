import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class CurrencyResultDto extends PagedResultDto {
  public items: CurrencyDto[] = [];
}

export class CurrencyDto extends Deserializable {
  public id: number | undefined;
  public name: string | undefined;
  public iso: string | undefined;
  public created_at: Date | undefined;
  public updated_at: Date | undefined;
  public is_delete: boolean | undefined;
}
