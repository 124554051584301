import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PdMultiSelectDialogComponent } from "@shared/dialogs/pd-multi-select-dialog/pd-multi-select-dialog.component";
import { VisualizationService } from "@shared/services/visualization.service";

@Component({
  selector: 'app-performance-development-wrapper',
  templateUrl: './performance-development-wrapper.component.html',
  styleUrls: ['./performance-development-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PerformanceDevelopmentWrapperComponent implements OnInit {
  private dialog: MatDialog = inject(MatDialog);
  private visualizationService: VisualizationService = inject(VisualizationService);

  public yAxis1: string[] = [];
  public yAxis2: string[] = [];
  public yAxis3: string[] = [];
  public plotConfig: any;

  @Input() public yAxisConfig: any;
  @Input() public id: any;
  @Input() public optionList: any;
  @Input() public isRemovable: boolean;
  @Input() public simulationType: number;
  @Input() public primaryType: string | undefined;
  @Input() public secondaryType: string | undefined;

  @Output() public onWrapperChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRemoveGraph: EventEmitter<any> = new EventEmitter<any>();

  public ngOnInit(): void {
    this.yAxis1 = this.yAxisConfig.y_axis_configuration.left_1.data;
    this.yAxis2 = this.yAxisConfig.y_axis_configuration.left_2.data;
    this.yAxis3 = this.yAxisConfig.y_axis_configuration.right_1.data;
    this.plotConfig = this.visualizationService.generateChartConfig(this.yAxis1, this.yAxis2, this.yAxis3, this.simulationType, this.primaryType, this.secondaryType);
  }

  public openYAxis1(): void {
    const dialogRef: MatDialogRef<PdMultiSelectDialogComponent> = this.dialog.open(PdMultiSelectDialogComponent, {
      width: '800px',
      data: {
        dataPointList: this.yAxis1,
        optionList: this.optionList,
        title: 'Y-axis 1',
      },
      disableClose: true,
    });
    dialogRef.afterClosed()
      .subscribe({
        next: (yAxisList: any) => {
          if (yAxisList) {
            console.log(yAxisList)
            this.yAxis1 = yAxisList;
            this.plotConfig = this.visualizationService.generateChartConfig(this.yAxis1, this.yAxis2, this.yAxis3, this.simulationType, this.primaryType, this.secondaryType);
            this.onWrapperChanged.emit({
              id: this.id,
              yAxis1: this.yAxis1,
              yAxis2: this.yAxis2,
              yAxis3: this.yAxis3,
            });
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      })
  }

  public openYAxis2(): void {
    const dialogRef: MatDialogRef<PdMultiSelectDialogComponent> = this.dialog.open(PdMultiSelectDialogComponent, {
      width: '800px',
      data: {
        dataPointList: this.yAxis2,
        optionList: this.optionList,
        title: 'Y-axis 2',
      },
      disableClose: true,
    });
    dialogRef.afterClosed()
      .subscribe({
        next: (yAxisList: any) => {
          if (yAxisList) {
            this.yAxis2 = yAxisList;
            this.plotConfig = this.visualizationService.generateChartConfig(this.yAxis1, this.yAxis2, this.yAxis3, this.simulationType, this.primaryType, this.secondaryType);
            this.onWrapperChanged.emit({
              id: this.id,
              yAxis1: this.yAxis1,
              yAxis2: this.yAxis2,
              yAxis3: this.yAxis3,
            });
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      })
  }

  public onYAxis3Changed(): void {
    const dialogRef: MatDialogRef<PdMultiSelectDialogComponent> = this.dialog.open(PdMultiSelectDialogComponent, {
      width: '800px',
      data: {
        dataPointList: this.yAxis3,
        optionList: this.optionList,
        title: 'Y-axis 3',
      },
      disableClose: true,
    });
    dialogRef.afterClosed()
      .subscribe({
        next: (yAxisList: any) => {
          if (yAxisList) {
            this.yAxis3 = yAxisList;
            this.plotConfig = this.visualizationService.generateChartConfig(this.yAxis1, this.yAxis2, this.yAxis3, this.simulationType, this.primaryType, this.secondaryType);
            this.onWrapperChanged.emit({
              id: this.id,
              yAxis1: this.yAxis1,
              yAxis2: this.yAxis2,
              yAxis3: this.yAxis3,
            });
          }
        },
        error: (error: any) => {
          console.log(error);
        },
      })
  }

  public removeCurrentGraph(): void {
    this.onRemoveGraph.emit(this.id);
  }
}
