import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class NumberFormatService {
  public static locale = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;

  public static currency(value: number, currencyCode: string): string {
    const CurrencyFormat = new Intl.NumberFormat(NumberFormatService.locale, {
      style: 'currency',
      currency: currencyCode,
    });

    return CurrencyFormat.format(value);
  }
}
