import { inject, Injectable } from "@angular/core";
import { MyProfileService } from "@shared/services/my-profile/my-profile.service";
import { IProfileItem } from "@shared/interfaces/profile-item.interface";

@Injectable()
export class MyProfileItemResolver {
  private myProfileService: MyProfileService = inject(MyProfileService);

  public resolve(): IProfileItem[] {
    return this.myProfileService.getProfileItemList();
  }
}
