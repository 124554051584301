<div class="progess-bar__section">
  <div class="label o-flex o-flex-column">
    <div class="name" [innerHTML]="name | truncate: 40"></div>
    <div class="name" *ngIf="subTitle">({{ subTitle }})</div>
  </div>

  <div class="progress-bar">
    <mat-progress-bar
      mode="determinate"
      [value]="percent"
      *ngIf="max"
    ></mat-progress-bar>
    <mat-progress-bar
      mode="determinate"
      value="100"
      *ngIf="percent > 100"
      style="width: 10%; border-left: 1px solid"
    >
    </mat-progress-bar>
    <div *ngIf="percent < 100" style="width: 10%"></div>
  </div>

  <div class="value">{{ use }} of {{ max }}</div>

  <div class="percent">{{ percent || 0 }} %</div>
</div>
