import { Component } from '@angular/core';
import { PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import {OrganizationDto, OrganizationResultDto} from '@core/services/organization/organization-dto';
import { OrganizationSettingService } from '@core/services/organization/organization-setting.service';

@Component({
  selector: 'app-org-tab-statistics',
  templateUrl: './org-tab-statistics.component.html',
  styleUrls: ['./org-tab-statistics.component.scss'],
})
export class OrgTabStatisticsComponent extends PagedListingBaseComponent<OrganizationDto> {
  public dataSource: OrganizationDto[] = [];
  public displayedColumns: string[] = [
    'id',
    'name',
    'is_active',
    'users',
    'athletes',
    'la',
    'ppd',
    'manual',
    'virtual',
    'events',
    'results',
  ];

  constructor(private readonly organizationSettingService: OrganizationSettingService) {
    super();
  }

  protected list(): void {
    this.organizationSettingService.getStatistics().subscribe((res: OrganizationResultDto): void => {
      this.dataSource = res.items;
      this.totalItems = res.totalCount;
    });
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return {} as PagedRequestDto;
  }
}
