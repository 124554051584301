import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IFeaturePrice } from "@shared/interfaces/feature-price-list.interface";

@Component({
  selector: 'app-feature-multi-select',
  templateUrl: './feature-multi-select.component.html',
})
export class FeatureMultiSelectComponent implements OnInit {
  private readonly fb: UntypedFormBuilder = inject(UntypedFormBuilder);

  public multiSelectFeature: UntypedFormGroup = this.fb.group({});
  public featureOptions: IFeaturePrice[];

  @Input() public config: IFeaturePrice[];
  @Output() public addFeatureToPrice: EventEmitter<IFeaturePrice[]> = new EventEmitter<IFeaturePrice[]>();

  public ngOnInit(): void {
    this.featureOptions = this.config;
    this.multiSelectFeature = this.fb.group({
      feature_ids: new UntypedFormControl([]),
    });
  }

  public onSelectFeature(value: IFeaturePrice[]): void {
    this.addFeatureToPrice.emit(value);
    this.multiSelectFeature.get('feature_ids')?.setValue(value);
  }
}
