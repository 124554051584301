import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { Router } from "@angular/router";

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent {
  private router: Router = inject(Router);

  @Input() public config: ITileConfig;
  @Input() public tabIndexToReturn: number;

  public navigateTo(): void {
    this.router.navigate([this.config.urlNavigateTo], {
      queryParams: {
        feature_id: this.config.id,
        tab_index_to_return: this.tabIndexToReturn,
      }
    });
  }
}
