import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { pairwise, startWith } from "rxjs/operators";

@Component({
  selector: 'app-animated-dropdown-menu',
  templateUrl: './animated-dropdown-menu.component.html',
  styleUrls: ['./animated-dropdown-menu.component.scss'],

})
export class AnimatedDropdownMenuComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);

  public form: FormGroup;

  @Input() public optionList: any[];
  @Input() public appliedOptionList: string[] = [];
  @Input() public dropdownConfig: string[] = [];
  @Input() public label: string;
  @Output() public optionChanged: EventEmitter<any> = new EventEmitter<any>();

  public ngOnInit(): void {
    this.initForm(this.dropdownConfig);

    this.form.get('dataValue')?.valueChanges
      .pipe(
        startWith([]),
        pairwise()
      )
      .subscribe({
        next: (res: any[]) => {
          this.optionChanged.emit({
            currentList: res[1],
          });
        }
      });
  }

  public initForm(config: string[]): void {
    this.form = this.fb.group({
      dataValue: [config],
    });
  }
}
