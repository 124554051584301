<h4 mat-dialog-title>Manual Entry of Threshold (CP)</h4>
<h2 style="text-align: center">Threshold (CP) Manual Entry</h2>
<mat-dialog-content>
  <div [formGroup]="form" class="form-box">
    <div *ngIf="!data.is_speed_sport" class="form-row">
      <p style="width: 80px">Anaerobic threshold</p>
      <mat-form-field
        class="form-field"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Anaerobic threshold</mat-label>
        <input
          matInput
          type="number"
          min="10"
          max="550"
          formControlName="threshold"
        />
        <mat-error
          *ngIf="
            form.get('threshold')?.errors && form.get('threshold')?.touched
          "
          >Anaerobic threshold must be between 10 and 550
        </mat-error>
      </mat-form-field>
      <p>W</p>
    </div>
    <div *ngIf="data.is_speed_sport" class="form-row">
      <p style="width: 80px">Pace</p>
      <mat-form-field
        class="form-field"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Pace</mat-label>
        <input matInput formControlName="pace" />
        <mat-error *ngIf="form.get('pace')?.errors && form.get('pace')?.touched"
          >Pace must be between 0:00 and 9:59
        </mat-error>
      </mat-form-field>
      <p>min:ss/km</p>
    </div>
    <div class="form-row">
      <p style="width: 80px">Tolerance</p>
      <mat-form-field
        class="form-field"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Tolerance</mat-label>
        <input
          matInput
          type="number"
          min="0"
          max="100"
          formControlName="tolerance"
        /><mat-error
          *ngIf="
            form.get('tolerance')?.errors && form.get('tolerance')?.touched
          "
          >Tolerance must be between 0 and 100
        </mat-error>
      </mat-form-field>
      <p>%</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">OK</button>
  </div>
</mat-dialog-actions>
