import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, DestroyRef, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from "@shared/dialogs/confirm-dialog/confirm-dialog.component";
import { ApiRequest } from '@shared/models/api-request';
import * as _ from 'lodash-es';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, finalize, retry } from 'rxjs/operators';
import { AppConstants } from '../constants';
import { SessionService } from "./session.service";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { MatDialogRef } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { HelperService } from "@shared/services/helper.service";

const globalThis = window;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private httpClient: HttpClient = inject(HttpClient);
  private dialog: MatDialog = inject(MatDialog);
  private router: Router = inject(Router);
  private location: Location = inject(Location);
  private sessionService: SessionService = inject(SessionService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private _isLoadingQueue: Array<string> = [];
  private _loadingQueueKey: string = 'LOADING';
  private countCallExpiredSession: number = 0;
  private helperService: HelperService = inject(HelperService);

  public resetCountCallExpiredSession(): void {
    this.countCallExpiredSession = 0;
  }

  public incrementCountCallExpiredSession(): void {
    this.countCallExpiredSession++;
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    this._isLoadingQueue = [];
    localStorage.removeItem(this._loadingQueueKey);
    let errorMessage: string;

    const message: string[] = [];
    let photoKeys: string[] = ['photo', 'watermark_photo', 'username', 'email', 'non_field_errors', 'details'];
    _.each(photoKeys, (key: string): void => {
      if (error?.error && error?.error[key] && error?.error[key].length) {
        if (this.helperService.isArray(error?.error[key])) {
          message.push(error?.error[key][0]);
        } else if (this.helperService.isString(error?.error[key])) {
          message.push(error?.error[key]);
        }
      }
    });

    if (message.length > 0) {
      errorMessage = 'Error: ' + message.join(', ');
      console.log(errorMessage);
      return throwError(errorMessage);
    }

    errorMessage = this.getErrorMessage(error);
    errorMessage = 'Error: ' + errorMessage;

    return throwError(errorMessage);
  }

  public openConfirmDialog(error: string): Observable<boolean> {
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Warning', error, 'Ok', undefined, true);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  private getErrorMessage(error: any): string {
    let errorMessage: string = '';
    const returnedPath: string = this.location.path();

    if (
      error.status == 403 &&
      error?.error?.detail == 'Authentication credentials were not provided.'
    ) {
      if (this.countCallExpiredSession === 0) {
        errorMessage = 'Session expired. Please login';
        this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
        this.openConfirmDialog(errorMessage)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((result: boolean): void => {
            if (result) {
              localStorage.setItem('returnPath', returnedPath);
              this.sessionService.deactivateLoginStatus();
              this.router.navigate(['/'], {
                queryParams: {
                  expired_session: true,
                }
              });
            }
          });
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

        this.incrementCountCallExpiredSession();
      }
    } else if (error.status == 404) {
      errorMessage = 'Resource not found or no permission.';
    } else if (error?.error?.detail) {
      errorMessage = error.error.detail;
      if (errorMessage.indexOf(' ') === -1) {
        errorMessage = errorMessage.replace(/_/g, ' ').toLowerCase();
        errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
      }
    } else if (error?.error?.warning_msg?.length) {
      errorMessage = error.error.warning_msg.join(', ');
    } else if (error?.error?.non_field_errors?.length) {
      errorMessage = error.error.non_field_errors.join(', ');
    } else if (error?.error.summary && error.error.summary === AppConstants.ERROR_MESSAGE.FORCE_UPDATE_CONSENT) {
      globalThis.FORCE_UPDATE_CONSENT.subject_id = error.error.subject_id;
      globalThis.FORCE_UPDATE_CONSENT.subject_email = error.error.subject_email;
      globalThis.FORCE_UPDATE_CONSENT.subject_last_name = error.error.subject_last_name;
      globalThis.FORCE_UPDATE_CONSENT.subject_first_name = error.error.subject_first_name;

      errorMessage = error.error.summary;
      if (errorMessage.indexOf(' ') === -1) {
        errorMessage = errorMessage.replace(/_/g, ' ').toLowerCase();
        errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
      }
    }
    return errorMessage;
  }

  private addApiCallToQueue(api: string, isLoadingQueue: string[]): void {
    if (!isLoadingQueue.includes(api)) {
      isLoadingQueue.push(api);
    }
  }

  private removeItemFromLocalStorageByName(name: string): void {
    localStorage.removeItem(name);
  }

  private removeApiCallFromLocalStorageByKey(api: string, loadingQueueKey: string): string[] {
    const loadQueue: string = localStorage.getItem(loadingQueueKey)!;
    const queue: Array<string> = JSON.parse(loadQueue);
    const currentApiCallIdInArray: number = queue.indexOf(api);
    const REMOVE_COUNT_ITEM: number = 1;


    if (currentApiCallIdInArray > -1) {
      queue.splice(currentApiCallIdInArray, REMOVE_COUNT_ITEM);
    }

    return queue;
  }

  private updateLocalStorageQueue(loadingQueueKey: string, loadingQueueValue: string[]): void {
    localStorage.setItem(loadingQueueKey, JSON.stringify(loadingQueueValue));
  }

  private updateLoadingCallQueue(api: string, loadingQueueKey: string, isHideLoadingInFinalize: boolean): void {
    this._isLoadingQueue = this.removeApiCallFromLocalStorageByKey(api, loadingQueueKey);
    this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);

    if (this._isLoadingQueue.length < 1) {
      if (isHideLoadingInFinalize) {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
      }
      this.removeItemFromLocalStorageByName(this._loadingQueueKey);
    }
  }

  public get(
    api: string,
    isHideLoadingInFinalize: boolean = true,
    isShowLoading: boolean = true
  ): Observable<any> {
    if (isShowLoading) {
      this.addApiCallToQueue(api, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    return this.httpClient.get(AppConstants.API_HOST + api, httpOptions).pipe(
      retry(1),
      catchError((error) => {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

        return this.handleError(error);
      }),
      finalize((): void => {
        if (isShowLoading) {
          this.updateLoadingCallQueue(api, this._loadingQueueKey, isHideLoadingInFinalize);
        }
      })
    );
  }

  public getFile(api: string, isHideLoadingInFinalize: boolean = true, isShowLoading: boolean = true): Observable<ArrayBuffer> {
    if (isShowLoading) {
      this.addApiCallToQueue(api, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
    }

    const httpOptions: any = {
      responseType: 'Blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    return this.httpClient.get(AppConstants.API_HOST + api, httpOptions).pipe(
      retry(1),
      catchError(() => {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

        return throwError('Error: File Not Found');
      }),
      finalize(() => {
        if (isShowLoading) {
          this.updateLoadingCallQueue(api, this._loadingQueueKey, isHideLoadingInFinalize);
        }
      })
    );
  }

  public getText(api: string, isHideLoadingInFinalize: boolean = true, isShowLoading: boolean = true): Observable<ArrayBuffer> {
    if (isShowLoading) {
      this.addApiCallToQueue(api, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      responseType: 'text',
    };
    // @ts-ignore
    return this.httpClient.get(AppConstants.API_HOST + api, httpOptions).pipe(
      retry(1),
      catchError((error: any) => {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

        return this.handleError(error);
      }),
      finalize((): void => {
        if (isShowLoading) {
          this.updateLoadingCallQueue(api, this._loadingQueueKey, isHideLoadingInFinalize);
        }
      })
    );
  }

  public post(api: string,
              body?: any,
              isForm: boolean = false,
              additionalHttpOptions: Record<string, any> = {},
              isShowLoading: boolean = true,
              isHideLoadingInFinalize: boolean = true,
  ): Observable<Object> {
    if (isShowLoading) {
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
      this.addApiCallToQueue(api, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
    }
    let header = {
      'Access-Control-Allow-Origin': '*',
    };
    if (isForm) {
      body = this.prepareFormData(body);
    } else {
      //@ts-ignore
      header['Content-Type'] = 'application/json';
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
      withCredentials: true,
      ...additionalHttpOptions
    };
    return this.httpClient
      .post(AppConstants.API_HOST + api, body, httpOptions)
      .pipe(
        catchError((error) => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

          return this.handleError(error);
        }),
        finalize((): void => {
          if (isShowLoading) {
            this.updateLoadingCallQueue(api, this._loadingQueueKey, isHideLoadingInFinalize);
          }
        }),
      );
  }

  public put(
    api: string,
    body: any,
    isForm: boolean = false,
    isShowLoading: boolean = true,
    isHideLoadingInFinalize: boolean = true,): Observable<Object> {
    if (isShowLoading) {
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
      this.addApiCallToQueue(api, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
    }

    let header = {};
    if (isForm) {
      body = this.prepareFormData(body);
    } else {
      //@ts-ignore
      header['Content-Type'] = 'application/json';
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    return this.httpClient
      .put(AppConstants.API_HOST + api, body, httpOptions)
      .pipe(
        catchError((error) => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

          return this.handleError(error);
        }),
        finalize(() => {
          if (isShowLoading) {
            this.updateLoadingCallQueue(api, this._loadingQueueKey, isHideLoadingInFinalize);
          }
        }),
      );
  }

  public patch(
    api: string,
    body: any,
    isForm: boolean = false,
    isShowLoading: boolean = true,
    isHideLoadingInFinalize: boolean = true,): Observable<Object> {
    if (isShowLoading) {
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
      this.addApiCallToQueue(api, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
    }

    let header = {};
    if (isForm) {
      body = this.prepareFormData(body);
    } else {
      //@ts-ignore
      header['Content-Type'] = 'application/json';
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    return this.httpClient
      .patch(AppConstants.API_HOST + api, body, httpOptions)
      .pipe(
        catchError((error) => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

          return this.handleError(error);
        }),
        finalize(() => {
          if (isShowLoading) {
            this.updateLoadingCallQueue(api, this._loadingQueueKey, isHideLoadingInFinalize);
          }
        }),
      );
  }

  public delete(api: string, isShowLoading: boolean = true, isHideLoadingInFinalize: boolean = true,): Observable<Object> {
    if (isShowLoading) {
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
      this.addApiCallToQueue(api, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };
    return this.httpClient
      .delete(AppConstants.API_HOST + api, httpOptions)
      .pipe(
        catchError((error) => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

          return this.handleError(error);
        }),
        finalize(() => {
          if (isShowLoading) {
            this.updateLoadingCallQueue(api, this._loadingQueueKey, isHideLoadingInFinalize);
          }
        }),
      );
  }

  public parseTextResponse(responseText: any) {
    let response = {};
    try {
      // @ts-ignore
      response = JSON.parseExtended(responseText);
    } catch (error) {
      response = JSON.parse(responseText);
    }
    return response;
  }

  public prepareInputSortAndSearchPage(
    apiUrl: string,
    request: PagedAndSortedRequestDto,
    searchKey: string = 'search'
  ): ApiRequest {
    if (request) {
      apiUrl += `?page=${request.pageIndex}`;
      if (request.keyword) {
        apiUrl += `&${searchKey}=${encodeURI(request.keyword)}`;
      }
      if (request.sorting) {
        const ordering = !request.sortByDesc
          ? request.sorting
          : encodeURI(`-${request.sorting}`);
        apiUrl += `&ordering=${ordering}`;
      } else {
        apiUrl += `&ordering=` + encodeURI(`-id`);
      }
    }
    const body = {};
    return new ApiRequest().deserialize({
      body,
      apiUrl,
    });
  }

  prepareFormData(formDataObj: any): FormData {
    const formData: FormData = new FormData();
    const keys: string[] = Object.keys(formDataObj);
    keys.forEach((key: string): void => {
      const value = formDataObj[key];
      if (value == null) return;
      if (Array.isArray(value)) {
        for (let i of value) {
          formData.append(key + '[]', value[i]);
        }
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  }

  public xhrCall(url: string, formDataObj: any, method: string, isShowLoading: boolean = true, isHideLoadingInFinalize: boolean = true,): Observable<Object> {
    if (isShowLoading) {
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
      this.addApiCallToQueue(url, this._isLoadingQueue);
      this.updateLocalStorageQueue(this._loadingQueueKey, this._isLoadingQueue);
    }

    const apiURL: string = `${AppConstants.API_HOST}${url}`;
    return new Observable((observer: Observer<object>): void => {
      let xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          if (xhr.status >= 200 && xhr.status <= 299) {
            observer.next(xhr);
            observer.complete()
          } else {
            observer.error(xhr);
          }
        }
      };
      const body: FormData = new FormData();
      const keys: string[] = Object.keys(formDataObj);
      keys.forEach((key: string): void => {
        const value = formDataObj[key];
        if (value == null) return;
        if (Array.isArray(value)) {
          for (let i of value) {
            body.append(key + '[]', i);
          }
        } else {
          body.append(key, value);
        }
      });
      xhr.open(method, apiURL, true);
      xhr.withCredentials = true;
      xhr.send(body);
    }).pipe(
      catchError((error) => {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);

        error.error = JSON.parse(error?.response);
        return this.handleError(error);
      }),
      finalize(() => {
        if (isShowLoading) {
          this.updateLoadingCallQueue(url, this._loadingQueueKey, isHideLoadingInFinalize);
        }
      })
    );
  }
}
