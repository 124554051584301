<div class="testimonial">
  <h1>{{config.title}}</h1>
  <div class="testimonial__card-container">
    <ng-container *ngFor="let testimonial of config.testimonialArray">
      <div class="testimonial__card-item">
        <span class="testimonial__title u-font-weight-500">{{testimonial.title}}</span>
        <span class="testimonial__date u-font-weight-100">{{testimonial.date | date: 'dd/MM/yyyy'}}</span>
        <span class="testimonial__description u-font-weight-500 line-height-24">{{testimonial.description}}</span>
        <span class="testimonial__full-name u-font-weight-700 line-height-16">{{testimonial.fullName}}</span>
        <span class="testimonial__location u-font-weight-400 line-height-16">{{testimonial.location}}</span>
      </div>
    </ng-container>
  </div>
</div>
