import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ApiService } from "../api.service";
import { AppConstants } from "../../constants";
import { Observable } from "rxjs";

export interface ReadReportTemplates {
  count: number;
  next: string;
  previous: string;
  results: Array<ReportTemplate>
}

export interface ReportTemplate {
  id?: number;
  name: string;
  description: string;
  is_active: boolean;
  is_public?: boolean;
  is_delete: boolean;
  organization: number;
  created_at?: Date;
  updated_at?: Date;
  fields: Array<ReportField>
}

export interface ReportField {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: string;
  textContent: string;
  textSettingType: string;
}

@Injectable({
	providedIn: 'root'
})
export class ReportTemplateService {
  constructor(private fb: UntypedFormBuilder, private readonly apiService: ApiService) {}

  public getReportTemplates(onlyActive: boolean = false): Observable<ReadReportTemplates> {
    const url: string = onlyActive ?  AppConstants.API.REPORTS.REPORTS_LIST+'?showOnlyActive=true' : AppConstants.API.REPORTS.REPORTS_LIST;

    return this.apiService.get(url) as Observable<ReadReportTemplates>;
  }

  public getReportTemplate(templateId: number): Observable<ReportTemplate> {
    const url: string = AppConstants.API.REPORTS.REPORTS_LIST + templateId;

    return this.apiService.get(url) as Observable<ReportTemplate>;
  }

  public createReportTemplate(template: ReportTemplate): Observable<any> {
    const url: string = AppConstants.API.REPORTS.REPORTS_LIST;

    return this.apiService.post(url, template);
  }

  public updateReportTemplate(template: ReportTemplate): Observable<any> {
    const url: string = AppConstants.API.REPORTS.REPORTS_LIST;

    return this.apiService.put(`${url}${template.id}/`, template);
  }

  public deleteReportTemplate(templateId: number): Observable<any> {
    const url: string = AppConstants.API.REPORTS.REPORTS_LIST;

    return this.apiService.delete(`${url}${templateId}`);
  }
}
