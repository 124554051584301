<div class="home-container">
  <p class="welcome-message">Welcome back</p>
  <div class="home-title">My Dashboard</div>
  <div class="home-section-container">
    <div class="home-section">Quick <span class="u-color-red">Links.</span></div>
    <swiper
      [slidesPerView]="4"
      [spaceBetween]="10"
      [pagination]="{
        clickable: true
      }"
      class="mySwiper"
    >
      <ng-template swiperSlide *ngIf="rights.athletes_list">
        <mat-card class="quick-link athlete">
          <a
            [routerLink]="[
              '/home/apc/setcard',
              selectedTestID ? selectedTestID : ''
            ]"
            class="black-layer"
          >
            <img
              alt="Athlete performance center"
              src="../../../assets/images/quick-links/athlete-performance-center.jpg"
            />
            <div class="info">
              <mat-icon>query_stats</mat-icon>
              <span class="text">Athlete Performance Center</span>
            </div>
          </a>
        </mat-card>
      </ng-template>
      <ng-template
        swiperSlide
        *ngIf="rights.teams_n_competitions_list && rights.teams_list"
      >
        <mat-card class="quick-link athlete">
          <a routerLink="/home/tac/teams" class="black-layer">
            <img
              alt="Teams & Competitions"
              src="../../../assets/images/quick-links/teams-competitions.jpg"
            />
            <div class="info">
              <mat-icon>sports_kabaddi</mat-icon>
              <span class="text">Teams & Competitions</span>
            </div>
          </a>
        </mat-card>
      </ng-template>
      <ng-template swiperSlide *ngIf="rights.loads_list">
        <mat-card class="quick-link athlete">
          <a routerLink="/home/load-analysis" class="black-layer">
            <img
              alt="Load Analysis"
              src="../../../assets/images/quick-links/load-analysis.jpg"
            />
            <div class="info">
              <mat-icon>bar_chart</mat-icon>
              <span class="text">Load Analysis</span>
            </div>
          </a>
        </mat-card>
      </ng-template>
      <ng-template swiperSlide *ngIf="rights.test_list">
        <mat-card class="quick-link athlete">
          <a routerLink="/home/manage/tests" class="black-layer">
            <img
              alt="Manage Tests"
              src="../../../assets/images/quick-links/manage-tests.jpg"
            />
            <div class="info">
              <span class="extra-inscyd-icon-moon-tests">
                <span class="path1"></span><span class="path2"></span
              ><span class="path3"></span><span class="path4"></span
              ><span class="path5"></span><span class="path6"></span
              ><span class="path7"></span><span class="path8"></span
              ><span class="path9"></span><span class="path10"></span
              ><span class="path11"></span><span class="path12"></span>
              </span>
              <span class="text">Manage Tests</span>
            </div>
          </a>
        </mat-card>
      </ng-template>
      <ng-template swiperSlide *ngIf="rights.athletes_list">
        <mat-card class="quick-link athlete">
          <a routerLink="/home/manage/athlete" class="black-layer">
            <img
              alt="Manage Athletes"
              src="../../../assets/images/quick-links/manage-athletes.jpeg"
            />
            <div class="info">
              <span class="extra-inscyd-icon-moon-athletes">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
              </span>
              <span class="text">Manage Athletes</span>
            </div>
          </a>
        </mat-card>
      </ng-template>
      <ng-template
        swiperSlide
        *ngIf="auth.role.is_owner || rights.training_zone_builder"
      >
        <mat-card class="quick-link athlete">
          <a
            [routerLink]="['/home/administration/training-zone-template']"
            class="black-layer"
          >
            <img
              alt="Training Zone Builder"
              src="../../../assets/images/quick-links/training-zone-builder.jpg"
            />
            <div class="info">
              <mat-icon>build</mat-icon>
              <span class="text">Training Zone Builder</span>
            </div>
          </a>
        </mat-card>
      </ng-template>
    </swiper>
  </div>
</div>
