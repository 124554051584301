import { Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { StripeElementsOptions } from '@stripe/stripe-js';
import moment from "moment/moment";
import {
  StripePaymentElementComponent,
  StripeService,
  injectStripe
} from 'ngx-stripe';
import { catchError } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { AuthService } from "@core/services/auth/auth.service";
import { SessionService } from "@core/services/session.service";
import { IAuth } from "../login.component";

const globalThis = window;

enum PASS_TYPE {
  SIGN_IN,
  REGISTER,
  REPEAT
}

@Component({
  selector: 'app-login-registration',
  templateUrl: './login-registration.component.html',
  styleUrls: ['./login-registration.component.scss'],
})
export class LoginRegistrationComponent implements OnInit {
  public auth!: IAuth;
  public signInForm!: UntypedFormGroup;
  public registrationForm!: UntypedFormGroup;
  public showPassSignIn: boolean = false;
  public showPassRegister: boolean = false;
  public showPassRegisterRepeat: boolean = false;
  public isRegistrationSubmitted: boolean = false;
  public clientSecret: string;
  public courseId: number | undefined;

  @ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;
  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  public readonly stripe = injectStripe();

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customRendererService: CustomRendererService,
    private destroyRef: DestroyRef,
    private readonly stripeService: StripeService,
  ) {}
  public initForms(): void {
    const firstName = this.activatedRoute.snapshot.queryParams.first_name;
    const lastName = this.activatedRoute.snapshot.queryParams.last_name;
    const email = this.activatedRoute.snapshot.queryParams.email;

    this.signInForm = this.fb.group({
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });
    this.registrationForm = this.fb.group({
      first_name: new UntypedFormControl(firstName ? firstName : '', Validators.required),
      last_name: new UntypedFormControl(lastName ? lastName : '', Validators.required),
      email: new UntypedFormControl(email ? email : '',
        [
          Validators.required,
          Validators.email
        ]),
      consent_accepted: [false, Validators.requiredTrue],
      register_payment_data: [true],
    });
  }
  ngOnInit(): void {
    this.initStripeOption()
    this.customRendererService.removeClass('body', ['light-theme']);
    this.customRendererService.addClass('body', ['dark-theme']);
    this.courseId = this.activatedRoute.snapshot.queryParams.course_id;
    this.initForms();
  }

  private initStripeOption() {
    this.elementsOptions = {
      locale: 'en',
      appearance: {
        theme: 'night',
      },
    };
  }

  public onShowPassword(regType: PASS_TYPE, evt: MouseEvent): void {
    evt.stopPropagation();
    switch (regType) {
      case PASS_TYPE.REGISTER:
        this.showPassRegister = !this.showPassRegister;
        break;
      case PASS_TYPE.SIGN_IN:
        this.showPassSignIn = !this.showPassSignIn;
        break;
      case PASS_TYPE.REPEAT:
        this.showPassRegisterRepeat = !this.showPassRegisterRepeat;
        break;
      default:
        this.showPassRegister = false;
        this.showPassSignIn = false;
    }
  }

  public login(): void {
    const validationErrorMessage: string = 'Please input email and password.';
    const alreadyLoggedInMessage: string = 'User already logged in another device';
    const invalidCredentialsMessage: string = 'Invalid user credentials.';

    if (!this.signInForm.valid) {
      this.snackBar.open(validationErrorMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }

    this.authService.login(this.signInForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (res: IAuth): void => {
          this.auth = res;
          this.sessionService.setCookie('auth', JSON.stringify(res));
          this.authService.setCookiesForGTM(this.auth);
          this.authService.getRoles(this.auth.role.id, false, this.courseId);
          this.getSession();
          this.setIdeTime();
        },
        (error): void => {
          this.sessionService.deleteAllCookies();
          if (error && error === 'Error: Force update device') {
            globalThis.FORCE_UPDATE_DEVICE = {
              username: this.signInForm.get('username')?.value,
              password: this.signInForm.get('password')?.value,
            };
            this.router.navigateByUrl('/force-update-device');

            return;
          }
          if (error && error === 'Error: User is already logged in another device') {
            this.snackBar.open(alreadyLoggedInMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);

            return;
          }
          this.snackBar.open(invalidCredentialsMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      );
  }

  public getPostAccountRegister(): string {
    return AppConstants.ACCOUNTS_URL.ACCOUNTS + AppConstants.ACCOUNTS_URL.POST.REGISTER;
  }

  public register(): void {
    const requestBody: Record<string, any> = {
      first_name: this.firstNameControl.value,
      last_name: this.lastNameControl.value,
      email: this.emailControl.value,
      consent_accepted: this.consentAcceptedControl.value,
      register_payment_data: this.registerPaymentData.value,
    };

    if (this.courseId) {
      requestBody.course_id = this.courseId;
    }

    this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
    this.apiService.post(this.getPostAccountRegister(), requestBody)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: Record<string, any>): void => {
          if (response) {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
            // this.isRegistrationSubmitted = true;
            this.clientSecret = response["client_secret"];
            this.elementsOptions.clientSecret = this.clientSecret;
            // TODO: Move this
            if (this.courseId) {
              this.router.navigate(['/home/college/consumed-course'], {
                queryParams: {
                  course_id: this.courseId,
                }
              });
            }
          }
        },
        error: (error => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
        }),
      });
  }

  public get firstNameControl() {
    return this.registrationForm.get('first_name') as UntypedFormControl;
  }

  public get lastNameControl() {
    return this.registrationForm.get('last_name') as UntypedFormControl;
  }

  public get emailControl() {
    return this.registrationForm.get('email') as UntypedFormControl;
  }

  get consentAcceptedControl() {
    return this.registrationForm.get('consent_accepted') as FormControl<boolean>;
  }

  get registerPaymentData() {
    return this.registrationForm.get('register_payment_data') as FormControl<boolean>;
  }

  public getSession(): void {
    this.apiService.get(AppConstants.API.SETTINGS.GET_CONFIGURATION, true, false)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((res: any): void => {
        const ideTime = res?.session_idle_time || 3600;
        localStorage.setItem(AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME_UNIT, ideTime);
      });
  }

  public setIdeTime(): void {
    const ideTimeUnit: string | null = localStorage.getItem(
      AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME_UNIT
    );

    if (ideTimeUnit) {
      const ideTime = moment().add(Number(ideTimeUnit) - 60, 'seconds');
      localStorage.setItem(
        AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME,
        ideTime.format(AppConstants.DATE_FORMAT.DATE_TIME_MOMENT)
      );
    }
  }

  public addPaymentMethod() {
    this.stripeService.confirmSetup({
      elements: this.paymentElement.elements,
      confirmParams: {
        return_url: location.origin
      },
    }).subscribe()
  }

  protected readonly open = open;
}
