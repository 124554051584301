<div class="right-dialog__section">
  <mat-dialog-content>
    <fieldset class="infor-fieldset">
      <legend>Role Infos</legend>
      <div class="form-infor" [formGroup]="formInfor">
        <div class="field field-text">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always"
          >
            <mat-label>Name</mat-label>
            <input type="text" matInput placeholder="" formControlName="name" />
            <mat-error
              *ngIf="
                formInfor.controls?.name?.errors &&
                formInfor.controls?.name?.touched
              "
            >
              <div
                [innerHTML]="
                  formInfor.controls.name.errors
                    | errorFormFormat: { fieldName: 'Name' }
                "
              ></div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field-space"></div>
        <div class="field field-toggle">
          <mat-slide-toggle formControlName="isActive">Active</mat-slide-toggle>
        </div>
        <div class="field field-toggle" *ngIf="isOwnerOrAgent">
          <mat-slide-toggle formControlName="isAgentRole"
            >Agent Role</mat-slide-toggle
          >
        </div>
        <div
          class="field field-text"
          *ngIf="!formInfor?.value?.isAgentRole && isOwnerOrAgent"
        >
          <app-autocomplate
            [label]="'Organization'"
            [form]="formInfor.get('organization')"
            [options]="filteredOptions"
            [displayField]="'label'"
            [showErrorRequired]="formInfor.controls.organization.errors?.required"
          ></app-autocomplate>
        </div>
      </div>
    </fieldset>
    <div class="note">
      <span>YES</span>
      <span>NO</span>
    </div>
    <ng-container *ngIf="rightList.length">
      <fieldset class="dynamic-fieldset" *ngFor="let item of rightList">
        <legend>{{ item.name }}</legend>
        <div class="option" *ngFor="let optionItem of item.options">
          <div class="label">{{ optionItem?.name }}</div>
          <mat-radio-group
            class="value"
            [(ngModel)]="rightOptions[optionItem.path]"
          >
            <mat-radio-button
              style="margin-right: 12px"
              color="primary"
              [value]="true"
            ></mat-radio-button>
            <mat-radio-button
              color="primary"
              [value]="false"
            ></mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- subGroup right option -->
        <ng-container *ngFor="let subGroup of item.subGroup">
          <div class="sub-title">{{ subGroup.name }}</div>
          <div class="option" *ngFor="let subOptionItem of subGroup.options">
            <div class="label">{{ subOptionItem?.name }}</div>
            <mat-radio-group
              class="value"
              [(ngModel)]="rightOptions[subOptionItem.path]"
              [disabled]="disableRightsOption(subOptionItem.path)"
            >
              <mat-radio-button
                style="margin-right: 12px"
                color="primary"
                [value]="true"
              ></mat-radio-button>
              <mat-radio-button
                color="primary"
                [value]="false"
              ></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </fieldset>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div>
      <button
        id="cancelButton"
        mat-raised-button
        (click)="close()"
        type="button"
      >
        Cancel
      </button>
      <button
        id="saveButton"
        mat-raised-button
        color="primary"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
