<mat-form-field
  appearance="outline"
  floatLabel="always"
  class="full-width"
  *ngIf="inputControl"
>
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="inputControl"
    [matAutocomplete]="auto"
    #inputAutoComplete
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="optionSelected()"
    (opened)="onOpened()"
    (closed)="onClosed()"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option[displayField] }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="showErrorRequired">
    {{ label }} is <strong>required</strong>
  </mat-error>
</mat-form-field>
