import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class PpdService {
  public g: number = 9.806;

  constructor() {}

  public average(items: number[]): number {
    let sum: number = 0.0;
    let count: number = 0;
    items.forEach((item: number): void => {
      if (item) {
        sum += item;
        count += 1;
      }
    });

    return sum / count;
  }

  public calc_ForwardAccelerationAF(delta_speed: number, delta_time: number): number {
    try {
      return delta_speed / delta_time;
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_EquivalentSlopeES(forward_acceleration_af: number): number {
    try {
      return forward_acceleration_af / this.g;
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_EquivalentBodyMassEM(forward_acceleration_af: number): number {
    try {
      return Math.sqrt(
        (forward_acceleration_af * forward_acceleration_af) /
          (this.g * this.g) +
          1
      );
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_GE(watto2_eq: number): number {
    try {
      return 60 / (watto2_eq * 20.9);
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_EnergyDemandRunning(vo2tot_ebg: number, speed_t: number, watto2_eq: number, ge: number): number {
    try {
      return vo2tot_ebg / 60 / speed_t / (watto2_eq / 60) / ge;
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_EnergyCostRunning(eq_slope_es: number, eq_body_mass_em: number, e_cost_run_ebg: number): number {
    try {
      return (
        (155.4 * Math.pow(eq_slope_es, 5) -
          30.4 * Math.pow(eq_slope_es, 4) -
          43.3 * Math.pow(eq_slope_es, 3) +
          46.3 * Math.pow(eq_slope_es, 2) +
          19.5 * eq_slope_es +
          e_cost_run_ebg) *
        eq_body_mass_em
      );
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_MetabolicPower(e_cost_run_cr: number, delta_dist: number, delta_time: number): number {
    try {
      return (e_cost_run_cr * delta_dist) / delta_time;
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_MetabolicPowerFromSpeed(e_cost_run_cr: number, speed: number): number {
    try {
      return e_cost_run_cr * speed;
    } catch (e) {
      return this.calc_Error(e);
    }
  }

  public calc_MechanicalPower(power_met: number, body_mass: number, ge: number): number {
    try {
      return power_met * body_mass * ge;
    } catch (e) {
      return this.calc_Error(e);
    }
  }


  public calc_vo2tot(speed: number, a: number, b: number, d: number): number {
    return Math.pow(speed, 3) * d + b * speed + a;
  }

  public calc_MechanicalPower_SelectionCase_Override(a: number, b: number, d: number, data_speed: any, data_time: any, watto2_eq: number, body_mass: number): any[] {
    let min_data_length: number = Math.min(data_speed.length, data_time.length);
    let powers: any[] = [];
    let prevDistance: number = 0;

    for (let i = 1; i < min_data_length; i++) {
      let delta_speed: number = data_speed[i] - data_speed[i - 1];
      let delta_time: number = Math.abs(data_time[i] - data_time[i - 1]);
      let speed = data_speed[i];
      let distance: number = data_speed[i] * delta_time + prevDistance;
      let delta_dist: number = Math.abs(distance - prevDistance); // Convert km to m
      prevDistance = distance;
      let result: any = this.calc_MechanicalPower_with_AF(
        speed,
        a,
        b,
        d,
        delta_speed,
        delta_time,
        delta_dist,
        watto2_eq,
        body_mass
      );
      powers.push(result['power']);
    }
    return powers;
  }

  public calc_MechanicalPower_SelectionCase_Smooth(a: number, b: number, d: number, data_speed: any, data_time: any, watto2_eq: number, body_mass: number): any[] {
    let min_data_length: number = Math.min(data_speed.length, data_time.length);
    let powers: any[] = [];
    let prevDistance: number = 0;

    for (let i = 1; i < min_data_length; i++) {
      let delta_speed: number = (data_speed[i] - data_speed[i - 1]) / 3.6;
      let delta_time: number = Math.abs(data_time[i] - data_time[i - 1]);
      let speed: number = data_speed[i] / 3.6;
      let distance: number = speed * delta_time + prevDistance;
      let delta_dist: number = Math.abs(distance - prevDistance); // Convert km to m
      prevDistance = distance;
      let result: any = this.calc_MechanicalPower_with_AF(speed, a, b, d, delta_speed, delta_time, delta_dist, watto2_eq, body_mass);
      powers.push(result['power']);
    }

    return powers;
  }

  public calc_MechanicalPower_with_AF(speed: any,a: number, b: number, d: number, delta_speed: number, delta_time: number, delta_dist: number, watto2_eq: number, body_mass: number) {
    let ge: number = this.calc_GE(watto2_eq);
    let forward_acceleration_af = this.calc_ForwardAccelerationAF(
      delta_speed,
      delta_time
    );
    let eq_slope_es: number = this.calc_EquivalentSlopeES(forward_acceleration_af);
    let eq_body_mass_em: number = this.calc_EquivalentBodyMassEM(
      forward_acceleration_af
    );
    let vo2tot: number = this.calc_vo2tot(speed, a, b, d);
    let e_cost_run_ebg: number = this.calc_EnergyDemandRunning(vo2tot, speed, watto2_eq, ge);
    let e_cost_run_cr = this.calc_EnergyCostRunning(eq_slope_es, eq_body_mass_em, e_cost_run_ebg);
    let power_met = this.calc_MetabolicPower(e_cost_run_cr, delta_dist, delta_time);
    let mechanical_power = this.calc_MechanicalPower(power_met, body_mass, ge);
    let result: any = {};
    result['ge'] = ge;
    result['forward_acceleration_af'] = forward_acceleration_af;
    result['eq_slope_es'] = eq_slope_es;
    result['eq_body_mass_em'] = eq_body_mass_em;
    result['vo2tot'] = vo2tot;
    result['e_cost_run_ebg'] = e_cost_run_ebg;
    result['e_cost_run_cr'] = e_cost_run_cr;
    result['power_met'] = power_met;
    result['power'] = mechanical_power;

    return result;
  }

  public overrideSprintData(avg_speed: any, duration: any): any {
    try {
      let ave = avg_speed; // Average Speed
      let localMax: number = Math.ceil(ave); // Max Speed at localMax seconds
      let totalTime = duration; // totalTime of graph, 16s
      let percentageDecline: number = 3; // Percentage Decline, dy/dx = percentageDecline/100
      let startDecline: number = 10; // Start decling at startDecline seconds
      let n: number = totalTime * 10000;
      let dt: number = totalTime / n;
      let speed: number[] = this.np_zeros(n + 1);
      let time: number[] = this.np_zeros(n + 1);
      let ddist: number[] = this.np_zeros(n + 1);
      let pertMax: number = 96.99;
      let exponent: number = Math.log(1 - pertMax / 100) / localMax;
      let offSet: number = (percentageDecline / 100) * startDecline;

      // Calculating speed, time, distance
      for (let i = 0; i < n + 1; i++) {
        time[i] = dt * i;
        speed[i] = ave * (1 - Math.exp(exponent * time[i]));
        // Stepwise decline
        if (time[i] >= startDecline) {
          speed[i] = speed[i] - ((percentageDecline / 100) * time[i] - offSet);
        }
        ddist[i] = speed[i] * dt;
      }

      // Extracting t=n speeds
      let tmpData: any = this.np_zeros([Math.floor(totalTime) + 1, 2]);
      let k: number = 0;

      for (let i = 0; i < speed.length; i++) {
        if (time[i] % 1 == 0) {
          tmpData[k] = [time[i], speed[i]];
          k++;
        }
      }

      // Calculating dAve

      // tmpData[0:,1] to js
      let newTmpData: any = [];
      for (let i = 0; i < tmpData.length; i++) {
        newTmpData.push(tmpData[i][1]);
      }
      let dAve: number = ave - _.mean(newTmpData); // 0.6405
      for (let i = 1; i < speed.length; i++) {
        speed[i] = speed[i] + dAve + dAve / k;
      }

      // Riemann sum of distance
      let dist: any = this.np_cumsum(ddist);

      // Decide the ticklabel position in the new x-axis,
      // then convert them to the position in the old x-axis
      const distanceInd: number = 6;
      const distlabel = this.np_zeros([distanceInd + 1, 1]);
      const distPos = this.np_zeros([distanceInd + 1, 1]);

      for (let i = 0; i < distanceInd + 1; i++) {
        let ind: number = 0;
        if (i == 0) {
          ind = 0;
        } else {
          ind = (i * n) / distanceInd - 1;
        }
        distlabel[i] = Math.round(Math.abs(dist[ind]));
        distPos[i] = time[ind];
      }
      // Exporting to csv
      // Extracting t=n speeds
      let dataExport: any = this.np_zeros([Math.floor(totalTime) + 1, 2]);
      k = 0;

      for (let i = 0; i < speed.length; i++) {
        if (time[i] % 1 == 0) {
          dataExport[k] = [time[i], speed[i]];
          k++;
        }
      }

      let allSpeeds: any[] = [];
      let selected_time: any[] = [];
      for (let i = 0; i < dataExport.length; i++) {
        allSpeeds.push(dataExport[i][1]);
        selected_time.push(i);
      }
      return {
        selected_speed: allSpeeds,
        selected_time: selected_time,
      };
    } catch (e) {
      console.log('Cannot calculate speed & time for Override Function');
      console.log(e);
    }
  }

  public np_zeros(n: any): number[] {
    let values: any[] = [];
    for (let i = 0; i < n; i++) {
      values.push(0);
    }

    return values;
  }

  public np_cumsum(values: number[]): number {
    let sum: number = 0;
    values.forEach((x: number): void => {
      sum += x;
    });

    return sum;
  }

  // Error handler for all calculations

  public calc_Error(e: any): number {
    console.log(e);

    return NaN;
  }

  public get_vo2tot(speed: number, test: any): number {
    let d = test.du_override;
    let a = test.au_override;
    let b = test.bu_override;

    return this.calc_vo2tot(speed, a, b, d);
  }

  public calculateWorkMeanMaxPowerdialog(
    is_speed_sport: boolean,
    duration: number,
    average_power: number,
    average_speed: number,
    test: any
  ): any {
    let work;
    if (!is_speed_sport) {
      if (!isNaN(duration) && !isNaN(average_power)) {
        work = Math.round(duration * average_power * 100) / 100;
      }
    } else if (is_speed_sport) {
      if (!isNaN(duration) && !isNaN(average_speed)) {
        let vo2tot: number = this.get_vo2tot(average_speed, test.origin);
        let mass = test.origin.mass;
        let watto2eq = test.origin.get_o2_per_watt_eq_b;
        let power: number = (vo2tot * mass) / watto2eq;
        let average_power: number = Math.round(power * 100) / 100;
        work = Math.round(duration * average_power * 100) / 100;
      }
    }

    return work;
  }

  public updateSprintDialogMaxEffortDialogValues(newValues: any): any {
    let lactate_rest_values: any[] = [];
    let post_lactate_values: any[] = [];
    if (!isNaN(newValues.duration_efforts)) {
      if (!isNaN(newValues.lactate_rest_1) && newValues.lactate_rest_1 != null) {
        lactate_rest_values.push(parseFloat(newValues.lactate_rest_1));
      }
      if (!isNaN(newValues.lactate_rest_2) && newValues.lactate_rest_2 != null) {
        lactate_rest_values.push(parseFloat(newValues.lactate_rest_2));
      }
      if (!isNaN(newValues.lactate_rest_3) && newValues.lactate_rest_3 != null) {
        lactate_rest_values.push(parseFloat(newValues.lactate_rest_3));
      }

      if (!isNaN(newValues.post_lactate_1) && newValues.post_lactate_1 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_1));
      }
      if (!isNaN(newValues.post_lactate_2) && newValues.post_lactate_2 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_2));
      }
      if (!isNaN(newValues.post_lactate_3) && newValues.post_lactate_3 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_3));
      }
      if (!isNaN(newValues.post_lactate_4) && newValues.post_lactate_4 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_4));
      }
      if (!isNaN(newValues.post_lactate_5) && newValues.post_lactate_5 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_5));
      }
      if (!isNaN(newValues.post_lactate_6) && newValues.post_lactate_6 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_6));
      }
      if (!isNaN(newValues.post_lactate_7) && newValues.post_lactate_7 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_7));
      }
      if (!isNaN(newValues.post_lactate_8) && newValues.post_lactate_8 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_8));
      }
      if (!isNaN(newValues.post_lactate_9) && newValues.post_lactate_9 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_9));
      }
      if (!isNaN(newValues.post_lactate_10) && newValues.post_lactate_10 != null) {
        post_lactate_values.push(parseFloat(newValues.post_lactate_10));
      }
    }

    return { lactate_rest_values, post_lactate_values };
  }
}
