import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { ICurrentAccount } from "@shared/interfaces/current-account.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  ChangePasswordDialogComponent
} from "@shared/dialogs/change-password-dialog/change-password-dialog/change-password-dialog.component";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private location: Location = inject(Location);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private apiService: ApiService = inject(ApiService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private matDialog: MatDialog = inject(MatDialog);

  public personalInfoForm: UntypedFormGroup;

  public ngOnInit(): void {
    this.initPersonalInfoForm();
  }

  public initPersonalInfoForm(): void {
    const currentAccount: ICurrentAccount = this.activatedRoute.snapshot.data.data;

    this.personalInfoForm = this.fb.group({
      id: [currentAccount.id],
      username: [currentAccount.username],
      email: [currentAccount.email],
      first_name: [currentAccount.first_name],
      last_name: [currentAccount.last_name],
      phone: [currentAccount.phone],
      role: [currentAccount.role.name],
    });
  }

  public save(): void {
    if (this.personalInfoForm.invalid) return;
    this.apiService
      .xhrCall('accounts/current/', {...this.personalInfoForm.value}, 'PUT')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.snackBar.open('Personal info has been successfully saved', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        },
        error: () => {
          this.snackBar.open('Please check the fields and try again', 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      });
  }

  public back(): void {
    this.location.back();
  }

  public openChangePasswordDialog(): void {
  const dialogRef: MatDialogRef<ChangePasswordDialogComponent> = this.matDialog.open(ChangePasswordDialogComponent, {
    width: '780px',
    disableClose: true,
  });

  dialogRef.afterClosed()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(
      {
        next: (dialogResponse: boolean): void => {
          if (dialogResponse) {
            this.snackBar.open('Password has been successfully changed', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          }
        },
        error: () => {
          this.snackBar.open('Please check the fields and try again', 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      }
    );
  }

  public get role(): string {
    return this.personalInfoForm.get('role')?.value;
  }
}
