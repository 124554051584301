import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {} from 'googlemaps';
import {AppConstants} from '@core/constants';
import * as _ from 'lodash-es';

declare var Bokeh: any;

interface CustomMapOptions extends google.maps.MapOptions {
  styles?: google.maps.MapTypeStyle[];
}

// Constants to be refactor later
let BORDER_COLOR = '#444444';
let GRID_LINE_COLOR = '#444444';
let CROSSHAIR_LINE_COLOR = '#888888';
let CHART_BORDER_FILL_COLOR = '#282929';
let CHART_BACKGROUND_FILL_COLOR = '#111111';
let PIE_CHART_BACKGROUND_FILL_COLOR = '#282929';
let LEGEND_BORDER_FILL_COLOR = '#282929';
let LEGEND_BACKGROUND_FILL_COLOR = '#111111';
let LEGEND_BACKGROUND_FILL_ALPHA = 0.75;
let LABEL_TEXT_COLOR = '#aaa';
let CHART_TITLE_COLOR = '#ddd';
let CHART_AXIS_LABEL_TEXT_COLOR = '#999999';
let LEGEND_BORDER_WIDTH = 0;
let CHART_FULL_WIDTH = 1160;
let CHART_HALF_WIDTH = 520;
let CHART_HEIGHT = 380;

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss'],
})
export class SingleComponent implements OnInit, AfterViewInit {
  @Output() public evaluateButtonsState: EventEmitter<any> = new EventEmitter<any>();
  @Input() public serie!: any;

  @ViewChildren('maps') public divs!: QueryList<ElementRef>;
  @ViewChildren('plots') public divPlots!: QueryList<ElementRef>;

  public map!: google.maps.Map;
  public path!: google.maps.Polyline;
  public selectedPath!: google.maps.Polyline;
  public dataSource: any[] = [];
  public id: number | undefined;
  public athleteWeight: number = 0;
  public sport_type: number | undefined;
  public lines: any;
  public x: any;
  public y: any;
  public overallMaxVal: any;
  public yPadding: any;
  public selectionStartTime: string = '';
  public selectionEndTime: string = '';
  public averageSelectedPower: number = 0;
  public averageSelectedCadence: number = 0;
  public averageSelectedHeartRate: number = 0;
  public averageSelectedSpeed: number = 0;
  public averageSelectedEnergy: number = 0;
  public averageSelectedDistance: number = 0;
  public averagePreSelectedPower: any;
  public averagePreSelectedSpeed: any;
  public selectedTime: any = [];
  public selectedPower: any = [];
  public selectedHeartRate: any = [];
  public selectedEnergy: any = [];
  public selectedCadence: any = [];
  public selectedSpeed: any = [];
  public selectedDistance: any = [];
  public preSelectedTime: any = [];
  public preSelectedSpeed: any = [];
  public preSelectedPower: any = [];
  public time: any = [];
  public power: any = [];
  public speed: any = [];
  public cadence: any = [];
  public altitude: any = [];
  public heartrate: any = [];
  public timeStrings: any = [];
  public isDark: boolean = false;

  constructor() {}

  public updateColorTheme(): void {
    const currentTheme: string | null = localStorage.getItem('theme');
    if (currentTheme == AppConstants.THEME.LIGHT) {
      BORDER_COLOR = '#BBBBBB';
      GRID_LINE_COLOR = '#BBBBBB';
      CROSSHAIR_LINE_COLOR = '#888888';
      CHART_BORDER_FILL_COLOR = '#FBFBFB';
      CHART_BACKGROUND_FILL_COLOR = '#FBFBFB';
      PIE_CHART_BACKGROUND_FILL_COLOR = '#FBFBFB';
      LEGEND_BORDER_FILL_COLOR = '#CCCCCC';
      LEGEND_BACKGROUND_FILL_COLOR = '#EEEEEE';
      LABEL_TEXT_COLOR = '#444444';
      CHART_TITLE_COLOR = '#222222';
      CHART_AXIS_LABEL_TEXT_COLOR = '#444444';
    } else {
      BORDER_COLOR = '#444444';
      GRID_LINE_COLOR = '#444444';
      CROSSHAIR_LINE_COLOR = '#888888';
      CHART_BORDER_FILL_COLOR = '#282929';
      CHART_BACKGROUND_FILL_COLOR = '#111111';
      PIE_CHART_BACKGROUND_FILL_COLOR = '#282929';
      LEGEND_BORDER_FILL_COLOR = '#282929';
      LEGEND_BACKGROUND_FILL_COLOR = '#111111';
      LABEL_TEXT_COLOR = '#aaa';
      CHART_TITLE_COLOR = '#ddd';
      CHART_AXIS_LABEL_TEXT_COLOR = '#999999';
    }
  }

  public ngOnInit(): void {
    const currentTheme: string | null = localStorage.getItem('theme');
    this.isDark = currentTheme == AppConstants.THEME.DARK;
    this.id = this.serie.id;
    this.athleteWeight = this.serie.athleteWeight;
    this.sport_type = this.serie.sport_type;
    this.lines = this.serie.lines;
    this.x = this.serie.lines ? this.serie.lines[0].x : undefined;
    this.y = this.serie.lines ? this.serie.lines[0].y : undefined;
    this.setOverallMaxVal();

    this.serie.table.forEach((item: any): void => {
      // 1: altitude, 2: cadence, 5: hr, 8: power, 9: speed
      this.time.push(item[0] * 1000);
      this.power.push(item[8]);
      this.speed.push(item[9]);
      this.cadence.push(item[2]);
      this.altitude.push(item[1]);
      this.heartrate.push(item[5]);
      let date: Date = new Date(item[0] * 1000);
      let timeStr: string = date.getHours() + ':' + this.padWithZero(date.getMinutes()) + ':' + this.padWithZero(date.getSeconds());
      this.timeStrings.push(timeStr);
    });

    this.dataSource = new Bokeh.ColumnDataSource({
      data: {
        timeValues: this.time,
        powerValues: this.power,
        speedValues: this.speed,
        cadenceValues: this.cadence,
        altitudeValues: this.altitude,
        heartrateValues: this.heartrate,
        timeStringValues: this.timeStrings,
      },
    });

    document.addEventListener('box_selection_performed_' + this.id, this.onBoxSelect);
  }

  public ngAfterViewInit(): void {
    const id: string = 'map' + this.id;
    const currentTheme: string | null = localStorage.getItem('theme');
    let mapOptions: CustomMapOptions = {
      zoom: 2,
      center: new google.maps.LatLng(20, -30),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    if (currentTheme == AppConstants.THEME.DARK) {
      mapOptions = {
        zoom: 2,
        center: new google.maps.LatLng(20, -30),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          {
              "featureType": "all",
              "elementType": "labels",
              "stylers": [
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "saturation": 36
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 40
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 16
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 20
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  },
                  {
                      "weight": 1.2
                  }
              ]
          },
          {
              "featureType": "administrative.country",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#e5c163"
                  }
              ]
          },
          {
              "featureType": "administrative.locality",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#c4c4c4"
                  }
              ]
          },
          {
              "featureType": "administrative.neighborhood",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#e5c163"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 20
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 21
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "poi.business",
              "elementType": "geometry",
              "stylers": [
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#e5c163"
                  },
                  {
                      "lightness": 0
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "color": "#e5c163"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 18
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#575757"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#ffffff"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "color": "#2c2c2c"
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 16
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#999999"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 19
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  }
              ]
          }
      ]
      };
    }

    this.map = new google.maps.Map(
      this.divs.filter(
        (div: ElementRef): boolean => div.nativeElement.id == id
      )[0].nativeElement,
      mapOptions
    );

    //map setting here
    let coords: any = [];
    let latMin: number = 1000;
    let lngMin: number = 1000;
    let latMax: number = -1000;
    let lngMax: number = -1000;
    this.serie.table.forEach((record: any): void => {
      if (record[6] == null || record[7] == null || record[6] == 0 || record[7] == 0) {
        return;
      }

      let coord: any = {};
      if (record[6] != null && record[7] != null) {
        coord['lat'] = record[6];
        coord['lng'] = record[7];
        coords.push(coord);
      }
    });

    coords.forEach((coord: any): void => {
      if (coord.lat < latMin) latMin = coord.lat;
      if (coord.lng < lngMin) lngMin = coord.lng;
      if (coord.lat > latMax) latMax = coord.lat;
      if (coord.lng > lngMax) lngMax = coord.lng;
    });
    let latCenter: number = (latMin + latMax) / 2.0;
    let lngCenter: number = (lngMin + lngMax) / 2.0;
    this.map.setCenter({ lat: latCenter, lng: lngCenter });
    this.map.setZoom(11);

    this.path = new google.maps.Polyline({
      path: coords,
      geodesic: true,
      strokeColor: 'steelblue',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    this.path.setMap(null);
    this.path.setMap(this.map);
    this.onBtnClick();
  }

  public setOverallMaxVal(): void {
    let overallMaxVal: number = 0;
    this.serie.table.forEach((item: any): void => {
      // 1: altitude, 2: cadence, 5: hr, 8: power, 9: speed
      if (item[1] > overallMaxVal) overallMaxVal = item[1];
      if (item[2] > overallMaxVal) overallMaxVal = item[2];
      if (item[5] > overallMaxVal) overallMaxVal = item[5];
      if (item[8] > overallMaxVal) overallMaxVal = item[8];
      if (item[9] > overallMaxVal) overallMaxVal = item[9];
    });
    this.overallMaxVal = overallMaxVal;
    this.yPadding = 0.06 * this.overallMaxVal;
  }

  public padWithZero(number: number): string {
    if (number < 10) return '0' + number;
    return number.toString();
  }

  public toUTCDate(date: any): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  public onBoxSelect = (event: any): void => {
    if (this.selectedPath) {
      this.selectedPath.setMap(null);
    }

    let lowerDateTime: any = new Date(Math.round(event.detail.lower));
    let upperDateTime: any = new Date(Math.round(event.detail.upper));
    let preDateTime: any = new Date(lowerDateTime - 90000);
    let selectedCoords: any[] = [];

    this.preSelectedTime = [];
    this.preSelectedPower = [];
    this.preSelectedSpeed = [];
    this.selectedTime = [];
    this.selectedPower = [];
    this.selectedHeartRate = [];
    this.selectedEnergy = [];
    this.selectedCadence = [];
    this.selectedSpeed = [];
    this.selectedDistance = [];

    let minIndex: number = 0;
    let offSet: number = 0;

    // Speed will need one more time period to calculate delta
    if (this.sport_type == AppConstants.SPORT_SIMULATION_TYPES[0].id) {
      offSet = 3000;
    } else {
      offSet = 1000;
    }
    this.serie.table.forEach((item: any): void => {
      if (item[0] * 1000 < preDateTime) {
        minIndex++;

        return;
      }
      if (item[0] * 1000 > upperDateTime) return;

      let coord: any = {};
      if (item[6] != null && item[7] != null) {
        coord['lat'] = item[6];
        coord['lng'] = item[7];
        selectedCoords.push(coord);
      }
      if (item[0] * 1000 < lowerDateTime - offSet) {
        this.preSelectedTime.push(item[0]);
        this.preSelectedPower.push(item[8]);
        this.preSelectedSpeed.push(item[9]);
      } else {
        this.selectedTime.push(item[0]);
        this.selectedPower.push(item[8]);
        this.selectedHeartRate.push(item[5]);
        this.selectedCadence.push(item[2]);
        this.selectedSpeed.push(item[9]);
        this.selectedDistance.push(item[3]);
      }
    });

    if (this.selectedTime.length == 0) {
      let item = this.serie.table[minIndex - 1];
      let itemMax = this.serie.table[minIndex];
      this.selectedTime.push(Math.round(event.detail.lower));
      this.selectedPower.push(item[8]);
      this.selectedHeartRate.push(item[5]);
      this.selectedCadence.push(item[2]);
      this.selectedSpeed.push(item[9]);
      this.selectedDistance.push(item[3]);

      let coord: any = {};
      coord['lat'] = item[6];
      coord['lng'] = item[7];
      selectedCoords.push(coord);

      this.selectedTime.push(Math.round(event.detail.upper));
      this.selectedPower.push(itemMax[8]);
      this.selectedHeartRate.push(itemMax[5]);
      this.selectedCadence.push(itemMax[2]);
      this.selectedSpeed.push(itemMax[9]);
      this.selectedDistance.push(itemMax[3]);

      let coordMax: any = {};
      coordMax['lat'] = itemMax[6];
      coordMax['lng'] = itemMax[7];
      selectedCoords.push(coordMax);
    }

    let startTime: Date = this.toUTCDate(lowerDateTime);
    let endTime: Date = this.toUTCDate(upperDateTime);

    // Parse time
    this.selectionStartTime = startTime.getHours() + ':' + this.padWithZero(startTime.getMinutes()) + ':' + this.padWithZero(startTime.getSeconds());
    this.selectionEndTime = endTime.getHours() + ':' + this.padWithZero(endTime.getMinutes()) + ':' + this.padWithZero(endTime.getSeconds());
    let selectionInSeconds = (endTime.getTime() - startTime.getTime()) / 1000;
    this.averagePreSelectedPower = Math.round(
      this.average(this.preSelectedPower)
    );
    this.averagePreSelectedSpeed = Math.round(
      this.average(this.preSelectedSpeed)
    );

    // Parse avg powers
    let filteredPowers = _.takeRight(
      this.selectedPower,
      selectionInSeconds + 1
    );

    this.averageSelectedPower = Math.round(this.average(filteredPowers));

    // Parse avg cadences
    let filteredCadences = _.takeRight(
      this.selectedCadence,
      selectionInSeconds + 1
    );
    this.averageSelectedCadence = Math.round(this.average(filteredCadences));

    // Parse avg heart rates
    let filteredHRs = _.takeRight(
      this.selectedHeartRate,
      selectionInSeconds + 1
    );
    this.averageSelectedHeartRate = Math.round(this.average(filteredHRs));

    // Parse avg speeds
    let filteredSpeeds = _.takeRight(
      this.selectedSpeed,
      selectionInSeconds + 1
    );

    this.averageSelectedSpeed = Math.round(this.average(filteredSpeeds) * 1000) / 1000;

    // Parse distance
    let startDistance = this.selectedDistance[0];
    let endDistance = this.selectedDistance[this.selectedDistance.length - 1];
    this.averageSelectedDistance = Math.round((endDistance - startDistance) * 1000) / 1000;
    this.averageSelectedEnergy = Math.round(
      this.sum(this.selectedPower) / 1000
    );

    // emit which buttons must be enabled / disabled
    let booleanValues = {
      mean_max_power: false,
      single_effort: false,
      sprint: false,
      max_effort: false,
    };

    if (12 <= selectionInSeconds && selectionInSeconds <= 25) {
      booleanValues.sprint = true;
    }
    // 2nd clause is the pre condition for Mean max. power valid during 90 seconds before actual snippet:
    if (this.sport_type == AppConstants.SPORT_SIMULATION_TYPES[0].id) {
      if (12 <= selectionInSeconds && selectionInSeconds <= 24 && this.averagePreSelectedSpeed / 3.6 <= 0.6) {
        booleanValues.mean_max_power = true;
      }
    } else if (this.sport_type == AppConstants.SPORT_SIMULATION_TYPES[1].id) {
      if (12 <= selectionInSeconds && selectionInSeconds <= 24 && this.averagePreSelectedPower / this.athleteWeight <= 0.5) {
        booleanValues.mean_max_power = true;
      }
    }
    if (135 <= selectionInSeconds && selectionInSeconds <= 300) {
      booleanValues.max_effort = true;
    }
    if (135 <= selectionInSeconds && selectionInSeconds <= 1800) {
      booleanValues.mean_max_power = true;
    }
    if (150 <= selectionInSeconds && selectionInSeconds <= 1800) {
      booleanValues.single_effort = true;
    }

    this.evaluateButtonsState.next({
      id: this.id,
      booleans: booleanValues,
      selectionInSeconds: selectionInSeconds + 1,
      selectedPower: this.selectedPower,
      selectedSpeed: this.selectedSpeed,
      selectedTime: this.selectedTime,
      selectedDistance: this.selectedDistance,
      averageSelectedPower: this.averageSelectedPower,
      averageSelectedSpeed: this.averageSelectedSpeed,
      averageSelectedCadence: this.averageSelectedCadence,
      averageSelectedHeartRate: this.averageSelectedHeartRate,
    });

    this.selectedPath = new google.maps.Polyline({
      path: selectedCoords,
      geodesic: true,
      strokeColor: 'orangered',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    this.selectedPath.setMap(null);
    this.selectedPath.setMap(this.map);
  };

  /* ----------------------------------------------------------- */

  public onBtnClick(): void {
    this.updateColorTheme();
    let plt = Bokeh.Plotting;
    let figure_config = {
      toolbar_location: 'above',
      background_fill_color: CHART_BACKGROUND_FILL_COLOR,
      border_fill_color: CHART_BORDER_FILL_COLOR,
      outline_line_color: BORDER_COLOR,
      tools: 'pan,box_zoom,reset,save',
      width: CHART_HALF_WIDTH,
      height: CHART_HEIGHT,
      x_range: new Bokeh.Range1d({
        start: this.time[0],
        end: this.time[this.time.length - 1],
      }),
      x_axis_type: 'datetime',
      y_range: new Bokeh.Range1d({
        start: -1 * this.yPadding,
        end: this.overallMaxVal + this.yPadding,
      }),
    };
    let p = plt.figure(figure_config);
    // powerLine
    p.line(
      { field: 'timeValues' },
      { field: 'powerValues' },
      {
        source: this.dataSource,
        line_width: 2,
        line_color: 'firebrick',
        legend: 'power',
      }
    );
    // speedLine
    p.line(
      { field: 'timeValues' },
      { field: 'speedValues' },
      {
        source: this.dataSource,
        line_width: 2,
        line_color: 'blue',
        legend: 'speed',
      }
    );
    // cadenceLine
    p.line(
      { field: 'timeValues' },
      { field: 'cadenceValues' },
      {
        source: this.dataSource,
        line_width: 2,
        line_color: 'green',
        legend: 'cadence',
      }
    );
    // heartRateLine
    p.line(
      { field: 'timeValues' },
      { field: 'heartrateValues' },
      {
        source: this.dataSource,
        line_width: 2,
        line_color: 'orange',
        legend: 'heart rate',
      }
    );
    // altitudeLine
    p.line(
      { field: 'timeValues' },
      { field: 'altitudeValues' },
      {
        source: this.dataSource,
        line_width: 2,
        line_color: '#ff66cc',
        legend: 'altitude',
      }
    );
    let elem_id: string = 'plot' + this.id;
    let tooltip: string = `<div>time: @timeStringValues</div>
                               <div>power: @powerValues{0} W</div>
                               <div>speed: @speedValues{0.1} km/h</div>
                               <div>cadence: @cadenceValues{0} min<sup>-1</sup></div>
                               <div>heart rate: @heartrateValues{0} min<sup>-1</sup></div>
                               <div>altitude: @altitudeValues{0} m a.s.l.</div>`;
    let hover = new Bokeh.HoverTool({tooltips: tooltip,});
    p.add_tools(hover);

    let rectSource = new Bokeh.ColumnDataSource({
      data: {
        x: [],
        y: [],
        width: [],
        height: [],
      },
    });

    let boxSelectCallbackCode: string =
      `let data = rectSource.data;
                    let geometry = cb_data.geometry;
                    let width = geometry.x1 - geometry.x0;
                    let height = geometry.y1 - geometry.y0;
                    let x = geometry.x0 + width / 2.0;
                    let y = geometry.y0 + height / 2.0;
                    rectSource.data["x"] = [x];
                    rectSource.data["y"] = [y];
                    rectSource.data["width"] = [width];
                    rectSource.data["height"] = [height];
                    rectSource.trigger("change");
                    let customEvent = new CustomEvent("` +
      'box_selection_performed_' +
      this.id +
      `", { detail: { lower: geometry.x0, upper: geometry.x1 } });
                    document.dispatchEvent(customEvent);`;

    let rect = new Bokeh.Rect({
      x: { field: 'x' },
      y: { field: 'y' },
      width: { field: 'width' },
      height: { field: 'height' },
      line_color: '#666699',
      fill_color: '#009933',
      fill_alpha: 0.3,
      line_width: 2,
    });

    let boxSelectCallback = new Bokeh.CustomJS({
      id: p.id,
      code: boxSelectCallbackCode,
      args: {
        rectSource: rectSource,
        chartData: p,
      },
    });

    p.add_glyph(rect, rectSource);
    p.add_tools(
      new Bokeh.BoxSelectTool({
        callback: boxSelectCallback,
        dimensions: 'width',
      })
    );

    // Hide legend, show legend outside as img
    p._legend.location = null;

    let divPlot = this.divPlots.filter(
      (div: ElementRef) => div.nativeElement.id == elem_id
    )[0].nativeElement;

    plt.show(p, divPlot);
  }

  public sum(items: any): number {
    let sum: number = 0.0;
    items.forEach((item: any): void => {
      sum += item;
    });

    return sum;
  }
  public average(items: any): number {
    let sum: number = 0.0;
    items.forEach((item: any) => {
      sum += item;
    });

    return sum / items.length;
  }
}
