import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousand',
})
export class ThousandPipe implements PipeTransform {
  public transform(value: number, toFixed: number = 2): string | number {
    if (!value) {
      return value;
    }
    const formatted = value.toFixed(toFixed);
    return formatted.replace(/\,/g, "'");
  }
}
