import { map, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { of as _observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRequest } from '@shared/models/api-request';
import { ServiceBase } from '../service-base';
import { OrganizationDto, OrganizationResultDto } from './organization-dto';
import { OrganizationPagedResultDto } from './organization-paged-result-dto';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends ServiceBase {
  public listAll(): Observable<OrganizationResultDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATIONS.LIST;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new OrganizationResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: OrganizationDto) =>
            new OrganizationDto().deserialize(item)
          ),
        });
      })
    );
  }

  public list(isHideLoadingInFinalize: boolean = true): Observable<OrganizationPagedResultDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATIONS.LIST;
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService
      .get(apiRequest.apiUrl, isHideLoadingInFinalize)
      .pipe(
        _observableMergeMap((response_: any): Observable<OrganizationPagedResultDto> => {
          if (response_.length === 0) {
            return _observableOf(new OrganizationPagedResultDto());
          }
          const elementPagedResultDto: OrganizationPagedResultDto =
            new OrganizationPagedResultDto().deserialize({
              totalCount: response_.count,
              items: response_.results.map((item: OrganizationDto) =>
                new OrganizationDto().deserialize(item)
              ),
            });
          return _observableOf(elementPagedResultDto);
        })
      );
  }
}
