import { inject, Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { TileService } from "@shared/services/tile/tile.service";
import { catchError, map } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MyInscydService } from "@shared/services/my-inscyd/my-inscyd.service";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { FeatureCategoryTypeEnum } from "@shared/enums/feature-category-type.enum";;
import { IFeatureSport } from "@shared/interfaces/feature-sport.interface";
import { IFeature } from "@shared/components/interfaces/feature.interface";
import { TileTypeNavigationEnum } from "@shared/enums/tile-type-navigation.enum";

@Injectable()
export class MyInscydFeatureItemResolver {
  private tileService: TileService = inject(TileService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private myInscydService: MyInscydService = inject(MyInscydService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);

  public resolve(): Observable<ITileConfig[] | null> {
    const isShowAllFeature: boolean = false;
    const apiList: Observable<any>[] = [
      this.featureStoreService.getFeatureListByCategory(FeatureCategoryTypeEnum.SPORTS, isShowAllFeature),
      this.featureStoreService.getFeatureListByCategory(FeatureCategoryTypeEnum.METRICS, isShowAllFeature),
      this.myInscydService.getHardCodedSettingTileList(),
    ];
    return forkJoin(apiList)
      .pipe(
        map(([featureSportList, featureMetricList, hardcodedTileList]: any[]): ITileConfig[] => {
          const tileBySportCategoryList: ITileConfig[] = featureSportList.map((featureSportType: IFeatureSport): IFeature => {
            return {
              id: featureSportType.id,
              name: featureSportType.name,
              description: featureSportType.description,
              description_html: {},
              is_recommended: false,
              urlNavigateTo: '/home/sport-configuration',
              tags: featureSportType.tags.length ? featureSportType.tags : ['Sport'],
              category_name: featureSportType.category_name,
              marketing_features: [],
              suggested_courses: [],
              discounts: [],
              category: 0,
              updated_at: '',
              waitlist: false,
              is_enabled: false,
              summary: '',
              created_at: '',
              slug: '',
              links: [],
              is_delete: false,
              configuration: featureSportType.configuration,
              dependencies: [],
              is_published: true,
            }
          });

          const tileByMetricCategoryList: ITileConfig[] = featureMetricList.map((featureMetricType: IFeatureSport): IFeature => {
            return {
              id: featureMetricType.id,
              name: featureMetricType.name,
              description: featureMetricType.description,
              description_html: {},
              is_recommended: false,
              urlNavigateTo: '/home/visualization',
              tags: featureMetricType.tags.length ? featureMetricType.tags : ['Metric'],
              category_name: featureMetricType.category_name,
              marketing_features: [],
              suggested_courses: [],
              discounts: [],
              category: 0,
              updated_at: '',
              waitlist: false,
              is_enabled: false,
              summary: '',
              created_at: '',
              slug: '',
              links: [],
              is_delete: false,
              configuration: featureMetricType.configuration,
              dependencies: [],
              is_published: true,
            }
          });

          const tempTileBySportCategory: ITileConfig[] = this.tileService.getGeneratedTileConfigBasedOnResponse<ITileConfig>(
            tileBySportCategoryList,
            'View Details',
            tileBySportCategoryList[0].urlNavigateTo,
            TileTypeNavigationEnum.FEATURE
          );

          const tempTileByMetricCategory: ITileConfig[] = this.tileService.getGeneratedTileConfigBasedOnResponse<ITileConfig>(
            tileByMetricCategoryList,
            'View Details',
            tileByMetricCategoryList[0].urlNavigateTo,
            TileTypeNavigationEnum.FEATURE
          );
          const tempHardcodedTileList: ITileConfig[] = this.tileService.getGeneratedTileConfigBasedOnResponse<ITileConfig>(
            hardcodedTileList,
            );

          return [...tempTileBySportCategory, ...tempHardcodedTileList, ...tempTileByMetricCategory];
        }),
        catchError(error => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          return of(null);
        }
        )
      )
  }
}
