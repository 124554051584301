 <form autocomplete="off" [formGroup]="eventForm" class="createNew">
    <div>
        <div class="">
            <h1 mat-dialog-title>Add Events</h1>
        </div>
        <mat-dialog-content>
            <div class="form-group row header__relative">
                <div class="">
                    <h3 class="">Event Data</h3>
                    <hr>
                </div>
                <div class="mt-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name">
                        <mat-error>
                        </mat-error>
                        <mat-error *ngIf="eventForm.get('name')?.hasError('required') && eventForm.controls.name.touched">
                            Please complete field
                        </mat-error>
                    </mat-form-field>

                </div>
                <div>
                    <mat-form-field
                        appearance="outline">
                        <mat-label>Date(*)</mat-label>
                        <input formControlName="date" matInput
                            [matDatepicker]="picker" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="eventForm.get('date')?.hasError('required') && eventForm.controls.date.touched">
                            Please complete field
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="multiple-select">
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>Sport</mat-label>
                            <mat-select formControlName="sport">
                                <mat-option *ngFor="let option of allSports" [value]="option?.id">
                                    {{option?.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="eventForm.get('sport')?.hasError('required') && eventForm.controls.sport.touched">
                                Please complete field
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>
                <div class="event-Description">
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <input matInput formControlName="desc">
                        <mat-error>
                        </mat-error>
                        <mat-error *ngIf="eventForm.get('desc')?.hasError('required') && eventForm.controls.desc.touched">
                            Please complete field
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="multiple-select">
                    <mat-form-field appearance="fill">
                        <mat-label>Tags</mat-label>
                        <mat-select multiple formControlName="tag">
                            <mat-option *ngFor="let option of allTags" [value]="option?.id">
                                {{option?.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="eventForm.get('tag')?.hasError('required') && eventForm.controls.tag.touched">
                            Please complete field
                        </mat-error>
                    </mat-form-field>

                </div>
                <div class="">
                    <h3 class="">Import</h3>
                    <hr>
                </div>
                <div class="form-group">
                    <mat-label>File Upload </mat-label>
                    <input type="file"
                        id="file"
                        (change)="handleFileInput($event)">
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <div class="mt-3">
                <button mat-raised-button class="create-new-button" (click)="saveAction()">
                    Save
                </button>

                <button mat-raised-button class="secondary" (click)="closeDialog()">
                    Cancel
                </button>
            </div>
        </mat-dialog-actions>

    </div>
</form>
