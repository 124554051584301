import { Component, DestroyRef, inject, Input } from '@angular/core';
import { IIntegration } from "@shared/components/interfaces/integration.interface";
import { AppConstants } from "@core/constants";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { from, Observable } from 'rxjs';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-integration-item',
  templateUrl: './integration-item.component.html',
  styleUrls: ['./integration-item.component.scss']
})
export class IntegrationItemComponent {
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private destroyRef: DestroyRef = inject(DestroyRef);

  @Input() config: IIntegration;

  public copyApiKey(): any {
    const clipboardObs$: Observable<any> = from(navigator.clipboard.writeText(this.config.key));

    clipboardObs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        {
          next: () => {
            this.snackBar.open('API Key copied to clipboard', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: (error: any) => {
            this.snackBar.open('API Key did not copied', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        }
      );
  }
}
