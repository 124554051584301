import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IIndexRangeSlider } from "@shared/interfaces/index-range-slider.interface";
import { IMinMaxSlider } from "@shared/interfaces/min-max-range-slider.interface";
import { config } from "rxjs";

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export  class RangeSliderComponent implements OnInit {
  @Input() public config: IMinMaxSlider;
  @Input() public index: number;
  @Input() public step: string;
  @Input() public metricId: string;

  @Output() public updateTableData: EventEmitter<IMinMaxSlider & IIndexRangeSlider> = new EventEmitter<IMinMaxSlider & IIndexRangeSlider>();

  public currentMinValue: any;
  public currentMaxValue: any;

  public ngOnInit():void {
    this.setInitialCurrentValue();
  }

  public setInitialCurrentValue(): void {
    this.currentMinValue = Object.values(this.config)[0];
    this.currentMaxValue = Object.values(this.config)[1];
  }

  public setMaxCurrentValue(maxValue: number): void {
    this.currentMaxValue = maxValue;
  }

  public setMinCurrentValue(minValue: number): void {
    this.currentMinValue = minValue;
  }

  public getUpdatedRangeSliderData(min: number | undefined, max: number| undefined, index: number, sliderId: string): IMinMaxSlider & IIndexRangeSlider {
    return {
      min,
      max,
      index,
      sliderId,
    }
  }

  public updateMinValue(min: number): void {
    this.setMinCurrentValue(min);
    this.updateTableData.emit(this.getUpdatedRangeSliderData(min, this.currentMaxValue, this.index, this.metricId));
  }

  public updateMaxValue(max: number): void {
    this.setMaxCurrentValue(max);
    this.updateTableData.emit(this.getUpdatedRangeSliderData(this.currentMinValue, max, this.index, this.metricId));
  }
}
