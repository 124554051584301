import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { SportPagedResultDto } from './sport-paged-result-dto';
import { SportDto } from './sport-dto';
import { ServiceBase } from '../service-base';
import { String } from 'typescript-string-operations';
import {ApiRequest} from "@shared/models/api-request";

@Injectable()
export class SportService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto): Observable<SportPagedResultDto> {
    const apiUrl: string = this._appConstants.API.SPORT.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return this.mappingSportPageResultDto(res);
      })
    );
  }

  public getSportById(id: number): Observable<SportDto> {
    const apiUrl: string = String.Format(this._appConstants.API.SPORT.GET_DETAIL, id);

    return this._apiService
      .get(apiUrl)
      .pipe(map((item: Object) => new SportDto().deserialize(item)));
  }

  public createSport(input: unknown): Observable<SportDto> {
    const apiUrl: string = this._appConstants.API.SPORT.ADD;

    return this._apiService
      .post(apiUrl, input)
      .pipe(map((item) => new SportDto().deserialize(item)));
  }

  public updateSport(id: number, input: unknown): Observable<SportDto> {
    const apiUrl: string = String.Format(this._appConstants.API.SPORT.UPDATE, id);

    return this._apiService
      .put(apiUrl, input)
      .pipe(map((item: Object) => new SportDto().deserialize(item)));
  }

  public deleteSport(id: number): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.SPORT.DELETE, id);

    return this._apiService.delete(apiUrl);
  }

  public listAll(): Observable<SportPagedResultDto> {
    const apiUrl: string = this._appConstants.API.SPORT.LIST;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return this.mappingSportPageResultDto(res);
      })
    );
  }

  private mappingSportPageResultDto(data: any): SportPagedResultDto {
    return new SportPagedResultDto().deserialize({
      totalCount: data.count,
      items: data.results.map((item: SportDto) =>
        new SportDto().deserialize(item)
      ),
    });
  }
}
