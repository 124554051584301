import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { PagedAndSortedRequestDto } from "@shared/components/paged-listing-component-base";
import { ICurrentUserSession } from "@shared/interfaces/current-user-session.interface";
import { ActivatedRoute } from "@angular/router";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Observable, of, switchMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { CurrentAccountService } from "@shared/services/current-account/current-account.service";
import { String } from "typescript-string-operations";
import { ConfirmDialogComponent, ConfirmDialogModel } from "@shared/dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialogRef } from "@angular/material/dialog";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
  DeactivateAccountDialogComponent
} from "@shared/dialogs/deactivate-account-dialog/deactivate-account-dialog.component";
import { DeleteAccountDialogComponent } from "@shared/dialogs/delete-account-dialog/delete-account-dialog.component";
import { AuthService } from "@core/services/auth/auth.service";

@Component({
  selector: 'app-current-user-session',
  templateUrl: './current-user-session.component.html',
})
export class CurrentUserSessionComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private apiService: ApiService = inject(ApiService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private currentAccountService: CurrentAccountService = inject(CurrentAccountService);
  private authService: AuthService = inject(AuthService);

  public dialog: MatDialog = inject(MatDialog);
  public confirmationDeactivateTitle: string = 'Yes, deactivate my account?';
  public confirmationDeactivateMessage: string = 'Are you sure?';
  public confirmationDeactivateDialogData: ConfirmDialogModel = new ConfirmDialogModel(this.confirmationDeactivateTitle, this.confirmationDeactivateMessage);
  public constants = AppConstants;
  public destroyRef: DestroyRef = inject(DestroyRef);
  public displayedColumns: string[] = ['device', 'ip', 'last_login', 'action'];
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: ICurrentUserSession[] = [];

  public ngOnInit(): void {
    const userSessionDataList: ICurrentUserSession[] = this.activatedRoute.snapshot.data.data;
    this.fillTable(userSessionDataList);
  }

  public fillTable(userSessionDataList: ICurrentUserSession[]): void {
    this.dataSource = userSessionDataList;
  }

  public resetTableData(): void {
    this.dataSource = [];
  }

  public getAllUserSessionList(): Observable<ICurrentUserSession[] | null> {
    return this.currentAccountService.getAllUserSessionList()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError((error: any) => {
            this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            return of(null);
          }
        )
      );
  }

  public logoutFromSession(element: ICurrentUserSession): void {
    const message: string = 'Are you sure you want to logout?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Confirmation', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((status: boolean): void => {
        if (status) {
          this.apiService.delete(String.Format(this.constants.ACCOUNTS_URL.USER_SESSION_LOGOUT.DELETE, element.id))
            .pipe(
              takeUntilDestroyed(this.destroyRef),
              switchMap(() => {
                return this.getAllUserSessionList();
              }),
            )
            .subscribe({
              next: (sessionList: ICurrentUserSession[] | null) => {
                if (sessionList?.length) {
                  this.resetTableData();
                  this.fillTable(sessionList);
                }
                this.snackBar.open('The session has been logout', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              },
              error: (error: any) => {
                this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
              },
            });
        }
      });
  }

  public logoutFromSessionAllExceptCurrenSession(): void {
    const message: string = 'Are you sure you want to logout all sessions?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Confirmation', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((status: boolean): void => {
        if (status) {
          this.apiService.delete(this.constants.ACCOUNTS_URL.USER_SESSION_ALL_LOGOUT.DELETE)
            .pipe(
              takeUntilDestroyed(this.destroyRef),
              switchMap(() => {
                return this.getAllUserSessionList();
              }),
            )
            .subscribe({
              next: (sessionList: ICurrentUserSession[] | null) => {
                if (sessionList?.length) {
                  this.resetTableData();
                  this.fillTable(sessionList);
                }
                this.snackBar.open('The all sessions have been logout', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              },
              error: (error: any) => {
                this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
              },
            });
        }
      });
  }

  public getDeactivateModalWindow(): MatDialogRef<any> {
    const deactivateTitle: string = 'Deactivate My INSCYD User Account';
    const deactivateMessage: string = 'This will deactivate your account. You can\'t log in until an admin reactivates your account.';
    const deactivateDialogData: ConfirmDialogModel = new ConfirmDialogModel(deactivateTitle, deactivateMessage);

    return this.dialog.open(DeactivateAccountDialogComponent, {
      width: '780px',
      data: deactivateDialogData,
      disableClose: true,
    });
  }

  public deactivateAccount(): void {
    this.runDeactivationModalWindow(this.confirmationDeactivateDialogData);
  }

  public deleteCurrentAccount(): void {
    const confirmDeleteTitle: string = 'Delete Forever?';
    const confirmDeleteMessage: string = 'Are you sure?';
    const confirmDeleteDialogData: ConfirmDialogModel = new ConfirmDialogModel(confirmDeleteTitle, confirmDeleteMessage);

    const deleteTitle: string = 'Delete My INSCYD User Account';
    const deleteMessage: string = 'This will delete your user account. All your personal info (like Name, Email address, etc.) will be deleted. This cannot be undone – your information will be lost. Alternatively, you can deactivate your account, which lets you recover your data later.';
    const deleteDialogData: ConfirmDialogModel = new ConfirmDialogModel(deleteTitle, deleteMessage);
    const deleteDialog: MatDialogRef<DeleteAccountDialogComponent> = this.dialog.open(DeleteAccountDialogComponent, {
      width: '780px',
      data: deleteDialogData,
      disableClose: true,
    });

    deleteDialog.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (status: boolean | string) => {
          if (status === 'deactivate') {
            this.runDeactivationModalWindow(this.confirmationDeactivateDialogData);
          } else if (status) {
            const confirmDeleteDialog: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
              width: '780px',
              data: confirmDeleteDialogData,
              disableClose: true,
            });
            confirmDeleteDialog.afterClosed()
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe({
                next: (status: boolean) => {
                  if (status) {
                    this.apiService.patch(AppConstants.ACCOUNTS_URL.DEACTIVATE_CURRENT_ACCOUNT.PATCH, { is_delete: true })
                      .pipe(
                        takeUntilDestroyed(this.destroyRef),
                        switchMap(() => this.authService.logout()),
                        )
                      .subscribe({
                        next: () => {
                          this.snackBar.open('The current account has been deleted', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
                        },
                        error: (error: any) => {
                          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
                        }
                      });
                  }
                },
              });
          }
        },
      });
  }

  public runDeactivationModalWindow(modalData: any): void {
    this.getDeactivateModalWindow().afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((status: boolean): void => {
        if (status) {
          const confirmationDeleteDialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: modalData,
            disableClose: true,
          });

          confirmationDeleteDialogRef.afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((status: boolean): void => {
              if (status) {
                this.apiService.patch(AppConstants.ACCOUNTS_URL.DEACTIVATE_CURRENT_ACCOUNT.PATCH, { is_active: false })
                  .pipe(takeUntilDestroyed(this.destroyRef),)
                  .subscribe({
                    next: () => {
                      window.location.reload();
                      this.snackBar.open('The current account has been deactivated', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
                    },
                    error: () => {
                      this.snackBar.open('The current account has not been deactivated', 'OK', AppConstants.TOAST_CONFIG.ERROR);
                    }
                  });
              }
            });
        }
      });
  }
}
