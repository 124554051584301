import { Component, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { AppConstants } from '@core/constants';
import {DeviceManagementDto, DeviceManagementResultDto} from '@core/services/device-management/device-management-dto';
import { DeviceManagementService } from '@core/services/device-management/device-management.service';
import { DeviceManagementDialogComponent } from './device-management-dialog/device-management-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { HelperService } from "@shared/services/helper.service";

@Component({
  selector: 'app-device-management',
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.scss'],
})
export class DeviceManagementComponent extends PagedListingBaseComponent<DeviceManagementDto> {
  private helperService: HelperService = inject(HelperService);

  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: DeviceManagementDto[] = [];
  public isHadAction: boolean = false;
  public displayedColumns: string[] = [
    'id',
    'user__username',
    'organization__name',
    'name',
    'ip',
    'updated_at',
    'action',
  ];
  public maximumDeviceNumber: number = 0;
  public currentUsedDeviceNumber: number = 0;

  constructor(
    private readonly deviceManagementService: DeviceManagementService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {
    super();
  }

  public onInitPage(): void {
    this.isHadAction =
      this.rights.admin_devices_update || this.rights.admin_devices_delete;
    if (!this.isHadAction) {
      this.displayedColumns = this.displayedColumns.filter(
        (item: string): boolean => item !== 'action'
      );
    }

    super.onInitPage();
  }
  protected list( request: PagedAndSortedRequestDto, pageNumber: number): void {
    request.pageIndex = pageNumber;
    this.deviceManagementService.list(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: DeviceManagementResultDto): void => {
        const organization: any = JSON.parse(this.sessionService.getCookie('auth')).organization;
        const isMaxDeviceNumber: boolean = this.helperService.hasProperty(organization, 'max_devices');

        this.dataSource = res.items;
        this.totalItems = res.totalCount;
        this.currentUsedDeviceNumber = this.totalItems;
        if (isMaxDeviceNumber) {
          this.maximumDeviceNumber = organization.max_devices;
        }
    });
  }

  public editDevice(element: DeviceManagementDto): void {
    const dialogRef: MatDialogRef<DeviceManagementDialogComponent> = this.dialog.open(DeviceManagementDialogComponent, {
      panelClass: 'general-dialog',
      width: '650px',
      autoFocus: false,
      disableClose: true,
      data: {
        id: element?.id,
      },
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
      (res): void => {
        if (res) {
          this.snackBar.open('Item updated successfully', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          this.refresh();
        }
      },
      (error): void => {
        this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    );
  }

  public deleteDevice(id: number): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result): void => {
      if (result) {
        if (id) {
          this.deviceManagementService.delete(id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
            (): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }

  public getPagedRequestDto(): PagedAndSortedRequestDto {
    return this.request;
  }
}
