import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { ICustomRendererAppend } from "@shared/components/interfaces/custom-renderer.interface";

@Injectable()
export class CustomRendererService {
  private _renderer: Renderer2;

  constructor( private rendererFactory: RendererFactory2 ) {
    this._renderer = this.rendererFactory.createRenderer(null, null);
  }

  public addClass(selector: string, classListName: string[]): void {
    const elementList: NodeListOf<Element> = document.querySelectorAll(selector);

    elementList.forEach((element: Element): void => {
      classListName.forEach((className: string): void => {
        this._renderer.addClass(element, className);
      });
    });
  }

  public removeClass(selector: string, classListName: string[]): void {
    const elementList: NodeListOf<Element> = document.querySelectorAll(selector);

    elementList.forEach((element: Element): void => {
      classListName.forEach((className: string): void => {
        this._renderer.removeClass(element, className);
      });
    });
  }

  public empty(selector: string): void {
    const targetElement: Element | null = document.querySelector(selector);

    if (targetElement) {
      targetElement.childNodes.forEach((node: Node): void => {
        setTimeout((): void => {
          this._renderer.removeChild(targetElement, node);
        });
      });
    }
  }

  public remove(selector: string): void {
      const child: Element | null = document.querySelector(selector);

    if (child) {
      child.remove();
    }
  }

  public createNode(childNodeConfig: ICustomRendererAppend): Node {
    const newSelector = this._renderer.createElement(childNodeConfig.selectorName);
    this.setAttribute(newSelector, 'id', childNodeConfig.id);

    return newSelector;
  }

  public append(parentNode: string, childNode: Node): void {
    const parent: Element | null = document.querySelector(parentNode);

    if (parent && childNode) {
      this._renderer.appendChild(parent, childNode);
    }
  }

  public appendNode(parentNode: Element, childNode: Node): void {
    this._renderer.appendChild(parentNode, childNode);
  }

  public select(selector: string): Element | null {
    return document.querySelector(selector);
  }

  public selectAll(selector: string): NodeListOf<HTMLElement> {
    return document.querySelectorAll(selector);
  }

  public getChildNodes(targetElement: Element | null, search: string): NodeListOf<Element> | undefined {
    return targetElement ? targetElement.querySelectorAll(search) : undefined;
  }

  public insertBefore(targetElement: Element | null, htmlString: string, refChild: Element): void {
    const newChild: Node = this.createHtmlFromString(htmlString);

    this._renderer.insertBefore(targetElement, newChild, refChild);
  }

  public css(selectorList: Array<string>, property: string, value: string): void {
    selectorList.forEach((element: string): void => {
      const node: Element | null = document.querySelector(element);

      if (node) {
        this._renderer.setStyle(node, property, value);
      }
    });
  }

  public show(selector: string): void {
    const node: Element | null = document.querySelector(selector);

    if (node) {
      this._renderer.setStyle(node, 'display', 'block');
    }
  }

  public hide(selector: string): void {
    const node: Element | null = document.querySelector(selector);

    if (node) {
      this._renderer.setStyle(node, 'display', 'none');
    }
  }

  public setAttribute(node: Node, attribute: string, value: string): void {
    this._renderer.setAttribute(node, attribute, value);
  }

  public getInputValue(selector: string): string {
    const node: HTMLInputElement | null = document.querySelector(selector);

    return node ? node.value : '';
  }

  public setInputValue(selector: string, property: string, value: string | number): void {
    const node: HTMLInputElement | null = document.querySelector(selector);

    if (node) {
      this._renderer.setProperty(node, property, value);
    }
  }

  public createHtmlFromString(htmlString: string): Node {
    const div = this._renderer.createElement('div');
    div.innerHTML = htmlString.trim();

    return div.firstChild;
  }
}
