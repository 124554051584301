import { Injectable } from '@angular/core';
import { PageNotFoundEnum } from "@shared/enums/page-not-found.enum";

@Injectable()
export class UrlBuilderService {

  public getCreatedCustomUrl(url: string, queryParam: string, paramValue: number | undefined): string {
    if (!url || !queryParam || !paramValue) return PageNotFoundEnum.PAGE_NOT_FOUND;

    return `${url}?${queryParam}=${paramValue}`;
  }
}
