<div>
    <div class="title-with-search">
      <p>API Tracking</p>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search" [(ngModel)]="searchText" (keyup.enter)="search(searchText)">
        <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
      </mat-form-field>
    </div>
    <table mat-table matSort #tableData [dataSource]="dataSource" class="mat-elevation-z8"
    style="width: 100%;" (matSortChange)="sortData($event)" matSortActive="id" matSortDirection="desc"
    matSortDisableClear aria-describedby="Api tracking list">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-id" scope="row">Organization ID</th>
      <td mat-cell *matCellDef="let element"> {{element.organization_id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Organization name</th>
      <td mat-cell *matCellDef="let element"> {{element.organization__name}}</td>
    </ng-container>
    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Share athletes data</th>
      <td mat-cell *matCellDef="let element"> {{element.share_athlete_data}} </td>
    </ng-container>
    <ng-container matColumnDef="training_planner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Training planner tool</th>
      <td mat-cell *matCellDef="let element"> {{element.training_planner_tool}}</td>
    </ng-container>
    <ng-container matColumnDef="get_metrics">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Get training metrics</th>
      <td mat-cell *matCellDef="let element"> {{element.get_training_metric}}</td>
    </ng-container>
    <ng-container matColumnDef="created_metrics">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Created training metrics</th>
      <td mat-cell *matCellDef="let element"> {{element.create_training_metric}}</td>
    </ng-container>
    <ng-container matColumnDef="fuelling_metrics">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Create fueling metrics</th>
      <td mat-cell *matCellDef="let element"> {{element.create_fueling_metric}}</td>
    </ng-container>
    <ng-container matColumnDef="actions" *ngIf="isHadAction">
      <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element" class="fixed-width-action">
        <button mat-raised-button color="primary" class="btn-icon-text" (click)="viewDialog(element)">
          <mat-icon>remove_red_eye</mat-icon> View
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="isHadAction ? 7 : 6">No data available in table</td>
    </tr>
  </table>
  <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
    <mat-paginator (page)="getDataPage($event.pageIndex + 1)" [length]="totalItems" [pageSize]="pageSize">
    </mat-paginator>
  </div>
</div>
