import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class AthleteResultDto extends PagedResultDto {
  public items: AthleteDto[] = [];
}

export class BaseAthleteResultDto extends PagedResultDto {
  public items: Array<BaseAthleteDto> = [];
}

export class BaseAthleteDto extends Deserializable {
  public id: number | undefined;
  public first_name: string | undefined;
  public last_name: string | undefined;
  public default_weight: number | undefined;
}
export class AthleteDto extends Deserializable {
  public id: number | undefined;
  public tags: Array<{ id: number | undefined; name: string | undefined }> | undefined;
  public coaches:
    | Array<{
        first_name: string | undefined;
        id: number | undefined;
        last_name: string | undefined;
        username: string | undefined;
      }>
    | undefined;
  public first_name: string | undefined;
  public last_name: string | undefined;
  public gender: string | undefined;
  public dob: string | undefined;
  public photo: string | undefined;
  public address_street_name: string | undefined;
  public address_street_number: string | undefined;
  public address_zip: string | undefined;
  public address_country_id: number | undefined;
  public phone: string | undefined;
  public email: string | undefined;
  public website: string | undefined;
  public is_active: boolean | undefined;
  public garmin_verified: boolean | undefined;
  public strava_id: number | undefined;
  public inactive_expiration: string | undefined;
  public created_at: string | undefined;
  public updated_at: string | undefined;
  public is_delete: boolean | undefined;
  public display_id: number | undefined;
  public organization: number | undefined;
}
