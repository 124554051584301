import { Injectable } from "@angular/core";
import { FeatureFlagsService } from "./feature-flags.service";
import { FeatureFlags, FeatureFlagValue, ValueMapper } from "./interfaces/feature-flags.interface";
import { WaffleFeatureFlags } from "./interfaces/waffle-feature-flags.interface";
import { ApiService } from "@core/services/api.service";
import { AppConstants } from "@core/constants";
import { map, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class WaffleFeatureFlagsService implements FeatureFlagsService {

  private LOCAL_STORAGE_KEY: string = "waffle_feature_flags"

  constructor(
    private readonly _apiService: ApiService
  ) { }

  public setFlags(waffleFlags: WaffleFeatureFlags): FeatureFlags {
    const flags: FeatureFlags = {};
    for (const waffleFeatureFlag in waffleFlags.flags) {
      flags[waffleFeatureFlag] = waffleFlags.flags[waffleFeatureFlag].is_active;
    }
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(flags));
    return flags;
  }

  /**
   * Gets flags from localstorage. This will not call the API if the flags are not set
   */
  public getFlags(): FeatureFlags | undefined {
    const localStorageValue: string | null = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (!localStorageValue) {
      return undefined;
    }

    try {
      return JSON.parse(localStorageValue) as FeatureFlags;
    } catch (error) {
      // Fail silently
      console.error(error);
      return undefined;
    }
  }

  /**
   * Fetches flags from the backend and assigns them to localstorage
   */
  public fetchFlags(): Observable<FeatureFlags> {
    return this._apiService.get(AppConstants.API.WAFFLE_FLAGS).pipe(
      map((response: WaffleFeatureFlags) => {
        return this.setFlags(response);
      })
    )
  }

  public getOrFetchFlags(): Observable<FeatureFlags> {
    const localFlags: FeatureFlags | undefined = this.getFlags();
    return localFlags ? of(localFlags) : this.fetchFlags();
  }

  /**
   * Get flag value from localstorage
   * 
   * This will _not_ perform a backend query if the flags are not set
   */
  public getValue(flagName: string, defaultValue?: FeatureFlagValue, valueMapper?: ValueMapper | undefined): FeatureFlagValue {
    const flags: FeatureFlags | undefined = this.getFlags();
    if (!flags) {
      return defaultValue;
    }

    const value: FeatureFlagValue = flags[flagName];
    if (!value) {
      return defaultValue;
    }

    return valueMapper ? valueMapper(value) : value;
  }
}
