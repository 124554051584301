import { SPEED_DROPDOWN_Y_AXIS_CONFIG } from "@shared/components/visualization/data-point-list.constant";

export const PLOT_DATA: any = {
  plots: [
    {
      "tools": "pan,box_zoom",
      "x_axis_type": "datetime",
      "y_axis_label": "Left Y axis 2",
      "y_range": {
        "bounds": [
          "auto"
        ],
        "plot_range": [
          0,
          500
        ]
      },
      "x_range": {
        "bounds": [
          "auto"
        ],
      },
      "extra_y_ranges": {
        "3": {
          "bounds": [
            "auto"
          ],
          "plot_range": [
            0,
            300
          ]
        },
        "4": {
          "bounds": [
            "auto"
          ],
          "plot_range": [
            0,
            400
          ]
        },
      },
      "extra_tools": [
        {
          "tooltips": [
            [
              "VO2max - maximum aerobic power",
              " @value Left Y axis 1"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "VLamax - maximum glycolytic power",
              " @value Right Y axis 1"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "Anaerobic Threshold (MLSS) (rel. AT)",
              " @value"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "% of VO2max @AT",
              " @value"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "FatMax",
              " @value"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        }
      ],
      circles: [
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "4",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "default",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "3",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "4",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "3",
        }
      ],
      "layouts": [
        {
          "axis_position": "right",
          "linear_axis": {
            "axis_label_text_font_size": "10pt",
            "y_range_name": "4",
            "axis_label": "Right Y axis 1"
          },
        },
        {
          "axis_position": "left",
          "linear_axis": {
            "axis_label_text_font_size": "10pt",
            "y_range_name": "3",
            "axis_label": "Left Y axis 1"
          },
        },
        {
          "legends": [
            ["VO2max Relative - maximum aerobic power",],
            ["VO2max Absolute - maximum aerobic power",],
            ["VLamax - maximum glycolytic power",],
            ["Anaerobic Threshold (MLSS) (rel. AT)",],
            ["% of VO2max @AT",],
            ["FatMax",]
          ],
        }
      ],

      "lines": [
        {
          "source": {
            "x": [
              1724198400000,
              1727308800000
            ],
            "y": [
              53.11980343976394,
              54.16177054063142
            ],
            "date": [
              "21-08-2024",
              "26-09-2024"
            ],
            "value": [
              "53.12",
              "54.16"
            ]
          },
          "line_color": "Blue",
          "line_width": 1,
          "y_range_name": "3",
        },
        {
          "source": {
            "x": [
              1724198400000,
              1727308800000
            ],
            "y": [
              53.11980343976394,
              54.16177054063142
            ],
            "date": [
              "21-08-2024",
              "26-09-2024"
            ],
            "value": [
              "53.12",
              "54.16"
            ]
          },
          "line_color": "FireBrick",
          "line_width": 1,
          "y_range_name": "4",
        },
        // {
        //   "line_color": "FireBrick",
        //   "line_width": 1,
        //   "source": {
        //     "x": [
        //       1724198400000,
        //       1727308800000
        //     ],
        //     "y": [
        //       45.872912017333248,
        //       45.256054224736036
        //     ],
        //     "date": [
        //       "21-08-2024",
        //       "26-09-2024"
        //     ],
        //     "value": [
        //       "45.87",
        //       "45.26"
        //     ]
        //   },
        //   "y_range_name": "default",
        // },
        // {
        //   "line_color": "DarkOrchid",
        //   "line_width": 1,
        //   "source": {
        //     "x": [
        //       1724198400000,
        //       1727308800000
        //     ],
        //     "y": [
        //       280.872912017333248,
        //       200.256054224736036
        //     ],
        //     "date": [
        //       "21-08-2024",
        //       "26-09-2024"
        //     ],
        //     "value": [
        //       "280.87",
        //       "200.26"
        //     ]
        //   },
        //   "y_range_name": "4",
        // },
      ],
    }
  ],
};

export const metabolicQ3: any = {
  dropdown_menu_option_list: SPEED_DROPDOWN_Y_AXIS_CONFIG,
  plots: [
    {
      "tools": "pan,box_zoom",
      "x_axis_type": "datetime",
      "y_axis_label": "Left Y axis 2",
      "y_range": {
        "bounds": [
          "auto"
        ],
        "plot_range": [
          0,
          500
        ]
      },
      "x_range": {
        "bounds": [
          "auto"
        ],
      },
      "extra_y_ranges": {
        "3": {
          "bounds": [
            "auto"
          ],
          "plot_range": [
            0,
            300
          ]
        },
        "4": {
          "bounds": [
            "auto"
          ],
          "plot_range": [
            0,
            400
          ]
        },
        "5": {
          "bounds": [
            "auto"
          ],
          "plot_range": [
            0,
            50
          ]
        }
      },
      "extra_tools": [
        {
          "tooltips": [
            [
              "VO2max - maximum aerobic power",
              " @value Left Y axis 1"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "VLamax - maximum glycolytic power",
              " @value Right Y axis 1"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "Anaerobic Threshold (MLSS) (rel. AT)",
              " @value"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "% of VO2max @AT",
              " @value"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        },
        {
          "tooltips": [
            [
              "FatMax",
              " @value"
            ],
            [
              "test_date",
              " @date"
            ]
          ]
        }
      ],
      circles: [
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "4",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "default",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "3",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "4",
        },
        {
          "fill_color": "white",
          "size": 8,
          "y_range_name": "3",
        }
      ],
      "layouts": [
        {
          "axis_position": "right",
          "linear_axis": {
            "axis_label_text_font_size": "10pt",
            "y_range_name": "4",
            "axis_label": "Right Y axis 1"
          },
        },
        {
          "axis_position": "left",
          "linear_axis": {
            "axis_label_text_font_size": "10pt",
            "y_range_name": "3",
            "axis_label": "Left Y axis 1"
          },
        },
        {
          "legends": [
            ["VO2max Relative - maximum aerobic power",],
            ["VO2max Absolute - maximum aerobic power",],
            ["VLamax - maximum glycolytic power",],
            ["Anaerobic Threshold (MLSS) (rel. AT)",],
            ["% of VO2max @AT",],
            ["FatMax",]
          ],
        }
      ],

      "lines": [
        {
          "source": {
            "x": [
              1724198400000,
              1727308800000
            ],
            "y": [
              155.11980343976394,
              188.16177054063142
            ],
            "date": [
              "21-08-2024",
              "26-09-2024"
            ],
            "value": [
              "155.12",
              "188.16"
            ]
          },
          "line_color": "Blue",
          "line_width": 1,
          "y_range_name": "3",
        },
        // {
        //   "line_color": "FireBrick",
        //   "line_width": 1,
        //   "source": {
        //     "x": [
        //       1724198400000,
        //       1727308800000
        //     ],
        //     "y": [
        //       45.872912017333248,
        //       45.256054224736036
        //     ],
        //     "date": [
        //       "21-08-2024",
        //       "26-09-2024"
        //     ],
        //     "value": [
        //       "45.87",
        //       "45.26"
        //     ]
        //   },
        //   "y_range_name": "default",
        // },
        // {
        //   "line_color": "DarkOrchid",
        //   "line_width": 1,
        //   "source": {
        //     "x": [
        //       1724198400000,
        //       1727308800000
        //     ],
        //     "y": [
        //       280.872912017333248,
        //       200.256054224736036
        //     ],
        //     "date": [
        //       "21-08-2024",
        //       "26-09-2024"
        //     ],
        //     "value": [
        //       "280.87",
        //       "200.26"
        //     ]
        //   },
        //   "y_range_name": "4",
        // },
      ],
    }
  ],
};
