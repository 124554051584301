import { inject, Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { ApiService } from './api.service';
import { AppConstants } from '../constants';

@Injectable()
export class ServiceBase {
  public _sessionService: SessionService = inject(SessionService);
  public _apiService: ApiService = inject(ApiService);
  public _appConstants = AppConstants;
}
