import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { IPerformanceLimitsConfig } from "@shared/interfaces/performance-limits.interface";
import { VisualizationService } from "@shared/services/visualization.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params } from "@angular/router";
import { UrlBuilderService } from "@shared/services/url-builder.service";
import { IFeatureSport } from "@shared/interfaces/feature-sport.interface";
import { Observable } from "rxjs";
import { AppConstants } from "@core/constants";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { testSettings } from "@shared/components/visualization/test-settings.constant";
import { metabolicQ } from "@shared/components/visualization/metaboli.contant";
import { default_text } from "@shared/components/visualization/default-text.constant";
import { ApiService } from "@core/services/api.service";
import { selected_test } from "@shared/components/visualization/selected-test.constant";
import { selected_athlete } from "@shared/components/visualization/selected-athlete.constant";
import { ChartConstants } from "@modules/apc/apc/data/chart-modules";
import { ChartMappingData } from "@modules/apc/apc/data/chart-mapping-data";
import { ChartService } from "@shared/services/chart.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { HelperService } from "@shared/services/helper.service";
import { MetricService } from "@shared/services/metric.service";
import { SIMULATION_TYPE } from "@shared/enums/simulation-type.enum";

@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss']
})
export class VisualizationComponent implements OnInit {
  private visualizationService: VisualizationService = inject(VisualizationService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private urlBuilder: UrlBuilderService = inject(UrlBuilderService);
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private apiService: ApiService = inject(ApiService);
  private chartService: ChartService = inject(ChartService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private helperService: HelperService = inject(HelperService);
  private metricService: MetricService = inject(MetricService);

  public physiologicalPerformanceLimitsTabConfig: IPerformanceLimitsConfig | any;
  public metabolicPerformanceLimitsTabConfigAngle1: IPerformanceLimitsConfig | any;
  public metabolicPerformanceLimitsTabConfigAngle2: IPerformanceLimitsConfig | any;
  public metabolicPerformanceLimitsTabConfigAngle3: IPerformanceLimitsConfig | any;
  public performanceLimitsSavedData: any = [];
  public customAthleteCategoryList: any = [];
  public parentId: number;
  public leftButton1: string = 'L Axis 1';
  public enabledMetricList: any[] = [];
  public sportList: any = [];
  public metricList: any = [];
  public metricConfigurationId: number;
  public metricConfigListBySport: any = [];
  public currentMetricConfig: any;
  public simulationType: number;
  public isEnabledMetric: boolean = true;
  public defaultMeterConfig: any;
  public defaultConfigAngle: any[];
  public performanceDevelopmentButtonOptionList: any[] = [
    {
      name: 'Relative Watts',
      id: 1,
    },
    {
      name: 'Absolute Watts',
      id: 2,
    },
  ];
  public leftButton2: string = 'L Axis 2';
  public rightButton1: string = 'R Axis 1';

  public customUrl: string;
  public params: any;
  public tabIndexToReturn: number = 0;
  public currentSportId: number;
  public sportOptionList: any[] = [];
  public metricOptionList: IFeatureSport[] = [];
  public metricId: number;
  public metricName: string;
  public currentMetric: any;
  public isShowCurrentMetric: boolean = true;
  public previewMeterConfig: any[] = [];
  public meterOptionListBySportType: any;
  public angleOptionListBySportType: any;
  public currentMeterConfig: any;
  public currentMetabolicAngleConfig: any;
  public currentMetabolicFingerprintConfig: any;

  public ngOnInit(): void {
    this.subscribeToQueryParams()
      .subscribe({
        next: (params: Params) => {
          this.params = params;
          this.metricId = +this.params.feature_id;

          this.setTabIndexToReturn(this.params.tab_index_to_return);
          this.setCustomUrl('/home/my-inscyd', 'tab_index_to_return', this.tabIndexToReturn);
          this.setMetricDropdownOption(this.activatedRoute.snapshot.data.data.metricList);
          this.setMetricName(this.metricId, this.activatedRoute.snapshot.data.data.metricList);
          this.setCurrentMetric(this.metricId, this.activatedRoute.snapshot.data.data.metricList);
          this.setMetricList(this.activatedRoute.snapshot.data.data.metricList);
          this.setEnabledMetricList(this.activatedRoute.snapshot.data.data.metricList);
          this.isEnabledMetric = this.isMetricEnabled(this.enabledMetricList, this.metricId);

          if (this.isEnabledMetric) {
            this.setCurrentSimulationType(this.activatedRoute.snapshot.data.data.sportList[0].feature.sport.simulation_type);

            if (this.activatedRoute.snapshot.data.data.sportList.length) {
              let mappedSportMetricList: any;

              if (this.helperService.isSpeedBasedSport(this.simulationType)) {
                this.defaultMeterConfig = this.metricService.getSpeedDropdownConfigByMetric(this.currentMetric);
                this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.activatedRoute.snapshot.data.data.sportList[0].feature.sport.simulation_type);
                this.meterOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig);
                this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle);
              } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
                this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.activatedRoute.snapshot.data.data.sportList[0].feature.sport.simulation_type);
                this.defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(this.currentMetric);
                this.meterOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig);
                this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle);
              }

              this.setCustomAthleteCategory(this.activatedRoute.snapshot.data.data.sportList[0].data.custom_athlete_category);
              this.setCurrentSportId(this.activatedRoute.snapshot.data.data.sportList[0].feature.id);
              this.setMetricConfigId(this.metricId, this.activatedRoute.snapshot.data.data.sportList, this.currentSportId);
              this.setSportDropdownOption(this.activatedRoute.snapshot.data.data.sportList);
              this.setSportList(this.activatedRoute.snapshot.data.data.sportList);
              mappedSportMetricList = this.getMappedSportMetricById(this.currentSportId, this.sportList);
              mappedSportMetricList.forEach((metricConfig: any) => {
                this.addPerformanceLimitsSavedData(metricConfig, this.performanceLimitsSavedData, this.currentSportId);
              });
              this.initPreviewMeterConfig(this.enabledMetricList, this.currentSportId);
              this.setParentId(this.activatedRoute.snapshot.data.data.sportList[0].id);
              this.setCurrentPerformanceLimitsConfig(this.sportList, this.currentSportId, this.metricId, this.metricName, this.customAthleteCategoryList);
              this.initPreviewMetabolicAngleConfig(this.currentMetabolicAngleConfig);
            }
          }
        },
        error: (error: any) => {
          if (error !== 'Error: Resource not found or no permission.') {

          }
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      },);
  }

  public setDefaultMetabolicConfigAngle(currentMetric: any, simulationType: number): any {
    if (simulationType === SIMULATION_TYPE.SPEED) {
      return this.metricService.getSpeedDropdownConfigByMetric(currentMetric);
    } else if (simulationType === SIMULATION_TYPE.POWER) {
      return this.metricService.getPowerDropdownConfigByMetric(currentMetric);
    }
  }

  public setCustomAthleteCategory(categoryList: any[]): void {
    this.customAthleteCategoryList = categoryList;
  }

  public getMappedSportMetricById(sportId: number, sportList: any[]): any {
    const currentSport: any = sportList.find((sport: any) => sport.feature.id === sportId);

    if (currentSport) {
      return currentSport.children.map((metric: any) => ({
        feature: metric.feature.id,
        data: metric.data,
        id: metric.id,
      }));
    }
  }

  public setCurrentSimulationType(type: number): void {
    this.simulationType = type;
  }

  public onMeterConfigChanged(data: any): void {
    this.previewMeterConfig.forEach((metric: any) => {
      if (metric.id === this.metricId) {
        metric.row1 = data.row1 !== 'none' ? data.row1 : false;
        metric.row2 = data.row2 !== 'none' ? data.row2 : false;
        metric.row3 = data.row3 !== 'none' ? data.row3 : false;
      }
    });
    this.currentMeterConfig = data;

    this.save();
  }

  public onMetabolicFingerprintChanged(data: any): void {
    this.currentMetabolicAngleConfig = data;
    this.save();
  }

  public setEnabledMetricList(allMetricList: any[]): void {
    this.enabledMetricList = allMetricList.filter((metric: any): any => {
      if (metric.is_enabled) {
        return metric;
      }
    });
  }

  public onTabChanged(event: MatTabChangeEvent): void {
    if (event.index === 2) {
      this.createPerformanceDevelopmentGraph(metabolicQ, default_text, testSettings);
    }  else {
      this.customRendererService.empty(ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID);
    }
  }

  public createPerformanceDevelopmentGraph(metabolicQ: any, default_text: any, testSettings: any): void {
    const response = this.apiService.parseTextResponse(metabolicQ);
    if (response) {
      const event: any = {
        id: 10,
        test_type: 'newtype',
        data: response,
        test: selected_test,
        athlete: selected_athlete,
        default_text: default_text,
        test_settings: testSettings,
        is_chart_changed: false,
      };
      const request = {
        data: event.data[4],
        testId: event.id,
        elementId: ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID,
        type: ChartConstants.PERFORMANCE_DEVELOPMENT.ID,
        test_type: event.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
      };

      setTimeout(() => {
        this.customRendererService.empty(ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID);
        this.chartService.ChartBokeh(request);
      })
    }
  }

  public onGraphLeftAxis1Changed(option: any): void {
    console.log('Left 1: ', option);
  }

  public onGraphLeftAxis2Changed(option: any): void {
    console.log('Left 2: ', option);
  }

  public onGraphRightAxis1Changed(option: any): void {
    console.log('Right 1: ', option);
  }

  public setCurrentMetricVisibility(metricId: number, previewMetricList: any[]): void {
    // this.isShowCurrentMetric = previewMetricList.find((metric: any) => metric.id === metricId).visibility;
  }

  public toggleCurrentMetric(featureId: number): void {
    this.previewMeterConfig.forEach((meter: any) => {
      if (meter.id === featureId) {
        meter.visibility = !meter.visibility;
      }
    });
    this.isShowCurrentMetric = !this.isShowCurrentMetric;

    this.save();
  }

  public setMetricName(metricId: number, metricList: any[]): void {
    this.metricName = metricList.find((metric: any) => metric.id === metricId)?.name;
  }

  public setCurrentMetric(metricId: number, metricList: any): void {
    this.currentMetric = metricList.find((metric: any) => metric.id === metricId);
  }

  public setMetricList(metricList: any[]): void {
    this.metricList = metricList;
  }

  public setMetricConfigId(metricId: number, sportList: any[], currentSportId: number): void {
    const metricListBySport: any = this.getMetricListBySport(sportList, currentSportId)

    this.metricConfigurationId = metricListBySport.find((metric: any) => metric.feature.id === metricId)?.id;
  }

  public setMetricConfigurationBySport(metricId: number, children: any[]): void {
    this.metricConfigurationId = children.find((metric: any) => metric.feature.id === metricId)?.id;
  }

  public generateRowBasedOnMetricRowNumber(metric: any): any {
    let defaultMeterConfig: any;
    let rowList: any = {};
    let row1: any = {row1: {id: 'none', name: 'None',}};
    let row2: any = {row2: {id: 'none', name: 'None',}};
    let row3: any = {row3: {id: 'none', name: 'None',}};

    if (this.helperService.isSpeedBasedSport(this.simulationType)) {
      defaultMeterConfig = this.metricService.getSpeedDropdownConfigByMetric(metric);
    } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
      defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(metric);
    }

    if (defaultMeterConfig) {
      switch (defaultMeterConfig.rowNumber){
        case 1:
          return Object.assign(rowList, row1);
        case 2:
          return Object.assign(rowList, row1, row2);
        case 3:
          return Object.assign(rowList, row1, row2, row3);
        default:
          return {};
      }
    } else {
      return {};
    }
  }

  public initPreviewMeterConfig(enabledMetricList: any[], sportId: number): void {
    this.previewMeterConfig = enabledMetricList.map((metric: any) => {
      let defaultRowList: any = this.generateRowBasedOnMetricRowNumber(metric);
      let defaultMetricObject: any = {
        visibility: true,
        metricName: metric.name,
        id: metric.id,
        ...defaultRowList,
      };

      const hasMetric = this.performanceLimitsSavedData.find((savedSport: any) => savedSport.sportId === sportId && savedSport.metricId === metric.id);

      if (hasMetric) {
        let copyRowList = this.helperService.makeCopy(hasMetric);
        delete copyRowList.data.physiological_performance_benchmark.meter_configuration['base'];

        return {
          id: metric.id,
          visibility: hasMetric.data.physiological_performance_benchmark.metric_visibility,
          metricName: metric.name,
          ...copyRowList.data.physiological_performance_benchmark.meter_configuration,
        };
      } else {
        return defaultMetricObject;
      }
    });
  }

  public initPreviewMetabolicAngleConfig(metabolicAngleConfig: any): void {
    this.currentMetabolicAngleConfig = metabolicAngleConfig;
  }

  public setCustomUrl(url: string, param: string, tabIndex: number): void {
    this.customUrl = this.urlBuilder.getCreatedCustomUrl(url, param, tabIndex);
  }

  public setCurrentSportId(sportId: number): void {
    this.currentSportId = sportId;
  }

  public setSportDropdownOption(sportOptionList: any[]): void {
    this.sportOptionList = sportOptionList.map((sportItem: any): any => ({
      id: sportItem.feature.id,
      name: sportItem.feature.name,
      parent_id: sportItem.id,
    }));
  }

  public setMetricDropdownOption(metricOptionList: IFeatureSport[]): void {
    this.metricOptionList = metricOptionList;
  }

  public setSportList(sportList: any): void {
    this.sportList = sportList;
  }

  public getSportList(): any {
    return this.sportList;
  }

  public getMetricListBySport(sportList: any[], sportId: number): any {
    return sportList.find((sport: any) => sport.feature.id === sportId)?.children;
  }

  public setParentId(parentId: number): void {
    this.parentId = parentId;
  }

  public getDefaultMeterConfig(defaultMeterOptionListConfig: any): any {
    let finalConfig: any;

    if (defaultMeterOptionListConfig.optionList.length === 0) {
      finalConfig = { base: { id: 'none', }, };
    } else {
      finalConfig = { base: { id: 'none', }, };
    }

    const row1: any = { row1: { id: 'none', } };
    const row2: any = { row2: { id: 'none', } };
    const row3: any = { row3: { id: 'none', } };

    switch(defaultMeterOptionListConfig.rowNumber) {
      case 1:
        return Object.assign(finalConfig, row1);
      case 2:
        return Object.assign(finalConfig, row1, row2);
      case 3:
        return Object.assign(finalConfig, row1, row2, row3);
      default:
        return finalConfig;
    }
  }

  public getDefaultMetabolicAngleConfig(): any {
    return {
      left: {
        id: 'none',
        name: 'None',
      },
      center: {
        id: 'none',
        name: 'None',
      },
      right: {
        id: 'none',
        name: 'None',
      },
    };
  }

  public getDefaultMeterOptionListByConfig(config: any): any[] {
    return [
      {
        name: 'None',
        id: 'none',
      },
      ...config.optionList,
    ];
  }

  public getCurrentMetricVisibility(): boolean {
    return true;
  }

  public setCurrentPerformanceLimitsConfig(sportList: any, currentSportId: number, metricId: number, metricName: string, customAthleteCategoryList: any[]): void {
    this.metricConfigListBySport = this.getMetricListBySport(sportList, currentSportId);
    this.currentMetricConfig = this.metricConfigListBySport.find((metric: any): any => metric.feature.id === metricId);

     if (this.currentMetricConfig) {
       let physiologicalTabConfig: any = this.currentMetricConfig.data.physiological_performance_benchmark.performance_limits;
       let currentMeterConfig: any = this.currentMetricConfig.data.physiological_performance_benchmark.meter_configuration;
       let currentMeterVisibility: any = this.currentMetricConfig.data.physiological_performance_benchmark.metric_visibility;
       let currentMetabolicAngleConfig: any = this.currentMetricConfig.data.metabolic_fingerprint.current_metabolic_angle_config;
       let metabolicTabConfigAngle1: any = this.currentMetricConfig.data.metabolic_fingerprint.performance_limits_angle1;
       let metabolicTabConfigAngle2: any = this.currentMetricConfig.data.metabolic_fingerprint.performance_limits_angle2;
       let metabolicTabConfigAngle3: any = this.currentMetricConfig.data.metabolic_fingerprint.performance_limits_angle3;

       if (!this.currentMetricConfig.data.physiological_performance_benchmark.hasOwnProperty('metric_visibility')) {
         currentMeterVisibility = this.getCurrentMetricVisibility();
       }

       if (!this.currentMetricConfig.data.physiological_performance_benchmark.hasOwnProperty('meter_configuration')) {
         currentMeterConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
       }

       if (!this.currentMetricConfig.data.metabolic_fingerprint.hasOwnProperty('current_metabolic_angle_config')) {
         currentMetabolicAngleConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
       }

       if (!this.currentMetricConfig.data.physiological_performance_benchmark.hasOwnProperty('performance_limits')) {
         physiologicalTabConfig = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       }

       if (!this.currentMetricConfig.data.metabolic_fingerprint.hasOwnProperty('performance_limits_angle1')) {
         metabolicTabConfigAngle1 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       }

       if (!this.currentMetricConfig.data.metabolic_fingerprint.hasOwnProperty('performance_limits_angle2')) {
         metabolicTabConfigAngle2 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       }

       if (!this.currentMetricConfig.data.metabolic_fingerprint.hasOwnProperty('performance_limits_angle3')) {
         metabolicTabConfigAngle3 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       }

       this.physiologicalPerformanceLimitsTabConfig = this.visualizationService.setPerformanceLimitsTabConfig(
         physiologicalTabConfig.tabs,
         physiologicalTabConfig.customTabObject,
         metricName
       );

       this.metabolicPerformanceLimitsTabConfigAngle1 = this.visualizationService.setPerformanceLimitsTabConfig(
         metabolicTabConfigAngle1.tabs,
         metabolicTabConfigAngle1.customTabObject,
         metricName
       );

       this.metabolicPerformanceLimitsTabConfigAngle2 = this.visualizationService.setPerformanceLimitsTabConfig(
         metabolicTabConfigAngle2.tabs,
         metabolicTabConfigAngle2.customTabObject,
         metricName
       );

       this.metabolicPerformanceLimitsTabConfigAngle3 = this.visualizationService.setPerformanceLimitsTabConfig(
         metabolicTabConfigAngle3.tabs,
         metabolicTabConfigAngle3.customTabObject,
         metricName
       );
       this.currentMeterConfig = currentMeterConfig;
       this.currentMetabolicAngleConfig = currentMetabolicAngleConfig;
       this.isShowCurrentMetric = currentMeterVisibility;
     } else {
       this.physiologicalPerformanceLimitsTabConfig = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       this.metabolicPerformanceLimitsTabConfigAngle1 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       this.metabolicPerformanceLimitsTabConfigAngle2 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       this.metabolicPerformanceLimitsTabConfigAngle3 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList));
       this.currentMeterConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
       this.currentMetabolicAngleConfig = this.getDefaultMetabolicAngleConfig();
       this.isShowCurrentMetric = this.getCurrentMetricVisibility();
     }
  }

  public setTabIndexToReturn(returnIndex: number): void {
    this.tabIndexToReturn = returnIndex;
  }

  public subscribeToQueryParams(): Observable<any> {
    return this.activatedRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef));
  }

  public isMetricEnabled(enabledMetricList: any[], currentMetricId: number): boolean {
    return enabledMetricList.some((metric: any) => metric.id === currentMetricId);
  }

  public onPhysiologicalPerformanceLimitsChanged(newConfig: any): void {
    this.physiologicalPerformanceLimitsTabConfig.tabs = newConfig.tabs;
    this.physiologicalPerformanceLimitsTabConfig.customTabObject = newConfig.customTabObject;
    this.save();
  }
  public onMetabolicPerformanceLimitsChangedAngle1(newConfig: any): void {
    this.metabolicPerformanceLimitsTabConfigAngle1.tabs = newConfig.tabs;
    this.metabolicPerformanceLimitsTabConfigAngle1.customTabObject = newConfig.customTabObject;
    this.save();
  }

  public onMetabolicPerformanceLimitsChangedAngle2(newConfig: any): void {
    this.metabolicPerformanceLimitsTabConfigAngle2.tabs = newConfig.tabs;
    this.metabolicPerformanceLimitsTabConfigAngle2.customTabObject = newConfig.customTabObject;
    this.save();
  }
  public onMetabolicPerformanceLimitsChangedAngle3(newConfig: any): void {
    this.metabolicPerformanceLimitsTabConfigAngle3.tabs = newConfig.tabs;
    this.metabolicPerformanceLimitsTabConfigAngle3.customTabObject = newConfig.customTabObject;
    this.save();
  }

  public resetPerformanceLimitsSavedData(): void {
    this.performanceLimitsSavedData = []
  }

  public onSportChanged(config: any): void {
    this.featureStoreService.getExtendedSportFeatureConfigurationList()
      .subscribe({
        next: (sportList: any) => {
          const currentSport: any = sportList.find((sport: any) => sport.feature.id === config.sportId);
          let mappedSportMetricList: any;

          this.setCurrentSimulationType(currentSport.feature.sport?.simulation_type);
          if (this.helperService.isSpeedBasedSport(this.simulationType)) {
            this.defaultMeterConfig = this.metricService.getSpeedDropdownConfigByMetric(this.currentMetric);
            this.meterOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig);
          } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
            this.defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(this.currentMetric);
            this.meterOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig);
          }
          this.setCustomAthleteCategory(currentSport.data.custom_athlete_category);
          this.setCurrentSportId(config.sportId);
          this.setMetricConfigurationBySport(this.metricId, currentSport.children);
          mappedSportMetricList = this.getMappedSportMetricById(this.currentSportId, this.sportList);
          mappedSportMetricList.forEach((metricConfig: any) => {
            this.addPerformanceLimitsSavedData(metricConfig, this.performanceLimitsSavedData, this.currentSportId);
          });
          this.setParentId(currentSport.id);
          this.initPreviewMeterConfig(this.enabledMetricList, this.currentSportId);
          this.setCurrentPerformanceLimitsConfig(sportList, config.sportId, this.metricId, this.metricName, this.customAthleteCategoryList);
        }
      })
  }

  public onMetricChanged(metric: any): void {
    const hasMetricInSavedArray: any = this.performanceLimitsSavedData.find((sport: any) => sport.sportId === this.currentSportId && sport.metricId === metric.id);
    this.setCurrentMetric(metric.id, this.activatedRoute.snapshot.data.data.metricList);

    if (hasMetricInSavedArray) {
      this.metricConfigurationId = hasMetricInSavedArray.id;
    }

    this.metricName = metric.name;
    this.metricId = metric.id;
    this.setCurrentMetricVisibility(this.metricId, this.previewMeterConfig);
    this.isEnabledMetric = this.isMetricEnabled(this.enabledMetricList, this.metricId);

    if (this.isEnabledMetric) {
      if (this.helperService.isSpeedBasedSport(this.simulationType)) {
        this.defaultMeterConfig = this.metricService.getSpeedDropdownConfigByMetric(this.currentMetric);
        this.meterOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig);
        this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.simulationType);
        this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle);
      } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
        this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.simulationType);
        this.defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(this.currentMetric);
        this.meterOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig);
        this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle);
      }

      if (hasMetricInSavedArray) {
        let physiologicalTabConfig: any = hasMetricInSavedArray.data.physiological_performance_benchmark.performance_limits;
        let currentMeterConfig: any = hasMetricInSavedArray.data.physiological_performance_benchmark.meter_configuration;
        let currentMeterVisibility: any = hasMetricInSavedArray.data.physiological_performance_benchmark.metric_visibility;
        let currentMetabolicAngleConfig: any = hasMetricInSavedArray.data.metabolic_fingerprint.current_metabolic_angle_config;
        let metabolicTabConfigAngle1: any = hasMetricInSavedArray.data.metabolic_fingerprint.performance_limits_angle1;
        let metabolicTabConfigAngle2: any = hasMetricInSavedArray.data.metabolic_fingerprint.performance_limits_angle2;
        let metabolicTabConfigAngle3: any = hasMetricInSavedArray.data.metabolic_fingerprint.performance_limits_angle3;

        if (!hasMetricInSavedArray.data.physiological_performance_benchmark.hasOwnProperty('metric_visibility')) {
          currentMeterVisibility = this.getCurrentMetricVisibility();
        }

        if (!hasMetricInSavedArray.data.physiological_performance_benchmark.hasOwnProperty('meter_configuration')) {
          currentMeterConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
        }

        if (!hasMetricInSavedArray.data.metabolic_fingerprint.hasOwnProperty('current_metabolic_angle_config')) {
          currentMetabolicAngleConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
        }

        if (!hasMetricInSavedArray.data.physiological_performance_benchmark.hasOwnProperty('performance_limits')) {
          physiologicalTabConfig = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(this.metricName, this.customAthleteCategoryList));
        }

        if (!hasMetricInSavedArray.data.metabolic_fingerprint.hasOwnProperty('performance_limits_angle1')) {
          metabolicTabConfigAngle1 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(this.metricName, this.customAthleteCategoryList));
        }

        if (!hasMetricInSavedArray.data.metabolic_fingerprint.hasOwnProperty('performance_limits_angle2')) {
          metabolicTabConfigAngle2 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(this.metricName, this.customAthleteCategoryList));
        }

        if (!hasMetricInSavedArray.data.metabolic_fingerprint.hasOwnProperty('performance_limits_angle3')) {
          metabolicTabConfigAngle3 = this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(this.metricName, this.customAthleteCategoryList));
        }

        this.physiologicalPerformanceLimitsTabConfig = this.visualizationService.setPerformanceLimitsTabConfig(
          physiologicalTabConfig.tabs,
          physiologicalTabConfig.customTabObject,
          this.metricName,
        );

        this.metabolicPerformanceLimitsTabConfigAngle1 = this.visualizationService.setPerformanceLimitsTabConfig(
          metabolicTabConfigAngle1.tabs,
          metabolicTabConfigAngle1.customTabObject,
          this.metricName,
        );

        this.metabolicPerformanceLimitsTabConfigAngle2 = this.visualizationService.setPerformanceLimitsTabConfig(
          metabolicTabConfigAngle2.tabs,
          metabolicTabConfigAngle2.customTabObject,
          this.metricName,
        );

        this.metabolicPerformanceLimitsTabConfigAngle3 = this.visualizationService.setPerformanceLimitsTabConfig(
          metabolicTabConfigAngle3.tabs,
          metabolicTabConfigAngle3.customTabObject,
          this.metricName,
        );
        this.currentMeterConfig = currentMeterConfig;
        this.currentMetabolicAngleConfig = currentMetabolicAngleConfig;
        this.isShowCurrentMetric = currentMeterVisibility;
      } else {
        this.setCurrentPerformanceLimitsConfig(this.sportList, this.currentSportId, this.metricId, this.metricName, this.customAthleteCategoryList);
      }
    }
  }

  public getMetricConfigPayload(): any {
    return {
      physiological_performance_benchmark: {
        performance_limits: this.physiologicalPerformanceLimitsTabConfig,
        meter_configuration: this.currentMeterConfig,
        metric_visibility: this.isShowCurrentMetric,
      },
      metabolic_fingerprint: {
        performance_limits_angle1: this.metabolicPerformanceLimitsTabConfigAngle1,
        performance_limits_angle2: this.metabolicPerformanceLimitsTabConfigAngle2,
        performance_limits_angle3: this.metabolicPerformanceLimitsTabConfigAngle3,
        current_metabolic_angle_config: this.currentMetabolicAngleConfig,
      },
    };
  }

  public  addPerformanceLimitsSavedData(metric: any, performanceLimitsSavedData: any[], sportId: number): void {
    const sport: any = performanceLimitsSavedData.find((sport: any) => sport.sportId === sportId && sport.metricId === metric.feature);

    if (sport) {
      sport.data = metric.data;
    } else {
      performanceLimitsSavedData.push({
        sportId: sportId,
        metricId: metric.feature,
        data: metric.data,
        id: metric.id,
      });
    }
  }

  public save(): void {
    const payloadMetricConfiguration: any = {
      data: this.getMetricConfigPayload(),
      feature: +this.metricId,
      parent: this.parentId,
    };
    const currentSport: any = this.performanceLimitsSavedData.find((sport: any) => sport.sportId === this.currentSportId && sport.metricId === this.metricId);

    if (currentSport) {
      this.featureStoreService.updateFeatureConfiguration(payloadMetricConfiguration, this.metricConfigurationId)
        .subscribe({
          next: (res: any) => {
            this.addPerformanceLimitsSavedData(res, this.performanceLimitsSavedData, this.currentSportId);
            this.snackBar.open('The configuration has been successfully updated', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: () => {
            this.snackBar.open('The configuration has not been successfully created', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        });
    } else {
      this.featureStoreService.createFeatureConfiguration(payloadMetricConfiguration)
        .subscribe({
          next: (res: any) => {
            this.metricConfigurationId = res.id;
            this.addPerformanceLimitsSavedData(res, this.performanceLimitsSavedData, this.currentSportId);
            this.snackBar.open('The configuration has been successfully updated', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: () => {
            this.snackBar.open('The configuration has not been successfully created', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        });
    }
  }

  public purchaseMetric(): void {}
}
