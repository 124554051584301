import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AppConstants } from '@core/constants';
import { ManageOrganizationDto, ManageOrganizationResultDto } from '@core/services/manage-organizations/manage-organization-dto';
import { ManageOrganizationsService } from '@core/services/manage-organizations/manage-organizations.service';
import { OrganizationDto } from '@core/services/organization/organization-dto';
import { RegionSettingsService } from '@core/services/region-settings/region-settings.service';
import { ManageOrganizationsDialogComponent } from './manage-organizations-dialog/manage-organizations-dialog.component';
import { OrganizationStatisticsDialogComponent } from './organization-statistics-dialog/organization-statistics-dialog.component';
import { OrganizationAnalyticsDialogComponent } from './organization-analytics-dialog/organization-analytics-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-manage-organizations',
  templateUrl: './manage-organizations.component.html',
  styleUrls: ['./manage-organizations.component.scss'],
})
export class ManageOrganizationsComponent extends PagedListingBaseComponent<ManageOrganizationDto> {
  public countries: Array<any> = [];
  public editData: ManageOrganizationDto = new ManageOrganizationDto();
  public isDeletedView: boolean = false;
  public isHadAction: boolean = false;
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public isDataFromEndpoint = false;
  public displayedColumns: string[] = [
    'id',
    'name',
    'active',
    'country',
    'allowed_devices',
    'users',
    'athletes',
    'la',
    'ppd',
    'manual',
    'virtual',
    'events',
    'simulations',
    'action',
  ];

  constructor(
    private readonly organizationService: ManageOrganizationsService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private countryService: RegionSettingsService,
  ) {
    super();
  }

  public onInitPage(): void {
    super.onInitPage();
    this.loadCountries();
    this.setPredicateFilter();
  }

  public setPredicateFilter(): void {
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.id?.toString().toLowerCase().includes(filter) ||
        data.name?.toString().toLowerCase().includes(filter) ||
        data.is_active?.toString().toLowerCase().includes(filter) ||
        data.country?.toString().toLowerCase().includes(filter) ||
        data.max_devices?.toString().toLowerCase().includes(filter) ||
        data.user?.toString().toLowerCase().includes(filter) ||
        data.athlete?.toString().toLowerCase().includes(filter) ||
        data.test_la?.toString().toLowerCase().includes(filter) ||
        data.test_map?.toString().toLowerCase().includes(filter) ||
        data.test_manual?.toString().toLowerCase().includes(filter) ||
        data.test_virtual?.toString().toLowerCase().includes(filter) ||
        data.test_event?.toString().toLowerCase().includes(filter) ||
        data.test_result?.toString().toLowerCase().includes(filter);
    };
  }

  public loadCountries(): void {
    this.countryService.listCountries().subscribe((res): void => {
      this.countries = res.items;
    });
  }

  public search(): void {
    this.dataSource.filter = this.searchText.trim().toLowerCase();
  }

  public convertData(data: ManageOrganizationResultDto) {
    return this.dataSource.data = data.items.map((item: ManageOrganizationDto) => ({
       ...item,
       is_active: item.is_active ? 'Yes' : 'No',
       user: item.total_user?.toString() + '/' + item.max_user,
       athlete: item.total_athlete?.toString() + '/' + item.max_athlete?.toString(),
       test_la: item.total_test_la?.toString()  + '/' + item.max_test_la?.toString() ,
       test_map: item.total_test_map?.toString()  + '/' + item.max_test_map?.toString() ,
       test_manual: item.total_test_manual?.toString()  + '/' + item.max_test_manual?.toString() ,
       test_virtual: item.total_test_virtual?.toString()  + '/' + item.max_test_virtual?.toString(),
       test_event: item.total_test_event?.toString()  + '/' + item.max_test_event?.toString() ,
       test_result: item.total_test_result?.toString()  + '/' + item.max_test_result?.toString() ,
       country: item.country?.name
     }));
   }

  public openStatistics(organization: OrganizationDto): void {
   this.dialog.open(OrganizationStatisticsDialogComponent, {
      panelClass: 'general-dialog',
      width: '600px',
      autoFocus: false,
      data: organization,
    });
  }

  public applyFilter(event: Event): void {
    const filterValue: string = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public edit(id: number): void {
    this.organizationService.getOrganizationById(id).subscribe((res: any): void => {
      this.editData = res;
      this.openDialog(true, res);
    });
  }
  public openAnalytics(id: number): void {
    this.dialog.open(OrganizationAnalyticsDialogComponent, {
      panelClass: 'general-dialog',
      width: '80%',
      autoFocus: false,
      data: id,
    });
  }

  public add(): void {
    this.openDialog(false);
  }

  public openDialog(isEdit: boolean, organizationDto?: ManageOrganizationDto): void {
    const dialogRef: MatDialogRef<ManageOrganizationsDialogComponent> = this.dialog.open(ManageOrganizationsDialogComponent, {
      panelClass: 'general-dialog',
      width: '600px',
      autoFocus: false,
      data: { isEdit, organizationDto, countries: this.countries },
    });

    dialogRef.afterClosed().subscribe((res): void => {
      if (res) {
        let message: string = 'Item created successfully';
        if (isEdit) {
          message = 'Item updated successfully';
        }
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.refresh();
      }
    });
  }

  public deleteOrganization(id: number): void {
    const message: string = 'Do you want to delete this organization?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.organizationService.delete(id).subscribe(
            (): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error) => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }

  public restoreOrganization(id: number): void {
    const message: string = 'Do you want to restore this organization?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Restore', message);
    let dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.organizationService.restore(id).subscribe(
            (): void => {
              this.snackBar.open('Item restored successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }

  public onHideLoading(): void {
    if (this.isDataFromEndpoint) {
      this.hideLoading();
    }
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number,): void {
    request.pageIndex = pageNumber;
    if (!this.isDeletedView) {
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
      this.organizationService.list(request).subscribe((res: ManageOrganizationResultDto): void => {
        this.isDataFromEndpoint = true;
        this.convertData(res);
      });
    } else {
      this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
      this.organizationService.listDeleted(request).subscribe((res: ManageOrganizationResultDto): void => {
        this.isDataFromEndpoint = true;
        this.convertData(res);
        this.totalItems = res.totalCount;
      });
    }
  }

  public viewDeletedOrganizations(): void {
    this.isDeletedView = true;
    this.list(this.request, this.request.pageIndex);
  }

  public viewActiveOrganizations(): void {
    this.isDeletedView = false;
    this.list(this.request, this.request.pageIndex);
  }
}
