import { Injectable } from "@angular/core";
import { SessionService } from "@core/services/session.service";


@Injectable({
  providedIn: "root"
})
export class FeatureManagementService {
  constructor(
    private readonly _sessionService: SessionService
  ) { }

  public getFeatureValue = (slug: string): boolean => this._sessionService.features[slug] ?? false;
}
