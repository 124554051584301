import { Injectable } from "@angular/core";
import { ApiService } from "@core/services/api.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { delay, distinctUntilChanged, finalize, tap } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { ApiBaseService } from "@core/services/api-base.service";
import { IPurchaseSession } from "@shared/interfaces/purchase-session.interface";

export type Tab = Record<string, any> | Array<Record<string, any>>;

@Injectable()
export class HumangoMetabolicProfileFacade {
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _tabs: BehaviorSubject<Tab[]> = new BehaviorSubject<Tab[]>([]);
  private readonly _purchaseStatus: Subject<IPurchaseSession> = new Subject<IPurchaseSession>();

  public loading$: Observable<boolean> = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());
  public tabs$: Observable<Tab> = this._tabs.asObservable();
  public purchaseStatus$: Observable<IPurchaseSession> = this._purchaseStatus.asObservable();


  constructor(private _apiService: ApiService, private _apiBaseService: ApiBaseService) { }

  public fetchPurchaseStatus(apiCallId: string) {
    const params: URLSearchParams = new URLSearchParams({ api_call_id: apiCallId });
    this._loading.next(true);

    return this._apiBaseService.getJson<IPurchaseSession>(`${AppConstants.API.EXTERNAL.HUMANGO.PURCHASE.STATUS}?${params.toString()}`)
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe((purchaseSession: IPurchaseSession) => this._purchaseStatus.next(purchaseSession));
  }

  public fetchMetabolicProfile(apiCallId: string) {
    const params: URLSearchParams = new URLSearchParams({ api_call_id: apiCallId });

    this._loading.next(true);
    return this._apiService.get(`${AppConstants.API.EXTERNAL.HUMANGO.METABOLIC_PROFILE}?${params.toString()}`)
      .pipe(finalize(() => this._loading.next(false)))
      .subscribe((tabs: any) => this._tabs.next(tabs));
  }
}
