import {Component, OnInit} from "@angular/core";
import { ApiService } from "@core/services/api.service";
import { AppConstants } from "@core/constants";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: 'terms.component.html',
  styleUrls: ['terms.component.scss'],
})

export class TermsComponent implements OnInit {
  public htmlContent: any;

  constructor(private apiService: ApiService) {}

  public ngOnInit(): void {
    this.apiService.get(AppConstants.TERM_API)
      .subscribe((res: any): void => {
        this.htmlContent = res.html_content;
      });
  }
}
