import { Component, OnInit } from "@angular/core";
import { ApiService } from "@core/services/api.service";
import { AppConstants } from "@core/constants";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: 'privacy-policy.component.html',
  styleUrls: ['privacy-policy.component.scss'],
})

export class PrivacyPolicyComponent implements OnInit {
  public htmlContent: any;

  constructor(private apiService: ApiService) {}

  public ngOnInit(): void {
    this.apiService.get(AppConstants.PRIVACY_POLICY_API)
      .subscribe((res: any): void => {
        this.htmlContent = res.html_content;
      });
  }
}
