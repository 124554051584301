import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '@core/constants';

@Pipe({
  name: 'errorFormFormat',
})
export class ErrorFormFormatPipe implements PipeTransform {
  public transform(errors: any, options?: {fieldName?: string, min?: number, max?: number}): string {
    let errorMessage: string = '';
    if (errors) {
      const key = Object.keys(errors);
      errorMessage = this.getErrorMessage(key[0], errors, options?.fieldName, options?.min, options?.max);
    }
    return errorMessage;
  }
  private getErrorMessage(error: string, errors: any, fieldName?: string, min? :number, max?: number): string {
    if (error) {
      let errorMessage = '';
      switch (error) {
        case 'required':
          errorMessage = `${fieldName} is <strong>required</strong>`;
          break;

        case 'email':
          errorMessage = `${fieldName} is <strong>invalid format</strong>`;
          break;

        case 'min':
          if (max) {
            errorMessage = `${fieldName} must be between ${errors.min?.min} and ${max}`;
          } else {
            errorMessage = `${fieldName} must be greater than or equal to ${errors.min?.min}`;
          }
          break;

        case 'max':
          if (min) {
            errorMessage = `${fieldName} must be between ${min} and ${errors.max?.max}`;
          } else {
            errorMessage = `${fieldName} must be less than or equal to ${errors.max?.max}`;
          }
          break;

        case 'isValidFormMinMax':
          errorMessage = `Min Value should be below Max Value`;
          break;
        case 'isValidFormMinMaxSec':
          errorMessage = `Min Value should be above Max Value`;
          break;
        case 'pattern':
          if (
            String(errors?.pattern?.requiredPattern) ===
            String(AppConstants.REGEX.MIN_SEC)
          ) {
            errorMessage = `Wrong format. Valid range is from 00:00 to 59:59`;
          } else if (
            String(errors?.pattern?.requiredPattern) ===
              String(AppConstants.REGEX.NO_WHITESPACE_SPECIAL) ||
            String(errors?.pattern?.requiredPattern) ===
              String(AppConstants.REGEX.NO_SPECIAL)
          ) {
            errorMessage = `${fieldName} must contain only alphabetic symbols and numbers`;
          } else if (
            String(errors?.pattern?.requiredPattern) ===
            String(AppConstants.REGEX.ONLY_ALPHABETS)
          ) {
            errorMessage = `${fieldName} must contain only alphabetic symbols`;
          } else if (
            String(errors?.pattern?.requiredPattern) ===
            String(AppConstants.REGEX.NUMBER_LETTER_DASH_UNDERSCORE)
          ) {
            errorMessage = `${fieldName} must contain only number, letter, dash and underscore`;
          } else if (
            String(errors?.pattern?.requiredPattern) ===
            String(AppConstants.REGEX.INTEGER)
          ) {
            errorMessage = `${fieldName} must be integer`;
          } else if (
            String(errors?.pattern?.requiredPattern) ===
            String(AppConstants.REGEX.DECIMAL_2)
          ) {
            errorMessage = `${fieldName} allowed maximum two decimals`;
          } else {
            errorMessage = `${fieldName} should be follow  ${errors?.pattern?.requiredPattern}`;
          }
          break;
        case 'maxlength':
          errorMessage = `${fieldName} should be below  ${errors.maxlength?.requiredLength} chars`;
          break;
        case 'matDatepickerParse':
        case 'matEndDateInvalid':
        case 'matStartDateInvalid':
          errorMessage = ` Invalid ${fieldName}`;
          break;
        default:
          errorMessage = error;
          break;
      }
      return errorMessage;
    }
    return '';
  }
}
