import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '@core/constants';

@Pipe({
  name: 'testTypeFormat',
})
export class TestTypeFormat implements PipeTransform {
  public transform(value: string): string {
    switch (value) {
      case AppConstants.TEST_TYPES.CRITICAL_POWER:
        return 'Critical Power';
      case AppConstants.TEST_TYPES.MANUAL:
        return 'Manual';
      case AppConstants.TEST_TYPES.VIRTUAL:
        return 'Virtual';
      case AppConstants.TEST_TYPES.LACTATE:
        return 'Lactate';
      case AppConstants.TEST_TYPES.PPD:
        return 'PPD';
      default:
        return '';
    }
  }
}
