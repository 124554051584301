import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as _ from 'lodash-es';
import { SessionService } from '../services/session.service';
import { ApiService } from "../services/api.service";
import { Observable, of } from "rxjs";
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private sessionService: SessionService, private router: Router, private apiService: ApiService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.apiService.get('accounts/session/').pipe(
      map((res: any): boolean => {
        if (res.message) {
          this.sessionService.getCurrentUserProfile();
          const auth: string = this.sessionService.getCookie('auth');
          if (auth) {
            if (this.isValidPermision(state, auth)) {
              return true;
            } else {
              this.router.navigate(['/']);
              return false;
            }
          } else {
            window.location.href = '/';
            return false;
          }
        }

        this.sessionService.deactivateLoginStatus();
        return false;
      }),
      catchError((): Observable<boolean> => {
        return of(false);
      })
    );
  }

  private isValidPermision(
    state: RouterStateSnapshot,
    authCookie: any
  ): boolean {
    const rights = this.sessionService.rights;
    const auth = JSON.parse(authCookie);
    const url: string = state.url;

    if (!rights || _.isEmpty(rights)) {
      return true;
    }

    if (url.includes('/manage')) {
      const urlChild = url.replace('/manage/', '');
      return this.isValidPermisionManageModule(urlChild, rights);
    } else if (url.includes('/apc/')) {
      const urlChild = url.replace('/apc/', '');
      return this.isValidPermisionAPCModule(urlChild, rights);
    } else if (url.includes('/administration/')) {
      const urlChild = url.replace('/home/administration/', '');
      return this.isValidPermissionAdministrationModule(urlChild, rights, auth);
    } else if (url.includes('/plan-subscription/')) {
      const urlChild = url.replace('/plan-subscription/', '');
      return this.isValidPermisionPlanSubscriptionModule(
        urlChild,
        rights,
        auth
      );
    } else if (url.includes('/college')) {
      return rights.college_list;
    } else {
      return true;
    }
  }

  private isValidPermisionManageModule(url: string, rights: any): boolean {
    switch (url) {
      case 'users':
        return rights.admin_users_list;
      case 'tests':
        return rights.test_list;
      case 'files':
        return rights.files_list;
      default:
        return true;
    }
  }

  private isValidPermisionAPCModule(url: string, rights: any): boolean {
    switch (url) {
      case 'setcard':
        return (
          rights.metabolic_profiling_body_composition_list ||
          rights.metabolic_profiling_capacities_list ||
          rights.metabolic_profiling_metabolic_fingerprint_list ||
          rights.metabolic_profiling_performance_development_list
        );
      case 'load-characteristics':
        return rights.metabolic_profiling_metabolic_profile_list;
      case 'test-data':
        return rights.metabolic_profiling_test_data_list;
      case 'economy':
        return rights.metabolic_profiling_economy;
      case 'training-zones':
        return rights.metabolic_profiling_trainings_zones_list;
      case 'time-to-depletion':
        return rights.metabolic_profiling_time_to_depletion;
      case 'recovery-matrix':
        return rights.metabolic_profiling_recovery_matrix;
      case 'speed-relationships':
        return rights.metabolic_profiling_speed_relationships;
      case 'performance-projection':
        return rights.performance_projection_page;
      default:
        return true;
    }
  }

  private isValidPermissionAdministrationModule(
    url: string,
    rights: any,
    auth: any
  ): boolean {
    switch (url) {
      case 'training-zone-template':
        return auth.role.is_owner || rights.training_zone_builder;
      case 'report-template':
        return rights.report_template_create;
      case 'rights-management':
        return auth.role.is_owner || rights.admin_rights_list;
      case 'device-management':
        return auth.role.is_owner || rights.admin_devices_list;
      case 'tags':
        return rights.admin_tags_list;
      case 'organization-settings':
        return (
          !auth.role.is_owner &&
          !auth.role.is_agent_role &&
          rights.admin_org_settings_list
        );
      case 'sports':
        return auth.role.is_owner || rights.can_manage_sport_settings;
      case 'courses':
        return auth.role.is_owner || auth.role.is_agent_role;
      default:
        return true;
    }
  }

  private isValidPermisionPlanSubscriptionModule(
    url: string,
    rights: any,
    auth: any
  ) {
    switch (url) {
      case 'plan-purchase':
        return (
          auth.role.is_owner ||
          auth.role.is_agent_role ||
          (auth.organization.is_check_invoice && rights.subscription_view_plan)
        );
      case 'product-purchase':
        return (
          auth.role.is_owner ||
          auth.role.is_agent_role ||
          (auth.organization.is_check_invoice &&
            rights.subscription_view_product)
        );
      case 'invoice':
        return (
          auth.role.is_owner ||
          auth.role.is_agent_role ||
          (auth.organization.is_check_invoice &&
            rights.subscription_view_invoice_page)
        );
      case 'invoice-admin':
        return auth.role.is_owner;
      case 'my-subscription':
        return (
          auth.role.is_owner ||
          auth.role.is_agent_role ||
          (auth.organization.is_check_invoice &&
            rights.subscription_view_subscription_page)
        );
      case 'my-payment-method':
        return (
          auth.role.is_owner ||
          auth.role.is_agent_role ||
          (auth.organization.is_check_invoice &&
            rights.subscription_view_payment_method_page)
        );
      default:
        return true;
    }
  }
}
