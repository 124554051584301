<div class="reports-playground o-flex u-margin-bottom-m">
  <aside class="reports-playground__side [ o-flex o-flex--column ]">
    <header class="reports-playground__header u-margin-bottom-m o-flex--full">
      <h2>Add Element</h2>
      <div class="o-flex o-flex--column">
        <mat-accordion class="u-margin-bottom-xs">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Graphs
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-selection-list [(ngModel)]="selectedGrapComponents"
                                (ngModelChange)="onSelectedGraphComponentsChange()">
              <mat-list-option class="mat-primary selection"
                               [value]="graph"
                               [selected]="selection.includes(graph.id)"
                               *ngFor="let graph of modelGraphs">
                {{ graph.name }}
              </mat-list-option>
            </mat-selection-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Tables
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-selection-list [(ngModel)]="selectedTableComponents"
                                (ngModelChange)="onSelectedTableComponentsChange()">
              <mat-list-option class="mat-primary selection"
                               [value]="table"
                               [selected]="selection.includes(table.id)"
                               *ngFor="let table of modelTables">
                {{ table.name }}
              </mat-list-option>
            </mat-selection-list>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Text
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button mat-raised-button
                    type="button"
                    class="u-margin-bottom-xs u-1/1"
                    (click)="addTextEditor()">
              Text Field
            </button>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Other
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-selection-list class="u-padding-bottom-xs"
                                [(ngModel)]="selectedOtherComponents"
                                (ngModelChange)="onSelectedOtherComponentsChange()">
              <mat-list-option class="mat-primary selection"
                               [value]="other"
                               [selected]="selection.includes(other.id)"
                               *ngFor="let other of modelOther">
                {{ other.name }}
              </mat-list-option>
            </mat-selection-list>
            <button mat-raised-button
                    type="button"
                    class="u-margin-bottom-xs u-1/1"
                    (click)="addOrganizationImage()">
              Organization Image
            </button>
            <button mat-raised-button
                    type="button"
                    class="u-margin-bottom-xs u-1/1"
                    (click)="addPageBreak()">
              Page Break
            </button>
            <button mat-raised-button
                    type="button"
                    class="u-1/1"
                    (click)="addLine()">
              Horizontal Line
            </button>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </header>
  </aside>
  <section class="o-flex--full">
    <div class="o-flex o-flex--middle u-margin-bottom">
      <div class="o-flex o-flex--full">
        <mat-form-field class="mat-input--flush u-margin-right u-width-260"
                        appearance="outline"
                        floatLabel="always">
          <mat-label>Name</mat-label>
          <input matInput
                 type="text"
                 required
                 [(ngModel)]="templateName"
                 (ngModelChange)="nameChanged()"/>
        </mat-form-field>
        <mat-form-field class="mat-input--flush u-margin-right o-flex--full"
                        appearance="outline"
                        floatLabel="always">
          <mat-label>Description</mat-label>
          <input matInput
                 type="text"
                 [style.resize]="'none'"
                 [(ngModel)]="templateDescription"/>
        </mat-form-field>
      </div>
      <mat-slide-toggle class="is-active-toggle" [(ngModel)]="templateActive">Active</mat-slide-toggle>
      <mat-slide-toggle *ngIf="auth && auth.role.is_owner" [(ngModel)]="isPublic">Public for all users</mat-slide-toggle>
    </div>
    <div class="report-grid-wrap u-padding">
      <div class="report-grid"
           #reportGrid
           [style.width.px]="pageWidth"
           [style.min-height.px]="pageHeight">
        <div *ngFor="let gridPageBreak of gridPageBreaks; let i = index;"
             class="report-grid__break"
             [style.top.px]="pageHeight * (i + 1)">
          <span class="report-grid__page-number">{{i + 1}} of {{gridPageBreaks.length + 1}}</span>
        </div>
        <ktd-grid *ngIf="gridReady"
                  [cols]="cols"
                  [rowHeight]="rowHeight"
                  [layout]="grid"
                  [compactType]="compactType"
                  (resizeStarted)="onResizeStart($event)"
                  (resizeEnded)="onResizeEnd($event)"
                  (dragEnded)="setPageBreaks()"
                  (layoutUpdated)="onLayoutUpdated($event)">
          <ktd-grid-item *ngFor="let gridItem of grid; trackBy: trackById"
                         [id]="gridItem.id"
                         [resizable]="gridMeta.get(gridItem.id)?.resizable"
                         [draggable]="gridMeta.get(gridItem.id)?.draggable"
                         [class.grid-text-item]="gridMeta.get(gridItem.id)?.type === buildingBlocks.TEXT_FIELD">
            <div class="report-grid__item"
                 [class.padding]="gridMeta.get(gridItem.id)?.type !== buildingBlocks.PAGE_BREAK"
                 [class.is-.]="gridMeta.get(gridItem.id)?.type === buildingBlocks.PAGE_BREAK"
                 [class.is-horizontal-line]="gridMeta.get(gridItem.id)?.type === buildingBlocks.HORIZONTAL_LINE"
                 (dblclick)="openTextEditor(gridItem, gridMeta.get(gridItem.id)?.type, gridItem.id, gridMeta.get(gridItem.id)?.textContent, gridItem.h, gridItem.w, GENERAL, gridMeta.get(gridItem.id)?.textSettingType)">
              <button mat-icon-button
                      type="button"
                      color="warn"
                      class="report-grid__icon"
                      (click)="onRemoveItem(gridItem.id)">
                <mat-icon>close</mat-icon>
              </button>
              <ng-container [ngSwitch]="gridMeta.get(gridItem.id)?.type">
                <div *ngSwitchCase="buildingBlocks.BODY_COMPOSITION"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'body_composition.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.PERFORMANCE_DEVELOPMENT"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'performance_development.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.METABOLIC_POWER"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'metabolic_power.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.METABOLIC_FINGERPRINT"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'metabolic_fingerprint.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.PHYSIOLOGICAL_PERFORMANCE"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'physiological_performance_benchmarks.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.METABOLIC_PROFILE"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'load_characteristics.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.TEST_DATA_GRAPHS"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'test_data_graph.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.TEST_DATA_TABLES"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'test_data_table.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.HEART_RATE"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'heart_rate_data.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.ECONOMY"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'Economy.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.TRAINING_ZONE"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'Training_zones.jpg)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.TEST_INFO"
                     class="report-grid__image"
                     [style.background-image]="'url('+ imagePath +'textPlaceholder.png)'">
                </div>
                <div *ngSwitchCase="buildingBlocks.ORG_IMAGE"
                     class="report-grid__image o-flex o-flex--middle o-flex--justify-center"
                     title="Organisation Image">
                  <mat-icon [style.width.px]="40"
                            [style.height.px]="40"
                            [style.font-size.px]="40"
                            [style.line-height.px]="40">supervisor_account</mat-icon>
                </div>
                <div *ngSwitchCase="buildingBlocks.ORG_INFO"
                     class="report-grid__image o-flex o-flex--middle o-flex--justify-center"
                     title="Organisation Notes">
                  <mat-icon [style.width.px]="gridItem.w * 15"
                            [style.height.px]="gridItem.w * 15"
                            [style.font-size.px]="gridItem.w * 15">notes</mat-icon>
                </div>
                <div *ngSwitchCase="buildingBlocks.TEXT_FIELD"
                     class="grid-item-content">
                  <div [style.height]="'auto'" class="text o-flex--self-start" [innerHTML]="gridMeta.get(gridItem.id)?.textContent!"></div>
                </div>
              </ng-container>
            </div>
          </ktd-grid-item>
        </ktd-grid>
        <span class="helper-page-count">{{gridPageBreaks.length + 1}} of {{gridPageBreaks.length + 1}}</span>
      </div>
    </div>
  </section>
</div>
<footer class="[ o-flex o-flex--justify-right ]">
  <button mat-raised-button
          class="u-margin-right-m"
          (click)="dialogRef.close()">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="saveDisabled"
    (click)="onSave()">
    Save
  </button>
</footer>
