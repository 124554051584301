import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { HelperService } from '@shared/services/helper.service';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent extends AppBaseComponent {
  public photo_form: UntypedFormGroup = {} as UntypedFormGroup;
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public data: any;
  public imgFile: any;

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef,
    private helperService: HelperService
  ) {
    super();
  }

  public onInitPage(): void {
    this.initForm();
    this.initValue();
  }
  private initForm(): void {
    this.photo_form = this.fb.group({
      photo: new UntypedFormControl(''),
    });
    this.form = this.fb.group({
      id: new UntypedFormControl('', Validators.required),
      smtp_email: new UntypedFormControl('', Validators.required),
      smtp_email_use_tls: new UntypedFormControl('', Validators.required),
      smtp_password: new UntypedFormControl('', Validators.required),
      smtp_port: new UntypedFormControl('', Validators.required),
      smtp_server: new UntypedFormControl('', Validators.required),
      smtp_username: new UntypedFormControl('', Validators.required),
    });
  }
  private initValue(): void {
    this.apiService.get('settings/1/').subscribe((res: any): void => {
      this.data = res;
      const photo: string = res?.watermark_photo ? this.helperService.replaceCorrectPhotoURL(res.watermark_photo) : '';
      this.photo_form.setValue({
        photo: photo,
      });

      this.form.setValue({
        id: res.id,
        smtp_email: res.smtp_email,
        smtp_email_use_tls: res.smtp_email_use_tls,
        smtp_password: res.smtp_password,
        smtp_port: res.smtp_port,
        smtp_server: res.smtp_server,
        smtp_username: res.smtp_username,
      });
    });
  }

  public save(): void {
    if (this.form.valid) {
      const data = this.prepareData();
      this.showLoading();
      this.apiService.xhrCall('settings/1/', data, 'PUT').subscribe(
        (): void => {
          this.hideLoading();
          this.snackBar.open('System settings updated', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        },
        (): void => {
          this.snackBar.open('Watermark photo is making the problem', 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      );
    } else {
      this.snackBar.open('Validation error, some fields are required', 'OK', AppConstants.TOAST_CONFIG.ERROR);
    }
  }

  private prepareData() {
    return {
      ...this.form.value,
      watermark_photo: this.imgFile ? this.imgFile : undefined,
    };
  }

  public changeImage(event: any): void {
    const files: File[] = event.target.files;
    if (files.length > 1 || !files.length) {
      event.target.value = '';
    }
    const readFile = async (file: File): Promise<void> => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        this.photo_form.setValue({ photo: e.target?.result });
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    };
    if (files.length) {
      if (files[0]?.size <= AppConstants.WATERMARK_PHOTO_MAX_SIZE) {
        this.imgFile = files[0];
        readFile(files[0]);
      } else {
        event.target.value = '';
        this.photo_form.controls.photo.setValue('');
        this.cdr.detectChanges();
        this.snackBar.open('Photo size must be less than 100KB', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    }
  }
}
