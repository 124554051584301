import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { AccountTypeDto, AccountTypeResultDto } from './account-type-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from "@shared/models/api-request";

@Injectable({
  providedIn: 'root',
})
export class AccountTypesService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto | any): Observable<AccountTypeResultDto> {
    const apiUrl: string = this._appConstants.API.ACCOUNT_TYPE.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);
    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new AccountTypeResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: AccountTypeDto) =>
            new AccountTypeDto().deserialize(item)
          ),
        });
      })
    );
  }

  public create(input: AccountTypeDto): Observable<AccountTypeDto> {
    const apiUrl: string = this._appConstants.API.ACCOUNT_TYPE.ADD;
    return <Observable<AccountTypeDto>>this._apiService.post(apiUrl, input);
  }

  public update(input: AccountTypeDto): Observable<Object> {
    const apiUrl: string = String.Format(
      this._appConstants.API.ACCOUNT_TYPE.UPDATE,
      input.id
    );

    return this._apiService.put(apiUrl, input);
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(
      this._appConstants.API.ACCOUNT_TYPE.DELETE,
      id
    );

    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }
}
