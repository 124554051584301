import { Component, DestroyRef, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { IDeviceDetail } from '@core/services/device-management/device-management-dto';
import { DeviceManagementService } from '@core/services/device-management/device-management.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-device-management-dialog',
  templateUrl: './device-management-dialog.component.html',
})
export class DeviceManagementDialogComponent extends AppBaseComponent {
  public form: UntypedFormGroup = {} as UntypedFormGroup;
  public currentDevice: IDeviceDetail = {};

  constructor(
    private readonly deviceManagementService: DeviceManagementService,
    public dialogRef: MatDialogRef<DeviceManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly fb: UntypedFormBuilder,
    private destroyRef: DestroyRef,
  ) {
    super();
  }
  public onInitPage(): void {
    this.initForm();
    this.initValue();
  }
  private initForm(): void {
    this.form = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
    });
  }

  private initValue(): void {
    if (this.data.id) {
      this.deviceManagementService.getById(this.data.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: IDeviceDetail): void => {
        if (res) {
          this.currentDevice = res;
          this.form.patchValue({
            name: res.name,
          });
        }
      });
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public save(): void {
    if (this.form.valid) {
      this.currentDevice.name = this.form.value.name;
      this.deviceManagementService.update(this.currentDevice)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res): void => {
          this.dialogRef.close(res);
        });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
