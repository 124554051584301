import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '@core/constants';

@Pipe({
  name: 'convertPrimaryTypeToUnit',
})
export class ConvertPrimaryTypeToUnit implements PipeTransform {
  public transform(value: number | undefined): string {
    if (value) {
      const options = [
        ...AppConstants.REFERENCE_SYSTEMS_SPEED,
        ...AppConstants.REFERENCE_SYSTEMS_POWER,
      ];
      const option = options.find((item) => item.id === value);
      return option?.unit ? `(${option?.unit})` : '';
    }
    return '';
  }
}
