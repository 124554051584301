import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppBaseComponent } from '@shared/components/app-component-base';

@Component({
  selector: 'app-history-logs-detail',
  templateUrl: './history-logs-detail.component.html',
  styleUrls: ['./history-logs-detail.component.scss']
})
export class HistoryLogsDetailComponent extends AppBaseComponent {
  public dataUsed: any;
  public dataProcessing: any;

  constructor(
    public dialogRef: MatDialogRef<HistoryLogsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    super();
  }

  public onInitPage(): void {
    this.dataUsed = JSON.stringify(JSON.parse(this.data.historyLog.json_data), undefined, 2);
    this.dataProcessing = JSON.stringify(JSON.parse(this.data.historyLog.json_processing_data), undefined, 2);
  }

  public close(): void {
    this.dialogRef.close(false);
  }
}
