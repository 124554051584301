<ng-template #resultsNotReady>
  <h3 class="u-text-align-center u-padding-vertical-xxl">We are preparing your report...</h3>
  <div class="o-flex o-flex--justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
<div class="c-report-wrapp"
     *ngIf="resultsReady; else resultsNotReady">
  <div class="c-report-controls u-no-print">
    <div class="c-report-control">
      <h2 class="bold-style">
        {{reportTemplate.name}}
      </h2>
      <p>{{reportTemplate.description}}</p>
      <div class="download-button-box">
        <button class="c-report-preview__button"
                mat-raised-button
                color="primary"
                type="button"
                [disabled]="isGeneratingReport"
                (click)="onPrint()">{{getPdfGenerateButtonText()}}</button>
      </div>
    </div>
    <div class="toggle-default-organisation-text c-report-control">
      <h2 class="bold-style">Texts in this report</h2>
      <div class="default-organization-text">
        <span class="organisation-texts">Use my own text</span>
        <mat-slide-toggle class="is-active-toggle" [(ngModel)]="isDefaultText">Use INSCYD default text</mat-slide-toggle>
      </div>
      <div class="clue-text bold-style"><i>Also you may click on any text in the text editor field to edit your text</i></div>
    </div>
  </div>
  <div class="c-report-preview-pages u-margin-horizontal-auto"
       [style.min-height.px]="1400">
    <div class="c-report-preview__page"
         *ngFor="let page of pages; let isLast = last; first as isFirst"
         [class.u-page-break-after]="!isLast">
      <div class="c-report-preview__field o-flex o-flex--middle custom-overflow"
           [class.c-report-preview__field-organisation-info]="field.id === reportBuildingBlocks.ORG_INFO"
           [style.z-index]="1000 - (i + 5)"
           [class.u-padding-xs]="field.id === reportBuildingBlocks.BODY_COMPOSITION"
           [class.o-flex--column]="field.id === reportBuildingBlocks.TEST_DATA_TABLES || field.id === reportBuildingBlocks.HEART_RATE"
           [class.o-flex--gap]="field.id === reportBuildingBlocks.TEST_DATA_TABLES"
           [class.initial-top]="isFirst"
           [style.width.px]="field.width * scaleFactor * widthUnit"
           [style.height.px] = "field.id !== reportBuildingBlocks.TRAINING_ZONE ? field.height * heightUnit : 'auto'"
           [style.transform]="'translate('+ field.x * scaleFactor * widthUnit +'px, '+ field.y * heightUnit +'px)'"
           *ngFor="let field of page.fields; let i = index;">
        <ng-container *ngIf="field.id.includes(reportBuildingBlocks.ORG_IMAGE)">
          <img *ngIf="!organisation.photo"
               [style.width.%]="100"
               [style.height.%]="100"
               [style.object-fit]="'contain'"
               [src]="getDefaultOrganizationPhoto()"
               alt="Organisation">
          <img *ngIf="organisation.photo"
               [style.width.%]="100"
               [style.height.%]="100"
               [style.object-fit]="'contain'"
               [src]="organisation.photo"
               alt="Organisation">
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.ORG_INFO">
          <div class="u-text-align-left o-flex--self-start">
            <p class="u-margin-bottom-xxs">{{organisation.name}}</p>
            <p class="u-margin-bottom-xxs">{{organisation.invoice_address_street_number}} {{organisation.invoice_address_street_name}}</p>
            <p class="u-margin-bottom-xxs">{{organisation.invoice_address_city}}, {{organisation.invoice_address_zip}}</p>
            <p class="u-margin-bottom-xxs">{{organisation.website}}</p>
            <p class="u-margin-bottom-none">{{organisation.email}}</p>
          </div>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.TEST_INFO">
          <table class="table u-font-size-small">
            <tr>
              <td>Test Id</td>
              <td>
                {{selectedTest.display_id}}
              </td>
            </tr>
            <tr>
              <td>Date</td>
              <td>
                {{selectedTest.test_date| dateFormatExt: constant.DATE_FORMAT.APP_FULL_DATE_MOMENT:false}}
              </td>
            </tr>
            <tr>
              <td>Sport</td>
              <td>
                {{selectedTest.sport.name}}
              </td>
            </tr>
            <tr>
              <td>Test Type</td>
              <td>
                {{ testService.formatTestType(selectedTest.test_type) }}
              </td>
            </tr>
            <tr>
              <td>Athlete</td>
              <td>
                {{selectedTest.athlete.first_name}} {{selectedTest.athlete.last_name}}
              </td>
            </tr>
            <tr>
              <td>Coach</td>
              <td>
                <span *ngIf="selectedTest.coach?.first_name">{{selectedTest.coach.first_name}} </span>
                <span *ngIf="selectedTest.coach?.last_name">{{selectedTest.coach.last_name}}</span>
              </td>
            </tr>
          </table>
        </ng-container>
        <ng-container *ngIf="field.id.includes('text')">
          <app-edit-text-preview [id]="field.id" [textType]="field.textSettingType" [isDefaultText]="isDefaultText" [organisationText]="getOrganizationText(field)" [text]="field.textContent" [width]="field.width * scaleFactor * widthUnit" [height]="field.height * heightUnit"></app-edit-text-preview>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.METABOLIC_FINGERPRINT">
          <div class="u-1/1"
               id="metabolic_fingerprint">
            <div class="water-mark"></div>
            <canvas id="metabolic_fingerprint_radar"
                    class="u-1/1"></canvas>
          </div>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.BODY_COMPOSITION">
          <div class="o-flex o-flex--gap-xxs">
            <div class="u-1/4">
              <canvas id="humanBodyCtx"
                      class="u-1/1 u-border"></canvas>
            </div>
            <div class="u-1/4">
              <div id="body_composition"
                   class="pie"></div>
            </div>
            <div class="u-1/2">
              <table class="table borderless u-font-size-small"
                     id="table-composition"
                     aria-describedby="table-composition">
                <tbody>
                <tr>
                  <th scope="col">Body Mass</th>
                  <td>{{ bodyComposition.body_mass }}</td>
                </tr>
                <tr>
                  <th scope="col">Body Height</th>
                  <td>{{ bodyComposition.body_height }}</td>
                </tr>
                <tr>
                  <th scope="col">Body Mass <br>Index</th>
                  <td>{{ bodyComposition.body_mass_index }}</td>
                </tr>
                <tr>
                  <th scope="col">Projected BSA</th>
                  <td>{{ bodyComposition.projected_BSA }}</td>
                </tr>
                <tr>
                  <th scope="col">Body Fat</th>
                  <td>{{ bodyComposition.body_fat }}</td>
                </tr>
                <tr>
                  <th scope="col">Fat Free</th>
                  <td>{{ bodyComposition.fat_free }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.PERFORMANCE_DEVELOPMENT">
          <div id="performance_development"></div>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.PHYSIOLOGICAL_PERFORMANCE">
          <div class="o-flex o-flex--wrap o-flex--justify-space-around o-flex--middle o-flex--gap-xxs c-chart-gauge u-height-100">
            <div class="water-mark-wrapper water-mark"></div>
            <div class="{{getBenchmarkGraphClass()}}"
                 *ngFor="let data of gaugeChartData; index as i">
              <div class="c-chart-gauge__title u-margin-bottom-s u-line-clamp-2"
                   [innerHTML]="data.title"></div>
              <canvas id="gauge-{{ i }}"></canvas>
              <div *ngIf="data.value !== 'no_label'" class="c-chart-gauge__value item-value">
                <small *ngIf="data.prefix" [innerHTML]="data.prefix"></small>&nbsp;
                <strong class="u-margin-right-xxs">{{ data.value }}</strong>
                <small>{{ data.unit }}</small>
              </div>
              <div *ngIf="data.value_alternative_1 !== 'no_label'" class="item-value-alternative">
                <small *ngIf="data.prefix_alternative_1" [innerHTML]="data.prefix_alternative_1"></small>&nbsp;
                <strong class="u-margin-right-xxs">{{ data.value_alternative_1 }}</strong>
                <small>{{ data.unit_alternative_1 }}</small>
              </div>
              <div *ngIf="data.value_alternative_2 !== 'no_label'" class="item-value-alternative">
                <small *ngIf="data.prefix_alternative_2" [innerHTML]="data.prefix_alternative_2"></small>&nbsp;
                <strong class="u-margin-right-xxs">{{ data.value_alternative_2 }}</strong>
                <small>{{ data.unit_alternative_2 }}</small>
              </div>
              <div *ngIf="data.value_alternative_3 !== 'no_label'" class="item-value-alternative">
                <small *ngIf="data.prefix_alternative_3" [innerHTML]="data.prefix_alternative_3"></small>&nbsp;
                <strong class="u-margin-right-xxs">{{ data.value_alternative_3 }}</strong>
                <small>{{ data.unit_alternative_3 }}</small>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.METABOLIC_POWER">
          <div class="u-position-relative u-1/1">
            <div class="water-mark-wrapper water-mark"></div>
            <canvas id="metabolic_power"></canvas>
          </div>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.METABOLIC_PROFILE">
          <app-metabolic-profile *ngIf="metabolicProfileData"
                                 [chartData]="metabolicProfileData"
                                 [selectedTest]="selectedTest" ></app-metabolic-profile>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.TEST_DATA_GRAPHS">
          <div id="test_data" [style.width.%]="100"></div>
        </ng-container>

        <ng-container *ngIf="field.id === reportBuildingBlocks.ECONOMY">
          <div id="economy-chart-wrapper">
            <canvas id="economy-chart"></canvas>
          </div>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.TEST_DATA_TABLES">
          <ng-container *ngIf="measuredValues.length > 0">
            <table class="u-font-size-small [ c-table ] o-flex--full u-margin-bottom"
                   aria-describedby="measured value">
              <tr>
                <th scope="col"
                    class="measured_values_header">Type</th>
                <th scope="col"
                    class="measured_values_header">
                  {{ isSpeed ? "Average Speed" : "Average Power" }}
                </th>
                <th scope="col"
                    class="measured_values_header">Duration</th>
                <th scope="col"
                    class="measured_values_header">Additional Value</th>
              </tr>
              <tr *ngFor="let measuredValue of measuredValues">
                <td [innerHTML]="measuredValue.type"></td>
                <td>
                  {{ isSpeed
                    ? measuredValue.speed
                      ? measuredValue.speed + "m/s"
                      : ""
                    : measuredValue.power
                      ? measuredValue.power + "W"
                      : ""}}
                </td>
                <td>{{ measuredValue.duration }}</td>
                <td [innerHTML]="measuredValue.additionalValueString"></td>
              </tr>
            </table>
            <table class="u-font-size-small [ table table--small ] weighted_values_table o-flex--full u-margin-bottom"
                   aria-describedby="weighted value">
              <tr>
                <th scope="col"
                    class="measured_values_header"
                    colspan="3">
                  Weighted Regression
                </th>
              </tr>
              <tr>
                <td>
                  <strong>VLa<sub>max</sub></strong>
                </td>
                <td>
                  <strong>VO<sub>2max</sub></strong>
                </td>
                <td><strong>Anaerobic Threshold</strong></td>
              </tr>
              <tr>
                <td>{{ weightedValues.vl_weight }}</td>
                <td>{{ weightedValues.vo_weight }}</td>
                <td>{{ weightedValues.at_weight }}</td>
              </tr>
            </table>
          </ng-container>
          <ng-container *ngIf="rawTestDataTable">
            <table id="rawTestDataTable"
                   border="0"
                   class="u-font-size-small c-table o-flex--full u-margin-bottom"
                   aria-describedby="tabel">
              <thead>
              <tr>
                <th id="testdatheadertable"
                    *ngFor="let header of rawTestDataTable.header"
                    [attr.colspan]="header.colspan">
                  {{ header.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let raw_data of rawTestDataTable.data">
                <td *ngFor="let value of raw_data">{{ value }}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-container *ngIf="calculatedDataTable">
            <table id="calculatedDataTable"
                   border="0"
                   class="c-table u-font-size-small o-flex--full"
                   aria-describedby="tabel">
              <thead>
              <tr>
                <th id="calculateddatheadertable"
                    *ngFor="let header of calculatedDataTable.header"
                    [attr.colspan]="header.colspan">
                  {{ header.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let raw_data of calculatedDataTable.data">
                <td *ngFor="let value of raw_data">{{ value }}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.TRAINING_ZONE">
          <div *ngIf="!trainingZoneTableData || (trainingZoneTableData && trainingZoneTableData.length === 0)"
               id="training_zones"
               class="u-font-size-xsmall o-flex--self-start"
               [innerHTML]="trainingZoneTableHtml"></div>
          <ng-container *ngIf="trainingZoneTableData && trainingZoneTableData.length > 0"
               class="">
            <table class="u-1/1 c-table u-font-size-xsmall o-flex--self-start" aria-describedby="training zone">
              <tr>
                <th scope="row">Zone</th>
                <th scope="row">Code</th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Lower
                </th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Upper
                </th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Target
                </th>
                <th [colSpan]="maxLowUpTarget" scope="row">
                  Physiological Conditions
                </th>
              </tr>
              <tr style="visibility: collapse">
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row">&nbsp;</th>
                <th scope="row" [colSpan]="getMaxOutputValues()">
                  &nbsp;
                </th>
              </tr>
              <tr *ngFor="let zone of trainingZoneTableData; let zoneIndex = index">
                <td *ngIf="zone.name.value">{{ zone.name.value }}</td>
                <td *ngIf="zone.code.value">{{ zone.code.value }}</td>
                <td [colSpan]="zone.lower.colspan"
                    *ngFor="let header of zone.lower.headers; let i = index">
                  <span class="title-zone">{{ header }}</span> <br />
                  <strong>{{ zone.lower.values[i] }}</strong><br />
                  <span class="title-zone">{{ zone.lower.units[i] }}</span> <br />
                </td>
                <td [colSpan]="zone.lower.colspan"
                    *ngFor="let header of zone.upper.headers; let i = index">
                  <span class="title-zone">{{ header }}</span> <br />
                  <strong>{{ zone.upper.values[i] }}</strong><br />
                  <span class="title-zone">{{ zone.upper.units[i] }}</span> <br />
                </td>
                <td [colSpan]="zone.lower.colspan"
                    *ngFor="let header of zone.target.headers; let i = index">
                  <div *ngIf="zone.name.value !== appConstant.LACTATE_SHUTTLING && zone.target.values[i] !== '0'">
                    <span class="title-zone">{{ header }}</span> <br />
                    <strong>{{ zone.target.values[i] }}</strong><br />
                    <span class="title-zone">{{ zone.target.units[i] }}</span> <br />
                  </div>
                </td>
                <td [style.width]="getWidthOutputValue()"
                    *ngFor="let header of zone.output_values.headers; let i = index">
                  <span class="title-zone">{{ header }}</span> <br />
                  <strong>{{ zone.output_values.values[i] }}</strong><br />
                  <span class="title-zone">{{ zone.output_values.units[i] }}</span>
                  <br />
                </td>
              </tr>
            </table>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="field.id === reportBuildingBlocks.HEART_RATE">
          <p class="u-1/1"
             *ngIf="linearRelationship === null || linearRelationship?.intercept === null">
            Unable to load the chart because no data available
          </p>
          <fieldset class="u-1/1 u-margin-bottom-s u-margin-horizontal-none"
                    *ngIf="linearRelationship && linearRelationship?.intercept !== null">
            <legend>Heart Rate Correlation</legend>
            <div>
              <strong>Slope:</strong> {{ linearRelationship?.slope }}
              <span>[bpm per {{ primary_unit }}]</span>
            </div>
            <div>
              <strong>Zero offset:</strong> {{ linearRelationship?.intercept }} [bpm]
            </div>
            <div>
              <strong>R<sup>2</sup>:</strong> {{ linearRelationship?.r2_value }}
            </div>
            <div>
              <strong>Max. heart rate</strong>
              {{ linearRelationship?.max_heart_rate?.toFixed(0) }} [bpm]
            </div>
            <div *ngIf="isPPD">
              <strong>Delay time:</strong>
              {{ linearRelationship?.best_delay_time }} [s]
            </div>
          </fieldset>
          <div class="u-position-relative u-1/1">
            <div class="water-mark-wrapper water-mark"></div>
            <div id="td-heart-rate-chart"
                 [class.u-display-none]="linearRelationship && !linearRelationship?.intercept"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="field.id.includes(reportBuildingBlocks.HORIZONTAL_LINE)">
          <div class="c-report-preview__line u-1/1"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
