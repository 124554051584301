import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IIntegration } from "@shared/components/interfaces/integration.interface";
import { AppConstants } from "@core/constants";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { from, Observable } from 'rxjs';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiInfoDto } from '@core/services/manage-organizations/manage-organization-dto';
import { ActivatedRoute } from '@angular/router';
import { ApiBaseService } from '@core/services/api-base.service';

@Component({
  selector: 'app-integration-item',
  templateUrl: './integration-item.component.html',
  styleUrls: ['./integration-item.component.scss']
})
export class IntegrationItemComponent implements OnInit, OnChanges {
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private destroyRef: DestroyRef = inject(DestroyRef);

  @Input() config: IIntegration;
  @Input() apiInfo: ApiInfoDto[];

  public apisForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiBaseService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateForm();
  }

  public copyApiKey(): any {
    const clipboardObs$: Observable<any> = from(navigator.clipboard.writeText(this.config.key));

    clipboardObs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        {
          next: () => {
            this.snackBar.open('API Key copied to clipboard', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: (error: any) => {
            this.snackBar.open('API Key did not copied', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        }
      );
  }

  private initForm() {
    const formGroupData: Record<string, FormControl> = {};
    this.formKeys().forEach(key => { formGroupData[key] = new FormControl<boolean>(false) });
    this.apisForm = this.fb.group(formGroupData);
  }

  private populateForm() {
    const organization = this.activatedRoute.snapshot.data.data;
    if (!organization) {
      return 
    }
  
    const apis: ApiInfoDto[] = (organization.api ?? []).map((api: ApiInfoDto) => api.id);
    if (!apis) {
      return
    }

    const formValues: Record<string, boolean> = {};
    this.formKeys().forEach(key => { formValues[key] = false });
    (organization.api ?? []).forEach((api: ApiInfoDto) => {
      if (api.alias) {
        formValues[this.getFormKeyFromAlias(api.alias)] = true;
      }
    });

    if (this.apisForm) {
      this.apisForm.setValue(formValues);
    }
  }

  public formKeys(): string[] {
    return AppConstants.VISIBLE_API_INFO_ALIASES.map(alias => this.getFormKeyFromAlias(alias));
  }

  public getNameFromKey(key: string) {
    const alias = this.getAliasFromKey(key);
    return this.apiInfo.find(api => api.alias != null && api.alias == alias)?.name;
  }

  public save() {
    const formData = this.apisForm.value;
    const apiIDs = this.apiInfo.filter(api => {
      if (api.alias == null) {
        return false
      }
      return !!formData[this.getFormKeyFromAlias(api.alias)];
    }).map(api => api.id);

    this.apiService.postJson(AppConstants.API.INTEGRATIONS.LINKS.APIS, { "apis": apiIDs }).subscribe({
      next: () => {
        this.snackBar.open("Organization API links updated", 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      },
      error: (err) => {
        this.snackBar.open(err, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    })
  }

  private getAliasFromKey(key: string): string {
    return key.replace("_", "-");
  }

  private getFormKeyFromAlias(key: string): string {
    return key.replace("-", "_");
  }
}
