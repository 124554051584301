import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { IFeatureSport } from "@shared/interfaces/feature-sport.interface";
import { IDropdownSelection } from "@shared/interfaces/dropdown-selection.interface";
import { FeatureManagementService } from '@shared/services/feature-management/feature-management.service';

const EXCLUDED_METRIC_SLUGS = [
  "w_prime",
  "MMSS",
  "max_power",
  "avg_power",
  "vo2_tau",
  "economy",
  "max_lactate"
];

@Component({
  selector: 'app-metric-selection',
  templateUrl: './metric-selection.component.html',
  styleUrls: ['./metric-selection.component.scss']
})
export class MetricSelectionComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private featureManagementService: FeatureManagementService = inject(FeatureManagementService);

  @Input() public config: IFeatureSport[];
  @Input() public preselectedMetric: number;
  @Output() public metricChanged: EventEmitter<number> = new EventEmitter<number>();

  public metricSelectionForm: FormGroup;
  public metricOptionList: IDropdownSelection[] = [];

  public ngOnInit(): void {
    this.initMetricSelectionForm();
    this.fillMetricSelectionForm(this.preselectedMetric);
    this.prepareMetricOptionList(this.config);

  }

  public prepareMetricOptionList(config: IFeatureSport[]): void {
    const excludedMetricSlugs = EXCLUDED_METRIC_SLUGS.filter(slug => !this.featureManagementService.getFeatureValue(slug))
    this.metricOptionList = config
      .filter((metric: IFeatureSport) => !excludedMetricSlugs.includes(metric.slug))
      .map((metric: IFeatureSport): IDropdownSelection => ({
        id: metric.id,
        name: metric.name,
      })
    );
  }

  public fillMetricSelectionForm(metricId: number): void {
    this.metricSelectionForm.get('metric_id')?.setValue(metricId);
  }

  public initMetricSelectionForm(): void {
    this.metricSelectionForm = this.fb.group({
      metric_id: [],
    });
  }

  public onChangeMetric(item: any): void {
    this.metricChanged.emit(item);
  }
}
