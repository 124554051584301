import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';
import * as moment from 'moment';

export class DeviceManagementResultDto extends PagedResultDto {
  public items: DeviceManagementDto[] = [];
}

export class DeviceManagementDto extends Deserializable {
  public id: number | undefined;
  public uuid: string | undefined;
  public ip: string | undefined;
  public is_delete: boolean | undefined;
  public name: string | undefined;
  public updated_at: string | moment.Moment | undefined;
  public created_at: string | moment.Moment | undefined;
  public organization:
    | {
        address_country_id: number | undefined;
        id: number | undefined;
        is_check_invoice: boolean | undefined;
        is_solvent: boolean | undefined;
        name: string | undefined;
        photo: string | undefined;
      }
    | undefined;
  public user:
    | {
        id: number | undefined;
        first_name: string | undefined;
        last_name: string | undefined;
        username: string | undefined;
      }
    | undefined;
}

export interface IDeviceDetail {
  id?: number;
  uuid?: string;
  confirm_code?: string;
  confirm_code_new?: string;
  ip?: string;
  is_confirm_new?: boolean;
  is_delete?: boolean;
  name?: string;
  updated_at?: string | moment.Moment;
  organization?: number;
  user?: number;
}
