<mat-card class="catalog">
  <div (click)="goToCourseDetail()">
    <div class="video-section">
      <img
        id="background"
        [src]="course.avatar"
        class="course-image"
        alt=""
      />
    </div>
    <div class="title-section">
      <div id="title">
        <h1 id="title-text">{{ course.name }}</h1>
      </div>
      <app-course-price-discount class="margin-left-10" [currency]="course?.currency_name" [userPrice]="course?.user_price" [originalPrice]="course?.original_price" [isCard]="true"></app-course-price-discount>
    </div>
    <div class="content-section">
      <p class="description">{{ course.description }}</p>
<!--      <p [id]="'description_' + course.id" class="description"></p>-->
    </div>
    <div class="icons-section">
      <div class="icon">
        <div>
          <mat-icon>schedule</mat-icon>
          <p>{{ course.duration || 'N/A' }}</p>
        </div>
        <div>
          <mat-icon>help_outline</mat-icon>
          <p>{{ course.number_of_quizzes ? course.number_of_quizzes : 0 }} Quizes</p>
        </div>
        <div>
          <mat-icon class="material-icons-outlined">verified</mat-icon>
          <p>{{ course.number_of_certificates ? course.number_of_certificates : 0 }} Certificates</p>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-section">
    <button
      mat-raised-button
      color="primary"
      class="{{getPurchaseButtonColor()}}"
      (click)="handleCourseAction()"
    >{{getPurchaseButtonText()}}</button>
  </div>
</mat-card>
