import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { TableService } from '@shared/services/table.service';
import { ITableHeader } from '../interfaces/table.interface';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  providers: [TableService],
})
export class TableComponent implements OnInit {
  @Input() public url: string;
  @Input() public headers: ITableHeader[];

  constructor(public readonly tableDataService: TableService, private destroyRef: DestroyRef,) {}

  public ngOnInit(): void {
    this.tableDataService.getTableData(this.url, this.headers)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
