import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IUpdateTableMetric } from "@shared/interfaces/update-table-metric.interface";
import { IMetricControlConfig } from "@shared/interfaces/metric-control-config.interface";

@Component({
  selector: 'app-pp-metric-control',
  templateUrl: './performance-projection-metric-control.component.html',
  styleUrls: ['./performance-projection-metric-control.component.scss'],
})
export class PerformanceProjectionMetricControlComponent {
  @Input() public config: IMetricControlConfig[];
  @Output() public updateControlSliderMetric: EventEmitter<IUpdateTableMetric> = new EventEmitter<IUpdateTableMetric>();
  @Output() public updateControlVisibilityMetric: EventEmitter<any> = new EventEmitter<any>();

  public isOneValueSlider: boolean;
  public changedSliderList: string[] = [];

  public updateSliderControls(data: any): void {
    if (data.min === data.max) {
      this.config[data.index].currentMetricValue = `a new value of ${data.max} will be used`;
      this.config[data.index].currentValue = data.max;
      this.isOneValueSlider = true;
    } else {
      this.isOneValueSlider = false;
      this.config[data.index].currentMetricValue = this.config[data.index].fallbackCurrentValue;
    }

    this.config[data.index].currentData = { min: data.min, max: data.max };
    this.updateControlSliderMetric.emit({
      data: this.config,
      sliderId: data.sliderId,
      isOneValueSlider: this.isOneValueSlider,
    });

    if (!this.changedSliderList.includes(data.sliderId)) {
      this.changedSliderList.push(data.sliderId);
    }
  }

  public onVisibilityChange(value: any): void {
    this.config.forEach((item: any) => {
      item.visibility = value.indexOf(item.id) > -1;
    });

    this.updateControlVisibilityMetric.emit({data: this.config});
  }

  public getCurrentMetricControl(): IMetricControlConfig[] {
    return this.config;
  }

  public getChangedSliderIdList(): string[] {
    return this.changedSliderList;
  }
}
