import { Component, DestroyRef, inject, Input } from '@angular/core';
import { PaymentSummaryDialogComponent } from "@shared/components/payment-summary-dialog/payment-summary-dialog.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { AppConstants } from "@core/constants";
import { IGeneralInfoList } from "@shared/interfaces/general-info-list.interface";
import { IExtendPaymentModalDialog } from "@shared/interfaces/extend-payment-modal-dialog.interface";
import { IResponsePaymentModalDialog } from "@shared/interfaces/response-payment-modal-dialog.interface";
import { ApiService } from "@core/services/api.service";
import { MatSidenav } from "@angular/material/sidenav";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CustomRendererService } from "@shared/services/custom-renderer.service";

@Component({
  selector: 'app-try-for-free-button',
  templateUrl: './try-for-free-button.component.html',
  styleUrls: ['./try-for-free-button.component.scss']
})
export class TryForFreeButtonComponent {
  private dialog: MatLegacyDialog = inject(MatLegacyDialog);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private apiService: ApiService = inject(ApiService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private customRendererService: CustomRendererService = inject(CustomRendererService);

  public buttonName: string = 'Try For Free';
  public extraInformation: string = '30-day free trial, then US$9.99/mo';

  @Input() public tryForFreeModalDialogConfig: IGeneralInfoList & IExtendPaymentModalDialog;

  public tryForFree(): void {
    this.dialog.open(PaymentSummaryDialogComponent, {
      data: this.tryForFreeModalDialogConfig,
    })
      .afterClosed()
      .subscribe({
        next: (dialogResponse: IResponsePaymentModalDialog): void => {
          this.apiService.post(
            AppConstants.API.FEATURE_PURCHASE.POST,
            {feature_id: dialogResponse.featureId, additional_dependencies: dialogResponse.additionalDependencies}
          )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: (): void => {
                this.snackBar.open('Item has been purchased successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              },
              error: () => {
                this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
                this.snackBar.open('Error', 'OK', AppConstants.TOAST_CONFIG.ERROR);
              }
          });
        },
        error: (error: string): void => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      });
  }
}
