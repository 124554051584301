import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { ITagsDetail, TagsManagementDto, TagsManagementResultDto } from './tags-management-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from '@shared/models/api-request';

@Injectable({
  providedIn: 'root',
})
export class TagsManagementService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto): Observable<TagsManagementResultDto> {
    const apiUrl: string = this._appConstants.API.TAGS.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new TagsManagementResultDto().deserialize(
          {
            totalCount: res.count,
            items: res.results.map((item: TagsManagementDto) =>
              new TagsManagementDto().deserialize(item)
            ),
          }
        );
      })
    );
  }
  public getById(id: number): Observable<ITagsDetail> {
    const apiUrl: string = String.Format(this._appConstants.API.TAGS.GET, id);

    return this._apiService.get(apiUrl);
  }

  public create(input: { name: string; organization: number }): Observable<Object> {
    const apiUrl: string = this._appConstants.API.TAGS.ADD;
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    Object.assign(body, input);

    return this._apiService.post(apiRequest.apiUrl, apiRequest.body);
  }

  public update(input: ITagsDetail): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.TAGS.UPDATE, input.id);
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    Object.assign(body, input);

    return this._apiService.put(apiRequest.apiUrl, apiRequest.body);
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(this._appConstants.API.TAGS.DELETE, id);

    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }
}
