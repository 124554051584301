export const PD_DEFAULT_GRAPH: any = {
  "x_axis_configuration": {
    "ticks": "date"
  },
  "y_axis_configuration": {
    "left_1": {
      "data": [
        {
          "name": "Recovery Index",
          "slug": "recovery_index",
          "value": "recovery_index__data_point-0",
          "datapoint": "data_point-0"
        }
      ]
    },
    "left_2": {
      "data": [
        {
          "name": "Accumulation Index",
          "slug": "accumulation_index",
          "value": "accumulation_index__data_point-0",
          "datapoint": "data_point-0"
        }
      ]
    },
    "right_1": {
      "data": [
        {
          "name": "W` - Absolute [kJ]",
          "slug": "w_prime",
          "value": "w_prime__data_point-1",
          "datapoint": "data_point-1"
        }
      ]
    }
  }
};
