import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IPerformanceLimitsConfig, IPerformanceLimitsTabsConfig, } from "@shared/interfaces/performance-limits.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-performance-limits',
  templateUrl: './performance-limits.component.html',
  styleUrls: ['./performance-limits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceLimitsComponent implements OnChanges {
  private fb: FormBuilder = inject(FormBuilder);

  public performanceLimitsProForm: FormGroup;
  public performanceLimitsAmateurForm: FormGroup;
  public performanceLimitsRecreationalForm: FormGroup;
  public performanceLimitsCustomForm1: FormGroup | undefined;
  public performanceLimitsCustomForm2: FormGroup | undefined;
  public performanceLimitsCustomForm3: FormGroup | undefined;
  public performanceLimitsCustomForm4: FormGroup | undefined;
  public performanceLimitsCustomForm5: FormGroup | undefined;
  public performanceLimitsCustomForm6: FormGroup | undefined;

  @Input() public config: IPerformanceLimitsConfig;
  @Input() public angleName: string;
  @Output() performanceLimitChanged: EventEmitter<any> = new EventEmitter<any>();

  public ngOnChanges(changes: SimpleChanges): void {
    this.initPerformanceLimitsDefaultFormList();
    this.resetCustomTabList();
    this.initAndFillCustomTabs();
    this.fillAllFormList(changes.config.currentValue);
  }

  public initPerformanceLimitsDefaultFormList(): void {
    this.performanceLimitsProForm = this.fb.group({
      name: [''],
      male_lower: [1],
      male_upper: [1, [Validators.min(1)]],
      male_stdDev: [1],
      female_lower: [1],
      female_upper: [1],
      female_stdDev: [1],
    });

    this.performanceLimitsAmateurForm = this.fb.group({
      name: [''],
      male_lower: [1],
      male_upper: [1],
      male_stdDev: [1],
      female_lower: [1],
      female_upper: [1],
      female_stdDev: [1],
    });

    this.performanceLimitsRecreationalForm = this.fb.group({
      name: [''],
      male_lower: [1],
      male_upper: [1],
      male_stdDev: [1],
      female_lower: [1],
      female_upper: [1],
      female_stdDev: [1],
    });
  }

  public emitAllFormsData(): void {
    let performanceLimitsObject: any = {
      tabs: {
        pro: this.performanceLimitsProForm.value,
        amateur: this.performanceLimitsAmateurForm.value,
        recreational: this.performanceLimitsRecreationalForm.value,
      },
      customTabObject: {},
    };

    if (this.performanceLimitsCustomForm1) {
      performanceLimitsObject = {
        ...performanceLimitsObject,
        customTabObject: {
          ...performanceLimitsObject.customTabObject,
          [this.performanceLimitsCustomForm1.get('name')?.value]: this.performanceLimitsCustomForm1.value
        }
      };
    }
    if (this.performanceLimitsCustomForm2) {
      performanceLimitsObject = {
        ...performanceLimitsObject,
        customTabObject: {
          ...performanceLimitsObject.customTabObject,
          [this.performanceLimitsCustomForm2.get('name')?.value]: this.performanceLimitsCustomForm2.value
        }
      };
    }
    if (this.performanceLimitsCustomForm3) {
      performanceLimitsObject = {
        ...performanceLimitsObject,
        customTabObject: {
          ...performanceLimitsObject.customTabObject,
          [this.performanceLimitsCustomForm3.get('name')?.value]: this.performanceLimitsCustomForm3.value
        }
      };
    }
    if (this.performanceLimitsCustomForm4) {
      performanceLimitsObject = {
        ...performanceLimitsObject,
        customTabObject: {
          ...performanceLimitsObject.customTabObject,
          [this.performanceLimitsCustomForm4.get('name')?.value]: this.performanceLimitsCustomForm4.value
        }
      };
    }
    if (this.performanceLimitsCustomForm5) {
      performanceLimitsObject = {
        ...performanceLimitsObject,
        customTabObject: {
          ...performanceLimitsObject.customTabObject,
          [this.performanceLimitsCustomForm5.get('name')?.value]: this.performanceLimitsCustomForm5.value
        }
      };
    }
    if (this.performanceLimitsCustomForm6) {
      performanceLimitsObject = {
        ...performanceLimitsObject,
        customTabObject: {
          ...performanceLimitsObject.customTabObject,
          [this.performanceLimitsCustomForm6.get('name')?.value]: this.performanceLimitsCustomForm6.value
        }
      };
    }

    this.performanceLimitChanged.emit(performanceLimitsObject);
  }

  public subscribeForAllFormChanges(): void {
    this.performanceLimitsProForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData();},});
    this.performanceLimitsAmateurForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsRecreationalForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm1?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm2?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm3?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm4?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm5?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm6?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
  }

  public resetCustomTabList(): void {
    this.performanceLimitsCustomForm1 = undefined;
    this.performanceLimitsCustomForm2 = undefined;
    this.performanceLimitsCustomForm3 = undefined;
    this.performanceLimitsCustomForm4 = undefined;
    this.performanceLimitsCustomForm5 = undefined;
    this.performanceLimitsCustomForm6 = undefined;
  }

  public initAndFillCustomTabs(): void {
    let tabObjectList: any[] = [];

    for(let customTab in this.config.customTabObject) {
      tabObjectList.push(this.config.customTabObject[customTab]);
    }

    tabObjectList.forEach((tab: IPerformanceLimitsTabsConfig, index: number) => {
      if ((index + 1) === 1) {
        this.performanceLimitsCustomForm1 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
        });
      }

      if ((index + 1) === 2) {
        this.performanceLimitsCustomForm2 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
        });
      }
      if ((index + 1) === 3) {
        this.performanceLimitsCustomForm3 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
        });
      }

      if ((index + 1) === 4) {
        this.performanceLimitsCustomForm4 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
        });
      }
      if ((index + 1) === 5) {
        this.performanceLimitsCustomForm5 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
        });
      }
      if ((index + 1) === 6) {
        this.performanceLimitsCustomForm6 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
        });
      }
    });
  }

  public fillAllFormList(config: IPerformanceLimitsConfig): void {
    this.performanceLimitsProForm.patchValue(config.tabs.pro);
    this.performanceLimitsAmateurForm.patchValue(config.tabs.amateur);
    this.performanceLimitsRecreationalForm.patchValue(config.tabs.recreational);

    this.subscribeForAllFormChanges();
  }
}
