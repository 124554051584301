import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppConstants } from '@core/constants';
import { SessionService } from '@core/services/session.service';
import { ApiService } from '@core/services/api.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { TeamDto } from '@core/services/teams-competitions/team-dto';
import { TeamService } from '@core/services/teams-competitions/team.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-create-team-dialog',
  templateUrl: './create-team-dialog.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateTeamDialogComponent implements OnInit {
  public teamForm: UntypedFormGroup = this.fb.group({});
  public _appConstants = AppConstants;
  public filteredCoaches: any;
  public coachesLst: string[] | undefined;
  public filteredAthletes: any;
  public athletesLst: string[] | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateTeamDialogComponent>,
    private sessionService: SessionService,
    private _apiService: ApiService,
    private _teamService: TeamService,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.fetchCoaches();
    this.fetchAthletes();
    this.initTeamForm();
  }
  public fetchCoaches(): void {
    this._apiService.get('accounts/coaches/')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: any): void => {
      this.coachesLst = response.results.sort();
      this.filteredCoaches = this.coachesLst?.slice();
    });
  }
  public fetchAthletes(): void {
    this._apiService.get('athletes/')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: any): void => {
      this.athletesLst = response.results.sort();
      this.filteredAthletes = this.athletesLst?.slice();
    });
  }
  public initTeamForm(): void {
    if (!this.data.isEditTeam) {
      this.teamForm = this.fb.group({
        name: new UntypedFormControl('', Validators.required),
        selectYear: new UntypedFormControl('', Validators.required),
        coaches: new UntypedFormControl('', Validators.required),
        athletes: new UntypedFormControl('', Validators.required),
        isActive: new UntypedFormControl(false, Validators.required),
      });
    } else {
      let selectedCoaches = this.data.team.coaches.map((c: any) => c.id);
      let selectedAthletes = this.data.team.athletes.map((a: any) => a.id);
      this.teamForm = this.fb.group({
        name: new UntypedFormControl(this.data.team.name, Validators.required),
        selectYear: new UntypedFormControl(
          moment().year(this.data.team.year),
          Validators.required
        ),
        coaches: new UntypedFormControl(selectedCoaches, Validators.required),
        athletes: new UntypedFormControl(selectedAthletes, Validators.required),
        isActive: new UntypedFormControl(
          this.data.team.is_active,
          Validators.required
        ),
      });
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public saveAction(): void {
    let profileData = TeamDto.fromJS(
      JSON.parse(this.sessionService.getCookie('auth'))
    );
    if (this.teamForm.status === 'INVALID') {
      this.snackBar.open('Please full fill the form.', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      return;
    }

    let year: number = new Date(this.teamForm.get('selectYear')?.value).getFullYear();
    let team: TeamDto = new TeamDto();
    team.organization = profileData?.organization?.id;
    team.athletes = this.teamForm.get('athletes')?.value;
    team.coaches = this.teamForm.get('coaches')?.value;
    team.year = year;
    team.is_active = this.teamForm.get('isActive')?.value;
    team.name = this.teamForm.get('name')?.value;
    team.updated_at = new Date();
    team.created_at = new Date();

    if (this.data.isEditTeam) {
      team.id = this.data.team.id;
      this._teamService.update(team)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: Object) => this.dialogRef.close(res));
    } else {
      this._teamService.create(team)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: Object) => this.dialogRef.close(res));
    }
  }

  public chosenYearHandler(ev: any, input: any): void {
    let { _d } = ev;
    this.teamForm?.controls.selectYear.setValue(_d);
    input.close();
  }
}
