import { environment } from 'src/environments/environment';

export const AppConstants = {
  MAT_SPINNER_CLASS: '.loader-indicator',
  ENVIRONMENT_URL: {
    PROD: 'https://app.inscyd.com/',
    DEV: 'https://app.dev.inscyd.com/',
    TEST: 'https://app.test.inscyd.com/',
  },
  VISUALIZATION_PAGE: {
    PERFORMANCE_LIMITS_DESCRIPTION: 'Here you can see default boundaries and set custom boundaries for the value displayed on the chart. The settings here will inform how the the corresponding physiological metric is ranked. You can set an upper and lower boundary and a standard deviation. If you don’t set a custom standard deviation, the standard deviation of a normal distribution between lower and upper boundaries is calculated automatically.'
  },
  WORDING: {
    INTRODUCTION_TEXT: 'Introduction Text',
    BODY_COMPOSITION: 'Body Composition',
    METABOLIC_FINGERPRINT: 'Metabolic Fingerprint',
    PERFORMANCE_DEVELOPMENT: 'Performance Development',
    PHYSIOLOGICAL_PERFORMANCE: 'Physiological Performance',
    METABOLIC_POWER: 'Metabolic Power',
    METABOLIC_PROFILE: 'Metabolic Profile',
    ORG_INFO: 'Organization Info',
    TEST_INFO: 'Test Info',
    LOAD_CHARACTERISTICS: 'Load Characteristics',
    TEST_DATA_GRAPHS: 'Test Data - Graphs',
    HEART_RATE: 'Heart Rate',
    ECONOMY: 'Economy',
    TEST_DATA_TABLES: 'Test Data - Tables',
    TRAINING_ZONES: 'Training Zones',
    GENERAL_TEXT: 'General Text',
    EUROPE_1: 'Europe 1',
    EUROPE_2: 'Europe 2',
    EUROPE_3: 'Europe 3',
    EUROPE_4: 'Europe 4',
    WORLD_1: 'World 1',
    WORLD_2: 'World 2',
    WORLD_3: 'World 3',
  },
  LACTATE_SHUTTLING: 'Lactate Shuttling',
  PRIVACY_POLICY_API: 'consent/notices/privacy_policy/',
  TERM_API: 'consent/notices/term/',
  TEXT_EDITOR_VIEW_TYPE: {
    BUILDER: 'builder',
    PREVIEW_PDF: 'previewPDF',
    GENERAL: 'general',
  },
  ACCOUNTS_URL: {
    ACCOUNTS: 'accounts/',
    ACCOUNTS_CURRENT: {
      GET: 'accounts/current/',
      PUT: 'accounts/current/',
    },
    CHANGE_PASSWORD: {
      POST: 'accounts/update-user-password/'
    },
    ALL_USER_SESSION: {
      GET: 'accounts/user-sessions/all/'
    },
    USER_SESSION_LOGOUT: {
      DELETE: 'accounts/user-sessions/{0}/'
    },
    USER_SESSION_ALL_LOGOUT: {
      DELETE: 'accounts/user-sessions/all/'
    },
    DEACTIVATE_CURRENT_ACCOUNT: {
      PATCH: 'accounts/current/'
    },
    PLANS: 'plans/',
    USER_COURSES: 'lms/user-courses/',
    USER_COURSES_DASHBOARD: 'lms/user-courses/dashboard/',
    LMS_COURSES: 'lms/user-courses/',
    LMS_USER_COURSE_DETAIL: 'lms/user-courses/{0}/',
    USER_COURSES_WAITLIST: 'lms/user-courses/{0}/waitlist/',
    POST: {
      REGISTER: 'register/',
      COUNTRY: 'country/',
      ADDITIONAL_INFO: 'additional-info/',
    },
    GET: {
      COUNTRY: 'countries/',
    }
  },
  API_HOST: environment.api,
  V1_URL: environment.v1URL,
  SERVER_URL: environment.serverURL,
  DATE_FORMAT: {
    APP_FULL_DATE: 'dd.MM.YYYY',
    DATE_TIME: 'dd.MM.YYYY HH:mm',
    DATE_TIME_SECOND: 'dd.MM.YYYY - HH:mm:ss',
    APP_FULL_DATE_MOMENT: 'DD.MM.YYYY',
    DATE_TIME_MOMENT: 'DD.MM.YYYY HH:mm',
    DATE_TIME_SECOND_MOMENT: 'DD.MM.YYYY - HH:mm:ss',
  },
  THEME: {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
  },
  LOCAL_STORAGE_KEYS: {
    REPORT_DATA: 'reportData',
    TTD_HISTORY: 'TTDHistory',
    RECOVERY_HISTORY: 'RecoveryHistory',
    IDE_TIME_UNIT: 'IATAC_IDE_TIME_UNIT',
    IDE_TIME: 'IATAC_IDE_TIME',
  },
  ADDITIONAL_CONSTANTS: {
    konv_lapcr: 1.35,
    pcr_exhaustion: 5.5,
    pcr_initial: 22,
    pcr_mlo2: 4.051,
    unit_tau: 12.5,
    vo2_initial: 1,
  },
  TEST_DEFAULT_CONSTANTS: {
    vo2max_tol_proz: 10,
    vlamax_tol_proz: 10,
    tolerance: 0.01,
    proz_body_fat_100: 15,
    watto2eq: 12.5, // O2 per watt equivalent,
    max_heart_rate: 220
  },
  TEST_STATIC_CONSTANTS: {
    klavo2: 0.01575,
    ks_lapH: 4.5e-21,
    ks_vo2: 0.00135,
    ks2_laADP: 0.0045,
    lao2eq: 2.745, // lao2eq = dist_space(%) * lao2eq_precise ex: 50% * 5.335 = 2.6675
    lao2eq_precise: 5.49,
    //        max_o2debt: 30, // Remove due to Section 1. New Lactate CR 2021
    pcro2eq: 3.95,
    vla_rest: 0.06,
    f_vs_musc_male_grad: 0.58,
    f_vs_musc_male_b: -5,
    f_vs_musc_female_grad: 0.58,
    f_vs_musc_female_b: -5,
    f_vs_h2o_male_grad: -0.25,
    f_vs_h2o_male_b: 72,
    f_vs_h2o_female_grad: -0.25,
    f_vs_h2o_female_b: 72,
    kel: 2.5,
    tau: 32,
    tau_vo2: 1.5,
    proz_lactat_dist_space_100_override: 50, // if this changed, should update lao2eq above
    glycogen_content: 20,
  },
  COUNTRY_SUPPORT_SEPA: [
    'Austria',
    'Belgium',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Netherlands',
    'Poland',
    'Portugal',
    'Romania',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
  ],
  TEST_TYPES: {
    MANUAL: 'manual',
    CRITICAL_POWER: 'map',
    VIRTUAL: 'virtual',
    LACTATE: 'lactate',
    PPD: 'newtype',
    USER: 'user',
    ATHLETE: 'athlete',
    EVENT: 'event',
    RESULT: 'result',
  },
  HTTP_CODES: {
    STATUS_OK: 200,
    CREATION_SUCCEEDED: 201,
    NOT_FOUND: 404,
  },
  SNACK_BAR_STATUS: {
    OK: 'OK',
  },
  NOTIFICATIONS: {
    SUCCESSFULLY_ADD_EVENT: 'Add Event Successfully',
    UNSUCCESSFULLY_ADD_EVENT: 'Add Event Unsuccessfully',
    ATHLETE_ADDED: 'Athlete has been added',
    TEAM_ADDED: 'Team has been added',
    EVENT_ADDED: 'Event has been added',
    ERROR: 'Something went wrong',
  },
  STAPS: {
    URL: 'http://iatac.local/',
    KEY: '4pVllhnHLK',
    PDF_LINK: 'http://iatac.local/wp-json/itac/v1/pdf-link',
    PDF_LINK_DOWNLOAD: 'http://iatac.local/download?token=',
    PURCHASE_LINK: 'http://iatac.local/wp-json/itac/v1/purchase',
    CHANGE_PAYMENT_METHOD_LINK:
      'http://iatac.local/wp-json/itac/v1/change-payment-method',
    CAN_PURCHASE: 'http://iatac.local/wp-json/itac/v1/can-purchase',
    PDK_BULK_DOWNLOAD:
      'http://iatac.local/wp-json/itac/v1/pdf-zip-link?file_name=',
  },
  PERIOD_TYPES: [
    { id: 1, name: 'Year' },
    { id: 2, name: 'Quarter' },
    { id: 3, name: 'Month' },
    { id: 4, name: 'Days' },
  ],
  ITEMS: [
    {
      id: 1,
      NAME: 'User',
    },
    {
      id: 2,
      NAME: 'Athlete',
    },
    {
      id: 3,
      NAME: 'Lactate',
    },
    {
      id: 4,
      NAME: 'PPD',
    },
    {
      id: 5,
      NAME: 'Manual',
    },
    {
      id: 6,
      NAME: 'Virtual',
    },
    {
      id: 7,
      NAME: 'Event',
    },
    {
      id: 8,
      NAME: 'Result',
    },
    {
      id: 9,
      NAME: 'Metabolic Profile',
    },
    {
      id: 10,
      NAME: 'Reports',
    },
  ],
  METABOLIC_PROFILE: {
    TOOLS: '',
    BODY_COMPOSITION: {
      PIE_CHART: {
        WIDTH: 255,
        HEIGHT: 365,
      },
      BAR_CHART: {
        WIDTH: 400,
        HEIGHT: 500,
      },
    },
  },
  WATERMARK_PHOTO_CSS: ".water-mark { " +
        "background: url('{0}') no-repeat;" +
        "opacity: 0.2;" +
        "background-size: {1}px {2}px;" +
        "background-position: 50% 50%;" +
        "}",
  METABOLIC_CAPACITIES_WATERMARK_CSS:
    '#metabolic_capacities_rp .water-mark,' +
    '#metabolic_capacities .water-mark {' +
    'background-size: {0}px {1}px;' + //max width 100px, height flexible 16px (ratio 0.16)
    'background-position: 33.75% 65%;' +
    '}',
  METABOLIC_FINGERPRINT_WATERMARK_CSS:
    '#metabolic_fingerprint .water-mark {' +
    'width: 70px;' +
    'height: 70px;' +
    'position: absolute;' +
    'background-size: contain;' +
    'left: {0}px;' +
    'top: {1}px;' +
    '}',
  METABOLIC_FINGERPRINT_RP_WATERMARK_CSS:
    '#metabolic_fingerprint_rp .water-mark {' +
    'width: 70px;' +
    'height: 70px;' +
    'position: absolute;' +
    'background-size: contain;' +
    'left: {0}px;' +
    'top: {1}px;' +
    '}',
  REFERENCE_SYSTEM: {
    POWER_WATT: 1,
    POWER_WATT_KG: 2,
    SPEED_MS: 3,
    SPEED_KM_H: 4,
    SPEED_MP_H: 5,
    SPEED_MIN_SEC_KM: 6,
    SPEED_MIN_SEC_MILE: 7,
    SPEED_MIN_SEC_100M: 8,
    SPEED_MIN_SEC_500M: 9,
  },
  MASTER_METRIC: {
    VO2_MAX: 1,
    VLA_MAX: 2,
    LACTATE_ACCUMULATION_RATE: 3,
    LACTATE_CLEARANCE_RATE: 4,
    LACTATE_CONCENTRATION: 5,
    TOTAL_ENERGY: 6,
    FAT_PERCENT: 7,
    FAT_KCAL: 8,
    FAT_GH: 9,
    CARBOHYDRATES_PERCENT: 10,
    CARBOHYDRATES_KCAL_H: 11,
    CARBOHYDRATES_G_H: 12,
    AEROBIC_POWER_KCAL_H: 13,
    AEROBIC_POWER_WATT: 14,
    AEROBIC_POWER_WATT_KG: 15,
    AEROBIC_POWER_JOULE_H: 16,
    ANAEROBIC_POWER_K_CAL: 17,
    ANAEROBIC_POWER_WATT: 18,
    ANAEROBIC_POWER_WATT_KG: 19,
    ANAEROBIC_POWER_WATT_JOULE: 20,
    FAT_MAX: 21,
    ANAEROBIC_THRESHOLD: 22,
    MAX_LACTATE_CLEARANCE: 23,
    MAX_LACTATE_PRODUCTION_RATE: 24,
    ENERGY_CONTRIBUTION_AEROBIC: 25,
    ENERGY_CONTRIBUTION_ANAEROBIC: 26,
    HEART_RATE: 27,
    LACTATE_SHUTTLING: 28,
    VO2MAX_POWER: 29,
    FAT_MAX_POWER: 30,
    MAX_HEART_RATE_PERCENT: 31,
    LT1: 32,
  },
  API: {
    FEATURE_SPORT_CONFIGURATION: {
      GET: 'features/configurations/{0}/',
    },
    MY_INSCYD_FEATURE_ITEM: {
      GET: 'my-inscyd-feature-item/',
    },
    FEATURE_ITEM: {
      GET: 'features/',
    },
    FEATURE_CATALOG: {
      GET: 'features/catalog/',
    },
    FEATURE_PURCHASE: {
      POST: 'features/purchases/',
      INFO: 'features/{0}/purchase-info/',
      UNSUBSCRIBE: 'features/purchases/{0}/',
      INVALIDATE_CANCELLATION: 'features/purchases/{0}/invalidate_cancellation/',
    },
    FEATURE_WAIT_LIST: {
      POST: 'features/{0}/waitlist/'
    },
    CONFIRM_SHARED_DATA: {
      POST: 'athletes/confirm-share-data/',
    },
    RIGHTS_MANAGEMENT: {
      LIST: 'rights/',
      ADD: 'rights/',
      GET: 'rights/{0}/',
      UPDATE: 'rights/{0}/',
      DELETE: 'rights/{0}/',
    },
    DEVICE_MANAGEMENT: {
      LIST: 'accounts/devices/',
      GET: 'accounts/devices/{0}/',
      UPDATE: 'accounts/devices/{0}/',
      DELETE: 'accounts/devices/{0}/',
    },
    HISTORY_LOG: {
      LIST: 'accounts/history-logs/',
      GET: 'accounts/history-logs/{0}/',
    },
    ACCOUNT_TYPE: {
      LIST: 'account-type/',
      ADD: 'account-type/',
      UPDATE: 'account-type/{0}/',
      DELETE: 'account-type/{0}/',
    },
    API_TRACKING: {
      LIST: 'organizations/api_tracking/',
      GET: 'organizations/api_tracking/{0}/',
    },
    REGION_SETTINGS: {
      LIST: 'region-pricing/',
      GET: 'region-pricing/{0}/',
      UPDATE: 'region-pricing/change_region/',
      ADD: 'region-pricing/',
      DELETE: 'region-pricing/{0}/',
    },
    COUNTRIES: {
      LIST: 'countries/countries_region/',
      GET: 'countries/'
    },
    CURRENCY: {
      LIST: 'currency/',
    },
    PRICING_PLANS: {
      UPDATE: 'plans/{0}/',
      ADD: 'plans/',
      LIST: 'plans/',
      GET_BY_ID: 'plans/{0}/',
      DELETE: 'plans/{0}/',
      GROUPS: 'plans/groups/',
      CLONE: 'plans/clone/',
      PAYMENT_LINKS: {
        BY_GUID: 'plans/payment_links/',
        STATUS_BY_GUID: 'plans/payment_links/status/',
        GET_BY_ID: 'plans/{0}/payment_links/{1}/',
      },
      PURCHASE_SESSIONS: {
        GENERATE: 'plans/{0}/purchase_sessions/',
        EMAIL: 'plans/{0}/purchase_sessions/{1}/messages/',
      },
    },
    ORGANIZATION_SETTING: {
      DEFAULT_TEXT: 'default-text/',
      UPDATE_DEFAULT_TEXT: 'default-text/{0}/',
      STATISTICS: 'organizations/',
    },
    MY_DEVICES: {
      LIST: 'accounts/my-devices/',
      DELETE: 'accounts/my-devices/{0}/',
    },
    TAGS: {
      LIST: 'tags/',
      ADD: 'tags/',
      GET: 'tags/{0}/',
      UPDATE: 'tags/{0}/',
      DELETE: 'tags/{0}/',
    },
    TRAINING_ZONE_TEMPLATE: {
      LIST: 'training_zone/',
      ADD: 'training_zone/',
      UPDATE: 'training_zone/{0}/',
      DELETE: 'training_zone/{0}/',
    },
    SPORT: {
      LIST: 'sports/',
      ADD: 'sports/',
      GET_DETAIL: 'sports/{0}/',
      UPDATE: 'sports/{0}/',
      DELETE: 'sports/{0}/',
    },
    ATHLETE: {
      ACCOUNTINFO: 'accounts/{0}/',
      STAPS: {
        URL: 'https://iatac-wordpress.soxes-projects.ch/',
      },
      GET_NOTIFICATIONS: 'athletes/get-notifications/',
      UPDATE_NOTIFICATION: 'athletes/update-notification/{0}/',
      LIST: 'athletes/',
      LISTALL: 'athletes/list-all/',
      ADD: 'athletes/',
      GET: 'athletes/{0}/',
      UPDATE: 'athletes/{0}/',
      DELETE: 'athletes/{0}/',
      CHECK_EMAIL_EXIST: 'athletes/request-share-data/',
      GARMIN_VERIFY: 'athletes/garmin-verify/{0}/',
      GARMIN_REVOKE: 'athletes/garmin-revoke/{0}/',
      STRAVA_REVOKE: 'athletes/strava-revoke/{0}',
      GARMIN_SYNC: 'athletes/garmin-sync-activity/',
      STRAVA_SYNC: 'athletes/strava-sync-activity/',
      TRAINING_LEVEL: 'athletes/training-levels/',
      UPDATE_TRAINING_LEVEL: 'athletes/training-levels/update-list/',
      ACCEPT_MULTIPLE: 'athletes/accept_multiple/',
    },
    TEAMS: {
      LIST: 'crossfilter/teams/',
      ADD: 'crossfilter/teams/',
      UPDATE: 'crossfilter/teams/{0}/',
      DELETE: 'crossfilter/teams/{0}/',
    },
    COMPETITIONS: {
      GET: 'crossfilter/competitions/',
      ADD: 'crossfilter/competitions/',
      UPDATE: 'crossfilter/competitions/{0}/',
      DELETE: 'crossfilter/competitions/{0}/',
    },
    PARTICIPATIONS: {
      GET: 'crossfilter/participations/',
      ADD: 'crossfilter/participations/',
    },

    SETTINGS: {
      GET_CONFIGURATION: 'accounts/configuration/',
    },
    TEST: {
      TIME_TO_DEPLETION: 'tests/apc/time_to_depletion/',
      RECOVERY_MATRIX: 'tests/apc/recovery_metric/',
      TZB_HEART_RATE: 'tests/tzb/heart_rate/',
      LIST: 'tests/',
      DELETE: 'tests/{0}/',
      GET_BY_ID: 'tests/{0}/',
      METABOLIC: 'tests/metabolic/{0}/',
      DOWNLOAD_FILE: 'tests/{0}/download/',
      DOWNLOAD_SSE_REPORT: 'tests/{0}/download_sse_report/',
      GET_FILTER_DATA_IN_FILE: 'tests/{0}/get_filter_data_in_file/',
      SPEED_RELATIONSHIP_CHART: 'tests/{0}/speed-relationship-chart/',
      GET_BY_ATHLETE_SPORT: 'tests/?athlete={0}&sport={1}',
      UPDATE: 'tests/{0}/',
    },
    EVENTS: {
      LIST: 'events/',
    },
    RESULTS: {
      LIST: 'results/',
      DELETE: 'results/{0}/',
      DOWNLOAD: 'results/{0}/download/'
    },
    INFORMATION_POPUP: {
      LIST: 'information_popups/',
      ADD: 'information_popups/',
      DELETE: 'information_popups/{0}/',
      UPDATE: 'information_popups/{0}/',
      GET_BY_USER: 'information_popups/get_by_user/',
      UPDATE_READ: 'information_popups/{0}/update_read/',
    },
    INVOICES: {
      PLANS: 'invoices/plans/',
      PURCHASE_EXCEED: 'invoices/purchase_exceed/{0}',
      LIST: 'invoices/',
      BULK_DOWNLOAD: 'invoices/v2/bulk_download_invoice/',
      RETRY: 'invoices/v2/retry_transaction/',
      UPDATE_STATUS: 'invoices/v2/update_invoice_status/',
      PURCHASE_PLAN: 'invoices/v2/purchase_plan/',
      PURCHASE_PRODUCT: 'invoices/v2/purchase_product/',
      PURCHASE_INVOICE: 'invoices/v2/purchase_invoice/',
      PURCHASE_COURSE: 'invoices/v2/purchase_course/',
      DOWNLOAD: 'invoices/v2/download_invoice_pdf/{0}',
    },
    FILES: {
      LIST: 'files/',
      ADD: 'files/',
      DELETE: 'files/{0}/',
      GET: 'files/{0}/',
      UPDATE: 'files/{0}/',
    },
    ACCOUNTS: {
      LIST: 'accounts/',
      LIST_DELETED: 'accounts/deleted/',
      DETAILS: 'accounts/{0}/',
      DELETE: 'accounts/{0}/',
      CREATE: 'accounts/{0}',
      UPDATE: 'accounts/{0}/',
      RESTORE: 'accounts/restore/{0}/',
      SEND_EMAIL: 'accounts/v2/send_contact_email/',
      ACCEPT_MULTIPLE: 'accounts/accept_multiple/',
      WAITLIST: 'accounts/waitlist/',
    },
    ORGANIZATIONS: {
      LIST: 'organizations/',
      ADD: 'organizations/',
      UPDATE: 'organizations/{0}/',
      GET_BY_ID: 'organizations/{0}/',
      DELETED: 'organizations/deleted/',
      DELETE: 'organizations/{0}/',
      API_INFO: 'organizations/api_info/',
      API_KEY: 'organizations/api_key/',
      UPDATE_KEY: 'organizations/api_key/update/{0}/',
      RESTORE: 'organizations/restore/{0}/',
      GET_PENDING_INVOICE: 'organizations/v2/get_pending_invoices/{0}/',
      PAY_PENDING_INVOICE: 'organizations/v2/pay_pending_invoices/{0}/',
    },
    PRODUCT: {
      PLAN_PURCHASE_LIST: 'products/plan_prices/',
      PRODUCT_PURCHASE_LIST: 'products/v2/available_products/',
      PRODUCT_PRICE: 'products/v2/price/',
      LIST: 'products/',
      DELETE: 'products/{0}/',
      UPDATE: 'products/{0}/',
    },
    CERTIFICATE: {
      POST: {
        GENERATE_CERTIFICATE: 'lms/user-courses/certificate/',
        GENERATE_CERTIFICATES: 'lms/user-courses/certificates/',
      },
    },
    COURSE: {
      LIST: 'lms/courses/',
      USER_CATALOG: 'lms/user-courses/catalog/',
      GET_BY_ID: 'lms/user-courses/',
      UPDATE: 'lms/courses/{0}/',
      ENROLL: 'lms/user-courses/enroll-user/',
      CONSUME: 'lms/user-courses/consume/'
    },
    PLAN: {
      AVAILABLE_PLAN: 'plans/v2/available_plans/',
      PLAN_PRICE: 'plans/v2/price/',
      GROUPS: 'plans/v2/available_groups/',
      ACTIVATED_PLAN: 'plans/v2/activated_plans/',
      LIST_UPGRADE: 'plans/v2/upgrade_plans/',
    },
    FEATURE_STORE: {
      PURCHASED_FEATURE_LIST: 'features/purchases/',
    },
    PAYMENT_METHOD: {
      CURRENT: 'organizations/v2/payment_method/',
      ADD: 'organizations/v2/add_payment_method/',
    },
    REPORTS: {
      REPORTS_LIST: 'reports/'
    },
    EXTERNAL: {
      HUMANGO: {
        METABOLIC_PROFILE: 'external/humango/profile/',
        PURCHASE: {
          STATUS: 'external/humango/purchase/status/'
        }
      }
    },
    BILLING: {
      PAYMENT_SESSION_STATUS: 'billing/payment_session/status/',
      PAYMENT_SESSION: 'billing/payment_session/'
    },
    WAFFLE_FLAGS: 'waffle_status',
    INTEGRATIONS: {
      TEMPLATES: 'integrations/templates/',
      LINKS: {
        LIST: 'integrations/links/',
        UPDATE: 'integrations/links/{0}/',
        CREATE: 'integrations/links/',
        APIS: 'integrations/links/apis/'
      },
    },
  },

  API_TRACKING_STATUS: {
    SUCCESS: 1,
    FAILED: 2,
  },

  INFORMATION_POPUP_TYPE: {
    FIRST: 1,
    SECOND: 2,
  },

  SIMULATE_TYPE: {
    SPEED: 1,
    POWER: 2,
  },
  TOAST_DURATION: {
    SUCCESS: 3000,
    ERROR: 10000,
    WARNING: 6000,
    UNLIMITED: 0,
  },
  TOAST_CONFIG: {
    SUCCESS: { duration: 3000, panelClass: 'success' },
    ERROR: { duration: 10000, panelClass: 'error' },
    WARNING: { duration: 6000, panelClass: 'warning' },
    UNLIMITED: { duration: 0, panelClass: 'unlimited' },
  },
  TRAINING_ZONE_VALIDATION: {
    MAX_OUTPUT: 5,
    MAX_SYSTEM_REF: 3,
    MAX_ZONE: 20,
  },
  REGEX: {
    DECIMAL_2: /^-?\d*[.,]?\d{0,2}$/,
    MIN_SEC: /^[0-5]?\d:[0-5]\d$/,
    NO_WHITESPACE_SPECIAL: /^[a-zA-Z0-9]*$/,
    NO_SPECIAL: /^[A-z0-9 ]*((_-|\s))*$/,
    ONLY_ALPHABETS: /^[a-zA-Z]*$/,
    INTEGER: /^-?\d*$/,
    NUMBER_LETTER_DASH_UNDERSCORE: /^[a-zA-Z0-9_-]*$/,
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  MAP_TRAINING_ZONE_UPPER_LOWER_TARGET: {
    power_watt: {
      name: 'Power',
      unit: '[watt]',
    },
    power_watt_kg: {
      name: 'Power',
      unit: '[watt/kg]',
    },
    speed_m_s: {
      name: 'Speed',
      unit: '[m/s]',
    },
    speed_km_h: {
      name: 'Speed',
      unit: '[km/h]',
    },
    speed_mp_h: {
      name: 'Speed',
      unit: '[mph]',
    },
    pace_min_sec_km: {
      name: 'Pace',
      unit: '[min:sec/km]',
    },
    pace_min_sec_mile: {
      name: 'Pace',
      unit: '[min:sec/mile]',
    },
    pace_min_sec_100m: {
      name: 'Pace',
      unit: '[min:sec/100m]',
    },
    pace_min_sec_500m: {
      name: 'Pace',
      unit: '[min:sec/500m]',
    },
  },
  MAP_TRAINING_ZONE_OUTPUT_KEYS: {
    vo2max: {
      name: 'VO2max',
      unit: '[%]',
    },
    vlamax: {
      name: 'VLamax',
      unit: '[%]',
    },
    lactate_accumulation_rate: {
      name: 'Lactate acc. rate',
      unit: '[mmol/l/min]',
    },
    lactate_clearance_rate: {
      name: 'Lactate cl. rate',
      unit: '[mmol/l/min]',
    },
    lactate_concentration: {
      name: 'Lactate con.',
      unit: '[mmol/l]',
    },
    total_energy: {
      name: 'Total Energy',
      unit: '[kcal/h]',
    },
    fat_percent: {
      name: 'Fat',
      unit: '[%]',
    },
    fat_kcal_h: {
      name: 'Fat',
      unit: '[kcal/h]',
    },
    fat_g_h: {
      name: 'Fat',
      unit: '[g/h]',
    },
    carbohydrates_percent: {
      name: 'Carbohydrates',
      unit: '[%]',
    },
    carbohydrates_kcal_h: {
      name: 'Carbohydrates',
      unit: '[kcal/h]',
    },
    carbohydrates_g_h: {
      name: 'Carbohydrates',
      unit: '[g/h]',
    },
    aerobic_power_kcal_h: {
      name: 'Aerobic Power',
      unit: '[kcal/h]',
    },
    aerobic_power_watt: {
      name: 'Aerobic Power',
      unit: '[Watt]',
    },
    aerobic_power_watt_kg: {
      name: 'Aerobic Power',
      unit: '[Watt/kg]',
    },
    aerobic_power_joule_h: {
      name: 'Aerobic Power',
      unit: '[kJ/h]',
    },
    anaerobic_power_kcal_h: {
      name: 'Anaerobic Power',
      unit: '[kcal/h]',
    },
    anaerobic_power_watt: {
      name: 'Anaerobic Power',
      unit: '[Watt]',
    },
    anaerobic_power_watt_kg: {
      name: 'Anaerobic Power',
      unit: '[Watt/kg]',
    },
    anaerobic_power_joule_h: {
      name: 'Anaerobic Power',
      unit: '[kJ/h]',
    },
    fat_max: {
      name: 'FatMax',
      unit: '[%]',
    },
    anaerobic_threshold: {
      name: 'AT',
      unit: '[%]',
    },
    max_lactate_clearance: {
      name: 'max. Lactate cl.',
      unit: '[%]',
    },
    lactate_production_rate: {
      name: 'Lactate pro rate',
      unit: '[mmol/l/min]',
    },
    energy_contribution_aerobic: {
      name: 'Energy con aerobic',
      unit: '[%]',
    },
    energy_contribution_anaerobic: {
      name: 'Energy con anaerobic',
      unit: '[%]',
    },
    heart_rate: {
      name: 'Heart rate',
      unit: '[bpm]',
    },
    fat_max_power_percent: {
      name: 'FatMax power',
      unit: '[%]',
    },
    lactate_shuttling: {
      name: 'Lactate shuttling',
      unit: '',
    },
    vo2max_power_percent: {
      name: 'VO2max Power',
      unit: '[%]',
    },
    max_heart_rate_percent: {
      name: 'Max Heart Rate',
      unit: '[%]',
    },
    lt1_percent: {
      name: '% LT1',
      unit: '[%]',
    },
  },
  HIDE_TEMPLATE_DROPDOWN_DATE: '2021-10-25',
  METABOLIC_MAPPING_COLOR: {
    vlamax: { first: '#b72237', second: '#821828' },
    vo2max: { first: '#000080', second: '#000066' },
    at: { first: '#808080', second: '#726b7a' },
    fatmax: { first: '#238032', second: '#185922' },
    carbmax: { first: '#ff991d', second: '#ff8428' },
  },
  RECOVERY_MATRIX_DEFAULT_VALUE: {
    POWER: {
      RANGE_MIN: 0.5,
      RANGE_MAX: 0,
      INTERVAL_COUNT: 20,
    },
    SPEED: {
      RANGE_MIN: 0.5,
      RANGE_MAX: 0,
      INTERVAL_COUNT: 20,
    },
    TIME_RANGE_MIN: 0,
    TIME_RANGE_MAX: 25,
    TIME_INTERVAL_COUNT: 25,
    ACCUMULATION_LIMIT: 25,
  },
  TIME_TO_DEPLETION_DIET: {
    HIGH_CARB: 1,
    MEDIUM_CARB: 2,
    LOW_CARB: 3,
  },
  TIME_TO_DEPLETION_COLORS: {
    TTD1: '#4876ff', // RoyalBlue1
    TTD2: '#c1cdcd', // Azure3
    CARBOHYDRATES: 'red',
  },
  ZONE_TABLE_VALUE_TYPE: {
    LOWER: 1,
    UPPER: 2,
    TARGET: 3,
    OUTPUT: 4,
  },
  TRAINING_LEVELS: [
    {
      value: 1,
      name: 'Pro',
    },
    {
      value: 2,
      name: 'Amateur',
    },
    {
      value: 3,
      name: 'Recreational',
    },
  ],
  GENDER: [
    {
      value: 'male',
      name: 'Male',
    },
    {
      value: 'female',
      name: 'Female',
    },
  ],
  ATHLETE_PHOTO_MAX_SIZE: 100 * 1024,
  ORGANIZATION_PHOTO_MAX_SIZE: 100 * 1024,
  WATERMARK_PHOTO_MAX_SIZE: 100 * 1024,
  STRAVA_INFO: {
    AUTH_URL: 'https://www.strava.com/oauth/authorize',
    CLIENT_ID: '107217',
    REDIRECT_URL: '/home/manage/athlete-verify-strava/{0}',
  },
  // STAPS: {
  //   PDF_LINK: environment.wordpressURL + 'wp-json/itac/v1/pdf-link',
  //   PDF_LINK_DOWNLOAD: environment.wordpressURL + 'download?token=',
  //   PURCHASE_LINK: environment.wordpressURL + 'wp-json/itac/v1/purchase',
  //   CHANGE_PAYMENT_METHOD_LINK:
  //     environment.wordpressURL + 'wp-json/itac/v1/change-payment-method',
  //   CAN_PURCHASE: environment.wordpressURL + 'wp-json/itac/v1/can-purchase',
  //   PDK_BULK_DOWNLOAD:
  //     environment.wordpressURL + 'wp-json/itac/v1/pdf-zip-link?file_name=',
  // },
  ATHLETE_SYNC_TYPE: {
    STRAVA: 1,
    GARMIN: 2,
  },

  DISCOUNT_TYPES: [
    {
      id: 1,
      name: '%',
    },
    {
      id: 2,
      name: 'Total',
    },
  ],

  PAYMENT_METHODS: [
    {
      value: 'credit_card',
      name: 'Credit Card',
    },
    {
      value: 'sepa_debit',
      name: 'SEPA Debit',
    },
    {
      value: 'checkmo',
      name: 'On Account',
    },
  ],
  PERIOD_ANALYTICS: [
    {
      id: 1,
      name: 'Annual',
    },
    {
      id: 2,
      name: 'Quarter',
    },
    {
      id: 3,
      name: 'Month',
    },
  ],
  PERIOD_TYPES_ENUM: {
    month: 'Month',
    days: 'Days',
    years: 'Years',
    quarter: 'Quarter',
  },
  TEST_TYPE_CHARS: {
    user: 'user',
    athlete: 'athlete',
    lactate: 'lactate',
    ppd: 'newtype',
    manual: 'manual',
    virtual: 'virtual',
    event: 'event',
    result: 'result',
  },
  ACTIVATION_ITEMS: [
    {
      id: 2,
      name: 'Athlete',
    },
    {
      id: 3,
      name: 'Lactate',
    },
    {
      id: 4,
      name: 'PPD',
    },
    {
      id: 5,
      name: 'Manual',
    },
    {
      id: 6,
      name: 'Virtual',
    },
    {
      id: 7,
      name: 'Event',
    },
    {
      id: 8,
      name: 'Result',
    },
    {
      id: 9,
      name: 'Metabolic Profile',
    },
    {
      id: 10,
      name: 'Reports',
    },
  ],
  ITEMS_INCLUDED: [
    {
      id: 1,
      name: 'User',
    },
    {
      id: 2,
      name: 'Athlete',
    },
    {
      id: 3,
      name: 'Lactate',
    },
    {
      id: 4,
      name: 'PPD',
    },
    {
      id: 5,
      name: 'Manual',
    },
    {
      id: 6,
      name: 'Virtual',
    },
    {
      id: 7,
      name: 'Event',
    },
    {
      id: 8,
      name: 'Result',
    },
  ],
  INCLUDE_ITEM_CONSTANT: {
    USER: {
      id: 1,
      name: 'User',
    },
    ATHLETE: {
      id: 2,
      name: 'Athlete',
    },
    LACTATE: {
      id: 3,
      name: 'Lactate',
    },
    PPD: {
      id: 4,
      name: 'PPD',
    },
    MANUAL: {
      id: 5,
      name: 'Manual',
    },
    VIRTUAL: {
      id: 6,
      name: 'Virtual',
    },
    EVENT: {
      id: 7,
      name: 'Event',
    },
    RESULT: {
      id: 8,
      name: 'Result',
    },
    BUNDLED: {
      id: 9,
      name: 'Bundle',
    },
    LINKED_ITEMS: {
      id: 10,
      name: 'Linked Items',
    },
  },
  PURCHASE_STATUS: {
    GET_START: 0,
    UPGRADE: 1,
    DOWNGRADE: 2,
  },
  INVOICE_STATUS: {
    PENDING: 1,
    PAID: 2,
    UP_DOWN: 3,
    FAILED: 4,
    PROCESSING: 5,
  },
  INVOICE_TYPE: {
    PLAN: 1,
    PRODUCT: 2,
    UP_DOWN: 3,
    COURSE: 4,
  },
  SPORT_SIMULATION_TYPES: [
    {
      id: 1,
      name: 'Speed',
    },
    {
      id: 2,
      name: 'Power',
    },
  ],
  SPORT_PERFORMANCE_DEVELOPMENT: [
    {
      id: 1,
      name: 'no',
    },
    {
      id: 2,
      name: 'Left 1',
    },
    {
      id: 3,
      name: 'Left 2',
    },
    {
      id: 4,
      name: 'Right 1',
    },
    {
      id: 5,
      name: 'Right 2',
    },
  ],
  SPORT_DEFAULT_CONSTANTS: {
    ebg_factor_a: 10,
    ebg_factor_b: 10,
    ebg_factor_c: 0,
    ebg_factor_d: 10,
    min_value: 20,
    max_value: 500,
    o2_per_watt_eq_a: 0,
    o2_per_watt_eq_b: 12.5,
    o2_per_watt_eq_c: 0,
    o2_per_watt_eq_d: 0,
    ebg_conditions: [],
    created_by: null,
  },
  REFERENCE_SYSTEMS_SPEED: [
    {
      id: 3,
      name: 'Speed (m/s)',
      unit: 'm/s',
    },
    {
      id: 4,
      name: 'Speed (km/h)',
      unit: 'km/h',
    },
    {
      id: 5,
      name: 'Speed (mph)',
      unit: 'mph',
    },
    {
      id: 6,
      name: 'Pace (min:sec/km)',
      unit: 'min:sec/km',
    },
    {
      id: 7,
      name: 'Pace (min:sec/mile)',
      unit: 'min:sec/mile',
    },
    {
      id: 8,
      name: 'Pace (min:sec/100m)',
      unit: 'min:sec/100m',
    },
    {
      id: 9,
      name: 'Pace (min:sec/500m)',
      unit: 'min:sec/500m',
    },
  ],
  REFERENCE_SYSTEMS_POWER: [
    {
      id: 1,
      name: 'Power (watt)',
      unit: 'watt',
    },
    {
      id: 2,
      name: 'Power (watt/kg)',
      unit: 'watt/kg',
    },
  ],
  PAYMENT_METHOD_TYPE: {
    CREDIT_CARD: 'card',
    SEPA_DEBIT: 'sepa_debit',
    ON_ACCOUNT: 'checkmo',
  },
  ERROR_MESSAGE: {
    SYSTEM_SETTING_NOT_FOUND: 'System settings not found',
    FORCE_UPDATE_CONSENT: 'FORCE_UPDATE_CONSENT',
  },
  MODEL_TYPES: [
    {
      id: 1,
      name: '1-OxPhos simply by [ADP]^2 activated, glycolysis [ADP]^3 activated',
    },
    {
      id: 2,
      name: '2-Respir ADP + dGOX = const. activated, Glycolysis ADP and AMP(Pi) activated',
    },
    {
      id: 3,
      name: '3-ANT driven ATP/ADP exchangedGOXmit ~ f(3*pmF),glycol: ADP + AMP + Glyc',
    },
  ],
  MODEL_SUBTYPES: [
    { id: 0, name: '0' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
  ],
  MODEL_1_SUBTYPES: [
    { id: 0, name: '0-Without glycogenolysis, glycolysis [ADP]^3 activated' },
    { id: 1, name: '1-With glycogenolysis solely [ADP]^2 activated' },
    { id: 2, name: '2-With glycogenolysis [ADP]^2 and Ca++ activated' },
  ],
  MODEL_2_SUBTYPES: [
    {
      id: 0,
      name: '0-Glycogenolysis solely AMP, glycolysis [ADP][AMP] activated',
    },
    {
      id: 1,
      name: '1-Glycogenolysis AMP + Pi, glycolysis [ADP][AMP] activated',
    },
    {
      id: 2,
      name: '2-Glycogenolysis AMP + Pi and Ca++ activated and glycolysis',
    },
  ],
  MODEL_2_SUBTYPES_2: [
    { id: 1, name: '1-[ADP] + dG(ATP) = constant activated' },
    { id: 2, name: '2-[ADP] + dG(ATP) + effect of muscle pH (pHm)' },
    { id: 3, name: '3-[ADP] + [Pi] = dG(ATP) + effect of muscle pH (pHm)' },
  ],
  MODEL_3_SUBTYPES: [
    {
      id: 0,
      name: '0-Glycogenolysis solely AMP, glycolysis [ADP][AMP] activated',
    },
    {
      id: 1,
      name: '1-Glycogenolysis AMP + Pi, glycolysis [ADP][AMP] activated',
    },
    {
      id: 2,
      name: '2-Glycogenolysis AMP + Pi and Ca++ activated and glycolysis [ADP][AMP] activated',
    },
  ],
  MODEL_3_SUBTYPES_2: [
    { id: 1, name: '1-Version 1 ADP dG( ) and substrate activated' },
    {
      id: 2,
      name: '2-Version 2 calcul. PMF dG(ATP ), and substrate activated',
    },
  ],
  RESULT_DEFAULT_CONSTANTS: {
    description: '',
    flag_forceDGATP: 0,
    example: 2,
    mod_glycPME: 0,
    act_oxyd_phos: 1,
    stepsize: 0.1,
    relerr: 0.00005,
    abserr: 0.00009,
    atp_rest: 6,
    adp_rest: 0.01,
    pcr_rest: 22,
    cr_rest: 4,
    pi_rest: 2,
    amp_rest: 0.00002,
    CLaM_rest: 1.25,
    CLaB_rest: 1.25,
    pH_rest: 7,
    conz_F6MP_rest: 0.2,
    conz_PEP_rest: 0.1,
    vo2_rest: 0.1,
    vent_est_slope: 10.1,
    vent_est_offset: 0.0285,
    vo2_vent_slope: 4.0019,
    vo2_vent_offset: 0.0049,
    base_pmf: -0.12,
    dGFakt_Mod1: 0.085,
    dGFakt_Mod2: 0.085,
    dGFakt: 0.065,
    DGOX: 72,
    evo2: 2,
    fakt_vssPME: 1.5,
    free_CArest: 5e-8,
    joule_pcreq: 19.4448,
    kh_F6MP: 1.75,
    konv_lapcr: 1.35,
    pcr_mlo2: 4.051,
    konv_o2pcr: 0.2468,
    ks_F6MPADP: 0.00345,
    ks_F6MPadpCA: 0.06523,
    ks_F6MPamp: 0.000425,
    ks_F6MPampCOOP: 0.00075,
    ks_F6MPCa: 2.5e-7,
    ks_lapH: 4.5e-21,
    ks_oxpi: 1.5,
    ks_pfkF6MP: 0.025,
    ks_piPME: 2.05,
    ks_vlaresADP: 0.0025,
    ks_vlaresCLa: 5,
    ks_vo2_Mod1: 0.03,
    ks_vo2: 0.00135,
    ks_vo2ADP: 0.00135,
    ks_vPMEres: 0.000035,
    ks2_laADP: 0.0045,
    ks2_laAMP: 0.000125,
    ks2_pi: 1.05,
    ks2_piPME: 3.05,
    laexp_pi: 1.528,
    m2: 1660000000,
    m3: 1.085,
    pH_mit: 7.6,
    phy_const: 0.4805,
    reduce_alpha1mV: 0.666,
    vlaresmax: 0.00935,
    vpme_resmax: 0.01285,
    zk_vo2: 0.0666666667,
    zk_vo2mod3: 1.33333333333,
    vo2max_const: 3.2,
    force_kjlow1: 41.8,
    force_kjlow0: 40.5,
    temp: 310,
    slyke: 45,
    slyke1: 0.022222,
    kel_ox: 2.5,
    vo2_passive_tissue: 2,
  },
  SELECTION_GRAPH_CONSTANTS: {
    rolling_average_unit_secs_vo: 30,
    rolling_average_and_value_diff_permit_percent_vo: 30,
    maximum_spike_width_secs_vo: 30,
    maximum_spike_count_vo: 30,
    minimum_plateau_snippet_length_percent_vo: 5,
    heartrate_limitation_vo: 20,
    rolling_average_diff_trend_limitation_vo: 40,
    trend_maintain_time_limitation_vo: 60,
    rolling_average_unit_secs_at: 30,
    rolling_average_and_value_diff_permit_percent_at: 30,
    maximum_spike_width_secs_at: 30,
    maximum_spike_count_at: 30,
    minimum_plateau_snippet_length_percent_at: 5,
    heartrate_limitation_at: 20,
    rolling_average_diff_trend_limitation_at: 40,
    trend_maintain_time_limitation_at: 60,
    using_spike_width_flag: 1,
    min_max_exclude_spike_flag: 1,
    limit_power_prior_against_bodyweight_vl: 3,
  },

  VALIDATION_PATTERNS: {
    REQUIRED: 'required',
    FLOAT: 'float',
    FLOAT_NO_REQUIRED: 'float-no-required',
    FLOAT_RANGE: 'float-range',
    FLOAT_RANGE_NO_REQUIRED: 'float-range-no-required',
    POSITIVE_NUMBER_NO_REQUIRED: 'positive-number-no-required',
    INTEGER_RANGE: 'integer-range',
    EMAIL: 'email',
    ARRAY: 'array',
    LARGER_OR_EQUAL_ZERO: '>=0',
    LARGER_THAN_ZERO: '>0',
    LARGER_THAN_ZERO_NO_REQUIRED: '>0 no-required',
    FLOAT_A_LARGER_THAN_B: 'A > B',
    ONE_REQUIRE_ALL: 'one-require-all'
  },
  HELP_CENTER: {
    URL: "https://helpcenter.inscyd.com"
  },
  GOOGLE_TAG_MANAGER: {
    EVENTS: {
      UPDATE_USER_PROFILE: 'userProfileUpdate',
    },
  },
  FEATURES: {
    TRIAL_PERIOD_DAYS: 30,
  },
  LINKED_SPORT_PERFORMANCE_LIMITS: [
    "anaerobic_threshold",
    "carb_max",
    "lt_1",
    "MMSS",
    "max_power",
    "avg_power"
  ],
  VISIBLE_API_INFO_ALIASES: ["share-data", "training-planner", "get-athlete-test-v2"],
};
