import { Injectable } from "@angular/core";
import { AppConstants } from "@core/constants";
import { ApiService } from "@core/services/api.service";
import { FeaturePurchaseInfoInterface } from "@shared/interfaces/feature-purchase-info.interface";
import { BehaviorSubject, Observable, Subject, delay, distinctUntilChanged, finalize } from "rxjs";

import { String } from 'typescript-string-operations';

@Injectable()
export class PaymentSummaryDialogState {
  private readonly _loading = new BehaviorSubject<boolean>(false);
  private readonly _purchaseInfo = new Subject<FeaturePurchaseInfoInterface>();

  public loading$ = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());
  public purchaseInfo$ = this._purchaseInfo.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  /** Loads the payment info into the local state variable */
  public loadPaymentInfo(feature_id: number, showLoading = true): void {
    this.loadingStart(showLoading);
    const url = String.Format(AppConstants.API.FEATURE_PURCHASE.INFO, feature_id);
    this.apiService.get(url)
      .pipe(finalize(() => this.loadingEnd(showLoading)))
      .subscribe({
        next: (paymentInfo: FeaturePurchaseInfoInterface) => this._purchaseInfo.next(paymentInfo),
        error: (error: any) => console.error(`Unable to fetch the feature's payment information status: ${error}`)
      });
  }

  /** Reloads the payment info with set dependencies assigned */
  public reloadPaymentInfo(feature_id: number, selected_set_dependencies: Array<number>, showLoading = true): void {
    this.loadingStart(showLoading);
    const url = String.Format(AppConstants.API.FEATURE_PURCHASE.INFO, feature_id);
    this.apiService.post(url, { selected_set_dependencies })
      .pipe(finalize(() => this.loadingEnd(showLoading)))
      .subscribe({
        next: paymentInfo => this._purchaseInfo.next(paymentInfo as FeaturePurchaseInfoInterface),
        error: (error: any) => console.error(`Unable to update the feature's payment information status: ${error}`)
      });
  }

  private loadingStart = (showLoading: boolean): void => showLoading ? this._loading.next(true) : undefined;

  private loadingEnd = (showLoading: boolean): void => showLoading ? this._loading.next(false) : undefined;
}