import { Observable, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Injectable } from '@angular/core';

interface Event {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  protected _eventsSubject: Subject<Event> = new Subject<Event>();

  public BroadcastEvent(key: string, value: any): void {
    this._eventsSubject.next({ key, value });
  }

  public GetEvent(key: string): Observable<any> {
    return this._eventsSubject.asObservable()
      .pipe(
        filter((e: Event): boolean => e.key === key),
        map((e: Event) => e.value)
      );
  }
}
