import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component( {
  selector   : 'app-confirm-dialog',
  templateUrl: './confirmation-dialog.html'
} )
export class ConfirmationDialogComponent {
  public confirmMessage: string | undefined;

  constructor( public dialogRef: MatDialogRef<ConfirmationDialogComponent> ) {}
}
