import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { String } from 'typescript-string-operations';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { DeviceManagementDto, DeviceManagementResultDto } from './device-management-dto';
import { ApiRequest } from "@shared/models/api-request";


@Injectable({
  providedIn: 'root',
})
export class MyDevicesService extends ServiceBase {
  public list( request: PagedAndSortedRequestDto ): Observable<DeviceManagementResultDto> {
    const apiUrl: string = this._appConstants.API.MY_DEVICES.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new DeviceManagementResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: DeviceManagementDto) =>
            new DeviceManagementDto().deserialize(item)
          ),
        });
      })
    );
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(
      this._appConstants.API.MY_DEVICES.DELETE, id);
    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }

}
