<div class="page-container">
  <span class="u-display-block u-margin-bottom-30 u-font-weight-400 general-info--font-size-35">Integrations</span>
  <ng-container *ngIf="integrationObject?.key; else noApiKey">
    <app-integration-item [config]="integrationObject"></app-integration-item>
  </ng-container>
  <ng-template #noApiKey>
    <div class="u-text-align-center">
      There is no API Key
    </div>
  </ng-template>

</div>
