import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatIconRegistryService } from "@shared/services/mat-icon-registry/mat-icon-registry.service";
import { MyProfileService } from "@shared/services/my-profile/my-profile.service";
import { IProfileItem } from "@shared/interfaces/profile-item.interface";
import { IMatIconRegister } from "@shared/interfaces/mat-icon-register.interface";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private matIconRegistryService: MatIconRegistryService = inject(MatIconRegistryService);
  private router: Router = inject(Router);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private myProfileService: MyProfileService = inject(MyProfileService);

  public ngOnInit(): void {
    this.addHandlerScrollToTop();
    this.registerCustomSvgIcons();
  }

  private addHandlerScrollToTop(): void {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event): void => {
        if (event instanceof NavigationEnd) {
          const sideNavContent: Element | null = document.querySelector('mat-sidenav-content');

          if (sideNavContent) {
            sideNavContent.scrollTop = 0;
          }
        }
      });
  }

  private registerCustomSvgIcons(): void {
    let matIconList: IMatIconRegister[] = this.myProfileService.getProfileItemList().map((matIcon: IProfileItem): IMatIconRegister => {
      return {
        iconUrl: matIcon.iconUrl,
        iconLabel: matIcon.iconLabel,
      };
    });

    matIconList.forEach((matIcon: IMatIconRegister): void => {
      this.matIconRegistryService.registerCustomMatIcon(matIcon.iconLabel, matIcon.iconUrl);
    });
  }
}
