import { Component, inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-pd-multi-select-dialog',
  templateUrl: './pd-multi-select-dialog.component.html',
  styleUrls: ['./pd-multi-select-dialog.component.scss']
})
export class PdMultiSelectDialogComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);
  private matDialogRef: MatDialogRef<PdMultiSelectDialogComponent> = inject(MatDialogRef);

  public form: FormGroup;
  public data: any = inject(MAT_DIALOG_DATA);

  public ngOnInit(): void {
    this.initForm(this.data);
  }

  public initForm(data: any): void {
    const mappedCurrentList: any[] = data.dataPointList.map((item: any) => ({
      value: item.value,
      name: item.name,
      slug: item.slug,
      datapoint: item.datapoint,
    }));

    this.form = this.fb.group({
      list: [mappedCurrentList]
    });
  }

  public onChanged(event: any): void {
    this.form.get('list')?.setValue(event);
  }

  public get dataPointListControl(): any {
    return this.form.get('list');
  }

  public get dataPointListValue(): any {
    return this.form.get('list')?.value;
  }

  public get optionList(): any {
    return this.data.optionList;
  }

  public close(): void {
    this.matDialogRef.close(false);
  }

  public save(): void {
    this.matDialogRef.close(this.dataPointListValue);
  }
}
