<h4 mat-dialog-title>{{modalWindowTitle}}</h4>
<h2 style="text-align: center">Ramp Test</h2>
<span class="ramp-test--red-color ramp-test--display-block ramp-test--mb-15">{{wattForMale}}{{hintForMale}}</span>
<mat-dialog-content>
  <div [formGroup]="form" class="ramp-test">
    <div class="ramp-test__row">
      <p style="width: inherit">Average Power last 30s</p>
      <mat-form-field appearance="outline" floatLabel="always" class="ramp-test__field">
        <mat-label>Average Power [W]</mat-label>
        <input
          matInput
          type="number"
          min="0"
          formControlName="average_power"
        />
        <mat-error
          *ngIf="form.get('average_power')?.errors && form.get('average_power')?.errors?.required">The average power is required
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">OK</button>
  </div>
</mat-dialog-actions>
