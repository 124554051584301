<app-loader-indicator></app-loader-indicator>
<div class="u-screen-height plan-payment-page" *ngIf="this.paymentSessionStatus$ | async as paymentSessionStatus; else loadingStatus">
  <div *ngIf="paymentSessionStatus.status === 'pending'; else invalidStatus">
    <div class="page">
      <!-- Plan details column -->
      <div class="page__plan">
        <div class="plan">
          <mat-card class="plan__card" *ngIf="paymentSession" appearance="outlined">
            <mat-card-title>
              {{ paymentSession.plan.name }}
            </mat-card-title>
            <mat-card-subtitle>
              {{ paymentSession.plan.description }}
            </mat-card-subtitle>
            <mat-card-content>
              <mat-divider></mat-divider>
              <div class="plan__features">
                <h2>Features</h2>
                <mat-list>
                  <mat-list-item class="plan__feature" *ngFor="let feature of paymentSession?.plan?.features">
                    {{feature}}
                  </mat-list-item>
                </mat-list>
              </div>
              <mat-divider></mat-divider>
              <div class="plan__contract">
                <h2>Contract information</h2>
                <mat-tab-group *ngIf="hasBillingPeriodDetails()">
                  <mat-tab *ngFor="let detail of getBillingPeriodDetails()" label="{{getRenewalPeriodText(detail.period_type)}}">
                    <div class="contract">
                      <div class="contract__field">
                        <div class="contract__key">
                          Renewal period
                        </div>
                        <div class="contract__value">
                          {{getRenewalPeriodText(detail.period_type)}}
                        </div>
                      </div>
                      <div *ngIf="getNextRenewalDate()" class="contract__field">
                        <div class="contract__key">
                          Next renewal date
                        </div>
                        <div class="contract__value--small">
                          {{getNextRenewalDate(detail.period_type)}}
                        </div>
                      </div>
                      <div class="contract__field">
                        <div class="contract__key">
                          Minimum contract duration
                        </div>
                        <div class="contract__value">
                          {{getMinimumContractDuration(detail.period_type)}} months
                        </div>
                      </div>
                      <div *ngIf="getEarliestCancellationDate()" class="contract__field">
                        <div class="contract__key">
                          Earliest cancellation date
                        </div>
                        <div class="contract__value--small">
                          {{getEarliestCancellationDate(detail.period_type)}}
                        </div>
                      </div>
                      <div class="contract__field">
                        <div class="contract__key">
                          Cancellation notice time
                        </div>
                        <div class="contract__value">
                          {{getCancellationNoticeTime(detail.period_type)}} days
                        </div>
                      </div>
                      <div *ngIf="getLatestCancellationNotice()" class="contract__field">
                        <div class="contract__key">
                          Latest cancellation notice
                        </div>
                        <div class="contract__value--small">
                          {{getLatestCancellationNotice(detail.period_type)}}
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
                <div *ngIf="!hasBillingPeriodDetails()" class="contract">
                  <div *ngIf="getRenewalPeriodText()" class="contract__field">
                    <div class="contract__key">
                      Renewal period
                    </div>
                    <div class="contract__value">
                      {{getRenewalPeriodText()}}
                    </div>
                  </div>
                  <div *ngIf="getNextRenewalDate()" class="contract__field">
                    <div class="contract__key">
                      Next renewal date
                    </div>
                    <div class="contract__value--small">
                      {{getNextRenewalDate()}}
                    </div>
                  </div>
                  <div *ngIf="getMinimumContractDuration()" class="contract__field">
                    <div class="contract__key">
                      Minimum contract duration
                    </div>
                    <div class="contract__value">
                      {{getMinimumContractDuration()}} months
                    </div>
                  </div>
                  <div *ngIf="getEarliestCancellationDate()" class="contract__field">
                    <div class="contract__key">
                      Earliest cancellation date
                    </div>
                    <div class="contract__value--small">
                      {{getEarliestCancellationDate()}}
                    </div>
                  </div>
                  <div *ngIf="getCancellationNoticeTime()" class="contract__field">
                    <div class="contract__key">
                      Cancellation notice time
                    </div>
                    <div class="contract__value">
                      {{getCancellationNoticeTime()}} days
                    </div>
                  </div>
                  <div *ngIf="getLatestCancellationNotice()" class="contract__field">
                    <div class="contract__key">
                      Latest cancellation notice
                    </div>
                    <div class="contract__value--small">
                      {{getLatestCancellationNotice()}}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="this.countryControl.value" class="plan__pricing">
                <h2>Pricing information</h2>
                <div class="o-flex o-flex--wrap o-flex--full o-flex--justify-space-around plan__pricing__wrapper">
                  <div *ngFor="let planPrice of planPricing()" class="o-flex o-flex--column plan__price">
                    <div class="plan__price__price">{{planPrice.currency}} {{planPrice.month_price}}</div>
                    <div class="plan__price__period">
                      billed {{planPrice.period}}
                      <span *ngIf="planPrice.period !== 'monthly'">*</span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <figure class="plan__logo">
          <img alt="INSCYD Logo" src="../../../../assets/images/app-logo-header-dark-theme.png"/>
        </figure>
      </div>
      <!-- Registration and purchase column -->
      <div class="page__register">
        <div class="register">
          <h2>Get started with your new subscription</h2>
          <mat-stepper orientation="vertical" [linear]="true" #stepper>
            <mat-step label="Set up your account" [stepControl]="registrationForm" [editable]="step1Editable">
              <div class="o-flex--full">
                <form class="o-flex o-flex--column u-margin-bottom-m" [formGroup]="registrationForm">
                  <mat-form-field floatLabel="always" class="mat-input--flush" appearance="outline">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="first_name" required/>
                  </mat-form-field>
                  <mat-form-field class="mat-input--flush" appearance="outline">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="last_name" required/>
                  </mat-form-field>
                  <mat-form-field class="mat-input--flush" appearance="outline">
                    <mat-label>Email address</mat-label>
                    <input matInput formControlName="email" required/>
                  </mat-form-field>
                  <mat-form-field class="u-1/1 mat-input--flush" appearance="outline">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" required>
                      <mat-option *ngFor="let country of countries" [value]="country.id">
                        {{country.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="showBillingPeriod()" class="u-1/1 mat-input--flush" appearance="outline">
                    <mat-label>Billing period</mat-label>
                    <mat-select formControlName="period" required>
                      <mat-option *ngFor="let period of periods" [value]="period.id">
                        {{period.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="consent-accepted-checkbox">
                    <label>
                      <mat-checkbox color="primary" formControlName="consent_accepted" name="documents" data-cons-preference="legal_documents"></mat-checkbox>
                      <span class="privacy-terms-text">&nbsp;I've read the <a class="hyper-link-color" href="#" target="_blank" [routerLink]="['/privacy-policy']">Privacy Policy</a> and accept the <a class="hyper-link-color" href="#" target="_blank" [routerLink]="['/terms']">Terms and Conditions</a></span>
                    </label>
                  </div>
                </form>
              </div>
              <div>
                <button mat-button matStepperNext>Next</button>
              </div>
            </mat-step>
            <mat-step label="Configure your purchase" [stepControl]="registrationForm" [editable]="step2Editable">
              <div class="o-flex--full">
                <form class="o-flex o-flex--column u-margin-bottom-m" [formGroup]="registrationForm">
                  <app-chips
                    *ngIf="getTestTypeOptions().length > 0"
                    [label]="'Test types'"
                    [form]="registrationForm.get('test_types')"
                    [options]="getTestTypeOptions()"
                    [displayField]="'name'"
                    (optionChange)="onSelectTestType($event)"
                  ></app-chips>
                  <app-chips
                    [label]="'Sports'"
                    [form]="registrationForm.get('sports')"
                    [options]="sportOptions"
                    [displayField]="'name'"
                    (optionChange)="onSelectSport($event)"
                  ></app-chips>
                </form>
              </div>
              <div>
                <button mat-button class="primary" type="button" (click)="registerUser()" [disabled]="!registrationForm.valid">Sign up</button>
              </div>
            </mat-step>
            <mat-step label="Complete your payment" [editable]="false">
              <div class="o-flex--full">
                <div *ngIf="clientSecret" class="client-secret">
                  <ngx-stripe-elements
                    [stripe]="stripe"
                    [elementsOptions]="elementsOptions"
                  >
                    <ngx-stripe-payment />
                  </ngx-stripe-elements>
                </div>
              </div>
              <br />
              <div>
                <button mat-button class="primary" type="button" (click)="handlePayment()">Complete purchase</button>
              </div>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Login</ng-template>
            </mat-step>
          </mat-stepper>
          <div class="document terms">
            <div class="document__content" [innerHtml]="terms$ | async">
              Terms and coditions
            </div>
            <div class="document__link">
              <a href="{{termsLink}}" target="_blank">Open in new tab<mat-icon class="icon-display">open_in_new</mat-icon></a>
            </div>
          </div>
          <div class="document privacy-policy">
            <div class="document__content" [innerHtml]="privacyPolicy$ | async">
              Privacy policy
            </div>
            <div class="document__link">
              <a href="{{privacyPolicyLink}}" target="_blank">Open in new tab<mat-icon class="icon-display">open_in_new</mat-icon></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #invalidStatus>
    <div class="full-page-message">
      <mat-card>
        <mat-card-content class="message-box">
          <h1>{{getStatusMessage(paymentSessionStatus.status).title}}</h1>
          <div>{{getStatusMessage(paymentSessionStatus.status).message}}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-template>
</div>
<ng-template #loadingStatus>
  <div class="full-page-message">
    <mat-card>
      <mat-card-content class="message-box">
        <h1>Loading</h1>
        <div>Fetching payment details for this link</div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
