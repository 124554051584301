<mat-radio-group class="o-flex" [(ngModel)]="selectedItem">
  <mat-radio-button class="economy-efficient-settings--style economy-efficient-settings--margin-right background-color" color="primary" (change)="onUpdateSelection(item)" *ngFor="let item of availableEconomyEfficientSettingsList" [value]="item">{{item.name}}</mat-radio-button>
</mat-radio-group>

<div *ngIf="economyEfficientSettingsForm" class="u-margin-top-20" [formGroup]="economyEfficientSettingsForm">
  <span class="u-font-size-14 u-font-weight-700 line-height-16 u-display-block u-margin-bottom-8">Create custom</span>
  <span class="u-font-size-14 u-font-weight-400 line-height-16 economy-efficient-settings--color u-display-block">Enter custom values</span>
  <hr>
  <br>
  <div class="o-flex o-flex--justify-space-between">
    <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
      <mat-label>Unit Value</mat-label>
      <input matInput placeholder="Enter unit value" (input)="onUpdateUnitValueType()" formControlName="unit_value"/>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" class="width-49-percent">
      <mat-label>Select Unit</mat-label>
      <mat-select formControlName="unit_type">
        <mat-option (click)="onUpdateUnitValueType()" [value]="item.id" *ngFor="let item of referenceOptionsList">{{ item.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="economyEfficientSettingsForm.controls?.unit_type?.errors && economyEfficientSettingsForm.controls?.unit_type?.touched">
        <div [innerHTML]="economyEfficientSettingsForm.controls.unit_type.errors | errorFormFormat: { fieldName: 'Unit type' }"></div>
      </mat-error>
    </mat-form-field>
  </div>
</div>

