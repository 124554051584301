<table style="border-collapse: collapse">
  <tr>
    <td rowspan="2" style="padding: 0px">
      <div id="plot{{ id }}" #plots></div>
      <br />
    </td>
    <td valign="top">
      <img *ngIf="!isDark" class="ppd-legend" src="../../../../assets/images/PPD_legend.png" />
      <img *ngIf="isDark" class="ppd-legend" src="../../../../assets/images/PPD_dark_legend.png" />
    </td>
    <td>
      <div #maps class="maps" id="map{{ id }}"></div>
    </td>
  </tr>
  <tr>
    <td></td>
    <td>
      <div id="mean_values_table_container">
        <table class="mean_values" style="width: 100%">
          <tr class="mean_values_hdr">
            <th class="dark">Marker #</th>
            <th class="dark">{{ selectionStartTime }}</th>
            <th class="dark">to</th>
            <th class="dark">{{ selectionEndTime }}</th>
          </tr>
          <tr class="mean_values_odd">
            <td class="light">Power</td>
            <td class="light">{{ averageSelectedPower }} W</td>
            <td class="light">Cadence</td>
            <td class="light">{{ averageSelectedCadence }} min<sup>-1</sup></td>
          </tr>
          <tr class="mean_values_even">
            <td class="medium">Heart rate</td>
            <td class="medium">
              {{ averageSelectedHeartRate }} min<sup>-1</sup>
            </td>
            <td class="medium">Speed</td>
            <td class="medium">{{ averageSelectedSpeed }} km/h</td>
          </tr>
          <tr class="mean_values_odd">
            <td class="light">Energy</td>
            <td class="light">{{ averageSelectedEnergy }} kJ</td>
            <td class="light">Distance</td>
            <td class="light">{{ averageSelectedDistance }} km</td>
          </tr>
        </table>
      </div>
    </td>
  </tr>
</table>
