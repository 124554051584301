<div class="description-text__section">
  <app-custom-back-button *ngIf="tabIndexToReturn" [customUrl]="customUrl"></app-custom-back-button>
  <div *ngIf="tabIndexToReturn">
    <div class="u-font-weight-bold u-font-size-xsmall u-margin-bottom-8">ADMINISTRATION</div>
    <div class="general-info--font-size-35 line-height-28 u-font-weight-400 u-margin-bottom-20">Custom descriptive texts for PDF reports</div>
    <div class="u-font-weight-500 u-font-size-16 line-height-20 u-margin-bottom-20">create your own description for PDF reports - select default or your own texts when you create a PDF</div>
  </div>
  <div class="description-text__form" [formGroup]="form">
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.INTRODUCTION_TEXT}}</mat-label>
        <textarea matInput formControlName="introduction" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.BODY_COMPOSITION}}</mat-label>
        <textarea matInput formControlName="body_composition" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.PHYSIOLOGICAL_PERFORMANCE}}</mat-label>
        <textarea matInput formControlName="metabolic_capacities" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.LOAD_CHARACTERISTICS}}</mat-label>
        <textarea matInput formControlName="metabolic_profile" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.METABOLIC_FINGERPRINT}}</mat-label>
        <textarea matInput formControlName="metabolic_fingerprint" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.PERFORMANCE_DEVELOPMENT}}</mat-label>
        <textarea matInput formControlName="performance_development" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.TRAINING_ZONES}}</mat-label>
        <textarea matInput formControlName="training_zones" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.TEST_DATA_GRAPHS}}</mat-label>
        <textarea matInput formControlName="test_data" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.ECONOMY}}</mat-label>
        <textarea matInput formControlName="economy" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.TEST_DATA_TABLES}}</mat-label>
        <textarea matInput formControlName="test_data_tables" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.HEART_RATE}}</mat-label>
        <textarea matInput formControlName="heart_rate" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="description-text__field">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>{{constants.WORDING.METABOLIC_POWER}}</mat-label>
        <textarea matInput formControlName="metabolic_power" rows="5"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="description-text__footer width-100-percent o-flex o-flex--justify-space-around">
    <button *ngIf="tabIndexToReturn" color="primary" class="width-49-percent border-color" mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button class="width-49-percent" color="primary" (click)="save()">Save</button>
  </div>
</div>
