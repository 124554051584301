<form
  autocomplete="off"
  [formGroup]="informationForm"
  (ngSubmit)="save()"
  class="user-dialog__section dialog-add-detail-form">
  <h2 mat-dialog-title>{{ "Add Information Popup" }}</h2>
  <mat-dialog-content>
    <fieldset>
      <legend>{{ "Information Popup" }}</legend>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Name (*)</mat-label>
          <input matInput formControlName="name" />
          <mat-error
            *ngIf="
            informationForm.controls?.name?.errors &&
            informationForm.controls?.name?.touched">
            <div [innerHTML]="informationForm.controls.name.errors| errorFormFormat: { fieldName: 'Name' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Title (*)</mat-label>
          <input matInput formControlName="title" />
          <mat-error
            *ngIf="
            informationForm.controls?.title?.errors &&
            informationForm.controls?.title?.touched
            ">
            <div
              [innerHTML]="
              informationForm.controls.title.errors
                  | errorFormFormat: { fieldName: 'Title' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Text content (*)</mat-label>
          <textarea rows="10" matInput formControlName="text_content"></textarea>
          <mat-error
            *ngIf="
            informationForm.controls?.text_content?.errors &&
            informationForm.controls?.text_content?.touched
            ">
            <div
              [innerHTML]="
              informationForm.controls.text_content.errors
                  | errorFormFormat: { fieldName: 'Text content' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>Type (*)</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type.value">{{type.viewValue}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
            informationForm.controls?.type?.errors &&
            informationForm.controls?.type?.touched
            ">
            <div
              [innerHTML]="
              informationForm.controls.type.errors
                  | errorFormFormat: { fieldName: 'Type' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-form-field class="full-width" appearance="outline" floatLabel="always">
          <mat-label>Expired date (*)</mat-label>
            <input
               [matDatepicker]="picker"
               matInput
               formControlName="expiration"/>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            *ngIf="
            informationForm.controls.expiration.errors &&
            informationForm.controls.expiration?.touched
            ">
            <div
              [innerHTML]="
              informationForm.controls.expiration.errors
                  | errorFormFormat: { fieldName: 'Date' }
              "></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field">
        <mat-slide-toggle class="full-width" formControlName="is_active">Active</mat-slide-toggle>
      </div>
      <div class="field field-toggle">
        <mat-slide-toggle class="full-width" formControlName="is_all_organizations">All organizations</mat-slide-toggle>
      </div>
      <br><br>
      <div class="field" *ngIf="!informationForm?.value?.is_all_organizations">
        <app-chips
          [label]="'Organizations'"
          [form]="informationForm.get('organizations')"
          [options]="organizationOptions"
          [displayField]="'name'"
          (optionChange)="onSelectOrganization($event)"
        ></app-chips>
      </div>
    </fieldset>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button mat-raised-button (click)="closeDialog()" type="button">
        Cancel
      </button>
      <button mat-raised-button color="primary" type="submit">Save</button>
    </div>
  </mat-dialog-actions>
</form>

