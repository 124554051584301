import { Injectable } from "@angular/core";
import { ApiBaseService } from "@core/services/api-base.service";
import { PlanActivatedDto } from "@core/services/plan-purchase/plan-dto";
import { PlanPurchaseService } from "@core/services/plan-purchase/plan-purchase.service";
import { BehaviorSubject, delay, distinctUntilChanged } from "rxjs";

@Injectable()
export class IntegrationVQVComponentState {
  private readonly _loading = new BehaviorSubject<boolean>(false);
  private readonly _purchases = new BehaviorSubject<PlanActivatedDto[]>([]);

  public loading$ = this._loading.asObservable().pipe(delay(0), distinctUntilChanged());
  public purchases$ = this._purchases.asObservable();

  constructor(
    private readonly planPurchaseService: PlanPurchaseService,
  ) { }

  public loadState(): void {
    this._loading.next(true);

    this.planPurchaseService.getListActivatedPlan().subscribe({
      next: (purchases) => {
        this._loading.next(false);
        this._purchases.next(purchases);
      },
      error: (err) => {
        this._loading.next(false);
        console.error(err);
      }
    });
  }
}