import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DOCUMENT } from "@angular/common";
import { IPlaceholderComponent } from "../models";
import { ReportBuildingBlocks, ReportTemplateGraphs, ReportTemplateOther, ReportTemplateTables } from "../../../apc/apc/data/chart-modules";
import { HelperService } from "@shared/services/helper.service";
import { KtdGridLayout, KtdResizeEnd, KtdResizeStart, ktdTrackById } from "@katoid/angular-grid-layout";
import { KtdGridLayoutItem } from "@katoid/angular-grid-layout/lib/grid.definitions";
import { Subject } from "rxjs";
import { GridMeta } from "@shared/models/reportTemplates";
import { ReportTemplate, ReportTemplateService } from "@core/services/report-template/report-template.service";
import { AppBaseComponent } from "@shared/components/app-component-base";
import { AppConstants } from "@core/constants";
import { TextEditorDialogComponent } from "@shared/dialogs/text-editor-dialog/text-editor-dialog.component";

interface DialogData {
  reportTemplate: ReportTemplate,
  isCopy: boolean,
  isEdit: boolean
}


@Component({
  selector: 'app-template-builder',
  templateUrl: './template-report-builder.component.html',
  styleUrls: ['./template-report-builder.component.scss'],
})
export class TemplateReportBuilderComponent extends AppBaseComponent implements OnInit, OnDestroy {
  @ViewChild('reportGrid') public reportGridElement: ElementRef<HTMLElement> | undefined;

  public auth: any;
  public selectedComponents: Array<IPlaceholderComponent> = [];
  public templateId: number | undefined;
  public templateName: string = '';
  public templateDescription: string = '';
  public templateActive: boolean = false;
  public isPublic: boolean | undefined = false;
  public templateDeleted: boolean = false;
  public templateOrganization: number;
  public buildingBlocks = ReportBuildingBlocks;
  public selection: Array<string> = [];
  public modelGraphs: Array<IPlaceholderComponent> = ReportTemplateGraphs;
  public modelTables: Array<IPlaceholderComponent> = ReportTemplateTables;
  public modelOther: Array<IPlaceholderComponent> = ReportTemplateOther;
  public selectedGrapComponents: Array<IPlaceholderComponent> = [];
  public selectedTableComponents: Array<IPlaceholderComponent> = [];
  public selectedOtherComponents: Array<IPlaceholderComponent> = [];
  public saveDisabled: boolean = true;
  public imagePath: string = '../../../../assets/images/pdf-components/';
  public trackById = ktdTrackById;
  private scaleFactor: number = 0.5;

  /**
   * These calculations are used to determine the background-grid sizing in _components.report-grid.scss
   * The scaleFactor of 0.5 above is used in all the relevant calculations.
   *
   * Currently background-size in the scss is set to: 36px 5px;
   *
   * The 36px comes from the width of each column:
   * pageWidth = 1080 * 0.5 = 540px
   * cols = 30 * 0.5 = 15 columns
   * widthPerColumn = 540px / 15 = 36px per column
   *
   * The 5px is just the row height:
   * rowHeight = 10 * 0.5 = 5px;
   */
  public pageWidth: number = 1080 * this.scaleFactor;
  public pageHeight: number = 1400 * this.scaleFactor;
  public cols: number = 30 * this.scaleFactor;
  public rowHeight: number = 10 * this.scaleFactor;
  public compactType: 'vertical' | 'horizontal' | null = null;
  public grid: KtdGridLayout = [];
  public gridMeta: Map<string, GridMeta> = new Map<string, GridMeta>();
  private _pageBreaks: number = 0;
  public gridPageBreaks: Array<number> = [];
  public pageBreakPositionList: any = [];
  private destroyer$: Subject<boolean> = new Subject<boolean>();
  private _startDimensions = {w: 0, h: 0};
  public gridReady: boolean = true;
  public GENERAL: string = AppConstants.TEXT_EDITOR_VIEW_TYPE.GENERAL;

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<TemplateReportBuilderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              @Inject(DOCUMENT) public document: Document,
              private readonly helperService: HelperService,
              private readonly reportTemplateService: ReportTemplateService,) {
    super();
  }

  public ngOnInit(): void {
    this.auth = this.sessionService.auth;
    if (this.data.isEdit || this.data.isCopy) {
      this.gridReady = false;
      const {id, name, description, fields, is_active, is_delete, organization, is_public} = this.data.reportTemplate;
      this.templateName = this.data.isCopy ? name + ' Copy' : name;
      this.templateDescription = description;
      this.templateActive = is_active;
      this.isPublic = is_public;
      this.templateDeleted = is_delete;
      this.templateId = id;
      this.templateOrganization = organization;
      const grid: KtdGridLayout = [];

      for (let field of fields) {
        const itemSize: {w: number, h: number } = this._getItemSize(field.type);
        if (this.modelGraphs.some((item: IPlaceholderComponent) => item.id === field.id)) {
          const foundItem: IPlaceholderComponent = this.modelGraphs.find((item: IPlaceholderComponent) => item.id === field.id)!;
          this.selectedGrapComponents = [
            ...this.selectedGrapComponents,
            {
              id: foundItem.id,
              name: foundItem.name,
              type: foundItem?.type,
              textContent: foundItem?.textContent
            }
          ];
        }

        if (this.modelTables.some((item: IPlaceholderComponent) => item.id === field.id)) {
          const foundItem: IPlaceholderComponent = this.modelTables.find((item: IPlaceholderComponent) => item.id === field.id)!;
          this.selectedTableComponents = [
            ...this.selectedTableComponents,
            {
              id: foundItem.id,
              name: foundItem.name,
              type: foundItem?.type,
              textContent: foundItem?.textContent
            }
          ];
        }

        if (this.modelOther.some((item: IPlaceholderComponent) => item.id === field.id)) {
          const foundItem: IPlaceholderComponent = this.modelOther.find((item: IPlaceholderComponent) => item.id === field.id)!;
          this.selectedOtherComponents = [
            ...this.selectedOtherComponents,
            {
              id: foundItem.id,
              name: foundItem.name,
              type: foundItem?.type,
              textContent: foundItem?.textContent
            }
          ];
        }

        switch (field.type) {
          case ReportBuildingBlocks.ECONOMY:
          case ReportBuildingBlocks.TEST_DATA_GRAPHS:
          case ReportBuildingBlocks.TEST_DATA_TABLES:
          case ReportBuildingBlocks.METABOLIC_PROFILE:
          case ReportBuildingBlocks.METABOLIC_POWER:
          case ReportBuildingBlocks.METABOLIC_FINGERPRINT:
          case ReportBuildingBlocks.PERFORMANCE_DEVELOPMENT:
          case ReportBuildingBlocks.PHYSIOLOGICAL_PERFORMANCE:
          case ReportBuildingBlocks.BODY_COMPOSITION:
          case ReportBuildingBlocks.HEART_RATE:
          case ReportBuildingBlocks.TRAINING_ZONE:
          case ReportBuildingBlocks.ORG_INFO:
          case ReportBuildingBlocks.ORG_IMAGE:
          case ReportBuildingBlocks.TEST_INFO:
          case ReportBuildingBlocks.TEXT_FIELD:
            grid.push({
              id: field.id,
              x: field.x,
              y: field.y,
              w: field.width,
              h: field.height,
              minH: itemSize.h,
              minW: itemSize.w,
              maxW: this.cols
            });
            this.gridMeta.set(field.id, {
              id: field.id,
              textContent: field.textContent,
              textSettingType: field.textSettingType,
              type: field.type || '',
              draggable: true,
              resizable: true
            });
            break;
          case ReportBuildingBlocks.PAGE_BREAK:
            grid.push({
              id: field.id,
              x: field.x,
              y: field.y,
              w: field.width,
              h: field.height,
              minH: 7,
              maxH: 7,
              maxW: this.cols
            });
            this.gridMeta.set(field.id, {
              id: field.id,
              textContent: '',
              type: field.type || '',
              draggable: true,
              resizable: false
            });
            break;
            case ReportBuildingBlocks.HORIZONTAL_LINE:
              grid.push({
                id: field.id,
                x: field.x,
                y: field.y,
                w: field.width,
                h: field.height,
                minH: 7,
                maxH: 7,
                maxW: this.cols
              });
              this.gridMeta.set(field.id, {
                id: field.id,
                textContent: '',
                type: field.type || '',
                draggable: true,
                resizable: true
              });
              break;
        }
      }
      this.selectedComponents = [
        ...this.selectedGrapComponents,
        ...this.selectedTableComponents,
        ...this.selectedOtherComponents
      ];
      this.selection = this.selectedComponents.map((item: IPlaceholderComponent) => item.id);
      setTimeout((): void => {
        this._doRerender(grid);
        this.gridReady = true;
      }, 200);
    }
  }


  public ngOnDestroy(): void {
    this.destroyer$.next(true);
    this.destroyer$.complete();
  }

  public onSelectedGraphComponentsChange(): void {
    this._handleSelectionChange();
  }

  public onSelectedTableComponentsChange(): void {
    this._handleSelectionChange();
  }

  public onSelectedOtherComponentsChange(): void {
    this._handleSelectionChange();
  }

  private _handleSelectionChange(): void {
    this.selectedComponents = [
      ...this.selectedGrapComponents,
      ...this.selectedTableComponents,
      ...this.selectedOtherComponents
    ];

    this.removeUncheckedComponents(this.selectedComponents);
    this.addCheckedComponents(this.selectedComponents);
    this._validateForm();
    this.setPageBreaks();
  }

  private removeUncheckedComponents = (selectedComponents: GridMeta[]): void => {
    const components: KtdGridLayout = selectedComponents.map((item: GridMeta) => ({
      id: item.id,
      x: 0,
      y: 0,
      w: 0,
      h: 0
    }));

    const removedComponents: KtdGridLayoutItem[] = this.helperService.findArrayDiff(this.grid, components, 'id');
    for (let removedComponent of removedComponents) {

      if (
        this.gridMeta.get(removedComponent.id)?.type === ReportBuildingBlocks.TEXT_FIELD ||
        this.gridMeta.get(removedComponent.id)?.type === ReportBuildingBlocks.PAGE_BREAK ||
        this.gridMeta.get(removedComponent.id)?.type === ReportBuildingBlocks.HORIZONTAL_LINE ||
        this.gridMeta.get(removedComponent.id)?.type === ReportBuildingBlocks.ORG_IMAGE) {
        continue;
      }
      this.removeItem(removedComponent.id);
    }
  };

  private addCheckedComponents = (selectedComponents: Array<IPlaceholderComponent>): void => {
    const components: KtdGridLayout = selectedComponents.map(item => ({
      id: item.id,
      x: 0,
      y: 0,
      w: 0,
      h: 0
    }));

    const addedComponents: KtdGridLayoutItem[] = this.helperService.findArrayDiff(components, this.grid, 'id');
    const notResizableComponents: Array<ReportBuildingBlocks | string> = [ReportBuildingBlocks.TEST_INFO, ReportBuildingBlocks.ORG_INFO];

    for (let addedComponent of addedComponents) {
      const selectedComponentMeta: IPlaceholderComponent = selectedComponents.find((item: IPlaceholderComponent) => item.id === addedComponent.id)!;
      const gridItemMeta: GridMeta = {
        id: addedComponent.id,
        type: selectedComponentMeta.type,
        textContent: selectedComponentMeta.textContent,
        draggable: true,
        resizable: !notResizableComponents.includes(addedComponent.id)
      };

      this.gridMeta.set(addedComponent.id, gridItemMeta);

      const itemSize: {w: number, h: number } = this._getItemSize(selectedComponentMeta.type);

      const gridItem: KtdGridLayoutItem = {
        id: addedComponent.id,
        x: 0,
        y: this.grid.reduce((acc: number, cur: KtdGridLayoutItem) => Math.max(acc, cur.y + cur.h), 0),
        w: itemSize.w,
        h: itemSize.h,
        minH: itemSize.h,
        minW: itemSize.w,
        maxW: this.cols
      };

      this.grid = [
        ...this.grid,
        gridItem
      ];
    }
  };

  private _getItemSize(type: ReportBuildingBlocks | string): { w: number, h: number } {
    switch (type) {
      case ReportBuildingBlocks.METABOLIC_FINGERPRINT:
        return {
          w: this.cols,
          h: 50
        };
      case ReportBuildingBlocks.BODY_COMPOSITION:
        return {
          w: this.cols,
          h: 40
        };
      case ReportBuildingBlocks.PERFORMANCE_DEVELOPMENT:
        return {
          w: 26 * this.scaleFactor,
          h: 33
        };
      case ReportBuildingBlocks.PHYSIOLOGICAL_PERFORMANCE:
        return {
          w: 26 * this.scaleFactor,
          h: 59
        };
      case ReportBuildingBlocks.METABOLIC_POWER:
        return {
          w: 22 * this.scaleFactor,
          h: 41
        };
      case ReportBuildingBlocks.METABOLIC_PROFILE:
        return {
          w: 26 * this.scaleFactor,
          h: 111.5
        };
      case ReportBuildingBlocks.TEST_DATA_GRAPHS:
        return {
          w: this.cols,
          h: 82.17
        };
      case ReportBuildingBlocks.TEST_DATA_TABLES:
        return {
          w: 20 * this.scaleFactor,
          h: 43
        };
      case ReportBuildingBlocks.HEART_RATE:
        return {
          w: 22 * this.scaleFactor,
          h: 67
        };
      case ReportBuildingBlocks.TRAINING_ZONE:
        return {
          w: this.cols,
          h: 86.5
        };
      case ReportBuildingBlocks.ECONOMY:
        return {
          w: 18 * this.scaleFactor,
          h: 33.5
        };
      case ReportBuildingBlocks.TEST_INFO:
        return {
          w: 14 * this.scaleFactor,
          h: 32
        };
      case ReportBuildingBlocks.TEXT_FIELD:
        return {
          w: 6,
          h: 15,
        };
      case ReportBuildingBlocks.ORG_IMAGE:
        return {
          w: 3 * this.scaleFactor,
          h: 10,
        };
      case ReportBuildingBlocks.ORG_INFO:
        return {
          w: 12 * this.scaleFactor,
          h: 16
        };
      default:
        return {
          w: 20 * this.scaleFactor,
          h: 40
        };
    }
  }

  public addPageBreak(): void {
    const breakElement: KtdGridLayoutItem = {
      id: ReportBuildingBlocks.PAGE_BREAK + this._generateRandomId(),
      x: 0,
      y: this.grid.reduce((acc: number, cur: KtdGridLayoutItem) => Math.max(acc, cur.y + cur.h) + 1, 0),
      w: this.cols,
      h: 7,
      minH: 7,
      maxH: 7,
      maxW: this.cols
    };

    const breakElementMeta: GridMeta = {
      id: breakElement.id,
      type: ReportBuildingBlocks.PAGE_BREAK,
      textContent: '',
      draggable: true,
      resizable: false
    };

    this.gridMeta.set(breakElement.id, breakElementMeta);
    this.grid = [
      ...this.grid,
      breakElement
    ];
    this._validateForm();
  }

  public addOrganizationImage(): void {
    const organizationImageElement: KtdGridLayoutItem = {
      id: ReportBuildingBlocks.ORG_IMAGE + this._generateRandomId(),
      x: 0,
      y: this.grid.reduce((acc: number, cur: KtdGridLayoutItem) => Math.max(acc, cur.y + cur.h) + 1, 0),
      w: 1.5,
      h: 10,
      minH: 10,
      maxH: 100,
      maxW: this.cols
    };

    const organizationImageElementMeta: GridMeta = {
      id: organizationImageElement.id,
      type: ReportBuildingBlocks.ORG_IMAGE,
      textContent: '',
      draggable: true,
      resizable: true
    };

    this.grid = [
      ...this.grid,
      organizationImageElement
    ];
    this.gridMeta.set(organizationImageElement.id, organizationImageElementMeta);
    this._validateForm();
  }

  public addLine(): void {
    const horizontalLine: KtdGridLayoutItem = {
      id: ReportBuildingBlocks.HORIZONTAL_LINE + this._generateRandomId(),
      x: 0,
      y: this.grid.reduce((acc: number, cur: KtdGridLayoutItem) => Math.max(acc, cur.y + cur.h), 0),
      w: this.cols,
      h: 7,
      minH: 7,
      maxH: 7,
      maxW: this.cols
    };

    const breakElementMeta: GridMeta = {
      id: horizontalLine.id,
      type: ReportBuildingBlocks.HORIZONTAL_LINE,
      textContent: '',
      draggable: true,
      resizable: true
    };

    this.gridMeta.set(horizontalLine.id, breakElementMeta);
    this.grid = [
      ...this.grid,
      horizontalLine
    ];
    this._validateForm();
    this.setPageBreaks();
  }

  public addTextEditor(): void {
    const textField: KtdGridLayoutItem = {
      id: ReportBuildingBlocks.TEXT_FIELD +  this._generateRandomId(),
      x: 0,
      y: this.grid.reduce((acc: number, cur: KtdGridLayoutItem) => Math.max(acc, cur.y + cur.h), 0),
      w: 26 * this.scaleFactor,
      h: 50,
      minH: 15,
      minW: 6,
      maxW: this.cols
    };
    const textFieldMeta: GridMeta = {
      id: textField.id,
      type: ReportBuildingBlocks.TEXT_FIELD,
      textSettingType: ReportBuildingBlocks.GENERAL_TEXT,
      textContent: '',
      resizable: true,
      draggable: true
    };

    this.grid = [
      ...this.grid,
      textField
    ];
    this.gridMeta.set(textField.id, textFieldMeta);
    this._validateForm();
    this.setPageBreaks();
  }

  public removeItem(id: string): void {
    const gridCopy: KtdGridLayoutItem[] = [...this.grid];
    const index: number = this.grid.findIndex((item: KtdGridLayoutItem) => item.id === id);
    if (index > -1) {
      gridCopy.splice(index, 1);
    }
    this.grid = [...gridCopy];

    if (this.gridMeta.has(id)) {
      this.gridMeta.delete(id);
    }

    const selectionModels: IPlaceholderComponent[][] = [
      this.selectedGrapComponents,
      this.selectedTableComponents,
      this.selectedOtherComponents
    ];
    for (let model of selectionModels) {
      for (let item of model) {
        if (item.id === id) {
          model.splice(model.findIndex((modelItem: IPlaceholderComponent) => modelItem.id === item.id), 1);
        }
      }
    }
    this.selectedGrapComponents = [...selectionModels[0]];
    this.selectedTableComponents = [...selectionModels[1]];
    this.selectedOtherComponents = [...selectionModels[2]];
  }

  public onRemoveItem(id: string): void {
    this.removeItem(id);
    this._validateForm();
    this.setPageBreaks();
  }

  public onChangeTextSettingsType(value: string, id: any): void {
    const focusedItem: GridMeta | undefined = this.gridMeta.get(id);
    if (focusedItem) {
      focusedItem.textSettingType = value;
    }
  }

  public openTextEditor(gridItem: any, blockType: string | undefined, id: string, textContent: string | undefined, height: number, width: number, type: string, textSettingType: any): void {
    if (blockType === ReportBuildingBlocks.TEXT_FIELD) {
      const dialogRef: MatDialogRef<TextEditorDialogComponent> = this.dialog.open(TextEditorDialogComponent, {
        width: gridItem.w * 72 + 70 + 'px',
        height: gridItem.h * 10 + 500 + 'px',
        data: { id, textContent, height, width, type, title: 'Edit text', textSettingType },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((res: any): void => {
        if (res.save) {
          this.onTextChanged({ text: res.data.text, id: res.data.id });
          this.onChangeTextSettingsType(res.textComponentType.value, res.textComponentType.id);
        }
      });
    }
  }

  public onLayoutUpdated(layout: KtdGridLayout): void {
    this.grid = layout;
  }

  public onTextChanged(event: {text: string, id: string}): void {
    const focusedItem: GridMeta = this.gridMeta.get(event.id)!;
    focusedItem.textContent = event.text;
  }

  public onResizeStart(event: KtdResizeStart): void {
    this._startDimensions.w = event.layoutItem.w;
    this._startDimensions.h = event.layoutItem.h;
  }
  public onResizeEnd(event: KtdResizeEnd): void {
    const fieldMeta: GridMeta = this.gridMeta.get(event.layoutItem.id)!;
    const ignoredFields: Array<ReportBuildingBlocks | string> = [
      ReportBuildingBlocks.TEXT_FIELD,
      ReportBuildingBlocks.HORIZONTAL_LINE,
      ReportBuildingBlocks.ORG_IMAGE,
      ReportBuildingBlocks.TEST_DATA_TABLES, ReportBuildingBlocks.TRAINING_ZONE];

    if (ignoredFields.includes(fieldMeta.type)) return;

    const layoutCopy: KtdGridLayoutItem[] = [...this.grid];
    let currentLayoutItem: KtdGridLayoutItem = layoutCopy.find((item: KtdGridLayoutItem) => item.id === event.layoutItem.id)!;

    // To get real resize diff, must use percentages
    // Calculate percentage diff for sides
    const wDiff: number = event.layoutItem.w - this._startDimensions.w;
    const hDiff: number = event.layoutItem.h - this._startDimensions.h;

    const wDiffPercent: number = Math.round((wDiff * 100) / this._startDimensions.w);
    const hDiffPercent: number = Math.round((hDiff * 100) / this._startDimensions.h);

    const checkIfWidthIsChanged: boolean = event.layoutItem.w !== this._startDimensions.w && event.layoutItem.h === this._startDimensions.h;

    const checkIhHeightIsChanged: boolean = event.layoutItem.h !== this._startDimensions.h && event.layoutItem.w === this._startDimensions.w;

    const checkIfWidthAndHeightAreChangedWidthMoreThanHeight: boolean = checkIfWidthIsChanged || (Math.abs(wDiffPercent) > Math.abs(hDiffPercent));

    const checkIfWidthAndHeightAreChangedHeightMoreThanWidth: boolean = checkIhHeightIsChanged || (Math.abs(hDiffPercent) > Math.abs(wDiffPercent));


    // Check if width and height were changed but width more than height
    if (checkIfWidthIsChanged || checkIfWidthAndHeightAreChangedWidthMoreThanHeight) {
      currentLayoutItem.w = event.layoutItem.w;

      const newHeight: number = this._startDimensions.h + (this._startDimensions.h / 100) * wDiffPercent;

      if (newHeight < event.layoutItem.minH!) {
        currentLayoutItem.h = event.layoutItem.minH || newHeight;
      } else {
        currentLayoutItem.h = newHeight;
      }

      setTimeout((): void => {
        this._doRerender(layoutCopy);
      });
    }

    // Check if width and height were changed but height more than width
    if (checkIhHeightIsChanged || checkIfWidthAndHeightAreChangedHeightMoreThanWidth) {
      currentLayoutItem.w = (event.layoutItem.h * this._startDimensions.w) / this._startDimensions.h;

      if (currentLayoutItem.w > this.cols) {
        currentLayoutItem.w = this.cols;
        currentLayoutItem.h = (currentLayoutItem.w * this._startDimensions.h) / this._startDimensions.w;
      } else {
        currentLayoutItem.h = event.layoutItem.h;
      }
      setTimeout((): void => {
        this._doRerender(layoutCopy);
      });
    }

    this._startDimensions.w = currentLayoutItem.w;
    this._startDimensions.h = currentLayoutItem.h;
  }

  public setPageBreaks(): void {
    setTimeout((): void => {
      this.gridPageBreaks.length = 0;
      this._pageBreaks = this._calculatePageBreaks();
      if (this._pageBreaks > 1) {
        for (let i = 1; i < this._pageBreaks; i++) {
          this.gridPageBreaks.push(i);
        }
      }

    }, 200);
  }

  private _doRerender(grid: KtdGridLayout): void {
    this.grid = [...grid];
    this.setPageBreaks();
  }

  public nameChanged(): void {
    this._validateForm();
  }

  public onSave(): void {
    let data: Array<KtdGridLayoutItem & GridMeta> = [];
    const initialPageBreakPosition: number = 140;

    for (let gridItem of this.grid) {
      const itemData: KtdGridLayoutItem & GridMeta = {
        id: gridItem.id,
        x: gridItem.x,
        y: gridItem.y,
        w: gridItem.w,
        h: gridItem.h,
        type: this.gridMeta.get(gridItem.id)?.type || '',
        textContent: this.gridMeta.get(gridItem.id)?.textContent,
        textSettingType: this.gridMeta.get(gridItem.id)?.textSettingType,
      };
      data.push(itemData);
    }
    this.gridPageBreaks.forEach((item: number): void => {
      this.pageBreakPositionList.push({
        id: "page_break",
        width: 15,
        height: 7,
        x: 0,
        y: initialPageBreakPosition * item,
      });
    });

    data = [
      ...data,
      ...this.pageBreakPositionList,
    ];

    const template: ReportTemplate = {
      name: this.templateName,
      description: this.templateDescription,
      is_active: this.templateActive,
      is_public: this.isPublic,
      is_delete: this.templateDeleted,
      organization: this.templateOrganization,
      fields: data.map((item: KtdGridLayoutItem & GridMeta) => (
        {
          id: item.id,
          width: item.w,
          height: item.h,
          x: item.x,
          y: item.y,
          type: item.type || '',
          textContent: item.textContent || '',
          textSettingType: item.textSettingType || '',
        })
      )
    };

    if (this.data.isEdit) {
      template['id'] = this.templateId;
      this.reportTemplateService.updateReportTemplate(template).subscribe(() => {
        this.dialogRef.close('SUCCESS');
      }, () => {
        this.dialogRef.close('ERROR');
      });
    } else {
      this.reportTemplateService.createReportTemplate(template).subscribe(() => {
          this.dialogRef.close('SUCCESS');
        },
        error => {
          this.dialogRef.close('ERROR');
        });
    }
  }

  private _generateRandomId(): string {
    return (Math.random() + 1).toString(36).substring(7);
  }

  private _validateForm(): void {
    this.saveDisabled = this.grid.length === 0 || this.templateName.length === 0;
  }

  private _calculatePageBreaks(): number {
    const h = this.reportGridElement?.nativeElement.clientHeight!;
    return Math.ceil( h / this.pageHeight);
  }
}
