import { AppConstants } from "@core/constants";

export const ChartModules = [
  {
    id: 'body_composition',
    type: 1,
    rpId: 'body_composition_rp',
    name: 'bc',
    text: AppConstants.WORDING.BODY_COMPOSITION,
    state: '',
  },
  {
    id: 'metabolic_capacities',
    type: 2,
    rpId: 'metabolic_capacities_rp',
    text: AppConstants.WORDING.PHYSIOLOGICAL_PERFORMANCE,
    name: 'pc',
    state: '',
  },
  {
    id: 'metabolic_profile',
    type: 3,
    rpId: 'metabolic_profile_rp',
    text: AppConstants.WORDING.LOAD_CHARACTERISTICS,
    name: 'mp',
    state: '',
  },
  {
    id: 'metabolic_fingerprint',
    type: 4,
    rpId: 'metabolic_fingerprint_rp',
    text: AppConstants.WORDING.METABOLIC_FINGERPRINT,
    name: 'mf',
    state: '',
  },
  {
    id: 'performance_development',
    type: 5,
    rpId: 'performance_development_rp',
    text: AppConstants.WORDING.PERFORMANCE_DEVELOPMENT,
    name: 'pd',
    state: '',
  },
  {
    id: 'training_zones',
    type: 6,
    rpId: '',
    text: AppConstants.WORDING.TRAINING_ZONES,
    name: 'iz',
    state: '',
  },
  {
    id: 'test_data',
    type: 7,
    rpId: 'test_data_rp',
    text: 'Test Data',
    name: 'td',
    state: '',
  },
];

export const ChartConstants = {
  BODY_COMPOSITION: {
    ID: 'body_composition',
    ELEMENT_ID: '#body_composition',
  },
  PERFORMANCE_DEVELOPMENT: {
    ID: 'performance_development',
    ELEMENT_ID: '#performance_development',
  },
  SPEED_RELATIONSHIPS: {
    ID: 'speed_relationships',
    ELEMENT_ID: '#speed_relationships',
  },
  METABOLIC_CAPACITIES: {
    ID: 'metabolic_capacities',
    ELEMENT_ID: '#metabolic_capacities',
  },
  METABOLIC_FINGERPRINT: {
    ID: 'metabolic_fingerprint',
    ELEMENT_ID: '#metabolic_fingerprint',
  },
  LOAD_CHARACTERISTICS: {
    ID: 'metabolic_profile',
    ELEMENT_ID: '#metabolic_profile',
  },
  TRAINING_ZONES: {
    ID: 'training_zones',
    ELEMENT_ID: '#training_zones',
  },
  TEST_DATA: {
    ID: 'test_data',
    ELEMENT_ID: '#test_data',
  },
  COLOR: {
    BLUE: '#8fd2ff',
    RED: '#e53227',
  },

  RANGE: {
    START: 0,
    END: 100,
  },

  MESSAGES: {
    NO_POWER_DATA: "This test doesn't have power data",
    NO_SPEED_DATA: "This test doesn't have speed data",
    NO_DATA: "This test doesn't have data"
  }
};

export enum ReportBuildingBlocks {
  BODY_COMPOSITION = 'body_composition',
  METABOLIC_FINGERPRINT = 'metabolic_fingerprint',
  METABOLIC_PROFILE = 'metabolic_profile',
  METABOLIC_POWER = 'metabolic_power',
  ECONOMY = 'economy',
  TRAINING_ZONE = 'training_zones',
  TEST_DATA_TABLES = 'test_data_tables',
  HEART_RATE = 'heart_rate',
  TEST_DATA_GRAPHS = 'test_data',
  PHYSIOLOGICAL_PERFORMANCE = 'metabolic_capacities',
  PERFORMANCE_DEVELOPMENT = 'performance_development',
  PAGE_BREAK = 'page_break',
  TEXT_FIELD = 'text_field',
  ORG_IMAGE = 'org_image',
  ORG_INFO = 'org_info',
  TEST_INFO = 'test_info',
  HORIZONTAL_LINE = 'horizontal_line',
  GENERAL_TEXT = 'general_text',
  INTRODUCTION_TEXT = 'introduction',
  LOAD_CHARACTERISTICS = 'metabolic_profile',
}

export const ReportTemplateGraphs =  [
  {id: ReportBuildingBlocks.BODY_COMPOSITION, name: AppConstants.WORDING.BODY_COMPOSITION, type: ReportBuildingBlocks.BODY_COMPOSITION, textContent: ''},
  {id: ReportBuildingBlocks.METABOLIC_FINGERPRINT, name: AppConstants.WORDING.METABOLIC_FINGERPRINT, type: ReportBuildingBlocks.METABOLIC_FINGERPRINT, textContent: ''},
  {id: ReportBuildingBlocks.PERFORMANCE_DEVELOPMENT, name: AppConstants.WORDING.PERFORMANCE_DEVELOPMENT, type: ReportBuildingBlocks.PERFORMANCE_DEVELOPMENT, textContent: ''},
  {id: ReportBuildingBlocks.PHYSIOLOGICAL_PERFORMANCE, name: AppConstants.WORDING.PHYSIOLOGICAL_PERFORMANCE, type: ReportBuildingBlocks.PHYSIOLOGICAL_PERFORMANCE, textContent: ''},
  {id: ReportBuildingBlocks.METABOLIC_POWER, name: AppConstants.WORDING.METABOLIC_POWER, type: ReportBuildingBlocks.METABOLIC_POWER, textContent: ''},
  {id: ReportBuildingBlocks.METABOLIC_PROFILE, name: AppConstants.WORDING.LOAD_CHARACTERISTICS, type: ReportBuildingBlocks.METABOLIC_PROFILE, textContent: ''},
  {id: ReportBuildingBlocks.TEST_DATA_GRAPHS, name: AppConstants.WORDING.TEST_DATA_GRAPHS, type: ReportBuildingBlocks.TEST_DATA_GRAPHS, textContent: ''},
  {id: ReportBuildingBlocks.HEART_RATE, name: AppConstants.WORDING.HEART_RATE, type: ReportBuildingBlocks.HEART_RATE, textContent: ''},
  {id: ReportBuildingBlocks.ECONOMY, name: AppConstants.WORDING.ECONOMY, type: ReportBuildingBlocks.ECONOMY, textContent: ''}
];

export const ReportTemplateTables = [
  {id: ReportBuildingBlocks.TEST_DATA_TABLES, name: AppConstants.WORDING.TEST_DATA_TABLES, type: ReportBuildingBlocks.TEST_DATA_TABLES, textContent: ''},
  {id: ReportBuildingBlocks.TRAINING_ZONE, name: AppConstants.WORDING.TRAINING_ZONES, type: ReportBuildingBlocks.TRAINING_ZONE, textContent: ''},
];

export const ReportTemplateOther = [
  {id: ReportBuildingBlocks.TEST_INFO, name: 'Test Information', type: ReportBuildingBlocks.TEST_INFO, textContent: ''},
  {id: ReportBuildingBlocks.ORG_INFO, name: 'Organisation Information', type: ReportBuildingBlocks.ORG_INFO, textContent: ''},
];
