import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";

export class RegionSettingsResultDto extends PagedResultDto {
    public items: RegionSettingsDto[] = [];
}

export interface ICountry {
    id: number;
    name: string;
    country_code: string;
}

export interface ICurrency {
    id: number;
    name: string;
    iso: string;
}

export class RegionSettingsDto extends Deserializable {
  public id!: number;
  public name!: string;
  public price!: number;
  public country!: Array<ICountry>;
  public currency!: ICurrency;
}

export interface IRegionDetail {
    from_region?: any;
    to_region?: any;
    country_id?: number;
}

export interface IRegionSettingsDetail {
    id?: number;
    name?: string;
    price?: number;
    country?: any;
    currency?: any;
}

export interface IRegionData {
    id: number;
    name: string;
    price: number;
    countryArray:  Array<string>;
    currency: string;
}
