<div class="page-container">
  <app-custom-back-button [customUrl]="customUrl"></app-custom-back-button>
  <span class="general-info--font-size-35 line-height-40 u-display-block">Visualization</span>
  <ng-container *ngIf="!metricList.length || !sportList.length; else metricContainer">
    <div class="visualization__try-for-free-container u-margin-top-20 o-flex o-flex--align-items-center o-flex--justify-center u-border-radius border-color u-padding-16 tile-color-background">
      <span>No Data</span>
    </div>
  </ng-container>
  <ng-template #metricContainer>
    <div class="u-margin-top-20 o-flex o-flex--justify-space-between">
      <div class="width-49-percent">
        <app-sport-selection *ngIf="currentSportId" [currentSportId]="currentSportId" [availableSportList]="sportOptionList" (sportChanged)="onSportChanged($event)"></app-sport-selection>
      </div>
      <div class="width-49-percent">
        <app-metric-selection *ngIf="metricOptionList" [preselectedMetric]="metricId" [config]="metricOptionList" (metricChanged)="onMetricChanged($event)"></app-metric-selection>
      </div>
    </div>
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="assets/custom-icons/physiological-performance-benchmark.svg" alt="physiological performance benchmark icon" class="u-margin-right-10">
          <span>Physiological Performance Benchmark</span>
        </ng-template>
        <div *ngIf="isEnabledMetric; else tryForFree" class="u-margin-top-20 o-flex o-flex--justify-space-between">
          <div class="width-49-percent">
            <app-meter-configuration
              *ngIf="currentMeterConfig"
              [config]="currentMeterConfig"
              [metricOptionsConfig]="meterOptionListBySportType"
              (meterConfigChanged)="onMeterConfigChanged($event)"
            ></app-meter-configuration>
          </div>
          <div class="width-49-percent">
            <div *ngIf="physiologicalPerformanceLimitsTabConfig" class="visualization__performance-limits-full">
              <app-performance-limits class="visualization__performance-limits-tab-min-width" [config]="physiologicalPerformanceLimitsTabConfig" (performanceLimitChanged)="onPhysiologicalPerformanceLimitsChanged($event)"></app-performance-limits>
            </div>
          </div>
        </div>
        <div class="u-margin-top-50 o-flex o-flex--justify-space-between o-flex--align-items-center">
          <div>
            <span class="u-display-block u-font-weight-700 u-font-size-16 line-height-16 u-margin-bottom-8">Preview</span>
            <span class="u-display-block  u-font-weight-400 u-font-size-14 faded-text-color line-height-16">Preview the result of your select below</span>
          </div>
          <div *ngIf="isEnabledMetric">
            <mat-checkbox color="primary" (ngModelChange)="toggleCurrentMetric(metricId)" [ngModel]="isShowCurrentMetric">Show {{metricName}} in Physiological Performance Benchmark</mat-checkbox>
          </div>
        </div>
        <hr>
        <div class="u-margin-top-30">
          <app-preview-meter [config]="previewMeterConfig"></app-preview-meter>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="assets/custom-icons/metabolic-fingerprint.svg" alt="metabolic fingerprint icon" class="u-margin-right-10">
          <span>Metabolic Fingerprint</span>
        </ng-template>
        <div class="u-margin-top-20">
          <ng-container *ngIf="isEnabledMetric; else tryForFree">
            <app-metabolic-configurator
              *ngIf="currentMetabolicAngleConfig"
              [config]="currentMetabolicAngleConfig"
              [metricOptionsConfig]="angleOptionListBySportType"
              (fingerprintConfigChanged)="onMetabolicFingerprintChanged($event)"
            ></app-metabolic-configurator>
            <div class="o-flex o-flex--justify-space-between u-margin-top-20">
              <div
                *ngIf="metabolicPerformanceLimitsTabConfigAngle1"
                class="width-33-percent">
                <app-performance-limits
                  *ngIf="metabolicPerformanceLimitsTabConfigAngle1"
                  class="visualization__performance-limits-tab-min-width"
                  [angleName]="'angle 1'" [config]="metabolicPerformanceLimitsTabConfigAngle1"
                  (performanceLimitChanged)="onMetabolicPerformanceLimitsChangedAngle1($event)"
                ></app-performance-limits></div>
              <div
                *ngIf="metabolicPerformanceLimitsTabConfigAngle2"
                class="width-33-percent">
                <app-performance-limits
                  *ngIf="metabolicPerformanceLimitsTabConfigAngle2"
                  class="visualization__performance-limits-tab-min-width"
                  [angleName]="'angle 2'" [config]="metabolicPerformanceLimitsTabConfigAngle2"
                  (performanceLimitChanged)="onMetabolicPerformanceLimitsChangedAngle2($event)"
                ></app-performance-limits></div>
              <div
                *ngIf="metabolicPerformanceLimitsTabConfigAngle3"
                class="width-33-percent">
                <app-performance-limits
                  *ngIf="metabolicPerformanceLimitsTabConfigAngle3"
                  class="visualization__performance-limits-tab-min-width"
                  [angleName]="'angle 3'" [config]="metabolicPerformanceLimitsTabConfigAngle3"
                  (performanceLimitChanged)="onMetabolicPerformanceLimitsChangedAngle3($event)"
                ></app-performance-limits></div>
            </div>
          </ng-container>
          <div class="u-margin-top-50 u-margin-bottom-30">
            <span class="u-display-block u-font-weight-700 u-font-size-16 line-height-16 u-margin-bottom-8">Preview</span>
            <span class="u-display-block  u-font-weight-400 u-font-size-14 faded-text-color line-height-16">Preview the result of your select below</span>
          </div>
          <app-preview-metabolic-fingerprint *ngIf="currentMetabolicAngleConfig" [config]="currentMetabolicAngleConfig"></app-preview-metabolic-fingerprint>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <img src="assets/custom-icons/performance-development.svg" alt="performance development icon" class="u-margin-right-10">
          <span>Performance Development</span>
        </ng-template>
        <ng-container *ngIf="isEnabledMetric; else tryForFree">
          <div class="o-flex o-flex--align-items-center o-flex--justify-center">
            <app-animated-dropdown-menu *ngIf="performanceDevelopmentButtonOptionList" [buttonName]="leftButton1" [optionList]="performanceDevelopmentButtonOptionList" (optionChanged)="onGraphLeftAxis1Changed($event)"></app-animated-dropdown-menu>
            <app-animated-dropdown-menu *ngIf="performanceDevelopmentButtonOptionList" [buttonName]="leftButton2" [optionList]="performanceDevelopmentButtonOptionList" (optionChanged)="onGraphLeftAxis2Changed($event)"></app-animated-dropdown-menu>
            <app-animated-dropdown-menu *ngIf="performanceDevelopmentButtonOptionList" [buttonName]="rightButton1" [optionList]="performanceDevelopmentButtonOptionList" (optionChanged)="onGraphRightAxis1Changed($event)"></app-animated-dropdown-menu>
          </div>
          <div class="u-margin-top-20">
            <div id="performance_development"></div>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>

<ng-template #tryForFree>
  <div class="visualization__try-for-free-container u-margin-top-20 o-flex o-flex--align-items-center o-flex--justify-center u-border-radius border-color u-padding-16 tile-color-background">
    <button color="primary" mat-raised-button (click)="purchaseMetric()">Try For Free</button>
  </div>
</ng-template>
