import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commentFormat',
})
export class CommentFormatPipe implements PipeTransform {
  public transform(value: any): string {
    if (value) {
      const title = value.replace(/./gm, (s: any) => {
        return '&#' + s.charCodeAt(0) + ';';
      });

      if (value.length > 30) {
        return `
                <div class="d-inline comment-format-warpper">${value.substr(
                  0,
                  30
                )}
                  <span class='btn-readmore-comment'>[...]
                    <div class="popover-comment">${title}</div>
                  </span>
                </div>`;
      }
    }
    return `<div class="d-inline">${value}</div>`;
  }
}
