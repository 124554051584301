import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { String } from 'typescript-string-operations';
import { ServiceBase } from '../service-base';
import { CompetitionDto, CompetitionResultDto } from './competition-dto';
import {ApiRequest} from "@shared/models/api-request";

@Injectable({
  providedIn: 'root',
})
export class CompetitionsService extends ServiceBase {
  public listAll(): Observable<Object> {
    return this._apiService.get(this._appConstants.API.COMPETITIONS.GET);
  }

  public list(request: PagedAndSortedRequestDto | any): Observable<CompetitionResultDto> {
    const apiUrl: string = this._appConstants.API.COMPETITIONS.GET;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new CompetitionResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: CompetitionDto) =>
            new CompetitionDto().deserialize(item)
          ),
        });
      })
    );
  }

  public create(competition: CompetitionDto): Observable<Object> {
    return this._apiService.post(this._appConstants.API.COMPETITIONS.ADD, competition);
  }

  public update(competition: CompetitionDto): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.COMPETITIONS.UPDATE, competition.id);

    return this._apiService.put(apiUrl, competition);
  }

  public delete(id: number): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.COMPETITIONS.DELETE, id);

    return this._apiService.delete(apiUrl);
  }
}
