
export class TimeToDepletionDto {
  public x: any | undefined;
  public yleft: any | undefined;
  public yright: any | undefined;
  public horizontal_data: any | undefined;

  public deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
