import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { IFeatureSport } from "@shared/interfaces/feature-sport.interface";
import { IDropdownSelection } from "@shared/interfaces/dropdown-selection.interface";
import { config } from "rxjs";

@Component({
  selector: 'app-sport-selection',
  templateUrl: './sport-selection.component.html',
})
export class SportSelectionComponent implements OnInit {
  private fb: FormBuilder = inject(FormBuilder);

  @Input() public currentSportId: number;
  @Input() public availableSportList: IFeatureSport[];
  @Output() public sportChanged: EventEmitter<any> = new EventEmitter<any>();

  public sportSelectionForm: FormGroup;
  public sportOptionList: IDropdownSelection[] = [];

  public ngOnInit(): void {
    this.initSportSelectionForm();
    this.fillSportSelectionForm(this.currentSportId);
    this.prepareSportOptionList(this.availableSportList);

  }

  public prepareSportOptionList(config: IFeatureSport[]): void {
    this.sportOptionList = config.map((sport: IFeatureSport): IDropdownSelection => ({
      id: sport.id ? sport.id : 0,
      name: sport.name,
      parent_id: sport.parent_id,
    }));
  }

  public fillSportSelectionForm(currentSportId: number): void {
    this.sportSelectionForm.get('sport_configuration_id')?.setValue(currentSportId);
  }

  public initSportSelectionForm(): void {
    this.sportSelectionForm = this.fb.group({
      sport_configuration_id: [],
    });
  }

  public onChangeSport(parentId: number | undefined): void {
    this.sportChanged.emit({
      sportId: this.sportSelectionForm.value.sport_configuration_id,
      parentId,
    });
  }
}
