<div [formGroup]="selectMetricForm" class="meter-configuration u-border-radius border-color tile-color-background u-padding-16">
  <div class="u-position-relative meter-configuration__icon-dropdown-menu">
    <ng-content select="[togglebutton]"></ng-content>
    <mat-icon class="width-100-percent u-height-100 u-margin-top-10" svgIcon="meter-icon"></mat-icon>
    <div class="meter-configuration__dropdown-menu dropdown-background-color">
      <mat-form-field appearance="outline" floatLabel="always" class="width-100-percent">
        <mat-label>Select metric to show on the meter:</mat-label>
        <mat-select formControlName="base">
          <mat-option (click)="onChangeBaseMetric(item)" [value]="item.id" *ngFor="let item of baseMetricOptionList">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="meter-configuration__configure-rows u-margin-top-30">
    <div class="meter-configuration__width">
      <span *ngIf="config.row1?.id" class="u-font-size-12 u-font-weight-500 line-height-16 text-color u-margin-bottom-8 u-display-block">Select Data Fields To Display On The Chart Below:</span>
      <div>
        <mat-form-field *ngIf="config.row1?.id" appearance="outline" floatLabel="always" class="width-100-percent">
          <mat-label>Select data for row 1:</mat-label>
          <mat-select formControlName="row1">
            <mat-option (click)="onChangeRow1(item)" [value]="item.id" *ngFor="let item of metricOptionList">{{ item.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="config.row2?.id" appearance="outline" floatLabel="always" class="width-100-percent">
          <mat-label>Select data for row 2:</mat-label>
          <mat-select formControlName="row2">
            <mat-option (click)="onChangeRow2(item)" [value]="item.id" *ngFor="let item of metricOptionList">{{ item.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="config.row3?.id" appearance="outline" floatLabel="always" class="width-100-percent">
          <mat-label>Select data for row 3:</mat-label>
          <mat-select formControlName="row3">
            <mat-option (click)="onChangeRow3(item)" [value]="item.id" *ngFor="let item of metricOptionList">{{ item.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
