import { DestroyRef, ElementRef, Injectable, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { IAuth } from '@modules/login/login.component';
import * as moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { String } from 'typescript-string-operations';
import { AppConstants } from '../../constants';
import { OrganizationDto } from '../organization/organization-dto';
import { RightsManagementService } from '../rights-management/rights-management.service';
import { ServiceBase } from '../service-base';

export interface ILoginData { username: string; password: string; }
export interface IRole {
  created_at: string;
  id: number;
  is_active: boolean;
  is_agent_role: boolean;
  is_delete: boolean;
  is_organization: boolean;
  is_owner: boolean;
  name: string;
  organization: OrganizationDto;
  right_management: string;
  updated_at: string;
}
export interface ISettings {
  created_at: string;
  id: number;
  smtp_email: string;
  smtp_email_use_tls: boolean;
  smtp_password: string;
  smtp_port: string;
  smtp_server: string;
  smtp_username: string;
  updated_at: string;
  watermark_photo: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ServiceBase {
  public auth!: IAuth;

  @ViewChild('tree_view_admin', { static: true }) public treeViewAdmin!: ElementRef;

  constructor(
    private router: Router,
    private readonly snackBar: MatSnackBar,
    private rightsManagementService: RightsManagementService,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public login(data: ILoginData): Observable<IAuth> {
    return <Observable<IAuth>> this._apiService.post('accounts/login/', data);
  }

  public logout(): Observable<any> {
    let selectedColorTheme: string = localStorage.getItem('theme') || '';
    return this._apiService.post('accounts/logout/', {})
      .pipe(
        tap(() => {
          localStorage.clear();
          localStorage.setItem('theme', selectedColorTheme);
          this._sessionService.deleteAllCookies();
          window.location.href = '/';
        }),
      );
  }

  public forceLogin(data: ILoginData, services: Record<string, any>) {
    // Used in cases where the login will always succeed - such as the plan payment page
    const sessionService = services.session;
    const apiService = services.api;
    const googleTagManagerHelperService = services.googleTagManagerHelper;

    this.login(data).subscribe((res: IAuth) => {
      res.photo = "";
      this.auth = res;
      sessionService.setCookie('auth', JSON.stringify(res));
      this.setCookiesForGTM(this.auth);
      this.getRoles(this.auth.role.id, false);
      apiService.resetCountCallExpiredSession();
      sessionService.activateLoginStatus();
      this.getSession(apiService);
      this.setIdeTime();
      googleTagManagerHelperService.setDataLayer(res);
    })
  }

  public getReturnPath(): string | null {
    return localStorage.getItem('returnPath');
  }

  public removeReturnPath(): void {
    localStorage.removeItem('returnPath');
  }

  public navigateToUrl(returnedPath: string): void {
    this.router.navigateByUrl(returnedPath);
  }

  public getRoles(roleId: number, isSessionExpired?: boolean | string, courseId?: number): void {
    this.rightsManagementService.getById(roleId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
      next: (res: any): void => {
        localStorage.setItem('rights', res.right_management);
        this.setAdditionalCookiesForGTM(res.right_management);
        const returnedPath: string | null = this.getReturnPath();

        if (isSessionExpired === 'true' && returnedPath) {
          this.navigateToUrl(returnedPath);
        } else if (courseId) {
          this.router.navigate(['/home/college/consumed-course'], {
            queryParams: {
              course_id: courseId
            }
          });
        } else {
          this.navigateToHomePage();
        }
      },
      complete: (): void => {
        this.removeReturnPath();
      }
    });
  }

  public navigateToHomePage(): void {
    this.router.navigate(['/home/dashboard']);
  }

  public setCookiesForGTM(auth: any): void {
    this._sessionService.setCookie('user_id', auth.id);
    this._sessionService.setCookie('first_name', auth.first_name);
    this._sessionService.setCookie('last_name', auth.last_name);
    this._sessionService.setCookie('username', auth.username);
    this._sessionService.setCookie('email', auth.email);
    this._sessionService.setCookie('role_id', auth.role.id);
    this._sessionService.setCookie('role_name', auth.role.name);
    this._sessionService.setCookie('is_owner', auth.role.is_owner);
    this._sessionService.setCookie('address_country_id', auth.organization.address_country_id);
    this._sessionService.setCookie('user_hash', auth.intercom_user_hash);
    if (auth?.users) {
      this._sessionService.setCookie('users', auth.users);
    }
    this._sessionService.setCookie('date_joined', auth.date_joined);
  }

  public setAdditionalCookiesForGTM(rights: any): void {
    let rightsObj = rights;
    if (typeof rights === "string") {
      rightsObj = JSON.parse(rights);
    }
    const products = this.getUserProducts(rightsObj);
    const sports = this.getUserSports(rightsObj);
    this._sessionService.setCookie('products', products);
    this._sessionService.setCookie('sports', sports);
  }

  public setAuthAndGoToMainPage(response: any, isSessionExpired: boolean = false): void {
    response.photo = "";
    this.auth = response;
    const getSettingsTask: Subscription | undefined = this.getSettings();
    const getRoleTask = this.getRole(this.auth.role.id, isSessionExpired);
    Promise.all([getRoleTask, getSettingsTask])
      .then((): void => {
        this._sessionService.setCookie('auth', JSON.stringify(this.auth));
        timer(1000)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((): void => {
            this.treeViewAdmin.nativeElement.dispatchEvent(new Event('mouseenter'));
          }
        );
      });
  }

  private getSession(apiService: any) {
    apiService
      .get(AppConstants.API.SETTINGS.GET_CONFIGURATION, true, false)
      .pipe(
        catchError((error) => {
          throw error;
        })
      )
      .subscribe((res: any) => {
        const ideTime = res?.session_idle_time || 3600;
        localStorage.setItem(
          AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME_UNIT,
          ideTime
        );
      });
  }

  private setIdeTime() {
    const ideTimeUnit = localStorage.getItem(
      AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME_UNIT
    );

    if (ideTimeUnit) {
      const ideTime = moment().add(Number(ideTimeUnit) - 60, 'seconds');
      localStorage.setItem(
        AppConstants.LOCAL_STORAGE_KEYS.IDE_TIME,
        ideTime.format(AppConstants.DATE_FORMAT.DATE_TIME_MOMENT)
      );
    }
  }
  getRole(role_id: number, isSessionExpired: boolean = false,): void {
    const api: string = String.Format('rights/{0}/', role_id);
    const parseError: string = 'Parse rights error. Use empty right';
    const roleError: string = 'User role not found';

    this._apiService.get(api, false)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
      next: (response: Object): void => {
        const data: IRole = response as IRole;
        if(response) {
          try {
            localStorage.setItem('rights', data.right_management);
            const returnedPath: string | null = this.getReturnPath();

            if (isSessionExpired && returnedPath) {
              this.navigateToUrl(returnedPath);
            } else {
              this.navigateToHomePage();
            }
          } catch (error) {
            this.snackBar.open(parseError, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          }
        } else {
          this.snackBar.open(roleError, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      },
      error: (error): void => {
        this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      },
      complete: (): void => {
        this.removeReturnPath();
      },
    });
  }

  public getSettings(): Subscription | undefined {
    if(this.auth.role.is_owner){
      const api: string = 'settings/1/';
      return this._apiService.get(api, false)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response: Object): void => {
        const data: ISettings = response as ISettings;
        if (response) {
          this._sessionService.setCookie('watermark_photo', data.watermark_photo);
          if (data.watermark_photo) {
            this.setWatermarkCss(data.watermark_photo, this);
          }
        } else {
          this.snackBar.open("System settings not found", 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      }, error => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      });
    }
   return;
  }

  public setWatermarkCss(watermark_photo: string, scope: any): void {
    if(watermark_photo) {
      const imgUrl: string = watermark_photo.replace('media/uploads', 'dist');
      const maxWidth: number = 200;
      const maxHeight: number = 32;
      const mcMaxWidth: number = 100;
      const mcMaxHeight: number = 16;
      const tmpImg: HTMLImageElement = new Image();
      tmpImg.src = imgUrl;
      tmpImg.onload = (): void => {
        const imgWidth: number = tmpImg.width;
        const imgHeight: number = tmpImg.height;

        const waterMarkRatio: number = Math.max(maxWidth / imgWidth, maxHeight / imgHeight);
        const dimension = {
          width: imgWidth * waterMarkRatio,
          height: imgHeight * waterMarkRatio
        };
        const mcWaterMarkRatio: number = Math.max(mcMaxWidth / imgWidth, mcMaxHeight / imgHeight);
        const mcDimension = {
          width: imgWidth * mcWaterMarkRatio,
          height: imgHeight * mcWaterMarkRatio
        };

        const watermarkCss: string = String.Format(AppConstants.WATERMARK_PHOTO_CSS, imgUrl, dimension.width, dimension.height);
        const mcWatermarkCss: string = String.Format(AppConstants.METABOLIC_CAPACITIES_WATERMARK_CSS, mcDimension.width, mcDimension.height);
        scope.waterMarkCss = watermarkCss + mcWatermarkCss;
        scope.waterMarkHeight = imgHeight;
        scope.waterMarkWidth = imgWidth;

        localStorage.setItem('watermarkCss', scope.waterMarkCss);

        }; tmpImg.onerror = (): void => {
          scope.waterMarkCss = String.Format(AppConstants.WATERMARK_PHOTO_CSS, imgUrl, maxWidth, maxHeight);
        };
    }
  }

  private getUserProducts(rightsObj: Record<string, any>): string {
    const mapping = {
      ppd: "test_create_critical_power_test",
      lactate: "test_create_lactate",
    };
    const products = [];
    if (rightsObj[mapping.ppd]) {
      products.push("ppd");
    }
    if (rightsObj[mapping.lactate]) {
      products.push("lactate");
    }
    return products.join("_and_");
  }

  private getUserSports(rightsObj: Record<string, any>): string {
    const mapping = {
      cycling: "sport_can_use_id_50_cycling",
      running: "sport_can_use_id_53_running",
      "swimming-freestyle": "sport_can_use_id_5_swimming_-_freestyle",
      "triathlon-running": "sport_can_use_id_56_triathlon-running",
      "triathlon-cycling": "sport_can_use_id_57_triathlon-cycling",
    };
    const sports = [];
    for (const [key, value] of Object.entries(mapping)) {
      if (rightsObj[value]) {
        sports.push(key);
      }
    }
    return sports.join("_and_");
  }
}
