import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ManageOrganizationsService } from "@core/services/manage-organizations/manage-organizations.service";
import { AppConstants } from "@core/constants";
import { HeadResultDto } from "@core/services/manage-organizations/head-dto";
import { Location } from "@angular/common";
import countries from '@shared/json/countries.json';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private location: Location = inject(Location);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private organizationService: ManageOrganizationsService = inject(ManageOrganizationsService);
  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public heads: Array<any> = [];
  public countries: Array<any> = [];
  public invoice_countries: Array<any> = [];
  public isChecked: boolean = false;
  public key: string = '';
  public orgForm: UntypedFormGroup;
  public imgFile: any;
  public organization: any = this.activatedRoute.snapshot.data.data;
  public temporaryPhoto: string | ArrayBuffer | null | undefined;

  public ngOnInit(): void {
    this.countries = countries;
    this.invoice_countries = countries;
    this.loadHeads();

    this.initForm();

    if (this.organization.is_use_invoice_address == true) {
      this.isChecked = true;
      this.setSameValues();
    } else {
      this.isChecked = false;
    }
  }

  public back(): void {
    this.location.back();
  }

  public initForm(): void {
    this.temporaryPhoto = this.organization?.photo;
    this.orgForm = this.fb.group({
      photo: new UntypedFormControl(this.organization?.photo),
      is_active: new UntypedFormControl(this.organization?.is_active),
      address_country_id: new UntypedFormControl(''),
      country: new UntypedFormControl(this.organization.country.id, Validators.required),
      invoice_country: new UntypedFormControl(this.organization?.invoice_country ? this.organization?.invoice_country.id : '', Validators.required),
      name: new UntypedFormControl(this.organization.name, Validators.required),
      address_street_name: new UntypedFormControl(this.organization.address_street_name),
      address_street_number: new UntypedFormControl(this.organization.address_street_number),
      address_zip: new UntypedFormControl(this.organization.address_zip),
      address_city: new UntypedFormControl(this.organization.address_city),
      address_state: new UntypedFormControl(this.organization.address_state),
      vat_number: new UntypedFormControl(this.organization.vat_number),
      is_use_invoice_address: new UntypedFormControl(this.organization.is_use_invoice_address),
      invoice_org_name: new UntypedFormControl(this.organization.invoice_org_name, Validators.required),
      invoice_address_street_name: new UntypedFormControl(this.organization.invoice_address_street_name),
      invoice_address_street_number: new UntypedFormControl(this.organization.invoice_address_street_number),
      invoice_address_zip: new UntypedFormControl(this.organization.invoice_address_zip),
      invoice_address_city: new UntypedFormControl(this.organization.invoice_address_city),
      invoice_address_state: new UntypedFormControl(this.organization.invoice_address_state),
      invoice_vat_number: new UntypedFormControl(this.organization.invoice_vat_number),
      phone: new UntypedFormControl(this.organization.phone),
      phone2: new UntypedFormControl(this.organization.phone2),
      fax: new UntypedFormControl(this.organization.fax),
      email: new UntypedFormControl(this.organization.email),
      email2: new UntypedFormControl(this.organization.email2),
      website: new UntypedFormControl(this.organization.website),
      facebook: new UntypedFormControl(this.organization.facebook),
      instagram: new UntypedFormControl(this.organization.instagram),
      account_type: new UntypedFormControl(this.organization.account_type),
      head: new UntypedFormControl(this.organization.head),
    });
  }

  public save(): void {
    if (this.orgForm.invalid) {
      this.orgForm.markAllAsTouched();
      this.snackBar.open('Please check the fields and try again.', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return;
    }

    this.orgForm.value.photo = this.imgFile ? this.imgFile : undefined;
    this.orgForm.value.api = this.orgForm?.value?.api ? this.orgForm?.value?.api.map((a: any) => a.value) : [];
    this.orgForm.value.address_country_id = this.orgForm?.value.country;

    this.organizationService
      .update({ ...this.orgForm.value, id: this.organization.id })
      .subscribe(() => {
        this.snackBar.open('Account info has been successfully saved', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      });
  }

  public onChangeDefault(event: any): void {
    if (event.checked) {
      this.isChecked = true;
      this.setSameValues();
    } else {
      this.isChecked = false;
      this.setEmptyFields();
    }
  }

  public setEmptyFields(): void {
    this.orgForm.controls['invoice_org_name'].setValue('');
    this.orgForm.controls['invoice_address_street_name'].setValue('');
    this.orgForm.controls['invoice_address_street_number'].setValue('');
    this.orgForm.controls['invoice_address_zip'].setValue('');
    this.orgForm.controls['invoice_address_city'].setValue('');
    this.orgForm.controls['invoice_address_state'].setValue('');
    this.orgForm.controls['invoice_vat_number'].setValue('');
    this.orgForm.controls['invoice_country'].setValue('');
  }

  public setSameValues(): void {
    this.orgForm.controls['invoice_org_name'].setValue(
      this.orgForm.controls['name'].value
    );
    this.orgForm.controls['invoice_address_street_name'].setValue(
      this.orgForm.controls['address_street_name'].value
    );
    this.orgForm.controls['invoice_address_street_number'].setValue(
      this.orgForm.controls['address_street_number'].value
    );
    this.orgForm.controls['invoice_address_zip'].setValue(
      this.orgForm.controls['address_zip'].value
    );
    this.orgForm.controls['invoice_address_city'].setValue(
      this.orgForm.controls['address_city'].value
    );
    this.orgForm.controls['invoice_address_state'].setValue(
      this.orgForm.controls['address_state'].value
    );
    this.orgForm.controls['invoice_vat_number'].setValue(
      this.orgForm.controls['vat_number'].value
    );
    this.orgForm.controls['invoice_country'].setValue(
      this.orgForm.controls['country'].value
    );
  }

  public loadHeads(): void {
    this.organizationService
      .getHead(this.organization.id)
      .subscribe((res: HeadResultDto): void => {
        this.heads = res.items;
      });
  }

  public changeImage(event: any): void {
    const files: File[] = event.target.files;
    if (files.length > 1 || !files.length) {
      event.target.value = '';
    }
    const readFile = async (file: File): Promise<void> => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.orgForm.controls.photo.setValue(e.target?.result);
        this.temporaryPhoto = e.target?.result;
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    };
    if (files.length) {
      if (files[0]?.size <= AppConstants.ORGANIZATION_PHOTO_MAX_SIZE) {
        this.imgFile = files[0];
        readFile(files[0]);
      } else {
        event.target.value = '';
        this.orgForm.controls.photo.setValue('');
        this.cdr.detectChanges();
        this.snackBar.open('Photo size must be less than 100KB', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    }
  }

  public onChangeName(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_org_name'].setValue(event.target.value);
    }
  }

  public onChangeStreet(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_street_name'].setValue(
        event.target.value
      );
    }
  }

  public onChangeNumber(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_street_number'].setValue(
        event.target.value
      );
    }
  }

  public onChangeZip(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_zip'].setValue(event.target.value);
    }
  }

  public onChangeCity(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_city'].setValue(
        event.target.value
      );
    }
  }

  public onChangeState(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_address_state'].setValue(
        event.target.value
      );
    }
  }

  public onChangeVat(event: any): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_vat_number'].setValue(event.target.value);
    }
  }

  public onChangeCountry(): void {
    if (this.isChecked) {
      this.orgForm.controls['invoice_country'].setValue(
        this.orgForm.controls['country'].value
      );
    }
  }
}
