import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { InvoiceDto, InvoiceResultDto } from './invoice-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from "@shared/models/api-request";

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto | any, filterUrl: string): Observable<InvoiceResultDto> {
    const apiUrl: string = this._appConstants.API.INVOICES.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl + filterUrl).pipe(
      map((res: any) => {
        return new InvoiceResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: InvoiceDto) => item),
        });
      })
    );
  }

  public downloadPdf(id: number): Observable<any> {
    const api: string = String.Format(this._appConstants.API.INVOICES.DOWNLOAD, id);

    return this._apiService.getFile(api);
  }

  public downloadSelectedInvoice(data: any, request: PagedAndSortedRequestDto | any, filterUrl: string): Observable<any> {
    const apiUrl: string = this._appConstants.API.INVOICES.BULK_DOWNLOAD;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.post(apiRequest.apiUrl + filterUrl, data);
  }

  public retry(invoice_id: number): Observable<Object> {
    const api: string = this._appConstants.API.INVOICES.RETRY;

    return this._apiService.post(api, { invoice_id });
  }

  public updateStatus(status: number, invoiceId: number): Observable<Object> {
    const api: string = this._appConstants.API.INVOICES.UPDATE_STATUS;

    return this._apiService.post(api, { invoice_id: invoiceId, status });
  }

  public purchaseInvoice(invoice_id: number): Observable<Object> {
    const api: string = this._appConstants.API.INVOICES.PURCHASE_INVOICE;

    return this._apiService.post(api, { invoice_id });
  }
}
