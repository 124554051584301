import { EntityDto } from '@shared/models/entity-dto';

export class SportDto extends EntityDto {
  public id: number | undefined;
  public name: string | undefined;
  public description: string | undefined;
  public simulation_type: number | undefined;
  public at_weight: number | undefined;
  public created_at: string | undefined;
  public ebg_conditions: string | undefined;
  public ebg_factor_a: number | undefined;
  public ebg_factor_b: number | undefined;
  public ebg_factor_d: number | undefined;
  public is_delete: boolean | undefined;
  public max_value: number | undefined;
  public min_value: number | undefined;
  public muscle_usage_percentage: number | undefined;
  public o2_per_watt_eq_a: number | undefined;
  public o2_per_watt_eq_b: number | undefined;
  public o2_per_watt_eq_d: number | undefined;
  public primary_type: string | undefined;
  public secondary_type: string | undefined;
  public settings: string | undefined;
  public sport_settings: string | undefined;
  public updated_at: string | undefined;
  public use_in_ppd: boolean | undefined;
  public vl_weight: number | undefined;
  public vo_weight: number | undefined;
  public simulation_type_text: string | undefined;
  public reference_system_settings:
    | {
        created_at: string | undefined;
        created_by: number | undefined;
        id: number | undefined;
        is_delete: boolean | undefined;
        muscle_usage_percentage: number | undefined;
        organization: number | undefined;
        primary_max: string | undefined;
        primary_min: string | undefined;
        primary_type: number | undefined;
        secondary_max: string | undefined;
        secondary_min: string | undefined;
        secondary_type: number | undefined;
        sport: number | undefined;
        updated_at: string | undefined;
      }
    | undefined;
  public training_level: Array<number> | undefined;
  public duration_steady_state: number | undefined;

  public deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  public clone(): SportDto {
    return new SportDto().deserialize(this);
  }
}
