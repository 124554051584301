export const SPEED_DROPDOWN_Y_AXIS_CONFIG = {
  optionList: [
    {
      name: 'Recovery Index',
      slug: 'recovery_index',
      datapoint: 'data_point-0',
      value: 'recovery_index__data_point-0',
    },
    {
      name: 'Accumulation Index',
      slug: 'accumulation_index',
      datapoint: 'data_point-0',
      value: 'accumulation_index__data_point-0',
    },
    {
      name: 'W` - Absolute [kJ]',
      slug: 'w_prime',
      datapoint: 'data_point-1',
      value: 'w_prime__data_point-1',
    },
    {
      name: 'W` - Relative [kJ/kg]',
      slug: 'w_prime',
      datapoint: 'data_point-2',
      value: 'w_prime__data_point-2',
    },
    {
      name: 'Max Lactate [mmol/l]',
      slug: 'max_lactate',
      datapoint: 'data_point-1',
      value: 'max_lactate__data_point-1',
    },
    {
      name: 'Max Heart Rate - Maximum [bpm]',
      slug: 'max_hr',
      datapoint: 'data_point-1',
      value: 'max_hr__data_point-1',
    },
    {
      name: 'Max Heart Rate - PWC150 [{0}]',
      slug: 'max_hr',
      datapoint: 'data_point-2',
      value: 'max_hr__data_point-2',
    },
    {
      name: 'Economy [J/m]',
      slug: 'economy',
      datapoint: 'data_point-1',
      value: 'economy__data_point-1',
    },
    {
      name: 'LT1 [{0}]',
      slug: 'lt1',
      datapoint: 'data_point-1',
      value: 'lt1__data_point-1',
    },
    {
      name: 'LT1 [{1}]',
      slug: 'lt1',
      datapoint: 'data_point-2',
      value: 'lt1__data_point-2',
    },
    {
      name: 'VO2Max - Absolute [ml/min]',
      slug: 'vo2max',
      datapoint: 'data_point-1',
      value: 'vo2max__data_point-1',
    },
    {
      name: 'VO2Max - Relative [ml/min/kg]',
      slug: 'vo2max',
      datapoint: 'data_point-2',
      value: 'vo2max__data_point-2',
    },
    {
      name: 'Vlamax [mmol/l/s]',
      slug: 'vlamax',
      datapoint: 'data_point-1',
      value: 'vlamax__data_point-1',
    },
    {
      name: 'Anaerobic Threshold - MLSS [{0}]',
      slug: 'anaerobic_threshold',
      datapoint: 'data_point-1',
      value: 'anaerobic_threshold__data_point-1',
    },
    {
      name: 'Anaerobic Threshold - MLSS [{1}]',
      slug: 'anaerobic_threshold',
      datapoint: 'data_point-2',
      value: 'anaerobic_threshold__data_point-2',
    },
    {
      name: 'Anaerobic Threshold - % VO2max [%]',
      slug: 'anaerobic_threshold',
      datapoint: 'data_point-3',
      value: 'anaerobic_threshold__data_point-3',
    },
    {
      name: 'Fat Max - MFO absolute [kcal/h]',
      slug: 'fat_max',
      datapoint: 'data_point-1',
      value: 'fat_max__data_point-1',
    },
    {
      name: 'Fat Max - MFO relative [kcal/h/kg]',
      slug: 'fat_max',
      datapoint: 'data_point-2',
      value: 'fat_max__data_point-2',
    },
    {
      name: 'Fat Max - FatMax [{0}]',
      slug: 'fat_max',
      datapoint: 'data_point-3',
      value: 'fat_max__data_point-3',
    },
    {
      name: 'Carb Max - Carbmax [{0}]',
      slug: 'carb_max',
      datapoint: 'data_point-1',
      value: 'carb_max__data_point-1',
    },
    {
      name: 'Carb Max - Carbmax [{1}]',
      slug: 'carb_max',
      datapoint: 'data_point-2',
      value: 'carb_max__data_point-2',
    },
    {
      name: 'Available Glycogen - Absolute [g]',
      slug: 'available_glycogen',
      datapoint: 'data_point-1',
      value: 'available_glycogen__data_point-1',
    },
    {
      name: 'Available Glycogen - Relative [g/kg]',
      slug: 'available_glycogen',
      datapoint: 'data_point-2',
      value: 'available_glycogen__data_point-2',
    },
    {
      name: 'Tau [s]',
      slug: 'vo2_tau',
      datapoint: 'data_point-1',
      value: 'vo2_tau__data_point-1',
    },
    {
      name: 'Average Power [{0}]',
      slug: 'avg_power',
      datapoint: 'data_point-1',
      value: 'avg_power__data_point-1',
    },
    {
      name: 'Average Power [{1}]',
      slug: 'avg_power',
      datapoint: 'data_point-2',
      value: 'avg_power__data_point-2',
    },
    {
      name: 'Maximum Power [{0}]',
      slug: 'max_power',
      datapoint: 'data_point-1',
      value: 'max_power__data_point-1',
    },
    {
      name: 'Maximum Power [{1}]',
      slug: 'max_power',
      datapoint: 'data_point-2',
      value: 'max_power__data_point-2',
    },
    {
      name: 'Body Fat [%]',
      slug: 'body_fat',
      datapoint: 'data_point-1',
      value: 'body_fat__data_point-1',
    },
    {
      name: 'MMSS [{0}]',
      slug: 'MMSS',
      datapoint: 'data_point-1',
      value: 'MMSS__data_point-1',
    },
    {
      name: 'MMSS [{1}]',
      slug: 'MMSS',
      datapoint: 'data_point-2',
      value: 'MMSS__data_point-2',
    },
    {
      name: 'MMSS - % VO2max [%]',
      slug: 'MMSS',
      datapoint: 'data_point-3',
      value: 'MMSS__data_point-3',
    },
  ]
}
export const POWER_DROPDOWN_Y_AXIS_CONFIG = {
  optionList: [
    {
      name: 'Recovery Index',
      slug: 'recovery_index',
      datapoint: 'data_point-0',
      value: 'recovery_index__data_point-0',
    },
    {
      name: 'Accumulation Index',
      slug: 'accumulation_index',
      datapoint: 'data_point-0',
      value: 'accumulation_index__data_point-0',
    },
    {
      name: 'W` - Absolute [kJ]',
      slug: 'w_prime',
      datapoint: 'data_point-1',
      value: 'w_prime__data_point-1',
    },
    {
      name: 'W` - Relative [kJ/kg]',
      slug: 'w_prime',
      datapoint: 'data_point-2',
      value: 'w_prime__data_point-2',
    },
    {
      name: 'Max Lactate [mmol/l]',
      slug: 'max_lactate',
      datapoint: 'data_point-1',
      value: 'max_lactate__data_point-1',
    },
    {
      name: 'Max Heart Rate - Maximum [bpm]',
      slug: 'max_hr',
      datapoint: 'data_point-1',
      value: 'max_hr__data_point-1',
    },
    {
      name: 'Max Heart Rate - PWC150 [Watt]',
      slug: 'max_hr',
      datapoint: 'data_point-2',
      value: 'max_hr__data_point-2',
    },
    {
      name: 'Economy',
      slug: 'economy',
      datapoint: 'data_point-1',
      value: 'economy__data_point-1',
    },
    {
      name: 'LT1 - Absolute [Watt]',
      slug: 'lt1',
      datapoint: 'data_point-1',
      value: 'lt1__data_point-1',
    },
    {
      name: 'LT1 - Relative [Watt/kg]',
      slug: 'lt1',
      datapoint: 'data_point-2',
      value: 'lt1__data_point-2',
    },
    {
      name: 'VO2Max - Absolute [ml/min]',
      slug: 'vo2max',
      datapoint: 'data_point-1',
      value: 'vo2max__data_point-1',
    },
    {
      name: 'VO2Max - Relative [ml/min/kg]',
      slug: 'vo2max',
      datapoint: 'data_point-2',
      value: 'vo2max__data_point-2',
    },
    {
      name: 'Vlamax [mmol/l/s]',
      slug: 'vlamax',
      datapoint: 'data_point-1',
      value: 'vlamax__data_point-1',
    },
    {
      name: 'Anaerobic Threshold - Absolute [Watt]',
      slug: 'anaerobic_threshold',
      datapoint: 'data_point-1',
      value: 'anaerobic_threshold__data_point-1',
    },
    {
      name: 'Anaerobic Threshold - Relative [Watt/kg]',
      slug: 'anaerobic_threshold',
      datapoint: 'data_point-2',
      value: 'anaerobic_threshold__data_point-2',
    },
    {
      name: 'Anaerobic Threshold - % VO2max [%]',
      slug: 'anaerobic_threshold',
      datapoint: 'data_point-3',
      value: 'anaerobic_threshold__data_point-3',
    },
    {
      name: 'Fat Max - MFO absolute [kcal/h]',
      slug: 'fat_max',
      datapoint: 'data_point-1',
      value: 'fat_max__data_point-1',
    },
    {
      name: 'Fat Max - MFO relative [kcal/h/kg]',
      slug: 'fat_max',
      datapoint: 'data_point-2',
      value: 'fat_max__data_point-2',
    },
    {
      name: 'Fat Max - FatMax [Watt]',
      slug: 'fat_max',
      datapoint: 'data_point-3',
      value: 'fat_max__data_point-3',
    },
    {
      name: 'Carb Max - Absolute [Watt]',
      slug: 'carb_max',
      datapoint: 'data_point-1',
      value: 'carb_max__data_point-1',
    },
    {
      name: 'Carb Max - Relative [Watt/kg]',
      slug: 'carb_max',
      datapoint: 'data_point-2',
      value: 'carb_max__data_point-2',
    },
    {
      name: 'Available Glycogen - Absolute [g]',
      slug: 'available_glycogen',
      datapoint: 'data_point-1',
      value: 'available_glycogen__data_point-1',
    },
    {
      name: 'Available Glycogen - Relative [g/kg]',
      slug: 'available_glycogen',
      datapoint: 'data_point-2',
      value: 'available_glycogen__data_point-2',
    },
    {
      name: 'Tau [s]',
      slug: 'vo2_tau',
      datapoint: 'data_point-1',
      value: 'vo2_tau__data_point-1',
    },
    {
      name: 'Average Power - Absolute [Watt]',
      slug: 'avg_power',
      datapoint: 'data_point-1',
      value: 'avg_power__data_point-1',
    },
    {
      name: 'Average Power - Relative [Watt/kg]',
      slug: 'avg_power',
      datapoint: 'data_point-2',
      value: 'avg_power__data_point-2',
    },
    {
      name: 'Maximum Power - Absolute [Watt]',
      slug: 'max_power',
      datapoint: 'data_point-1',
      value: 'max_power__data_point-1',
    },
    {
      name: 'Maximum Power - Relative [Watt/kg]',
      slug: 'max_power',
      datapoint: 'data_point-2',
      value: 'max_power__data_point-2',
    },
    {
      name: 'MLSS - Absolute [Watt]',
      slug: 'MLSS',
      datapoint: 'data_point-1',
      value: 'MLSS__data_point-1',
    },
    {
      name: 'MLSS - Relative [Watt/kg]',
      slug: 'MLSS',
      datapoint: 'data_point-2',
      value: 'MLSS__data_point-2',
    },
    {
      name: 'MLSS - % VO2max [%]',
      slug: 'MLSS',
      datapoint: 'data_point-3',
      value: 'MLSS__data_point-3',
    },
    {
      name: 'Body Fat [%]',
      slug: 'body_fat',
      datapoint: 'data_point-1',
      value: 'body_fat__data_point-1',
    },
    {
      name: 'MMSS - Absolute [Watt]',
      slug: 'MMSS',
      datapoint: 'data_point-1',
      value: 'MMSS__data_point-1',
    },
    {
      name: 'MMSS - Relative [Watt/kg]',
      slug: 'MMSS',
      datapoint: 'data_point-2',
      value: 'MMSS__data_point-2',
    },
    {
      name: 'MMSS - % VO2max [%]',
      slug: 'MMSS',
      datapoint: 'data_point-3',
      value: 'MMSS__data_point-3',
    },
  ]
};
