import { inject, Injectable } from "@angular/core";
import { HelperService } from "@shared/services/helper.service";
import { VLAMAX, BODY_FAT, VO2MAX, ECONOMY, BUFFERING_CAPACITY } from './range-sliders.constant';
import { IMetricControlConfig } from "@shared/interfaces/metric-control-config.interface";
import { BackEndMetricPropertyEnum } from "@shared/enums/back-end-metric-property.enum";
import { METRIC_PROPERTY_NAME_ENUM } from "@shared/enums/metric-property-name.enum";
import { METRIC_UNIT_ENUM } from "@shared/enums/metric-unit.enum";
import { METRIC_NAME_POSTFIX_ENUM } from "@shared/enums/metric-name-postfix.enum";
import { METRIC_NAME_ENUM } from "@shared/enums/metric-name.enum";
import { IMinMaxSlider } from "@shared/interfaces/min-max-range-slider.interface";
import { IMetricControlRange } from "@shared/interfaces/metric-control-range.interface";
import { InitialValuesInterface } from "@shared/interfaces/initial-values.interface";

@Injectable()
export class PerformanceProjectionControlsService {
  public helperService: HelperService = inject(HelperService);
  public NOT_AVAILABLE: string = 'N/A';
  public STEP_BY_NUMBER_RANGE_SLIDER: string = '1';
  public STEP_BY_FLOAT_RANGE_SLIDER: string = '0.01';
  public metricControlConfig: IMetricControlConfig[] = [
    {
      id: 1,
      backEndProperty: BackEndMetricPropertyEnum.BODY_FAT,
      metricPropertyName: METRIC_PROPERTY_NAME_ENUM.BODY_FAT,
      currentValue: undefined,
      unit: METRIC_UNIT_ENUM.PERCENTAGE,
      fallbackCurrentValue: this.NOT_AVAILABLE,
      metricName: METRIC_NAME_ENUM.BODY_FAT,
      metricNamePostfix: '',
      slider: {
        min: undefined,
        max: undefined,
      },
      visibility: true,
      step: this.STEP_BY_NUMBER_RANGE_SLIDER,
      currentMetricValue: undefined,
      currentData: {
        min: undefined,
        max: undefined,
      },
    },
    {
      id: 2,
      backEndProperty: BackEndMetricPropertyEnum.VO2MAX,
      metricPropertyName: METRIC_PROPERTY_NAME_ENUM.VO2MAX,
      unit: METRIC_UNIT_ENUM.VO2MAX,
      currentValue: undefined,
      fallbackCurrentValue: this.NOT_AVAILABLE,
      metricName: METRIC_NAME_ENUM.VO,
      metricNamePostfix: METRIC_NAME_POSTFIX_ENUM.TWO_MAX,
      slider: {
        min: undefined,
        max: undefined,
      },
      visibility: false,
      step: this.STEP_BY_FLOAT_RANGE_SLIDER,
      currentMetricValue: undefined,
      currentData: {
        min: undefined,
        max: undefined,
      },
    },
    {
      id: 3,
      backEndProperty: BackEndMetricPropertyEnum.VLAMAX,
      metricPropertyName: METRIC_PROPERTY_NAME_ENUM.VLAMAX,
      unit: METRIC_UNIT_ENUM.VLAMAX,
      currentValue: undefined,
      fallbackCurrentValue: this.NOT_AVAILABLE,
      metricName: METRIC_NAME_ENUM.VLA,
      metricNamePostfix: METRIC_NAME_POSTFIX_ENUM.MAX,
      slider: {
        min: undefined,
        max: undefined,
      },
      visibility: false,
      step: this.STEP_BY_FLOAT_RANGE_SLIDER,
      currentMetricValue: undefined,
      currentData: {
        min: undefined,
        max: undefined,
      },
    },
    {
      id: 4,
      backEndProperty: BackEndMetricPropertyEnum.ECONOMY,
      metricPropertyName: METRIC_PROPERTY_NAME_ENUM.ECONOMY,
      unit: METRIC_UNIT_ENUM.ECONOMY,
      currentValue: undefined,
      fallbackCurrentValue: this.NOT_AVAILABLE,
      metricName: METRIC_NAME_ENUM.ECONOMY,
      metricNamePostfix: '',
      slider: {
        min: undefined,
        max: undefined,
      },
      visibility: false,
      step: this.STEP_BY_FLOAT_RANGE_SLIDER,
      currentMetricValue: undefined,
      currentData: {
        min: undefined,
        max: undefined,
      },
    },
    {
      id: 5,
      backEndProperty: BackEndMetricPropertyEnum.BUFFERING_CAPACITY,
      metricPropertyName: METRIC_PROPERTY_NAME_ENUM.BUFFERING_CAPACITY,
      unit: METRIC_UNIT_ENUM.BUFFERING_CAPACITY,
      currentValue: undefined,
      fallbackCurrentValue: this.NOT_AVAILABLE,
      metricName: METRIC_NAME_ENUM.BUFFERING_CAPACITY,
      metricNamePostfix: '',
      slider: {
        min: undefined,
        max: undefined,
      },
      visibility: false,
      step: this.STEP_BY_FLOAT_RANGE_SLIDER,
      currentMetricValue: undefined,
      currentData: {
        min: undefined,
        max: undefined,
      },
    },
  ];

  public getSliderConfig(test: any, initialValues: any, backendProperty: string, metricType: IMetricControlRange): IMinMaxSlider {
    let twoDecimalValue: number;
    if (test[backendProperty]) {
      twoDecimalValue = +test[backendProperty].toFixed(2);
    } else {
      twoDecimalValue = +parseFloat(initialValues[backendProperty]["value"]).toFixed(2);
    }
    const min: number = twoDecimalValue - metricType.plusMinus;
    const max: number = twoDecimalValue + metricType.plusMinus;
    let sliderObject: IMinMaxSlider = {};

    if (min >= metricType.lower && max <= metricType.upper) {
      sliderObject = {
        min,
        max,
      };
    } else if (min < metricType.lower && max <= metricType.upper) {
      sliderObject = {
        min: metricType.lower,
        max: max,
      };
    } else if (min >= metricType.lower && max > metricType.upper) {
      sliderObject = {
        min: min,
        max: metricType.upper,
      };
    } else {
      sliderObject = {
        min: metricType.lower,
        max: metricType.upper,
      };
    }

    return sliderObject;
  }

  public getMetricControlConfigBasedOnTest(test: any, initialValues: InitialValuesInterface): IMetricControlConfig[] {
    const copyMetricControl: IMetricControlConfig[] = this.helperService.makeCopy<IMetricControlConfig[]>(this.metricControlConfig);

    copyMetricControl.forEach((metricItem: IMetricControlConfig): void => {
      if (this.helperService.hasProperty(test, metricItem.backEndProperty) || initialValues.hasOwnProperty(metricItem.backEndProperty)) {
        if (metricItem.metricPropertyName === METRIC_PROPERTY_NAME_ENUM.BODY_FAT) {
          metricItem.slider = this.getSliderConfig(test, initialValues, metricItem.backEndProperty, BODY_FAT);
        }

        if (metricItem.metricPropertyName === METRIC_PROPERTY_NAME_ENUM.VO2MAX) {
          metricItem.slider = this.getSliderConfig(test, initialValues, metricItem.backEndProperty, VO2MAX);
        }

        if (metricItem.metricPropertyName === METRIC_PROPERTY_NAME_ENUM.VLAMAX) {
          metricItem.slider = this.getSliderConfig(test, initialValues, metricItem.backEndProperty, VLAMAX);
        }

        if (metricItem.metricPropertyName === METRIC_PROPERTY_NAME_ENUM.ECONOMY) {
          metricItem.slider = this.getSliderConfig(test, initialValues, metricItem.backEndProperty, ECONOMY);
        }

        if (metricItem.metricPropertyName === METRIC_PROPERTY_NAME_ENUM.BUFFERING_CAPACITY) {
          metricItem.slider = this.getSliderConfig(test, initialValues, metricItem.backEndProperty, BUFFERING_CAPACITY);
        }

        metricItem['currentMetricValue'] = `${this.getFixedFractionDigits(test, initialValues, metricItem.backEndProperty, 2)}`;
        metricItem['fallbackCurrentValue'] = `${this.getFixedFractionDigits(test, initialValues, metricItem.backEndProperty, 2)}`;
      } else {
        metricItem['currentMetricValue'] = `${this.NOT_AVAILABLE}`;
      }
    });

    return copyMetricControl;
  }

  public getFixedFractionDigits(test: any, initialValues: any, backEndProperty: string, fractionDigits: number): number {
    if (test[backEndProperty]) {
      return +test[backEndProperty].toFixed(fractionDigits);
    } else {
      return +parseFloat(initialValues[backEndProperty]["value"]).toFixed(fractionDigits);
    }
  }

  public getRemovedConfigItemByName(config: IMetricControlConfig[], propertyValue: string): IMetricControlConfig[] {
    return config.filter((item: IMetricControlConfig): boolean => item.metricName !== propertyValue);
  }
}
