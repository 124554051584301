import { Component, inject, Input, OnChanges, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import IMask from "imask";
import { MaximizeConstraintService } from "@shared/services/maximize-constraint.service";
import { HelperService } from "@shared/services/helper.service";
import { InitialValuesInterface } from "@shared/interfaces/initial-values.interface";

@Component({
  selector: 'app-maximize',
  templateUrl: 'maximize.component.html',
  styleUrls: ['./maximize.component.scss']
})
export class MaximizeComponent implements OnChanges {
  @Input() public sportType: number;
  @Input() public systemReference: number;
  @Input() public initialValues: any;

  private helperService: HelperService = inject(HelperService);
  private maximizeConstraintService: MaximizeConstraintService = inject(MaximizeConstraintService);
  public maximizeForm: FormGroup;
  public selectedValue: any = null;
  public selectedValueItem: any = null;
  public isShowToggle: boolean = false;
  public isPowerSport: boolean = false;
  public dataArray: any;
  public powerBasedConfig: any = [
    {
      id: 'anaerobic_threshold',
      tag: 'anaerobic_threshold',
      name: 'Anaerobic Threshold',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'fat_max',
      tag: 'fatmax',
      name: 'FatMax',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'power_speed_static',
      name: 'Power @',
      isShowCarbohydrateInput: true,
      placeholder: 'g/h',
      postfix: 'carbohydrate combustion rate',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'power_speed_dynamic',
      name: 'Power @ duration of',
      placeholder: 'mm:ss.s',
      placeholderOverrideWat: 'Watt',
      isShowOverrideWattInput: true,
      isDisable: false,
      isShow: true,
    },
  ];
  public speedBasedPaceConfig: any = [
    {
      id: 'anaerobic_threshold',
      tag: 'anaerobic_threshold',
      name: 'Anaerobic Threshold',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'fat_max',
      tag: 'fatmax',
      name: 'FatMax ',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'power_speed_static',
      name: 'Pace @ ',
      isShowCarbohydrateInput: true,
      placeholder: 'g/h',
      postfix: 'carbohydrate combustion rate',
      isDisable: false,
      isShow: true,
    },
    {
      id: 'power_speed_dynamic',
      name: 'Time @ distance of ',
      placeholder: 'meters',
      placeholderOverrideWat: 'mm:ss.s',
      isShowOverrideWattInput: true,
      isDisable: false,
      isShow: true,
    },
  ];

  public generateMaximize(): void {
    this.isPowerSport = this.helperService.isPowerBasedSport(this.sportType);

    if (this.helperService.isPowerBasedSport(this.sportType)) {
      this.isShowToggle = true;
      this.dataArray = this.powerBasedConfig;

      this.dataArray.forEach((data: any) => {
        if (this.initialValues.hasOwnProperty(data.tag)) {
          data.name += ` (${this.getOptionName(data.tag)})`
        }
      });

      this.maximizeForm = new FormGroup({
        maximize: new FormControl('', [Validators.required]),
        carbohydrate: new FormControl(''),
        initialValue: new FormControl(''),
        calculatedInitialValue: new FormControl(''),
        overrideValue: new FormControl(''),
        maximizeToggle: new FormControl(true),
      });
    } else if (this.helperService.isSpeedBasedSport(this.sportType)) {
      this.isShowToggle = false;
      this.dataArray = this.speedBasedPaceConfig;

      this.dataArray.forEach((data: any) => {
        if (this.initialValues.hasOwnProperty(data.tag)) {
          data.name += ` (${this.getOptionName(data.tag)})`
        }
      });

      this.maximizeForm = new FormGroup({
        maximize: new FormControl('', [Validators.required]),
        carbohydrate: new FormControl(''),
        initialValue: new FormControl(''),
        calculatedInitialValue: new FormControl(''),
        overrideValue: new FormControl(''),
      });
    }

    this.maximizeConstraintService.constraintComponentState$.subscribe({
      next: (constraint: any): void => {
        this.resetStateDataArray();

        if (this.helperService.isSpeedBasedSport(this.sportType)) {
          this.dataArray.forEach((item: any) => {
            if (constraint.constraints === item.id) {
              item.isDisable = true;
              item.isShow = false;
            }
          });
        } else if (this.helperService.isPowerBasedSport(this.sportType)) {
          if (constraint.constraints === this.maximize?.value && constraint.constraintsToggle === this.maximizeToggle?.value) {
            // TODO: Where is this logic described? It seems a bit arbitrary so disabling for now
            // this.maximizeToggle?.setValue(!this.maximizeToggle?.value);
          }
        }
      },
    });

    this.maximizeForm.get('initialValue')?.valueChanges.subscribe((initialValue: any) => {
      this.maximizeForm.get('calculatedInitialValue')?.setValue(initialValue + 1);
    });

    this.maximizeForm.valueChanges.subscribe({
      next: (formValue: FormGroup): void => {
        this.maximizeConstraintService.maximizeComponentState$.next(formValue);
      }
    });
  }

  public addMaskToOverridePaceInput(): void {
    const el: any = document.getElementById('override-pace-input');

    IMask(el, {mask: '00:00',});
  }

  public ngOnChanges(): void {
    this.generateMaximize();
  }

  public getCarbohydrateCurrentValue(): string {
    const ghValue = this.maximizeForm.get("carbohydrate")?.value;
    return this.maximizeConstraintService.getCarbohydrateCurrentValue(this.initialValues, ghValue);
  }

  public getDynamicPowerInitialValue(): string {
    const dynPowerValue = this.maximizeForm.get("initialValue")?.value;
    const isRelative = this.maximizeForm.get('maximizeToggle')?.value ?? false;
    return this.maximizeConstraintService.getDynamicPowerCurrentValue(this.initialValues, dynPowerValue, this.isPowerSport, isRelative);
  }

  public get maximize(): AbstractControl | null {
    return this.maximizeForm.get('maximize');
  }

  public get maximizeToggle(): AbstractControl | null {
    return this.maximizeForm.get('maximizeToggle');
  }

  public setValue(value: any): void {
    this.selectedValue = value.name;
    this.selectedValueItem = value;
  }

  public getCurrentMaximizeForm(): FormGroup {
    return this.maximizeForm;
  }

  public resetStateDataArray(): void {
    this.dataArray.forEach((item: any) => {
      item.isDisable = false;
      item.isShow = true;
    });
  }

  public get isPaceRef(): boolean {
    return this.helperService.isPaceMinSecKm(this.systemReference) ||
      this.helperService.isPaceMinSecMile(this.systemReference) ||
      this.helperService.isPaceMinSec100Meter(this.systemReference) ||
      this.helperService.isPaceMinSec500Meter(this.systemReference);
  }

  public get isSpeedRef(): boolean {
    return this.helperService.isSpeedMS(this.systemReference) ||
      this.helperService.isSpeedKmH(this.systemReference) ||
      this.helperService.isSpeedMph(this.systemReference) ||
      this.helperService.isSpeedMph(this.systemReference);
  }

  private getOptionName(tag: string): string {
    const dto = this.initialValues[tag]
    if (!dto) {
      return ""
    }

    switch (tag) {
      case "anaerobic_threshold":
        return dto["value"] + " " + dto["units"]
      case "fatmax":
        return dto["reference"] + " " + dto["reference_units"]
      default:
        return ""
    }
  }
}
