export const ChartMappingData = [
  {
    "name": "cla",
    "color": "DodgerBlue",
    "unit": "mmol/l",
    "legend": "lactate concentration",
    "descriptions": "lactic acid concentration",
    "emptyData": ""
  },
  {
    "name": "CLaSS",
    "color": "GoldenRod",
    "unit": "mmol/l",
    "legend": "lactate concentration",
    "descriptions": "lactate concentraion under staedy state conditions",
    "emptyData": ""
  },
  {
    "name": "effvirt_vo2tot",
    "color": "red",
    "unit": "",
    "legend": "",
    "descriptions": "",
    "emptyData": ""
  },
  {
    "name": "pH",
    "color": "Indigo",
    "unit": "",
    "legend": "pH ",
    "descriptions": "",
    "emptyData": ""
  },
  {
    "name": "proz_fatcomb",
    "color": "DarkOliveGreen",
    "unit": "%",
    "legend": "%fat",
    "descriptions": "percentage of fat of total energy demand",
    "emptyData": ""
  },
  {
    "name": "proz_fatcomb_negative",
    "color": "Grey",
    "unit": "%",
    "legend": "%fat_negativ values",
    "descriptions": "-",
    "emptyData": ""
  },
  {
    "name": "proz_fatcombcla",
    "color": "Grey",
    "unit": "%",
    "legend": "%fat_CLa",
    "descriptions": "lactate concentration dependend percentage of fat of total energy demand",
    "emptyData": ""
  },
  {
    "name": "proz_vlamaxph",
    "color": "CornflowerBlue",
    "unit": "%",
    "legend": "%VLamax_pH",
    "descriptions": "percentual, pH dependent activation of glycolysis",
    "emptyData": ""
  },
  {
    "name": "proz_vlass",
    "color": "orange",
    "unit": "%",
    "legend": "%VLa",
    "descriptions": "percentage of carbohydrate of total energy demand",
    "emptyData": ""
  },
  {
    "name": "RQ_vlaoxcla",
    "color": "DarkMagenta",
    "unit": "-",
    "legend": "RER_VLaoxCLa",
    "descriptions": "respiratory exchance ratio based on VLaox and CLa",
    "emptyData": ""
  },
  {
    "name": "RQ_vlaoxmax",
    "color": "DarkMagenta",
    "unit": "-",
    "legend": "RER",
    "descriptions": "respiratory exchance ratio based on VLaoxmax",
    "emptyData": ""
  },
  {
    "name": "vla_net",
    "color": "#e066ff",
    "unit": "mmol/l/min",
    "legend": "la.-accumulation",
    "descriptions": "net lactate accumulation",
    "emptyData": ""
  },
  {
    "name": "vlaox_cla",
    "color": "Chocolate",
    "unit": "mmol/l/min",
    "legend": "VLaoxCLa",
    "descriptions": "lactate acid depended rate of lactate elimination by oxidation, millimoles per minute per kilogram ",
    "emptyData": ""
  },
  {
    "name": "vlaoxmax",
    "color": "#4876ff",
    "unit": "mmol/l/min",
    "legend": "max. aerobic la.-comb.",
    "descriptions": "maximum rate of lactate elimination by oxidation, millimoles per minute per kilogram ",
    "emptyData": ""
  },
  {
    "name": "vlass",
    "color": "#ee2c2c",
    "unit": "mmol/l/min",
    "legend": "la.-production",
    "descriptions": "rate of lactic acid formation / glycolytic rate ",
    "emptyData": ""
  },
  {
    "name": "vms",
    "color": "LightSalmon",
    "unit": "m/s",
    "legend": "speed",
    "descriptions": "velocity in meters per second",
    "emptyData": ""
  },
  {
    "name": "vo2ss",
    "color": "DeepSkyBlue",
    "unit": "ml/min/kg",
    "legend": "oxygen uptake (VO2)",
    "descriptions": "oxygen uptake under steady state conditions",
    "emptyData": ""
  },
  {
    "name": "vo2tot",
    "color": "Blue",
    "unit": "ml/min/kg",
    "legend": "oxygen demand",
    "descriptions": "total required oxygen uptake as a function of the power output",
    "emptyData": ""
  },
  {
    "name": "vo2totvms",
    "color": "OrangeRed",
    "unit": "ml/min/kg",
    "legend": "vo2totvms",
    "descriptions": "",
    "emptyData": ""
  },
  {
    "name": "power",
    "color": "black",
    "unit": "W",
    "legend": "Power",
    "descriptions": "Powe input for bike",
    "emptyData": ""
  },
  {
    "name": "pyru_def",
    "color": "#c1cdcd",
    "unit": "mmol/l/min",
    "legend": "lactate recovery",
    "descriptions": "lack of pyruvate",
    "emptyData": ""
  },
  {
    "name": "en_fat_kJ",
    "color": "DarkGreen",
    "unit": "kJ/h",
    "legend": "fat",
    "descriptions": "fat",
    "emptyData": "YellowGreen"
  },
  {
    "name": "en_carbo_kJ",
    "color": "OrangeRed",
    "unit": "kJ/h",
    "legend": "carbohydrate",
    "descriptions": "carbohydrate",
    "emptyData": "Orange"
  },
  {
    "name": "en_fat_kcal",
    "color": "DarkGreen",
    "unit": "kcal/h",
    "legend": "fat",
    "descriptions": "fat",
    "emptyData": "YellowGreen"
  },
  {
    "name": "en_carbo_kcal",
    "color": "OrangeRed",
    "unit": "kcal/h",
    "legend": "carbohydrate",
    "descriptions": "carbohydrate",
    "emptyData": "Orange"
  },
  {
    "name": "en_fat_g",
    "color": "DarkGreen",
    "unit": "g/h",
    "legend": "fat",
    "descriptions": "fat",
    "emptyData": ""
  },
  {
    "name": "en_carbo_g",
    "color": "OrangeRed",
    "unit": "g/h",
    "legend": " ",
    "descriptions": "carbohydrate",
    "emptyData": ""
  },
  {
    "name": "energy_total_per_kJ_h",
    "color": "red",
    "unit": "kJ/h",
    "legend": "total energy",
    "descriptions": "total energy",
    "emptyData": ""
  },
  {
    "name": "pCO2",
    "color": "DodgerBlue",
    "unit": "-",
    "legend": "pCO2",
    "descriptions": "pCO2",
    "emptyData": ""
  },
  {
    "name": "pHm",
    "color": "black",
    "unit": "-",
    "legend": "pH muscle",
    "descriptions": "pH muscle",
    "emptyData": ""
  },
  {
    "name": "sa",
    "color": "Blue",
    "unit": "-",
    "legend": "sa",
    "descriptions": "sa",
    "emptyData": ""
  },
  {
    "name": "pcr_cyt",
    "color": "Green",
    "unit": "mmol/kg",
    "legend": "CrP",
    "descriptions": "pcr_cyt",
    "emptyData": ""
  },
  {
    "name": "atp_cyt",
    "color": "GoldenRod",
    "unit": "mmol/kg",
    "legend": "ATP",
    "descriptions": "atp_cyt",
    "emptyData": ""
  },
  {
    "name": "adp_cyt",
    "color": "Grey",
    "unit": "mmol/kg",
    "legend": "ADP",
    "descriptions": "adp_cyt",
    "emptyData": ""
  },
  {
    "name": "vlapH",
    "color": "CornflowerBlue",
    "unit": "-",
    "legend": "vlapH",
    "descriptions": "vlapH",
    "emptyData": ""
  },
  {
    "name": "dG0",
    "color": "DarkSlateGray",
    "unit": "kJ/mol",
    "legend": "-dG0",
    "descriptions": "dG0 (negative)",
    "emptyData": ""
  },
  {
    "name": "amp",
    "color": "Green",
    "unit": "-",
    "legend": "amp",
    "descriptions": "amp",
    "emptyData": ""
  },
  {
    "name": "sc",
    "color": "DarkGreen",
    "unit": "-",
    "legend": "sc",
    "descriptions": "sc",
    "emptyData": ""
  },
  {
    "name": "vla_elim1",
    "color": "GreenYellow",
    "unit": "-",
    "legend": "vla_elim1",
    "descriptions": "vla_elim1",
    "emptyData": ""
  },
  {
    "name": "vla_elim2",
    "color": "Chocolate",
    "unit": "-",
    "legend": "vla_elim2",
    "descriptions": "vla_elim2",
    "emptyData": ""
  },
  {
    "name": "vla_elim0",
    "color": "MediumVioletRed",
    "unit": "-",
    "legend": "vla_elim0",
    "descriptions": "vla_elim0",
    "emptyData": ""
  },
  {
    "name": "vlares_M",
    "color": "GreenYellow",
    "unit": "-",
    "legend": "vlares_M",
    "descriptions": "vlares_M",
    "emptyData": ""
  },
  {
    "name": "vlares_B",
    "color": "Green",
    "unit": "-",
    "legend": "vlares_B",
    "descriptions": "vlares_B",
    "emptyData": ""
  },
  {
    "name": "vss_PME",
    "color": "Red",
    "unit": "-",
    "legend": "vss_PME",
    "descriptions": "vss_PME",
    "emptyData": ""
  },
  {
    "name": "vlares_PME",
    "color": "Orange",
    "unit": "-",
    "legend": "vlares_PME",
    "descriptions": "vlares_PME",
    "emptyData": ""
  },
  {
    "name": "pCO2_2",
    "color": "OrangeRed",
    "unit": "-",
    "legend": "pCO2_2",
    "descriptions": "pCO2_2",
    "emptyData": ""
  },
  {
    "name": "p_vo2ss",
    "color": "black",
    "unit": "-",
    "legend": "p_vo2ss",
    "descriptions": "p_vo2ss",
    "emptyData": ""
  },
  {
    "name": "p_vla",
    "color": "DodgerBlue",
    "unit": "-",
    "legend": "p_vla",
    "descriptions": "p_vla",
    "emptyData": ""
  },
  {
    "name": "pi_cyt",
    "color": "DarkBlue",
    "unit": "mmol/kg",
    "legend": "Pi",
    "descriptions": "pi_cyt",
    "emptyData": ""
  },
  {
    "name": "DGOX",
    "color": "Blue",
    "unit": "-",
    "legend": "DGOX",
    "descriptions": "DGOX",
    "emptyData": ""
  },
  {
    "name": "vlaPEP",
    "color": "Indigo",
    "unit": "-",
    "legend": "vlaPEP",
    "descriptions": "vlaPEP",
    "emptyData": ""
  },
  {
    "name": "Lam",
    "color": "Magenta",
    "unit": "mmol/kg",
    "legend": "lactate conc. muscle",
    "descriptions": "La_m",
    "emptyData": ""
  },
  {
    "name": "Lab",
    "color": "SeaGreen",
    "unit": "mmol/kg",
    "legend": "lab",
    "descriptions": "La_b",
    "emptyData": ""
  },
  {
    "name": "lab_per_l",
    "color": "DarkOrchid",
    "unit": "mmol/l",
    "legend": "lactate conc. blood",
    "descriptions": "lactate concentration blood",
    "emptyData": ""
  },
  {
    "name": "PME",
    "color": "Brown",
    "unit": "mmol/kg",
    "legend": "PME",
    "descriptions": "PMEyk4",
    "emptyData": ""
  },
  {
    "name": "empty1",
    "color": "CadetBlue",
    "unit": "-",
    "legend": "empty1",
    "descriptions": "empty1",
    "emptyData": ""
  },
  {
    "name": "empty2",
    "color": "Green",
    "unit": "-",
    "legend": "empty2",
    "descriptions": "empty2",
    "emptyData": ""
  },
  {
    "name": "not_named1",
    "color": "DarkGreen",
    "unit": "-",
    "legend": "(DGOX-dG0)*dGFakt_Mod2",
    "descriptions": "(DGOX-dG0)*dGFakt_Mod2",
    "emptyData": ""
  },
  {
    "name": "empty3",
    "color": "GreenYellow",
    "unit": "-",
    "legend": "empty3",
    "descriptions": "empty3",
    "emptyData": ""
  },
  {
    "name": "not_named2",
    "color": "Chocolate",
    "unit": "-",
    "legend": "100*(vla_elim1+vla_elim2)/(0.01575*vo2max)",
    "descriptions": "100*(vla_elim1+vla_elim2)/(0.01575*vo2max)",
    "emptyData": ""
  },
  {
    "name": "empty4",
    "color": "MediumVioletRed",
    "unit": "-",
    "legend": "empty4",
    "descriptions": "empty4",
    "emptyData": ""
  },
  {
    "name": "x",
    "color": "DarkKhaki",
    "unit": "",
    "legend": "",
    "descriptions": "x",
    "emptyData": ""
  },
  {
    "name": "pload",
    "color": "DimGrey",
    "unit": "pcr_eq",
    "legend": "pload",
    "descriptions": "pload",
    "emptyData": ""
  },
  {
    "name": "yk0",
    "color": "Red",
    "unit": "-",
    "legend": "yk0",
    "descriptions": "yk0",
    "emptyData": ""
  },
  {
    "name": "yk1",
    "color": "Orange",
    "unit": "-",
    "legend": "yk1",
    "descriptions": "yk1",
    "emptyData": ""
  },
  {
    "name": "yk2",
    "color": "OrangeRed",
    "unit": "-",
    "legend": "yk2",
    "descriptions": "yk2",
    "emptyData": ""
  },
  {
    "name": "yk3",
    "color": "black",
    "unit": "-",
    "legend": "yk3",
    "descriptions": "yk3",
    "emptyData": ""
  },
  {
    "name": "result0",
    "color": "DodgerBlue",
    "unit": "-",
    "legend": "result0",
    "descriptions": "result0",
    "emptyData": ""
  },
  {
    "name": "result1",
    "color": "DarkBlue",
    "unit": "-",
    "legend": "result1",
    "descriptions": "result1",
    "emptyData": ""
  },
  {
    "name": "result2",
    "color": "Blue",
    "unit": "-",
    "legend": "result2",
    "descriptions": "result2",
    "emptyData": ""
  },
  {
    "name": "result3",
    "color": "Indigo",
    "unit": "-",
    "legend": "result3",
    "descriptions": "result3",
    "emptyData": ""
  },
  {
    "name": "pload_orig",
    "color": "Silver",
    "unit": "pcr_eq",
    "legend": "pload_orig",
    "descriptions": "pload_orig",
    "emptyData": ""
  },
  {
    "name": "vo2_per_vo2max",
    "color": "Red",
    "unit": "%",
    "legend": "VO2",
    "descriptions": "VO2",
    "emptyData": ""
  },
  {
    "name": "vla_per_vlamax",
    "color": "Red",
    "unit": "%",
    "legend": "Vla",
    "descriptions": "Vla",
    "emptyData": ""
  },
  {
    "name": "pload_wkg",
    "color": "DimGrey",
    "unit": "W/kg",
    "legend": "Watt",
    "descriptions": "P_load",
    "emptyData": ""
  },
  {
    "name": "pload_orig_wkg",
    "color": "Silver",
    "unit": "W/kg",
    "legend": "P_load_orig",
    "descriptions": "P_load_orig",
    "emptyData": ""
  },
  {
    "name": "proz_E_vo2ss",
    "color": "Blue",
    "unit": "%",
    "legend": "aerobic",
    "descriptions": "% Ox",
    "emptyData": ""
  },
  {
    "name": "proz_E_laktat",
    "color": "Red",
    "unit": "%",
    "legend": "CrP",
    "descriptions": "% PCr_cyt",
    "emptyData": ""
  },
  {
    "name": "proz_E_vlass",
    "color": "GreenYellow",
    "unit": "%",
    "legend": "glycolytic",
    "descriptions": "% Glyc",
    "emptyData": ""
  },
  {
    "name": "vla_per_min",
    "color": "DeepPink",
    "unit": "mmol/l/min",
    "legend": "VLa",
    "descriptions": "VLa",
    "emptyData": ""
  },
  {
    "name": "vo2_per_min",
    "color": "Blue",
    "unit": "ml/kg/min",
    "legend": "VO2",
    "descriptions": "VO2",
    "emptyData": ""
  },
  {
    "name": "pload_w",
    "color": "Grey",
    "unit": "Watt",
    "legend": "Power",
    "descriptions": "Power",
    "emptyData": ""
  },
  {
    "name": "lab_meas",
    "color": "DarkOrchid",
    "unit": "",
    "legend": "lab_meas",
    "descriptions": "lab_meas",
    "emptyData": ""
  },
  {
    "name": "vo2_meas",
    "color": "Blue",
    "unit": "",
    "legend": "vo2_meas",
    "descriptions": "vo2_meas",
    "emptyData": ""
  },
  {
    "name": "fat_gh_meas",
    "color": "OrangeRed",
    "unit": "",
    "legend": "fat_gh_meas",
    "descriptions": "fat_gh_meas",
    "emptyData": ""
  },
  {
    "name": "cho_gh_meas",
    "color": "DarkGreen",
    "unit": "",
    "legend": "cho_gh_meas",
    "descriptions": "cho_gh_meas",
    "emptyData": ""
  },
  {
    "name": "pcr_meas",
    "color": "Green",
    "unit": "",
    "legend": "pcr_meas",
    "descriptions": "pcr_meas",
    "emptyData": ""
  },
  {
    "name": "atp_meas",
    "color": "GoldenRod",
    "unit": "",
    "legend": "atp_meas",
    "descriptions": "atp_meas",
    "emptyData": ""
  },
  {
    "name": "pi_meas",
    "color": "DarkBlue",
    "unit": "",
    "legend": "pi_meas",
    "descriptions": "pi_meas",
    "emptyData": ""
  },
  {
    "name": "phb_meas",
    "color": "Grey",
    "unit": "",
    "legend": "phb_meas",
    "descriptions": "phb_meas",
    "emptyData": ""
  },
  {
    "name": "phm_meas",
    "color": "black",
    "unit": "",
    "legend": "phm_meas",
    "descriptions": "phm_meas",
    "emptyData": ""
  },
  {
    "name": "hb_ox_meas",
    "color": "Grey",
    "unit": "",
    "legend": "hb_ox_meas",
    "descriptions": "hb_ox_meas",
    "emptyData": ""
  },
  {
    "name": "ox_meas",
    "color": "Grey",
    "unit": "",
    "legend": "ox_meas",
    "descriptions": "ox_meas",
    "emptyData": ""
  },
  {
    "name": "so2_meas",
    "color": "Grey",
    "unit": "",
    "legend": "so2_meas",
    "descriptions": "so2_meas",
    "emptyData": ""
  },
  {
    "name": "lab_static",
    "color": "DarkOrchid",
    "unit": "",
    "legend": "lab_static",
    "descriptions": "lab_static",
    "emptyData": ""
  },
  {
    "name": "vo2_static",
    "color": "Blue",
    "unit": "",
    "legend": "vo2_static",
    "descriptions": "vo2_static",
    "emptyData": ""
  },
  {
    "name": "phm_static",
    "color": "black",
    "unit": "",
    "legend": "phm_static",
    "descriptions": "phm_static",
    "emptyData": ""
  },
  {
    "name": "fat_gh_static",
    "color": "OrangeRed",
    "unit": "",
    "legend": "fat_gh_static",
    "descriptions": "fat_gh_static",
    "emptyData": ""
  },
  {
    "name": "cho_gh_static",
    "color": "OrangeRed",
    "unit": "",
    "legend": "cho_gh_static",
    "descriptions": "cho_gh_static",
    "emptyData": ""
  },
  {
    "name": "vla_static",
    "color": "DeepPink",
    "unit": "",
    "legend": "vla_static",
    "descriptions": "vla_static",
    "emptyData": ""
  },
  {
    "name": "aerob_static",
    "color": "Grey",
    "unit": "",
    "legend": "aerob_static",
    "descriptions": "aerob_static",
    "emptyData": ""
  },
  {
    "name": "anaerbic_static",
    "color": "Grey",
    "unit": "",
    "legend": "anaerbic_static",
    "descriptions": "anaerbic_static",
    "emptyData": ""
  },
  {
    "name": "cda",
    "color": "Grey",
    "unit": "",
    "legend": "cda",
    "descriptions": "cda",
    "emptyData": ""
  },
  {
    "name": "crr",
    "color": "Grey",
    "unit": "",
    "legend": "crr",
    "descriptions": "crr",
    "emptyData": ""
  },
  {
    "name": "torso_angle",
    "color": "Grey",
    "unit": "",
    "legend": "torso_angle",
    "descriptions": "torso_angle",
    "emptyData": ""
  },
  {
    "name": "knee_angle",
    "color": "Grey",
    "unit": "",
    "legend": "knee_angle",
    "descriptions": "knee_angle",
    "emptyData": ""
  },
  {
    "name": "pressure",
    "color": "Grey",
    "unit": "",
    "legend": "pressure",
    "descriptions": "pressure",
    "emptyData": ""
  },
  {
    "name": "altitude",
    "color": "Grey",
    "unit": "",
    "legend": "altitude",
    "descriptions": "altitude",
    "emptyData": ""
  },
  {
    "name": "yaw",
    "color": "Grey",
    "unit": "",
    "legend": "yaw",
    "descriptions": "yaw",
    "emptyData": ""
  },
  {
    "name": "effect_wind",
    "color": "Grey",
    "unit": "",
    "legend": "effect_wind",
    "descriptions": "effect_wind",
    "emptyData": ""
  },
  {
    "name": "torque",
    "color": "Grey",
    "unit": "",
    "legend": "torque",
    "descriptions": "torque",
    "emptyData": ""
  },
  {
    "name": "force",
    "color": "Grey",
    "unit": "",
    "legend": "force",
    "descriptions": "force",
    "emptyData": ""
  },
  {
    "name": "cho_intake",
    "color": "Grey",
    "unit": "",
    "legend": "cho_intake",
    "descriptions": "cho_intake",
    "emptyData": ""
  },
  {
    "name": "glycogen",
    "color": "Grey",
    "unit": "",
    "legend": "glycogen",
    "descriptions": "glycogen",
    "emptyData": ""
  },
  {
    "name": "var1_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var1_meas",
    "descriptions": "var1_meas",
    "emptyData": ""
  },
  {
    "name": "var2_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var2_meas",
    "descriptions": "var2_meas",
    "emptyData": ""
  },
  {
    "name": "var3_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var3_meas",
    "descriptions": "var3_meas",
    "emptyData": ""
  },
  {
    "name": "var4_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var4_meas",
    "descriptions": "var4_meas",
    "emptyData": ""
  },
  {
    "name": "var5_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var5_meas",
    "descriptions": "var5_meas",
    "emptyData": ""
  },
  {
    "name": "var6_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var6_meas",
    "descriptions": "var6_meas",
    "emptyData": ""
  },
  {
    "name": "var7_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var7_meas",
    "descriptions": "var7_meas",
    "emptyData": ""
  },
  {
    "name": "var8_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var8_meas",
    "descriptions": "var8_meas",
    "emptyData": ""
  },
  {
    "name": "var9_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var9_meas",
    "descriptions": "var9_meas",
    "emptyData": ""
  },
  {
    "name": "var10_meas",
    "color": "Grey",
    "unit": "",
    "legend": "var10_meas",
    "descriptions": "var10_meas",
    "emptyData": ""
  },
  {
    "name": "aerobic_percent",
    "color": "#4876ff",
    "unit": "%",
    "legend": "aerobic",
    "descriptions": "",
    "emptyData": ""
  },
  {
    "name": "anaerobic_percent",
    "color": "#ee2c2c",
    "unit": "%",
    "legend": "anaerobic",
    "descriptions": "",
    "emptyData": ""
  },
  {
    "name": "carbohydrates_percent",
    "color": "#ee2c2c",
    "unit": "%",
    "legend": "carbohydrate",
    "help": "",
  }
];
