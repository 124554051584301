import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, Inject } from '@angular/core';
import { ReportBuildingBlocks } from "@modules/apc/apc/data/chart-modules";
import { AppConstants } from "@core/constants";

@Component({
  selector: 'app-text-editor-dialog',
  templateUrl: './text-editor-dialog.component.html',
  styleUrls: ['./text-editor-dialog.component.scss'],
})
export class TextEditorDialogComponent {
  public title: string;
  public saveButton: string;
  public cancelButton: string;
  public textSettings: any;
  public textComponentType: any;
  public textSettingList: any = [
    {
      value: ReportBuildingBlocks.GENERAL_TEXT,
      name: AppConstants.WORDING.GENERAL_TEXT,
    },
    {
      value: ReportBuildingBlocks.INTRODUCTION_TEXT,
      name: AppConstants.WORDING.INTRODUCTION_TEXT,
    },
    {
      value: ReportBuildingBlocks.BODY_COMPOSITION,
      name: AppConstants.WORDING.BODY_COMPOSITION,
    },
    {
      value: ReportBuildingBlocks.PHYSIOLOGICAL_PERFORMANCE,
      name: AppConstants.WORDING.PHYSIOLOGICAL_PERFORMANCE,
    },
    {
      value: ReportBuildingBlocks.LOAD_CHARACTERISTICS,
      name: AppConstants.WORDING.LOAD_CHARACTERISTICS,
    },
    {
      value: ReportBuildingBlocks.METABOLIC_FINGERPRINT,
      name: AppConstants.WORDING.METABOLIC_FINGERPRINT,
    },
    {
      value: ReportBuildingBlocks.PERFORMANCE_DEVELOPMENT,
      name: AppConstants.WORDING.PERFORMANCE_DEVELOPMENT,
    },
    {
      value: ReportBuildingBlocks.TRAINING_ZONE,
      name: AppConstants.WORDING.TRAINING_ZONES,
    },
    {
      value: ReportBuildingBlocks.TEST_DATA_GRAPHS,
      name: AppConstants.WORDING.TEST_DATA_GRAPHS,
    },
    {
      value: ReportBuildingBlocks.ECONOMY,
      name: AppConstants.WORDING.ECONOMY,
    },
    {
      value: ReportBuildingBlocks.TEST_DATA_TABLES,
      name: AppConstants.WORDING.TEST_DATA_TABLES,
    },
    {
      value: ReportBuildingBlocks.HEART_RATE,
      name: AppConstants.WORDING.HEART_RATE,
    },
    {
      value: ReportBuildingBlocks.METABOLIC_POWER,
      name: AppConstants.WORDING.METABOLIC_POWER,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<TextEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.saveButton = data.saveButton || 'Save';
    this.cancelButton = data.cancelButton || 'Cancel';
    this.textSettings = { text: this.data.textContent, id: this.data.id };
    this.textComponentType = { value: this.data.textSettingType, id: this.data.id };
  }

  public onTextChangeModal(event: any): void {
    this.textSettings = event;
  }

  public onChangeTextSettingsType(value: any, id: any): void {
    this.textComponentType = { value, id };
  }

  public onConfirm(): void {
    this.dialogRef.close({ save: true, data: this.textSettings, textComponentType: this.textComponentType });
  }

  public onDismiss(): void {
    this.dialogRef.close({ save: false });
  }
}
