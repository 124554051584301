import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITotalPrice } from "@shared/interfaces/total-price-dialog.interface";

@Component({
  selector: 'app-total-price',
  templateUrl: './total-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalPriceComponent {
  @Input() public config: ITotalPrice;
}
