import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@core/services/api.service';
import { AppConstants } from "@core/constants";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { CustomRendererService } from "@shared/services/custom-renderer.service";

@Component({
  selector: 'app-confirm-shared-data',
  templateUrl: './confirm-shared-data.component.html',
  styleUrls: ['./confirm-shared-data.component.scss']
})
export class ConfirmSharedDataComponent {
  public isConfirmSharedDataFormAccessible: boolean = true;
  public showThanksPage: boolean = false;
  public organizationName: string = '';
  public token: number | string | undefined;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private customRendererService: CustomRendererService,
  ) {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    this.organizationName = this.activatedRoute.snapshot.queryParams.organization;
  }

  public sendShareStatus(isAccept: boolean = false): void {
    this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
    this.apiService.post(AppConstants.API.CONFIRM_SHARED_DATA.POST, { isAccept, token: this.token })
      .subscribe((): void => {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
        this.isConfirmSharedDataFormAccessible= false;
        this.showThanksPage = true;
      }, (): void => {
        this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
        this.snackBar.open('Something went wrong with shared data', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      });
  }
}
