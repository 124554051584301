<h4 mat-dialog-title>{{modalWindowTitle}}</h4>
<h2 style="text-align: center">Single effort + W'</h2>
<mat-dialog-content>
  <div [formGroup]="form" class="form-box">
    <div class="form-row">
      <p style="width: 50px">Effort</p>
      <mat-form-field appearance="outline" floatLabel="always" class="field">
        <mat-label>Time [sec]</mat-label>
        <input
          matInput
          type="number"
          min="150"
          max="1800"
          formControlName="duration_efforts"
        />
        <mat-error
          *ngIf="
            form.get('duration_efforts')?.errors &&
            form.get('duration_efforts')?.touched
          "
          >Time must be between 150 and 1800 seconds
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="field">
        <mat-label>Average Power [W]</mat-label>
        <input matInput type="number" min="1" formControlName="average_power" />
        <mat-error
          *ngIf="
            form.get('average_power')?.errors &&
            form.get('average_power')?.touched
          "
          >Average power must be larger than 0
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="field">
        <mat-label>Work [Joule]</mat-label>
        <input readonly matInput type="number" formControlName="work"
      /></mat-form-field>
    </div>
    <div class="form-row">
      <p style="width: 50px">W'</p>
      <mat-form-field appearance="outline" floatLabel="always" class="field">
        <mat-label>Work [Joule]</mat-label>
        <input matInput type="number" min="1" formControlName="manual_w" />
        <mat-error
          *ngIf="form.get('manual_w')?.errors && form.get('manual_w')?.touched"
          >Manual work must be larger than 0
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button mat-raised-button (click)="closeDialog()" type="button">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">OK</button>
  </div>
</mat-dialog-actions>
