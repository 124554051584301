import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import * as _ from 'lodash-es';
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips";
import { AutocompleteTagsEnum } from "@shared/components/chips/autocomplete-tags.interface";

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
})
export class ChipsComponent implements OnChanges {
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public inputControl!: UntypedFormControl;
  public filteredOptions!: Observable<any[]>;
  public selectedOptions: any[] = [];

  @Input() public options!: any[];
  @Input() public label!: string;
  @Input() public form!: any;
  @Input() public displayField = 'name';
  @Input() public autocompleteType: AutocompleteTagsEnum;
  @Input() public isDisabled: boolean = false;
  @Output() public optionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectNewTag: EventEmitter<any> = new EventEmitter<any>();
  @Output() public removeNewTag: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('chipInput') public chipInput!: ElementRef<HTMLInputElement>;

  public ngOnChanges(): void {
    this.inputControl = this.form as UntypedFormControl;
    if (this.options && this.inputControl) {
      this.selectedOptions = this.inputControl.value || [];
      this.filteredOptions = this.inputControl.valueChanges.pipe(
        startWith(null),
        map((option: any | null) =>
          option ? this._filter(option) : this._initOptions()
        )
      );
    }
  }

  public remove(option: any): void {
    const index: number = this.selectedOptions.findIndex((item) =>
      _.isEqual(item, option)
    );

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.optionChange.emit(this.selectedOptions);
    }
    this.removeNewTag.emit({option, inputValue: this.chipInput.nativeElement.value});
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.push(event.option.value);
    this.chipInput.nativeElement.value = '';
    this.inputControl.setValue(null);
    this.chipInput.nativeElement.blur();
    setTimeout((): void => {
      this.chipInput.nativeElement.focus();
    });
    this.optionChange.emit(this.selectedOptions);
  }

  public add(event: MatChipInputEvent): void {
    if (this.autocompleteType === AutocompleteTagsEnum.TAGS) {
      const value: string = (event.value || '').trim();

      if (value) {
        this.selectedOptions.push({name: value });
      }

      event.chipInput!.clear();
      this.inputControl.setValue(null);
      this.selectNewTag.emit(value);
    }
  }

  private _filter(values: any): any[] {
    const isValuesArray: boolean = Array.isArray(values);
    return this.options.filter((option): boolean => {
      if (isValuesArray) {
        const selectedOption = _.find(values, (item) =>
          _.isEqual(item, option)
        );
        return !selectedOption;

      }
      const selectedOption2 = _.find(this.selectedOptions, (item) =>
        _.isEqual(item, option)
      );
      if (selectedOption2) {
        return false;
      }
      if (typeof values === 'string') {
        return option[this.displayField].toLowerCase().includes(values.toLowerCase());
      } else {
        return _.includes(
          _.lowerCase(option[this.displayField]),
          _.lowerCase(values)
        );
      }
    });
  }

  private _initOptions(): any[] {
    return this.options.filter((option) => {
      const selectedOption = _.find(this.selectedOptions, (item) =>
        _.isEqual(item, option)
      );
      return !selectedOption;
    });
  }
}
