import { Component, OnInit, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetForm!: UntypedFormGroup;
  public key: string = '';

  constructor(private route: ActivatedRoute,
              private readonly fb: UntypedFormBuilder,
              private apiService: ApiService,
              private readonly snackBar: MatSnackBar,
              private router: Router,
              private destroyRef: DestroyRef,
              ) {}

  public ngOnInit(): void {
    this.initForm();
  }

  public checkPasswordKey(): void {
    const api_check: string = 'accounts/check-reset-password-key/';
    let model = { reset_password_key: this.key };
    const message: string = 'The reset password link is not valid or expired !';

    this.apiService.post(api_check, model)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {},
    (): void => {
      this.snackBar.open(message ,'OK', AppConstants.TOAST_CONFIG.ERROR);
      this.router.navigate(['']);
    });
  }

  public initForm(): void {
    this.key = this.route.snapshot.paramMap.get('key') || '';
    this.checkPasswordKey();

    const ControlsConfig = {
      password: new UntypedFormControl('', Validators.required),
      password_confirm: new UntypedFormControl('', Validators.required),
    };
    this.resetForm = this.fb.group(ControlsConfig);
  }


  public handleResetPassword(): void {
    const passwordNotMatch: string = 'Passwords not match.';
    const passwordWeak: string = 'Password must be longer than 6 characters.';
    const invalidFormMessage: string = 'Password and Confirm Password are required.';

    if(!this.resetForm.valid){
      this.snackBar.open(invalidFormMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      return;
    }

    if(this.resetForm.get('password')?.value !== this.resetForm.get('password_confirm')?.value) {
      this.snackBar.open(passwordNotMatch, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      return;
    }

    if(this.resetForm.get('password')?.value.length < 6){
      this.snackBar.open(passwordWeak, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      return;
    }

    this.resetPassword();
  }

  public resetPassword(): void {
    const successMessage: string = 'Reset password successfully. Now you can login with your new password';
    const errorMessage: string = 'Invalid user credentials.';
    const api: string = 'accounts/reset-password/';
    let model = {
      new_password: this.resetForm.get('password')?.value,
      reset_password_key: this.key
    };

    this.apiService.post(api, model)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((): void => {
      this.snackBar.open(successMessage, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      this.router.navigate(['']);
    },
    (): void => {
      this.snackBar.open(errorMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
    });
  }
}
