<app-loader-indicator></app-loader-indicator>
<div class="full-page-container">
    <div class="form">
      <div class="form-group text-center">
        <h1>Forgot Password</h1>
      </div>
      <div class="field" [formGroup]="resetForm">
        <mat-form-field appearance="outline" [style.width.px]="350">
          <input
            class="loginInput"
            type="text"
            matInput
            placeholder="Username"
            formControlName="username"
          />
        </mat-form-field>
      </div>
      <button class="c-btn-blue" (click)="handleResetPassword()">Confirm</button>
    </div>
</div>

