import { Component, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBaseComponent }  from '@shared/components/app-component-base';
import { ChartService } from '@shared/services/chart.service';
import { AppConstants } from '@core/constants';
import { ApiService } from '@core/services/api.service';
import { MetabolicService } from '@core/services/metabolic-service/metabolic.service';
import * as _ from 'lodash-es';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OptimizationOptionDialogComponent } from '@shared/dialogs/optimization-option-dialog/optimization-option-dialog.component';
import { catchError } from 'rxjs/operators';
import { TestDialogComponent } from '@shared/dialogs/test-dialog/test-dialog.component';
import { String } from 'typescript-string-operations';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { PPD_ENTITY } from "@shared/enums/ppd-entity.enum";
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export interface IRecord {
  id: number | null;
  isSpecialAT?: boolean;
  type: string;
  hidden?: boolean;
  power: number;
  powerTolerance: number;
  speed?: number;
  work?: number;
  selected_power?: number[];
  duration: number;
  additionalValue: number;
  additionalValueUnit: string;
  additionalValueString?: string;
  additionalValueTolerance: number;
  initial_average_speed?: number;
  initial_average_power?: number;
  initial_duration_efforts?: number;
  dataForPOST: IDataForPost;
}

export interface IDataForPost {
  entity?: string;
  option?: number;
  target: number;
  range: number;
  upper: number;
  lower: number;
}

@Component({
  selector: 'app-ppd-test',
  templateUrl: './ppd-test.component.html',
  styleUrls: ['./ppd-test.component.scss'],
})
export class PpdTestComponent extends AppBaseComponent {
  public wattO2eq: number;
  public newtype = {
    upper: 0,
    lower: 0,
  };
  public regressionForm: UntypedFormGroup = {} as UntypedFormGroup;
  public showStar: boolean = false;
  public results: never[] = [];
  public progressValue: number = 0;
  public dataSource: any = [];
  public test: any = {};
  public sport_measure_weight: any = {};
  public measuredValues: IRecord[] = [];
  public isFinalCPCalculated: boolean = false;
  public itemData: any = {};
  public chartRendered: boolean = false;
  public athlete = [
    {
      header: 'ID',
    },
    {
      header: 'Athlete Name',
    },
    {
      header: 'Sport',
    },
    {
      header: 'Date',
    },
    {
      header: 'Tags',
    },
  ];

  public errors = {};
  public action: string = '';
  public testID: string = '';
  public data = {
    coaches: [],
    coach: null,
    countries: [],
    country: null,
    country_name: '',
  };
  public modules = [
    {
      id: 'test_data',
      type: 7,
      rpId: 'test_data_rp',
      text: 'Test Data',
      name: 'td',
      state: 'active',
      isShow: true,
    },
  ];
  public chartData: any = [];
  public optimization = {};
  public optimize_option = {
    vl_weight: 1,
    vo_weight: 1,
    at_weight: 1,
  };
  public isAcceptAndView: boolean = false;
  public isCallingAction: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private metabolicService: MetabolicService,
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private chartService: ChartService,
    private router: Router,
    private snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  public onInitPage(): void {
    this.testID = this.route.snapshot.params['id'];
    this.action = this.route.snapshot.params['action'];
    this.regressionForm = this.fb.group({
      vla_max: new UntypedFormControl(1),
      vo2_max: new UntypedFormControl(1),
      anaerobicThreshold: new UntypedFormControl(1),
    });
    this.loadTests();
  }

  public loadTests(): void {
    if (this.action) {
      this.metabolicService.fetchTestDataDelete({testId: this.testID,})
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response: any): void => {
          this.callbackTests(response);
        });
    } else {
      this.metabolicService.fetchTestData({testId: this.testID,})
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response: any): void => {
          this.callbackTests(response);
        });
    }
  }

  public callbackTests(response: any): void {
    if (response.results.length) {
      let tests = response.results;
      this.dataSource = tests;
      this.wattO2eq = this.dataSource[0]['watto2eq'];
      this.test = {
        id: this.dataSource[0].id,
        date: this.dataSource[0].test_date,
        location: this.dataSource[0].location,
        athlete:
          this.dataSource[0].athlete.first_name +
          ' ' +
          this.dataSource[0].athlete.last_name,
        coach: this.auth.first_name + ' ' + this.auth.last_name,
        email: this.auth.email,
        test_type: this.dataSource[0].test_type,
        sport: this.dataSource[0].sport,
        test_id: this.dataSource[0].test_id,
      };

      this.test.origin = this.dataSource[0];

      try {
        this.test.extra = JSON.parse(tests[0].json_data);
      } catch (_) {
        this.test.extra = {};
      }

      // Parse sport measures
      this.sport_measure_weight = {
        vl_weight: !this.test.sport.vl_weight
          ? 1
          : parseInt(this.test.sport.vl_weight),
        vo_weight: !this.test.sport.vo_weight
          ? 1
          : parseInt(this.test.sport.vo_weight),
        at_weight: !this.test.sport.at_weight
          ? 1
          : parseInt(this.test.sport.at_weight),
      };

      // Clone measures
      this.optimize_option = {
        vl_weight: this.sport_measure_weight.vl_weight,
        vo_weight: this.sport_measure_weight.vo_weight,
        at_weight: this.sport_measure_weight.at_weight,
      };

      this.loadCharts();
    } else {
      this.snackBar.open('Tests not found', 'OK', AppConstants.TOAST_CONFIG.ERROR);
    }
  }

  public loadCharts(): void {
    if (!this.chartRendered) this.fetchDataAllTab(this.modules);
  }
  fetchDataAllTab(listElements: any) {
    this.metabolicService.fetchChartData(false, this.testID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: any): void => {
        // Render charts
        if (response && response.length > 0) {
          // Optimization only take the last chart data
          this.chartData = [response[listElements[0].type - 1]];
          this.chartRendered = true;

          this.itemData = {
            data: this.chartData[0],
            testID: this.testID,
            test: this.test,
          };
        }
      });
  }

  public setUniqueEntity(entityArray: string[], entity: string): void {
    if (entityArray.indexOf(entity) == -1) {
      entityArray.push(entity);
    }
  }

  public updateProgressBaseOnNumberOfEntities(measuredValues: IRecord[]): void {
    const LONG_EFFORT: number = 689;
    const ENSURING_DELTA: number = 540;
    const SPECIAL_AT_COUNT_ALLOWED_FOR_PERFORM_PPD_TEST: number = 2;

    this.measuredValues = measuredValues;
    let filteredValues: IRecord[] = measuredValues.filter((x: IRecord) => !x.isSpecialAT);

    let specialATs: IRecord[] = measuredValues.filter((x) => x.isSpecialAT);

    let uniqueEntities: any[] = Array.from(
      new Set(filteredValues.map((x: any) => x.dataForPOST.entity))
    );

    let numberOfEntities: number = filteredValues.length;

    if (specialATs.length > 2) {
      this.setUniqueEntity(uniqueEntities, PPD_ENTITY.AT);
      numberOfEntities++;
    } else if (specialATs.length === SPECIAL_AT_COUNT_ALLOWED_FOR_PERFORM_PPD_TEST) {
      const isFirstRangeCasePositive: boolean = specialATs[0].duration > LONG_EFFORT && specialATs[1].duration < ENSURING_DELTA;
      const isSecondRangeCasePositive: boolean = specialATs[1].duration > LONG_EFFORT && specialATs[0].duration < ENSURING_DELTA;

      if (isFirstRangeCasePositive || isSecondRangeCasePositive) {
        this.setUniqueEntity(uniqueEntities, PPD_ENTITY.AT);

        numberOfEntities++;
      }
    }

    this.showStar = numberOfEntities > uniqueEntities.length;

    if (uniqueEntities.length > 2) {
      this.progressValue = 100;
    } else if (uniqueEntities.length > 1) {
      this.progressValue = 67;
    } else if (uniqueEntities.length > 0) {
      this.progressValue = 33;
    } else {
      this.progressValue = 0;
    }
  }

  public accept(isAcceptAndView: boolean): void {
    this.openOptimizationOptionsModal(isAcceptAndView);
  }
  public handleCancelView(isManual: boolean, isPPD: boolean, isVirtual: boolean, isLactate: boolean): void {
    const id: string | null = this.route.snapshot.paramMap.get('id');

    this.router.navigate(['home/manage', 'tests'], {
      queryParams: {
        action: 'back',
        test_id: this.testID,
        create: 'newtype',
      },
    });

    const dialogRef: MatDialogRef<TestDialogComponent> = this.dialog.open(TestDialogComponent, {
      width: '45%',
      height: '95%',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { isManual, isPPD, isVirtual, isLactate, isBack: true, testId: id },
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res): void => {
      if (res) {
        let message: string = 'Item created successfully';
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
      }
    });
  }

  public openOptimizationOptionsModal(isAcceptAndView: boolean): void {
    const type: string = AppConstants.TEST_TYPES.PPD;
    let modalUpdate = this.dialog.open(OptimizationOptionDialogComponent, {
      panelClass: 'general-dialog',
      disableClose: true,
      data: {
        type,
      },
    });

    modalUpdate.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any): void => {
        if (!data || data.action !== 'confirm') {
          return;
        }
        this.doTestOptimization(isAcceptAndView, data);
      });
  }

  public doTestOptimization(isAcceptView: boolean, data: any): void {
    let param_array: any[] = [];
    let params: string = '';

    if (data && data.action == 'confirm') {
      if (data.productId) {
        param_array.push('product=' + data.productId);
      }
      if (data.planId) {
        param_array.push('plan=' + data.planId);
      }
      params = param_array.join('&');
    }
    const api: string = `tests/${this.testID}/accept/${params ? '?' + params : ''}`;
    this.apiService.put(api, {})
      .pipe(
        catchError((error) => {
          const paramErrors = error.split(' ').slice(1); // Ignore the initial 'Error: ' prefix
          if (error === 'Error: TEST_ALREADY_ACTIVE') {
            this.router.navigateByUrl('home/metabolic-profile/' + this.testID + '/');
          }
          if (paramErrors.length > 0 && paramErrors.indexOf('500') > -1) {
            let testName: string = '';
            if (paramErrors[1] == 'newtype') testName = 'PPD test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.LACTATE)
              testName = 'Lactate test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.VIRTUAL)
              testName = 'Virtual test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.MANUAL)
              testName = 'Manual test';
            else if (paramErrors[1] == AppConstants.TEST_TYPES.CRITICAL_POWER)
              testName = 'critical power test';
            this.isCallingAction = false;
            this.popupPurchase(testName, error);
          } else {
            this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          }
          throw error;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((): void => {
        this.handleRedirect(this.testID, isAcceptView);
      });
  }
  public handleRedirect(testId: string, isAcceptView: boolean): void {
    if (!isAcceptView) {
      const createType: string | null = this.route.snapshot.queryParamMap.get('create');
      if (createType) {
        this.router.navigateByUrl('home/manage/tests');
      }
    } else {
      this.router.navigateByUrl('home/apc/setcard/' + testId);
    }
  }

  private popupPurchase(testName: string, error: string): void {
    const paramErrors: string[] = error.split(' ');
    const message: string = `Maximum number of ${testName}s has been exceeded. Do you want to purchase more?`;
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Confirmation', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
    });

    dialogRef.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result): void => {
      if (result) {
        const params1: string = '?plan=' + paramErrors[3] + '&type=' + paramErrors[2] + '&new_id=' + paramErrors[4];
        const apiUrl: string = String.Format(this.constant.API.INVOICES.PURCHASE_EXCEED, params1);

        this.apiService.post(apiUrl, {})
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
          (): void => {
            this.handleRedirect(this.testID, this.isAcceptAndView);
          },
          (errorPurchase): void => {
            this.snackBar.open(errorPurchase, 'OK', this.constant.TOAST_CONFIG.ERROR);
          }
        );
      }
    });
  }

  public performCPTest(): void {
    if (!this.regressionForm.valid) {
      return;
    }

    let filteredValues: IRecord[] = this.measuredValues.filter((x) => !x.isSpecialAT);

    let VOs: IRecord[] = [];
    let VO4s: IRecord[] = [];
    filteredValues.forEach((filteredValue: IRecord): void => {
      if (filteredValue.dataForPOST.entity == 'VO') {
        if (filteredValue.dataForPOST.option == 4) {
          VO4s.push(filteredValue);
        } else {
          VOs.push(filteredValue);
        }
      }
    });

    let VLs: IRecord[] = filteredValues.filter((item: IRecord): boolean => item.dataForPOST.entity == 'VL');

    let vLOption1s: IRecord[] = this.measuredValues.filter(
      (x: IRecord) => x.dataForPOST.entity == 'VL' && x.dataForPOST.option == 1
    );

    // Calculate VL Option 1 - question: what if there is many Option 1 ? => SOLVED
    if (vLOption1s && vLOption1s.length) {
      if (VOs && VOs.length) {
        vLOption1s.map((vLOption1: any): void => {
          let data: any = {
            duration_efforts: vLOption1.duration,
            average_power: vLOption1.power,
            selected_power: vLOption1.selected_power,
            work: vLOption1.duration * vLOption1.power,
            average_speed: vLOption1.speed,
            initial_average_power: vLOption1.initial_average_power,
            initial_average_speed: vLOption1.initial_average_speed,
          };
          let vo_target =
            VOs.reduce((acc: number, x: any) => acc + x.dataForPOST.target, 0) /
            VOs.length;
          this.calculateVL1(data, vo_target);
          vLOption1.dataForPOST.target = data.target;
          vLOption1.dataForPOST.upper = data.upper;
          vLOption1.dataForPOST.lower = data.lower;
          vLOption1.dataForPOST.range = data.range;
        });
      } else if (VO4s.length) {
        // Estimation for VO2max to fulfill dependency of VO2max in VL option 1:
        let estimatedVOs: number[] = [];
        VO4s.forEach((VO4: IRecord): void => {
          let estimatedVO = this.estimateVO(VO4);
          estimatedVOs.push(estimatedVO);
        });
        let sumEstimatedVOs: number = 0.0;
        estimatedVOs.forEach((estimatedVO: number) => {
          sumEstimatedVOs += estimatedVO;
        });
        let avgEstimatedVO: number = sumEstimatedVOs / estimatedVOs.length;
        vLOption1s.map((vLOption1: any): void => {
          let data: any = {
            duration_efforts: vLOption1.duration,
            average_power: vLOption1.power,
            selected_power: vLOption1.selected_power,
            work: vLOption1.duration * vLOption1.power,
            average_speed: vLOption1.speed,
            initial_average_power: vLOption1.initial_average_power,
            initial_average_speed: vLOption1.initial_average_speed,
          };
          this.calculateVL1(data, avgEstimatedVO);
          vLOption1.dataForPOST.target = data.target;
          vLOption1.dataForPOST.upper = data.upper;
          vLOption1.dataForPOST.lower = data.lower;
          vLOption1.dataForPOST.range = data.range;
        });
      }
    }

    /* ---------------- new VO2max calculation ---------------- */

    let sumVL: number = 0.0;
    let count: number = 0;
    VLs.forEach((VL: IRecord): void => {
      sumVL += VL.dataForPOST.target;
      count++;
    });

    let avgVL: number = sumVL / count;
    VO4s.forEach((VO4: IRecord): void => {
      this.calculateVO2Max(VO4, avgVL);
    });

    // Calculate AT Option 4
    let specialATs: IRecord[] = this.measuredValues.filter((x: IRecord) => x.isSpecialAT);
    this.combineSpecialATs(specialATs, filteredValues);

    // Filter real ATs
    let ATs: IRecord[] = filteredValues.filter((item: IRecord): boolean => item.dataForPOST.entity == 'AT');

    let VOsForPOST: IDataForPost[] = [];
    let VLsForPOST: IDataForPost[] = [];
    let ATsForPOST: IDataForPost[] = [];

    for (let i = 0; i < this.optimize_option.vo_weight; i++) {
      VOs.forEach((item: any) => VOsForPOST.push(item.dataForPOST));
      VO4s.forEach((item: any) => VOsForPOST.push(item.dataForPOST));
    }
    for (let i = 0; i < this.optimize_option.vl_weight; i++) {
      VLs.forEach((item: any) => VLsForPOST.push(item.dataForPOST));
    }
    for (let i = 0; i < this.optimize_option.at_weight; i++) {
      ATs.forEach((item: any) => ATsForPOST.push(item.dataForPOST));
    }

    let finalData = {
      VL: VLsForPOST,
      VO: VOsForPOST,
      AT: ATsForPOST,
      weighted_values: {
        vl_weight: this.regressionForm.get('vla_max')?.value,
        vo_weight: this.regressionForm.get('vo2_max')?.value,
        at_weight: this.regressionForm.get('anaerobicThreshold')?.value,
      },
    };
    this.postMeasuredValues(finalData);
  }

  public estimateVO(VO4: IRecord): number {
    let vo2tot: number = (VO4.power / this.test.origin.mass) * this.wattO2eq;

    return 0.85 * vo2tot;
  }

  public postMeasuredValues(data: any): void {
    data.measured_values = this.measuredValues;
    let apiUrl: string = 'tests/' + this.testID + '/optimize_solution/';
    this.customRendererService.empty('#chartPPD');

    this.apiService.post(apiUrl, data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
      (res: any): void => {
        if (res) {
          if (res.warning) {
            this.snackBar.open(res.warning, 'OK', AppConstants.TOAST_CONFIG.WARNING);
          } else {
            this.isFinalCPCalculated = true;
            this.chartService.generatePPDChart('#chartPPD', res, false);
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          }
        } else {
          this.snackBar.open('Cannot find any solution for measured values.', 'OK', AppConstants.TOAST_CONFIG.ERROR);
        }
      },
      (): void => {
        this.snackBar.open('Cannot find any solution for measured values.', 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    );
  }
  public calculateVL1(data: any, vo_target: any): void {
    if (this.test.sport.simulation_type == AppConstants.SPORT_SIMULATION_TYPES[0].id) {
      let powerLength = data.selected_power.length;
      let target: number = 0;
      if (data.initial_average_speed != data.average_speed) {
        // If changed average speed => use single data.average_power
        let result = this.calculateVL1Target(data.duration_efforts, data.average_power, vo_target);
        target = result['target'];
      } else {
        // If not changed -> calculate average power for each dataset from 12 seconds -> 25 seconds
        let vlaValues: any[] = [];
        let initialIndex = Math.min(powerLength - 3, 11);
        let avgPowerFrom12Sec: any[] = [];
        for (let index = initialIndex; index < powerLength; index++) {
          // Take powers for this period
          let avgPowers = _.take(data.selected_power, index + 1);
          // Remove NaN, Infinity and <zero number
          avgPowers = avgPowers.filter(
            (x: any) => !isNaN(x) && x != Infinity && x > 0
          );
          // Calculate average power for this period
          let avgPower: number = _.mean(avgPowers);
          avgPowerFrom12Sec.push(avgPower);
          // Calculate VL Target
          let result = this.calculateVL1Target(index + 1, avgPower, vo_target);
          vlaValues.push(result['target']);
        }
        // Calculate median of top 3 highest of VLa values
        target = this.medianOf3Highest(vlaValues);
      }
      data.range = 0.175; // VL Option 1 Range
      data.lower = parseFloat((target * (1.0 - data.range)).toFixed(3));
      data.upper = parseFloat((target * (1.0 + data.range)).toFixed(3));
      data.target = parseFloat(target.toFixed(3));
    } else if (
      this.test.sport.simulation_type ==
      AppConstants.SPORT_SIMULATION_TYPES[1].id
    ) {
      let powerLength = data.selected_power.length;
      let target: number = 0;
      if (data.initial_average_power != data.average_power) {
        // If changed average power => use new average
        let result = this.calculateVL1Target(
          data.duration_efforts,
          data.average_power,
          vo_target
        );
        target = result['target'];
      } else {
        // If not changed -> calculate average power for each dataset from 12 seconds -> 25 seconds
        let vlaValues: any[] = [];
        let initialIndex: number = Math.min(powerLength - 3, 11);
        let avgPowerFrom12Sec = [];
        for (let index = initialIndex; index < powerLength; index++) {
          // Take powers for this period
          let avgPowers = _.take(data.selected_power, index + 1);
          // Remove NaN, Infinity and <zero number
          avgPowers = avgPowers.filter(
            (x: any) => !isNaN(x) && x != Infinity && x > 0
          );
          // Calculate average power for this period
          let avgPower: number = _.mean(avgPowers);
          avgPowerFrom12Sec.push(avgPower);
          // Calculate VL Target
          let result = this.calculateVL1Target(index + 1, avgPower, vo_target);
          vlaValues.push(result['target']);
        }
        // Calculate median of top 3 highest of VLa values
        target = this.medianOf3Highest(vlaValues);
      }

      data.range = 0.175; // VL Option 1 Range
      data.lower = parseFloat((target * (1.0 - data.range)).toFixed(3));
      data.upper = parseFloat((target * (1.0 + data.range)).toFixed(3));
      data.target = parseFloat(target.toFixed(3));
    }
  }

  public calculateVL1Target(duration_efforts: any, medianPower: number, vo_target: number) {
    let calculatedWork: number = duration_efforts * medianPower;
    let athlete_weight = this.test.origin.mass;
    let lactate_distribution: number = this.test.origin.proz_lactat_dist_space_100_override / 100;
    let muscle_mass_pct: number = this.test.origin.proz_muscle_mass_used_100_override / 100;
    let body_muscle_pct: number = this.test.origin.proz_body_muscle_100_override / 100;
    let la_o2_equiv: number = lactate_distribution * AppConstants.TEST_STATIC_CONSTANTS.lao2eq_precise;
    let mlo2ToJoune: number = this.wattO2eq / 60.0; // Constants
    let tau: number = -0.34 * vo_target + 35;
    let mmolPCrToJoule: number = AppConstants.ADDITIONAL_CONSTANTS.pcr_mlo2 / mlo2ToJoune;
    let jouleToMmolPCr: number = 1 / mmolPCrToJoule;
    // VO Values
    let vo2MaxTotal: number = athlete_weight * vo_target;
    let vo2RestInitial = this.test.extra.additional_constants.vo2_initial; // Test - Additional Constants
    let vo2RestTotal: number = athlete_weight * vo2RestInitial;
    // PCr System
    let activeMuscleMass: number = athlete_weight * body_muscle_pct * muscle_mass_pct;
    let pcrInitialValue = this.test.extra.additional_constants.pcr_initial; // Test - Additional Constants
    let pcrAtExhaustion = this.test.extra.additional_constants.pcr_exhaustion; // Test - Additional Constants
    let deltaPcrAvailable: number = pcrInitialValue - pcrAtExhaustion;
    let totalPCrAvailable: number = deltaPcrAvailable * activeMuscleMass;
    // PCr kinetics
    let endState: number = 1 - Math.exp(-duration_efforts / tau);
    let usedPCr: number = endState * totalPCrAvailable;
    let energyFromPCr: number = usedPCr * mmolPCrToJoule;
    // O2 kinetics
    let endState2: number = Math.exp(-duration_efforts / tau);
    let usedO2: number = endState2 * vo2MaxTotal - vo2RestTotal;
    let energyFromVO2: number = usedO2 * mlo2ToJoune;
    // Calculate VL1
    let restEnergy1: number = calculatedWork - energyFromPCr - energyFromVO2;
    let power1: number = restEnergy1 / duration_efforts;
    let vo2: number = power1 * this.wattO2eq;
    let vo2Rel: number = vo2 / athlete_weight;
    let laRate: number = vo2Rel / la_o2_equiv;
    let vl1: number = laRate / 60;
    // Calculate VL 2
    let restEnergy2: number = restEnergy1 * jouleToMmolPCr; // Constants
    let convertToLa: number = restEnergy2 / 1.35;
    let divideByWater: number = convertToLa / (athlete_weight * lactate_distribution);
    let divideByTime: number = divideByWater / (duration_efforts / 60);
    let vl2: number = divideByTime / 60;
    // Calculate Final VL
    let target: number = (vl1 + vl2) / 2;
    let result: any = {};
    result['power'] = medianPower;
    result['energyFromPCr'] = energyFromPCr;
    result['energyFromVO2'] = energyFromVO2;
    result['vl1'] = vl1;
    result['vl2'] = vl2;
    result['target'] = target;
    return result;
  }

  public combineSpecialATs(specialATs: any, filteredValues: any): void {
    let x_values: any = [];
    let y_values: any = [];
    _.each(specialATs, function (specialAT): void {
      x_values.push(parseFloat(specialAT.duration));
      y_values.push(parseFloat(specialAT.work));
    });
    let slope = this.calculateSlope(x_values, y_values);
    if (slope && !isNaN(slope.intercept)) {
      let at_w: number = parseFloat(slope.intercept.toFixed(3));
    }
    if (slope && !isNaN(slope.slope)) {
      let at: number = parseFloat(slope.slope.toFixed(3));
      // Fixed tolerance number for now - (Mr Weber and Marcel email)
      let range: number = 0.075; // AT Option 4 Range
      let lower: number = parseFloat((at * (1 - range)).toFixed(3));
      let upper: number = parseFloat((at * (1 + range)).toFixed(3));
      let record: IRecord = this.emptyMeasuredValuesRecord();
      record.id = new Date().getTime();
      record.type = 'Power Duration';
      record.dataForPOST.entity = 'AT';
      record.dataForPOST.option = 4;
      record.dataForPOST.target = at;
      record.dataForPOST.range = range;
      record.dataForPOST.upper = upper;
      record.dataForPOST.lower = lower;
      // Add to filteredValues
      filteredValues.push(record);
    }
  }

  public calculateSlope(x_values: number[], y_values: number[]) {
    let x_avg: number = _.reduce(x_values, function (memo, num) {return memo + num;}, 0) / x_values.length;
    let y_avg: number = _.reduce(y_values, function (memo: number, num: number) {return memo + num;}, 0) / y_values.length;

    // Calculate parts
    let b_top: number = 0;
    let b_bot: number = 0;
    let r_bot_x: number = 0;
    let r_bot_y: number = 0;

    for (let i = 0; i < x_values.length; i++) {
      b_top += (x_values[i] - x_avg) * (y_values[i] - y_avg);
      b_bot += (x_values[i] - x_avg) * (x_values[i] - x_avg);
      r_bot_x += (x_values[i] - x_avg) * (x_values[i] - x_avg);
      r_bot_y += (y_values[i] - y_avg) * (y_values[i] - y_avg);
    }

    // Calculate slope, intersect and r2
    let b: number = b_top / b_bot;
    let a: number = y_avg - b * x_avg;
    let r: number = b_top / Math.sqrt(r_bot_x * r_bot_y);

    return {
      slope: b,
      intercept: a,
      r2: r * r,
    };
  }

  public emptyMeasuredValuesRecord(): IRecord {
    return {
      id: null,
      type: '',
      power: 0,
      powerTolerance: 0,
      duration: 0,
      additionalValue: 0,
      additionalValueUnit: '',
      additionalValueTolerance: 0,
      dataForPOST: {
        entity: '',
        option: 0,
        target: 0,
        range: 0,
        upper: 0,
        lower: 0,
      },
    };
  }

  public initVO2MaxCalculation(data: any, avgVL: any): void {
    data.vlamax = avgVL;
    data.accumla_min = data.duration / 60;
    data.vo2ss = [];
    data.vla_vo2 = [];
    data.vlass = [];
    data.cla = [];
    data.ph = [];
    data.vlamaxph = [];

    // We calculate vo2tot from mass, average power and wattO2equivalent (12.5)
    //data.vo2tot = data.power / data.mass * 12.5
    data.vo2tot = (data.power / this.test.origin.mass) * this.wattO2eq;
    data.records = [];
  }

  public set_vo2ss(data: any): void {
    let delta: number = 0.1;
    let vo2ss = data.vo2tot;

    for (; vo2ss > 0; ) {
      vo2ss = vo2ss - delta;
      data.vo2ss.push(vo2ss);
    }

    for (let i = 0; i < data.vo2ss.length; i++) {
      let record: any = {};
      record.vo2ss = 0;
      record.vla_vo2 = 0;
      record.vlass = 0;
      record.cla = 0;
      record.ph = 0;
      record.vlamax_ph = 0;
      record.vo2ss = data.vo2ss[i];
      data.records.push(record);
    }
  }

  public set_vla_vo2(data: any): void {
    for (let i = 0; i < data.vo2ss.length; i++) {
      let vla_vo2: number = data.vo2tot - data.vo2ss[i];
      data.vla_vo2.push(vla_vo2);
    }

    for (let i = 0; i < data.records.length; i++) {
      data.records[i].vla_vo2 = data.vo2tot - data.records[i].vo2ss;
    }
  }

  public set_vlass_cla_vlamaxph(data: any): void {
    let parameter = this.test.origin;
    let klavo2a: number = parameter.klavo2 / (parameter.proz_lactat_dist_space_100_override / 100);

    let vo2max = data.records[0].vo2ss;
    for (let i = 0; i < data.records.length; i++) {
      let record = data.records[i];
      let lao2eq: number = parameter.lao2eq_precise * (parameter.proz_lactat_dist_space_100_override / 100);
      let vo2_initial = this.test.extra.additional_constants.vo2_initial;
      let CLaSS: number = 300; // while testing, the value is always 300 until we get a zero division at the end
      record.vlass = (data.vo2tot - record.vo2ss) / lao2eq;
      let vlaoxmax: number = record.vo2ss * klavo2a;
      let vla_net: number = record.vlass - vlaoxmax;
      let accumla_min = data.accumla_min;
      let t_limit: number = 0;

      // Inserted from lactate_calculation.py:
      let class_expmax: number = 1.25;

      // mpa: necessary for fixing too early setting of t_limit
      let class_exp: number = class_expmax;

      if (CLaSS < 160) {
        class_exp = CLaSS * (1 - Math.exp(-accumla_min / (1.65 * CLaSS)));
        if (class_exp < class_expmax) {
          class_exp = class_expmax;
        }
      }

      if (CLaSS >= 160 && record.vo2ss > 0.3 * vo2max) {
        if (t_limit == 0) {
          t_limit = accumla_min;
        }
      }

      if (CLaSS >= 160 && record.vo2ss > 0.3 * vo2max) {
        let class_expmax3: number = -0.0046 * t_limit * t_limit + 0.5046 * t_limit + 0.3073;

        if (class_expmax3 < 1.25) {
          class_expmax3 = 1.25;
        }
        class_exp = class_expmax3;
      }

      let cla: number = accumla_min * vla_net;
      cla = cla + class_exp;
      record.cla = cla;

      let vlamax_pHm = this.clapH_vlamaxpH(cla, data.vlamax, parameter.ks_lapH);
      record.vlamax_ph = vlamax_pHm.vlamaxpH;
      record.ph = vlamax_pHm.pHm;

      if (record.vlass > record.vlamax_ph) {
        // Calculate vo2max PPD
        let vo2max_ppd =
          data.vo2tot - (cla / accumla_min) * lao2eq + vo2_initial;

        let target;
        if (this.test.sport.simulation_type == AppConstants.SPORT_SIMULATION_TYPES[0].id) {
          target = vo2max_ppd;
        } else {
          target = record.vo2ss * 0.9994 + 2.896;
        }

        data.range = 0.08; // VO Option 4 Range
        data.lower = parseFloat((target * (1.0 - data.range)).toFixed(3));
        data.upper = parseFloat((target * (1.0 + data.range)).toFixed(3));
        data.target = parseFloat(target.toFixed(3));
        data.dataForPOST.target = data.target;
        data.dataForPOST.range = data.range;
        data.dataForPOST.lower = data.lower;
        data.dataForPOST.upper = data.upper;
        return;
      }
    }
  }

  public clapH_vlamaxpH(cla: any, vlamax: any, ks_lapH: any) {
    try {
      let _ks_lapH: number = 4.5e-22;
      let H_ion, pHm, vlamaxpH;
      pHm = 7.1 - 0.022 * 1.3 * cla;
      H_ion = 1.0 / 10.0 ** pHm;
      vlamaxpH = (60.0 * vlamax) / (1.0 + (H_ion * H_ion * H_ion) / _ks_lapH);
      vlamaxpH = vlamaxpH;

      return {
        vlamaxpH: vlamaxpH,
        pHm: pHm,
      };
    } catch (error) {
      this.snackBar.open('There is a calculation error in clapH_vlamaxpH.', 'OK', AppConstants.TOAST_CONFIG.ERROR);

      return {
        vlamaxpH: 0,
        pHm: 0,
      };
    }
  }

  public calculateVO2Max(data: any, avgVL: any): void {
    this.initVO2MaxCalculation(data, avgVL);
    this.set_vo2ss(data);
    this.set_vla_vo2(data);
    this.set_vlass_cla_vlamaxph(data);
    let lastIdx: number = data.records.length - 1;
  }
  // Functions to calculate VO2 Max - END

  public medianOf3Highest(values: number[]): number {
    let newValues: number[] = values.slice().sort((a: number, b: number) => a - b);
    let len: number = values.length;
    let median: number = newValues[0];
    if (len >= 3) {
      median = this.median([
        newValues[len - 1],
        newValues[len - 2],
        newValues[len - 3],
      ]);
      return median;
    } else if (len == 2) {
      median = this.median([newValues[len - 1], newValues[len - 2]]);
      return median;
    }
    return median;
  }

  public median(values: number[]): number {
    if (!values.length) return 0;

    values.sort((a: number, b: number) => a - b);

    let half: number = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }
}
