import { PagedResultDto } from '@shared/components/paged-listing-component-base';

export class InvoiceResultDto extends PagedResultDto {
  public items: InvoiceDto[] = [];
}

export interface InvoiceDto {
  additional_id?: number;
  additional_type?: string;
  amount?: number;
  created_at?: string | Date;
  credited?: number;
  currency?: {
    id: number;
    name: string;
    iso: string;
    created_at: string | Date;
    is_delete: boolean;
  };
  delta_amount?: number;
  description?: string;
  discount?: number;
  download_link?: string;
  expiration?: string | Date;
  fee_total_by_month?: number;
  group_id?: number;
  handling_fee_apply?: number;
  id: number;
  invoice_date?: string | Date;
  invoice_type?: number;
  is_delete?: boolean;
  is_system?: boolean;
  is_up_downgrade?: boolean;
  job_id?: number;
  job_type?: string;
  min_handling_fee?: number;
  months_contract_expired?: number;
  name?: string;
  net_amount?: number;
  note?: string;
  org_paid_method?: string;
  organization?: {
    id: number;
    name: string;
    photo: string;
    is_solvent: boolean;
    is_check_invoice: boolean;
    address_country_id: number;
  };
  paid_method?: string;
  paid_method_display?: string;
  parent_id?: number;
  period_type?: number;
  period_type_display?: string;
  plan_bill_at?: number;
  pre_paid?: number;
  purchase_details?: Array<IPurchaseDetails>;
  rank_type?: number;
  retry_times?: number;
  start_date?: string | Date;
  status?: number;
  status_display?: string;
  tobe_credited?: number;
  up_down_detail?: string;
  up_downgrade_from_id?: number;
  up_downgrade_status?: number;
  updated_at?: string | Date;
  user?: number;
  reasonDisplay?: string;
}

export interface IPurchaseDetails {
  amount: number;
  cancel_notice_time: number;
  created_at: string | Date;
  description: string;
  discount: number;
  duration: number;
  expiration: string | Date;
  expiration_trigger: any;
  features: string;
  id: number;
  invoice: number;
  is_active: boolean;
  is_additional: boolean;
  is_delete: boolean;
  is_renewal: boolean;
  is_show_setting: boolean;
  is_system: boolean;
  is_trigger: boolean;
  max_athlete: number;
  max_test_event: number;
  max_test_la: number;
  max_test_manual: number;
  max_test_map: number;
  max_test_result: number;
  max_test_virtual: number;
  max_user: number;
  name: string;
  notification_athlete: number;
  notification_test_event: number;
  notification_test_la: number;
  notification_test_manual: number;
  notification_test_map: number;
  notification_test_result: number;
  notification_test_virtual: number;
  notification_user: number;
  organization: number;
  original_athlete: number;
  original_max_athlete: number;
  original_max_test_event: number;
  original_max_test_la: number;
  original_max_test_manual: number;
  original_max_test_map: number;
  original_max_test_result: number;
  original_max_test_virtual: number;
  original_max_user: number;
  original_plan_price: number;
  original_start_date: number;
  original_test_event: number;
  original_test_la: number;
  original_test_manual: number;
  original_test_map: number;
  original_test_result: number;
  original_test_virtual: number;
  original_user: number;
  period_type: number;
  plan: number;
  plan_bill_at: number;
  plan_price: number;
  price: number;
  product: any[];
  purchase_alternative_items: any[];
  purchase_link_items: any[];
  purchase_lots: any[];
  purchase_plan_prices: any[];
  purchase_ratios: any[];
  rank_type: number;
  renewal: number;
  renewal_unit: number;
  start_date: string | Date;
  total_athlete: number;
  total_test_event: number;
  total_test_la: number;
  total_test_manual: number;
  total_test_map: number;
  total_test_result: number;
  total_test_virtual: number;
  total_user: number;
  triggers: any;
  up_downgrade: any;
  updated_at: string | number;
  user: any;
}
