import { UntypedFormControl, AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AppConstants } from '@core/constants';

export class ValidatorHelper {
  static cannotContainSpace(control: AbstractControl) {
    if (control.value === null || control.value === '') {
      return null;
    }
    if (!(control.value + '').trim()) {
      return { required: true };
    }

    return null;
  }

  static validateOutputValues(control: AbstractControl) {
    if (
      control.value &&
      control.value.length > AppConstants.TRAINING_ZONE_VALIDATION.MAX_OUTPUT
    ) {
      return { maximum: true };
    }
    return null;
  }

  static validateSystemRef(control: AbstractControl) {
    if (
      control.value &&
      control.value.length >
        AppConstants.TRAINING_ZONE_VALIDATION.MAX_SYSTEM_REF
    ) {
      return { maximum: true };
    }
    return null;
  }

  static trainingZoneCheckAdditionalValue(
    control: UntypedFormControl,
    group: UntypedFormGroup,
    addValueSelect: string
  ) {
    if (
      group.controls[addValueSelect] !== undefined &&
      group.controls[addValueSelect].value
    ) {
      if (!control.value) {
        return { mismatch: true };
      }
    }
    return null;
  }
  static greaterOrEqualToZero(control: AbstractControl) {
    if (control.value < 0) {
      return { lessThanZero: true };
    }
    return null;
  }
}
