import { Injectable } from '@angular/core';
import { ServiceBase } from '../service-base';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class MetabolicService extends ServiceBase {
  public fetchChartData(isGenerateReport?: boolean, testId?: string): Observable<Object> {
    if (isGenerateReport) {
      return this._apiService.get('tests/metabolic/' + testId + '?generate-report=1', false);
    }

    return this._apiService.get('tests/metabolic/' + testId, true);
  }

  public fetchTestData(params: any): Observable<Object> {
    return this._apiService.get('tests/?id=' + params.testId, false);
  }

  public fetchTestDataDelete(params: any): Observable<Object> {
    return this._apiService.get('tests/?id=' + params.testId + '&test_action=show', false);
  }
}
