<form [formGroup]="orgForm" (ngSubmit)="save()">
  <div class="sport-dialog__section">
    <h2 mat-dialog-title>
      {{ data?.isEdit ? "Edit Organization" : "Add Organization" }}
    </h2>
    <mat-dialog-content>
      <fieldset class="infor-fieldset">
        <legend>Organization info</legend>
        <div class="form-infor">
          <div class="full-width photo">
            <img
              style="height: 100px; width: 100px"
              [src]="orgForm.value?.photo"
              *ngIf="orgForm?.value?.photo && data.isEdit"/>
            <div style="display: flex; flex-direction: row">
              <mat-label style="margin-right: 1%">Logo </mat-label>
              <input
                type="file"
                id="photo"
                name="organization"
                accept="image/png, image/jpeg"
                (change)="changeImage($event)"/>
            </div>
          </div>
          <br />
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Name (*)</mat-label>
              <input
                matInput
                placeholder="Name"
                formControlName="name"
                (keypress)="onChangeName($event)"/>
              <mat-error
                *ngIf="
                  orgForm.controls?.name?.errors &&
                  orgForm.controls?.name?.touched
                ">
                <div
                  [innerHTML]="
                    orgForm.controls.name.errors
                      | errorFormFormat : { fieldName: 'Name' }
                  "></div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Max Devices (*)</mat-label>
              <input
                matInput
                type="number"
                min="0"
                placeholder="Max devices"
                formControlName="max_devices"/>
            </mat-form-field>
          </div>
          <div class="field-col">
            <mat-form-field
              style="width: 65%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Street</mat-label>
              <input
                matInput
                placeholder="Street Name"
                formControlName="address_street_name"
                (keypress)="onChangeStreet($event)"/>
            </mat-form-field>
            <mat-form-field
              style="width: 34%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Number</mat-label>
              <input
                matInput
                placeholder="Street Number"
                formControlName="address_street_number"
                (keypress)="onChangeNumber($event)"/>
            </mat-form-field>
          </div>
          <div class="field-col">
            <mat-form-field
              style="width: 34%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>ZIP</mat-label>
              <input
                matInput
                placeholder="Zip code"
                formControlName="address_zip"
                (keypress)="onChangeZip($event)"/>
            </mat-form-field>
            <mat-form-field
              style="width: 65%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>City</mat-label>
              <input
                matInput
                placeholder="City name"
                formControlName="address_city"
                (keypress)="onChangeCity($event)"
              />
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>State</mat-label>
              <input
                matInput
                placeholder="State name"
                formControlName="address_state"
                (keypress)="onChangeState($event)"
              />
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>VAT</mat-label>
              <input
                matInput
                placeholder="VAT"
                formControlName="vat_number"
                (keypress)="onChangeVat($event)"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Country (*)</mat-label>
              <mat-select
                placeholder="Select a country"
                formControlName="country"
                (selectionChange)="onChangeCountry()">
                <mat-option *ngFor="let item of countries" [value]="item.id">{{
                  item.name
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  orgForm.controls?.country?.errors &&
                  orgForm.controls?.country?.touched
                ">
                <div
                  [innerHTML]="
                    orgForm.controls.country.errors
                      | errorFormFormat : { fieldName: 'Country' }
                  "></div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-slide-toggle
              id="inv"
              (change)="onChangeDefault($event)"
              formControlName="is_use_invoice_address"
              labelPosition="before"
              class="full-width"
              >Use same address for reports</mat-slide-toggle>
          </div>
          <br />
        </div>
        <hr />
        <br />
        <div class="form-infor">
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Name (*)</mat-label>
              <input
                matInput
                placeholder="Name"
                [readonly]="isChecked"
                formControlName="invoice_org_name"/>
              <mat-error
                *ngIf="
                  orgForm.controls?.invoice_org_name?.errors &&
                  orgForm.controls?.invoice_org_name?.touched
                ">
                <div
                  [innerHTML]="
                    orgForm.controls.invoice_org_name.errors
                      | errorFormFormat : { fieldName: 'Name' }
                  "></div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field-col">
            <mat-form-field
              style="width: 65%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Street</mat-label>
              <input
                matInput
                placeholder="Street Name"
                [readonly]="isChecked"
                formControlName="invoice_address_street_name"/>
            </mat-form-field>
            <mat-form-field
              style="width: 34%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Number</mat-label>
              <input
                matInput
                placeholder="Street Number"
                [readonly]="isChecked"
                formControlName="invoice_address_street_number"/>
            </mat-form-field>
          </div>
          <div class="field-col">
            <mat-form-field
              style="width: 34%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>ZIP</mat-label>
              <input
                matInput
                placeholder="Zip code"
                [readonly]="isChecked"
                formControlName="invoice_address_zip"/>
            </mat-form-field>
            <mat-form-field
              style="width: 65%"
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>City</mat-label>
              <input
                matInput
                placeholder="City name"
                [readonly]="isChecked"
                formControlName="invoice_address_city"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>State</mat-label>
              <input
                matInput
                placeholder="State name"
                [readonly]="isChecked"
                formControlName="invoice_address_state"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>VAT</mat-label>
              <input
                matInput
                placeholder="VAT"
                [readonly]="isChecked"
                formControlName="invoice_vat_number"
              />
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Country (*)</mat-label>
              <mat-select
                [disabled]="isChecked"
                placeholder="Select a country"
                formControlName="invoice_country">
                <mat-option
                  *ngFor="let item of invoice_countries"
                  [value]="item.id"
                  >{{ item.name }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  orgForm.controls?.invoice_country?.errors &&
                  orgForm.controls?.invoice_country?.touched
                ">
                <div
                  [innerHTML]="
                    orgForm.controls.invoice_country.errors
                      | errorFormFormat : { fieldName: 'Country' }
                  "></div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
      <fieldset class="infor-fieldset">
        <legend>Contact</legend>
        <div class="form-infor">
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Phone</mat-label>
              <input
                matInput
                placeholder="+(00) 123-456789"
                formControlName="phone"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Phone 2</mat-label>
              <input
                matInput
                placeholder="+(00) 123-456789"
                formControlName="phone2"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>FAX</mat-label>
              <input
                matInput
                placeholder="+(00) 123-456789"
                formControlName="fax"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Email (*)</mat-label>
              <input
                matInput
                placeholder="some@email.com"
                formControlName="email"/>
              <mat-error
                *ngIf="
                  orgForm.controls?.email?.errors &&
                  orgForm.controls?.email?.touched
                ">
                <div
                  [innerHTML]="
                    orgForm.controls.email.errors
                      | errorFormFormat : { fieldName: 'Email' }
                  "></div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Email 2</mat-label>
              <input
                matInput
                placeholder="some@email.com"
                formControlName="email2"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Website</mat-label>
              <input
                matInput
                placeholder="www.iatac.com"
                formControlName="website"/>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Facebook</mat-label>
              <input matInput placeholder="" formControlName="facebook" />
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Instagram</mat-label>
              <input matInput placeholder="" formControlName="instagram" />
            </mat-form-field>
          </div>
        </div>
      </fieldset>
      <fieldset class="infor-fieldset">
        <legend>Settings</legend>
        <div class="form-infor">
          <div class="field">
            <mat-slide-toggle
              formControlName="is_active"
              labelPosition="before"
              class="full-width"
              >Active</mat-slide-toggle>
          </div>
          <br />
          <div class="field">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Select an acount type</mat-label>
              <mat-select
                placeholder="Select a account type"
                formControlName="account_type">
                <mat-option
                  *ngFor="let item of accountTypes"
                  [value]="item.id"
                  >{{ item.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-slide-toggle
              formControlName="is_solvent"
              labelPosition="before"
              class="full-width"
              >Solvent</mat-slide-toggle>
          </div>
          <br />
          <div class="field" *ngIf="data.isEdit">
            <mat-form-field
              class="full-width"
              appearance="outline"
              floatLabel="always">
              <mat-label>Head of organization</mat-label>
              <mat-select formControlName="head">
                <mat-option *ngFor="let item of heads" [value]="item.id">{{
                  item.username
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="!data.isEdit">
        <legend>Create Admin</legend>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always">
            <mat-label>Username</mat-label>
            <input
              matInput
              placeholder="Username"
              formControlName="admin_username"/>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field
            class="full-width"
            appearance="outline"
            floatLabel="always">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="some@email.com"
              formControlName="admin_email"/>
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset>
        <legend>APIs</legend>
        <div class="field-api">
          <label>API Key</label>
          <div>
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="generateKey()">
              Generate Key
            </button>
            <label>(Max: 10)</label>
          </div>
          <br />
          <div>
            <div
              class="copy"
              formArrayName="api_keys"
              *ngFor="let key of keys.controls; index as i">
              <div [formGroupName]="i">
                <label>Key {{ i + 1 }}</label>
                <div class="field">
                  <mat-form-field
                    class="full-width"
                    appearance="outline"
                    floatLabel="always">
                    <input
                      matInput
                      placeholder=""
                      formControlName="key"
                      value="key.key"
                      [readonly]="true"/>
                  </mat-form-field>
                  <div class="buttons">
                    <button
                      mat-raised-button
                      type="button"
                      (click)="removeKey(i)">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button
                      mat-raised-button
                      type="button"
                      (click)="copyKey(key)">
                      <mat-icon>file_copy</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="field">
          <app-chips
            [label]="'Enable API'"
            [form]="orgForm.get('api')"
            [options]="apiOptions"
            [displayField]="'name'"
            (optionChange)="onSelectOrganization($event)"></app-chips>
        </div>
      </fieldset>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button id="cancelButton" mat-button (click)="close()" type="button">
          Cancel
        </button>
        <button id="saveButton" mat-raised-button color="primary" type="submit">
          Save
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
