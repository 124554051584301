export const TestConstants = {
  Type: {
    LACTATE: 'LACTATE',
    PPD: 'NEWTYPE',
    CRITICAL_POWER: 'MAP',
    MANUAL: 'MANUAL',
    VIRTUAL: 'VIRTUAL'
  },
  TypeDisplay: {
    LACTATE: 'LACTATE',
    PPD: 'PPD',
    CRITICAL_POWER: 'CRITICAL POWER',
    MANUAL: 'MANUAL',
    VIRTUAL: 'VIRTUAL',
    UNKNOWN: 'UNKNOWN',
  }
};
