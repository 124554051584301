import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { String } from 'typescript-string-operations';
import { ServiceBase } from '../service-base';
import { ProductDto, ProductResultDto } from './product-dto';
import { ApiRequest } from "@shared/models/api-request";

@Injectable({
  providedIn: 'root',
})
export class ProductService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto | any): Observable<ProductResultDto> {
    const apiUrl: string = this._appConstants.API.PRODUCT.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new ProductResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: ProductDto) =>
            new ProductDto().deserialize(item)
          ),
        });
      })
    );
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(this._appConstants.API.PRODUCT.DELETE, id);

    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }

  public update(id: number, product: any): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.PRODUCT.UPDATE, id);

    return this._apiService.put(apiUrl, product);
  }
}
