import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { throwError as _observableThrow, of as _observableOf, Observable } from 'rxjs';
import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { ApiInfoDto, ApiInfoResultDto, ManageOrganizationDto, ManageOrganizationResultDto } from './manage-organization-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from '@shared/models/api-request';
import { AccountTypeDto, AccountTypeResultDto } from '../account-types/account-type-dto';
import { HeadDto, HeadResultDto } from './head-dto';

@Injectable({
  providedIn: 'root'
})
export class ManageOrganizationsService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto): Observable<ManageOrganizationResultDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATIONS.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new ManageOrganizationResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: ManageOrganizationDto) =>
            new ManageOrganizationDto().deserialize(item)
          ),
        });
      })
    );
  }

  public listDeleted(request: PagedAndSortedRequestDto): Observable<ManageOrganizationResultDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATIONS.DELETED;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new ManageOrganizationResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: ManageOrganizationDto) =>
            new ManageOrganizationDto().deserialize(item)
          ),
        });
      })
    );
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(this._appConstants.API.ORGANIZATIONS.DELETE, id);

    return this._apiService.delete(apiUrl).pipe(
      _observableCatch((response_: any) => {
        return <Observable<void>>(<any>_observableThrow(response_));
      })
    );
  }

  public restore(id: number): Observable<ManageOrganizationDto> {
    const apiUrl: string = String.Format(this._appConstants.API.ORGANIZATIONS.RESTORE, id);
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body,apiUrl,});

    return this._apiService.put(apiRequest.apiUrl, apiRequest.body).pipe(
      _observableMergeMap((response_: any) => {
        return _observableOf(response_);
      })
    );
  }

  public getApiInfo(): Observable<ApiInfoResultDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATIONS.API_INFO;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new ApiInfoResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: ApiInfoDto) =>
            new ApiInfoDto().deserialize(item)
          ),
        });
      })
    );
  }

  public getAccountTypes(): Observable<AccountTypeResultDto> {
    const apiUrl: string = this._appConstants.API.ACCOUNT_TYPE.LIST;

    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new AccountTypeResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: AccountTypeDto) =>
            new AccountTypeDto().deserialize(item)
          ),
        });
      })
    );
  }

  public getHead(id?: number): Observable<HeadResultDto> {
    let api: string = 'accounts/';
        if (id) {
            api += '?organization_id=' + id;
        }

      return this._apiService.get(api).pipe(
          map((res: any) => {
            return new HeadResultDto().deserialize({
              totalCount: res.count,
              items: res.results.map((item: HeadDto) =>
                new HeadDto().deserialize(item)
              ),
            });
          })
      );
  }
  public create(input: ManageOrganizationDto): Observable<ManageOrganizationDto> {
    const apiUrl: string = this._appConstants.API.ORGANIZATIONS.ADD;

    return this._apiService.xhrCall(apiUrl, input, 'POST').pipe(map((item: any) => {
      return new ManageOrganizationDto().deserialize(JSON.parse(item.response));
    }));
  }

  public update(input: any): Observable<any> {
    const apiUrl: string = String.Format(this._appConstants.API.ORGANIZATIONS.UPDATE, input.id);
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    Object.assign(body, input);

    return this._apiService
      .xhrCall(apiUrl, apiRequest.body, 'PUT')
      .pipe(map((item: Object) => new ManageOrganizationDto().deserialize(item)));
  }

  public getOrganizationById(id: number): Observable<ManageOrganizationDto> {
    const apiUrl: string = String.Format(this._appConstants.API.ORGANIZATIONS.GET_BY_ID, id);

    return this._apiService
      .get(apiUrl)
      .pipe(map((item) => new ManageOrganizationDto().deserialize(item)));
  }

  public getRandomString(length: number): string {
    let randomChars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result: string = '';

    for (var i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }

    return result;
  }

  public setApiKey(): Observable<Object> {
    const apiUrl: string = this._appConstants.API.ORGANIZATIONS.API_KEY;
    const data = { name: this.getRandomString(20) };

    return this._apiService.post(apiUrl, data);
  }
}
