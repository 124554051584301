import { OnInit, Component, inject } from '@angular/core';
import { SessionService } from '@core/services/session.service';
import { AppConstants } from '@core/constants';
import { CustomRendererService } from "@shared/services/custom-renderer.service";

@Component({
  template: '',
})
export abstract class AppBaseComponent implements OnInit {
  public sessionService: SessionService = inject(SessionService);
  public customRendererService: CustomRendererService = inject(CustomRendererService);
  public isDataLoading: boolean = false;
  public rights: any = {};
  public auth: any = {
    first_name: '',
    last_name: '',
    organization: {
      name: '',
    },
  };
  public constant = AppConstants;
  public v1URL: string = AppConstants.V1_URL;
  public isDarkMode: boolean = false;

  public ngOnInit(): void {
    this.sessionService.getCurrentUserProfile();
    this.auth = this.sessionService.auth;
    const theme: string | null = localStorage.getItem('theme');
    this.isDarkMode = theme === this.constant.THEME.DARK;
    this.rights = this.sessionService.rights;
    this.onInitPage();
  }

  public onInitPage(): void {}

  public hideLoading(): void {
    this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
  }

  public showLoading(): void {
    this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
  }
}
