import { inject, Injectable } from "@angular/core";
import { IChartDataType, IChartValues } from "@shared/services/d3/multiple-x-axis-chart/d3-multiple-x-axis-chart.interface";
import { IChartDimension } from "@shared/services/d3/multiple-x-axis-chart/d3-multiple-x-axis-chart-dimension.interface";
import { IChartStyle } from "@shared/services/d3/multiple-x-axis-chart/d3-multiple-x-axis-chart.interface";
import { HelperService } from "@shared/services/helper.service";
import { METRIC_PROPERTY_NAME_ENUM } from "@shared/enums/metric-property-name.enum";
import { index } from "d3";

@Injectable()
export class D3MultipleXAxisChartService {
  private helperService: HelperService = inject(HelperService);

  public INCREMENT_STEP_X_AXIS_BOTTOM: number = 45;
  public INCREMENT_STEP_X_AXIS_TOP: number = 35;
  public INITIAL_BOTTOM_X_AXIS_POSITION: number = 55;
  public INITIAL_BOTTOM_Y_AXIS_LABEL_POSITION: number = 20;
  public INCREMENT_STEP_Y_AXIS_LABEL_BOTTOM: number = 10;
  public INCREMENT_STEP_Y_AXIS_LABEL_TOP: number = 3;
  public MAX_COUNT_X_AXIS_BOTTOM: number = 3;
  public MAX_COUNT_X_AXIS_TOP: number = 6;
  public FIRST_X_AXIS_BOTTOM_ELEMENT: number = 1;
  public NEXT_ELEMENT: number = 3;
  public OFFSET_FOR_ONE_X_AXIS: number = 10;
  public OFFSET_FOR_MULTIPLE_X_AXIS: number = 20;
  public MAX_CHARACTER_PER_ROW: number = 10;
  public DIVIDE_VALUE: number = 2;
  public INITIAL_TOP_POSITION: number = 0;
  public GRAPH_STYLES: Record<string, IChartStyle> = {
    [METRIC_PROPERTY_NAME_ENUM.BODY_FAT]: {
      lineLabelTextColor: '#8e8e8e',
      xAxisClass: 'body-fat-stroke-color',
      lineColorStroke: '#8e8e8e',
    },
    [METRIC_PROPERTY_NAME_ENUM.VLAMAX]: {
      lineLabelTextColor: '#b72237',
      xAxisClass: 'vlamax-stroke-color',
      lineColorStroke: '#b72237',
    },
    [METRIC_PROPERTY_NAME_ENUM.VO2MAX]: {
      lineLabelTextColor: '#000080',
      xAxisClass: 'vo2max-stroke-color',
      lineColorStroke: '#000080',
    },
    [METRIC_PROPERTY_NAME_ENUM.ECONOMY]: {
      lineLabelTextColor: '#ff8000',
      xAxisClass: 'economy-stroke-color',
      lineColorStroke: '#ff8000',
    },
    [METRIC_PROPERTY_NAME_ENUM.BUFFERING_CAPACITY]: {
      lineLabelTextColor: '#e066ff',
      xAxisClass: 'buffering-capacity-stroke-color',
      lineColorStroke: '#e066ff',
    },
  };

  public getPredefinedMultipleXAxisStyles(): Record<string, IChartStyle> {
    return this.GRAPH_STYLES;
  }

  public getXAxisBottomPosition(elementOrder: number, incrementStep: number, initialBottomPosition: number, firstBottomElement: number, currentHeight: number): number {
    return elementOrder === firstBottomElement ? initialBottomPosition : currentHeight - incrementStep;
  }

  public getXAxisTopPosition(elementOrder: number, incrementStep: number, initialTopPosition: number, nextElement: number, currentHeight: number): number {
    if (elementOrder === nextElement) {
      return initialTopPosition;
    } else {
      return currentHeight + incrementStep;
    }
  }

  public getYAxisLabelBottomPosition(elementOrder: number, incrementStep: number, initialBottomPosition: number, firstElement: number, currentHeight: number): number {
    if (elementOrder === firstElement) {
      return initialBottomPosition;
    } else {
      return currentHeight - incrementStep;
    }
  }

  public getYAxisLabelTopPosition(elementOrder: number, incrementStep: number, initialBottomPosition: number, firstElement: number, currentHeight: number): number {
    if (elementOrder === firstElement) {
      return initialBottomPosition;
    } else {
      return currentHeight + incrementStep + 10;
    }
  }

  public getInitialTopPositionXAxis(chartHeight: number): number {
    return chartHeight - chartHeight;
  }

  public getInitialBottomPositionXAxis(chartHeight: number, incrementStep: number): number {
    return chartHeight - incrementStep;
  }

  public getInitialYLabelBottomPosition(chartHeight: number, incrementStep: number): number {
    return chartHeight - incrementStep;
  }

  public getInitialYLabelTopPosition(chartHeight: number, incrementStep: number): number {
    return chartHeight - chartHeight + incrementStep;
  }

  public reduceHeightPositionForNextElement(generalBottomHeight: number, xAxisBottom: number, xAxisLabelBottom: number): number {
    return generalBottomHeight - xAxisBottom - xAxisLabelBottom;
  }

  public increaseHeightPositionForNextElement(generalTopHeight: number, xAxiTop: number, xAxisLabelTop: number): number {
    return generalTopHeight + xAxiTop + xAxisLabelTop;
  }

  public getYAxisLabelPosition(xAxisNameLength: number, maxCharacter: number, xAxisLabelPosition: number): number {
    if (xAxisNameLength > maxCharacter) {
      return xAxisLabelPosition - (xAxisNameLength * 5);
    }

    return xAxisLabelPosition - (xAxisNameLength * 10);
  }

  public getMaxDomainYAxis(chartData: Array<IChartDataType | number[]>): number {
    let maxYAxisValue: number = 0;
    const data: IChartDataType = chartData[0] as IChartDataType;
    const keysArray: string[] = Object.keys(data);

    keysArray.forEach((key: string): void => {
      data[key].values.forEach((axis: IChartValues): void => {
        if (axis.y > maxYAxisValue) {
          maxYAxisValue = axis.y;
        }
      });
    });

    return maxYAxisValue;
  }

  public getMinDomainYAxis(chartData: Array<IChartDataType | number[]>, maxYAxis: number): number {
    let minYAxisValue: number = maxYAxis;
    const data: IChartDataType = chartData[0] as IChartDataType;
    const keysArray: string[] = Object.keys(data);

    keysArray.forEach((key: string): void => {
      data[key].values.forEach((axis: IChartValues): void => {
        if (axis.y < minYAxisValue) {
          minYAxisValue = axis.y;
        }
      });
    });

    return minYAxisValue;
  }

  public getXAxisLabelPosition(generalWidth: number, divideValue: number): number {
    return generalWidth / divideValue;
  }

  public getChartHeight(chartDimensions: IChartDimension): number {
    return chartDimensions.chartConfig.height;
  }

  public getChartTopPosition(): number {
    return this.INITIAL_TOP_POSITION;
  }

  public getObjectKey<T extends Object>(reference: T): string[] {
    return Object.keys(reference);
  }

  public generatePositionForEachXAxis(chartData: Array<IChartDataType>, chartDimensions: IChartDimension): Array<IChartDataType | number[]> {
    const xAxisArray: string[] = this.getObjectKey<IChartDataType>(chartData[0]);
    const chartDataCopy: IChartDataType = this.helperService.makeCopy<IChartDataType>(chartData[0]);
    const chartDataWithXYPositions: Array<IChartDataType | number[]> = [];
    const YRange: number[] = [0, 0]
    const X_AXIS_LABEL_POSITION: number = this.getXAxisLabelPosition(chartDimensions.chartConfig.width, this.DIVIDE_VALUE);
    const initialXAxisBottomPosition: number = this.getInitialBottomPositionXAxis(chartDimensions.chartConfig.height, this.INITIAL_BOTTOM_X_AXIS_POSITION);
    const initialYAxisLabelBottomPosition: number = this.getInitialYLabelBottomPosition(chartDimensions.chartConfig.height, this.INITIAL_BOTTOM_Y_AXIS_LABEL_POSITION);
    const initialXAxisTopPosition: number = this.getInitialTopPositionXAxis(chartDimensions.chartConfig.height);
    const initialYAxisTopLabelPosition: number = this.getInitialYLabelTopPosition(chartDimensions.chartConfig.height, this.INITIAL_BOTTOM_Y_AXIS_LABEL_POSITION);
    let currentHeightForBottomElements: number = this.getChartHeight(chartDimensions);
    let currentHeightForTopElements: number = this.getChartTopPosition();

    xAxisArray.forEach((xAxisName: string, index: number): void => {
      const natureOrder: number = index + 1;
      const xAxisNameLength: number = xAxisName.length;

      if (natureOrder <= this.MAX_COUNT_X_AXIS_BOTTOM) {
        chartDataCopy[xAxisName].position = {
          xAxis: this.getXAxisBottomPosition(natureOrder, this.INCREMENT_STEP_X_AXIS_BOTTOM, initialXAxisBottomPosition, this.FIRST_X_AXIS_BOTTOM_ELEMENT, currentHeightForBottomElements),
          yLabel: this.getYAxisLabelBottomPosition(natureOrder, this.INCREMENT_STEP_Y_AXIS_LABEL_BOTTOM, initialYAxisLabelBottomPosition, this.FIRST_X_AXIS_BOTTOM_ELEMENT, currentHeightForBottomElements),
          xLabel: this.getYAxisLabelPosition(xAxisNameLength, this.MAX_CHARACTER_PER_ROW, X_AXIS_LABEL_POSITION),
        }

        currentHeightForBottomElements = this.reduceHeightPositionForNextElement(currentHeightForBottomElements, this.INCREMENT_STEP_X_AXIS_BOTTOM, this.INITIAL_BOTTOM_Y_AXIS_LABEL_POSITION);
        YRange[0] = xAxisArray.length === 1 ? currentHeightForBottomElements + this.OFFSET_FOR_ONE_X_AXIS : currentHeightForBottomElements + this.OFFSET_FOR_MULTIPLE_X_AXIS;
      } else if (natureOrder > this.MAX_COUNT_X_AXIS_BOTTOM && natureOrder <= this.MAX_COUNT_X_AXIS_TOP) {
        chartDataCopy[xAxisName].position = {
          xAxis: this.getXAxisTopPosition(natureOrder, this.INCREMENT_STEP_X_AXIS_TOP, initialXAxisTopPosition, this.NEXT_ELEMENT, currentHeightForTopElements),
          yLabel: this.getYAxisLabelTopPosition(natureOrder, this.INCREMENT_STEP_Y_AXIS_LABEL_TOP, initialYAxisTopLabelPosition, this.FIRST_X_AXIS_BOTTOM_ELEMENT, currentHeightForTopElements),
          xLabel: this.getYAxisLabelPosition(xAxisNameLength, this.MAX_CHARACTER_PER_ROW, X_AXIS_LABEL_POSITION),
        };

        currentHeightForTopElements = this.increaseHeightPositionForNextElement(currentHeightForTopElements, this.INCREMENT_STEP_X_AXIS_TOP,  this.INITIAL_BOTTOM_Y_AXIS_LABEL_POSITION);
        YRange[1] = currentHeightForTopElements - this.OFFSET_FOR_MULTIPLE_X_AXIS;
      }
    });

    chartDataWithXYPositions.push(chartDataCopy, YRange);

    return chartDataWithXYPositions;
  }

  public generateStylesForEachXAxis(chartData: Array<IChartDataType | number[]>, graphStylesConfig: Record<string, IChartStyle>): Array<IChartDataType> {
    const xAxisArray: string[] = this.getObjectKey<IChartDataType>(chartData[0] as IChartDataType);
    const chartDataCopy: IChartDataType = this.helperService.makeCopy<IChartDataType>(chartData[0] as IChartDataType);
    const chartDataWithStyles: Array<IChartDataType> = [];

    xAxisArray.forEach((xAxisName: string): void => {
      chartDataCopy[xAxisName].styles = {
        lineLabelTextColor: graphStylesConfig[xAxisName].lineLabelTextColor,
        xAxisClass: graphStylesConfig[xAxisName].xAxisClass,
        lineColorStroke: graphStylesConfig[xAxisName].lineColorStroke,
      }
    });

    chartDataWithStyles.push(chartDataCopy);

    return chartDataWithStyles;
  }

  public removeObjectWithEmptyValuesArray(reference: IChartDataType): IChartDataType {
    for (let item in reference) {
      if (!reference[item].values.length) {
        delete reference[item];
      }
    }

    return reference;
  }
}
