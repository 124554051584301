import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { REMOVE_STYLES_ON_COMPONENT_DESTROY } from "@angular/platform-browser";

@Component({
  selector: 'app-deactivate-account-dialog',
  templateUrl: './deactivate-account-dialog.component.html',
  styleUrls: ['./deactivate-account-dialog.component.scss']
})
export class DeactivateAccountDialogComponent {
  public title: string;
  public message: string;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: any, public dialogRef: MatDialogRef<DeactivateAccountDialogComponent>,) {
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  protected readonly REMOVE_STYLES_ON_COMPONENT_DESTROY = REMOVE_STYLES_ON_COMPONENT_DESTROY;
}
