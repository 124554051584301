import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { AppConstants } from '@core/constants';
import {TagsManagementDto, TagsManagementResultDto} from '@core/services/tags-management/tags-management-dto';
import { TagsManagementService } from '@core/services/tags-management/tags-management.service';
import { TagDialogComponent } from './tag-dialog/tag-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-tags-management',
  templateUrl: './tags-management.component.html',
})
export class TagsManagementComponent extends PagedListingBaseComponent<TagsManagementDto> {
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: TagsManagementDto[] = [];
  public displayedColumns: string[] = ['id', 'name', 'organization__name', 'action'];
  public isOwnerOrAgent: boolean = false;

  constructor(
    private readonly tagsManagementService: TagsManagementService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  public onInitPage(): void {
    this.isOwnerOrAgent =
      this.auth.role.is_owner || this.auth.role.is_agent_role;
    if (!this.isOwnerOrAgent) {
      this.displayedColumns = this.displayedColumns.filter(
        (item: string): boolean => item !== 'organization__name'
      );
    }
    this.request.sortByDesc = true;
    this.request.sorting = 'id';
    super.onInitPage();
  }
  protected list(request: PagedAndSortedRequestDto, pageNumber: number,): void {
    request.pageIndex = pageNumber;
    this.tagsManagementService.list(request).subscribe((res: TagsManagementResultDto): void => {
      this.dataSource = res.items;
      this.totalItems = res.totalCount;
    });
  }

  public addTag(): void {
    this.openDialog();
  }

  public editTag(element: TagsManagementDto): void {
    this.openDialog(element.id);
  }

  private openDialog(id: number | string = ''): void {
    const dialogRef: MatDialogRef<TagDialogComponent> = this.dialog.open(TagDialogComponent, {
      panelClass: 'general-dialog',
      width: '650px',
      autoFocus: false,
      disableClose: true,
      data: {
        id: id,
      },
    });

    dialogRef.afterClosed().subscribe(
      (res): void => {
        if (res) {
          let message: string = 'Item created successfully';
          if (id) {
            message = 'Item updated successfully';
          }
          this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          this.refresh();
        }
      },
      (error): void => {
        this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
      }
    );
  }

  public deleteTag(id: number): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.tagsManagementService.delete(id).subscribe(
            (): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }

  public getPagedRequestDto(): PagedAndSortedRequestDto {
    return this.request;
  }
}
