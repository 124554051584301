import { Deserializable } from './deserializable';

export class ApiRequest extends Deserializable {
    apiUrl: string = '';
    body: any | undefined;
    created_at: Date | undefined;
    status: string | undefined;
    error: any | undefined;
    constructor() {
        super();
    }

}
