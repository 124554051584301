import { inject, Injectable } from '@angular/core';
import { ApiService } from "@core/services/api.service";
import { Observable, of } from "rxjs";
import { IFeature } from "@shared/components/interfaces/feature.interface";
import { AppConstants } from "@core/constants";

@Injectable()
export class MyInscydService {
  private apiService: ApiService = inject(ApiService);

  public hardCodedSettingTileList: IFeature[] = [
    {
      urlNavigateTo: '/home/administration/training-zone-template',
      id: 0,
      category_name: 'App',
      tags: ['App'],
      is_published: true,
      category: 5,
      name: 'Training Zone Builder',
      is_recommended: false,
      marketing_features: [],
      dependencies: [],
      description_html: {},
      created_at: '',
      description: 'Create custom training zones to target specific training adaptations',
      discounts: [],
      is_delete: false,
      is_enabled: true,
      links: [],
      slug: '',
      suggested_courses: [],
      summary: 'Training Zone Building',
      updated_at: '',
      waitlist: true,
      buttonName: 'set up',
    },
    {
      urlNavigateTo: '/home/custom-pdf-text',
      id: 0,
      category_name: 'App',
      tags: ['App'],
      is_published: true,
      category: 5,
      name: 'PDF Report Texts',
      is_recommended: false,
      marketing_features: [],
      dependencies: [],
      description_html: {},
      created_at: '',
      description: 'Create your own texts to show up on the PDF reports',
      discounts: [],
      is_delete: false,
      is_enabled: true,
      links: [],
      slug: '',
      suggested_courses: [],
      summary: 'Training Zone Building',
      updated_at: '',
      waitlist: true,
      buttonName: 'Customize',
    },
    {
      urlNavigateTo: '/home/custom-athlete-category',
      id: 0,
      category_name: 'General',
      tags: ['General'],
      is_published: true,
      category: 0,
      name: 'Athlete categories & grading',
      is_recommended: false,
      marketing_features: [],
      dependencies: [],
      description_html: {},
      created_at: '',
      description: 'Create custom categories for different group of athletes and set up custom grading system to be used in the dashboards',
      discounts: [],
      is_delete: false,
      is_enabled: true,
      links: [],
      slug: '',
      suggested_courses: [],
      summary: 'Custom Athlete Category',
      updated_at: '',
      waitlist: true,
      buttonName: 'Customize',
    },
  ];

  public getMyInscydFeatureList(): Observable<IFeature[]> {
    return this.apiService.get(AppConstants.API.MY_INSCYD_FEATURE_ITEM.GET);
  }

  public getHardCodedSettingTileList(): Observable<IFeature[]> {
    return of(this.hardCodedSettingTileList);
  }
}
