<div class="performance-limits u-border-radius border-color u-padding-16 tile-color-background">
  <span *ngIf="angleName" class="primary-text-color line-height-12 u-font-weight-700 u-font-size-xsmall u-margin-bottom-8 u-text-uppercase u-display-block">{{angleName}}</span>
  <span class="u-display-block u-margin-bottom-8 u-font-size-16 u-font-weight-700 line-height-18">Set Performance Limits for: {{config.metricName}}</span>
  <span class="u-display-block u-margin-bottom-20 performance-limits__sub-text u-font-size-14 u-font-weight-400 line-height-16">{{config.description}}</span>
  <div>
    <mat-tab-group>
      <mat-tab [formGroup]="performanceLimitsProForm" [label]="performanceLimitsProForm.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [formGroup]="performanceLimitsAmateurForm" [label]="performanceLimitsAmateurForm.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [formGroup]="performanceLimitsRecreationalForm" [label]="performanceLimitsRecreationalForm.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsProForm.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="performanceLimitsCustomForm1" [formGroup]="performanceLimitsCustomForm1" [label]="performanceLimitsCustomForm1.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm1.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm1.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm1.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm1.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm1.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm1.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="performanceLimitsCustomForm2" [formGroup]="performanceLimitsCustomForm2" [label]="performanceLimitsCustomForm2.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm2.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm2.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm2.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm2.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm2.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm2.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="performanceLimitsCustomForm3" [formGroup]="performanceLimitsCustomForm3" [label]="performanceLimitsCustomForm3.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm3.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm3.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm3.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm3.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm3.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm3.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="performanceLimitsCustomForm4" [formGroup]="performanceLimitsCustomForm4" [label]="performanceLimitsCustomForm4.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm4.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm4.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm4.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm4.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm4.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm4.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="performanceLimitsCustomForm5" [formGroup]="performanceLimitsCustomForm5" [label]="performanceLimitsCustomForm5.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm5.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm5.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm5.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm5.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm5.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm5.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab *ngIf="performanceLimitsCustomForm6" [formGroup]="performanceLimitsCustomForm6" [label]="performanceLimitsCustomForm6.get('name')?.value">
        <div class="u-margin-top-20">
          <div class="performance-limits__gender-inputs border-color u-padding-16 u-margin-bottom-20">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Male</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm6.get('male_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm6.get('male_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="male_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm6.get('male_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="performance-limits__gender-inputs border-color u-padding-16">
            <span class="u-display-block u-font-size-16 u-font-weight-400 line-height-24">Female</span>
            <div class="u-margin-top-10">
              <div class="o-flex o-flex--justify-space-between">
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Upper</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_upper"/>
                  <mat-error *ngIf="performanceLimitsCustomForm6.get('female_upper')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Lower</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_lower"/>
                  <mat-error *ngIf="performanceLimitsCustomForm6.get('female_lower')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" class="width-32-percent">
                  <mat-label>Std/Dev</mat-label>
                  <input type="number" min="1" matInput placeholder="Enter Value" formControlName="female_stdDev"/>
                  <mat-error *ngIf="performanceLimitsCustomForm6.get('female_stdDev')?.hasError('min')">should be > 0</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
