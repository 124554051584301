import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { IRightDetail, RightsManagementDto, RightsManagementResultDto } from './rights-management-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from '@shared/models/api-request';

@Injectable({
  providedIn: 'root',
})
export class RightsManagementService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto): Observable<RightsManagementResultDto> {
    const apiUrl: string = this._appConstants.API.RIGHTS_MANAGEMENT.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return this.mappingDataRightList(res);
      })
    );
  }

  public create(input: IRightDetail): Observable<Object> {
    const apiUrl: string = this._appConstants.API.RIGHTS_MANAGEMENT.ADD;
    const body = {};
    Object.assign(body, input);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService.post(apiRequest.apiUrl, apiRequest.body);
  }

  public update(id: number, input: IRightDetail): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.RIGHTS_MANAGEMENT.UPDATE, id);
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    Object.assign(body, input);

    return this._apiService.put(apiRequest.apiUrl, apiRequest.body);
  }

  public getById(id: number, isHideLoadingInFinalize: boolean = false, isShowLoading: boolean = true): Observable<IRightDetail> {
    const apiUrl: string = String.Format(this._appConstants.API.RIGHTS_MANAGEMENT.GET,id);

    return this._apiService.get(apiUrl, isHideLoadingInFinalize, isShowLoading);
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(this._appConstants.API.RIGHTS_MANAGEMENT.DELETE, id);

    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }

  public listActive(isHideLoadingInFinalize = true): Observable<RightsManagementResultDto> {
    const apiUrl: string = this._appConstants.API.RIGHTS_MANAGEMENT.LIST + '?is_active=1';
    const body = {};
    const apiRequest: ApiRequest = new ApiRequest().deserialize({body, apiUrl,});

    return this._apiService
      .get(apiRequest.apiUrl, isHideLoadingInFinalize)
      .pipe(
        map((res: any) => {
          return this.mappingDataRightList(res);
        })
      );
  }

  private mappingDataRightList(res: any): RightsManagementResultDto {
    return new RightsManagementResultDto().deserialize({
      totalCount: res.count,
      items: res.results.map((item: RightsManagementDto) =>
        new RightsManagementDto().deserialize(item)
      ),
    });
  }
}
