<app-loader-indicator></app-loader-indicator>
<div class="u-screen-height additional-info-page">
  <mat-card class="addinfo__card">
    <div class="addinfo__card__header">
      <mat-card-title>Create your INSCYD account</mat-card-title> 
      <mat-card-subtitle>Finish setting up your account and choose some sample datasets to get you started</mat-card-subtitle>
    </div>
    <mat-card-content>

      <form class="addinfo__form" [formGroup]="additionalInfoForm" (ngSubmit)="submit()">
        <h3 class="text-header">Company details</h3>
        <div class="addinfo__form__group">
          <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
            <mat-label>Company name</mat-label>
            <input matInput formControlName="organizationName" placeholder="Company name" required />
          </mat-form-field>
          <div class="u-color-white addinfo__form__control">
            <div class="addinfo__fileinput__label">Company Logo</div>
            <div class="addinfo__fileinput__field">
              <input type="file" #filePicker (change)="onFilePicked($event)" accept="image/png, image/jpeg" />
            </div>
          </div>
        </div>
        <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
          <mat-label>VAT number</mat-label>
          <input matInput formControlName="vatNumber" placeholder="VAT Number" />
        </mat-form-field>

        <h3 class="text-header">Company legal address</h3>
        <app-address-form formControlName="address" [countries]="countryList" [horizontal]="true" [address]="address"></app-address-form>

        <p class="text-header">Contact information</p>
        <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
          <mat-label>Phone number</mat-label>
          <input matInput formControlName="phone" placeholder="Phone number" />
        </mat-form-field>
        <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
          <mat-label>Company email address</mat-label>
          <input matInput formControlName="companyEmailAddress" placeholder="Company email address" />
        </mat-form-field>
        <mat-form-field class="u-color-white addinfo__form__control" floatLabel="auto">
          <mat-label>Website URL</mat-label>
          <input matInput formControlName="website" placeholder="Website URL" />
        </mat-form-field>

        <h3 class="text-header">Do you have a different address for your office or human performance lab?</h3>
        <p class="small-text">Here you can define a separate address in case the company legal address (defined above) differs from your office address</p>
        <mat-checkbox formControlName="sameInvoiceAddress">Same as company legal address</mat-checkbox>
        <app-address-form *ngIf="showInvoiceAddressForm" formControlName="invoiceAddress" [countries]="countryList" [horizontal]="true"></app-address-form>
      </form>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="submit()" matTooltip="Confirm" [disabled]="!additionalInfoForm.valid">Confirm</button>
      <button mat-button (click)="logout()" matTooltip="Logout">Logout</button>
    </mat-card-actions>
  </mat-card>
</div>
