import { Injectable, inject } from "@angular/core";
import { Router, CanActivateFn } from "@angular/router";
import { HelperService } from "@shared/services/helper.service";
import { AppConstants } from "@core/constants";

@Injectable()
export class DevelopmentOnlyGuardService {
  private router: Router = inject(Router);
  private helperService: HelperService = inject(HelperService);

  public canActivate(): boolean {
    const isProductionEnvironment: boolean = this.helperService.isProductionEnvironment(location, AppConstants.ENVIRONMENT_URL.PROD);
    if (!isProductionEnvironment) {
      return true;
    } else {
      this.router.navigateByUrl('/home/dashboard');
      return false;
    }
  }
}

export const DevelopmentOnlyGuard: CanActivateFn = (): boolean => {
  return inject(DevelopmentOnlyGuardService).canActivate();
};
