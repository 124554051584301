<div class="page-container">
  <app-custom-back-button *ngIf="tabIndexToReturn" [customUrl]="customUrl"></app-custom-back-button>
  <div class="o-flex o-flex--align-items-center u-margin-bottom-50">
    <div class="general-info--font-size-35 line-height-28 u-font-weight-400 u-margin-right-10">Custom Athlete Category</div>
    <app-label class="u-right-padding-5" *ngFor="let tag of tags" [text]="tag" [isBeta]="isBeta()"></app-label>
  </div>
  <div class="o-flex o-flex--align-items-center">
  </div>
  <mat-dialog-content>
    <div [formGroup]="form">
      <span class="u-font-size-14 u-font-weight-700 line-height-normal">Default categories</span>
      <hr>
      <br>
      <div class="inherit-width background-color u-border-radius u-padding-10 u-margin-bottom-30">
        <ul>
          <ng-container *ngFor="let item of listTrainingLevel; index as i">
            <li class="athlete-category-list-item u-capitalize" *ngIf="item?.is_default_level">{{ item.value }}</li>
          </ng-container>
        </ul>
      </div>
      <span class="u-font-size-14 u-font-weight-700 line-height-normal">Custom Categories</span>
      <hr>
      <br>
      <div class="o-flex o-flex--justify-space-between">
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 1</mat-label>
          <input matInput formControlName="custom1" />
          <mat-error *ngIf="form.controls?.custom1?.errors && form.controls?.custom1?.touched           ">
            <div [innerHTML]="form.controls.custom1.errors | errorFormFormat: { fieldName: 'Athlete Category 1' }"></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 2</mat-label>
          <input matInput formControlName="custom2" />
          <mat-error *ngIf="form.controls?.custom2?.errors && form.controls?.custom2?.touched">
            <div [innerHTML]="form.controls.custom2.errors | errorFormFormat: { fieldName: 'Athlete Category 2' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="o-flex o-flex--justify-space-between">
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 3</mat-label>
          <input matInput formControlName="custom3" />
          <mat-error *ngIf="form.controls?.custom3?.errors && form.controls?.custom3?.touched">
            <div [innerHTML]="form.controls.custom3.errors | errorFormFormat: { fieldName: 'Athlete Category 3' }"></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 4</mat-label>
          <input matInput formControlName="custom4" />
          <mat-error *ngIf="form.controls?.custom4?.errors && form.controls?.custom4?.touched">
            <div [innerHTML]="form.controls.custom4.errors | errorFormFormat: { fieldName: 'Athlete Category 4' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="o-flex o-flex--justify-space-between">
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 5</mat-label>
          <input matInput formControlName="custom5" />
          <mat-error *ngIf="form.controls?.custom5?.errors && form.controls?.custom5?.touched">
            <div [innerHTML]="form.controls.custom5.errors | errorFormFormat: { fieldName: 'Athlete Category 5' }"></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 6</mat-label>
          <input matInput formControlName="custom6" />
          <mat-error *ngIf="form.controls?.custom6?.errors && form.controls?.custom6?.touched">
            <div [innerHTML]="form.controls.custom6.errors | errorFormFormat: { fieldName: 'Athlete Category 6' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div class="full-width o-flex o-flex--justify-space-between u-margin-top-20">
      <button class="width-49-percent u-border-color" mat-button type="button" (click)="back()">Cancel</button>
      <button class="width-49-percent" mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
  </mat-dialog-actions>

  <br /><hr />
  <div class="details-wrapper">
    <div class="details">
      <h1 class="details__header">
        Instructions for Custom Athlete Categories
      </h1>

      <p class="details__content">
        Here you can create and customize up to six additional athlete categories.
        These are additional categories other than the three pre-defined categories: 
        “professional”, “amateur” and “recreational”. By using custom athlete
        categories you can ensure that the visualizations in the performance benchmark
        and fingerprint chart align best with the aims and demands of your athletes.
      </p>

      <p class="details__content">
        Each category has defined ranges for male and female athletes for every
        metric shown in the physiological performance benchmarks and metabolic
        fingerprint chart. These reference ranges are specific to the sport and
        gender of the athlete.
      </p>

      <h2 class="details__header--small">
        Usage
      </h2>

      <p class="details__content">
        The default custom categories are named from “custom 1” to “custom 6”.
        These default categories are generally not visible within the app. In
        order to start using a specific category, you need to assign it a unique name

        <details>
          <summary>Assigning a unique name to a custom category</summary>
          Given that you have the categories named “custom 1” to “custom 6” in the 
          configuration section above, select one of the category inputs, provide
          a different name for it and the save the configuration:

          <ol>
            <li>Select a category input in the configuration above. For example “custom 1”</li>
            <li>Change the name to something more descriptive of the athlete training level. For example “Expert”</li>
            <li>Save the configuration using the red 'Save' button</li>
          </ol>
        </details>

        Upon entering a name for a custom category here, this custom athlete
        category will become available in:
      </p>

      <h3>Creating and editing athletes</h3>
      <p class="details__content">
        You can assign this custom category to any of your athletes
      </p>

      <h3>Custom Benchmarks</h3>
      <p class="details__content">
        This is available in our Visualization page, under Settings -> Customization.
        You can now customize the upper and lower boundaries for your newly created
        categories. These boundaries are specific to <strong>gender</strong> and <strong>sport</strong>
      </p>
      <p class="details__content">
        If you do not configure the additional athlete categories, default values of
        the builtin category “amateur” will be used. You will be informed of this
        default setting when reviewing a test in the APC
      </p>
    </div>
  </div>
</div>
