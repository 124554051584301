import { DestroyRef, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodGuard {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private readonly dialog: MatDialog,
    private destroyRef: DestroyRef,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const authString: string = this.sessionService.getCookie('auth');
    const rights = this.sessionService.rights;
    if (authString && rights) {
      const auth = JSON.parse(authString);
      const currentURL: string = route.pathFromRoot
        .map((v: ActivatedRouteSnapshot) => v.url.map((segment: UrlSegment) => segment.toString()).join('/'))
        .join('/');
      const isValidURL: boolean | '' = currentURL && currentURL !== '/home/plan-subscription//my-payment-method';
      const isValidAccessPageMyPaymentMethod =
        auth.organization.is_check_invoice &&
        rights.subscription_view_payment_method_page;
      if (auth.is_admin && isValidURL && isValidAccessPageMyPaymentMethod) {
        const isForceUpdatePaymentMethod =
          auth.organization?.is_force_update_payment_method &&
          !auth.organization?.active_payment_method_type;
        const isValidCard =
          !auth?.error_flag?.is_have_failed_invoice &&
          auth?.error_flag?.is_payment_method &&
          auth?.error_flag?.is_payment_method_valid;
        if (
          isForceUpdatePaymentMethod ||
          (auth.organization?.is_force_update_payment_method &&
            auth.organization?.active_payment_method_type &&
            !isValidCard)
        ) {
          const message: string = 'Our system detect that your payment method invalid. Please update new payment method to continue using our system.';
          const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Please update payment method', message, 'OK', '', true);
          const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: dialogData,
            disableClose: true,
          });

          dialogRef.afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((): void => {
            this.router.navigateByUrl('/home/plan-subscription/my-payment-method');
          });

          return false;
        }
      }

      return true;
    } else {
      return false;
    }
  }
}
