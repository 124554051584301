import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplate',
  templateUrl: './autocomplate.component.html',
})
export class AutocomplateComponent implements OnChanges {
  public inputControl!: UntypedFormControl;
  public filteredOptions!: Observable<any[]>;
  private _oldValue!: any;

  @Input() public options!: any[];
  @Input() public label!: string;
  @Input() public form!: any;
  @Input() public showErrorRequired: boolean = false;
  @Input() public displayField: string = 'name';
  @Output() public optionChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputAutoComplete') public inputAutoComplete!: ElementRef<HTMLInputElement>;

  public ngOnChanges(): void {
    this.inputControl = this.form as UntypedFormControl;
    if (this.options && this.inputControl) {
      this.filteredOptions = this.inputControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value?.name)),
        map((name) => (name ? this._filter(name) : this.options.slice()))
      );
    }
  }

  public displayFn(item: any): string {
    let result = null;
    if (!item) {
      result = '';
    } else {
      result = item[this.displayField];
    }
    return result;
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter((option) => {
      return option[this.displayField]?.toLowerCase()?.includes(filterValue);
    });
  }

  public optionSelected(): void {
    this.inputAutoComplete.nativeElement.blur();
    this.proceedSelected();
  }

  public proceedSelected(): void {
    const value =
      this.displayField == 'viewValue'
        ? this.inputControl.value.value
        : this.inputControl.value.id;
    this.optionChange.emit(value);
  }

  public onOpened(): void {
    this._oldValue = this.inputControl.value;
    this.inputControl.setValue('');
  }

  public onClosed(): void {
    if (!this.inputControl.value && this._oldValue) {
      this.inputControl.setValue(this._oldValue);
      this.proceedSelected();
    }
  }
}
