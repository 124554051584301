import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-y-axis-button',
  templateUrl: './y-axis-button.component.html',
  styleUrls: ['./y-axis-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YAxisButtonComponent {
  @Input() public buttonName: string;
}
