import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ChapterDto } from "@core/services/courses/course-dto";

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChapterComponent {
  @Input() public chapterList: ChapterDto[];
}
