<div class="form" [formGroup]="addressFormGroup">
  <div class="form__controlgroup">
    <div [class.form__controlgroup__horizontal]="horizontal">
      <div class="form__control">
        <mat-form-field class="u-color-white full-width" floatLabel="auto">
          <mat-label>Street name</mat-label>
          <input matInput formControlName="streetName" placeholder="Street name" />
        </mat-form-field>
      </div>
      <div class="form__control form__control--small">
        <mat-form-field class="u-color-white full-width" floatLabel="auto">
          <mat-label>Street number</mat-label>
          <input matInput formControlName="streetNumber" placeholder="Street number" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="form__controlgroup">
    <div [class.form__controlgroup__horizontal]="horizontal">
      <div class="form__control">
        <mat-form-field class="u-color-white full-width" floatLabel="auto">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" placeholder="City" />
        </mat-form-field>
      </div>
      <div class="form__control">
        <mat-form-field class="u-color-white full-width" floatLabel="auto">
          <mat-label>State</mat-label>
          <input matInput formControlName="state" placeholder="State" />
        </mat-form-field>
      </div>
      <div class="form__control form__control--small">
        <mat-form-field class="u-color-white full-width" floatLabel="auto">
          <mat-label>ZIP Code</mat-label>
          <input matInput formControlName="zip" placeholder="ZIP Code" />
        </mat-form-field>
      </div>
    </div>
    <div class="form__control">
      <mat-form-field class="u-color-white full-width" floatLabel="auto">
        <mat-label>Country</mat-label>
        <mat-select formControlName="country" placeholder="Country" required>
          <mat-option *ngFor="let cty of countries" [value]="cty.id">{{cty.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>