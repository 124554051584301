import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, Subject } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PaymentLinkDialogDataInterface } from '../interfaces/payment-link-dialog-data.interface';
import { PaymentLinkInterface } from '../interfaces/payment-link.interface';
import { PaymentLinkState } from './payment-link-dialog.state';
import { AccountTypeDto } from '@core/services/account-types/account-type-dto';
import { AppConstants } from '@core/constants';

const DEFAULT_ACCOUNT_TYPE_NAME = "Commercial User";

@Component({
  selector: 'app-payment-link-dialog',
  templateUrl: './payment-link-dialog.component.html',
  styleUrls: ['./payment-link-dialog.component.scss'],
  providers: [PaymentLinkState]
})
export class PaymentLinkDialogComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  public loading$: Observable<boolean>;

  public paymentLinkForm!: FormGroup;
  public paymentLink: PaymentLinkInterface;
  public accountTypes: AccountTypeDto[];
  public accountTypeOptions: AccountTypeDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentLinkDialogDataInterface,
    public dialogRef: MatDialogRef<PaymentLinkDialogComponent>,
    private fb: FormBuilder,
    private state: PaymentLinkState,
    private readonly snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loading$ = this.state.loading$;
    this.state.paymentLink$.subscribe((paymentLink: PaymentLinkInterface) => {
      this.paymentLink = paymentLink;
    })
    this.state.accountTypes$.subscribe(accountTypes => this.accountTypes = accountTypes);
    this.initForm();
    this.state.fetchAccountTypes().subscribe(() => {
      const accountTypeIds = this.data.plan.account_types || [];
      if (accountTypeIds.length === 0) {
        this.accountTypeOptions = this.accountTypes;
      } else {
        this.accountTypeOptions = this.accountTypes.filter(a => accountTypeIds.includes(a.id!));
      }
      this.initFormValues();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public cancel() { this.dialogRef.close([false, undefined]); }

  public sendEmail() {
    this.state.emailPaymentUrl(this.data.plan.id!, this.paymentLink, this.paymentLinkForm.get("email")?.value)
      .subscribe(() => {
        this.snackBar.open("Email successfuly sent", 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.dialogRef.close([true, undefined]);
      })
  }

  public generatePaymentLink() {
    this.state.generatePaymentLink(this.data.plan.id!, this.paymentLinkForm.get("accountType")?.value);
  }

  public canGenerateLink() {
    return this.paymentLinkForm.get("accountType")?.value != null;
  }

  public showAccountType(): boolean {
    return (this.data.plan.account_types || []).length !== 1;
  }

  private initForm() {
    this.paymentLinkForm = this.fb.group({
      email: new FormControl<string>("", [Validators.email, Validators.required]),
      accountType: new FormControl<number | undefined>(undefined)
    });
  }

  private initFormValues() {
    const accountTypes = this.data.plan?.account_types || [];
    if (accountTypes.length === 0) {
      this.paymentLinkForm.patchValue({
        accountType: this.defaultAccountType().id
      });
    } else if (accountTypes.length === 1) {
      this.paymentLinkForm.patchValue({
        accountType: accountTypes[0]
      });
    }
  }

  private defaultAccountType(): AccountTypeDto {
    const commercialUser = this.accountTypes.find(a => a.name === DEFAULT_ACCOUNT_TYPE_NAME);
    return commercialUser || this.accountTypes[0];
  }
}
