import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AngularEditorComponent, AngularEditorConfig } from "@kolkov/angular-editor";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
})
export class TextEditorComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() public id!: string;
  @Input() public width!: number;
  @Input() public height!: number;
  @Input() public maxHeight!: number;
  @Input() public content: string = '';
  @Input() public initHeight: number;

  @Output() public textChanged = new EventEmitter<{text: string, id: string}>();
  @Output() public focusEvent: EventEmitter<{id: string, inFocus: boolean}> = new EventEmitter<{id: string, inFocus: boolean}>();
  @Input() public set type(type: any) {
    this.previewType = type;
    if (type === AppConstants.TEXT_EDITOR_VIEW_TYPE.BUILDER) {
      this.config.showToolbar = false;
    }
  }

  @ViewChild(AngularEditorComponent) public angularEditor: AngularEditorComponent;

  public model: string = '';
  public resizeObserver: ResizeObserver = new ResizeObserver((): void => {});
  private _modelChanges$: Subject<string> = new Subject<string>();
  public previewType: any;
  public config: AngularEditorConfig;

  constructor(private destroyRef: DestroyRef,) {}

  public ngOnInit(): void {
    this.config = {
      editable: true,
      spellcheck: true,
      width: `${this.width}px`,
      height: `${this.height}px`,
      minHeight: `${this.height}px`,
      maxHeight: `${this.maxHeight ? this.maxHeight : this.height}px`,
      placeholder: 'Enter text here...',
      translate: 'no',
      showToolbar: true,
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      defaultFontSize: '2',
      toolbarPosition: 'bottom',
      toolbarHiddenButtons: [
        ['insertVideo'], ['toggleEditorMode'], ['backgroundColor'], ['customClasses'], ['fontName'], ['justifyLeft'],
        ['justifyCenter'], ['justifyRight'], ['justifyFull'], ['removeFormat'], ['link'], ['unlink'], ['insertImage']
      ],
    };

    this.model = this.content;
    this._modelChanges$.pipe(
      debounceTime(400),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(event => {
      this.textChanged.emit({text: event, id: this.id});
    });
  }

  public ngAfterViewInit(): void {
    const resizableElem: HTMLElement = this.angularEditor.textArea.nativeElement;
    resizableElem.style.height = this.initHeight * 5 - this._getToolbarHeight() + 'px';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.initHeight && !changes.initHeight.firstChange && changes.initHeight.previousValue !== changes.initHeight.currentValue) {
      (this.angularEditor.textArea.nativeElement as HTMLElement).style.height = changes.initHeight.currentValue * 5 - this._getToolbarHeight() + 'px';
    }
  }

  public ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.angularEditor.textArea.nativeElement as HTMLElement);
  }

  public getModel(): string {
    return this.model;
  }

  public onBlur(): void {
    this._modelChanges$.next(this.model);
    this.focusEvent.emit({id: this.id, inFocus: false});
  }

  public onFocus(): void {
    this.focusEvent.emit({id: this.id, inFocus: true});
  }

  public onModelChanged(event: string): void {
    this._modelChanges$.next(event);
  }

  private _getToolbarHeight(): number {
    const resizableElem: HTMLElement = this.angularEditor.textArea.nativeElement;
    const parent: HTMLElement= resizableElem.closest('.angular-editor')!;
    const toolBar: HTMLElement = parent.querySelector('.angular-editor-toolbar')!;
    return toolBar.offsetHeight + 48;
  }
}
