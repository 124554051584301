<section class="background-color integration u-padding-16">
    <header class="integration__header line-height-24 u-font-size-16">
      {{template?.name}}
      <div class="integration__subheader line-height-16 u-font-size-14">
        <a href="{{template?.website}}" target="_blank">{{template?.website}}</a>
      </div>
    </header>
    <div class="integration__content border-color background-color-intense u-margin-top-20 u-margin-bottom-8">
      <form class="o-flex o-flex--column u-margin-bottom-m" [formGroup]="integrationForm">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>Subscription</mat-label>
          <mat-select placeholder="Subscription to assign tests to" formControlName="purchase" required>
            <mat-option *ngFor="let purchase of purchases" [value]="purchase.id">{{ purchase.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle formControlName="is_active">Integration active?</mat-slide-toggle>
      </form>
    </div>
    <div class="integration__buttons">
      <button mat-raised-button color="primary" [disabled]="!canSave()" (click)="save()">Save</button>
    </div>
  </section>
  