import { Component, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppBaseComponent } from '@shared/components/app-component-base';
import { SnackbarComponent } from '@shared/snackbar/snackbar.component';
import * as _ from 'lodash-es';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AthleteTrainingLevelDto } from '@core/services/athletes-management/athletes-training-level-dto';
import { AthletesService } from '@core/services/athletes-management/athletes.service';
import { SportDto } from '@core/services/sports/sport-dto';
import { SportService } from '@core/services/sports/sport.service';
import { String } from 'typescript-string-operations';

@Component({
  selector: 'app-sport-detail',
  templateUrl: './sport-detail.component.html',
  styleUrls: ['./sport-detail.component.scss'],
})
export class SportDetailComponent extends AppBaseComponent {
  public formInfor: UntypedFormGroup = {} as UntypedFormGroup;
  public formSetting: UntypedFormGroup = {} as UntypedFormGroup;
  public formConditions: UntypedFormGroup = {} as UntypedFormGroup;
  public formAthleteCategory: UntypedFormGroup = {} as UntypedFormGroup;
  public isShowDetailSetting: boolean = false;
  public isShowDetailCondition: boolean = false;
  public tableData: Array<any> = [];
  public isEditMode: boolean = false;
  public sportDetail?: SportDto;
  public referenceOptions: Array<any> = [];
  public secondaryReferenceOptions: Array<any> = [];
  public athleteCategoryOptions: Array<AthleteTrainingLevelDto> = [];
  public listAthleteCategorySelected: Array<AthleteTrainingLevelDto> = [];
  public displayedColumns: string[] = [
    'parameter',
    'unit',
    'performance_capacities',
    'metabolic_fingerprint',
    'performance_development',
    'level',
    'gender',
    'upper_boundary',
    'lower_boundary',
    'std_dev',
  ];
  public defaultSportConstant = this.constant.SPORT_DEFAULT_CONSTANTS;
  public performanceDevelopmentOptions = this.constant.SPORT_PERFORMANCE_DEVELOPMENT;
  public sportTypeOptions = this.constant.SPORT_SIMULATION_TYPES;
  public errorMessageTable: Array<any> = [];
  public level_list: string[] = ['pro', 'amateur', 'recreational'];

  constructor(
    public dialogRef: MatDialogRef<SportDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: UntypedFormBuilder,
    private readonly sportService: SportService,
    private readonly snackBar: MatSnackBar,
    private readonly athletesService: AthletesService
  ) {
    super();
  }

  public onInitPage(): void {
    this.isEditMode = !!this.data?.id;
    this.initForm();
    this.initDataAndOptions();
  }

  private initDataAndOptions(): void {
    this.isDataLoading = true;
    this.getReferentOptions();
    forkJoin([
      this.athletesService.getAthleteTrainingLevel(true),
      this.isEditMode ? this.sportService.getSportById(this.data.id) : of(null),
    ])
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);

          throw error;
        }),
        finalize((): boolean => (this.isDataLoading = false))
      )
      .subscribe(([resTrainingOptions, resSportDetail]): void => {
        this.athleteCategoryOptions = resTrainingOptions.items;
        if (resSportDetail) {
          this.sportDetail = resSportDetail;
          this.initBaseValue();
          this.loadEBGConditions();
          this.loadSportExtraSettings();
        }
      });
  }

  private initForm(): void {
    this.formInfor = this.fb.group({
      name: new UntypedFormControl(
        { value: '', disabled: this.isReadOnly },
        Validators.required
      ),
      simulation_type: new UntypedFormControl('', Validators.required),
      primary_type: new UntypedFormControl('', Validators.required),
      secondary_type: new UntypedFormControl(0, Validators.required),
      muscle_usage_percentage: new UntypedFormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      min_value: new UntypedFormControl(
        !this.isEditMode ? this.defaultSportConstant.min_value : '',
        [Validators.required, Validators.min(0), Validators.max(500)]
      ),
      max_value: new UntypedFormControl(
        !this.isEditMode ? this.defaultSportConstant.max_value : '',
        [Validators.required, Validators.min(1), Validators.max(1000)]
      ),
      vo_weight: new UntypedFormControl(''),
      vl_weight: new UntypedFormControl(''),
      at_weight: new UntypedFormControl(''),
      use_in_ppd: new UntypedFormControl(false),
      duration_steady_state: new UntypedFormControl('', Validators.required),
    });

    this.formSetting = this.fb.group({
      ebg_factor_a: new UntypedFormControl(
        {
          value: !this.isEditMode ? this.defaultSportConstant.ebg_factor_a : '',
          disabled: this.isReadOnly,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      ebg_factor_b: new UntypedFormControl(
        {
          value: !this.isEditMode ? this.defaultSportConstant.ebg_factor_b : '',
          disabled: this.isReadOnly,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      ebg_factor_d: new UntypedFormControl(
        {
          value: !this.isEditMode ? this.defaultSportConstant.ebg_factor_d : '',
          disabled: this.isReadOnly,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      o2_per_watt_eq_a: new UntypedFormControl(
        {
          value: !this.isEditMode
            ? this.defaultSportConstant.o2_per_watt_eq_a
            : '',
          disabled: this.isReadOnly,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      o2_per_watt_eq_b: new UntypedFormControl(
        {
          value: !this.isEditMode
            ? this.defaultSportConstant.o2_per_watt_eq_b
            : '',
          disabled: this.isReadOnly,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      o2_per_watt_eq_d: new UntypedFormControl(
        {
          value: !this.isEditMode
            ? this.defaultSportConstant.o2_per_watt_eq_d
            : '',
          disabled: this.isReadOnly,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
    });
    this.formConditions = this.fb.group({
      conditions: this.fb.array([]),
    });

    this.formAthleteCategory = this.fb.group({
      categories: new UntypedFormControl(),
    });
  }

  private getReferentOptions(): void {
    this.referenceOptions = [
      ...this.constant.REFERENCE_SYSTEMS_SPEED,
      ...this.constant.REFERENCE_SYSTEMS_POWER,
    ];
    this.secondaryReferenceOptions = [
      {
        id: 0,
        name: 'None',
        unit: '',
      },
      ...this.constant.REFERENCE_SYSTEMS_SPEED,
      ...this.constant.REFERENCE_SYSTEMS_POWER,
    ];
  }

  public get conditions(): UntypedFormArray {
    return this.formConditions.get('conditions') as UntypedFormArray;
  }

  public get isReadOnly(): boolean {
    return !this.auth.role.is_owner && !this.auth.role.is_agent_role;
  }

  public isCustomAthleteCategoryReadOnly(level: any): boolean {
    if (!this.isReadOnly) {
      return false;
    } else {
      const result: string | undefined = _.find(this.level_list, (item: string): boolean => {
        return item == level.value;
      });

      return !!result;
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  private initBaseValue(): void {
    const {
      name,
      simulation_type,
      primary_type,
      secondary_type,
      muscle_usage_percentage,
      reference_system_settings,
      min_value,
      max_value,
      vo_weight,
      vl_weight,
      at_weight,
      use_in_ppd,
      ebg_factor_a,
      ebg_factor_b,
      ebg_factor_d,
      o2_per_watt_eq_a,
      o2_per_watt_eq_b,
      o2_per_watt_eq_d,
      duration_steady_state,
    } = this.sportDetail as SportDto;

    this.formInfor.patchValue({
      name,
      simulation_type,
      primary_type,
      secondary_type,
      muscle_usage_percentage,
      min_value,
      max_value,
      vo_weight,
      vl_weight,
      at_weight,
      use_in_ppd,
      duration_steady_state
    });

    if (
      !this.auth.role.is_owner &&
      !this.auth.role.is_agent_role &&
      reference_system_settings
    ) {
      this.formInfor.patchValue({
        primary_type: reference_system_settings.primary_type,
        secondary_type: reference_system_settings.secondary_type,
        muscle_usage_percentage:
          reference_system_settings.muscle_usage_percentage,
      });
    }
    this.formSetting.patchValue({
      ebg_factor_a,
      ebg_factor_b,
      ebg_factor_d,
      o2_per_watt_eq_a,
      o2_per_watt_eq_b,
      o2_per_watt_eq_d,
    });

    const currentTrainingLevel: AthleteTrainingLevelDto[] =
      this.athleteCategoryOptions.filter((item: AthleteTrainingLevelDto) => {
        const level: number | undefined = this.sportDetail?.training_level?.find(
          (levelID: any): boolean => levelID === item.id
        );

        return !!level;
      }) || [];
    this.listAthleteCategorySelected = _.cloneDeepWith(currentTrainingLevel);
    this.formAthleteCategory.patchValue({
      categories: currentTrainingLevel,
    });
  }

  private loadEBGConditions(): void {
    if (this.sportDetail?.ebg_conditions) {
      const dataCondition = JSON.parse(this.sportDetail.ebg_conditions);
      if (dataCondition && dataCondition.length) {
        const canEdit = this.auth.role.is_owner || this.auth.role.is_agent_role;
        dataCondition.forEach((item: any): void => {
          const isDisabled: boolean = !(canEdit || item.created_by === this.auth.id);
          this.conditions.push(this.newConditions(item, isDisabled));
        });
      }
    }
  }

  private loadSportExtraSettings(): void {
    if (this.sportDetail?.settings || this.sportDetail?.sport_settings) {
      const jsonString: string = (this.sportDetail?.sport_settings ?? this.sportDetail?.settings) || '';
      const dataSetting = JSON.parse(jsonString);
      const temp: Array<any> = [];

      if (dataSetting) {
        dataSetting.forEach((item: any): void => {
          const displayCondition: boolean =
            this.auth.role.is_owner ||
            this.auth.role.is_agent_role ||
            item.performance_capacities?.value === true ||
            item.metabolic_fingerprint?.value === true ||
            (item.performance_development &&
              item.performance_development?.id !== 1);

          if (displayCondition) {
            temp.push(item);
          }
        });
        this.tableData = temp;
      }
    }
  }

  public newConditions(data?: any, isDisabled: boolean = false): UntypedFormGroup {
    return this.fb.group({
      name: new UntypedFormControl(
        { value: data?.name ? data.name : '', disabled: isDisabled },
        [
          Validators.required,
          Validators.pattern(this.constant.REGEX.NUMBER_LETTER_DASH_UNDERSCORE),
        ]
      ),
      ebg_factor_a: new UntypedFormControl(
        {
          value:
            data?.ebg_factor_a || data?.ebg_factor_a === 0
              ? data?.ebg_factor_a
              : '',
          disabled: isDisabled,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      ebg_factor_b: new UntypedFormControl(
        {
          value:
            data?.ebg_factor_b || data?.ebg_factor_b === 0
              ? data?.ebg_factor_b
              : '',
          disabled: isDisabled,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      ebg_factor_d: new UntypedFormControl(
        {
          value:
            data?.ebg_factor_d || data?.ebg_factor_d === 0
              ? data?.ebg_factor_d
              : '',
          disabled: isDisabled,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      o2_per_watt_eq_a: new UntypedFormControl(
        {
          value:
            data?.o2_per_watt_eq_a || data?.o2_per_watt_eq_a === 0
              ? data?.o2_per_watt_eq_a
              : '',
          disabled: isDisabled,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      o2_per_watt_eq_b: new UntypedFormControl(
        {
          value:
            data?.o2_per_watt_eq_b || data?.o2_per_watt_eq_b === 0
              ? data?.o2_per_watt_eq_b
              : '',
          disabled: isDisabled,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      o2_per_watt_eq_d: new UntypedFormControl(
        {
          value:
            data?.o2_per_watt_eq_d || data?.o2_per_watt_eq_d === 0
              ? data?.o2_per_watt_eq_d
              : '',
          disabled: isDisabled,
        },
        [Validators.required, Validators.min(0), Validators.max(100)]
      ),
      created_by: new UntypedFormControl(data.created_by ?? ''),
      isDisabled: new UntypedFormControl(isDisabled),
    });
  }

  public addCondition(): void {
    const data = this.defaultSportConstant;
    data.created_by = this.auth.id;
    this.conditions.push(this.newConditions(data));
  }

  public removeCondition(i: number): void {
    this.conditions.removeAt(i);
  }

  public save(): void {
    this.validateTableData();
    if (
      !this.formConditions.invalid &&
      !this.formInfor.invalid &&
      !this.formSetting.invalid &&
      !this.errorMessageTable.length
    ) {
      if (!this.data.id) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.formConditions.markAllAsTouched();
      this.formInfor.markAllAsTouched();
      this.formSetting.markAllAsTouched();
      if (this.formConditions.invalid) {
        this.isShowDetailCondition = true;
      }
      if (this.formSetting.invalid) {
        this.isShowDetailSetting = true;
      }

      if (this.errorMessageTable?.length) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          verticalPosition: 'bottom',
          data: { html: this.errorMessageTable.join('<br/>') },
        });
      }
    }
  }

  public objectComparisonFunction = (option: any, value: any): boolean => {
    return option.id === value.id;
  };

  public create(): void {
    this.isDataLoading = true;
    const input = this.prepareInput();
    input.settings = input.sport_settings;
    this.sportService.createSport(input)
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);
          throw error;
        }),
        finalize((): boolean => (this.isDataLoading = false))
      )
      .subscribe((res: SportDto): void => {
        this.dialogRef.close(res);
      });
  }

  public update(): void {
    this.isDataLoading = true;
    const input = this.prepareInput();
    if (!this.auth.role.is_owner && !this.auth.role.is_agent_role) {
      delete input.primary_type;
      delete input.secondary_type;
      delete input.muscle_usage_percentage;
    }
    this.sportService
      .updateSport(this.data.id, input)
      .pipe(
        catchError((error) => {
          this.snackBar.open(error, 'OK', this.constant.TOAST_CONFIG.ERROR);

          throw error;
        }),
        finalize((): boolean => (this.isDataLoading = false))
      )
      .subscribe((res: SportDto): void => {
        this.dialogRef.close(res);
      });
  }

  private prepareInput() {
    const { muscle_usage_percentage, primary_type, secondary_type } = this.formInfor.getRawValue();
    const conditions = this.formConditions.getRawValue().conditions;
    const ebg_conditions: string = JSON.stringify(conditions);
    const reference_system_settings = {
      id: this.data?.reference_system_settings?.id ?? null,
      muscle_usage_percentage,
      primary_type,
      secondary_type,
      created_by: this.auth.role.is_owner || this.auth.role.is_agent_role ? '' : this.auth.id,
    };

    _.each(this.tableData, (item): void => {
      delete item.$$hashKey;
      item.showLevel = false;
      _.each(item, (key): void => {
        delete key.$$hashKey;
      });
      _.each(item.level, (level): void => {
        delete level.$$hashKey;
        level.showGender = false;
        _.each(level.gender, (gender): void => {
          if (level?.is_default_level) {
            if (gender.ol !== 10 || gender.ul !== 10 || gender.std_dev !== 10) {
              gender.is_default_value = false;
            }
          }
          delete gender.$$hashKey;
          delete gender.inValid_ol;
          delete gender.inValid_ul;
        });
      });
    });
    const sport_settings: string = JSON.stringify(this.tableData);

    const training_level: (number | undefined)[] = this.listAthleteCategorySelected?.map(
      (item: AthleteTrainingLevelDto) => item.id
    );

    return {
      ...this.formInfor.getRawValue(),
      ...this.formSetting.getRawValue(),
      sport_settings,
      ebg_conditions,
      reference_system_settings,
      training_level,
    };
  }

  private validateTableData(): void {
    let listErrorMessage: Array<any> = [];
    _.each(this.tableData, (item): void => {
      let countError: number = 0;
      _.each(item.level, (level): void => {
        let isValid: boolean = true;
        _.each(level.gender, (gender): void => {
          isValid = this.checkValidationGenderTable(item, gender);
          if (!isValid) {
            countError += 1;
          }
        });
        if (!isValid) {
          level.showGender = true;
        }
      });
      if (countError) {
        item.showLevel = true;
        const msg = String.Format('{0} : UL, OL values are required', item.field);
        listErrorMessage.push(msg);
      }
    });
    this.errorMessageTable = listErrorMessage;
  }

  private checkValidationGenderTable(item: any, gender: any): boolean {
    if (
      item?.performance_capacities?.value === true ||
      item?.metabolic_fingerprint?.value === true ||
      (item?.performance_development && item?.performance_development?.id !== 1)
    ) {
      if (!gender?.ol || !gender?.ul) {
        gender.inValid_ol = !gender?.ol;
        gender.inValid_ul = !gender?.ul;

        return false;
      }
    }

    return true;
  }

  public categoriesChange(event: Array<AthleteTrainingLevelDto>): void {
    if (event.length > this.listAthleteCategorySelected.length) {
      const data: AthleteTrainingLevelDto[] = event.filter(
        (item: AthleteTrainingLevelDto) =>
          !this.listAthleteCategorySelected.find((i: AthleteTrainingLevelDto): boolean => i.id === item.id)
      );
      this.addCategoryInTable(data[0]);
    } else {
      const data: AthleteTrainingLevelDto[] = this.listAthleteCategorySelected.filter(
        (item: AthleteTrainingLevelDto) => !event.find((i: AthleteTrainingLevelDto): boolean => i.id === item.id)
      );
      this.removeCategoryInTable(data[0]);
    }
    this.listAthleteCategorySelected = _.cloneDeepWith(event);
    this.formAthleteCategory.get('categories')?.setValue(event);
  }

  private addCategoryInTable(category: AthleteTrainingLevelDto): void {
    const defaultLevel = [
      {
        value: category.value,
        showGender: false,
        gender: [
          {
            max: '',
            min: '',
            ol: 10,
            sex: 'male',
            std_dev: 10,
            ul: 10,
            is_default_value: true,
          },
          {
            max: '',
            min: '',
            ol: 10,
            sex: 'female',
            std_dev: 10,
            ul: 10,
            is_default_value: true,
          },
        ],
        id: category.id,
        is_default_level: true,
      },
    ];
    const dataClone: any[] = _.cloneDeepWith(this.tableData);
    dataClone.forEach((data): void => {
      data.level = [...data.level, ...defaultLevel];
    });
    const dataJson: string = JSON.stringify(dataClone);
    this.tableData = JSON.parse(dataJson);
  }

  private removeCategoryInTable(category: AthleteTrainingLevelDto): void {
    const dataClone: any[] = _.cloneDeepWith(this.tableData);
    dataClone.forEach((data): void => {
      data.level = data.level.filter(
        (item: any) =>
          !item.hasOwnProperty('is_default_level') ||
          (item.hasOwnProperty('is_default_level') &&
            item.is_default_level &&
            item.id !== category.id)
      );
    });
    const dataJson: string = JSON.stringify(dataClone);
    this.tableData = JSON.parse(dataJson);
  }
}
