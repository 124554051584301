import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { PlanActivatedDto, PlanPriceResultDto } from './plan-dto';

@Injectable({
  providedIn: 'root',
})
export class PlanPurchaseService extends ServiceBase {
  public getListActivesPlan(groupId?: number): Observable<any> {
    let apiURL: string = this._appConstants.API.PLAN.AVAILABLE_PLAN;
    if (groupId) {
      apiURL = `${apiURL}?group_id=${groupId}`;
    }

    return this._apiService.get(apiURL);
  }

  public getListUpgradePlan(planId: number): Observable<any> {
    let apiURL: string = this._appConstants.API.PLAN.LIST_UPGRADE;
    if (planId) {
      apiURL = `${apiURL}?plan_id=${planId}`;
    }

    return this._apiService.get(apiURL);
  }

  public getPricePlan(id: number, period: number = 3): Observable<PlanPriceResultDto> {
    const apiUrl: string = this._appConstants.API.PLAN.PLAN_PRICE + id + '/' + `?period=${period}`;

    return this._apiService.get(apiUrl).pipe(
      map((item: Object) => {
        return new PlanPriceResultDto().deserialize(item);
      })
    );
  }

  public getListGroups(): Observable<any> {
    const apiUrl: string = this._appConstants.API.PLAN.GROUPS;

    return this._apiService.get(apiUrl);
  }

  public purchasePlan(input: any): Observable<any> {
    const apiUrl: string = this._appConstants.API.INVOICES.PURCHASE_PLAN;

    return this._apiService.post(apiUrl, input);
  }

  public getListActivatedPlan(): Observable<Array<PlanActivatedDto>> {
    const apiUrl: string = this._appConstants.API.PLAN.ACTIVATED_PLAN;

    return this._apiService.get(apiUrl).pipe(
      map((item: any) => {
        return item.map((element: PlanActivatedDto) =>
          new PlanActivatedDto().deserialize(element)
        );
      })
    );
  }
}
