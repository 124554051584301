import { Directive, ElementRef, inject, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { SessionService } from "@core/services/session.service";

@Directive({
  selector: '[appHideForOrganization]'
})
export class HideElementForOrganizationDirective implements AfterViewInit {
  private renderer2: Renderer2 = inject(Renderer2);
  private sessionService: SessionService = inject(SessionService);
  private elementRef: ElementRef = inject(ElementRef);

  @Input() public appHideForOrganization: number[];

  public ngAfterViewInit(): void {
    const organizationId = JSON.parse(this.sessionService.getCookie('auth')).organization.id;
    const currentElement: ElementRef = this.elementRef.nativeElement;

    if (this.appHideForOrganization.includes(organizationId)) {
      this.renderer2.setStyle(currentElement, 'display', 'none');
    }
  }
}
