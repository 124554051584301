import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { IGeneralInfoList } from "@shared/interfaces/general-info-list.interface";
import { IExtendPaymentModalDialog } from "@shared/interfaces/extend-payment-modal-dialog.interface";
import { ITotalPrice } from "@shared/interfaces/total-price-dialog.interface";
import { IFeaturePrice } from "@shared/interfaces/feature-price-list.interface";
import { HelperService } from "@shared/services/helper.service";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { PaymentSummaryDialogState } from './payment-summary-dialog.state';
import { Observable } from 'rxjs';
import { FeatureDependency, FeaturePurchaseInfoInterface } from '@shared/interfaces/feature-purchase-info.interface';

@Component({
  selector: 'app-payment-summary-dialog',
  templateUrl: './payment-summary-dialog.component.html',
  styleUrls: ['./payment-summary-dialog.component.scss'],
  providers: [PaymentSummaryDialogState]
})
export class PaymentSummaryDialogComponent implements OnInit {
  public expiresOn: number;
  public dependencyLength: number;
  public totalPrice: ITotalPrice;
  public featureListWithPrice: IFeaturePrice[];
  public copyFeatureListWithPrice: IFeaturePrice[];
  public featureDependencyList: IFeaturePrice[];
  public additionalDependencies: IFeaturePrice[] = [];

  public generalInfoConfig: IGeneralInfoList;

  // State
  public loading$: Observable<boolean>;
  public purchaseInfo$: Observable<FeaturePurchaseInfoInterface>;

  constructor(
    public dialogRef: MatDialogRef<PaymentSummaryDialogComponent>,
    private helperService: HelperService,
    private featureStoreService: FeatureStoreService,
    private state: PaymentSummaryDialogState,
    @Inject(MAT_DIALOG_DATA) public data: IGeneralInfoList & IExtendPaymentModalDialog,
  ) {
    this.dependencyLength = data.dependencyLength;
    this.totalPrice = data.totalPrice;
    this.featureListWithPrice = data.featureListWithPrice;
    this.copyFeatureListWithPrice = this.helperService.makeCopy(data.featureListWithPrice);
    this.featureDependencyList = data.featureDependencyList;
  }

  public ngOnInit(): void {
    this.loading$ = this.state.loading$;
    this.purchaseInfo$ = this.state.purchaseInfo$;
    this.state.loadPaymentInfo(this.data.featureId);
    this.purchaseInfo$.subscribe((purchaseInfo: FeaturePurchaseInfoInterface) => {
      this.generalInfoConfig = {
        title: purchaseInfo.name,
        tags: purchaseInfo.tags ?? [],
        isRecommended: purchaseInfo.is_recommended ?? false,
        marketingFeatures: purchaseInfo.marketing_features ?? [],
        labelHeight: 20
      };
    })
  }

  public getGeneralInfoConfig(purchaseInfo: FeaturePurchaseInfoInterface): IGeneralInfoList {
    return {
      title: purchaseInfo.name,
      tags: purchaseInfo.tags ?? [],
      isRecommended: purchaseInfo.is_recommended ?? false,
      marketingFeatures: purchaseInfo.marketing_features ?? [],
      labelHeight: 20
    };
  }

  public getDependenciesText(purchaseInfo: FeaturePurchaseInfoInterface): string {
    const dependenciesLength = (purchaseInfo.dependencies ?? []).length;
    if (!dependenciesLength) {
      return "";
    }
    return `with ${dependenciesLength} required ${dependenciesLength === 1 ? "dependency" : "dependencies"}`;
  }

  public getFeatureDependencyList(purchaseInfo: FeaturePurchaseInfoInterface): Array<IFeaturePrice> {
    const requiresDependency: boolean = !(purchaseInfo.set_dependencies ?? []).some(dependency => dependency.is_enabled);
    if (!requiresDependency) {
      return []
    }

    const featureDependencyList: IFeaturePrice[] = [];
    (purchaseInfo.set_dependencies ?? []).forEach((dependency: FeatureDependency): void => {
      featureDependencyList.push({
        id: dependency.id,
        name: dependency.name,
        currencyName: dependency.currency_code ?? "",
        price: dependency.price ?? 0,
        value: dependency.price ?? 0,
      })
    })

    return featureDependencyList;
  }

  public getFeatureListWithPrice(purchaseInfo: FeaturePurchaseInfoInterface): Array<IFeaturePrice> {
    const listWithPrice: IFeaturePrice[] = [{
      name: purchaseInfo.name,
      currencyName: purchaseInfo.currency_code ?? "",
      price: purchaseInfo.price ?? 0,
      value: purchaseInfo.price ?? 0,
    }];

    (purchaseInfo.dependencies ?? []).forEach((dependency: FeatureDependency): void => {
      listWithPrice.push({
        name: dependency.name,
        currencyName: dependency.currency_code ?? "",
        price: dependency.price ?? 0,
        value: dependency.price ?? 0,
      })
    });

    const selectedSetDependencies: FeatureDependency[] = [];
    (purchaseInfo.selected_set_dependencies ?? []).forEach((id: number): void => {
      const setDependency = (purchaseInfo.set_dependencies ?? []).find(dependency => dependency.id === id);
      if (setDependency) {
        selectedSetDependencies.push(setDependency)
      }
    });

    selectedSetDependencies.forEach((dependency: FeatureDependency): void => {
      listWithPrice.push({
        name: dependency.name,
        currencyName: dependency.currency_code ?? "",
        price: dependency.price ?? 0,
        value: dependency.price ?? 0,
      })
    });

    return listWithPrice;
  }

  public getTotalPrice(purchaseInfo: FeaturePurchaseInfoInterface): ITotalPrice {
    return {
      userCount: purchaseInfo.user_count ?? 1,
      subTotal: purchaseInfo.sub_total ?? 0,
      discount: purchaseInfo.discount ?? 0,
      tax: purchaseInfo.tax ?? 0,
      totalAmount: purchaseInfo.total_amount ?? 0,
      currentCurrencyName: purchaseInfo.currency_code ?? ""
    }
  }

  /** Reload with the set dependency that has been chosen */
  public onAddFeatureToPrice(event: IFeaturePrice[], purchaseInfo: FeaturePurchaseInfoInterface): void {
    // The expectation is that price.id will always exist here so -1 will never be reached
    this.additionalDependencies = event;
    this.state.reloadPaymentInfo(purchaseInfo.id, this.additionalDependencies.map(price => price.id ?? -1));
  }

  public showFeatureMultiSelect(purchaseInfo: FeaturePurchaseInfoInterface): boolean {
    return this.getFeatureDependencyList(purchaseInfo).length > 0;
  }

  public accept(): void {
    this.dialogRef.close({
      featureId: this.data.featureId,
      additionalDependencies: this.additionalDependencies.map(dep => dep.id)
    });
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
