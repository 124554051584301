import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import * as moment from 'moment';
import { AppConstants } from '@core/constants';
import { SessionService } from '@core/services/session.service';
import { CompetitionDto } from '@core/services/teams-competitions/competition-dto';
import { CompetitionsService } from '@core/services/teams-competitions/competitions.service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-create-competition-dialog',
  templateUrl: './create-competition-dialog.component.html',
})
export class CreateCompetitionDialogComponent implements OnInit {
  public competitionForm: UntypedFormGroup = this.fb.group({});
  public profileData = JSON.parse(this.sessionService.getCookie('auth'));
  public startDate: Date = new Date();

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateCompetitionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService,
    private competitionService: CompetitionsService,
    private snackBar: MatSnackBar,
    private datePipe: DateFormatPipe,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.initForm();
    this.competitionForm.get('selectYear')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((change): void => {
        this.startDate = new Date(change, 0, 1);
      });
  }

  public initForm(): void {
    if (!this.data.isEdit) {
      this.competitionForm = this.fb.group({
        name: new UntypedFormControl('', Validators.required),
        selectYear: new UntypedFormControl(new Date().getFullYear(), [
          Validators.required,
          Validators.min(1900),
          Validators.max(2100),
        ]),
        shortname: new UntypedFormControl('', Validators.required),
        dateFrom: new UntypedFormControl('', Validators.required),
        dateTo: new UntypedFormControl(false, Validators.required),
      });
    } else {
      this.competitionForm = this.fb.group({
        name: new UntypedFormControl(this.data.competition.name, Validators.required),
        selectYear: new UntypedFormControl(
          this.data.competition.year,
          Validators.required
        ),
        shortname: new UntypedFormControl(
          this.data.competition.short_name,
          Validators.required
        ),
        dateFrom: new UntypedFormControl(
          this.data.competition.date_from,
          Validators.required
        ),
        dateTo: new UntypedFormControl(
          this.data.competition.date_to,
          Validators.required
        ),
      });
    }
  }

  public saveAction(): void {
    let dateFrom = this.competitionForm.get('dateFrom')?.value;
    let dateTo = this.competitionForm.get('dateTo')?.value;
    let selectYear = this.competitionForm.get('selectYear')?.value;

    if(this.data.isEdit){
      dateFrom = moment(dateFrom);
      dateTo = moment(dateTo);
    }

    if (!this.competitionForm.valid) {
      this.snackBar.open(
        'Please full fill the form.',
        'OK',
        AppConstants.TOAST_CONFIG.ERROR
      );
      return;
    }

    if (
      dateFrom?.year() !== dateTo?.year() ||
      dateFrom?.year() !== selectYear ||
      dateFrom?.isSameOrAfter(dateTo)
    ) {
      if (dateFrom?.isSameOrAfter(dateTo)) {
        this.snackBar.open(
          'Date From must be before Date To.',
          'OK',
          AppConstants.TOAST_CONFIG.ERROR
        );
      } else {
        this.snackBar.open(
          'Fileds From / To must be in the same year as field Year.',
          'OK',
          AppConstants.TOAST_CONFIG.ERROR
        );
      }
      return;
    }
    let competition: CompetitionDto = new CompetitionDto();
    competition.name = this.competitionForm.get('name')?.value;
    competition.short_name = this.competitionForm.get('shortname')?.value;
    competition.year = selectYear;
    competition.date_from = this.datePipe.transform(
      new Date(dateFrom),
      'YYYY-MM-DD'
    );
    competition.date_to = this.datePipe.transform(
      new Date(dateTo),
      'YYYY-MM-DD'
    );
    competition.organization = this.profileData.organization.id;
    if (this.data.isEdit) {
      competition.id = this.data.competition.id;
      this.competitionService
        .update(competition)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: Object) => this.dialogRef.close(res));
    } else {
      this.competitionService
        .create(competition)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: Object) => this.dialogRef.close(res));
    }
  }
  public closeDialog(): void {
    this.dialogRef.close();
  }
}
