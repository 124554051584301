import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-vla-max-dialog',
  templateUrl: './vla-max-dialog.component.html',
  styleUrls: ['./vla-max-dialog.component.scss'],
})
export class VlaMaxDialogComponent implements OnInit {
  public form!: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<VlaMaxDialogComponent>) {}

  public initForm(): void {
    this.form = this.fb.group({
      vla_max: new UntypedFormControl(),
      tolerance: new UntypedFormControl(),
    });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}
