<div class="sport-management-list">
  <div class="title-with-search">
    <p>Sports Management</p>
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input
        matInput
        placeholder="Sport name"
        [(ngModel)]="searchText"
        (keyup.enter)="search(searchText)"
      />
      <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
    </mat-form-field>
  </div>
  <div class="group-actions mb-3">
    <button
      *ngIf="auth.role.is_owner || rights.can_access_athlete_categories"
      mat-raised-button
      color="primary"
      (click)="manageAthleteCategory()"
      class="ml-4"
    >
      Manage Athlete Category
    </button>

    <button
      *ngIf="auth.role.is_owner"
      mat-raised-button
      color="primary"
      (click)="add()"
      class="ml-4"
    >
      Add
    </button>
  </div>
  <table
    mat-table
    matSort
    #tableData
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    style="width: 100%"
    (matSortChange)="sortData($event)"
    matSortActive="id"
    matSortDirection="desc"
    matSortDisableClear
    aria-describedby="device management list"
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="mat-column-id"
        scope="row"
      >
        ID
      </th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
    </ng-container>
    <ng-container matColumnDef="simulation_type_text">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.simulation_type_text }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ebg_factor_a">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        EDE A
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.ebg_factor_a }}</td>
    </ng-container>
    <ng-container matColumnDef="ebg_factor_b">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        EDE B
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.ebg_factor_b }}</td>
    </ng-container>

    <ng-container matColumnDef="ebg_factor_d">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        EDE D
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.ebg_factor_d }}</td>
    </ng-container>
    <ng-container matColumnDef="muscle_usage_percentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        % muscle mass used
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.muscle_usage_percentage }}
      </td>
    </ng-container>
    <ng-container matColumnDef="is_delete">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">
        Active
      </th>
      <td mat-cell *matCellDef="let element">
        {{ !element?.is_delete | translateBooleanText }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th scope="row" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element" class="fixed-width-action">
        <button
          mat-raised-button
          color="primary"
          class="btn-icon-text"
          (click)="edit(element?.id)"
        >
          <mat-icon>mode_edit</mat-icon> Edit
        </button>
        <button
          *ngIf="auth.role.is_owner"
          mat-raised-button
          class="btn-icon-text"
          (click)="deleteSport(element?.id)"
        >
          <mat-icon>delete</mat-icon> Delete
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row no-data-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="9">No data available in table</td>
    </tr>
  </table>
  <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
    <mat-paginator
      (page)="getDataPage($event.pageIndex + 1)"
      [length]="totalItems"
      [pageSize]="pageSize">
    </mat-paginator>
  </div>
</div>
