import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionService } from '@core/services/session.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  private permissions: string[] = [];
  public rights: any = {};
  public auth: any = {
    first_name: "",
    last_name: "",
    organization: {
      name: ""
    }
  };

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionService: SessionService
  ) {}


  @Input() public  set appHasPermission(val: string[]) {
    this.sessionService.getCurrentUserProfile();
    this.rights = this.sessionService.rights;
    this.auth = this.sessionService.auth;
    this.permissions = val;
    this.updateView();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasPermission: boolean = false;
    if (this.permissions) {
      this.permissions.forEach((permission: string): void => {
        if (this.rights[permission]) {
          hasPermission = true;
        }
      });
    }
    return hasPermission;
  }
}
