<div class="page-container">
    <div class="top-toolbar">
      <div class="sub-title">SINGLE ATHLETE METABOLIC PROFILING</div>
      <div class="title">
        <p>Single Athlete Metabolic Profiling</p>
      </div>
      <mat-tab-group>
        <mat-tab label="Test Data">
          <br>
          <div class="section">
            <div id="test_data"></div>
          </div>     
        </mat-tab>
      </mat-tab-group>
      <section class="btn-group">
        <button mat-raised-button color="secondary" (click)="handleCancelView(false, false, false, true)">Decline</button>
        <button mat-raised-button color="primary" (click)="handleAcceptView()">Accept</button>
        <button mat-raised-button color="primary" (click)="handleAcceptAndView()">Accept & View</button>
    </section>
    </div>
  </div>
  