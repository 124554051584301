<div class="constraints">
<!--  <span class="constraints__header u-font-weight-bold">Constraints</span>-->
  <form [formGroup]="constraintsForm" class="constraints__form">

    <mat-form-field class="constraints__select" appearance="fill">
      <mat-label>Constraints</mat-label>
      <mat-select formControlName="constraints">
        <mat-option *ngFor="let item of dataArray; let i = index" [value]="item.id" (click)="setValue(item)" [disabled]="item.isDisable">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="selectedValueItem">
      <div class="u-display-inline-block" *ngIf="selectedValue === selectedValueItem.name">
        <ng-container *ngIf="selectedValueItem.isShowAnaerobicThreshold && isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" type="number" min="0" formControlName="anaerobicThreshold">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowAnaerobicThreshold && !isPowerSport && isSpeedRef">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" type="number" formControlName="anaerobicThreshold">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowFatMax && isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" type="number" min="0" formControlName="fatmax">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowFatMax && !isPowerSport && isSpeedRef">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" type="number" formControlName="fatmax">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowAnaerobicThreshold && !isPowerSport && isPaceRef">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input id="at-pace-max-min-sec-km"  (click)="addMaskToInputATPaceMinSecKm()" class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" type="text" formControlName="fatmax">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowFatMax && !isPowerSport && isPaceRef">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input id="fatmax-pace-max-min-sec-km"  (click)="addMaskToInputFatMaxPaceMinSecKm()" class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" type="text" formControlName="fatmax">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowCarbohydrateInput">
          <ng-container *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" formControlName="carbohydrate">
            <span matPrefix>{{getCarbohydrateCurrentValue()}}</span>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowCarbohydrateInverseInput">
          <ng-container *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="getCarbohydrateInverseUnits()" formControlName="carbohydrateInverse">
            <span matPrefix>{{getCarbohydrateInverseValue()}}</span>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowDistanceTimeInput && isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" formControlName="initialValue">
          </mat-form-field>
          <span matPrefix>{{getDynamicPowerInitialValue()}}</span>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowDistanceTimeOverrideInput && isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholderOverride" formControlName="overrideValue">
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowDistanceTimePaceInput && !isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholder" formControlName="initialValue">
          </mat-form-field>
          <span matPrefix>{{getDynamicPowerInitialValue()}}</span>
        </ng-container>

        <ng-container *ngIf="selectedValueItem.isShowDistanceTimePaceOverrideInput && !isPowerSport">
          <mat-form-field floatLabel="auto" *ngIf="selectedValueItem.isShow">
            <input class="constraints-form__input" matInput [placeholder]="selectedValueItem.placeholderOverride" formControlName="overrideValue">
          </mat-form-field>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="isShowToggle">
      <div>
        <span class="constraints-form__toggle-button">Use relative (Watt/kg) values</span>
        <mat-slide-toggle class="constraints--padding-right-left-10" formControlName="constraintsToggle"></mat-slide-toggle>
      </div>
    </ng-container>
  </form>

  <!--<button (click)="checkFormValue()">Check</button>-->
</div>
