import { Injectable } from '@angular/core';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceBase } from '../service-base';
import { AthleteDto, AthleteResultDto, BaseAthleteDto, BaseAthleteResultDto } from './athletes-dto';
import { String } from 'typescript-string-operations';
import { ApiRequest } from '@shared/models/api-request';
import { AthleteTrainingLevelDto, AthleteTrainingLevelResultDto } from './athletes-training-level-dto';

@Injectable({
  providedIn: 'root',
})
export class AthletesService extends ServiceBase {
  public list(request: PagedAndSortedRequestDto | any): Observable<AthleteResultDto> {
    const apiUrl: string = this._appConstants.API.ATHLETE.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new AthleteResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: AthleteDto) =>
            new AthleteDto().deserialize(item)
          ),
        });
      })
    );
  }

  public listAll(): Observable<BaseAthleteResultDto> {
    const apiUrl: string = this._appConstants.API.ATHLETE.LISTALL;
    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new BaseAthleteResultDto().deserialize({
          totalCount: res.count,
          items: res.map((item: BaseAthleteDto) =>
            new BaseAthleteDto().deserialize(item)
          ),
        });
      })
    );
  }

  public getById(id: number): Observable<AthleteDto> {
    const apiUrl: string = String.Format(this._appConstants.API.ATHLETE.GET, id);
    return this._apiService.get(apiUrl)
      .pipe(map((res: Object) => new AthleteDto().deserialize(res)));
  }

  public create(input: AthleteDto, param: any): Observable<any> {
    const apiUrl: string = this._appConstants.API.ATHLETE.ADD + param;
    return this._apiService.xhrCall(apiUrl, input, 'POST');
  }

  public update(data: any): Observable<Object> {
    const apiUrl: string = String.Format(
      this._appConstants.API.ATHLETE.UPDATE,
      data.id
    );
    return this._apiService.xhrCall(apiUrl, data, 'PUT');
  }

  public delete(id: number): Observable<void | Object> {
    const apiUrl: string = String.Format(this._appConstants.API.ATHLETE.DELETE, id);
    return this._apiService.delete(apiUrl).pipe(
      catchError((response_: any) => {
        return <Observable<void>>(<any>throwError(response_));
      })
    );
  }
  public checkEmailExist(email: string): Observable<Object> {
    const apiUrl: string = `${this._appConstants.API.ATHLETE.CHECK_EMAIL_EXIST}?email=${email}`;
    return this._apiService.get(apiUrl);
  }

  public garminVerify(input: any): Observable<Object>  {
    const apiUrl: string = String.Format(
      this._appConstants.API.ATHLETE.GARMIN_VERIFY,
      input.id
    );
    const body = {};
    Object.assign(body, input);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({
      body,
      apiUrl,
    });
    return this._apiService.post(apiRequest.apiUrl, apiRequest.body);
  }

  public revokeGarmin(id: number): Observable<Object> {
    const apiUrl: string = String.Format(
      this._appConstants.API.ATHLETE.GARMIN_REVOKE,
      id
    );
    return this._apiService.get(apiUrl);
  }

  public revokeStrava(id: number): Observable<Object> {
    const apiUrl: string = String.Format(
      this._appConstants.API.ATHLETE.STRAVA_REVOKE,
      id
    );
    return this._apiService.get(apiUrl);
  }

  public syncStravaOrGarmin(
    input: {
      athlete_id: number;
      sync_type: number;
      weight: number;
    },
    apiUrl: string
  ): Observable<Object> {
    const body = {};
    Object.assign(body, input);
    const apiRequest: ApiRequest = new ApiRequest().deserialize({
      body,
      apiUrl,
    });
    return this._apiService.post(apiRequest.apiUrl, apiRequest.body);
  }

  public getAthleteTrainingLevel(isAddition: boolean = false): Observable<AthleteTrainingLevelResultDto> {
    let apiUrl: string = this._appConstants.API.ATHLETE.TRAINING_LEVEL + '?ordering=id';
    if (isAddition) {
      apiUrl = apiUrl + '&is_additional=true';
    }
    return this._apiService.get(apiUrl).pipe(
      map((res: any) => {
        return new AthleteTrainingLevelResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: AthleteTrainingLevelDto) =>
            new AthleteTrainingLevelDto().deserialize(item)
          ),
        });
      })
    );
  }

  public updateAthleteTrainingLevel(
    input: Array<{ id: number; value: string }>
  ): Observable<Object> {
    const body = [...input];
    const apiUrl: string = this._appConstants.API.ATHLETE.UPDATE_TRAINING_LEVEL;
    const apiRequest: ApiRequest = new ApiRequest().deserialize({
      body,
      apiUrl,
    });
    return this._apiService.put(apiRequest.apiUrl, apiRequest.body);
  }

  public checkAthleteAcceptMultiple(param: string): Observable<Object> {
    const apiUrl: string = this._appConstants.API.ATHLETE.ACCEPT_MULTIPLE + param;
    return this._apiService.get(apiUrl);
  }
}
