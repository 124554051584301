import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview-metabolic-fingerprint',
  templateUrl: './preview-metabolic-fingerprint.component.html',
  styleUrls: ['./preview-metabolic-fingerprint.component.scss']
})
export class PreviewMetabolicFingerprintComponent {
  @Input() public config: any;
}
