import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-animated-dropdown-menu',
  templateUrl: './animated-dropdown-menu.component.html',
  styleUrls: ['./animated-dropdown-menu.component.scss']
})
export class AnimatedDropdownMenuComponent {
  @Input() public buttonName: any;
  @Input() public optionList: any[];
  @Output() public optionChanged: EventEmitter<any> = new EventEmitter<any>();

  public onSelectOption(option: any): void {
    this.optionChanged.emit(option);
  }
}
