import { IMetricControlRange } from "@shared/interfaces/metric-control-range.interface";

const BODY_FAT: IMetricControlRange = {
  lower: 3,
  upper: 50,
  plusMinus: 5,
};
const VO2MAX: IMetricControlRange = {
  lower: 20,
  upper: 100,
  plusMinus: 10,
};
const VLAMAX: IMetricControlRange = {
  lower: 0.1,
  upper: 1.3,
  plusMinus: 0.25,
};
const ECONOMY: IMetricControlRange = {
  lower: 0.1,
  upper: 100,
  plusMinus: 10,
};

const BUFFERING_CAPACITY: IMetricControlRange = {
  lower: 10,
  upper: 80,
  plusMinus: 20,
};

export {
  BODY_FAT,
  VO2MAX,
  VLAMAX,
  ECONOMY,
  BUFFERING_CAPACITY,
}
