import { Injectable } from '@angular/core';
import { ServiceBase } from '../service-base';
import { TeamDto, TeamResultDto } from './team-dto';
import { String } from 'typescript-string-operations';
import { Observable } from 'rxjs';
import { PagedAndSortedRequestDto } from '@shared/components/paged-listing-component-base';
import { map } from 'rxjs/operators';
import { ApiRequest } from "@shared/models/api-request";

@Injectable({
  providedIn: 'root',
})
export class TeamService extends ServiceBase {
  public listAll(): Observable<Object> {
    return this._apiService.get(this._appConstants.API.TEAMS.LIST);
  }

  public list(request: PagedAndSortedRequestDto | any): Observable<TeamResultDto> {
    const apiUrl: string = this._appConstants.API.TEAMS.LIST;
    const apiRequest: ApiRequest = this._apiService.prepareInputSortAndSearchPage(apiUrl, request);

    return this._apiService.get(apiRequest.apiUrl).pipe(
      map((res: any) => {
        return new TeamResultDto().deserialize({
          totalCount: res.count,
          items: res.results.map((item: TeamDto) =>
            new TeamDto().deserialize(item)
          ),
        });
      })
    );
  }

  public delete(id: number): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.TEAMS.DELETE, id);

    return this._apiService.delete(apiUrl);
  }

  public create(team: TeamDto): Observable<Object> {
    return this._apiService.post(this._appConstants.API.TEAMS.ADD, {
      athletes: team.athletes,
      coaches: team.coaches,
      name: team.name,
      year: team.year,
      organization: team.organization,
      is_active: team.is_active,
    });
  }

  public update(team: TeamDto): Observable<Object> {
    const apiUrl: string = String.Format(this._appConstants.API.TEAMS.UPDATE, team.id);

    return this._apiService.put(apiUrl, team);
  }
}
