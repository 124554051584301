import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

export interface FileHandle {
  file: File;
  url: any;
}

@Directive({
  selector: '[appDragFile]',
})
export class DragFileDirective {
  @Output() public files: EventEmitter<FileHandle[]> = new EventEmitter();

  @HostBinding('style.background') private background = '';

  constructor() {}

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';

    let files: FileHandle[] = [];
    if (evt.dataTransfer) {
      for (let i of evt.dataTransfer.files as any) {
        const file = i;
        const url = window.URL.createObjectURL(file);
        files.push({ file, url });
      }
      if (files.length > 0) {
        this.files.emit(files);
      }
    }
  }
}
