import { Component, ViewChild } from '@angular/core';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { TrainingZoneTemplateDto } from '@core/services/training-zone/training-zone-template-dto';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TemplateBuilderDialogComponent } from './template-builder/template-builder-dialog.component';
import { TrainingZoneService } from '@core/services/training-zone/training-zone.service';
import { TrainingZonePagedResultDto } from '@core/services/training-zone/training-zone-paged-result-dto';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConstants } from "@core/constants";
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";


class PagedTrainingZoneRequestDto extends PagedAndSortedRequestDto {
  public title: string | undefined;
}


@Component({
  selector: 'app-athlete',
  templateUrl: './training-zone-template.component.html',
  styleUrls: ['./training-zone-template.component.scss']
})
export class TrainingZoneTemplateComponent extends PagedListingBaseComponent<TrainingZoneTemplateDto> {
  @ViewChild('tableTests') public tableTests: MatTable<any> | undefined;

  public displayedColumns: string[] = ['id', 'name', 'description', 'sport__name', 'is_active', 'is_default', 'action'];
  public request: PagedTrainingZoneRequestDto = new PagedTrainingZoneRequestDto();
  public trainingZones: TrainingZoneTemplateDto[] = [];

  constructor(
    public dialog: MatDialog,
    public trainingZoneService: TrainingZoneService,
    private snackBar: MatSnackBar) {
    super();
  }

  public addTemplate(): void {
    const dialogRef: MatDialogRef<TemplateBuilderDialogComponent> = this.dialog.open(TemplateBuilderDialogComponent, {
      width: '1400px',
      panelClass: 'general-dialog',
      disableClose : true,
      data:
      {
        id: null,
        isAllowEdit: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refresh();
      }
    });

  }

  public editTemplate(template: any): void {
    const dialogRef: MatDialogRef<TemplateBuilderDialogComponent> = this.dialog.open(TemplateBuilderDialogComponent, {
      width: '1400px',
      panelClass: 'general-dialog',
      disableClose : true,
      data:
      {
        id: template.id,
        isAllowEdit: this.isAllowEdit(template.organization)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogClose(result);
    });
  }

  public copyTemplate(template: any): void {
    const dialogRef: MatDialogRef<TemplateBuilderDialogComponent> = this.dialog.open(TemplateBuilderDialogComponent, {
      width: '1400px',
      panelClass: 'general-dialog',
      disableClose : true,
      data:
        {
          id: template.id,
          isAllowEdit: true,
          isCopy: true
        }
    });

    dialogRef.afterClosed().subscribe(result => {
     this.dialogClose(result);
    });
  }

  private dialogClose(result : any): void{
    if (result) {
      this.refresh();
    }
  }

  public deleteItem(id: number | undefined): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel("Delete", message);
    let dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose : true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id) {
          this.trainingZoneService.delete(id).subscribe((): void => {
            this.refresh();
          }, (error): void => {
            this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          });
        }
      }
    });
  }

  public list(request: PagedTrainingZoneRequestDto, pageNumber: number): void {
    request.pageIndex = pageNumber;
    this.trainingZoneService.list(request).subscribe((result: TrainingZonePagedResultDto): void => {
      this.trainingZones = result.items;
      this.totalItems = result.totalCount;
    });
  }

  public isAllowEdit(organizationId: number | undefined) {
    return this.auth.organization.id === organizationId || this.auth.role.is_owner;
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }

}
