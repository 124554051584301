import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateBooleanText',
})
export class TranslateBooleanTextPipe implements PipeTransform {
  public transform(value: boolean): string {
    return value ? 'YES' : 'NO';
  }
}
