import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogsData } from '../../tabs/tabs.component';
import { MODAL_WINDOW_TITLE } from "../enums/modal-window-title.enum";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-single-effort-dialog',
  templateUrl: './single-effort-dialog.component.html',
  styleUrls: ['./single-effort-dialog.component.scss'],
})
export class SingleEffortDialogComponent implements OnInit {
  public form!: UntypedFormGroup;
  protected modalWindowTitle: MODAL_WINDOW_TITLE = MODAL_WINDOW_TITLE.POWER_ONLY_EFFORTS;

  constructor(
    private dialogRef: MatDialogRef<SingleEffortDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogsData,
    private destroyRef: DestroyRef,
    ) {}

  public initForm(): void {
    this.form = this.fb.group({
      duration_efforts: new UntypedFormControl(
        this.data.duration_efforts,
        Validators.required
      ),
      average_power: new UntypedFormControl(
        this.data.average_power,
        Validators.required
      ),
      work: new UntypedFormControl(
        this.data.duration_efforts! * this.data.average_power!
      ),
      manual_w: new UntypedFormControl('', Validators.required),
    });

    this.form.get('duration_efforts')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((duration) =>
        this.updateValues(duration, this.form.get('average_power')?.value)
      );
    this.form.get('average_power')?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((power) =>
        this.updateValues(this.form.get('duration_efforts')?.value, power)
      );
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public updateValues(duration_efforts: number, average_power: number): void {
    if (!isNaN(duration_efforts) && !isNaN(average_power)) {
      this.form.patchValue({
        work: duration_efforts * average_power,
      });
    }
  }
}
