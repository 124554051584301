import { PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class CourseResultDto extends PagedResultDto {
  public items: CourseDto[] = [];
}

export class CourseDto extends Deserializable {
  public account_types: number[] | undefined;
  public days: string | number | undefined;
  public suggested_courses: [] | number | undefined;
  public created_at: Date | undefined;
  public description: string | undefined;
  public discounts: DiscountDto[] | undefined;
  public chapters: ChapterDto[] | undefined;
  public duration: number | undefined;
  public features: string | undefined;
  public id: number | undefined;
  public is_delete: boolean | undefined;
  public is_publish: boolean | undefined;
  public is_show_setting: boolean | undefined;
  public max_athlete: number | undefined;
  public max_test_event: number | undefined;
  public max_test_la: number | undefined;
  public max_test_manual: number | undefined;
  public max_test_map: number | undefined;
  public max_test_result: number | undefined;
  public max_test_virtual: number | undefined;
  public max_user: number | undefined;
  public name: string | undefined;
  public organizations: number[] | undefined;
  public plan_bill_at: number | undefined;
  public plans: any[] | undefined;
  public prices: CoursePriceDto[] | undefined;
  public ratios: RatioDto[] | undefined;
  public regions: number[] | undefined;
  public right_management: string | undefined;
  public triggers: string | undefined;
  public updated_at: Date | undefined;
  public entitled_for_days: number | undefined;
  public offer_ongoing_access_for_days: number | undefined;
  public parent: number | undefined;
  public units: number[] | undefined;
  public is_parent: boolean | undefined;
  public description_html: string | undefined;
  public waitlist: boolean | undefined;
}

export class DiscountDto {
  public id: number | undefined;
  public discount_type: number | undefined;
  public discount_value: number | undefined;
  public valid_until: Date | undefined;
  public purchased_day: number | undefined;
  public plan: undefined;
  public course: number | undefined;
  public account_types: number[] | undefined;
  public regions: number[] | undefined;
  public organizations: number[] | undefined;
}

export class ChapterDto {
  public id: number | undefined;
  public number: number | undefined;
  public course_id: number | undefined;
  public title: string | undefined;
  public description: string | undefined;
}

export class CoursePriceDto {
  public id: number | undefined;
  public price: number | undefined;
  public region: number | undefined;
  public course: number | undefined;
}

export class RatioDto {
  public id: number | undefined;
  public item1: number | undefined;
  public item1_quantity: number | undefined;
  public item2: number | undefined;
  public item2_quantity: number | undefined;
  public period_type: number | undefined;
  public period_value: number | undefined;
  public plan: undefined;
  public course: number | undefined;
}

export interface ICoursePurchaseInput {
  course_id: number;
  period?: number;
}

export interface ICourseEnrollInput {
  course_id: number;
}

export class CoursePurchaseDto extends Deserializable {
  public invoices: Array<number> | undefined;
}

export enum CourseEnrollmentStatus {
  // Default initial state
  CREATED = 'created',
  // Course has been purchase
  PURCHASED = 'purchased',
  // Enrolled
  ENROLLED = 'enrolled'
}

export enum CourseEntitlementStatus {
  // Course isn't purchased yet
  NONE = 'none',
  // Never enrolled before, or enrolled but within entitlement period
  ENTITLED = 'entitled',
  // Entitlement period has ended, but ongoing access can be offered
  OFFER_ONGOING_ACCESS = 'offer_ongoing_access',
  // User is in the ongoing access state (no entitlement applies)
  ONGOING_ACCESS = 'ongoing_access'
}

export class CourseCatalogDto extends Deserializable {
  public category_name: string | undefined;
  public course_rating: number | undefined;
  public currency_name: string | undefined;
  public description: string | undefined;
  public duration: number | undefined;
  public enrolled_on: Date | undefined;
  public entitled_until: Date | undefined;
  public id: number | undefined;
  public is_purchased: boolean | undefined;
  public name: string | undefined;
  public ongoing_access_until: Date | undefined;
  public original_price: number | undefined;
  public user_price: number | undefined;
  public video_preview: string | undefined;
  public entitled_for_days: number | undefined;
  public ongoing_access_price: number | undefined;
  public waitlist: boolean | undefined;
}
