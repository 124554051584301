import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PagedAndSortedRequestDto, PagedListingBaseComponent, PagedRequestDto } from '@shared/components/paged-listing-component-base';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import { AppConstants } from '@core/constants';
import {InformationPopupDto, InformationPopupResultDto} from '@core/services/information-popup/information-popup-dto';
import { InformationPopupService } from '@core/services/information-popup/information-popup.service';
import { AddInformationDialogComponent } from './add-information-dialog/add-information-dialog.component';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
})
export class InformationPopupComponent extends PagedListingBaseComponent<InformationPopupDto> {
  public isHadAction: boolean = false;
  public searchText: string = '';
  public request: PagedAndSortedRequestDto = new PagedAndSortedRequestDto();
  public dataSource: InformationPopupDto[] = [];
  public displayedColumns: string[] = [
    'id',
    'message_name',
    'active',
    'type',
    'expired_date',
    'action',
  ];

  constructor(
    private readonly popupService: InformationPopupService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    super();
  }

  public onInitPage(): void {
    super.onInitPage();
  }

  protected getPagedRequestDto(): PagedRequestDto {
    return this.request;
  }

  protected list(request: PagedAndSortedRequestDto, pageNumber: number): void {
    request.pageIndex = pageNumber;
    this.popupService.list(request).subscribe((res: InformationPopupResultDto): void => {
      this.dataSource = res.items;
      this.totalItems = res.totalCount;
    });
  }

  public add(): void {
    this.openDialog(false);
  }

  public edit(informationPopup: InformationPopupDto): void {
    this.openDialog(true, informationPopup);
  }

  public openDialog(isEdit: boolean, informationPopup?: InformationPopupDto): void{
    const dialogRef: MatDialogRef<AddInformationDialogComponent> = this.dialog.open(AddInformationDialogComponent, {
      width: '600px',
      panelClass: 'general-dialog',
      autoFocus: false,
      disableClose: true,
      data: { isEdit, informationPopup },
    });

    dialogRef.afterClosed().subscribe((res): void => {
      if (res) {
        let message = 'Item created successfully';
        if (isEdit) {
          message = 'Item updated successfully';
        }
        this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
        this.refresh();
      }
    });
  }

  public deletePopup(id: number): void {
    const message: string = 'Do you want to delete this item?';
    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Delete', message);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result) {
        if (id) {
          this.popupService.delete(id).subscribe(
            (): void => {
              this.snackBar.open('Item deleted successfully.', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
              this.refresh();
            },
            (error): void => {
              this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
            }
          );
        }
      }
    });
  }
}
