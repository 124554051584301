<ng-container *ngIf="plotConfig; else noPlots">
  <div class="u-margin-top-20 u-margin-bottom-50">
    <div class="o-flex o-flex--align-items-center o-flex--justify-space-between u-padding-left-right-5">
      <div class="pd-wrapper_left-side-buttons o-flex o-flex--justify-space-between">
        <app-y-axis-button (click)="openYAxis1()" buttonName="Y 1"></app-y-axis-button>
        <app-y-axis-button (click)="openYAxis2()" buttonName="Y 2"></app-y-axis-button>
      </div>
      <div class="pd-right-side-buttons o-flex o-flex--justify-space-between">
        <app-y-axis-button (click)="onYAxis3Changed()" buttonName="Y 3"></app-y-axis-button>
      </div>
    </div>
    <app-performance-development-chart-preview [config]="plotConfig"></app-performance-development-chart-preview>
    <div *ngIf="isRemovable" class="o-flex o-flex--justify-center">
      <button mat-button color="warn" (click)="removeCurrentGraph()">Remove</button>
    </div>
  </div>
  <mat-divider></mat-divider>
</ng-container>
<ng-template #noPlots>No Plots Data</ng-template>
