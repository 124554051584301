<div class="device-dialog__section">
  <mat-dialog-actions align="end">
    <div>
      <button
          id="cancelButton"
          mat-button
          (click)="close()"
          type="button">
          X
      </button>
    </div>
  </mat-dialog-actions>
    <h4 mat-dialog-title>{{ "API Tracking" }}</h4>
    <h1 mat-dialog-title>{{ "MASTERING PERFORMANCE" }}</h1><br>
    <mat-dialog-content>
        <div class="line">
            <div class="actions mb-3" style="display: flex;">
                <div class="field">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Date range</mat-label>
                    <button mat-raised-button color="primary" class="btn-icon-text" (click)="picker.open()">
                      <mat-icon>calendar_today</mat-icon>
                    </button>
                    <mat-date-range-input [rangePicker]="picker">
                      <input matStartDate
                         placeholder="From"
                         #dateRangeStart>
                      <input
                        matEndDate
                        placeholder="To"
                        #dateRangeEnd
                        (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
                </div>
                <div class="filter-input">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label floatLabel="always">Status</mat-label>
                    <mat-select [(value)]="selected" (selectionChange)="selectFilter()">
                      <mat-option *ngFor="let item of trackingStatuses" [value]="item.value">{{item.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Search" [(ngModel)]="searchText" (keyup.enter)="search(searchText)">
                <mat-icon matPrefix (click)="search(searchText)">search</mat-icon>
            </mat-form-field>
        </div>
        <table mat-table matSort #tableData [dataSource]="results" class="mat-elevation-z8"
            style="width: 100%;" (matSortChange)="sortData($event)" matSortActive="id" matSortDirection="desc"
            matSortDisableClear aria-describedby="Api tracking list">
            <ng-container matColumnDef="api_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">API Name</th>
                <td mat-cell *matCellDef="let element">{{element.api.name}}</td>
            </ng-container>
            <ng-container matColumnDef="api_key">
                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">API Key</th>
                <td mat-cell *matCellDef="let element">{{element.api_key}}</td>
            </ng-container>
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Timestamp</th>
                <td mat-cell *matCellDef="let element">{{element.getDate()}}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="row">Status</th>
                <td mat-cell *matCellDef="let element">{{element.getStatus()}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row no-data-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="isHadAction ? 7 : 6">No data available in table</td>
            </tr>
        </table>
        <div class="paging-display mt-2" *ngIf="dataSource.length > 0">
            <mat-paginator (page)="getDataPage($event.pageIndex + 1)" [length]="totalItems" [pageSize]="20">
            </mat-paginator>
        </div>
    </mat-dialog-content>
  </div>
