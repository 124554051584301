<form autocomplete="off" [formGroup]="competitionForm" class="createNew">
  <div>
    <div class="">
      <h1 *ngIf="!data.isEdit" mat-dialog-title>Add Competition</h1>
      <h1 *ngIf="data.isEdit" mat-dialog-title>Edit Competition</h1>
    </div>
    <div class="form-group row">
      <div>
        <mat-form-field
          floatLabel="always"
          class="full-width"
          appearance="outline"
        >
          <mat-label>Name (*)</mat-label>
          <input placeholder="Name" matInput formControlName="name" />
          <mat-error> </mat-error>
          <mat-error
            *ngIf="
              competitionForm.get('name')?.hasError('required') &&
              competitionForm.controls.name.touched
            "
          >
            Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field
          floatLabel="always"
          class="full-width"
          appearance="outline"
        >
          <mat-label>Shortname (*)</mat-label>
          <input
            placeholder="Short Name"
            matInput
            formControlName="shortname"
          />
          <mat-error> </mat-error>
          <mat-error
            *ngIf="
              competitionForm.get('shortname')?.hasError('required') &&
              competitionForm.controls.shortname.touched
            "
          >
            Short name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field
          floatLabel="always"
          class="full-width"
          appearance="outline"
        >
          <mat-label>Year (*)</mat-label>
          <input
            placeholder="Year"
            matInput
            type="number"
            formControlName="selectYear"
          />
          <mat-error> </mat-error>
          <mat-error
            *ngIf="
              competitionForm.get('selectYear')?.errors &&
              competitionForm.controls.selectYear.touched
            "
          >
            Year must be a valid integer between 1900 and 2100.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>From(*)</mat-label>
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="dateFrom"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker2
            [startAt]="startDate"
          ></mat-datepicker>
          <mat-error
            *ngIf="
              competitionForm.get('dateFrom')?.hasError('required') &&
              competitionForm.controls.dateFrom.touched
            "
          >
            From is required
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>To(*)</mat-label>
          <input matInput [matDatepicker]="picker1" formControlName="dateTo" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker1
            [startAt]="startDate"
          ></mat-datepicker>
          <mat-error
            *ngIf="
              competitionForm.get('dateTo')?.hasError('required') &&
              competitionForm.controls.dateTo.touched
            "
          >
            To is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <div class="mt-3">
        <button mat-raised-button color="primary" (click)="saveAction()">
          Save
        </button>
        <button
          mat-raised-button
          id="cancelButton"
          type="button"
          (click)="closeDialog()"
        >
          Cancel
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
