import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFeaturePrice } from "@shared/interfaces/feature-price-list.interface";

@Component({
  selector: 'app-unordered-list-with-price',
  templateUrl: './unordered-list-with-price.component.html',
  styleUrls: ['./unordered-list-with-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnorderedListWithPriceComponent {
  @Input() featureListWithPrice: IFeaturePrice[];
}
