import { Component, Input } from '@angular/core';
import { ITestimonialCard } from "@shared/components/interfaces/testimonial-card.interface";

@Component({
  selector: 'app-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrls: ['./testimonial-card.component.scss']
})
export class TestimonialCardComponent {
  @Input() public config: ITestimonialCard;
}
