<div class="device-dialog__section">
  <mat-dialog-content [formGroup]="form">
    <div class="field field-text">
      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
        <mat-label>Name</mat-label>
        <input type="text" matInput placeholder="" formControlName="name">
        <mat-error *ngIf="form.controls?.name?.errors && form.controls?.name?.touched">
          <div [innerHTML]=" form.controls.name.errors | errorFormFormat : {fieldName : 'Name'}"></div>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button id="cancelButton" mat-raised-button (click)="close()" type="button">Cancel</button>
      <button id="saveButton" mat-raised-button color="primary" (click)="save()"> Save </button>
    </div>
  </mat-dialog-actions>
</div>
