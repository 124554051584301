import { EntityDto,PagedResultDto } from '@shared/components/paged-listing-component-base';
import { Deserializable } from '@shared/models/deserializable';

export class RightsManagementResultDto extends PagedResultDto {
  public items: RightsManagementDto[] = [];
}
export class OrganizationOfRightsDto extends EntityDto {
  public address_country_id: number | undefined;
  public is_check_invoice: boolean | undefined;
  public is_solvent: boolean | undefined;
  public name: string | undefined;
  public photo: string | undefined;
}

export class RightsManagementDto extends Deserializable {
  public id: number | undefined;
  public is_active: boolean | undefined;
  public is_agent_role: boolean | undefined;
  public is_delete: boolean | undefined;
  public is_owner: boolean | undefined;
  public name: string | undefined;
  public organization: OrganizationOfRightsDto | undefined;
}

export interface IRightDetail {
  id?: number;
  name?: string;
  is_owner?: boolean;
  is_organization?: boolean;
  right_management?: string;
  is_agent_role?: boolean;
  organization?: number;
  is_active?: boolean;
}
