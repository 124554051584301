import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { HelperService } from "@shared/services/helper.service";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

@Component({
  selector: 'app-suggested-features',
  templateUrl: './suggested-features.component.html',
  styleUrls: ['./suggested-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedFeaturesComponent implements OnInit {
  private helperService: HelperService = inject(HelperService);
  public configSwiper: any;

  @Input() public  dependencyFeatureList: ITileConfig[];
  @Input() public  title: string;

  public ngOnInit(): void {
    this.configSwiper = this.helperService.getSwiperConfig(window.innerWidth);
  }
}
