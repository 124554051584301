import { Component, Input } from '@angular/core';
import { IReferenceLink } from "@shared/components/interfaces/reference-link.interface";

@Component({
  selector: 'app-reference-link',
  templateUrl: './reference-link.component.html',
  styleUrls: ['./reference-link.component.scss']
})
export class ReferenceLinkComponent {
  @Input() public config: IReferenceLink;
}
