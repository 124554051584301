import { Injectable } from '@angular/core';
import { AppConstants } from "../../constants";
import { ApiService } from "../api.service";
import { TrainingZoneTemplateDto } from "../training-zone/training-zone-template-dto";
import * as _ from "lodash-es";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TrainingZonesComponentService {
  public test: any = null;
  public templates: TrainingZoneTemplateDto[] = [];

  constructor(private apiService: ApiService) {}

  public getColspan(dataJson: any): number {
    return Object.keys(dataJson).length;
  }

  public fetchTrainingZoneTemplate(sportId: number): Observable<Object> {
    const url: string = 'training_zone/sport/' + sportId + '/';

    return this.apiService.get(url);
  }

  public convertZeroValueToString(zones: any[]): any {
    if (!zones || !_.isArray(zones)) {
      return [];
    }
    zones.forEach((zone: any): void => {
      this.handleDecimalValue(zone.lower);
      this.handleDecimalValue(zone.upper);

      Object.keys(zone.target).forEach((key: string): void => {
        zone.target[key] =
          zone.target[key] !== 0
            ? zone.target[key]
            : zone.target[key].toString();
      });

      this.handleDecimalValue(zone.output_values);
    });

    return zones;
  }

  private handleDecimalValue(input: any): void {
    const list_keys_decimal_2: string[] = [
      'lactate_accumulation_rate',
      'lactate_clearance_rate',
      'lactate_concentration',
      'anaerobic_power_watt_kg',
      'aerobic_power_watt_kg',
      'power_watt_kg',
    ];
    Object.keys(input).forEach((key: string): void => {
      let newValue: string[] = input[key] !== 0 ? input[key] : input[key].toFixed(0);
      if (list_keys_decimal_2.indexOf(key) !== -1) {
        newValue = input[key] !== 0 ? input[key] : input[key].toFixed(1);
      }
      input[key] = newValue;
    });
  }

  public convertJsonDataToTable(trainingZoneJsonData: any): any {
    const row_data: any[] = [];
    const mapLowerConstant: any =
      AppConstants.MAP_TRAINING_ZONE_UPPER_LOWER_TARGET;
    const mapOutputConstant: any = AppConstants.MAP_TRAINING_ZONE_OUTPUT_KEYS;
    _.forEach(trainingZoneJsonData, (zone: any): void => {
      // lower
      const currentLower = Object.keys(zone.lower).length;
      const lowerHeaders: string[] = [];
      const lowerUnits: string[] = [];
      const lowerKeys: string[] = Object.keys(zone.lower);
      lowerKeys.forEach((item: string): void => {
        if (!_.isEmpty(mapLowerConstant[item])) {
          lowerHeaders.push(mapLowerConstant[item].name);
          lowerUnits.push(mapLowerConstant[item].unit);
        }
      });

      // upper
      const upperHeaders: string[] = [];
      const upperUnits: string[] = [];
      const upperKeys: string[] = Object.keys(zone.upper);
      upperKeys.forEach((item: string): void => {
        if (!_.isEmpty(mapLowerConstant[item])) {
          upperHeaders.push(mapLowerConstant[item].name);
          upperUnits.push(mapLowerConstant[item].unit);
        }
      });

      // target
      const targetHeaders: any[] = [];
      const targetUnits: any[] = [];
      const targetKeys: string[] = Object.keys(zone.target);
      targetKeys.forEach((item: string): void => {
        if (!_.isEmpty(mapLowerConstant[item])) {
          targetHeaders.push(mapLowerConstant[item].name);
          targetUnits.push(mapLowerConstant[item].unit);
        } else {
          delete zone.target[item];
        }
      });

      // output values
      const outputValueKeys: string[] = Object.keys(zone.output_values);
      const outputValueHeaders: string[] = [];
      const outputValueUnits: string[] = [];
      outputValueKeys.forEach((key: string): void => {
        if (!_.isEmpty(mapOutputConstant[key])) {
          outputValueHeaders.push(mapOutputConstant[key].name);
          outputValueUnits.push(mapOutputConstant[key].unit);
        }
      });

      const outputValueValues = Object.values(zone.output_values);

      const additionalSpace: number =
        this.getMaxOutputValues(trainingZoneJsonData) -
        Object.keys(zone.output_values).length;
      if (additionalSpace > 0) {
        for (let i = 0; i < additionalSpace; i++) {
          outputValueHeaders.push('');
          outputValueUnits.push('');
          outputValueValues.push('');
        }
      }

      const colspan: number = this.getMaxLowUpTarget() / currentLower;
      // header
      row_data.push({
        name: {value: zone.name, rowspan: 3},
        code: {value: zone.code, rowspan: 3},
        lower: {
          colspan: colspan,
          headers: lowerHeaders,
          values: Object.values(zone.lower),
          units: lowerUnits,
        },
        upper: {
          colspan: colspan,
          headers: upperHeaders,
          values: Object.values(zone.upper),
          units: upperUnits,
        },
        target: {
          colspan: colspan,
          headers: targetHeaders,
          values: Object.values(zone.target),
          units: targetUnits,
        },
        output_values: {
          headers: outputValueHeaders,
          values: outputValueValues,
          units: outputValueUnits,
        },
      });
    });
    return row_data;
  }

  public getMaxLowUpTarget(): number {
    return 6;
  }

  public getMaxOutputValues(trainingZoneJsonData: any): number {
    let maxOut: number = 0;
    if (trainingZoneJsonData) {
      _.forEach(trainingZoneJsonData, (zone): void => {
        const countUpper: number = Object.keys(zone.output_values).length;
        if (countUpper > maxOut) {
          maxOut = countUpper;
        }
      });
    }
    return maxOut;
  }

  public getWidthOutputValue(trainingZoneJsonData: any) {
    if (this.getMaxOutputValues(trainingZoneJsonData) === 5) {
      return '10%';
    } else if (
      this.getMaxOutputValues(trainingZoneJsonData) === 4 ||
      this.getMaxOutputValues(trainingZoneJsonData) === 3
    ) {
      return '12%';
    }
    return '20%;';
  }
}
