<form autocomplete="off" [formGroup]="formData" (ngSubmit)="save()">
    <h2 mat-dialog-title>{{ 'How you want to continue' }}</h2>
    <mat-dialog-content>
        <p *ngIf="!showConfirmTrigger">How do you wish to allocate this</p>
        <p *ngIf="showConfirmTrigger">This action will start the free usage period of
            <span>{{currentPlanName}}</span>
        </p>
        <mat-form-field appearance="outline" floatLabel="always" class="d-block" *ngIf="data.option && data.option.value === 'Plan'">
            <mat-label>Plan</mat-label>
            <mat-select formControlName="plan">
                <mat-option *ngFor="let item of data.invoicePlans" [value]="item['id']" (onSelectionChange)="onSelectPlan(item)">
                    {{ item['name'] }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formData.get('plan')?.hasError('required')">
                Plan is required
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="d-block" *ngIf="data.option && data.option.value === 'Product'">
            <mat-label>Product</mat-label>
            <mat-select formControlName="product">
                <mat-option *ngFor="let item of data.invoiceProducts" [value]="item['id']">
                  {{ item['name'] }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formData.get('product')?.hasError('required')">
                Product is required
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div>
            <button mat-raised-button (click)="cancel()" type="button">{{ "Cancel" }}</button>
            <button mat-raised-button color="primary" type="submit">{{ "Save" }}</button>
        </div>
    </mat-dialog-actions>
</form>
