import { PagedResultDto } from "@shared/components/paged-listing-component-base";
import { Deserializable } from "@shared/models/deserializable";


export class ApiDetailResultDto extends PagedResultDto {
  public items: ApiDetailDto[] = [];
}

export class ApiDetailDto extends Deserializable {
  public id: number | undefined;
  public api:
    |
     {
        id: | undefined;
        name: string | undefined;
        method: string | undefined;
        url: string | undefined;
        is_active: boolean | undefined;
        alias: string | undefined;
        created_at: Date | undefined;
        updated_at: Date | undefined;
        is_delete: boolean | undefined;
     }
    | undefined;
  public organization:
    |
     {
        id: number | undefined;
        name: string | undefined;
        photo: string | undefined;
        is_solvent: boolean | undefined;
        address_country_id: number | undefined;
        is_check_invoice: boolean | undefined;
        is_force_update_payment_method: boolean | undefined;
        active_payment_method_id: string | undefined;
        active_payment_method_type: string | undefined;
     }
    | undefined;
  public status: number | undefined;
  public api_key: string | undefined;
  public created_at: string | undefined;
  public updated_at: Date | undefined;
  public is_delete: boolean  | undefined;

  public getDate(): string {
      let date: Date | null = this.created_at ? new Date(this.created_at) : null;
      const time: string | undefined = date?.toLocaleTimeString('it-IT');
      const fullDate: string | undefined = date?.toLocaleDateString('it-IT').replace(/\//g, '.');

      return fullDate + " " + time;
    }
}

