import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject, Input, ViewChild } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { debounceTime } from "rxjs/operators";
import { ISearch } from "@shared/interfaces/search.interface";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements AfterViewInit {
  @Input() public searchObservable$: Subject<string>;
  @Input() public config: ISearch;
  @ViewChild('search') public search: ElementRef;

  public destroyRef: DestroyRef = inject(DestroyRef);

  public ngAfterViewInit(): void {
    fromEvent(this.search.nativeElement, 'input')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(1000),
      )
      .subscribe({
        next: (event: any): void => {
          this.searchObservable$.next(event.target.value);
        }
      })
  }
}
