import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-course-price-discount',
  templateUrl: './course-price-discount.component.html',
  styleUrls: ['./course-price-discount.component.scss']
})
export class CoursePriceDiscountComponent implements OnChanges {
  @Input() public currency: string;
  @Input() public originalPrice: number;
  @Input() public userPrice: number;
  @Input() public isCard: boolean;

  public savedAmount: string | number;
  public isSavedAmount: boolean = false;
  constructor() { }

  public ngOnChanges(): void {
    if (this.originalPrice === this.userPrice) {
      this.isSavedAmount = false;
    } else if ((this.originalPrice - this.userPrice) > 0) {
      this.isSavedAmount = true;
    }

    if (this.originalPrice > this.userPrice) {
      if ((this.originalPrice - this.userPrice) > 0) {
        this.savedAmount = (this.originalPrice - this.userPrice);
      }
    }
  }
}
